import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { SubmitButton } from "../customComponent/Buttons";
import { hrEmployeePayslipFindAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import { EmployeePayslipTable } from "./EmployeePayslipTable";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
function AllEmployeePaySlip() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();

  const month = currentDate.toLocaleString("en-US", { month: "long" });

  const monthName = `${month} ${year}`;

  const dispatch = useDispatch();

  const [EmployeePaysliplist, setEmployeePayslip] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const [FilterDate, SetFilterDate] = useState(monthName);
  // Fetch_Data //

  let { hrEmployeePayslipCreateSuccess } = useSelector((state) => {
    return state.hrEmployeePayslipCreate;
  });

  let { hrEmployeePayslipDeleteSuccess } = useSelector((state) => {
    return state.hrEmployeePayslipDelete;
  });

  console.log(hrEmployeePayslipCreateSuccess, "created");

  useEffect(() => {
    dispatch(hrEmployeePayslipFindAction(FilterDate));
  }, [
    dispatch,
    hrEmployeePayslipCreateSuccess,
    hrEmployeePayslipDeleteSuccess,
  ]);

  let { hrEmployeePayslipFindSuccess } = useSelector((state) => {
    return state.hrEmployeePayslipFind;
  });

  console.log(hrEmployeePayslipFindSuccess, "payslip data");
  //set_data_to_state

  useEffect(() => {
    if (hrEmployeePayslipFindSuccess) {
      setEmployeePayslip(hrEmployeePayslipFindSuccess);
    }
  }, [hrEmployeePayslipFindSuccess]);

  const data = EmployeePaysliplist.filter(
    (master) => master.branchId !== null
  ).map((employee, index) => ({
    sl: index + 1,
    id: employee.id,
    coloumn1: employee.firstName,
    coloumn2: employee.employeeId,
    coloumn3: employee.role !== null ? employee.role.roleName : "-",
    coloumn4:
      employee.employee_payslip !== null
        ? employee.employee_payslip.Payslip
        : null,
  }));

  if (!hasPrivilege("payslip") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }


  return (
    <>
      {/* {hasPrivilege("payslip") && privilege == null ? ( */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Action"
            submit=""
            widthSize="136px"
            heightSize="38px"
            type="click"
            //   handleSubmit={handleOpen}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <EmployeePayslipTable
            columns={[
              {
                title: "SL NO",
                field: "SL NO",
              },
              {
                title: "EMPLOYEE NAME",
                field: "EMPLOYEE NAME",
              },
              {
                title: "ID",
                field: "ID",
              },
              {
                title: "ROLE",
                field: "ROLE",
              },
              {
                title: "MONTH",
                field: "MONTH",
              },
              {
                title: "PAYSLIP",
                field: "PAYSLIP",
              },
            ]}
            data={data}
            component="PaySlip"
            subMenu="payslip"
            //   isPresentMap={isPresent}
          />
        </Grid>
      </Grid>
      {/* ) : (
        <Alert severity="error">
          <AlertTitle>warning</AlertTitle>
          You don't have permission to access — <strong>this page.!</strong>
        </Alert>
      )} */}
    </>
  );
}

export default AllEmployeePaySlip;
