import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectinputFieldCustom } from "../../customComponent/InputField";
import CourseModalListItems from "../studentRegister/course/CourseModalListItems";
import { SubmitButton } from "../../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCategoryFindAction } from "../../../actions/IeltsActions/learningManadmentAction";

function IeltsOfflineSelectTeacherModal({
  selectModal,
  handleClose,
  selectedTeacher,
  handleSelected,
  handleAssignTeacher,
  teachers,
}) {
  const dispatch = useDispatch();

  let { ieltsCategoryFindSuccess } = useSelector((state) => {
    return state.ieltsCategoryList;
  });

  const [filterData, setFilterData] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryList, setCategoryList] = useState();

  console.log(teachers)

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("categoryTable"));
  }, [dispatch]);

  useEffect(() => {
    setFilterData(teachers);
  }, [teachers]);

  useEffect(() => {
    handleFilter(searchKeyword, selectedCategory);
  }, [searchKeyword, selectedCategory]);

  const handleFilter = (name, cat) => {
    let searchFilter =
      searchKeyword === ""
        ? teachers
        : teachers?.filter((search) =>
            search?.ielts_teacher?.teacherName
              ?.toLowerCase()
              .includes(name?.toLowerCase())
          );

    let categoryfilter =
      selectedCategory === ""
        ? searchFilter
        : searchFilter?.filter((category) =>
            category?.ielts_teacher?.teacherSpecialization
              ?.toLowerCase()
              .includes(cat?.toLowerCase())
          );

    setFilterData(categoryfilter);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  return (
    <Dialog
      open={selectModal}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "100%",
          width: "1200px",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid #ECECEC",
          display: "flex",
          maxWidth: "100%",
          gap: "30px",
        }}
      >
        <Box
          component={"form"}
          sx={{
            width: "100%",
            maxWidth: "530px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #C4C4C4",
            borderRadius: "4px",
            height: "44px",
            "& input": {
              padding: "8px",
              outline: "none",
              border: "none",
              width: "100%",
            },
          }}
        >
          <input placeholder="Search" onChange={handleSearchChange} />
          <Button>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#ACB1C6"
              />
              <path
                d="M21.9995 22.7495C21.8095 22.7495 21.6195 22.6795 21.4695 22.5295L19.4695 20.5295C19.1795 20.2395 19.1795 19.7595 19.4695 19.4695C19.7595 19.1795 20.2395 19.1795 20.5295 19.4695L22.5295 21.4695C22.8195 21.7595 22.8195 22.2395 22.5295 22.5295C22.3795 22.6795 22.1895 22.7495 21.9995 22.7495Z"
                fill="#ACB1C6"
              />
            </svg>
          </Button>
        </Box>
        <Box
          sx={{
            maxWidth: "300px",
            width: "100%",
          }}
        >
          <SelectinputFieldCustom
            array={categoryList}
            label="category"
            mapMethod="centerCategory"
            handleChange={handleCategoryChange}
          />
        </Box>
      </DialogTitle>
      {filterData?.length > 0 ? (
        <DialogContent
          sx={{
            paddingTop: "15px !important",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "32px",
          }}
        >
          {filterData?.map((item, i) => (
            <Box
              onClick={() => handleSelected(item?.ielts_teacher?.id)}
              sx={{
                border:
                  selectedTeacher === item?.ielts_teacher?.id
                    ? "1px solid rgba(254, 11, 122, 1)"
                    : "1px solid rgba(254, 11, 122, 0)",
                padding: "8px",
                boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
                display: "grid",
                gridTemplateColumns: "80px 1fr",
                gap: "12px",
                position: "relative",
                "& .delete": {
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                },
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  width: "80px",
                  height: "76px",
                  "& img": {
                    borderRadius: "8px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img
                  src={item?.ielts_teacher?.imageUrl}
                  alt="teacher image"
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",

                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#000",
                  }}
                >
                  {item?.ielts_teacher?.teacherName}
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    fontSize: "16px",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.ielts_teacher?.teacherSpecialization}
                </Typography>
              </Box>
            </Box>
          ))}
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            paddingTop: "15px !important",
          }}
        >
          <Box
            sx={{
              padding: "55px",
              textAlign: "center",
              position: "relative",
            }}
          >
            No Items Found
          </Box>
        </DialogContent>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: "20px",
          padding: "16px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Button
            sx={{
              color: "#141E3C",
              fontWeight: "700",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <SubmitButton
            type={"click"}
            handleSubmit={handleAssignTeacher}
            title={"Done"}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

export default IeltsOfflineSelectTeacherModal;
