import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LoginSubmitAction } from "../../../actions/adminAuthAction";
import { SubmitButton } from "../../customComponent/Buttons";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function LoginField({ forgotPageHandler }) {
  const dispatch = useDispatch();

  const { loginSuccess, loginSubmitLoading, loginSubmiterror } = useSelector(
    (state) => {
      return state.loginSubmit;
    }
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [view, setView] = useState(false);

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === "") {
      setEmailError(true);
    }
    if (password === "") {
      setPasswordError(true);
    }
    if (email !== "" && password !== "") {
      event.preventDefault();
      dispatch(LoginSubmitAction(email, password));
    }
  };
  // onchange email handle
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  // onchange password handle
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  return (
    <Grid item xs={12} sm={12} md={6} square>
      <Box
        sx={{
          m: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          pl: 3
        }}
      >
        {loginSubmiterror && (
          <Typography
            style={{
              fontWeight: 90,
              fontSize: "20px",
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: "52px",
              color: "red"
            }}
          >
            {loginSubmiterror}
          </Typography>
        )}
        <Typography
          component="h2"
          variant="h2"
          style={{
            fontWeight: 1000,
            fontSize: "34px",
            fontFamily: "'Poppins', sans-serif",
            lineHeight: "52px",
            color: "#141E3C"
          }}
        >
          Login
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          style={{
            width: "425px",
            height: "24px",
            left: "40px",
            top: "101px",
            fontStyle: "normal",
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 400,
            fontSize: "15px",
            lineHeight: "150%",

            color: "#777AAE"
          }}
        >
          Login to GSL account using your email and password
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Typography
            component="h6"
            variant="h6"
            style={{
              width: "425px",
              height: "24px",
              left: "40px",
              top: "101px",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "150%",
              fontFamily: "'Poppins', sans-serif",
              color: "rgba(119, 122, 174, 1)"
            }}
          >
            Email
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            // id="email"
            // label="Email Address"
            name="email"
            autoComplete="email"
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? "Please enter your email" : ""}
            autoFocus
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "23px",
                backgroundColor: "white"
              },
              "& .MuiInputBase-root": {
                borderWidth: "1px",
                borderRadius: "12px",
                borderColor: "#fcfcfc",
                border: "1px solid #fcfcfc",
                "&:hover": {
                  borderColor: "#fcfcfc"
                },
                "&.Mui-focused": {
                  borderColor: "#fcfcfc"
                }
              }
            }}
          />

          <Typography
            component="h6"
            variant="h6"
            style={{
              width: "425px",
              height: "24px",
              left: "40px",
              top: "101px",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "150%",
              fontFamily: "'Poppins', sans-serif",

              color: "rgba(119, 122, 174, 1)"
            }}
          >
            Password
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            type={view ? "text" : "password"}
            id="password"
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={passwordError ? "Please enter your password" : ""}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setView(!view)}>
                    {view ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "23px",
                backgroundColor: "white"
              },
              "& .MuiInputBase-root": {
                borderWidth: "1px",
                borderRadius: "12px",
                borderColor: "#fcfcfc",
                border: "1px solid #fcfcfc",
                "&:hover": {
                  borderColor: "#fcfcfc"
                },
                "&.Mui-focused": {
                  borderColor: "#fcfcfc"
                }
              }
            }}
          />

          <Grid container mt="10px">
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    style={{ borderRadius: "12px" }}
                  />
                }
                label="Remember me"
                style={{ color: "#777AAE" }}
              />
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{
                  color: "#FF1B6F",
                  textDecoration: "none",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 400,
                  fontSize: "16px"
                }}
                onClick={forgotPageHandler}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          {/* <Button
            className="button"
            type="submit"
            fullWidth
            size="large"
            sx={{
              mt: 3,
              mb: 2,
              fontWeight: "700",
              backgroundColor: "#141E3C",
              color: "#fff",
            }}
          >
            Login
          </Button> */}
          <Grid
            sx={{
              mt: 3
            }}
          >
            <SubmitButton
              title="Login"
              submit="submit"
              widthSize="100%"
              heightSize="58px"
              type="submit"
              handleSubmit=""
            />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}

export default LoginField;
