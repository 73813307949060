import React, { useCallback, useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { getSectionOrder } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { useDispatch, useSelector } from "react-redux";
import { bannerCreateAction } from "../../../actions/bannerAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import InputField from "../../customComponent/InputField";
import CloseIcon from "@mui/icons-material/Close";
import {
  addNewGalleryItemsAction,
  findGalleryItemsByIdAction,
  updateGalleryItemsByIdAction,
} from "../../../actions/LandingPageAction";
import { useParams } from "react-router-dom";

function EditGallery({ toggle }) {
  const dispatch = useDispatch();
  let { id } = useParams();

  const [formData, setFormData] = useState({
    title: "",
  });
  const [err, setErr] = useState({});

  let { updateGalleryItemsByIdSuccess } = useSelector((state) => {
    return state.updateGalleryItemsById;
  });

  let { findGalleryItemsByIdSuccess } = useSelector((state) => {
    return state.findGalleryItemsById;
  });

  // UseEffect to check is banner already upload

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (updateGalleryItemsByIdSuccess && successAlertMsgSuccess) {
      Swal.fire("created!", `gallery updated successfully.`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [updateGalleryItemsByIdSuccess]);

  useEffect(() => {
    if (findGalleryItemsByIdSuccess) {
      let result = findGalleryItemsByIdSuccess.data;
      setFormData({
        title: result.title,
        discription: result.description,
        selectedImage: result.gallery_images,
      });
    }
  }, [findGalleryItemsByIdSuccess]);

  useEffect(() => {
    dispatch(findGalleryItemsByIdAction(id));
  }, []);

  const handleImageUpload = (data) => {
    if (data.length <= 5) {
      for (let i = 0; i < data.length; i++) {
        const file = data[i];
        let docs = Array.from(data);

        setFormData((prev) => ({
          ...prev,
          ["uploadedBanner"]: docs,
        }));
      }
      setFormData((prev) => ({
        ...prev,
        ["selectedImage"]: [],
      }));
    } else {
      Swal.fire("Warning!", `Maximum upload 5`, "warning");
    }
  };

  //   Delete function

  const handleDelete = (index) => {
    const filteredData = formData?.uploadedBanner?.filter(
      (value, i) => i !== index
    );
    setFormData((prev) => ({
      ...prev,
      ["uploadedBanner"]: filteredData,
    }));
  };

  const handleSelectedDelete = (index) => {
    const filteredData = formData?.selectedImage?.filter(
      (value, i) => i !== index
    );
    setFormData((prev) => ({
      ...prev,
      ["selectedImage"]: filteredData,
    }));
  };

  //   Handle change

  const handleChange = (e) => {
    let { value, name } = e.target;
    console.log(name);
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErr((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validate = () => {
    let error = {};

    if (
      (!formData?.uploadedBanner && formData?.uploadedBanner?.length === 0) ||
      (!formData?.selectedImage && formData?.selectedImage?.length === 0)
    ) {
      error.uploadedBanner = "Please select atleast one image to upload";
    }

    if (!formData?.title) {
      error.title = "please enter a title";
    }

    if (!formData?.discription) {
      error.discription = "please enter a discription";
    }
    setErr(error);

    return Object.keys(error).length === 0;
  };

  const handleSubmit = () => {
    console.log(formData);
    let dataimagesArray = formData?.selectedImage?.map((item) => item.image);
    if (validate()) {
      const data = new FormData();
      formData?.uploadedBanner?.forEach((file, index) => {
        data.append("image", file);
      });
      data.append("title", formData?.title);
      data.append("id", id);
      data.append("selectedimage", dataimagesArray);
      data.append("discription", formData?.discription);
      dispatch(updateGalleryItemsByIdAction(data));
      dispatch(successTrueMsgAction(true));
    }
  };

  return (
    <>
      <MainContainer active={toggle}>
        <InfoHeader headTitle="Add Gallery" url="/landingPage" />
        {/* <Grid container spacing={2} sx={{ mt: 1 }}> */}
        <Box
          sx={{
            pt: "20px",
            "& input": {
              maxWidth: "calc(100% - 12px)",
            },
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            gap: "20px",
            "& .input-group": {
              mb: "0",
            },
            "& .input-group ~ div": {
              mt: "0",
            },
          }}
        >
          <Box item lg={4} xs={12}>
            <FormLabel
              sx={{
                width: "calc(100% - 28px)",
                height: "46px",
                border: "1px solid #C4C4C4",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                pl: "14px",
                pr: "14px",
              }}
              //   onChange={handleChange}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#ACB1C6",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                {"Select gallery images"}
              </Typography>
              <input
                type="file"
                hidden
                name="image"
                onChange={(e) => {
                  handleImageUpload(e.target.files);
                  // alert("clicked")
                  setErr((prev) => ({ ...prev, ["uploadedBanner"]: "" }));
                }}
                accept="image/jpeg, image/jpg, image/png, image/gif"
                multiple
              />
              <Box
                sx={{
                  cursor: "pointer",
                  padding: "8px 18px",
                  background: "#EBEBEB",
                  fontFamily: "Poppins",
                  color: "#010101",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                Browse
              </Box>
            </FormLabel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "24px",
                  color: Boolean(err?.uploadedBanner) ? "#FF2400" : "#656395",
                  textAlign: "left",
                  //   width: "100%",
                  fontWeight: 400,
                  fontfamily: "'Open Sans', sans-serif",
                }}
              >
                {Boolean(err?.uploadedBanner)
                  ? err?.uploadedBanner
                  : "You can select a minimum of 1 image and a maximum of 5 images"}
              </Typography>
            </Box>
            {formData?.uploadedBanner?.length > 0 ? (
              <Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#05050F",
                    fontWeight: 500,
                    fontFamily: "'Montserrat', sans-serif",
                    mt: 2,
                  }}
                >
                  Uploaded images
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Box>
          <Box item lg={4} xs={12}>
            <InputField
              label="Title"
              value={formData?.title}
              error={Boolean(err?.title)}
              name="title"
              helperText={err?.title}
              handleChange={(e) => handleChange(e)}
              required
              paramses
            ></InputField>
          </Box>
        </Box>
        {(formData?.uploadedBanner?.length > 0 ||
          formData?.selectedImage?.length > 0) && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              mt: "20px",
            }}
          >
            {formData?.uploadedBanner?.map((value, index) => (
              <Box
                sx={{
                  width: "120px",
                  height: "120px",
                  position: "relative",
                  overflow: "hidden",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={URL.createObjectURL(value)} alt={`item-${index}`} />
                <CloseIcon
                  onClick={() => handleDelete(index)}
                  sx={{
                    position: "absolute",
                    padding: "2px",
                    right: "5px",
                    top: "5px",
                    background: "#fff",
                    borderRadius: "50%",
                    width: "14px",
                    height: "14px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            ))}
            {formData?.selectedImage?.map((value, index) => (
              <Box
                sx={{
                  width: "120px",
                  height: "120px",
                  position: "relative",
                  overflow: "hidden",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={value?.image} alt={`item-${index}`} />
                <CloseIcon
                  onClick={() => handleSelectedDelete(index)}
                  sx={{
                    position: "absolute",
                    padding: "2px",
                    right: "5px",
                    top: "5px",
                    background: "#fff",
                    borderRadius: "50%",
                    width: "14px",
                    height: "14px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
        <TextField
          sx={{
            width: "100%",
            mt: "20px",
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6) !important",
            },
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              borderColor: "#C4C4C4 !important",
              borderWidth: "1px !important",
            },
            "& .Mui-error.MuiFormHelperText-root": {
              ml: "0 !important",
              color: "#FF2400",
            },
          }}
          label="Discription"
          placeholder="Discription"
          name="discription"
          value={formData?.discription || ""}
          error={Boolean(err?.discription)}
          helperText={err?.discription}
          onChange={(e) => handleChange(e)}
          multiline
          rows={2}
          maxRows={4}
        />
        <Button
          sx={{
            color: "white",
            backgroundColor: "#141E3C",
            height: "38px",
            width: "143px",
            fontWeight: 700,
            fontSize: "12px",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "rgba(20, 30, 60, 0.9)",
            },
            mt: 3,
          }}
          onClick={handleSubmit}
        >
          submit
        </Button>
        {/* </Grid> */}
      </MainContainer>
    </>
  );
}

export default EditGallery;
