// chat with cousilor
export const CHAT_WITH_COUNSELOR_REQUEST = "CHAT_WITH_COUNSELOR_REQUEST";
export const CHAT_WITH_COUNSELOR_SUCCESS = "CHAT_WITH_COUNSELOR_SUCCESS";
export const CHAT_WITH_COUNSELOR_ERR = "CHAT_WITH_COUNSELOR_ERR ";

// get chat with cousilor

export const GET_CHAT_WITH_COUNSELOR_REQUEST =
  "GET_CHAT_WITH_COUNSELOR_REQUEST";
export const GET_CHAT_WITH_COUNSELOR_SUCCESS =
  "GET_CHAT_WITH_COUNSELOR_SUCCESS";
export const GET_CHAT_WITH_COUNSELOR_ERR = "GET_CHAT_WITH_COUNSELOR_ERR ";

// chat with cousilor notification
export const CHAT_WITH_COUNSELOR_NOTIFICATION_REQUEST =
  "CHAT_WITH_COUNSELOR_NOTIFICATION_REQUEST";
export const CHAT_WITH_COUNSELOR_NOTIFICATION_SUCCESS =
  "CHAT_WITH_COUNSELOR_NOTIFICATION_SUCCESS";
export const CHAT_WITH_COUNSELOR_NOTIFICATION_ERR =
  "CHAT_WITH_COUNSELOR_NOTIFICATION_ERR ";

// chat with cousilor status update
export const CHAT_WITH_COUNSELOR_STATUS_UPDATE_REQUEST =
  "CHAT_WITH_COUNSELOR_STATUS_UPDATE_REQUEST";
export const CHAT_WITH_COUNSELOR_STATUS_UPDATE_SUCCESS =
  "CHAT_WITH_COUNSELOR_STATUS_UPDATE_SUCCESS";
export const CHAT_WITH_COUNSELOR_STATUS_UPDATE_ERR =
  "CHAT_WITH_COUNSELOR_STATUS_UPDATE_ERR ";

export const COUNSELOR_NOTIFICATION_UPDATE_REQUEST =
  "COUNSELOR_NOTIFICATION_UPDATE_REQUEST";
export const COUNSELOR_NOTIFICATION_UPDATE_SUCCESS =
  "COUNSELOR_NOTIFICATION_UPDATE_SUCCESS";
export const COUNSELOR_NOTIFICATION_UPDATE_ERR =
  "COUNSELOR_NOTIFICATION_UPDATE_ERR ";
