import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Paper,
  Alert,
  AlertTitle,
  Pagination,
  FormControl,
  Select,
  Dialog
} from "@mui/material";

import { InputLabel, makeStyles } from "@material-ui/core";

import { SlOptionsVertical } from "react-icons/sl";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Modal,
  TableContainer,
  Typography
} from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { roleDeleteAction } from "../../actions/roleMangmentAction";
import { useDispatch } from "react-redux";

import "./Table.css";
import {
  deleteEmployeeAction,
  updateEmployeeStatusAction
} from "../../actions/employeeManagmentAction";

import { MdNotInterested } from "react-icons/md";

import { BiSortAlt2 } from "react-icons/bi";

import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4
};

export const TableData = ({
  columns,
  data,
  allRoleFindSuccess,
  showRollNameColumn,
  showDescriptionNameColumn,
  showUpdatebyNameColumn,
  showLastUpdatebyNameColumn,
  component,
  showUrl,
  pagiantionEntry,
  paginationPageNumber,
  showStatusColumn,
  subMenu
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);
  // custom Hooks
  const {
    privilege,
    hasPrivilege,
    isUpdatePrevilage,
    isDeletePrevilage,
    isStatusChangePrevilage
  } = usePrivilegeCheck();

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [roleData, setRoleData] = useState(allRoleFindSuccess);
  const [popupData, setPopupData] = useState({});
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(paginationPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(pagiantionEntry);
  const [openAlert, setOpenAlert] = useState(false);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // navigate to Edit page
  const rollEditHandler = (tableId) => {
    navigate(`/Administration/editRole/${tableId}`);
  };

  const RoledeleteHandler = (role) => {
    setActionButtonShow(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(roleDeleteAction(role));
        Swal.fire("Deleted!", "Role has been deleted.", "success");
      }
    });
  };

  const handleOpen = (id) => {
    if (component == "employee") {
      navigate(`${showUrl}/${id}`);
    }

    if (component == "role") {
      navigate(`/Administration/role/details/${id}`);
    } else {
      let arrData;
      if (roleData.some((obj) => obj.id === id)) {
        arrData = roleData.find((obj) => obj.id === id);
      }

      setPopupData(arrData);
      setOpen(true);
    }
  };

  // pagination
  useEffect(() => {
    setRowsPerPage(pagiantionEntry);
    setPage(paginationPageNumber);
  }, [data, paginationPageNumber, pagiantionEntry]);

  // Event handler for changing page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const maxPage = Math.ceil(data.length / pagiantionEntry);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [data, pagiantionEntry, page]);

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  const handleStatusChange = (value, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      dispatch(updateEmployeeStatusAction(id, value));
      setStatus(value);
    } else {
      setOpenAlert(true);
    }
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  const handleClose = () => setOpen(false);

  // check handler

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    // setIdsFunction(updatedSelectedRows);
  };

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      // selectBoxIdPassHandler(Ids);
    }
  };
  // if (!hasPrivilege("isadministrationView") && privilege !== null) {
  //   return (
  //     <Alert severity="error">
  //       <AlertTitle>warning</AlertTitle>
  //       You don't have permission to access — <strong>this page.!</strong>
  //     </Alert>
  //   );
  // }

  const formatedDate = (name) => {
    let date = new Date(name);
    return date ? date.toDateString() : name;
  };

  return sortedData.length > 0 ? (
    <>
      <Typography
        sx={{
          backgroundColor: "#fff",
          padding: "5px",
          paddingRight: "0px",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          fontSize: "16px",
          fontWeight: "400"
        }}
      >
        Showing{" "}
        <Typography
          component={"span"}
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingLeft: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            // lineHeight: "24px",
            fontWeight: "700"
          }}
        >
          {
            sortedData?.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            ).length
          }{" "}
          Result
        </Typography>
      </Typography>
      <Box
        sx={{
          overflowX: "scroll"
        }}
      >
        <Table
          className="administratTable"
          sx={{
            minWidth: "1200px"
          }}
        >
          <TableHead>
            <TableRow className="administratTableCell">
              <TableCell>
                <Checkbox
                  checked={selectedRows.length === sortedData.length}
                  onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell
                    key={column.field}
                    className="administratTableCell administratCommon"
                  >
                    <TableSortLabel
                      className="administratHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, indx) => (
                <TableRow className="administratTable" key={indx}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(item.id)}
                      onChange={() => handleSelectRow(item.id)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>

                  <TableCell>{item.sl}</TableCell>
                  {component == "employee" && (
                    <TableCell>{item.employeeId}</TableCell>
                  )}

                  {!showRollNameColumn && <TableCell>{item.name}</TableCell>}

                  {!showDescriptionNameColumn && (
                    <TableCell>{item.description}</TableCell>
                  )}
                  {component == "role" && <TableCell>{item.bulkID}</TableCell>}

                  {!showUpdatebyNameColumn && (
                    <TableCell>{item.updatedby}</TableCell>
                  )}

                  {!showLastUpdatebyNameColumn && (
                    <TableCell>
                      {component === "role"
                        ? formatedDate(item.lastupdateby)
                        : item.lastupdateby}
                    </TableCell>
                  )}

                  {component === "employee" && item?.branch ? (
                    <TableCell>{item.branch.join(",")}</TableCell>
                  ) : (
                    ""
                  )}

                  {!showStatusColumn && component === "employee" && (
                    <TableCell>
                      <FormControl
                        fullWidth
                        sx={{
                          // mt: 1,
                          bgcolor:
                            item.status === "Active" ? "#228200" : "#E52900",
                          borderRadius: "8px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          "& .MuiSelect-icon": {
                            top: "3px"
                          },
                          "&.MuiFormControl-root": {
                            width: "106px"
                          }
                        }}
                      >
                        <InputLabel
                          htmlFor="demo-simple-select-placeholder"
                          style={{
                            // transform: "translate(10px, 10px)",
                            // pointerEvents: "none",
                            // zIndex: 1,
                            transition: "transform 0.25s, font-size 0.25s",
                            // margin: "-6px",
                            color: "white",
                            fontWeight: 400,
                            paddingRight: "20px",
                            border: "none !important"
                          }}
                        >
                          {item.status ? item.status : "placeholder"}
                        </InputLabel>
                        <Select
                          value={item.status}
                          onChange={(e) =>
                            handleStatusChange(e.target.value, item.id)
                          }
                          inputProps={{
                            // name: {item.coloum3},
                            id: "demo-simple-select-placeholder"
                          }}
                          sx={{
                            position: "absolute",
                            top: "0px",
                            right: "0",
                            "& .MuiSelect-select": {
                              padding: "0"
                            },
                            "& .MuiOutlinedInput-input": {
                              opacity: "0"
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              outline: "none !important",
                              border: "none !important"
                            }
                          }}
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  )}

                  <TableCell>
                    <SlOptionsVertical
                      id="demo-positioned-button"
                      aria-controls={
                        openActionButton ? "demo-positioned-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openActionButton ? "true" : undefined}
                      onClick={(e) => {
                        handleClickAction(e, item.id);
                      }}
                      variant="outlined"
                      style={{ marginLeft: "10px", color: "#F80B7A" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter />
        </Table>
      </Box>

      <Pagination
        sx={{
          mt: "28px",
          "& .Mui-selected": {
            backgroundColor: "#f81b82 !important",
            color: "#fff"
          },
          "& .MuiPagination-ul": {
            justifyContent: "end"
          }
        }}
        count={Math.ceil(data.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />

      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left"
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto"
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          {component !== "employee" && isUpdatePrevilage(subMenu) ? (
            <MenuItem>
              <TbEdit
                style={{
                  fontSize: "lg",
                  width: "20px",
                  height: "19px"
                }}
                onClick={() => rollEditHandler(tableId)}
                className="icons"
              />
            </MenuItem>
          ) : (
            ""
          )}

          <MenuItem>
            <AiOutlineEye
              onClick={() => handleOpen(tableId)}
              className="icons"
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px"
              }}
            />
          </MenuItem>

          {isDeletePrevilage(subMenu) && (
            <MenuItem>
              {component !== "employee" ? (
                <RiDeleteBin6Line
                  onClick={() => RoledeleteHandler(tableId)}
                  className="icons"
                  style={{
                    fontSize: "lg",
                    width: "20px",
                    height: "19px"
                  }}
                />
              ) : (
                <RiDeleteBin6Line
                  onClick={() => {
                    setActionButtonShow(false);
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!"
                    }).then((result) => {
                      if (result.isConfirmed) {
                        dispatch(deleteEmployeeAction(tableId));
                        Swal.fire(
                          "Deleted!",
                          "Role has been deleted.",
                          "success"
                        );
                      }
                    });
                  }}
                  style={{
                    fontSize: "lg",
                    width: "20px",
                    height: "19px"
                  }}
                  className="icons"
                />
              )}
            </MenuItem>
          )}
        </Box>
      </Menu>
      {/* {/*TABLE ACTION MENU END */}

      {/*ROLE DETAILS POPUP START  */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Role Name :{popupData.roleName}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            variant="h6"
            component="h2"
            style={{ marginBottom: "20px" }}
          >
            Description : {popupData.roleDescription}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ height: "500px", overflow: "scroll" }}
          >
            <Grid item lg={12} xs={12}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#F9F9FB" }}>
                      <TableRow>
                        <TableCell>Category Name</TableCell>
                        <TableCell>Update</TableCell>
                        <TableCell>View</TableCell>
                        <TableCell>Delete</TableCell>
                        <TableCell>All</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Lead Managment</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isLeadUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isLeadView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isLeadDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isLeadAll}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Administration</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isadministrationUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isadministrationView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isadministrationDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isadministrationAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Application</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isApplicationUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isApplicationView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isApplicationDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isApplicationAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>University</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isUniversityUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isUniversityView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isUniversityDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isUniversityAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Course</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isCoursUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isCoursView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isCoursDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isCoursAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ticket</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isTicketUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isTicketView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isTicketDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isTicketAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>enquiry</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isEnquiryUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isEnquiryView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isEnquiryDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isEnquiryAll}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Website</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isWebsitUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isWebsitView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isWebsitDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isWebsitAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>HR Managment</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isHRmanagmentUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isHRmanagmentView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isHRmanagmentDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isHRmanagmentAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>IELTS</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isIeltsUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isIeltsView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isIeltsDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isIeltsAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Accounts</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isAccountsUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isAccountsView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isAccountsDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isAccountsAll}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Settings</TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isSettingsUpdate}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isSettingsView}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isSettingsDelete}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            sx={{
                              color: "#f81b82",
                              "&.Mui-checked": {
                                color: "#f81b82",
                              },
                            }}
                            checked={popupData.isSettingsAll}
                          />
                        </TableCell>
                      </TableRow>
                      
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Modal> */}
      {/*ROLE DETAILS POPUP END*/}

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Close
            sx={{
              cursor: "pointer"
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700"
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px"
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px"
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  ) : (
    <TableNoItemComponent />
  );
};
