import React, { useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Box, Typography } from "@mui/material";
import ApplicationDocumentsComponent from "./ApplicationDocumentsComponent";
import { findApplicationDetailsAction } from "../../../actions/aplicationActions/applicationsAction";
import { SubmitButton } from "../../customComponent/Buttons";

function ApplicationDetails(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id,leadId} = useParams();

  const [applicationData, setApplicationData] = useState({});
  const [visaDetails, setVisaDetails] = useState({});
  const [registrationFeeDetails, setRegistrationFeeDetails] = useState({});
  const [docs, setDocs] = useState([]);
  const [assignedOfficer, setAssinedOfficer] = useState({
    Interviewer: "",
    visaOfficer: "",
    applicationHandler: "",
    universityHandler: "",
  });

  let { applicationDetailsFindSuccess } = useSelector((state) => {
    return state.findApplicationDetails;
  });

  // let docs = [
  //   {
  //     id: 2,
  //     name: "Offer Letter",
  //     url:
  //       "https://gsl-dev2.s3.ap-south-1.amazonaws.com/chatdocument/chat-doc-Employee_Managment.pdf",
  //   },
  //   {
  //     id: 3,
  //     name: "Register Document",
  //     url:
  //       "https://gsl-dev2.s3.ap-south-1.amazonaws.com/chatdocument/chat-doc-Employee_Managment.pdf",
  //   },

  // ];

  useEffect(() => {
    dispatch(findApplicationDetailsAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      applicationDetailsFindSuccess &&
      applicationDetailsFindSuccess != undefined
    ) {
      setApplicationData(applicationDetailsFindSuccess?.applicationDetails);
      setRegistrationFeeDetails(
        applicationDetailsFindSuccess?.registrationFeeDetails
      );
      setDocs([
        {
          id: 2,
          name: "Offer Letter",
          type:
            applicationDetailsFindSuccess?.applicationDetails?.OfferLetterType,
          url: applicationDetailsFindSuccess?.applicationDetails?.offerLetter,
        },
        {
          id: 2,
          name: "RegistrationFee Slip",
          type:
            applicationDetailsFindSuccess?.registrationFeeDetails?.status ==
            "Approved"
              ? "Paid"
              : "Unpaid",
          url: applicationDetailsFindSuccess?.registrationFeeDetails?.reciept,
        },
      ]);

      // Interviewer

      const interviewerId = Number(
        applicationDetailsFindSuccess?.applicationDetails?.interviewerId
      );

      const interviewer =
        interviewerId &&
        applicationDetailsFindSuccess?.employees?.find(
          (value) => value.id === interviewerId
        );

      console.log(
        interviewerId,
        "interviewerIdinterviewerIdinterviewerId",
        interviewer,
        "interviewerinterviewer"
      );
      // Visa officer

      const visaOfficerId = Number(
        applicationDetailsFindSuccess?.applicationDetails?.visaOfficerId
      );

      const visaOfficer =
        visaOfficerId &&
        applicationDetailsFindSuccess?.employees?.find(
          (value) => value.id === visaOfficerId
        );

      // Application handler

      const applicationHandlerId = Number(
        applicationDetailsFindSuccess?.applicationDetails?.lead
          ?.applicationHandler
      );

      const applicationHandler =
        applicationHandlerId &&
        applicationDetailsFindSuccess?.employees?.find(
          (value) => value.id === applicationHandlerId
        );

      // UniversityHandler

      const universityHandlerId = Number(
        applicationDetailsFindSuccess?.applicationDetails?.universityHandlerId
      );

      const universityHandler =
        universityHandlerId &&
        applicationDetailsFindSuccess?.employees?.find(
          (value) => value.id === universityHandlerId
        );
      setAssinedOfficer({
        ...assignedOfficer,
        Interviewer: interviewer.firstName,
        visaOfficer: visaOfficer.firstName,
        applicationHandler: applicationHandler.firstName,
        universityHandler: universityHandler.firstName,
      });

      setVisaDetails({
        ...visaDetails,
        appliedDate:
          applicationDetailsFindSuccess?.applicationDetails?.lead
            ?.lead_visa_summaries[0]?.appliedDate,

        cardDetail:
          applicationDetailsFindSuccess?.applicationDetails?.lead
            ?.lead_visa_summaries[0]?.cardDetail,

        paymentDate:
          applicationDetailsFindSuccess?.applicationDetails?.lead
            ?.lead_visa_summaries[0]?.paymentDate,

        receptNo:
          applicationDetailsFindSuccess?.applicationDetails?.lead
            ?.lead_visa_summaries[0]?.receptNo,

        visaReceivedDate:applicationDetailsFindSuccess?.applicationDetails?.visaReceivedDate?.split("T")[0]
      });
    }
  }, [applicationDetailsFindSuccess]);

  console.log(
    applicationDetailsFindSuccess,
    "applicationDetailsFindSuccessapplicationDetailsFindSuccess"
  );

  return (
    <MainContainer active={props.toggle}>
      <Box
        sx={{
          width: "auto",
          height: "50px",
          display: "flex",
          alignItems: "center",
          // paddingLeft: "16px",
          backgroundColor: "white",
          borderRadius: "5px",
          m: 1,
        }}
      >
        <BiLeftArrowAlt
          style={{
            fontSize: "600px",
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/Leads/details/${leadId}/${"Deals"}/${"Deals"}`)}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            ml: 1,
            fontSize: "22px",
          }}
        >
          Application Details
        </Typography>
        <Box sx={{ ml: "auto" }}>
          <SubmitButton
            title="Payment history"
            submit="click"
            widthSize="150px"
            heightSize="40px"
            type="click"
            handleSubmit={() =>
              navigate(`/paymentHistory/${id}/${applicationData?.lead?.id}`)
            }
          />
        </Box>
      </Box>

      <Box
        sx={{
          filter: "drop-shadow(0px 0px 10px #00000012)",
          backgroundColor: "#ffffff",
          padding: "25px",
          maxWidth: "1850px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          Basic Details
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            mt: "20px",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                CRM ID
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.applicationId}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Application ID
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.givenApplicationId?applicationData?.givenApplicationId:"-"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Application Method
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.applyMethod}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
               Application Started Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.updatedAt?.split("T")[0]}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Status
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.methodStatus}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "1px",
              backgroundColor: "#ECECEC",
            }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Lead Name
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.lead?.firstName}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                University
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.universityName
                  ? applicationData?.universityName
                  : "Not Assigned"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Student ID
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.universityGivenId
                  ? applicationData?.universityGivenId
                  : "Not Assigned"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Course
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {applicationData?.courseName}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "1px",
              backgroundColor: "#ECECEC",
            }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Interviewer
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {assignedOfficer?.Interviewer
                  ? assignedOfficer?.Interviewer
                  : "Not Assigned"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                VISA Officer
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {assignedOfficer?.visaOfficer
                  ? assignedOfficer?.visaOfficer
                  : "Not Assigned"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Application Handler
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {assignedOfficer?.applicationHandler
                  ? assignedOfficer?.applicationHandler
                  : "Not Assigned"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                University Handler
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {assignedOfficer?.universityHandler
                  ? assignedOfficer?.universityHandler
                  : "Not Assigned"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          filter: "drop-shadow(0px 0px 10px #00000012)",
          backgroundColor: "#ffffff",
          padding: "25px",
          maxWidth: "1850px",
          my: "30px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          Visa Details
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "40px",
            mt: "20px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Applied Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.appliedDate}
              </Typography>
            </Box>


            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Visa Received Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.visaReceivedDate}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Card Detail{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.cardDetail}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Visa Receipt No :
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.receptNo}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Registration amount
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {registrationFeeDetails?.status == "Approved"
                  ? "Paid"
                  : "Unpaid"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* <Box
        sx={{
          filter: "drop-shadow(0px 0px 10px #00000012)",
          backgroundColor: "#ffffff",
          padding: "25px",
          maxWidth: "1850px",
          my: "30px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          Payment Details
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "40px",
            mt: "20px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Applied Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.appliedDate}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Card Detail{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.cardDetail}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Visa Receipt No :
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                {visaDetails?.receptNo}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Registration amount
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                Paid
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "1px",
              backgroundColor: "#ECECEC",
            }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "fit-content",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Tuition Fund
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                Paid
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#010101",
                }}
              >
                Source funding
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#848484",
                }}
              >
                Lorem Ipsum
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box> */}

      <Box
        sx={{
          filter: "drop-shadow(0px 0px 10px #00000012)",
          backgroundColor: "#ffffff",
          padding: "25px",
          maxWidth: "1850px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          Documents
        </Typography>

        <Box
          sx={{
            mt: "25px",
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr 1fr",
              md: "1fr 1fr 1fr",
              lg: "1fr 1fr 1fr 1fr",
            },
            columnGap: "12px",
            rowGap: "24px",
          }}
        >
          {docs?.map((item) => (
            <ApplicationDocumentsComponent item={item} />
          ))}
        </Box>
      </Box>

      {applicationData?.declarationDocument && (
        <Box
          sx={{
            filter: "drop-shadow(0px 0px 10px #00000012)",
            backgroundColor: "#ffffff",
            padding: "25px",
            maxWidth: "1850px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontFamily: "Montserrat,sans-serif",
              fontSize: "20px",
              lineHeight: "24px",
            }}
          >
            Declaration document
          </Typography>

          <Box
            sx={{
              mt: "25px",
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr 1fr",
                md: "1fr 1fr 1fr",
                lg: "1fr 1fr 1fr 1fr",
              },
              columnGap: "12px",
              rowGap: "24px",
            }}
          >
            <ApplicationDocumentsComponent
              item={{
                name: "Declaration",
                url: applicationData?.declarationDocument,
              }}
            />
          </Box>
        </Box>
      )}
    </MainContainer>
  );
}

export default ApplicationDetails;
