import axios from "axios";
import {
  PARTNER_APPROVAL_STATUS_UPDATE_ERR,
  PARTNER_APPROVAL_STATUS_UPDATE_REQUEST,
  PARTNER_APPROVAL_STATUS_UPDATE_SUCCESS,
  PARTNER_ENQUIRY_FIND_ERR,
  PARTNER_ENQUIRY_FIND_REQUEST,
  PARTNER_ENQUIRY_FIND_SUCCESS,
} from "../../constants/partnerConstant";

// findEnquiriedPartnerAction
export const findEnquiriedPartnerAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PARTNER_ENQUIRY_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/partner/findPartnerEnquiry",
      config
    );

    dispatch({ type: PARTNER_ENQUIRY_FIND_SUCCESS, payload: data });

    // let isUserExist = localStorage.getItem("loginInfo")
    //   ? JSON.parse(localStorage.getItem("loginInfo"))
    //   : null;
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: PARTNER_ENQUIRY_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// partnerApprovalStatusUpdateAction
export const partnerApprovalStatusUpdateAction =
  (id, status) => async (dispatch, getState) => {
    try {
      dispatch({ type: PARTNER_APPROVAL_STATUS_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/partner/updatePartnerStatusEnquiry?id=${id}`,
        { status },
        config
      );

      dispatch({ type: PARTNER_APPROVAL_STATUS_UPDATE_SUCCESS, payload: data });

      // let isUserExist = localStorage.getItem("loginInfo")
      //   ? JSON.parse(localStorage.getItem("loginInfo"))
      //   : null;
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: PARTNER_APPROVAL_STATUS_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };


