import {
  COUNTRY_CURRENCY_FIND_ERR,
  COUNTRY_CURRENCY_FIND_REQUEST,
  COUNTRY_CURRENCY_FIND_SUCCESS,
  COUNTRY_FETCH_UNIQUE_TOKEN_ERR,
  COUNTRY_FETCH_UNIQUE_TOKEN_REQUEST,
  COUNTRY_FETCH_UNIQUE_TOKEN_SUCCESS,
  COUNTRY_MASTER_ADD_ERR,
  COUNTRY_MASTER_ADD_REQUEST,
  COUNTRY_MASTER_ADD_SUCCESS,
  COUNTRY_MASTER_DELETE_ERR,
  COUNTRY_MASTER_DELETE_REQUEST,
  COUNTRY_MASTER_DELETE_SUCCESS,
  COUNTRY_MASTER_EDIT_ERR,
  COUNTRY_MASTER_EDIT_FIND_ERR,
  COUNTRY_MASTER_EDIT_FIND_REQUEST,
  COUNTRY_MASTER_EDIT_FIND_SUCCESS,
  COUNTRY_MASTER_EDIT_REQUEST,
  COUNTRY_MASTER_EDIT_SUCCESS,
  COUNTRY_MASTER_FIND_ERR,
  COUNTRY_MASTER_FIND_REQUEST,
  COUNTRY_MASTER_FIND_SUCCESS,
} from "../constants/countryMasterConstant";

// countryMasterAddReducer
export const countryMasterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_MASTER_ADD_REQUEST:
      return {
        ...state,
        countryAddLoading: true,
      };
    case COUNTRY_MASTER_ADD_SUCCESS:
      return {
        ...state,
        countryAddLoading: false,
        countryAddSuccess: action.payload,
      };
    case COUNTRY_MASTER_ADD_ERR:
      return {
        ...state,
        countryAddLoading: false,
        countryAddErr: action.payload,
      };
    default:
      return state;
  }
};

// findcountryMasterAddReducer
export const findcountryMasterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_MASTER_FIND_REQUEST:
      return {
        ...state,
        countryFindLoading: true,
      };
    case COUNTRY_MASTER_FIND_SUCCESS:
      return {
        ...state,
        countryFindLoading: false,
        countryFindSuccess: action.payload,
      };
    case COUNTRY_MASTER_FIND_ERR:
      return {
        ...state,
        countryFindLoading: false,
        countryFindErr: action.payload,
      };
    default:
      return state;
  }
};

// editCountryFormReducer
export const editCountryFormReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_MASTER_EDIT_FIND_REQUEST:
      return {
        ...state,
        countryEditFindLoading: true,
      };
    case COUNTRY_MASTER_EDIT_FIND_SUCCESS:
      return {
        ...state,
        countryEditFindLoading: false,
        countryEditFindSuccess: action.payload,
      };
    case COUNTRY_MASTER_EDIT_FIND_ERR:
      return {
        ...state,
        countryEditFindLoading: false,
        countryEditFindErr: action.payload,
      };
    default:
      return state;
  }
};

// fetchCountryUniqueTokenReducer
export const fetchCountryUniqueTokenReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_FETCH_UNIQUE_TOKEN_REQUEST:
      return {
        ...state,
        countryUniqueFindLoading: true,
      };
    case COUNTRY_FETCH_UNIQUE_TOKEN_SUCCESS:
      return {
        ...state,
        countryUniqueFindLoading: false,
        countryUniqueFindSuccess: action.payload,
      };
    case COUNTRY_FETCH_UNIQUE_TOKEN_ERR:
      return {
        ...state,
        countryUniqueFindLoading: false,
        countryUniqueFindErr: action.payload,
      };
    default:
      return state;
  }
};


// CountryMasterDeleteReducer
export const CountryMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_MASTER_DELETE_REQUEST:
      return {
        ...state,
        countryDeleteLoading: true,
      };
    case COUNTRY_MASTER_DELETE_SUCCESS:
      return {
        ...state,
        countryDeleteLoading: false,
        countryDeleteSuccess: action.payload,
      };
    case COUNTRY_MASTER_DELETE_ERR:
      return {
        ...state,
        countryDeleteLoading: false,
        countryDeleteErr: action.payload,
      };
    default:
      return state;
  }
};
// countryMasterEditReducer
export const countryMasterEditReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_MASTER_EDIT_REQUEST:
      return {
        ...state,
        countryEditedLoading: true,
      };
    case COUNTRY_MASTER_EDIT_SUCCESS:
      return {
        ...state,
        countryEditedLoading: false,
        countryEditedSuccess: action.payload,
      };
    case COUNTRY_MASTER_EDIT_ERR:
      return {
        ...state,
        countryEditedLoading: false,
        countryEditedErr: action.payload,
      };
    default:
      return state;
  }
};


// countryCurrencyFindReducer

export const countryCurrencyFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_CURRENCY_FIND_REQUEST:
      return {
        ...state,
        countryCurrencyFindLoading: true,
      };
    case COUNTRY_CURRENCY_FIND_SUCCESS:
      return {
        ...state,
        countryCurrencyFindLoading: false,
        countryCurrencyFindSuccess: action.payload,
      };
    case COUNTRY_CURRENCY_FIND_ERR:
      return {
        ...state,
        countryCurrencyFindLoading: false,
        countryCurrencyFindErr: action.payload,
      };
    default:
      return state;
  }
};
