import {
  IELTS_SETTINGS_CONTACT_DELETE_ERR,
  IELTS_SETTINGS_CONTACT_DELETE_REQUEST,
  IELTS_SETTINGS_CONTACT_DELETE_SUCCESS,
  IELTS_SETTINGS_CONTACT_FIND_BY_ID_ERR,
  IELTS_SETTINGS_CONTACT_FIND_BY_ID_REQUEST,
  IELTS_SETTINGS_CONTACT_FIND_BY_ID_SUCCESS,
  IELTS_SETTINGS_CONTACT_FIND_ERR,
  IELTS_SETTINGS_CONTACT_FIND_REQUEST,
  IELTS_SETTINGS_CONTACT_FIND_SUCCESS,
  IELTS_SETTINGS_FAQ_CREATE_ERR,
  IELTS_SETTINGS_FAQ_CREATE_REQUEST,
  IELTS_SETTINGS_FAQ_CREATE_SUCCESS,
  IELTS_SETTINGS_FAQ_DELETE_ERR,
  IELTS_SETTINGS_FAQ_DELETE_REQUEST,
  IELTS_SETTINGS_FAQ_DELETE_SUCCESS,
  IELTS_SETTINGS_FAQ_FIND_BY_ID_ERR,
  IELTS_SETTINGS_FAQ_FIND_BY_ID_REQUEST,
  IELTS_SETTINGS_FAQ_FIND_BY_ID_SUCCESS,
  IELTS_SETTINGS_FAQ_FIND_ERR,
  IELTS_SETTINGS_FAQ_FIND_REQUEST,
  IELTS_SETTINGS_FAQ_FIND_SUCCESS,
  IELTS_SETTINGS_FAQ_STATUS_CHANGE_ERR,
  IELTS_SETTINGS_FAQ_STATUS_CHANGE_REQUEST,
  IELTS_SETTINGS_FAQ_STATUS_CHANGE_SUCCESS,
  IELTS_SETTINGS_FAQ_UPDATE_ERR,
  IELTS_SETTINGS_FAQ_UPDATE_REQUEST,
  IELTS_SETTINGS_FAQ_UPDATE_SUCCESS,
  IELTS_SETTINGS_ZOOMCONFIG_CREATE_ERR,
  IELTS_SETTINGS_ZOOMCONFIG_CREATE_REQUEST,
  IELTS_SETTINGS_ZOOMCONFIG_CREATE_SUCCESS,
  IELTS_SETTINGS_ZOOMCONFIG_FINDONE_ERR,
  IELTS_SETTINGS_ZOOMCONFIG_FINDONE_REQUEST,
  IELTS_SETTINGS_ZOOMCONFIG_FINDONE_SUCCESS,
  IELTS_SETTINGS_ZOOMCONFIG_FIND_ERR,
  IELTS_SETTINGS_ZOOMCONFIG_FIND_REQUEST,
  IELTS_SETTINGS_ZOOMCONFIG_FIND_SUCCESS,
} from "../../constants/Ielts/ieltsSettingsConstants";


// SETTINGS FAQ


// create faq reducer
export const ieltsSettingsFaqCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_FAQ_CREATE_REQUEST:
      return {
        ...state,
        ieltsSettingsFaqCreateLoading: true,
      };
    case IELTS_SETTINGS_FAQ_CREATE_SUCCESS:
      return {
        ...state,
        ieltsSettingsFaqCreateLoading: false,
        ieltsSettingsFaqCreateSuccess: action.payload,
      };
    case IELTS_SETTINGS_FAQ_CREATE_ERR:
      return {
        ...state,
        ieltsSettingsFaqCreateLoading: false,
        ieltsSettingsFaqCreateerror: action.payload,
      };
    default:
      return state;
  }
};

// find faq reducer
export const ieltsSettingsFaqFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_FAQ_FIND_REQUEST:
      return {
        ...state,
        ieltsSettingsFaqFindLoading: true,
      };
    case IELTS_SETTINGS_FAQ_FIND_SUCCESS:
      return {
        ...state,
        ieltsSettingsFaqFindLoading: false,
        ieltsSettingsFaqFindSuccess: action.payload,
      };
    case IELTS_SETTINGS_FAQ_FIND_ERR:
      return {
        ...state,
        ieltsSettingsFaqFindLoading: false,
        ieltsSettingsFaqFinderror: action.payload,
      };
    default:
      return state;
  }
};

// faq find by id
export const ieltsSettingsFaqFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_FAQ_FIND_BY_ID_REQUEST:
      return {
        ...state,
        ieltsSettingsFaqFindByIdLoading: true,
      };
    case IELTS_SETTINGS_FAQ_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        ieltsSettingsFaqFindByIdLoading: false,
        ieltsSettingsFaqFindByIdSuccess: action.payload,
      };
    case IELTS_SETTINGS_FAQ_FIND_BY_ID_ERR:
      return {
        ...state,
        ieltsSettingsFaqFindByIdLoading: false,
        ieltsSettingsFaqFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// update faq
export const ieltsSettingsFaqUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_FAQ_UPDATE_REQUEST:
      return {
        ...state,
        ieltsSettingsFaqUpdateLoading: true,
      };
    case IELTS_SETTINGS_FAQ_UPDATE_SUCCESS:
      return {
        ...state,
        ieltsSettingsFaqUpdateLoading: false,
        ieltsSettingsFaqUpdateSuccess: action.payload,
      };
    case IELTS_SETTINGS_FAQ_UPDATE_ERR:
      return {
        ...state,
        ieltsSettingsFaqUpdateLoading: false,
        ieltsSettingsFaqUpdateerror: action.payload,
      };
    default:
      return state;
  }
};
 
// delete faq
export const ieltsSettingsFaqDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_FAQ_DELETE_REQUEST:
      return {
        ...state,
        ieltsSettingsFaqDeleteLoading: true,
      };
    case IELTS_SETTINGS_FAQ_DELETE_SUCCESS:
      return {
        ...state,
        ieltsSettingsFaqDeleteLoading: false,
        ieltsSettingsFaqDeleteSuccess: action.payload,
      };
    case IELTS_SETTINGS_FAQ_DELETE_ERR:
      return {
        ...state,
        ieltsSettingsFaqDeleteLoading: false,
        ieltsSettingsFaqDeleteerror: action.payload,
      };
    default:
      return state;
  }
};

// faq status Change
export const ieltsSettingsFaqChangeStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_FAQ_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        ieltsSettingsFaqChangeStatusLoading: true,
      };
    case IELTS_SETTINGS_FAQ_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        ieltsSettingsFaqChangeStatusLoading: false,
        ieltsSettingsFaqChangeStatusSuccess: action.payload,
      };
    case IELTS_SETTINGS_FAQ_STATUS_CHANGE_ERR:
      return {
        ...state,
        ieltsSettingsFaqChangeStatusLoading: false,
        ieltsSettingsFaqChangeStatuserror: action.payload,
      };
    default:
      return state;
  }
};



// SETTINGS CONTACT US

// find contact
export const ieltsSettingsContactFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_CONTACT_FIND_REQUEST:
      return {
        ...state,
        ieltsSettingsContactFindLoading: true,
      };
    case IELTS_SETTINGS_CONTACT_FIND_SUCCESS:
      return {
        ...state,
        ieltsSettingsContactFindLoading: false,
        ieltsSettingsContactFindSuccess: action.payload,
      };
    case IELTS_SETTINGS_CONTACT_FIND_ERR:
      return {
        ...state,
        ieltsSettingsContactFindLoading: false,
        ieltsSettingsContactFinderror: action.payload,
      };
    default:
      return state;
  }
};

// find contact by id
export const ieltsSettingsContactFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_CONTACT_FIND_BY_ID_REQUEST:
      return {
        ...state,
        ieltsSettingsContactFindByIdLoading: true,
      };
    case IELTS_SETTINGS_CONTACT_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        ieltsSettingsContactFindByIdLoading: false,
        ieltsSettingsContactFindByIdSuccess: action.payload,
      };
    case IELTS_SETTINGS_CONTACT_FIND_BY_ID_ERR:
      return {
        ...state,
        ieltsSettingsContactFindByIdLoading: false,
        ieltsSettingsContactFindByIderror: action.payload,
      };
    default:
      return state;
  }
};

// delete contact by id
export const ieltsSettingsContactDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_CONTACT_DELETE_REQUEST:
      return {
        ...state,
        ieltsSettingsContactDeleteLoading: true,
      };
    case IELTS_SETTINGS_CONTACT_DELETE_SUCCESS:
      return {
        ...state,
        ieltsSettingsContactDeleteLoading: false,
        ieltsSettingsContactDeleteSuccess: action.payload,
      };
    case IELTS_SETTINGS_CONTACT_DELETE_ERR:
      return {
        ...state,
        ieltsSettingsContactDeleteLoading: false,
        ieltsSettingsContactDeleteerror: action.payload,
      };
    default:
      return state;
  }
};



// Zooom config reducer

export const ieltsSettingsZoomConfigCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_ZOOMCONFIG_CREATE_REQUEST:
      return {
        ...state,
        ieltsSettingsZoomConfigCreateLoading: true,
      };
    case IELTS_SETTINGS_ZOOMCONFIG_CREATE_SUCCESS:
      return {
        ...state,
        ieltsSettingsZoomConfigCreateLoading: false,
        ieltsSettingsZoomConfigCreateSuccess: action.payload,
      };
    case IELTS_SETTINGS_ZOOMCONFIG_CREATE_ERR:
      return {
        ...state,
        ieltsSettingsZoomConfigCreateLoading: false,
        ieltsSettingsZoomConfigCreateerror: action.payload,
      };
    default:
      return state;
  }
};


// Zooom config find reducer

export const ieltsSettingsZoomConfigFindReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_ZOOMCONFIG_FIND_REQUEST:
      return {
        ...state,
        ieltsSettingsZoomConfigFindLoading: true,
      };
    case IELTS_SETTINGS_ZOOMCONFIG_FIND_SUCCESS:
      return {
        ...state,
        ieltsSettingsZoomConfigFindLoading: false,
        ieltsSettingsZoomConfigFindSuccess: action.payload,
      };
    case IELTS_SETTINGS_ZOOMCONFIG_FIND_ERR:
      return {
        ...state,
        ieltsSettingsZoomConfigFindLoading: false,
        ieltsSettingsZoomConfigFinderror: action.payload,
      };
    default:
      return state;
  }
};




// Zooom config findOne reducer

export const ieltsSettingsZoomConfigFindOneReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_SETTINGS_ZOOMCONFIG_FINDONE_REQUEST:
      return {
        ...state,
        ieltsSettingsZoomConfigFindOneLoading: true,
      };
    case IELTS_SETTINGS_ZOOMCONFIG_FINDONE_SUCCESS:
      return {
        ...state,
        ieltsSettingsZoomConfigFindOneLoading: false,
        ieltsSettingsZoomConfigFindOneSuccess: action.payload,
      };
    case IELTS_SETTINGS_ZOOMCONFIG_FINDONE_ERR:
      return {
        ...state,
        ieltsSettingsZoomConfigFindOneLoading: false,
        ieltsSettingsZoomConfigFindOneerror: action.payload,
      };
    default:
      return state;
  }
};
