export const PARTNER_ENQUIRY_FIND_REQUEST = "PARTNER_ENQUIRY_FIND_REQUEST";
export const PARTNER_ENQUIRY_FIND_SUCCESS = "PARTNER_ENQUIRY_FIND_SUCCESS";
export const PARTNER_ENQUIRY_FIND_ERR = "PARTNER_ENQUIRY_FIND_ERR";

export const PARTNER_APPROVAL_STATUS_UPDATE_REQUEST =
  "PARTNER_APPROVAL_STATUS_UPDATE_REQUEST";
export const PARTNER_APPROVAL_STATUS_UPDATE_SUCCESS =
  "PARTNER_APPROVAL_STATUS_UPDATE_SUCCESS";
export const PARTNER_APPROVAL_STATUS_UPDATE_ERR =
  "PARTNER_APPROVAL_STATUS_UPDATE_ERR";

export const PARTNER_GET_ALL_REQUEST = "PARTNER_GET_ALL_REQUEST";
export const PARTNER_GET_ALL_SUCCESS = "PARTNER_GET_ALL_SUCCESS";
export const PARTNER_GET_ALL_ERR = "PARTNER_GET_ALL_ERR";

export const PARTNER_GET_DETAIL_REQUEST = "PARTNER_GET_DETAIL_REQUEST";
export const PARTNER_GET_DETAIL_SUCCESS = "PARTNER_GET_DETAIL_SUCCESS";
export const PARTNER_GET_DETAIL_ERR = "PARTNER_GET_DETAIL_ERR";

export const PARTNER_EDIT_DETAIL_REQUEST = "PARTNER_EDIT_DETAIL_REQUEST";
export const PARTNER_EDIT_DETAIL_SUCCESS = "PARTNER_EDIT_DETAIL_SUCCESS";
export const PARTNER_EDIT_DETAIL_ERR = "PARTNER_EDIT_DETAIL_ERR";

export const PARTNER_APPROVAL_REQUEST = "PARTNER_APPROVAL_REQUEST";
export const PARTNER_APPROVAL_SUCCESS = "PARTNER_APPROVAL_SUCCESS";
export const PARTNER_APPROVAL_ERR = "PARTNER_APPROVAL_ERR";

export const PARTNER_CREATE_REQUEST = "PARTNER_CREATE_REQUEST";
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS";
export const PARTNER_CREATE_ERR = "PARTNER_CREATE_ERR";

export const PARTNER_DELETE_REQUEST = "PARTNER_DELETE_REQUEST";
export const PARTNER_DELETE_SUCCESS = "PARTNER_DELETE_SUCCESS";
export const PARTNER_DELETE_ERR = "PARTNER_DELETE_ERR";

export const PARTNER_LEADS_LIST_REQUEST = "PARTNER_LEADS_LIST_REQUEST";
export const PARTNER_LEADS_LIST_SUCCESS = "PARTNER_LEADS_LIST_SUCCESS";
export const PARTNER_LEADS_LIST_ERR = "PARTNER_LEADS_LIST_ERR";

export const PARTNER_OTP_VERIFICATION_REQUEST =
  "PARTNER_OTP_VERIFICATION_REQUEST";
export const PARTNER_OTP_VERIFICATION_SUCCESS =
  "PARTNER_OTP_VERIFICATION_SUCCESS";
export const PARTNER_OTP_VERIFICATION_ERR = "PARTNER_OTP_VERIFICATION_ERR";

export const PARTNER_LEAD_FIND_REQUEST = "PARTNER_LEAD_FIND_REQUEST";
export const PARTNER_LEAD_FIND_SUCCESS = "PARTNER_LEAD_FIND_SUCCESS";
export const PARTNER_LEAD_FIND_ERR = "PARTNER_LEAD_FIND_ERR";

export const PARTNER_LEAD_ENTROLLED_FIND_REQUEST = "PARTNER_LEAD_ENTROLLED_FIND_REQUEST";
export const PARTNER_LEAD_ENTROLLED_FIND_SUCCESS = "PARTNER_LEAD_ENTROLLED_FIND_SUCCESS";
export const PARTNER_LEAD_ENTROLLED_FIND_ERR = "PARTNER_LEAD_ENTROLLED_FIND_ERR";

export const PARTNER_COMMISSION_CATEGORY_REQUEST =
  "PARTNER_COMMISSION_CATEGORY_REQUEST";
export const PARTNER_COMMISSION_CATEGORY_SUCCESS =
  "PARTNER_COMMISSION_CATEGORY_SUCCESS";
export const PARTNER_COMMISSION_CATEGORY_ERR =
  "PARTNER_COMMISSION_CATEGORY_ERR";

export const PARTNER_COMMISSIONID_UPDATE_REQUEST =
  "PARTNER_COMMISSIONID_UPDATE_REQUEST";
export const PARTNER_COMMISSIONID_UPDATE_SUCCESS =
  "PARTNER_COMMISSIONID_UPDATE_SUCCESS";
export const PARTNER_COMMISSIONID_UPDATE_ERR =
  "PARTNER_COMMISSIONID_UPDATE_ERR";

export const PARTNER_LEAD_APPLICATION_FIND_REQUEST =
  "PARTNER_LEAD_APPLICATION_FIND_REQUEST";
export const PARTNER_LEAD_APPLICATION_FIND_SUCCESS =
  "PARTNER_LEAD_APPLICATION_FIND_SUCCESS";
export const PARTNER_LEAD_APPLICATION_FIND_ERR =
  "PARTNER_LEAD_APPLICATION_FIND_ERR";

// partner notifications

export const PARTNER_NOTIFICATION_CREATE_REQUEST =
  "PARTNER_NOTIFICATION_CREATE_REQUEST";
export const PARTNER_NOTIFICATION_CREATE_SUCCESS =
  "PARTNER_NOTIFICATION_CREATE_SUCCESS";
export const PARTNER_NOTIFICATION_CREATE_ERR =
  "PARTNER_NOTIFICATION_CREATE_ERR";

export const PARTNER_NOTIFICATION_FIND_ALL_REQUEST =
  "PARTNER_NOTIFICATION_FIND_ALL_REQUEST";
export const PARTNER_NOTIFICATION_FIND_ALL_SUCCESS =
  "PARTNER_NOTIFICATION_FIND_ALL_SUCCESS";
export const PARTNER_NOTIFICATION_FIND_ALL_ERR =
  "PARTNER_NOTIFICATION_FIND_ALL_ERR";

  export const PARTNER_NOTIFICATION_FIND_ONE_REQUEST =
  "PARTNER_NOTIFICATION_FIND_ONE_REQUEST";
export const PARTNER_NOTIFICATION_FIND_ONE_SUCCESS =
  "PARTNER_NOTIFICATION_FIND_ONE_SUCCESS";
export const PARTNER_NOTIFICATION_FIND_ONE_ERR =
  "PARTNER_NOTIFICATION_FIND_ONE_ERR";

  export const NOTIFICATION_DELETE_REQUEST =
  "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_SUCCESS =
  "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_ERR =
  "NOTIFICATION_DELETE_ERR";

  export const NOTIFICATION_EDIT_REQUEST =
  "NOTIFICATION_EDIT_REQUEST";
export const NOTIFICATION_EDIT_SUCCESS =
  "NOTIFICATION_EDIT_SUCCESS";
export const NOTIFICATION_EDIT_ERR =
  "NOTIFICATION_EDIT_ERR";
