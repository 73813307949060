import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";

import Swal from "sweetalert2";

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { IoKeyOutline } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";

import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import {
  changePasswordAction,
  employeedetailsShowAction
} from "../../actions/employeeManagmentAction";
import { MainContainer } from "../content/content.element";
import { InfoHeader } from "../customComponent/InfoHeader";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import EmployeePaySlip from "../administration/employee/employeeTabs/EmployeePaySlip";
import EmployeeHoliday from "../administration/employee/employeeTabs/employeeHoliday/EmployeeHoliday";
import LeaveShowTable from "../administration/employee/employeeLeaveApply/LeaveShowTable";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";
import {
  EMPLOYEE_CHANGE_PASSWORD_ERR,
  EMPLOYEE_CHANGE_PASSWORD_SUCCESS
} from "../../constants/employeeManagmentConstant";

// dialogue transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EmployeeProfile(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [oldPasswordView, setoldPasswordView] = useState(false);
  const [view, setView] = useState(false);
  const [confirmview, setConfirmView] = useState(false);

  // Coustom hook :::::::::::::::::::
  const {
    privilege,
    hasPrivilege,
    isUpdatePrevilage,
    isDeletePrevilage,
    isStatusChangePrevilage
  } = usePrivilegeCheck();

  const dispatch = useDispatch();

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "black"
      // borderBottom: "3px solid black",
    }
  });

  //  selectors
  let {
    showDetailsEmployeeLoading,
    showDetailsEmployeeSuccess,
    showDetailsEmployeeErr
  } = useSelector((state) => {
    return state.employeedetailsShow;
  });

  let { changePassLoading, changePassSuccess, changePassErr } = useSelector(
    (state) => {
      return state.changePassword;
    }
  );

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (changePassSuccess) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Password changed successfullly!"
      });
      dispatch({ type: EMPLOYEE_CHANGE_PASSWORD_SUCCESS, payload: false });
    }
    if (changePassErr) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: changePassErr
      });
      dispatch({ type: EMPLOYEE_CHANGE_PASSWORD_ERR, payload: false });
    }
  }, [changePassSuccess, changePassErr]);

  // useEffect(() => {
  //   if (successAlertMsgSuccess) {
  //     Swal.fire("Good job!", `Password Changed`, "success");
  //     dispatch(successTrueMsgAction(false));
  //   }
  // }, [successAlertMsgSuccess, dispatch]);

  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  useEffect(() => {
    dispatch(employeedetailsShowAction(isUserExist?.UserId));
  }, [dispatch]);

  // tabs handle
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // change Password modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [valid, setValid] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    dispatch(
      changePasswordAction(oldPassword, newPassword, confirmPassword, id)
    );
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    // Reset error
    setError("");
    setOpen(false);
  };

  console.log(
    showDetailsEmployeeSuccess,
    "showDetailsEmployeeSuccessshowDetailsEmployeeSuccessshowDetailsEmployeeSuccess"
  );

  const validatePassword = (password) => {
    // Check if the password contains at least 6 characters and one symbol
    const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
    return regex.test(password);
  };

  const userBranch = showDetailsEmployeeSuccess?.users?.user_Branches.map(
    (value) => value?.branch_master?.branchName
  );

  console.log(userBranch, "userBranchuserBranchuserBranch");

  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="Profile Details" url="/Home" />
        <Paper
          elevation={0}
          sx={{ height: "175px", border: "0.5px solid #ECECEC", mt: 2 }}
        >
          <Grid container spacing={1} sx={{ mt: 0.5 }}>
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={showDetailsEmployeeSuccess?.users?.img_url}
                    sx={{
                      width: 105,
                      height: 111,
                      margin: "23px",
                      left: "10px"
                    }}
                  />

                  <div
                    style={{
                      marginTop: "30px",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 600,
                        fontSize: "24px",
                        marginTop: "5px"
                      }}
                    >
                      {showDetailsEmployeeSuccess?.users?.firstName
                        ? showDetailsEmployeeSuccess.users.firstName +
                          (showDetailsEmployeeSuccess?.users?.lastName
                            ? " " + showDetailsEmployeeSuccess.users.lastName
                            : "")
                        : ""}
                    </Typography>{" "}
                    &nbsp;
                    <div
                      style={{
                        marginTop: "-15px"
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 400,
                          fontSize: "16px"
                        }}
                      >
                        <span style={{ fontSize: "16px", fontWeight: 700 }}>
                          {"Role : "}
                        </span>
                        {showDetailsEmployeeSuccess?.users?.fk_role_id ==
                        "admin"
                          ? showDetailsEmployeeSuccess?.users?.fk_role_id
                          : showDetailsEmployeeSuccess?.users?.role?.roleName}
                      </Typography>
                    </div>
                    <div
                      style={{
                        marginTop: "10px"
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 400,
                          fontSize: "16px"
                        }}
                      >
                        <span style={{ fontSize: "16px", fontWeight: 700 }}>
                          {"Designation : "}
                        </span>
                        {showDetailsEmployeeSuccess?.users?.designation}
                      </Typography>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    height="150px"
                    sx={{ marginLeft: "50px" }}
                    flexItem
                  />

                  <div
                    style={{
                      display: "flex",
                      marginLeft: "50px"
                    }}
                  >
                    <div>
                      <p>
                        <BsTelephone />
                      </p>
                      <p>
                        <MdOutlineEmail />
                      </p>
                      <p>
                        <CiLocationOn />
                      </p>
                      <p>
                        <IoKeyOutline style={{ color: "#2a7ad3" }} />
                      </p>
                    </div>

                    <div
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontweight: 400,
                        letterSpacing: "0.4399999976158142px",
                        marginLeft: "10px"
                      }}
                    >
                      <p style={{ fontweight: 500, fontSize: "15px" }}>
                        {showDetailsEmployeeSuccess?.users?.mobile
                          ? (showDetailsEmployeeSuccess?.users
                              ?.mobile_countryCode
                              ? showDetailsEmployeeSuccess?.users
                                  ?.mobile_countryCode + " "
                              : "") + showDetailsEmployeeSuccess.users.mobile
                          : ""}
                      </p>
                      <p style={{ fontweight: 500, fontSize: "15px" }}>
                        {showDetailsEmployeeSuccess?.users?.email}
                      </p>
                      <p style={{ fontweight: 500, fontSize: "15px" }}>
                        {showDetailsEmployeeSuccess?.users?.country_code !==
                        null
                          ? showDetailsEmployeeSuccess?.users?.country_code
                          : "-"}
                      </p>
                      <Button
                        style={{
                          marginTop: "-7px",
                          marginLeft: "-5px",
                          fontweight: 500,
                          fontSize: "15px"
                        }}
                        onClick={handleOpen}
                      >
                        Change Password
                      </Button>
                    </div>
                    <div>
                      <div style={{ display: "flex", marginLeft: "50px" }}>
                        <div>
                          <p>
                            {/* <BsTelephone /> */}
                            Branch :
                          </p>
                        </div>
                        <div
                          style={{
                            fontFamily: "Open Sans",
                            fontSize: "16px",
                            fontweight: 400,
                            letterSpacing: "0.4399999976158142px",
                            marginLeft: "10px"
                          }}
                        >
                          <p style={{ fontweight: 500, fontSize: "15px" }}>
                            {userBranch?.join()}
                          </p>
                        </div>
                      </div>
                      <div style={{ marginLeft: "50px", marginTop: "10px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px"
                          }}
                        >
                          {showDetailsEmployeeSuccess?.users?.is_lead ==
                            true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                Team Lead B2B
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users?.isTeleCaller ==
                            true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                TeleCaller
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users?.isManager ==
                            true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                Manager
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users?.is_Lead_B_to_C ==
                            true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                Team Lead B2C
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users
                            ?.is_applicationHandler == true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                Application handler
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users
                            ?.is_universityHandler == true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                University handler
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users
                            ?.isTelecallerManager == true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                Telecaller Manager
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}

                          {showDetailsEmployeeSuccess?.users
                            ?.isTeleCallerTeamLead == true && (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "rgba(138, 146, 166, 1)"
                                }}
                              >
                                Telecaller Team Lead
                              </Typography>
                              <Checkbox
                                checked={true}
                                style={{ color: "green" }}
                              />
                            </Box>
                          )}
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>

        {/* dialogue */}

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ width: "100%" }}
        >
          <DialogTitle>{"Change Password"}</DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6} md={4} lg={12}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    type={oldPasswordView ? "text" : "password"}
                    label="Old Password"
                    fullWidth
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setoldPasswordView(!oldPasswordView)}
                          >
                            {oldPasswordView ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <TextField
                    type={view ? "text" : "password"}
                    label="New Password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setValid(validatePassword(newPassword));
                    }}
                    required
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setView(!view)}>
                            {view ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {!valid && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        fontWeight: 300,
                        paddingTop: "-20px"
                      }}
                    >
                      Password must have at least 6 characters and contain at
                      least one symbol (!@#$%^&*)
                    </span>
                  )}

                  <TextField
                    type={confirmview ? "text" : "password"}
                    label="Confirm Password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    margin="normal"
                    error={error.length > 0}
                    helperText={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setConfirmView(!confirmview)}
                          >
                            {confirmview ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <Box mt={2}>
                    <Button
                      type="submit"
                      sx={{
                        width: "auto",
                        height: "auto",
                        color: "white",
                        p: 2,
                        backgroundColor: "rgba(20, 30, 60, 1)",
                        borderRadius: "8px",
                        fontWeight: 600,
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "rgba(20, 30, 60, 0.9)"
                        }
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </form>
                {/* </Paper> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button> */}
          </DialogActions>
        </Dialog>
      </MainContainer>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Close
            sx={{
              cursor: "pointer"
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700"
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px"
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px"
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
}

export default EmployeeProfile;
