import React from 'react'
import EmployeePaySlipTable from './EmployeePaySlipTable'

function EmployeePaySlip({role}) {
  return (
<>

<EmployeePaySlipTable role={role}/>
</>  )
}

export default EmployeePaySlip