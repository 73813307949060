import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { socketUrl } from "./url";

function useChatSocket() {
  const [chat, setChat] = useState([]);

  useEffect(() => {
    const socket = io(socketUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("message", (message) => {
      // setMessages((prevMessages) => [...prevMessages, message]);
      setChat((prevChats) => [...prevChats, message]);

      // alert("hellllooo entered");
    });

    socket.on("replyType", (chats) => {
      setChat((prevChats) => [...prevChats, chats]);
    });

    socket.on("error", (error) => {
      console.error("Socket error:", error);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return chat;
}

export default useChatSocket;
