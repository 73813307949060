export const COMMON_DOC_ADD_REQUEST = "COMMON_DOC_ADD_REQUEST";
export const COMMON_DOC_ADD_SUCCESS = "COMMON_DOC_ADD_SUCCESS";
export const COMMON_DOC_ADD_ERR = "COMMON_DOC_ADD_ERR";

export const COMMON_DOC_FIND_REQUEST = "COMMON_DOC_FIND_REQUEST";
export const COMMON_DOC_FIND_SUCCESS = "COMMON_DOC_FIND_SUCCESS";
export const COMMON_DOC_FIND_ERR = "COMMON_DOC_FIND_ERR";

export const COMMON_DOC_IS_MANDATORY_UPDATE_REQUEST =
  "COMMON_DOC_IS_MANDATORY_UPDATE_REQUEST";
export const COMMON_DOC_IS_MANDATORY_UPDATE_SUCCESS =
  "COMMON_DOC_IS_MANDATORY_UPDATE_SUCCESS";
export const COMMON_DOC_IS_MANDATORY_UPDATE_ERR =
  "COMMON_DOC_IS_MANDATORY_UPDATE_ERR";

export const COMMON_DOC_IS_FRON_BACK_UPDATE_REQUEST =
  "COMMON_DOC_IS_FRON_BACK_UPDATE_REQUEST";
export const COMMON_DOC_IS_FRON_BACK_UPDATE_SUCCESS =
  "COMMON_DOC_IS_FRON_BACK_UPDATE_SUCCESS";
export const COMMON_DOC_IS_FRON_BACK_UPDATE_ERR =
  "COMMON_DOC_IS_FRON_BACK_UPDATE_ERR";

export const COMMON_DOC_DELETE_REQUEST = "COMMON_DOC_DELETE_REQUEST";
export const COMMON_DOC_DELETE_SUCCESS = "COMMON_DOC_DELETE_SUCCESS";
export const COMMON_DOC_DELETE_ERR = "COMMON_DOC_DELETE_ERR";
