import {
  UNIVERSITYANDCOURSE_BULKUPLOAD_ERR,
  UNIVERSITYANDCOURSE_BULKUPLOAD_REQUEST,
  UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS,
  UNIVERSITY_DELETE_ERR,
  UNIVERSITY_DELETE_REQUEST,
  UNIVERSITY_DELETE_SUCCESS,
  UNIVERSITY_EDIT_ERR,
  UNIVERSITY_EDIT_REQUEST,
  UNIVERSITY_EDIT_SUCCESS,
  UNIVERSITY_FIND_ERR,
  UNIVERSITY_FIND_REQUEST,
  UNIVERSITY_FIND_SUCCESS,
  UNIVERSITY_IMAGE_DELETE_ERR,
  UNIVERSITY_IMAGE_DELETE_REQUEST,
  UNIVERSITY_IMAGE_DELETE_SUCCESS,
  UNIVERSITY_IMAGE_UPLOAD_ERR,
  UNIVERSITY_IMAGE_UPLOAD_REQUEST,
  UNIVERSITY_IMAGE_UPLOAD_SUCCESS,
  UNIVERSITY_NULL_IMAGE_ERR,
  UNIVERSITY_NULL_IMAGE_REQUEST,
  UNIVERSITY_NULL_IMAGE_SUCCESS,
  UNIVERSITY_SINGLE_VIEW_ERR,
  UNIVERSITY_SINGLE_VIEW_REQUEST,
  UNIVERSITY_SINGLE_VIEW_SUCCESS,
  UNIVERSITY_SUBMIT_ERR,
  UNIVERSITY_SUBMIT_REQUEST,
  UNIVERSITY_SUBMIT_SUCCESS,
} from "../../constants/university/universityManagentConstant.js";

// uploadUniversityImageReducer
export const uploadUniversityImageReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_IMAGE_UPLOAD_REQUEST:
      return {
        ...state,
        unversityImageUploadLoading: true,
      };
    case UNIVERSITY_IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        unversityImageUploadLoading: false,
        unversityImageUploadSuccess: action.payload,
      };
    case UNIVERSITY_IMAGE_UPLOAD_ERR:
      return {
        ...state,
        unversityImageUploadLoading: false,
        unversityImageUploadErr: action.payload,
      };
    default:
      return state;
  }
};

// nullUniversityImageShowReducer
export const nullUniversityImageShowReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_NULL_IMAGE_REQUEST:
      return {
        ...state,
        unversityImageNullShowLoading: true,
      };
    case UNIVERSITY_NULL_IMAGE_SUCCESS:
      return {
        ...state,
        unversityImageNullShowLoading: false,
        unversityImageNullShowSuccess: action.payload,
      };
    case UNIVERSITY_NULL_IMAGE_ERR:
      return {
        ...state,
        unversityImageNullShowLoading: false,
        unversityImageNullShowErr: action.payload,
      };
    default:
      return state;
  }
};

// deleteUniversityImageReducer
export const deleteUniversityImageReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_IMAGE_DELETE_REQUEST:
      return {
        ...state,
        unversityImageNullDeleteLoading: true,
      };
    case UNIVERSITY_IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        unversityImageNullDeleteLoading: false,
        unversityImageNullDeleteSuccess: action.payload,
      };
    case UNIVERSITY_IMAGE_DELETE_ERR:
      return {
        ...state,
        unversityImageNullDeleteLoading: false,
        unversityImageNullDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// universityAddSumitReducer
export const universityAddSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_SUBMIT_REQUEST:
      return {
        ...state,
        universityAddLoading: true,
      };
    case UNIVERSITY_SUBMIT_SUCCESS:
      return {
        ...state,
        universityAddLoading: false,
        universityAddSuccess: action.payload,
      };
    case UNIVERSITY_SUBMIT_ERR:
      return {
        ...state,
        universityAddLoading: false,
        universityAddErr: action.payload,
      };
    default:
      return state;
  }
};

// findUniversityReducer
export const findUniversityReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_FIND_REQUEST:
      return {
        ...state,
        universityFindLoading: true,
      };
    case UNIVERSITY_FIND_SUCCESS:
      return {
        ...state,
        universityFindLoading: false,
        universityFindSuccess: action.payload,
      };
    case UNIVERSITY_FIND_ERR:
      return {
        ...state,
        universityFindLoading: false,
        universityFindErr: action.payload,
      };
    default:
      return state;
  }
};

// universityViewReducer
export const universityViewReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_SINGLE_VIEW_REQUEST:
      return {
        ...state,
        universityViewByIdLoading: true,
      };
    case UNIVERSITY_SINGLE_VIEW_SUCCESS:
      return {
        ...state,
        universityViewByIdLoading: false,
        universityViewByIdSuccess: action.payload,
      };
    case UNIVERSITY_SINGLE_VIEW_ERR:
      return {
        ...state,
        universityViewByIdLoading: false,
        universityViewByIdErr: action.payload,
      };
    default:
      return state;
  }
};



// universityEditReducer
export const universityEditReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_EDIT_REQUEST:
      return {
        ...state,
        universityEditLoading: true,
      };
    case UNIVERSITY_EDIT_SUCCESS:
      return {
        ...state,
        universityEditLoading: false,
        universityEditSuccess: action.payload,
      };
    case UNIVERSITY_EDIT_ERR:
      return {
        ...state,
        universityEditLoading: false,
        universityEditErr: action.payload,
      };
    default:
      return state;
  }
};




// universityDeleteReducer
export const universityDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_DELETE_REQUEST:
      return {
        ...state,
        universityDeleteLoading: true,
      };
    case UNIVERSITY_DELETE_SUCCESS:
      return {
        ...state,
        universityDeleteLoading: false,
        universityDeleteSuccess: action.payload,
      };
    case UNIVERSITY_DELETE_ERR:
      return {
        ...state,
        universityDeleteLoading: false,
        universityDeleteErr: action.payload,
      };
    default:
      return state;
  }
};



// universityAnd course bulkUpload Reducer
export const universityAndCourseBulkUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITYANDCOURSE_BULKUPLOAD_REQUEST:
      return {
        ...state,
        universityAndCourseBulkUploadLoading: true,
      };
    case UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        universityAndCourseBulkUploadLoading: false,
        universityAndCourseBulkUploadSuccess: action.payload,
      };
    case UNIVERSITYANDCOURSE_BULKUPLOAD_ERR:
      return {
        ...state,
        universityAndCourseBulkUploadLoading: false,
        universityAndCourseBulkUploadErr: action.payload,
      };
    default:
      return state;
  }
};

