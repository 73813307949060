import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Modal,
  Stack,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import CancelIcon from "@mui/icons-material/Cancel";

import { useDispatch, useSelector } from "react-redux";

import { countryDocumentAdmissionApplicationFindAction } from "../../../actions/knowledgeBankActions/CountryDocsActions";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import InputField, { CustomDateField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  eventsCreateAction,
  newsCreateAction,
} from "../../../actions/LandingPageAction";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { set } from "date-fns";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function CreateEvents(props) {
  const dispatch = useDispatch();
  const [selectedStyle, setSelectedStyle] = useState("NORMAL");
  const [bgColor, setBgColor] = useState("transparent");
  const [textColor, setTextColor] = useState("black");
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);

  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [title, setTitle] = useState("");
  const [Location, setLocation] = useState("");
  const [newsImage, setNewsImage] = useState();
  const [eventDate, setEventData] = useState();
  const [eventTime, setEventTime] = useState();
  const newsimage = useRef();

  let { eventsCreateSuccess } = useSelector((state) => {
    return state.eventsCreate;
  });
  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (successAlertMsgSuccess && eventsCreateSuccess?.status == true) {
      setTitle("");
      setLocation("");
      setDestinationimageValue("");
      setEventData("");
      setEventTime("");
      setDescription(EditorState.createEmpty());


      Swal.fire("Created", eventsCreateSuccess.message, "success");
      dispatch(successTrueMsgAction(false));
    }
    if (successAlertMsgSuccess && eventsCreateSuccess?.status == false) {
      Swal.fire("Error", eventsCreateSuccess.message, "warning");
      dispatch(successTrueMsgAction(false));
    }
  }, [eventsCreateSuccess, successAlertMsgSuccess]);

  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );

  //   DESCRIPTION STYLE
  const handleBoldClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "BOLD"));
  };

  const handleItalicClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "ITALIC"));
  };

  const handleUnderlineClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "UNDERLINE"));
  };

  const handleStrikethroughClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "STRIKETHROUGH"));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
    setDescription(
      RichUtils.toggleInlineStyle(
        description,
        `BG_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setDescription(
      RichUtils.toggleInlineStyle(
        description,
        `TEXT_COLOR_${color.toUpperCase()}`
      )
    );
  };
  const handleTextAlignment = (alignment) => {
    setDescription(
      RichUtils.toggleBlockType(description, `ALIGN_${alignment.toUpperCase()}`)
    );
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  // text editor section
  const handleStyleChange = (e) => {
    const newStyle = e.target.value;

    // Toggle the new style immediately
    const newEditorState = RichUtils.toggleInlineStyle(description, newStyle);

    setSelectedStyle(newStyle);
    setDescription(newEditorState);
  };

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    setNewsImage(event.target.files[0]);
  };

  // HandleCreate

  const HandleCreate = () => {
    const contentState = description.getCurrentContent();
    function getInlineStyle(entity) {
      const entityKey = entity.getEntity();
      const entityInstance = contentState.getEntity(entityKey);
      const entityType = entityInstance.getType();
      if (entityType === "STYLE") {
        // Check if the entity type is 'STYLE'
        const entityData = entityInstance.getData();
        return entityData.style; // Return the style data from the entity
      }
      return null; // Return null for other entity types
    }
    const htmlContent = stateToHTML(contentState, {
      inlineStyles: {
        STYLE: {
          element: "span", // Use a span element for text with 'STYLE' entity
          attributes: (style, entity) => ({
            style: getInlineStyle(entity), // Apply the style retrieved from the entity
          }),
        },
      },
    });
    console.log(htmlContent, "htmlcontenttttttttttttttttt");
    console.log(title, "title");
    console.log(newsImage, "image");
    console.log(Location, "location");
    console.log(eventDate, "dateeeeeeee");
    console.log(eventTime, "eventTime");

    const formData = new FormData();
    formData.append("title", title);
    formData.append("image", newsImage);
    formData.append("location", Location);
    formData.append("date", eventDate);
    formData.append("time", eventTime);
    formData.append("description", htmlContent);
    dispatch(eventsCreateAction(formData));
    dispatch(successTrueMsgAction(true));
  };
  console.log(description,"description")

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Events" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={12}>
          <InputField
            label="Title"
            handleChange={(e) => setTitle(e.target.value)}
            widthSize="99.2%"
            value={title}
            InputType="discription"
            // dispcriptionHeight=""
            required
            paramses
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          style={{ position: "relative", marginTop: "15px" }}
        >
          <Grid>
            <InputField
              label="Cover Image"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 50 }}>
            <button
              onClick={() => newsimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={newsimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          style={{ position: "relative", marginTop: "15px" }}
        >
          <InputField
            label="Location"
            handleChange={(e) => setLocation(e.target.value)}
            widthSize="99.2%"
            value={Location}
            InputType="discription"
            // dispcriptionHeight=""
            required
            paramses
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          style={{ position: "relative", marginTop: "15px", height: "100%" }}
        >
          <Box sx={{ maxWidth: "94%", height: "100%" }}>
            <CustomDateField
              name="date"
              value={eventDate}
              handleChange={(value) => setEventData(value)}
              placeholder={"Date"}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          style={{ position: "relative", marginTop: "15px" }}
          // class="input-group"
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "46px",
                  "& input": {
                    boxSizing: "border-box",
                    height: "100%",
                  },
                }}
                value={eventTime}
                name="time"
                onChange={(value) => {
                  console.log(value, "valueeeeeeeeeeeeeeeeeeee");
                  const formattedTime = value.$d.toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  });

                  console.log(formattedTime, "formattedTimeformattedTime");
                  setEventTime(formattedTime);
                }}
              />
            </LocalizationProvider>
            {/* {errorData?.time && (
                <Typography sx={{ color: "red", fontSize: "12px" }}>
                  {errorData?.time}
                </Typography>
              )} */}
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ mt: 5 }}>
          <div>
            <ToggleButtonGroup
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <select value={selectedStyle} onChange={handleStyleChange}>
                <option value="NORMAL">Normal</option>
                <option value="BOLD">Bold</option>
                <option value="ITALIC">Italic</option>
                <option value="UNDERLINE">Underline</option>
              </select>
              <FormatBoldIcon
                onClick={handleBoldClick}
                value="bold"
                aria-label="bold"
              />

              <FormatItalicIcon
                onClick={handleItalicClick}
                value="italic"
                aria-label="italic"
              />

              <FormatUnderlinedIcon
                onClick={handleUnderlineClick}
                value="underlined"
                aria-label="underlined"
              />

              <StrikethroughSIcon
                onClick={handleStrikethroughClick}
                value="strikethrough"
                aria-label="strikethrough"
              />
              <FormatColorTextIcon
                onClick={() => handleBgColorChange("yellow")}
                value="backgroundcolor"
                aria-label="backgroundcolor"
              />

              <InvertColorsIcon
                onClick={() => handleTextColorChange("blue")}
                value="textcolor"
                aria-label="textcolor"
              />

              <FormatAlignLeftIcon
                onClick={() => handleTextAlignment("left")}
                value="textcolor"
                aria-label="textcolor"
              />
            </ToggleButtonGroup>
          </div>
          <div
            style={{
              border: "1px solid #ececec",
              padding: "10px",
              minHeight: "200px",
              marginTop: "10px",
            }}
          >
            <Editor
              editorState={description}
              onChange={setDescription}
              customStyleMap={{
                // Define custom inline styles for text and background colors
                TEXT_COLOR_RED: { color: "red" },
                TEXT_COLOR_BLUE: { color: "blue" },
                BG_COLOR_YELLOW: { backgroundColor: "yellow" },
                BG_COLOR_GREEN: { backgroundColor: "green" },
                ALIGN_LEFT: { textAlign: "left" },
              }}
            />
          </div>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default CreateEvents;
