import React from "react";
import { Box } from "@mui/material";

const Tag = ({ text,color }) => {
  return (
    <Box
      sx={{

        width: "auto", // Width of the rectangle
        pr:2,
        pl:2,
        height: "23px", // Height of the rectangle
        backgroundColor: color, // Green background color
        position: "relative", // For positioning the arrow tip
        display: "flex", // Flexbox for centering text
        alignItems: "center", // Vertically center the text
        justifyContent: "center", // Horizontally center the text
        color: "white", // Text color
        fontWeight: "bold", // Bold text
        fontSize: "9px", // Text size
        borderRadius: "0 25px 25px 0", // Round the right side

        // "&::after": {
        //   content: '""',
        //   position: "absolute",
        //   top: 0,
        //   right: 0,
        //   width: 0,
        //   height: 0,
        //   borderTop: "25px solid transparent", // Half of rectangle height
        //   borderBottom: "25px solid transparent", // Half of rectangle height
        //   borderLeft: "25px solid green", // Arrow tip
        // },
      }}
    >
      {text}
    </Box>
  );
};

export default Tag;
