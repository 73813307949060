import React, { useEffect, useState } from "react";
import { SubmitButton } from "../../customComponent/Buttons";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  NormalInputField,
  NormalSelect,
} from "../../customComponent/InputField";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNotificationAction, findAllPartnerAction } from "../../../actions/partnerAction/partnerAction";
import { hrEmployeeFindAction } from "../../../actions/HrActions/hrEmployeeManagmentAction";
import { successTrueMsgAction } from "../../../actions/globalAction";

function EditNotification({ open, handleClose, data }) {
  const dispatch = useDispatch();

  let { partnerFindSuccess } = useSelector((state) => {
    return state.findAllPartner;
  });

  let { hrEmployeeFindSuccess } = useSelector((state) => {
    return state.hrEmployeeFind;
  });

  useEffect(() => {
    if (partnerFindSuccess) {
      let data = partnerFindSuccess.map((item) => ({
        label: item.name,
        id: item.id,
      }));
      setPartnerOptions(data);
    }
  }, [partnerFindSuccess]);

  useEffect(() => {
    if (hrEmployeeFindSuccess) {
      let data = hrEmployeeFindSuccess.map((item) => ({
        label: item.firstName,
        id: item.id,
      }));
      setEmployeeOptions(data);
    }
  }, [hrEmployeeFindSuccess]);

  useEffect(() => {
    dispatch(findAllPartnerAction());
    dispatch(hrEmployeeFindAction());
  }, []);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [formData, setFormData] = useState({});
  const [partnerOptions, setPartnerOptions] = useState([]);
  const partnerOptionMap = Object.fromEntries(
    partnerOptions.map((e) => [e.id, e.label])
  );
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const employeeOptionMap = Object.fromEntries(
    employeeOptions.map((e) => [e.id, e.label])
  );

  const [error, setError] = useState();
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(data.content)
    );
    setEditorState(EditorState.createWithContent(contentState));
    if (data) {
      let employee = data.employee_notifications.map((item) => item.id);
      let partner = data.partner_notifications.map((item) => item.partnerId);
      setFormData({
        id: data.id,
        subject: data.subject,
        user: data.targetedUser,
        selectedPartner: partner,
        selectedEmployee: employee,
        content: data.content,
        url: data.attchedFile,
      });
      setSelectedFile("url");
    }
  }, [data]);

  const toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
    },
    fontSize: {
      options: [10, 12, 14, 16, 18, 24, 30, 36],
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    textAlign: {
      options: ["left", "center", "right"],
    },
    colorPicker: {
      colors: [
        "rgba(0,0,0,0.65)",
        "rgba(255,0,0,0.65)",
        "rgba(255,255,0,0.65)",
        "rgba(0,0,255,0.65)",
      ],
    },
  };

  const validate = () => {
    let error = {};

    if (!formData?.subject) {
      error.subject = "Subject is required";
    }

    if (!formData?.user) {
      error.user = "Please select a user";
    }

    if (!formData?.content || formData?.content === "<p><br></p>") {
      error.content = "Please enter some contents";
    }

    if (
      formData?.user === "Partners" &&
      (!formData?.selectedPartner || formData?.selectedPartner.length === 0)
    ) {
      error.selectedPartner = "Please select partners";
    }

    if (!formData?.file && formData?.url === "") {
      error.file = "Please choose a file";
    }
    console.log(formData?.url === "");

    setError(error);
    return Object.keys(error).length === 0;
  };

  let options = [
    "All Users",
    "Students",
    "Partners",
    "Employee",
    "Accommodation",
  ];

  const handleSubmit = () => {
    console.log(formData?.file);
    if (validate()) {
      const newFormData = new FormData();
      newFormData.append("id", formData.id);
      newFormData.append("subject", formData.subject);
      newFormData.append("content", formData.content);
      newFormData.append("uploadedFile", formData.file);
      newFormData.append("url", formData.url);
      dispatch(UpdateNotificationAction(newFormData));
      dispatch(successTrueMsgAction(true));
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleEditorChange = (state) => {
    const contentState = state.getCurrentContent();
    const html = stateToHTML(contentState);
    setEditorState(state);
    setFormData((prevSlots) => ({
      ...prevSlots,
      content: html,
    }));

    setError((prev) => ({
      ...prev,
      ["content"]: "",
    }));
  };

  const handleFileChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      ["file"]: event.target.files[0],
    }));
    setError((prev) => ({
      ...prev,
      ["file"]: "",
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "850px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "18px",
            fontFamily: "Montserrat', sans-serif",
          }}
        >
          New Notification
        </Typography>
        <CloseIcon
          onClick={handleClose}
          sx={{
            cursor: "pointer",
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            pt: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            "& .MuiTextField-root": {
              width: "100%",
            },
            "& .public-DraftEditorPlaceholder-root": {
              color: Boolean(error?.content) ? "#d32f2f" : "#05050F",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px !important",
            },
            "& .MuiInputLabel-root": {
              color: "#05050F",
            },
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              border: "1px solid #ECECEC !important",
            },
            "&  .MuiTextField-root .MuiOutlinedInput-root  fieldset.MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #ECECEC !important",
              },
            "&  .MuiTextField-root .MuiOutlinedInput-root.Mui-error  fieldset.MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #d32f2f !important",
              },
          }}
        >
          <NormalInputField
            label="Subject"
            value={formData?.subject}
            error={Boolean(error?.subject)}
            helperText={error?.subject}
            handleChange={handleInput}
            name={"subject"}
          />
          {formData?.user && (
            <NormalSelect
              label={"Targeted users"}
              value={formData?.user}
              error={Boolean(error?.user)}
              helperText={error?.user}
              name={"user"}
              options={options}
              mapMethod={"targetedUsers"}
              // handleChange={handleInput}
            />
          )}
          {formData?.user === "Partners" && (
            <Box
              sx={{
                "& .MuiPaper-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                  padding: "0",
                },
                "& .MuiFormControl-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                },
                "& .MuiInputLabel-root.Mui-error ": {
                  color: "#d32f2f !important",
                },
              }}
            >
              <Autocomplete
                id="using-simple-state"
                multiple
                value={formData?.selectedPartner}
                // onChange={(_event, val) => {
                //   setFormData((prev) => ({
                //     ...prev,
                //     ["selectedPartner"]: val,
                //     ["selectedEmployee"]: [],
                //   }));
                //   setError((prev) => ({
                //     ...prev,
                //     ["selectedPartner"]: "",
                //   }));
                // }}
                sx={{
                  "& .MuiInputLabel-root ": {
                    color: "#05050F !important",
                  },
                  "& legend ": {
                    width: "95px",
                  },
                }}
                popupIcon={
                  <KeyboardArrowDown
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  />
                }
                options={partnerOptions.map((c) => c.id)}
                getOptionLabel={(opt) => partnerOptionMap[opt]}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Select Partners"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(error?.selectedPartner)}
                      helperText={error?.selectedPartner}
                    />
                  );
                }}
              />
            </Box>
          )}

          {formData?.user === "Employee" && (
            <Box
              sx={{
                "& .MuiPaper-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                  padding: "0",
                },
                "& .MuiFormControl-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                },
                "& .MuiInputLabel-root.Mui-error ": {
                  color: "#d32f2f !important",
                },
              }}
            >
              <Autocomplete
                id="using-simple-state"
                multiple
                value={formData?.selectedEmployee}
                // onChange={(_event, val) => {
                //   setFormData((prev) => ({
                //     ...prev,
                //     ["selectedEmployee"]: val,
                //     ["selectedPartner"]: [],
                //   }));
                //   setError((prev) => ({
                //     ...prev,
                //     ["selectedEmployee"]: "",
                //   }));
                // }}
                sx={{
                  "& .MuiInputLabel-root ": {
                    color: "#05050F !important",
                  },
                  "& legend ": {
                    width: "95px",
                  },
                }}
                popupIcon={
                  <KeyboardArrowDown
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  />
                }
                options={employeeOptions.map((c) => c.id)}
                getOptionLabel={(opt) => employeeOptionMap[opt]}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Select Employees"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(error?.selectedEmployee)}
                      helperText={error?.selectedEmployee}
                    />
                  );
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              //   overflow: "hidden",
              "& .rdw-editor-main": {
                paddingX: "20px",
                border: `1px solid ${
                  Boolean(error?.content) ? "#d32f2f" : "#ECECEC"
                }`,
                minHeight: "200px",
                cursor: "text",
              },
            }}
          >
            <Editor
              toolbar={toolbarOptions}
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              placeholder="Content"
              wrapperClassName="rdw-editor-wrapper"
              editorClassName="rdw-editor-main"
            />
            {Boolean(error?.content) && (
              <FormHelperText
                sx={{
                  color: "#d32f2f",
                  ml: "14px",
                }}
              >
                {error.content}
              </FormHelperText>
            )}
          </Box>
          <Box
            sx={{
              border: !Boolean(error?.file)
                ? "1px solid #ECECEC"
                : "1px solid #d32f2f",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 10px",
              color: !Boolean(error?.file) ? "#05050F" : "#d32f2f",
              fontSize: "16px",
              "& label": {
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              },
            }}
          >
            {selectedFile === "url" && (
              <Box
                sx={{
                  maxWidth: "240px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "28px 88px",
                    backgroundColor: "#F8F8F8",
                    border: "1px solid #EFEFEF",
                    borderBottom: "0",
                    width: "fit-content",
                  }}
                >
                  <svg
                    width="60"
                    height="78"
                    viewBox="0 0 60 78"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.9874 0H37.0653L59.1673 23.0375V67.8456C59.1673 73.4588 54.6261 78 49.0323 78H10.9874C5.37422 78 0.833008 73.4588 0.833008 67.8456V10.1544C0.832909 4.54121 5.37412 0 10.9874 0Z"
                      fill="#E5252A"
                    />
                    <path
                      opacity="0.302"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37.0459 0V22.8621H59.1674L37.0459 0Z"
                      fill="white"
                    />
                    <path
                      d="M12.1182 58.2005V43.9531H18.1797C19.6804 43.9531 20.8694 44.3624 21.7659 45.2005C22.6624 46.0191 23.1107 47.1301 23.1107 48.5138C23.1107 49.8976 22.6624 51.0086 21.7659 51.8272C20.8694 52.6653 19.6804 53.0746 18.1797 53.0746H15.7628V58.2005H12.1182ZM15.7628 49.9757H17.7704C18.3161 49.9757 18.7449 49.8587 19.0373 49.5859C19.3296 49.3325 19.4856 48.9817 19.4856 48.5139C19.4856 48.0462 19.3297 47.6954 19.0373 47.442C18.745 47.1691 18.3161 47.0522 17.7704 47.0522H15.7628V49.9757ZM24.6114 58.2005V43.9531H29.6594C30.6534 43.9531 31.5889 44.0895 32.466 44.3819C33.343 44.6742 34.1421 45.0836 34.8437 45.6488C35.5454 46.1945 36.1106 46.9351 36.5199 47.8707C36.9097 48.8063 37.1242 49.8782 37.1242 51.0866C37.1242 52.2755 36.9098 53.3475 36.5199 54.2829C36.1106 55.2185 35.5454 55.9591 34.8437 56.5048C34.1421 57.07 33.343 57.4793 32.466 57.7717C31.5889 58.064 30.6534 58.2005 29.6594 58.2005H24.6114ZM28.1781 55.1016H29.2306C29.7958 55.1016 30.322 55.0432 30.8093 54.9067C31.277 54.7703 31.7253 54.5558 32.1541 54.2635C32.5634 53.9712 32.8948 53.5618 33.1286 53.0161C33.3625 52.4704 33.4795 51.8272 33.4795 51.0866C33.4795 50.3264 33.3625 49.6833 33.1286 49.1376C32.8948 48.5919 32.5634 48.1826 32.1541 47.8902C31.7253 47.5979 31.2771 47.3834 30.8093 47.2471C30.322 47.1107 29.7958 47.0521 29.2306 47.0521H28.1781V55.1016ZM38.9563 58.2005V43.9531H49.0912V47.0521H42.6009V49.3324H47.7853V52.4119H42.6009V58.2005H38.9563Z"
                      fill="white"
                    />
                  </svg>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    border: "1px solid #EFEFEF",
                    borderTop: "1px solid #EFEFEF",
                    padding: "16px 16px 7px 16px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Uploaded Document
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                      mt: "10px",
                    }}
                  >
                    <Typography
                      target="_blank"
                      component={"a"}
                      sx={{
                        color: "#141E3C",
                        fz: "16px",
                        lineHeight: "24px",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      href={formData?.url}
                    >
                      View
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FE0B7A",
                        fz: "16px",
                        lineHeight: "24px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          ["file"]: "",
                        }));
                        setSelectedFile("file");
                      }}
                    >
                      Remove
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            {selectedFile === "file" && (
              <Box>
                <label for="upload">
                  {formData?.file ? (
                    <Typography>{formData?.file?.name}</Typography>
                  ) : (
                    <>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.6501 2.40898C15.223 0.0824223 11.2737 0.0824223 8.84647 2.40898L1.30006 9.64264C-0.433462 11.3046 -0.433348 14.0087 1.30037 15.6705C2.16728 16.5015 3.30564 16.917 4.44446 16.9169C5.58298 16.9168 6.72188 16.5014 7.58868 15.6705L14.506 9.03972C15.5463 8.04273 15.5464 6.42025 14.5061 5.4229C13.4658 4.42579 11.7731 4.42583 10.733 5.42308L6.2094 9.75912C5.86211 10.092 5.86211 10.6318 6.20933 10.9647C6.55669 11.2976 7.11968 11.2976 7.46704 10.9647L11.9907 6.62854C12.3374 6.29615 12.9017 6.29615 13.2484 6.62843C13.5951 6.9609 13.5951 7.50165 13.2483 7.83404L6.33108 14.4649C5.29076 15.462 3.59824 15.4621 2.55801 14.465C1.51781 13.4678 1.51769 11.8453 2.55778 10.8482L10.1041 3.61456C11.8379 1.95282 14.6588 1.95274 16.3925 3.61463C17.2324 4.41963 17.695 5.49002 17.6949 6.6285C17.6949 7.76703 17.2324 8.83738 16.3924 9.64245L8.84628 16.8762C8.49899 17.2091 8.49899 17.7488 8.84635 18.0818C9.02002 18.2483 9.24761 18.3315 9.47517 18.3315C9.70273 18.3315 9.93037 18.2482 10.104 18.0818L17.6501 10.8481C18.8259 9.72106 19.4735 8.22254 19.4735 6.62858C19.4736 5.03454 18.826 3.53602 17.6501 2.40898Z"
                          fill={!Boolean(error?.file) ? "#141E3C" : "#d32f2f"}
                        />
                      </svg>
                      Attach File
                    </>
                  )}
                </label>
                <input
                  accept=".pdf"
                  id="upload"
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: "none" }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "20px 24px",
          pt: "0",
        }}
      >
        <SubmitButton
          title="send"
          submit=""
          widthSize="81px"
          heightSize="35px"
          handleSubmit={handleSubmit}
          type="click"
        />
      </DialogActions>
    </Dialog>
  );
}

export default EditNotification;
