import React from 'react'

function TicketsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9494_3389)">
        <path
          d="M21.0318 6.01027L20.5346 6.50752C20.1283 6.91378 19.5881 7.13752 19.0135 7.13752C18.4389 7.13752 17.8988 6.91378 17.4925 6.50752C17.0863 6.10125 16.8625 5.56106 16.8625 4.98652C16.8625 4.41197 17.0863 3.87178 17.4925 3.46547L17.9898 2.96822L15.0215 0L0 15.0215L2.96822 17.9897L3.46547 17.4925C3.87173 17.0862 4.41192 16.8625 4.98652 16.8625C5.56111 16.8625 6.1012 17.0862 6.50752 17.4925C6.91378 17.8988 7.13752 18.4389 7.13752 19.0135C7.13752 19.588 6.91378 20.1282 6.50752 20.5345L6.01027 21.0318L8.97848 24L24 8.97848L21.0318 6.01027ZM7.95047 20.9829C8.33658 20.4044 8.544 19.7249 8.544 19.0135C8.544 18.0632 8.17397 17.1699 7.50202 16.498C6.83011 15.826 5.93672 15.456 4.98652 15.456C4.27514 15.456 3.59564 15.6634 3.01706 16.0495L1.989 15.0215L15.0215 1.989L16.0495 3.01706C15.6634 3.59564 15.456 4.27514 15.456 4.98652C15.456 5.93677 15.826 6.83011 16.498 7.50202C17.1699 8.17397 18.0633 8.544 19.0135 8.544C19.7249 8.544 20.4044 8.33658 20.9829 7.95047L22.011 8.97848L8.97848 22.011L7.95047 20.9829Z"
          fill="#ACB1C6"
        />
        <path
          d="M14.014 8.98793L13.0195 9.98242L14.018 10.9809L15.0125 9.9864L14.014 8.98793Z"
          fill="#ACB1C6"
        />
        <path
          d="M12.016 6.98988L11.0215 7.98438L12.02 8.98284L13.0144 7.98835L12.016 6.98988Z"
          fill="#ACB1C6"
        />
        <path
          d="M16.0101 10.984L15.0156 11.9785L16.0141 12.977L17.0086 11.9825L16.0101 10.984Z"
          fill="#ACB1C6"
        />
      </g>
      <defs>
        <clipPath id="clip0_9494_3389">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TicketsIcon