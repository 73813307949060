import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

function BulkUploadModal({
  open,
  handleClose,
  handleSubmit,
  title,
  downloadurl,
  name,
}) {
  const [bulkUploadFile, setBulkUploadFile] = useState("");
  const [bulkUploadError, setBulkUploadError] = useState("");

  const handleUpload = () => {
    if (bulkUploadFile == "") {
      setBulkUploadError("excel sheet is manadatory");
    } else {
      handleSubmit(bulkUploadFile, name);
      setBulkUploadError("");
      handleClose();
    }
  };

  const handleCloseButton = () => {
    setBulkUploadError("");
    setBulkUploadFile("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "100%",
          width: "850px",
          mt: "0px",
        },
        "& .MuiDialog-container": {
          alignItems: "start",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          //   alignItems: "center",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "20px",
              color: "#05050F",
              fontWeight: "700",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#ACB1C6",
            }}
          >
            Upload your data through xls file
          </Typography>
        </Box>
        <CloseIcon
          sx={{ width: "34px", height: "34px", cursor: "pointer" }}
          onClick={handleCloseButton}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          display: "grid",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              maxWidth: "368px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#141E3C",
                fontWeight: "600",
              }}
            >
              Step 1 : Download the sample xls file
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#ACB1C6",
              }}
            >
              Download the sample xls file by clicking the button. You can fill
              in that sample file
            </Typography>
          </Box>
          <a
            style={{ maxWidth: "303px", width: "100%", textDecoration: "none" }}
            href={downloadurl}
            target="_blank"
          >
            <Button
              component={"label"}
              sx={{
                maxWidth: "303px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                border: "1px solid #ECECEC",
                borderRadius: "8px",
                px: "30px",
                textTransform: "capitalize",
                color: "#05050F",
                py: "13px",
              }}
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 19 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.416 10.792C17.2061 10.792 17.0047 10.8754 16.8562 11.0239C16.7078 11.1723 16.6243 11.3737 16.6243 11.5837V14.9189C16.6237 15.5039 16.3911 16.0648 15.9774 16.4784C15.5638 16.892 15.0029 17.1247 14.418 17.1253H4.58072C3.99575 17.1247 3.43491 16.892 3.02128 16.4784C2.60764 16.0648 2.37498 15.5039 2.37435 14.9189V11.5837C2.37435 11.3737 2.29094 11.1723 2.14248 11.0239C1.99401 10.8754 1.79265 10.792 1.58268 10.792C1.37272 10.792 1.17136 10.8754 1.02289 11.0239C0.874423 11.1723 0.791016 11.3737 0.791016 11.5837V14.9189C0.792063 15.9237 1.19167 16.887 1.90215 17.5975C2.61263 18.308 3.57595 18.7076 4.58072 18.7087H14.418C15.4227 18.7076 16.3861 18.308 17.0965 17.5975C17.807 16.887 18.2066 15.9237 18.2077 14.9189V11.5837C18.2077 11.3737 18.1243 11.1723 17.9758 11.0239C17.8273 10.8754 17.626 10.792 17.416 10.792Z"
                  fill="#141E3C"
                />
                <path
                  d="M13.6896 8.64879L10.291 12.0474L10.291 2.0835C10.291 1.87354 10.2076 1.67217 10.0591 1.52371C9.91064 1.37524 9.70928 1.29184 9.49931 1.29184C9.28935 1.29184 9.08799 1.37524 8.93952 1.52371C8.79105 1.67217 8.70765 1.87354 8.70765 2.0835L8.70764 12.0474L5.30902 8.64879C5.15971 8.50458 4.95973 8.42479 4.75216 8.42659C4.54459 8.4284 4.34603 8.51166 4.19925 8.65844C4.05247 8.80522 3.96921 9.00378 3.9674 9.21135C3.9656 9.41892 4.04539 9.6189 4.1896 9.76821L8.9396 14.5182C9.08806 14.6666 9.28939 14.75 9.49931 14.75C9.70923 14.75 9.91056 14.6666 10.059 14.5182L14.809 9.76821C14.9532 9.6189 15.033 9.41892 15.0312 9.21135C15.0294 9.00378 14.9462 8.80522 14.7994 8.65844C14.6526 8.51166 14.454 8.4284 14.2465 8.42659C14.0389 8.42479 13.8389 8.50459 13.6896 8.64879Z"
                  fill="#141E3C"
                />
              </svg>
              Download sample
            </Button>
          </a>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              maxWidth: "368px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#141E3C",
                fontWeight: "600",
              }}
            >
              Step 2 : Upload the xls file
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#ACB1C6",
              }}
            >
              Download the edited xls file by clicking the button.
            </Typography>
          </Box>
          <Button
            component={"label"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              border: "1px solid #ECECEC",
              borderRadius: "8px",
              px: "30px",
              textTransform: "capitalize",
              color: "#05050F",
              maxWidth: "303px",
              width: "100%",
              py: "13px",
            }}
          >
            {!bulkUploadFile ? (
              <>
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6501 1.91191C15.223 -0.414648 11.2737 -0.414648 8.84647 1.91191L1.30006 9.14557C-0.433462 10.8075 -0.433348 13.5116 1.30037 15.1734C2.16728 16.0044 3.30564 16.4199 4.44446 16.4198C5.58298 16.4197 6.72188 16.0043 7.58868 15.1734L14.506 8.54265C15.5463 7.54566 15.5464 5.92318 14.5061 4.92583C13.4658 3.92872 11.7731 3.92876 10.733 4.92601L6.2094 9.26205C5.86211 9.59492 5.86211 10.1347 6.20933 10.4676C6.55669 10.8005 7.11968 10.8005 7.46704 10.4676L11.9907 6.13147C12.3374 5.79908 12.9017 5.79908 13.2484 6.13136C13.5951 6.46383 13.5951 7.00458 13.2483 7.33697L6.33108 13.9679C5.29076 14.9649 3.59824 14.9651 2.55801 13.9679C1.51781 12.9708 1.51769 11.3483 2.55778 10.3511L10.1041 3.11749C11.8379 1.45574 14.6588 1.45567 16.3925 3.11756C17.2324 3.92256 17.695 4.99295 17.6949 6.13143C17.6949 7.26996 17.2324 8.34031 16.3924 9.14538L8.84628 16.3791C8.49899 16.712 8.49899 17.2518 8.84635 17.5847C9.02002 17.7512 9.24761 17.8344 9.47517 17.8344C9.70273 17.8344 9.93037 17.7511 10.104 17.5847L17.6501 10.351C18.8259 9.22399 19.4735 7.72547 19.4735 6.13151C19.4736 4.53747 18.826 3.03895 17.6501 1.91191Z"
                    fill="#141E3C"
                  />
                </svg>
                Upload File
                <input
                  type="file"
                  hidden
                  onChange={(e) => setBulkUploadFile(e.target.files[0])}
                />
              </>
            ) : (
              <>
                {/* <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6501 1.91191C15.223 -0.414648 11.2737 -0.414648 8.84647 1.91191L1.30006 9.14557C-0.433462 10.8075 -0.433348 13.5116 1.30037 15.1734C2.16728 16.0044 3.30564 16.4199 4.44446 16.4198C5.58298 16.4197 6.72188 16.0043 7.58868 15.1734L14.506 8.54265C15.5463 7.54566 15.5464 5.92318 14.5061 4.92583C13.4658 3.92872 11.7731 3.92876 10.733 4.92601L6.2094 9.26205C5.86211 9.59492 5.86211 10.1347 6.20933 10.4676C6.55669 10.8005 7.11968 10.8005 7.46704 10.4676L11.9907 6.13147C12.3374 5.79908 12.9017 5.79908 13.2484 6.13136C13.5951 6.46383 13.5951 7.00458 13.2483 7.33697L6.33108 13.9679C5.29076 14.9649 3.59824 14.9651 2.55801 13.9679C1.51781 12.9708 1.51769 11.3483 2.55778 10.3511L10.1041 3.11749C11.8379 1.45574 14.6588 1.45567 16.3925 3.11756C17.2324 3.92256 17.695 4.99295 17.6949 6.13143C17.6949 7.26996 17.2324 8.34031 16.3924 9.14538L8.84628 16.3791C8.49899 16.712 8.49899 17.2518 8.84635 17.5847C9.02002 17.7512 9.24761 17.8344 9.47517 17.8344C9.70273 17.8344 9.93037 17.7511 10.104 17.5847L17.6501 10.351C18.8259 9.22399 19.4735 7.72547 19.4735 6.13151C19.4736 4.53747 18.826 3.03895 17.6501 1.91191Z"
                    fill="#141E3C"
                  />
                </svg> */}
                Uploaded_File.xls
                <CloseIcon
                  sx={{ width: "24px", height: "24px", cursor: "pointer" }}
                  onClick={() => setBulkUploadFile("")}
                />
              </>
            )}
          </Button>
          {/* {bulkUploadError !== "" && (
            <span
              style={{ color: "red", justifyContent: "end", alignItems: "end" }}
            >
              {bulkUploadError}
            </span>
          )} */}
        </Box>
      </DialogContent>
      <Box
        sx={{ borderBottom: "1px solid #ECECEC", mx: "24px", mt: "20px" }}
      ></Box>
      <DialogActions
        sx={{
          p: "32px",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#141E3C !important",
            color: "#fff",
            textTransform: "capitalize",
            fontWeight: "600",
            px: "15px",
          }}
          onClick={handleUpload}
        >
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkUploadModal;
