import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Reorder from "./Reorder";

function ApplicationAndStudentsTable() {
  function createData(
    intake,
    applications,
    appsPriorYear,
    appsComparison,
    students,
    studentsPriorYear,
    studentsPercentage
  ) {
    return {
      intake,
      applications,
      appsPriorYear,
      appsComparison,
      students,
      studentsPriorYear,
      studentsPercentage,
    };
  }
  const rows = [
    createData("Jan - Mar - 2023", 440, 360, "22%", 292, 247, "18%"),
    createData("Jan - Mar - 2023", 440, 360, "22%", 292, 247, "18%"),
    createData("Jan - Mar - 2023", 440, 360, "22%", 292, 247, "18%"),
    createData("Jan - Mar - 2023", 440, 360, "22%", 292, 247, "18%"),
  ];

  return (
    <Box sx={{}}>
      <Typography
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "700",
          // fontFamily: "Open-Sans",
        }}
      >
        Applications & students
      </Typography>
      <Box
        sx={{
          overflowX: "scroll",
          maxWidth: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& table": {
            minWidth: "1200px",
          },
        }}
      >
        <Table>
          <TableHead
            sx={{
              background: "#F9F9FB",
              "& th": {
                textTransform: "uppercase",
                fontWeight: "600",
                // fontFamily: "Inter",
                color: "#666E7D",
              },
            }}
          >
            <TableRow>
              <TableCell>
                sl no <Reorder />
              </TableCell>
              <TableCell>
                Intake <Reorder />
              </TableCell>
              <TableCell>
                Applications <Reorder />
              </TableCell>
              <TableCell>
                Apps prior year <Reorder />
              </TableCell>
              <TableCell>
                Apps comparison <Reorder />
              </TableCell>
              <TableCell>
                students <Reorder />
              </TableCell>
              <TableCell>
                students prior year <Reorder />
              </TableCell>
              <TableCell>
                students <Reorder />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              background: "#fff",
              border: "1px solid rgba(220, 223, 227, 0.50)",
            }}
          >
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  "& .MuiTableCell-root": {
                    borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
                  },
                }}
              >
                <TableCell>{i + 1}</TableCell>
                <TableCell>{row.intake}</TableCell>
                <TableCell>{row.applications}</TableCell>
                <TableCell>{row.appsPriorYear}</TableCell>
                <TableCell>{row.appsComparison}</TableCell>
                <TableCell>{row.students}</TableCell>
                <TableCell>{row.studentsPriorYear}</TableCell>
                <TableCell>{row.studentsPercentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

export default ApplicationAndStudentsTable;
