import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TopicsTable from "./topicsTable";
import EntrolledFilterEmployee from "../IeltsEntrolled/ieltsEntorlledFilterSearch";
import { MdClear } from "react-icons/md";
import { ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { AiOutlineSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { ieltsLearningManagmentFindTopicAction } from "../../../actions/IeltsActions/learningManadmentAction";

function TopicsMain({ searchvalue }) {
  const dispatch = useDispatch();
  const [topicList, setTopicList] = useState([]);
  const [secListopen, setSecListOpen] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [categoryList, setCategoyList] = useState([
    "Listening",
    "Reading",
    "Speaking",
    "Writing",
    "Grammar",
    "Exam Tips",
    "Question Bank",
  ]);

  let {
    ieltsLearningManagmentFindTopicSuccess,
    ieltsLearningManagmentFindTopicerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentFindTopic;
  });
  let {
    ieltsLearningManagmentCreateTopicSuccess,
    ieltsLearningManagmentCreateTopicerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentCreateTopic;
  });
  let {
    ieltsLearningManagmentEntireTopicDeleteSuccess,
    ieltsLearningManagmentEntireTopicDeleteerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentEntireTopicDelete;
  });
  let {
    ieltsLearningManagmentTopicStatusChangeSuccess,
    ieltsLearningManagmentTopicStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentTopicsStatusChange;
  });
  useEffect(() => {
    dispatch(ieltsLearningManagmentFindTopicAction());
  }, [
    dispatch,
    ieltsLearningManagmentCreateTopicSuccess,
    ieltsLearningManagmentTopicStatusChangeSuccess,
    ieltsLearningManagmentEntireTopicDeleteSuccess,
  ]);

  useEffect(() => {
    if (ieltsLearningManagmentFindTopicSuccess) {
      setTopicList(ieltsLearningManagmentFindTopicSuccess.data);
    }
  }, [ieltsLearningManagmentFindTopicSuccess]);

  useEffect(() => {
    if (searchvalue !== "") {
      console.log(searchvalue);
      let filterdata = ieltsLearningManagmentFindTopicSuccess?.data?.filter((item) => {
        return item?.TopicName?.toLowerCase().includes(
          searchvalue?.toLowerCase()
        );
      });
      setTopicList(filterdata);
    } else {
      setTopicList(ieltsLearningManagmentFindTopicSuccess?.data);
    }
  }, [searchvalue]);

  // console.log(topicList, "topicListttttttttttttttttt");
  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "TOPIC NAME", field: "topic name" },
    { title: "CATEGORY", field: "category Name" },
    { title: "STATUS", field: "status" },
  ];

  // const handleCheckCountry = (event, branchId, CheckedValue) => {
  //   if (event !== "gender") {
  //     setSelectedCategory(branchId === selectedCountry ? null : branchId);
  //     //   props.LeadFilterFunction(branchId, CheckedValue, event.target.checked);

  //     const searchText = event.target.cheked;

  //     if (searchText === false) {
  //       // setCountryList(uniqueCountries); // Reset to the original data when search text is empty
  //     }
  //   } else {
  //     //   props.LeadFilterFunction(branchId.target.value, "gender", true);
  //   }
  // };

  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus) => {
    setSelectedCategory(filterValue === selectedCategory ? null : filterValue);
    if (checkedStatus) {
      setTopicList(
        ieltsLearningManagmentFindTopicSuccess?.data?.filter(
          (topics, index) => {
            return filterValue == topics.Category;
          }
        )
      );
    } else {
      setTopicList(ieltsLearningManagmentFindTopicSuccess.data);
    }
  };

  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setTopicList(ieltsLearningManagmentFindTopicSuccess?.data);
    setSelectedCategory(null);
  };

  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };

  const [ids, setIds] = useState([]);

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  const handleDelete = () => {
    alert("You deleted the chip");
  };



  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };

  return (
    <Box
      sx={{
        mt: 1,
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          lg: "330px 1fr",
        },
        gap: "25px",
      }}
    >
      <Box>
        {selectedCategory ? (
          <Chip
            sx={{
              "&.MuiChip-root": {
                pl: "5px",
                pr: "5px",
              },
              mt: "10px",
            }}
            label={
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "700",
                  color: "rgba(88, 94, 106, 1)",
                }}
              >
                Category:{" "}
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                  }}
                  component={"span"}
                >
                  {selectedCategory}
                </Typography>
              </Typography>
            }
            onDelete={handleClearAllFunction}
          />
        ) : (
          ""
        )}
        <Box
          sx={{
            " & .MuiListSubheader-root": {
              backgroundColor: "#F2F2F2",
              mt: "24px ",
            },
          }}
        >
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div>Filter</div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "12px",
                      cursor: "pointer",
                    }}
                    //   onClick={handleClearClick}
                  >
                    <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                    <Typography onClick={handleClearAllFunction}>
                      Clear All
                    </Typography>
                  </div>
                </div>
              </ListSubheader>
            }
          >
            <Collapse
              in={secListopen}
              timeout="auto"
              unmountOnExit
              sx={{
                mt: "20px",
              }}
            >
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        width: "100%",
                      },
                      width: "100%",
                    }}
                  >
                    {categoryList &&
                      categoryList?.map((category, i) => (
                        <FormControlLabel
                          key={i}
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={category === selectedCategory}
                              onChange={(e) => {
                                handleFilterFunction(
                                  category,
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label={category}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    mt: "20px",
                    "& input": {
                      width: "30px",
                      outline: "none",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                    }}
                  >
                    Rows Per Page :{" "}
                  </Typography>
                  <input
                    type="number"
                    value={page}
                    onChange={handlePageSearch}
                  />
                </Box>
                {pageError && (
                  <Typography
                    sx={{
                      fontSize: 10,
                      color: "red",
                    }}
                  >
                    {pageError}
                  </Typography>
                )}
              </List>
            </Collapse>
          </List>
        </Box>
      </Box>

      <TopicsTable
        data={topicList}
        columns={columns}
        component="ieltsCategory"
        selectBoxIdPassHandler={selectBoxIdPassHandler}
        pageNumber={page}
        subMenu = "learning managment"
      />
    </Box>
  );
}

export default TopicsMain;
