import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Checkbox,
  Box,
  MenuItem,
  Pagination,
  Typography,
  Rating,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { counsilorRatingAction } from "../../../actions/ratingActions";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

function CounsilorRatingTable() {
  const dispatch = useDispatch();

  let { counselorRatingFindSuccess } = useSelector(
    (state) => state.counselorRatingsFind
  );

  useEffect(() => {
    dispatch(counsilorRatingAction(0));
  }, [dispatch]);

  useEffect(() => {
    if (counselorRatingFindSuccess) {
      setData(counselorRatingFindSuccess.data);
    }
  }, [counselorRatingFindSuccess]);

  const columns = [
    "Sl No",
    "Name",
    "Counsellor Name",
    "Satisfied With Counsilor Support",
    "Satisfication Level",
    "Ratings",
    "Feedback",
  ];
  // const data = [
  //   {
  //     id: 1,
  //     name: "savad",
  //     counsilorName: "vishnu",
  //     isSatisfied: "No",
  //     satisficationLevel: "Not Satisfied",
  //     rating: "1",
  //     feedback: "Need to improve a lot",
  //   },
  // ];

  const [data, setData] = useState([]);
  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const openActionButton = Boolean(actionButtonShow);

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  const EditHandler = (item) => {
    console.log(item);
  };
  const DeleteHandler = (item) => {};

  const handleClickAction = (event) => {
    setActionButtonShow(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return data.length > 0 ? (
    <>
      <Box
        sx={{
          mt: "20px",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingRight: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            fontWeight: "400",
            mb: "10px",
          }}
        >
          Showing{" "}
          <Typography
            component={"span"}
            sx={{
              backgroundColor: "#fff",
              padding: "5px",
              paddingLeft: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              // lineHeight: "24px",
              fontWeight: "700",
            }}
          >
            {data.length} Result
          </Typography>
        </Typography>
        <Box
          sx={{
            overflowX: "scroll",
          }}
        >
          <Table
            className="masterTable"
            sx={{
              minWidth: "1400px",
            }}
          >
            <TableHead>
              <TableRow className="msterTableCell">
                <TableCell>
                  <Checkbox
                    //   checked={selectedRows.length === sortedData.length}
                    //   onChange={handleSelectAllRows}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                {columns?.map((column) => {
                  return (
                    <TableCell key={column}>
                      <TableSortLabel
                        sx={{
                          fontWeight: "700",
                          textTransform: "uppercase",
                          fontSize: "13px",
                        }}
                      >
                        {column}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <TableRow className="tableRow" key={item.id}>
                  <TableCell>
                    <Checkbox
                      // checked={selectedRows.includes(item.id)}
                      // onChange={() => handleSelectRow(item.id)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.leadName}</TableCell>
                  <TableCell>{item.counsilorName}</TableCell>
                  <TableCell>{item.isSatisfiedWithCouncilor}</TableCell>
                  <TableCell>{item.isSatisfiedWithService}</TableCell>
                  <TableCell>
                    <Rating
                      name="read-only"
                      value={item?.starRating}
                      readOnly
                      size="large"
                      sx={{
                        "& svg": {
                          width: "25px",
                          height: "25px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ maxWidth: "380px" }}>
                    {item.feedBack}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter />
          </Table>
        </Box>
        <Pagination
          sx={{
            mt: "28px",
            "& .Mui-selected": {
              backgroundColor: "#f81b82 !important",
              color: "#fff",
            },
            "& .MuiPagination-ul": {
              justifyContent: "end",
            },
          }}
          count={Math.ceil(data?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  ) : (
    <TableNoItemComponent />
  );
}

export default CounsilorRatingTable;
