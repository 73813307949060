import React, { useEffect, useState } from "react";
import BasicTabs from "../../Tabs/TabsMain";
import { Box, Button, Chip, Dialog, Divider, Typography } from "@mui/material";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import TeacherModalListItems from "./IeltesCutomComponents/TeacherModalListItems";
import { useDispatch, useSelector } from "react-redux";
import {
  ieltsCenterAddTeacherModalUpdateAction,
  ieltsCenterManagmentCenterFindByIdAction,
  ieltsCenterManagmentCenterSlotUpdateAction,
  ieltsCenterManagmentCenterTeacherDeleteAction,
} from "../../../actions/IeltsActions/centerManagmentAction";
import { useParams } from "react-router-dom";
import { SlOptionsVertical } from "react-icons/sl";
import Dialogue from "../../customComponent/Dialogue";
import { RxCross1 } from "react-icons/rx";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import CloseIcon from "@mui/icons-material/Close";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import TeacherModal from "./IeltesCutomComponents/TeacherModal";
import { ieltsTeacherManagmentFindAction } from "../../../actions/IeltsActions/teacherManagmentAction";

function IeltsCeterDetailedView({ toggle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [centerDetails, setCenterDetails] = useState({});
  const [centerTimeByWeek, setTimeByWeek] = useState([]);

  // Find Teachers

  let { ieltsCenterAddTeacherModalUpdateSuccess } = useSelector((state) => {
    return state.ieltsCenterAddTeacherModalUpdate;
  });

  let {
    ieltsTeacherManagmentTeacherFindSuccess,
    ieltsTeacherManagmentTeacherFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherFind;
  });

  useEffect(() => {
    dispatch(ieltsTeacherManagmentFindAction("centerComponent"));
  }, [dispatch]);

  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [AddTeacherModalData, setAddTeacherModalData] = useState({
    name: "",
    location: "",
    center: [],
    selectedTeacher: [],
  });

  let [openTeacherModal, setOpenTeacherModal] = useState(false);

  const handleTeacherModalClose = () => {
    setOpenTeacherModal(false);
  };

  const handleSelectedTeacher = (value) => {
    let resultValue = {
      centerId: id,
      teacherID: value,
    };
    dispatch(ieltsCenterAddTeacherModalUpdateAction(resultValue));
    setSelectedTeacher([])
    setOpenTeacherModal(false);
  };

  // -------------------------------------------------------------------------------------------

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsCenterManagmentCenterSlotUpdateSuccess,
    ieltsCenterManagmentCenterSlotUpdateerror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterSlotUpdate;
  });

  let {
    ieltsCenterManagmentCenterTeacherDeleteSuccess,
    ieltsCenterManagmentCenterTeacherDeleteerror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterTeacherDelete;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsCenterManagmentCenterSlotUpdateSuccess?.status == true
    ) {
      Swal.fire(
        "Updated",
        ieltsCenterManagmentCenterSlotUpdateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsCenterManagmentCenterSlotUpdateSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsCenterManagmentCenterSlotUpdateSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsCenterManagmentCenterSlotUpdateSuccess, successAlertMsgSuccess]);

  const handleDelete = (index) => {
    dispatch(
      ieltsCenterManagmentCenterTeacherDeleteAction(index, centerDetails?.id)
    );
  };

  //  Find center details

  let {
    ieltsCenterManagmentCenterFindByIdSuccess,
    ieltsCenterManagmentCenterFindByIderror,
  } = useSelector((state) => {
    return state.ieltsCenterManagmentCenterFindById;
  });

  useEffect(() => {
    dispatch(ieltsCenterManagmentCenterFindByIdAction(id));
  }, [
    dispatch,
    ieltsCenterManagmentCenterSlotUpdateSuccess,
    ieltsCenterManagmentCenterTeacherDeleteSuccess,
    ieltsCenterAddTeacherModalUpdateSuccess,
  ]);

  useEffect(() => {
    if (
      ieltsCenterManagmentCenterFindByIdSuccess &&
      ieltsTeacherManagmentTeacherFindSuccess
    ) {
      const updatedSelectedTeacher =
        ieltsCenterManagmentCenterFindByIdSuccess?.data?.ielts_center_teachers.map(
          (teacher) => teacher.ielts_teacher
        );

      const filteredModalData =
        ieltsTeacherManagmentTeacherFindSuccess.data.filter(
          (item1) =>
            !updatedSelectedTeacher.some((item2) => item1.id === item2.id)
        );

      setAddTeacherModalData(filteredModalData);
    }
  }, [
    ieltsCenterManagmentCenterFindByIdSuccess,
    ieltsTeacherManagmentTeacherFindSuccess,
  ]);

  useEffect(() => {
    if (ieltsCenterManagmentCenterFindByIdSuccess) {
      setCenterDetails(ieltsCenterManagmentCenterFindByIdSuccess?.data);

      const groupedByWeek =
        ieltsCenterManagmentCenterFindByIdSuccess?.data?.ielts_center_slots?.reduce(
          (acc, slot) => {
            const { id, week, timeFrom, timeTo, seatAvailability } = slot;

            if (!acc[week]) {
              acc[week] = [];
            }

            acc[week].push({ id, timeFrom, timeTo, seatAvailability });

            return acc;
          },
          {}
        );

      const timeByWeek = Object.entries(groupedByWeek).map(([week, times]) => ({
        week,
        times,
      }));

      setTimeByWeek(
        ieltsCenterManagmentCenterFindByIdSuccess?.data?.ielts_center_slots
      );

      setSlotArray(
        ieltsCenterManagmentCenterFindByIdSuccess?.data?.ielts_center_slots
      );
    }
  }, [dispatch, ieltsCenterManagmentCenterFindByIdSuccess]);

  // useEffect(() => {
  //   setModalData(
  //     ieltsCenterManagmentCenterFindByIdSuccess?.data.ielts_center_slots
  //   );
  // }, [ieltsCenterManagmentCenterFindByIdSuccess]);

  const [openModal, setOpenModal] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [slotValue, setSlotValue] = useState({
    timeFrom: "",
    timeTo: "",
    seatAvailability: "",
  });
  const [error, setError] = useState("");
  const [modalData, setModalData] = useState();
  const [slotArray, setSlotArray] = useState();
  const [deleteIds, setDeleteIds] = useState([]);

  const handleModal = (index) => {
    setOpenModal(true);
    setModalData(centerTimeByWeek[index]);
    setItemIndex(index);
    console.log(centerTimeByWeek[index]);
  };

  const handleModalClose = (index) => {
    setItemIndex(index);
    setOpenModal(false);
  };

  const day = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "Thursday",
    "friday",
    "saturday",
  ];

  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [availability, setavailability] = useState("");

  const hours = Array.from({ length: 24 }, (_, index) => index);

  const convertTo12HourFormat = (hour) => {
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const period = hour < 12 ? "AM" : "PM";
    return `${hour12 === 12 ? 12 : hour12}:00 ${period}`;
  };

  const getAvailableToTimes = () => {
    if (!fromTime) {
      return hours;
    }
    const fromIndex = parseInt(fromTime, 10);
    return hours.filter((hour) => hour > fromIndex);
  };

  const convertTo24HourFormat = (timeString) => {
    if (typeof timeString !== "number" && timeString !== "") {
      const [hourString, period] = timeString?.split(" ");
      const [hour, minute] = hourString?.split(":").map(Number);

      if (period === "AM" && hour === 12) {
        return 0;
      }

      if (period === "PM" && hour < 12) {
        return hour + 12;
      }

      return hour;
    } else {
      return timeString;
    }
  };

  const classSubmit = (e) => {
    e.preventDefault();
    if (
      (modalData.timeFrom || modalData.timeFrom == 0) &&
      (modalData.timeTo || modalData.timeTo === 0) &&
      modalData.seatAvailability
    ) {
      setSlotArray((prev) => {
        const updatedTimeByWeek = [...prev];
        updatedTimeByWeek[itemIndex] = {
          ...updatedTimeByWeek[itemIndex],
          timeFrom: modalData.timeFrom,
          timeTo: modalData.timeTo,
          seatAvailability: modalData.seatAvailability,
        };
        return updatedTimeByWeek;
      });
      console.log(modalData, "modalDatamodalDatamodalData");
      dispatch(ieltsCenterManagmentCenterSlotUpdateAction(modalData));
      handleModalClose();
    } else {
      setError(
        "Please select both 'From Time','To Time' and 'availability' before confirming."
      );
    }
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    if (slotArray[itemIndex].times.length > 0) {
      setTimeByWeek(slotArray);
      handleModalClose();
      setError("");
      const newData = [];
      slotArray.forEach((entry) => {
        const week = entry.week;
        entry.times.forEach((timeEntry) => {
          const transformedEntry = {
            centerId: id,
            week: week,
            id: timeEntry.id,
            timeFrom: timeEntry.timeFrom,
            timeTo: timeEntry.timeTo,
            seatAvailability: timeEntry.seatAvailability,
          };
          newData.push(transformedEntry);
        });
      });
      console.log(newData, "OIJOIHOHOIH");
      dispatch(ieltsCenterManagmentCenterSlotUpdateAction(newData, deleteIds));
      dispatch(successTrueMsgAction(true));
    } else {
      setError("timeslot cannot be 0");
    }
  };

  // console.log(slotArray, "slotArray");
  const handlechange = (e) => {
    const { name, value } = e.target;
    if (name === "seatAvailability" && !/^\d+$/.test(value)) {
      setError("Please enter a valid number");
      return;
    }
    if (name === "seatAvailability") {
      setModalData((prevSlots) => ({
        ...prevSlots,
        [name]: value,
      }));
      setError("");
      return;
    }

    if (name != "week") {
      setModalData((prevSlots) => ({
        ...prevSlots,
        [name]: convertTo12HourFormat(value),
      }));
      setError("");
    }
  };

  const handleSlotDelete = (itemIndex, index) => {
    console.log(slotArray, "slotArray");
    console.log(index, "indexindex");
    setSlotArray((prev) => [
      ...prev.slice(0, itemIndex),
      {
        ...prev[itemIndex],
        times: prev[itemIndex].times.filter((_, i) => i !== index),
      },
      ...prev.slice(itemIndex + 1),
    ]);

    const deletedValues = slotArray[0].times.filter((value, i) => i === index);

    setDeleteIds((prev) => [...prev, ...deletedValues]);
  };

  const tab1 = (
    <>
      {openModal === true && (
        <Dialog
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "632px",
              height: "300px",
            },
          }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <RxCross1
              style={{
                width: "20px",
                height: "auto",
                margin: "15px",
                cursor: "pointer",
              }}
              onClick={handleModalClose}
            />
          </div>
          <Box
            sx={{
              padding: "20px",
            }}
          >
            {error !== " " && (
              <Typography sx={{ color: "red", mb: "12px", fontSize: "12px" }}>
                {error}
              </Typography>
            )}
            <Box
              component={"form"}
              onSubmit={classSubmit}
              sx={{
                display: "grid",
                gridTemplateColumns: "110px 110px 1fr 108px",
                gap: "17px",
                maxWidth: "632px",
              }}
            >
              {/* <SelectInputField
                mapValue={day}
                value={modalData?.week}
                name="week"
                mapMethod={"slots"}
                label={"Select Day"}
                handleSelectChange={handlechange}
              /> */}
              <SelectInputField
                value={convertTo24HourFormat(modalData?.timeFrom)}
                name="timeFrom"
                label={"From"}
                mapValue={hours}
                mapMethod={"selectFromTime"}
                handleSelectChange={handlechange}
                convertTo12HourFormat={convertTo12HourFormat}
              />
              <SelectInputField
                value={convertTo24HourFormat(modalData?.timeTo)}
                name="timeTo"
                label={"To"}
                mapValue={getAvailableToTimes()}
                mapMethod={"selectFromTime"}
                handleSelectChange={handlechange}
                convertTo12HourFormat={convertTo12HourFormat}
                // disabled={fromTime}
              />
              <Box
                sx={{
                  "& .input-group": {
                    marginBottom: "0",
                  },
                }}
              >
                <InputField
                  name="seatAvailability"
                  handleChange={handlechange}
                  value={modalData?.seatAvailability}
                  label="Seat Availability"
                  widthSize="calc(100% - 12px)"
                  paramses
                  InputType="text"
                  dispcriptionHeight
                />
              </Box>
              <Button
                type="submit"
                sx={{
                  maxWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(236, 236, 236, 1)",
                  height: "47px",
                }}
                onClick={classSubmit}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(20, 30, 60, 1)",
                  }}
                >
                  Add Slot
                </Typography>
              </Button>
              {/* <Button
                sx={{
                  maxWidth: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(236, 236, 236, 1)",
                  height: "47px",
                }}
                onClick={handleConfirmation}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(20, 30, 60, 1)",
                  }}
                >
                  Save Day
                </Typography>
              </Button> */}
            </Box>
          </Box>
        </Dialog>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "10px",
          mb: "10px",
        }}
      >
        {slotArray?.map((slot, i) => (
          <Box
            key={i}
            sx={{
              padding: "12px",
              borderRadius: "4px",
              border: "1px solid #141E3C",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& svg": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#141E3C",
                  textTransform: "capitalize",
                }}
              >
                Slot {i + 1}
              </Typography>
              <SlOptionsVertical onClick={() => handleModal(i)} />
            </Box>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#686868",
                textTransform: "capitalize",
              }}
            >
              {slot.timeFrom} to {slot.timeTo}
            </Typography>
            {/* <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#686868",
                textTransform: "capitalize",
              }}
            >
              {"Seat Availability :" + slot.seatAvailability}
            </Typography> */}
            {/* <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {slot.times.map((item, i) => (
                <Chip
                  key={i}
                  sx={{
                    "& .MuiChip-label": {
                      pl: "8px",
                      pr: "8px",
                    },
                    "&.MuiChip-root": {
                      borderRadius: "8px",
                      border: "1px solid rgba(206, 206, 206, 1)",
                      backgroundColor: "rgba(255, 255, 255,1)",
                    },
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        padding: "6px",
                        color: "rgba(20, 30, 60, 1)",
                      }}
                    >
                      <Typography>
                        {item.timeFrom} - {item.timeTo}
                      </Typography>
                      <Divider orientation="vertical" flexItem />
                      <Typography>{item.seatAvailability} slots</Typography>
                    </Box>
                  }
                />
              ))}
            </Box> */}
          </Box>
        ))}
      </Box>
    </>
  );
  const tab2 = (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr ",
            md: "1fr 1fr",
            lg: "1fr 1fr 1fr 1fr",
            xl: "1fr 1fr 1fr 1fr 1fr",
          },
          gap: "20px",
        }}
      >
        {centerDetails?.ielts_center_teachers?.map((item, i) => (
          <TeacherModalListItems
            listview={true}
            handleDeleteTeacher={handleDelete}
            id={i}
            item={item}
          />
        ))}

        <TeacherModal
          data={AddTeacherModalData}
          selectedTeacher={selectedTeacher}
          selectModal={openTeacherModal}
          handleClose={handleTeacherModalClose}
          handleSelectedTeacher={handleSelectedTeacher}
        />

        <Box
          sx={{
            padding: "8px",
            boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "12px",
            position: "relative",
            cursor: "pointer",
            height: "92px",
            maxWidth: "250px",
            boxSizing: "border-box",
          }}
          onClick={() => setOpenTeacherModal(true)}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Add Teachers
          </Typography>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="11.1953"
              width="24"
              height="3.06383"
              rx="1.53191"
              fill="#3B478F"
            />
            <rect
              x="13.5332"
              y="0.726562"
              width="24"
              height="3.06383"
              rx="1.53191"
              transform="rotate(90 13.5332 0.726562)"
              fill="#3B478F"
            />
          </svg>
        </Box>
      </Box>
    </>
  );

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle={centerDetails?.CenterName || ""} url="/IELTS" />
      <Box sx={{}}>
        <BasicTabs
          tab1Name={"Slots"}
          tab2Name={"Teachers"}
          tab1={<>{tab1}</>}
          tab2={<>{tab2}</>}
        />
      </Box>
    </MainContainer>
  );
}

export default IeltsCeterDetailedView;
