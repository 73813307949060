import React, { useEffect, useState } from "react";
import { MainContainer } from "../../../content/content.element";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ieltsSettingsFaqFindbyIdAction } from "../../../../actions/IeltsActions/SettingsAction";

function FaqSingleItem({ toggle }) {
  const [faqData, setFaqData] = useState();
  const dispatch = useDispatch();
  let { id } = useParams();

  let { ieltsSettingsFaqFindByIdSuccess } = useSelector((state) => {
    return state.ieltsSettingsFaqFindById;
  });

  useEffect(() => {
    dispatch(ieltsSettingsFaqFindbyIdAction(id));
  }, []);

  useEffect(() => {
    if (ieltsSettingsFaqFindByIdSuccess) {
      console.log(ieltsSettingsFaqFindByIdSuccess.data);
      setFaqData(ieltsSettingsFaqFindByIdSuccess.data);
    }
  }, [ieltsSettingsFaqFindByIdSuccess]);

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="FAQ" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            gap: "20px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              maxWidth: "950px",
            }}
          >
            <Typography
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              {faqData?.question}
            </Typography>
            <Typography
              sx={{
                mt: "24px",
              }}
            >
              {faqData?.answer}
            </Typography>
          </Box>
        </Box>
      </div>
    </MainContainer>
  );
}

export default FaqSingleItem;
