import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";

import { useDispatch, useSelector } from "react-redux";
import {
  sceduleMeetingFindAction,
  slotFindAction,
} from "../../../actions/ticket/sceduleMeeting";
import SlotTable from "./SlotTable";
import SlotHeader from "./SlotHeader";
import SlotFilterSearch from "./SlotFilterSearch";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function MySlotsMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [filterPassData, setFilterPassData] = useState();
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Response

  let { slotFindSuccess, slotFinderror } = useSelector((state) => {
    return state.SlotFind;
  });

  let { slotStatusFindSuccess } = useSelector((state) => {
    return state.slotStatusChange;
  });

  let { loginFindLoading, loginFindSuccess, loginFinderror } = useSelector(
    (state) => {
      return state.findUser;
    }
  );

  useEffect(() => {
    dispatch(slotFindAction());
  }, [dispatch, slotStatusFindSuccess]);

  useEffect(() => {
    if (slotFindSuccess) {
      setData(slotFindSuccess?.data);
    }
  }, [slotFindSuccess]);

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "DATE",
      field: "DATE",
    },
    {
      title: "SLOTS",
      field: "SLOTS",
    },
    {
      title: "STATUS",
      field: "STATUS",
    },
  ];

  const counsilor = {
    title: "COUNSILOR",
    field: "COUNSILOR",
  };

  if (loginFindSuccess?.fk_role_id == "admin") {
    columns.splice(3, 0, counsilor);
  }

  // filter
  const LeadFilterFunction = (date, filterOption) => {
    console.log(date, "date", filterOption, "option");
    if (filterOption == "date") {
      setData(
        slotFindSuccess?.data?.filter(
          (item) => item.date?.split("T")[0] == date
        )
      );
    }
    if (filterOption == "status") {
      setData(slotFindSuccess?.data?.filter((item) => item.status == date));
    }
    setwhichFilter(filterOption);
    setFilterPassData(data);
  };

  // Reset data

  const handleResetData = (status) => {
    setData(slotFindSuccess?.data);
  };

  // const data = [
  //   {
  //     name: "Shabeer Muhammed",
  //     course: "Bachelor in compurter application ",
  //     Counsellor: "Vidya Raj",
  //     Scheduled: "15 / 02 / 2023",
  //     time: "10 am - 11 am",
  //     status: "Approved",
  //   },
  //   {
  //     name: "Shabeer Muhammed",
  //     course: "Bachelor in compurter application ",
  //     Counsellor: "Vidya Raj",
  //     Scheduled: "15 / 02 / 2023",
  //     time: "10 am - 11 am",
  //     status: "Approved",
  //   },
  //   {
  //     name: "Shabeer Muhammed",
  //     course: "Bachelor in compurter application ",
  //     Counsellor: "Vidya Raj",
  //     Scheduled: "15 / 02 / 2023",
  //     time: "10 am - 11 am",
  //     status: "Approved",
  //   },
  // ];

  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "slotList");
  };

  const pdfDownloadClick = () => {
    DownloadPdf(columns, data, "slotList", "slotList");
  };

  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };

  if (!hasPrivilege("slots") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("slots"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <div>
      <SlotHeader
        pdfDownloadClick={pdfDownloadClick}
        excelDownloadClick={excelDownloadClick}
      />
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <SlotFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
          />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              mt: "20px",
              "& input": {
                width: "30px",
                outline: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              Rows Per Page :{" "}
            </Typography>
            <input type="number" value={page} onChange={handlePageSearch} />
          </Box>
          {pageError && (
            <Typography
              sx={{
                fontSize: 10,
                color: "red",
              }}
            >
              {pageError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <SlotTable
            columns={columns}
            data={data}
            pageNumber={page}
            subMenu="slots"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default MySlotsMain;
