import React, { useEffect, useState } from "react";
import BonusMenu from "./BonusMenu";
import { BonusTable } from "./BonusTable";
import { Alert, AlertTitle, Box } from "@mui/material";
import { findAllEmployeeAction } from "../../../actions/employeeManagmentAction";
import { listBonusEndrolledAppEmployeeAction } from "../../../actions/aplicationActions/applicationsAction.js";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck.js";

function BonusMain() {
  const dispatch = useDispatch();

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [data, setData] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { bonusEmployeesSuccess } = useSelector((state) => {
    return state.listBonusEndrolledAppEmployee;
  });

  let columns = [
    {
      title: "SL NO",
      field: "SL NO",
    },
    {
      title: "ID",
      field: "ID",
    },
    {
      title: "NAME",
      field: "NAME",
    },
    {
      title: "ROLE",
      field: "ROLE",
    },
    {
      title: "ENROLLMENT COUNT",
      field: "ENROLLMENT COUNT",
    },
    // {
    //   title: "DATE ",
    //   field: "DATE ",
    // },

    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];

  useEffect(() => {
    if (bonusEmployeesSuccess && bonusEmployeesSuccess !== undefined) {
      let results = bonusEmployeesSuccess?.map((item) => ({
        id: item?.user?.id,
        employeeId: item?.user?.employeeId ? item?.user?.employeeId : "-",
        name: item?.user?.firstName ? item?.user?.firstName : "-",
        role: item?.user?.role?.roleName ? item?.user?.role?.roleName : "-",
        enrollmentCount: item?.leads?.length ? item?.leads?.length : "0",
        // date: "20 / 02 / 2023",
      }));
      setAllEmployeeData(results);
      setData(results);
    }
  }, [bonusEmployeesSuccess]);

  useEffect(() => {
    dispatch(listBonusEndrolledAppEmployeeAction());
  }, [dispatch]);

  const handleSearch = (e) => {
    let filteredValues = allEmployeeData?.filter((row) =>
      row.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setData(filteredValues);
  };

  if (!hasPrivilege("bonus") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <Box>
      <BonusMenu handleSearch={handleSearch} />
      <Box
        sx={{
          mt: "26px",
          overflow: "hidden",
        }}
      >
        <BonusTable columns={columns} data={data} subMenu="bonus" />
      </Box>
    </Box>
  );
}

export default BonusMain;
