import axios from "axios";
import {
  ADD_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
  ADD_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
  ADD_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST,
  ADD_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
  ADD_COST_ESTIMATE_OF_COUNTRY_ERR,
  ADD_COST_ESTIMATE_OF_COUNTRY_REQUEST,
  ADD_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
  ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_DOCUMENT_OF_COUNTRY_ERR,
  ADD_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR,
  ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST,
  ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS,
  ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
  ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
  ADD_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
  COUNTRY_DOC_ADD_ERR,
  COUNTRY_DOC_ADD_REQUEST,
  COUNTRY_DOC_ADD_SUCCESS,
  COUNTRY_DOC_DELETE_ERR,
  COUNTRY_DOC_DELETE_REQUEST,
  COUNTRY_DOC_DELETE_SUCCESS,
  COUNTRY_DOC_FIND_ERR,
  COUNTRY_DOC_FIND_REQUEST,
  COUNTRY_DOC_FIND_SUCCESS,
  DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
  DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST,
  DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
  DELETE_COST_ESTIMATE_OF_COUNTRY_ERR,
  DELETE_COST_ESTIMATE_OF_COUNTRY_REQUEST,
  DELETE_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
  DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_SOP_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_SOP_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_SOP_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
  FIND_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
  FIND_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST,
  FIND_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
  FIND_COST_ESTIMATE_OF_COUNTRY_ERR,
  FIND_COST_ESTIMATE_OF_COUNTRY_REQUEST,
  FIND_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
  FIND_CRIME_DOCUMENT_OF_COUNTRY_ERR,
  FIND_CRIME_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_CRIME_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_DOCUMENT_OF_COUNTRY_ERR,
  FIND_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR,
  FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST,
  FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS,
  FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
  FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  FIND_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
  FIND_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
  PAYMENT_GETWAY_ADD_OF_COUNTRY_ERR,
  PAYMENT_GETWAY_ADD_OF_COUNTRY_REQUEST,
  PAYMENT_GETWAY_ADD_OF_COUNTRY_SUCCESS,
} from "../../constants/knowledgeBankConstant/countryDocsConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

// LoginSubmitAction
export const submitCountryDocsAddAction =
  (DocId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COUNTRY_DOC_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/CountryDocAdd",
        { DocId: DocId },
        config
      );

      dispatch({ type: COUNTRY_DOC_ADD_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({ type: COUNTRY_DOC_ADD_ERR, payload: error.response.data });
    }
  };

//   CountryDocFindAction
export const CountryDocFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRY_DOC_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/knowledgeBank/CountryDocFind",
      config
    );

    dispatch({ type: COUNTRY_DOC_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: COUNTRY_DOC_FIND_ERR, payload: error.response.data });
  }
};

// countryDocDeleteAction
export const countryDocDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRY_DOC_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/knowledgeBank/CountryDocDelete?id=${id}`,
      config
    );

    dispatch({ type: COUNTRY_DOC_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: COUNTRY_DOC_DELETE_ERR, payload: error.response.data });
  }
};

// countryDocumentSOPApplicationAddAction
export const countryDocumentSOPApplicationAddAction =
  (docName, previewImage, docId) => async (dispatch, getState) => {
    console.log(docId, "docIddocIddocIddocIddocIddocId");
    try {
      dispatch({ type: ADD_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryDocumentSOPApplicationAdd",
        { docName, previewImage, docId },
        config
      );

      dispatch({ type: ADD_DOCUMENT_OF_COUNTRY_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentSOPApplicationFindAction
export const countryDocumentSOPApplicationFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryDocumentSOPApplicationFind?id=${id}`,
        config
      );

      dispatch({ type: FIND_DOCUMENT_OF_COUNTRY_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// SopDocDeleteAction
export const SopDocDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_SOP_DOCUMENT_OF_COUNTRY_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/knowledgeBank/countryDocumentSOPApplicationDelete?id=${id}`,
      config
    );

    dispatch({ type: DELETE_SOP_DOCUMENT_OF_COUNTRY_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: DELETE_SOP_DOCUMENT_OF_COUNTRY_ERR,
      payload: error.response.data,
    });
  }
};

// taravel
// countryDocumentTravelApplicationAddAction
export const countryDocumentTravelApplicationAddAction =
  (travelDocName, travelPreviewImage, docId) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryDocumentTravelApplicationAdd",
        { travelDocName, travelPreviewImage, docId },
        config
      );

      dispatch({ type: ADD_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentTravelApplicationFind
export const countryDocumentTravelApplicationFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryDocumentTravelApplicationFind?id=${id}`,
        config
      );

      dispatch({
        type: FIND_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// TravelApplicationDeleteAction
export const TravelApplicationDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/knowledgeBank/countryDocumentTravelApplicationDelete?id=${id}`,
        config
      );

      dispatch({
        type: DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// Guidance
// countryDocumentGuidanceApplicationAddAction
export const countryDocumentGuidanceApplicationAddAction =
  (guidanceDocName, guidancePreviewImage, docId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryDocumentGuidanceApplicationAdd",
        { guidanceDocName, guidancePreviewImage, docId },
        config
      );

      dispatch({
        type: ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// guidance for country
// documentForCountryGuidanceApplicationAddAction
export const documentForCountryGuidanceApplicationAddAction =
  (guidanceDocName, guidancePreviewImage, docId) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/documentForCountryGuidanceApplicationAdd",
        { guidanceDocName, guidancePreviewImage, docId },
        config
      );

      dispatch({
        type: ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentGuidanceApplicationFindAction
export const countryDocumentGuidanceApplicationFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryDocumentGuidanceApplicationFind?id=${id}`,
        config
      );

      dispatch({
        type: FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// documentForCountryGuidanceApplicationFind
export const documentForCountryGuidanceApplicationFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/documentForCountryGuidanceApplicationFind?id=${id}`,
        config
      );

      dispatch({
        type: FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentGuidanceApplicationDeleteAction
export const countryDocumentGuidanceApplicationDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/knowledgeBank/countryDocumentGuidanceApplicationDelete?id=${id}`,
        config
      );

      dispatch({
        type: DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// Admission

// countryDocumentAdmissionApplicationAddAction
export const countryDocumentAdmissionApplicationAddAction =
  (docName, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryDocumentAdmissionApplicationAdd",
        { docName, id },
        config
      );

      dispatch({
        type: ADD_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryAdmissionProcedureAddAction
export const countryAdmissionProcedureAddAction =
  (docName, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryAdmissionProcedureApplicationAdd",
        { docName, id },
        config
      );

      dispatch({
        type: ADD_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryCostEstimateAddAction
export const countryCostEstimateAddAction =
  (docName, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_COST_ESTIMATE_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryCostEstimateAdd",
        { docName, id },
        config
      );

      dispatch({
        type: ADD_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_COST_ESTIMATE_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentAdmissionApplicationFindAction
export const countryAdmissionProcedureFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryAdmissionProcedureFind?id=${id}`,
        config
      );

      dispatch({
        type: FIND_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryCostEstimateFindAction
export const countryCostEstimateFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_COST_ESTIMATE_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryCostEstimateFind?id=${id}`,
        config
      );

      dispatch({
        type: FIND_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_COST_ESTIMATE_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentAdmissionApplicationFindAction
export const countryDocumentAdmissionApplicationFindAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryDocumentAdmissionApplicationFind?id=${id}`,
        config
      );

      dispatch({
        type: FIND_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentAdmissionApplicationDeleteAction
export const countryDocumentAdmissionApplicationDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/knowledgeBank/countryDocumentAdmissionApplicationDelete?id=${id}`,
        config
      );

      dispatch({
        type: DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryDocumentAdmissionProcedureDeleteAction
export const countryDocumentAdmissionProcedureDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/knowledgeBank/countryDocumentAdmissionProcedureDelete?id=${id}`,
        config
      );

      dispatch({
        type: DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryCostEstimateDeleteAction
export const countryCostEstimateDeleteAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_COST_ESTIMATE_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/knowledgeBank/countryCostEstimateDelete?id=${id}`,
        config
      );

      dispatch({
        type: DELETE_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: DELETE_COST_ESTIMATE_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryCrimeAndMedicalApplicationAddAction
export const countryCrimeAndMedicalApplicationAddAction =
  (docName, previewImage, docId, section) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/knowledgeBank/countryCrimeAndMedicalApplicationAdd",
        { docName, previewImage, docId, section },
        config
      );

      dispatch({
        type: ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// criminalDocDeleteAction
export const criminalDocDeleteAction = (id) => async (dispatch, getState) => {
  // alert(id);
  try {
    dispatch({ type: DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/knowledgeBank/countryCriminalDocDelete?id=${id}`,
      config
    );

    dispatch({
      type: DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
      payload: error.response.data,
    });
  }
};


// medicalDocDeleteAction
export const medicalDocDeleteAction = (id) => async (dispatch, getState) => {
  // alert(id);
  try {
    dispatch({ type: DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/knowledgeBank/countryMedicalDocDelete?id=${id}`,
      config
    );

    dispatch({
      type: DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
      payload: error.response.data,
    });
  }
};

// countryCrimeApplicationGet
export const countryCrimeApplicationGetAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_CRIME_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryCrimeApplicationGet?id=${id}`,
        config
      );

      dispatch({ type: FIND_CRIME_DOCUMENT_OF_COUNTRY_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_CRIME_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryMedicalApplicationGetAction
export const countryMedicalApplicationGetAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/knowledgeBank/countryMedicalApplicationGet?id=${id}`,
        config
      );
      dispatch({
        type: FIND_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: FIND_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };

// countryPaymentGetwayUpdateAction
export const countryPaymentGetwayUpdateAction =
  (id, status) => async (dispatch, getState) => {
    try {
      dispatch({ type: PAYMENT_GETWAY_ADD_OF_COUNTRY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/knowledgeBank/countryPaymentUpdate?id=${id}`,
        { status: status },
        config
      );
      dispatch({
        type: PAYMENT_GETWAY_ADD_OF_COUNTRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: PAYMENT_GETWAY_ADD_OF_COUNTRY_ERR,
        payload: error.response.data,
      });
    }
  };
