import React, { useEffect, useState } from "react";
import TopicUploadComponent from "../../../../../customComponent/TopicUploadComponent";
import { Box } from "@mui/material";
import TopicModalEdit from "./TopicModalEdit";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCourseTopicFindAction } from "../../../../../../actions/IeltsActions/courseManagmentAction";
import ModalTopicListComponentEdit from "./ModalTopicListComponentEdit";

function TopicTabItemEdit({ category, type, handleSelected, Selectedfile }) {
  const dispatch = useDispatch();
  // console.log(category, "category");
  const [selectModal, setSelectModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [topics, setTopics] = useState([]);
  const [examTip, setExamTips] = useState([]);
  const [questionBank, setQuestionBank] = useState([]);
  const [resultedArray, setResultedArray] = useState([]);
  const [videoData, setVideData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [noteData, setNoteData] = useState([]);

  // Find Topics based on category:::::::::::::::::::::::::::;

  let {
    ieltsCourseManagmentCourseTopicFindSuccess,
    ieltsCourseManagmentCourseTopicFinderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseTopicFind;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseTopicFindAction(category));
  }, [dispatch]);

  console.log(
    ieltsCourseManagmentCourseTopicFindSuccess,
    "ieltsCourseManagmentCourseTopicFindSuccess"
  );

  useEffect(() => {
    if (ieltsCourseManagmentCourseTopicFindSuccess) {
      setTopics(ieltsCourseManagmentCourseTopicFindSuccess.data.topic);
      setQuestionBank(
        ieltsCourseManagmentCourseTopicFindSuccess.data.QuestionBank
      );
      setExamTips(ieltsCourseManagmentCourseTopicFindSuccess.data.examTip);
      let topic = ieltsCourseManagmentCourseTopicFindSuccess.data.topic;
      let questionBank =
        ieltsCourseManagmentCourseTopicFindSuccess.data.QuestionBank;
      let examTip = ieltsCourseManagmentCourseTopicFindSuccess.data.examTip;
      if (type === "video") {
        setModalData(topic.filter((value) => value.VideoUrl !== ""));
      }
      if (type === "audio") {
        setModalData(topic.filter((value) => value.AudioUrl !== ""));
      }
      if (type === "notes") {
        setModalData(topic.filter((value) => value.NoteUrl !== ""));
      }
      if (type === "questionbank") {
        setModalData(questionBank);
      }
      if (type === "examtip") {
        setModalData(examTip);
      }
    }
  }, [ieltsCourseManagmentCourseTopicFindSuccess, type]);

  // console.log(resultedArray, "ieltsCourseManagmentCourseTopicFindSuccess");

  const handleClickOpen = () => {
    setSelectModal(true);
  };

  const handleClose = () => {
    setSelectModal(false);
  };

  const handleSelectedTopicData = (type, array) => {
    let filterArray;
    if (type == "examtip") {
      filterArray = examTip.filter((item) => array.includes(item.id));
    } else if (type == "questionbank") {
      filterArray = questionBank.filter((item) => array.includes(item.id));
    } else {
      filterArray = topics.filter((item) => array.includes(item.id));
    }
    setResultedArray(filterArray);
    handleSelected(type, filterArray);
  };

  // console.log(resultedArray,"resultArrayresultArrayresultArray")

  const length =
    Selectedfile?.onlineItems?.[category]?.Topics?.[type]?.length ?? 0;
  // const length = resultedArray?.length ?? 0;

  return length === 0 ? (
    <>
      <TopicModalEdit
        type={type}
        data={modalData}
        selectModal={selectModal}
        handleClose={handleClose}
        handleSelectedTopicData={handleSelectedTopicData}
      />

      <Box
        sx={{
          ml: "-24px",
          padding: "20px",
          backgroundColor: "rgba(245, 245, 245, 1)",
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(2,1fr)",
            md: "repeat(3,1fr)",
            lg: "repeat(4,1fr)",
            xl: "repeat(6,13%)",
          },
          gap: "25px",
        }}
      >
        <TopicUploadComponent
          type="add"
          title={"Select Topic 1"}
          handleClick={handleClickOpen}
        />
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column-reverse",
        gap: "20px",
      }}
    >
      {Selectedfile?.onlineItems?.[category]?.Topics?.[type]?.map((item, i) => (
        <ModalTopicListComponentEdit
          key={i}
          item={item}
          type={type}
          listview={true}
        />
      ))}
    </Box>
  );
}

export default TopicTabItemEdit;
