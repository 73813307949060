import React from 'react'

function IeltsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.22656 6.79688H4.47656C4.08825 6.79688 3.77344 7.11169 3.77344 7.5C3.77344 7.88831 4.08825 8.20312 4.47656 8.20312H8.22656C8.61487 8.20312 8.92969 7.88831 8.92969 7.5C8.92969 7.11169 8.61487 6.79688 8.22656 6.79688Z"
        fill="#ACB1C6"
      />
      <path
        d="M8.22656 10.5469H4.47656C4.08825 10.5469 3.77344 10.8617 3.77344 11.25C3.77344 11.6383 4.08825 11.9531 4.47656 11.9531H8.22656C8.61487 11.9531 8.92969 11.6383 8.92969 11.25C8.92969 10.8617 8.61487 10.5469 8.22656 10.5469Z"
        fill="#ACB1C6"
      />
      <path
        d="M8.22656 14.2969H4.47656C4.08825 14.2969 3.77344 14.6117 3.77344 15C3.77344 15.3883 4.08825 15.7031 4.47656 15.7031H8.22656C8.61487 15.7031 8.92969 15.3883 8.92969 15C8.92969 14.6117 8.61487 14.2969 8.22656 14.2969Z"
        fill="#ACB1C6"
      />
      <path
        d="M18.3047 12.8873V6.46781C18.3047 5.54109 17.9474 4.66683 17.2985 4.00561L14.4456 1.05534C14.4443 1.05394 14.4428 1.05248 14.4415 1.05108C13.7846 0.383109 12.8706 0 11.9339 0H2.88281C1.7197 0 0.773438 0.946266 0.773438 2.10938V20.3438C0.773438 21.5069 1.7197 22.4531 2.88281 22.4531H13.75C14.752 23.4109 16.1093 24 17.6016 24C20.7032 24 23.2266 21.4977 23.2266 18.4219C23.2266 15.5822 21.0757 13.2315 18.3047 12.8873ZM2.17969 20.3438V2.10938C2.17969 1.72167 2.49511 1.40625 2.88281 1.40625H11.9339C12.4952 1.40625 13.0429 1.63552 13.437 2.03522L16.2894 4.98502C16.2907 4.98642 16.2921 4.98783 16.2934 4.98919C16.6836 5.38608 16.8984 5.91117 16.8984 6.46786V12.888C14.1533 13.2347 12.0234 15.5843 12.0234 18.4219C12.0234 19.3703 12.2617 20.2641 12.681 21.0469H2.88281C2.49511 21.0469 2.17969 20.7315 2.17969 20.3438ZM17.6016 22.5938C15.3012 22.5938 13.4297 20.7223 13.4297 18.4219C13.4297 16.1215 15.3012 14.25 17.6016 14.25C19.9278 14.25 21.8203 16.1215 21.8203 18.4219C21.8203 20.7223 19.9278 22.5938 17.6016 22.5938Z"
        fill="#ACB1C6"
      />
      <path
        d="M19.2236 16.7567C18.9253 16.5082 18.4819 16.5484 18.2333 16.8467L17.0466 18.2708L16.7774 17.7324C16.6037 17.3851 16.1814 17.2442 15.834 17.418C15.4867 17.5916 15.3459 18.014 15.5196 18.3613L16.2696 19.8613C16.3757 20.0735 16.5817 20.2179 16.8174 20.2453C16.8446 20.2484 16.8716 20.25 16.8986 20.25C17.1058 20.25 17.3042 20.1583 17.4386 19.997L19.3136 17.747C19.5622 17.4486 19.5219 17.0052 19.2236 16.7567Z"
        fill="#ACB1C6"
      />
      <path
        d="M11.9766 8.20312H12.7266C13.1149 8.20312 13.4297 7.88831 13.4297 7.5C13.4297 7.11169 13.1149 6.79688 12.7266 6.79688H11.9766C11.5882 6.79688 11.2734 7.11169 11.2734 7.5C11.2734 7.88831 11.5882 8.20312 11.9766 8.20312Z"
        fill="#ACB1C6"
      />
      <path
        d="M13.4297 11.25C13.4297 10.8617 13.1149 10.5469 12.7266 10.5469H11.9766C11.5882 10.5469 11.2734 10.8617 11.2734 11.25C11.2734 11.6383 11.5882 11.9531 11.9766 11.9531H12.7266C13.1149 11.9531 13.4297 11.6383 13.4297 11.25Z"
        fill="#ACB1C6"
      />
    </svg>
  );
}

export default IeltsIcon