import React, { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Checkbox,
  Pagination,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
// import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { CountryMasterDeleteAction } from "../../../actions/countryMasterAction";
import { stateMasterDeleteAction } from "../../../actions/StateMasterAction";
import { cityMasterDeleteAction } from "../../../actions/cityManagmentAction";
import { branchMasterDeleteAction } from "../../../actions/branchMasterActions";
import { BiSortAlt2 } from "react-icons/bi";

import "./Master.table.css";
import { format } from "date-fns";
import { bannerDeleteAction } from "../../../actions/bannerAction";
import {
  courseMasterDeleteAction,
  subjectMasterDeleteAction,
} from "../../../actions/courseMasterAction";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import { locationDeleteAction } from "../../../actions/locationAction";

export const MasterTable = ({
  columns,
  data,
  allRoleFindSuccess,
  component,
  showUrl,
  rollEditHandler,
  pagination,
  subMenu,
}) => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();
  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setSelectedRows] = useState([]);

  const openActionButton = Boolean(actionButtonShow);

  const { isUpdatePrevilage, isDeletePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  const [datas, setData] = useState();

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClickAction = (event, id, item) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
    setData(item);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // Pagination

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // pagination(newPage - 1);
  };

  // delete action
  const RoledeleteHandler = (id) => {
    setActionButtonShow(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // setAnchorEl(false);
        if (component === "stateMaster") {
          dispatch(stateMasterDeleteAction(tableId));
        }
        if (component === "countryMaster") {
          dispatch(CountryMasterDeleteAction(tableId));
        }
        if (component === "cityMaster") {
          dispatch(cityMasterDeleteAction(tableId));
        }
        if (component === "branchMaster") {
          dispatch(branchMasterDeleteAction(tableId));
          console.log("first");
        }
        if (component === "bannerMaster") {
          dispatch(bannerDeleteAction(tableId));
        }
        if (component === "courseMajorMaster") {
          dispatch(courseMasterDeleteAction(tableId));
        }
        if (component === "subjectMajorMaster") {
          dispatch(subjectMasterDeleteAction(tableId));
        }
        if (component="locationMaster") {
         dispatch(locationDeleteAction(tableId));
        }
        Swal.fire("Deleted!", "Delete successfully!.", "success");
      }
    });
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // const handleClose = () => setOpen(false);

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };
  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      // selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    // setIdsFunction(updatedSelectedRows);
  };


  return (
    <>
      {sortedData?.length > 0 ? (
        <Table className="masterTable">
          <TableHead>
            <TableRow className="msterTableCell">
              <TableCell>
                <Checkbox
                  checked={selectedRows.length === sortedData.length}
                  onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field}>
                    <TableSortLabel
                      className="headerText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              ?.map((item, indx) => (
                <TableRow className="tableRow" key={indx}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(item.id)}
                      onChange={() => handleSelectRow(item.id)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  <TableCell>{item.sl}</TableCell>
                  {component == "countryMaster" && (
                    <TableCell>{item.countryID}</TableCell>
                  )}
                  {component == "bannerMaster" ? (
                    <TableCell>
                      <img src={item.column1} width={52} height={25}></img>
                    </TableCell>
                  ) : (
                    <TableCell>{item.column1}</TableCell>
                  )}
                  {component === "courseMajorMaster" && (
                    <TableCell>{item.column2}</TableCell>
                  )}
                  {component === "subjectMajorMaster" && (
                    <TableCell>{item.column2}</TableCell>
                  )}
                  {component === "courseMajorMaster" ? (
                    <TableCell>
                      <img
                        src={item.column3}
                        width={32}
                        height={32}
                        alt="item"
                      />
                    </TableCell>
                  ) : (
                    component !== "subjectMajorMaster" && (
                      <TableCell>{item.column2}</TableCell>
                    )
                  )}
                  {/* {component === "courseLevelMajorMaster" && (
                    <TableCell>{item.column3 || "-"}</TableCell>
                  )} */}
                  {item.column3 && component !== "courseMajorMaster" ? (
                    <TableCell>{item.column3 || "-"}</TableCell>
                  ) : (
                    ""
                  )}

                  {item.email && <TableCell>{item.email}</TableCell>}
                  {item.column4 && <TableCell>{item.column4}</TableCell>}

                  {item.column5 && (
                    <TableCell>
                      {item.column5
                        ? format(
                            new Date(item?.column5.split("T")[0]),
                            "dd / MM / yyyy"
                          )
                        : null}
                    </TableCell>
                  )}
                  {component !== "stateMaster" &&
                  component !== "cityMaster" &&
                  component !== "currency" ? (
                    <TableCell>
                      <SlOptionsVertical
                        id="demo-positioned-button"
                        aria-controls={
                          openActionButton ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openActionButton ? "true" : undefined}
                        onClick={(e) => {
                          handleClickAction(e, item.id, item);
                        }}
                        variant="outlined"
                        style={{ marginLeft: "10px", color: "#F80B7A" }}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}

      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          marginLeft: component === "branchMaster" ? "-31px" : "-81px",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <TbEdit
              onClick={() => {
                if (isUpdatePrevilage(subMenu)) {
                  if (component === "courseLevelMajorMaster") {
                    rollEditHandler(tableId, datas);
                  } else {
                    rollEditHandler(tableId);
                  }
                } else {
                  setOpenAlert(true);
                }
              }}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>

          {component !== "countryMaster" &&
          component !== "stateMaster" &&
          component !== "cityMaster" &&
          component !== "currency" ? (
            <MenuItem>
              <RiDeleteBin6Line
                onClick={() => {
                  if (isDeletePrevilage(subMenu)) {
                    RoledeleteHandler(tableId);
                  } else {
                    setOpenAlert(true);
                  }
                }}
                style={{ fontSize: "lg", width: "50px", height: "23px" }}
              />
            </MenuItem>
          ) : (
            ""
          )}
        </Box>
      </Menu>
      <Pagination
        sx={{
          mt: "28px",
          "& .Mui-selected": {
            backgroundColor: "#f81b82 !important",
            color: "#fff",
          },
          "& .MuiPagination-ul": {
            justifyContent: "end",
          },
        }}
        count={Math.ceil(data?.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
      {/* {/*TABLE ACTION MENU END */}
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
