import React, { useEffect, useState } from "react";
// import LeadsTable from "../Table/LeadsTable";
// import LeadsFilterSearch from "./LeadsFilterSearch";

import { Alert, AlertTitle, Grid } from "@mui/material";
// import {
//   deleteLeadAction,
//   leadFindAction,
// } from "../../actions/leadActions/leadActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import EmployeeJobSearchHeader from "./EmployeeJobSearchHeader";
import EmployeeJobFilterSearch from "./EmployeeJobFilterSearch";
import EmployeeJobTable from "./EmployeeJobTable";
import { hrEmployeeJobFindAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import DownloadExcel from "../extraComponents/DownloadExcel";
import DownloadPdf from "../extraComponents/DownloadPdf";
import PremissionDialog from "../customComponent/premissionDialog";
// import { findUserAction } from "../../actions/adminAuthAction";

function AllEmployeeJobs(props) {
  const dispatch = useDispatch();
  const [filterPassData, setFilterPassData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [leadData, setLeadData] = useState([]);
  const [EmployeeJoblist, SetEmployeeJobList] = useState([]);

  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  let { hrEmployeeJobCreateSuccess } = useSelector((state) => {
    return state.hrEmployeeJobCreate;
  });

  let { hrEmployeeJobDeleteSuccess } = useSelector((state) => {
    return state.hrEmployeeJobDelete;
  });

  let { hrEmployeeJobUpdateSuccess } = useSelector((state) => {
    return state.hrEmployeeJobUpdate;
  });

  let { jobStatusChangeSuccess } = useSelector((state) => {
    return state.employeeJobStatusChange;
  });

  useEffect(() => {
    dispatch(hrEmployeeJobFindAction());
  }, [
    dispatch,
    hrEmployeeJobCreateSuccess,
    hrEmployeeJobDeleteSuccess,
    hrEmployeeJobUpdateSuccess,
    jobStatusChangeSuccess,
  ]);

  let { hrEmployeeJobFindSuccess } = useSelector((state) => {
    return state.hrEmployeeJobFind;
  });

  useEffect(() => {
    if (hrEmployeeJobFindSuccess) {
      SetEmployeeJobList(
        hrEmployeeJobFindSuccess.map((Jobs, index) => ({
          sl: index + 1,
          id: Jobs.id,
          coloum1: Jobs.jobtitle,
          coloum2: Jobs.jobLocation,
          coloum3: Jobs.jobType,
          coloum4: Jobs.Experience,
          coloum5: Jobs.Vacanices,
          coloum6: Jobs.PostedOn,
          coloum7: Jobs.isOpen,
        }))
      );
    }
  }, [hrEmployeeJobFindSuccess]);

  // const datas = EmployeeJoblist.map((Jobs, index) => ({
  //   sl: index + 1,
  //   id: Jobs.id,
  //   coloum1: Jobs.jobtitle,
  //   coloum2: Jobs.jobLocation,
  //   coloum3: Jobs.jobType,
  //   coloum4: Jobs.Experience,
  //   coloum5: Jobs.Vacanices,
  //   coloum6: Jobs.PostedOn,
  // }));

  //   useEffect(() => {
  //     setData(
  //       leadData?.map((lead, index) => ({
  //         id: lead.id,
  //         sl: index + 1,
  //         JobTitle: lead.firstName,
  //         phone: lead.locationNumber,
  //         preferredCountry: lead.preferredCountryId,
  //         Experience: lead.Experience,
  //         leadOwner:
  //           loginFindSuccess &&
  //           loginFindSuccess.role !== null &&
  //           loginFindSuccess.role?.roleName === "branch manager"
  //             ? lead?.user?.firstName
  //             : lead.leadOwnerName,
  //         status: lead.status,
  //         priority: lead.priority ? lead.priority : "-",
  //         leadCity: lead.location,
  //         branchId: lead.branchId,
  //         leadBranch: lead?.branch_master?.branchName
  //           ? lead?.branch_master?.branchName
  //           : "-",
  //       }))
  //     );
  //   }, [leadData]);

  const searchHandleChange = (value) => {
    if (value) {
      SetEmployeeJobList(
        EmployeeJoblist?.filter((item) => {
          const jobTitle = item.coloum1 ? item.coloum1.toLowerCase() : "";
          const location = item.coloum2 ? item.coloum2.toLowerCase() : "";

          // :lead?.branch_master?.branchName
          return (
            jobTitle.includes(value.toLowerCase()) ||
            location.includes(value.toLowerCase())
          );
        })
      );
    } else {
      SetEmployeeJobList(
        hrEmployeeJobFindSuccess.map((Jobs, index) => ({
          sl: index + 1,
          id: Jobs.id,
          coloum1: Jobs.jobtitle,
          coloum2: Jobs.jobLocation,
          coloum3: Jobs.jobType,
          coloum4: Jobs.Experience,
          coloum5: Jobs.Vacanices,
          coloum6: Jobs.PostedOn,
        }))
      );
    }
  };

  // Employee column Visibility
  const [showJobTitleColumn, setShowJobTitleColumn] = useState(false);
  const [showLocationColumn, setShowLocationColumn] = useState(false);
  const [showJobTypeColumn, setShowJobTypeColumn] = useState(false);
  const [showExperienceColumn, setShowExperienceColumn] = useState(false);
  const [showVacanciesColumn, setShowVacanciesColumn] = useState(false);
  const [showPostedonColumn, setShowPostedOnColumn] = useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  // tabel columns

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "JOB TITLE",
      field: "JOB TITLE",
      hidden: showJobTitleColumn,
    },
    {
      title: "LOCATION",
      field: "LOCATION",
      hidden: showLocationColumn,
    },
    {
      title: "JOB TYPE",
      field: "JOBTYPE",
      hidden: showJobTypeColumn,
    },
    {
      title: "EXPERIENCE",
      field: "EXPERIENCE",
      hidden: showExperienceColumn,
    },
    {
      title: "VACANCIES",
      field: "VACANCIES",
      hidden: showVacanciesColumn,
    },
    {
      title: "POSTED ON",
      field: "POSTED ON",
      hidden: showPostedonColumn,
    },

    {
      title: "ACTION",
      field: "ACTION",
      hidden: showPostedonColumn,
    },
  ];

  console.log(EmployeeJoblist, "EmployeeJoblistEmployeeJoblist");

  // Excel export function
  const excelDownloadClick = () => {
    DownloadExcel(columns, EmployeeJoblist, "Hr_Jobvacancies_Managment");
  };

  // Pdf Export function

  const pdfDownloadClick = () => {
    DownloadPdf(
      columns,
      EmployeeJoblist,
      "Hr_Jobvacancies_Managment",
      "Hr_Jobvacancies_Managment"
    );
  };

  const toggleNameColumn = (status, column) => {
    if (column === "JobTitle") {
      setColumnName(column);
      setShowJobTitleColumn(status);
    }
    if (column === "location") {
      setColumnName(column);
      setShowLocationColumn(status);
    }
    if (column === "jobType") {
      setColumnName(column);
      setShowJobTypeColumn(status);
    }
    if (column === "Experience") {
      setColumnName(column);
      setShowExperienceColumn(status);
    }
    if (column === "Vacancies") {
      setColumnName(column);
      setShowVacanciesColumn(status);
    }
    if (column === "PostedOn") {
      setColumnName(column);
      setShowPostedOnColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedJobTitleColumn = localStorage.getItem("JobTitle");
    const storedlocationColumn = localStorage.getItem("location");
    const storedjobTypeColumn = localStorage.getItem("jobType");
    const storedJobExperienceColumn = localStorage.getItem("Experience");
    const storedVacanciesColumn = localStorage.getItem("Vacancies");
    const storedPostedOnColumn = localStorage.getItem("PostedOn");

    if (storedJobTitleColumn !== null) {
      setColumnName("JobTitle");
      setShowJobTitleColumn(JSON.parse(storedJobTitleColumn));
    }
    if (storedlocationColumn !== null) {
      setColumnName("location");
      setShowLocationColumn(JSON.parse(storedlocationColumn));
    }
    if (storedjobTypeColumn !== null) {
      setColumnName("jobType");
      setShowJobTypeColumn(JSON.parse(storedjobTypeColumn));
    }
    if (storedJobExperienceColumn !== null) {
      setColumnName("Experience");
      setShowExperienceColumn(JSON.parse(storedJobExperienceColumn));
    }
    if (storedVacanciesColumn !== null) {
      setColumnName("Vacancies");
      setShowVacanciesColumn(JSON.parse(storedVacanciesColumn));
    }
    if (storedPostedOnColumn !== null) {
      setColumnName("PostedOn");
      setShowPostedOnColumn(JSON.parse(storedPostedOnColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("JobTitle", JSON.stringify(showJobTitleColumn));
    localStorage.setItem("location", JSON.stringify(showLocationColumn));
    localStorage.setItem("jobType", JSON.stringify(showJobTypeColumn));
    localStorage.setItem("Experience", JSON.stringify(showExperienceColumn));
    localStorage.setItem("Vacancies", JSON.stringify(showVacanciesColumn));
    localStorage.setItem("PostedOn", JSON.stringify(showPostedonColumn));
  }, [
    showJobTitleColumn,
    showLocationColumn,
    showJobTypeColumn,
    showExperienceColumn,
    showVacanciesColumn,
    showPostedonColumn,
    columnName,
  ]);

  //   const LeadFilterFunction = (data, filterOption, checkedStatus) => {
  //     alert(data);
  //     if (filterOption === "city") {
  //       setLeadData(
  //         leadData?.filter((item) => {
  //           return item.location.toLowerCase().includes(data.toLowerCase());
  //         })
  //       );

  //       setwhichFilter(filterOption);
  //       setFilterPassData(data);
  //     }
  //     if (filterOption === "preffered country") {
  //       if (checkedStatus === true) {
  //         setLeadData(
  //           leadData?.filter((item) => {
  //             return item.preferredCountryId
  //               .toLowerCase()
  //               .includes(data.toLowerCase());
  //           })
  //         );
  //         setwhichFilter(filterOption);
  //         setFilterPassData(data);
  //       } else {
  //         setwhichFilter(null);
  //         setFilterPassData(null);
  //         setLeadData(leadFindSuccess);
  //       }
  //     }
  //   };

  //   const handleResetData = (status) => {
  //     setwhichFilter(null);
  //     setFilterPassData(null);
  //     setReset(!reset);
  //     setwhichFilter(null);
  //     setFilterPassData(null);
  //   };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };
  //   const handleDeleteClick = () => {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         dispatch(deleteLeadAction(leadId));
  //       }
  //     });
  //   };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("jobs") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  return (
    <>
      <EmployeeJobSearchHeader
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        pdfDownloadClick={pdfDownloadClick}
        showJobTitleColumn={showJobTitleColumn}
        showJobTypeColumn={showJobTypeColumn}
        showLocationColumn={showLocationColumn}
        showExperienceColumn={showExperienceColumn}
        showVacanciesColumn={showVacanciesColumn}
        showPostedonColumn={showPostedonColumn}
        // handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        subMenu="jobs"
      />
      {/* <div style={{display:"flex",justifyContent:"space-evenly", width:"auto"}}> */}
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <EmployeeJobFilterSearch
            // LeadFilterFunction={LeadFilterFunction}
            // handleResetData={handleResetData}
            statusComponent="EmployeeJobs"
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <EmployeeJobTable
            toggle={props.toggle}
            showJobTitleColumn={showJobTitleColumn}
            showJobTypeColumn={showJobTypeColumn}
            showLocationColumn={showLocationColumn}
            showExperienceColumn={showExperienceColumn}
            showVacanciesColumn={showVacanciesColumn}
            showPostedonColumn={showPostedonColumn}
            // reset={reset}
            setIdsFunction={setIdsFunction}
            data={EmployeeJoblist}
            // leadFindLoading={leadFindLoading}
            selectBoxIdPassHandler={selectBoxIdPassHandler}
            // isExistCounsilor={loginFindSuccess}
            component="EmployeeJob"
            columns={columns}
            subMenu="jobs"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default AllEmployeeJobs;
