import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Button from "@mui/material/Button";

import Swal from "sweetalert2";

import { HiOutlineChevronRight } from "react-icons/hi";

import { Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import BasicInformation from "./BasicInformation";
import AboutUniversity from "./AboutUniversity";
import EligibleCerteria from "./EligibleCerteria";
import { MainContainer } from "../../../content/content.element";
import FileUpload from "./FileUpload";
import { universityAddSubmitAction } from "../../../../actions/universityActions/universityManagmentAction";
import { useNavigate } from "react-router-dom";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";

function AddUniversity(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { universityAddSuccess, universityAddErr } = useSelector((state) => {
    return state.universityAddSubmit;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (universityAddSuccess && successAlertMsgSuccess) {
      localStorage.removeItem("universityName");
      localStorage.removeItem("country");
      localStorage.removeItem("city");
      localStorage.removeItem("state");
      localStorage.removeItem("ranking");
      localStorage.removeItem("rank");
      localStorage.removeItem("affiliation");
      localStorage.removeItem("facilities");
      localStorage.removeItem("types");
      localStorage.removeItem("establishedYear");
      localStorage.removeItem("universityAbout");
      localStorage.removeItem("selectedFiles");
      localStorage.removeItem("internationalFee");
      localStorage.removeItem("internationalStudent");
      localStorage.removeItem("qualification");
      localStorage.removeItem("test");
      localStorage.removeItem("workExperience");
      localStorage.removeItem("remark");
      localStorage.removeItem("documentRequired");
      localStorage.removeItem("isTopUniversity");
      localStorage.removeItem("website");
      localStorage.removeItem("universityHandler");

      Swal.fire("Added!", "University Added Successfully.", "success");
      dispatch(successTrueMsgAction(false));
      navigate("/university");
    }
  }, [universityAddSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (universityAddErr && successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${universityAddErr}`
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [universityAddErr, successAlertMsgSuccess]);

  const steps = [
    { label: "1", details: "Basic Information" },
    { label: "2", details: "About University" },
    { label: "3", details: "Eligibility Criteria" },
    { label: "4", details: "File upload" }
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = (status) => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    status === "completed" && handleNext();
  };

  const handleUploadSubmit = (
    selectedVideoFiles,
    selectedFiles,
    brochurFile,
    logoFile,
    universityCoverImage
  ) => {
    let universityName = JSON.parse(localStorage.getItem("universityName"));
    let country = JSON.parse(localStorage.getItem("country"));
    let state = JSON.parse(localStorage.getItem("state"));
    console.log(localStorage.getItem("city"), "localStorage.getItem");
    let city =
      localStorage.getItem("city") !== "undefined"
        ? JSON.parse(localStorage.getItem("city"))
        : "";
    let location =
      localStorage.getItem("location") !== "undefined"
        ? JSON.parse(localStorage.getItem("location"))
        : "";
    let ranking = JSON.parse(localStorage.getItem("ranking"));
    let rank = JSON.parse(localStorage.getItem("rank"));
    let websiteLink = JSON.parse(localStorage.getItem("website"));
    let affiliation = JSON.parse(localStorage.getItem("affiliation"));
    let facilities = JSON.parse(localStorage.getItem("facilities"));
    let types = JSON.parse(localStorage.getItem("types"));
    let establishedYear = JSON.parse(localStorage.getItem("establishedYear"));
    let universityAbout = JSON.parse(localStorage.getItem("universityAbout"));
    let internationalFee = JSON.parse(localStorage.getItem("internationalFee"));
    let eligibilityQualification = JSON.parse(
      localStorage.getItem("qualification")
    );
    let eligibilityTest = JSON.parse(localStorage.getItem("test"));
    let eligibilityWorkExperience = JSON.parse(
      localStorage.getItem("workExperience")
    );
    let eligibilityRemark = JSON.parse(localStorage.getItem("remark"));

    const documentRequired = localStorage.getItem("documentRequired");
    const isTopUniversity = localStorage.getItem("isTopUniversity");
    const applicationFee = localStorage.getItem("applicationFee");
    const universityHandler = localStorage.getItem("universityHandler");


    console.log(
      {
        universityName: universityName,
        country: country,
        state: state,
        city: city,
        ranking: ranking,
        rank: rank,
        websiteLink,
        affiliation: affiliation,
        facilities: facilities,
        types: types,
        establishedYear: new Date(establishedYear).getFullYear(),
        universityAbout: universityAbout,
        filesVideo: selectedVideoFiles,
        internationalFee,
        eligibilityQualification,
        eligibilityTest,
        eligibilityWorkExperience,
        eligibilityRemark,
        documentRequired
      },
      "-+++++++++-+--++-+-+--+-*--*--*-*-+--*-*/-*-++-"
    );

    if (logoFile) {
      dispatch(
        universityAddSubmitAction({
          universityName: universityName,
          country: country,
          state: state,
          city: city,
          location,
          ranking: ranking,
          rank: rank,
          websiteLink,
          affiliation: affiliation,
          facilities: facilities,
          types: types,
          establishedYear: new Date(establishedYear).getFullYear(),
          universityAbout: universityAbout,
          filesVideo: selectedVideoFiles,
          internationalFee,
          eligibilityQualification,
          eligibilityTest,
          eligibilityWorkExperience,
          eligibilityRemark,
          documentRequired,
          brochurFile,
          logoFile,
          isTopUniversity,
          applicationFee,
          universityCoverImage,
          universityHandler
        })
      );
      localStorage.removeItem("universityName");
      localStorage.removeItem("country");
      localStorage.removeItem("state");
      localStorage.removeItem("city");
      localStorage.removeItem("ranking");
      localStorage.removeItem("rank");
      localStorage.removeItem("website");
      localStorage.removeItem("affiliation");
      localStorage.removeItem("facilities");
      localStorage.removeItem("types");
      localStorage.removeItem("establishedYear");
      localStorage.removeItem("universityAbout");
      localStorage.removeItem("internationalFee");
      localStorage.removeItem("qualification");
      localStorage.removeItem("test");
      localStorage.removeItem("workExperience");
      localStorage.removeItem("remark");
      localStorage.removeItem("documentRequired");
      localStorage.removeItem("isTopUniversity");
      localStorage.removeItem("applicationFee");
      localStorage.removeItem("universityHandler");

    } else {
      Swal.fire("Add Logo!", `Please Upload Logo`, "error");
    }
  };

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader headTitle="Add University" url="/university" />
      <Box sx={{ mt: 3 }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <>
              <Button
                key={label.label}
                sx={{
                  color: completed[index] ? "#fff" : "#141E3C",
                  backgroundColor: completed[index] ? "#141E3C" : "#fff",
                  marginRight: "8px",
                  borderRadius: "50%",
                  minWidth: "40px",
                  height: "40px",
                  border: "1px solid #141E3C",
                  fontWeight: 700
                }}
                color="inherit"
                onClick={handleStep(index)}
              >
                {label.label}
              </Button>

              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Montserrat',sans-serif",
                  fontWeight: 600,
                  fontSize: "18px",
                  ml: 1
                }}
              >
                {label.details}
              </Typography>
              {index !== steps.length - 1 && (
                <HiOutlineChevronRight
                  style={{
                    marginLeft: "20px",
                    fontSize: "25px",
                    marginRight: "15px",
                    fontWeight: 1,
                    color: "rgba(172, 177, 198, 1)"
                  }}
                />
              )}
            </>
          ))}
        </Stepper>
        <>
          {activeStep === 0 && (
            <BasicInformation handleComplete={handleComplete} />
          )}

          {activeStep === 1 && (
            <AboutUniversity
              handleBack={handleBack}
              handleComplete={handleComplete}
            />
          )}

          {activeStep === 2 && (
            <EligibleCerteria
              handleBack={handleBack}
              handleComplete={handleComplete}
            />
          )}

          {activeStep === 3 && (
            <FileUpload
              activeStep={activeStep}
              steps={steps}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleComplete={handleComplete}
              handleUploadSubmit={handleUploadSubmit}
            />
          )}
        </>
      </Box>
    </MainContainer>
  );
}

export default AddUniversity;
