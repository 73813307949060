import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
} from "@mui/material";

import Swal from "sweetalert2";

import "./CommonDocument.css";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { Box, MenuItem, Menu, Paper, TextField, Checkbox } from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";

import { Skeleton, Typography } from "@mui/material";

import {
  CommonDocumentApplicationAddAction,
  CommonDocumentApplicationFindAction,
  deleteCommonDocAction,
  updateIsFrontAction,
  updateIsMandatoryAction,
} from "../../actions/knowledgeBankActions/CommonDocsAction";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";

import "./CommonDocument.css";
import InputField from "../customComponent/InputField";
import { successTrueMsgAction } from "../../actions/globalAction";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { Close } from "@mui/icons-material";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CommonDocument() {
  const dispatch = useDispatch();
  // Custom hook for Previlage checking

  const { privilege, hasPrivilege, isCreatePrevilage, isDeletePrevilage } =
    usePrivilegeCheck();

  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [docName, setDocName] = useState("");
  const [previlageAlert, setPrevilageAlert] = useState(false);

  // table action
  const [employeeActionButtonShow, setEmployeeActionButtonShow] =
    useState(null);
  const [tableId, setTableId] = useState("");

  const employeeOpenActionButton = Boolean(employeeActionButtonShow);

  const handleClickAction = (event, id) => {
    setEmployeeActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  let { commonDocFindLoading, commonDocFindSuccess } = useSelector((state) => {
    return state.CommonDocumentApplicationFind;
  });

  let { commonDocAddSuccess } = useSelector((state) => {
    return state.CommonDocumentApplicationAdd;
  });

  let { commonDocUpdateIsFrontSuccess } = useSelector((state) => {
    return state.updateIsFront;
  });

  let { commonDocUpdateIsMandatorySuccess } = useSelector((state) => {
    return state.updateIsMandatory;
  });

  let { deleteCommonDocSuccess } = useSelector((state) => {
    return state.deleteCommonDoc;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (commonDocFindSuccess && successAlertMsgSuccess) {
      Swal.fire("Added", "Country added successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [commonDocAddSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (commonDocFindSuccess) {
      setAllEmployeeData(commonDocFindSuccess);
    }
  }, [commonDocAddSuccess, commonDocFindSuccess, deleteCommonDocSuccess]);

  useEffect(() => {
    dispatch(CommonDocumentApplicationFindAction());
  }, [
    dispatch,
    commonDocAddSuccess,
    commonDocUpdateIsFrontSuccess,
    commonDocUpdateIsMandatorySuccess,
    commonDocAddSuccess,
    deleteCommonDocSuccess,
  ]);

  const handleCloseAction = () => {
    setEmployeeActionButtonShow(null);
  };

  // material table data & column
  const data = allEmployeeData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    name: data.DocName,
    isMandatory: (
      <Checkbox
        sx={{
          color: "#F8469A",
          "&.Mui-checked": {
            color: "#F8469A",
          },
        }}
        onChange={(e) =>
          dispatch(updateIsMandatoryAction(e.target.checked, data.id))
        }
        checked={data.isMandotary === true ? true : false}
      />
    ),
    isFrontBack: (
      <Checkbox
        sx={{
          color: "#F8469A",
          "&.Mui-checked": {
            color: "#F8469A",
          },
        }}
        onChange={(e) =>
          dispatch(updateIsFrontAction(e.target.checked, data.id))
        }
        checked={data.isFrontBack === true ? true : false}
      />
    ),
  }));

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      //   hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Document name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    // {
    //   title: "Mandatory",
    //   field: "isMandatory",
    //   //   hidden: showEmailColumn,
    //   headerStyle: { backgroundColor: "#F9F9FB" },
    // },
    // {
    //   title: "Front & Back",
    //   field: "isFrontBack",
    //   //   hidden: showMobileColumn,
    //   headerStyle: { backgroundColor: "#F9F9FB" },
    // },
    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  //   popup add common document
  const handleClickOpen = () => {
    if (isCreatePrevilage("document upload")) {
      setOpen(true);
    } else {
      setPrevilageAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (docName !== "") {
      dispatch(CommonDocumentApplicationAddAction(docName));
      dispatch(successTrueMsgAction(true));
      setDocName("");
      setOpen(false);
    } else {
      alert("Please add document Name");
    }
  };
  // material Table export pdf

  //   const handleExportPDF = (tableData) => {
  //     exportToPDF(tableData);
  //   };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          // width: "auto",
          height: "72px",
          //   display: "flex",
          alignItems: "center",
          // paddingLeft: "15px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "space-between",
          border: "0.5px solid  solid #ECECEC",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, m: 1, fontSize: "20px" }}
        >
          Common Documents
        </Typography>

        <Box sx={{ m: 1 }}>
          <OutLinedButton
            title="Add"
            handleClick={handleClickOpen}
            widthSize="72px"
            heightSize="36px"
          />
        </Box>
      </Paper>
      {commonDocFindLoading ? (
        //  <Skeleton variant="rect" width="100%" height={500} />

        <>
          {/* <Skeleton /> */}
          <Typography variant="h1">
            <Skeleton animation={false} />
          </Typography>

          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
          <Typography variant="h3">
            <Skeleton animation="wave" />
          </Typography>

          <Skeleton animation={false} />
        </>
      ) : (
        <>
          <Box sx={{ mt: 2 }}>
            {sortedData.length > 0 ? (
              <Table className="commonTable">
                <TableHead>
                  <TableRow className="commonTableCell">
                    {columns?.map((column) => {
                      if (column.hidden) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={column.field}
                          className="commonTableCell"
                        >
                          <TableSortLabel
                            className="commonHeaderText"
                            active={column === sortBy}
                            direction={sortOrder}
                            onClick={() => requestSort(column)}
                          >
                            {column.title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedData?.map((item, indx) => (
                    <TableRow className="commonTable" key={indx}>
                      <TableCell>{item.sl}</TableCell>
                      <TableCell>{item.name}</TableCell>

                      {/* <TableCell>{item.isMandatory}</TableCell>

                    <TableCell>{item.isFrontBack}</TableCell> */}
                      <TableCell>
                        <SlOptionsVertical
                          id="demo-positioned-button"
                          aria-controls={
                            employeeOpenActionButton
                              ? "demo-positioned-menu"
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            employeeOpenActionButton ? "true" : undefined
                          }
                          onClick={(e) => {
                            handleClickAction(e, item.id);
                          }}
                          variant="outlined"
                          style={{ marginLeft: "10px", color: "#F80B7A" }}
                        />

                        <Menu
                          open={employeeOpenActionButton}
                          onClose={handleCloseAction}
                          anchorEl={employeeActionButtonShow}
                          //   placement="bottom"
                          disablePortal
                          modifiers={{
                            flip: {
                              enabled: true,
                            },
                          }}
                          //   style={{ marginLeft: "-55px" }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <MenuItem>
                              <RiDeleteBin6Line
                                style={{
                                  fontSize: "lg",
                                  width: "50px",
                                  height: "23px",
                                }}
                                onClick={() => {
                                  if (isDeletePrevilage("document upload")) {
                                    setEmployeeActionButtonShow(false);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        dispatch(
                                          deleteCommonDocAction(tableId)
                                        );
                                        Swal.fire(
                                          "Deleted!",
                                          "Your file has been deleted.",
                                          "success"
                                        );
                                      }
                                    });
                                  } else {
                                    setPrevilageAlert(true);
                                  }
                                }}
                              />
                            </MenuItem>
                          </Box>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                <TableFooter />
              </Table>
            ) : (
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "17px",
                  textTransform: "capitalize",
                }}
              >
                No items to List
              </Typography>
            )}
          </Box>
        </>
      )}

      {/* dialog pupup */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        <DialogTitle>{"Add Common Document"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <InputField
              label="Document Name"
              handleChange={(e) => setDocName(e.target.value)}
              widthSize="100%"
              value={docName}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>

      {/* Dialog for Previlage popUp */}

      <Dialog
        open={previlageAlert}
        onClose={() => setPrevilageAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setPrevilageAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission to create/delete
        </Typography>
      </Dialog>
    </>
  );
}

export default CommonDocument;
