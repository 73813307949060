import React from 'react'

function UniversityIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.01 17.0003C11.16 17.0003 10.3 16.7803 9.63001 16.3503L3.61001 12.4203C2.49001 11.6903 1.82001 10.4603 1.82001 9.12028C1.82001 7.78028 2.49001 6.55028 3.61001 5.82028L9.64001 1.90028C10.98 1.03028 13.07 1.03028 14.4 1.91028L20.39 5.84028C21.5 6.57028 22.17 7.80028 22.17 9.13028C22.17 10.4603 21.5 11.6903 20.39 12.4203L14.4 16.3503C13.73 16.7903 12.87 17.0003 12.01 17.0003ZM12.01 2.75028C11.44 2.75028 10.87 2.88028 10.46 3.16028L4.44001 7.08028C3.74001 7.54028 3.33001 8.28028 3.33001 9.12028C3.33001 9.96028 3.73001 10.7003 4.44001 11.1603L10.46 15.0903C11.29 15.6303 12.75 15.6303 13.58 15.0903L19.57 11.1603C20.27 10.7003 20.67 9.96028 20.67 9.12028C20.67 8.28028 20.27 7.54028 19.57 7.08028L13.58 3.15028C13.16 2.89028 12.59 2.75028 12.01 2.75028Z"
        fill="#ACB1C6"
      />
      <path
        d="M12 22.7501C11.56 22.7501 11.11 22.6901 10.75 22.5701L7.56 21.5101C6.05 21.0101 4.86 19.3601 4.87 17.7701L4.88 13.0801C4.88 12.6701 5.22 12.3301 5.63 12.3301C6.04 12.3301 6.38 12.6701 6.38 13.0801L6.37 17.7701C6.37 18.7101 7.15 19.7901 8.04 20.0901L11.23 21.1501C11.63 21.2801 12.37 21.2801 12.77 21.1501L15.96 20.0901C16.85 19.7901 17.63 18.7101 17.63 17.7801V13.1401C17.63 12.7301 17.97 12.3901 18.38 12.3901C18.79 12.3901 19.13 12.7301 19.13 13.1401V17.7801C19.13 19.3701 17.95 21.0101 16.44 21.5201L13.25 22.5801C12.89 22.6901 12.44 22.7501 12 22.7501Z"
        fill="#ACB1C6"
      />
      <path
        d="M21.4 15.75C20.99 15.75 20.65 15.41 20.65 15V9C20.65 8.59 20.99 8.25 21.4 8.25C21.81 8.25 22.15 8.59 22.15 9V15C22.15 15.41 21.81 15.75 21.4 15.75Z"
        fill="#ACB1C6"
      />
    </svg>
  );
}

export default UniversityIcon