import React, { useEffect } from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { TypographyText1 } from "../../customComponent/Typography";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { useState } from "react";
import { SubmitButton } from "../../customComponent/Buttons";
import "../../customComponent/inputField.css";
import { useDispatch, useSelector } from "react-redux";
import { ieltsTeacherManagmentCreateAction } from "../../../actions/IeltsActions/teacherManagmentAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import { ieltsCategoryFindAction } from "../../../actions/IeltsActions/learningManadmentAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

function IeltsTeacherAddForm({ toggle }) {
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState("");
  const [avatarImage, setAvatarImage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [adharNumber, setAdharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(true);
  const [type, setType] = useState(false);
  const [err, setErr] = useState({
    name: "",
    phone: "",
    email: "",
    specialization: "",
    adharNumber: "",
    pan: "",
    userName: "",
    password: "",
  });
  const navigate = useNavigate();

  // Function to validate Aadhar number

  function validateAadhar(aadharNumber) {
    const aadharPattern = /^\d{12}$/;
    return aadharPattern.test(aadharNumber);
  }

  // Function to validate Pan number

  function validatePAN(panNumber) {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/; // Regular expression for PAN
    return panPattern.test(panNumber);
  }

  // Handle image upload

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setProfileImage(file);
    const reader = new FileReader();
    // Read the image file as a data URL
    reader.readAsDataURL(file);

    // Set the previewImage state when the reader has finished loading the image
    reader.onloadend = () => {
      setAvatarImage(reader.result);
    };
  };

  // handle Password

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setValid(validatePassword(newPassword));
    setPassword(newPassword);
    setErr((prev) => ({
      ...prev,
      ["password"]: "",
    }));
  };

  const validatePassword = (password) => {
    // Check if the password contains at least 6 characters and one symbol
    const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
    return regex.test(password);
  };

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsTeacherManagmentTeacherCreateSuccess,
    ieltsTeacherManagmentTeacherCreateerror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherCreate;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsTeacherManagmentTeacherCreateSuccess?.status == true
    ) {
      Swal.fire(
        "Created",
        ieltsTeacherManagmentTeacherCreateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsTeacherManagmentTeacherCreateSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsTeacherManagmentTeacherCreateSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsTeacherManagmentTeacherCreateSuccess, successAlertMsgSuccess]);

  // Category find action

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );
  useEffect(() => {
    dispatch(ieltsCategoryFindAction("teacherTable"));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  // console.log(specialization, "specializationspecializationspecialization");

  //   HandleForm submit function

  const handleFormSubmit = () => {
    let isValid = true;
    const currentErr = { ...err };

    if (!name) {
      currentErr.name = "Enter teacher name";
      isValid = false;
    }
    if (!phone) {
      currentErr.phone = "Enter teacher phonenumber";
      isValid = false;
    }
    if (!email) {
      currentErr.email = "Enter teacher email";
      isValid = false;
    }
    if (specialization?.length == 0) {
      currentErr.specialization = "Enter teacher specialization";
      isValid = false;
    }
    if (!panNumber) {
      currentErr.pan = "Enter teacher panNumber";
      isValid = false;
    } else {
      const isPanValid = validatePAN(panNumber);
      if (!isPanValid) {
        currentErr.pan = "Enter valid panNumber";
        isValid = false;
      }
    }
    if (!adharNumber) {
      currentErr.adharNumber = "Enter teacher adharNumber";
      isValid = false;
    } else {
      const isAadharValid = validateAadhar(adharNumber);
      if (!isAadharValid) {
        currentErr.adharNumber = "Enter valid adharNumber";
        isValid = false;
      }
    }
    if (!userName) {
      currentErr.userName = "Enter teacher userName";
      isValid = false;
    }
    if (!password) {
      currentErr.password = "create teacher password";
      isValid = false;
    }
    setErr(currentErr);
    if (!isValid) {
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    specialization.forEach((item) => {
      formData.append("category", item.CategoryName);
    });
    specialization.forEach((item) => {
      formData.append("specialization", item.id);
    });
    formData.append("pan", panNumber);
    formData.append("adhar", adharNumber);
    formData.append("username", userName);
    formData.append("password", password);
    formData.append("image", profileImage);
    console.log(formData);
    dispatch(ieltsTeacherManagmentCreateAction(formData));
    dispatch(successTrueMsgAction(true));
    // setName("");
    // setPhone("");
    // setEmail("");
    // setSpecialization([]);
    // setPanNumber("");
    // setAdharNumber("");
    // setUserName("");
    // setPassword("");
    // setAvatarImage("");
    setErr({
      name: "",
      phone: "",
      email: "",
      specialization: "",
      adharNumber: "",
      pan: "",
      userName: "",
      password: "",
    });
  };

  // console.log(specialization, "specializationspecialization");
  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          // paddingLeft: "16px",
          marginTop: "18px",
          gap:"10px"
        }}
      >
        <KeyboardBackspaceIcon onClick={() => navigate(-1)} sx={{
          cursor:"pointer"
        }} />
        <Typography
          sx={{
            fontWeight:"600",
          }}
        >
          Enter Profile Details
        </Typography>
        {/* <TypographyText1 variant="h6" title="Enter Profile Details" /> */}
      </Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontFamily: "'Open Sans', sans-serif",
          color: "rgba(172, 177, 198, 1)",
          ml: 1.5,
        }}
      >
        Profile Picture
      </Typography>

      <Box mt={3} mb={5}>
        <Avatar
          src={avatarImage}
          alt="Avatar"
          sx={{ width: 120, height: 120, backgroundColor: "#F4F4F4", m: 1.5 }}
        >
          {!avatarImage && (
            <label htmlFor="avatar-file">
              <img
                src="/menuIcons/fileUploadCenter.png"
                alt="gsl"
                style={{
                  width: 35,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </label>
          )}
        </Avatar>
        <input
          type="file"
          accept="image/*"
          id="avatar-file"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </Box>
      <div>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Name"
              handleChange={(e) => {
                setName(e.target.value);
                setErr((prev) => ({
                  ...prev,
                  ["name"]: "",
                }));
              }}
              widthSize="98.4%"
              value={name}
              required
              paramses
              InputType="text"
              dispcriptionHeight
            />
            {err.name ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                {err.name}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Mobile number"
              widthSize="98.4%"
              handleChange={(e) => {
                if (/^\d{0,16}$/.test(e.target.value)) {
                  /^\d{0,9}$/.test(e.target.value)
                    ? setPhoneError(true)
                    : setPhoneError(false);
                  setPhone(e.target.value);
                  setErr((prev) => ({
                    ...prev,
                    ["phone"]: "",
                  }));
                } else {
                  setPhoneError(false);
                }
              }}
              value={phone}
              InputType="number"
            />
            {/* {phoneError ? (
              <span
                style={{
                  color: "red",
                  fontSize: "13px",
                  fontWeight: 300,
                  paddingTop: "-20px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                Please Enter Atleast 10 Numbers
              </span>
            ) : null} */}
            {err.phone ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                {err.phone}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Email address"
              handleChange={(e) => {
                setEmail(e.target.value);
                setErr((prev) => ({
                  ...prev,
                  ["email"]: "",
                }));
              }}
              value={email}
              widthSize="98.4%"
            />
            {err.email ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                {err.email}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <FormControl
              label="skajfhkasdfhjlas"
              sx={{
                width: "100%",
                // maxWidth: widthSize,
              }}
              fullWidth
            >
              <InputLabel
                id="lead-source-label"
                className="formControlLabelRole"
                sx={{
                  fontWeight: 400,
                  color: "black",
                  fontSize: "14px",
                }}
              >
                Specialization
              </InputLabel>
              <Select
                value={specialization}
                multiple
                // input={
                //   multiple === "truSelectInputField2e" ? (
                //     <OutlinedInput id="select-multiple-chip" label="Chip" />
                //   ) : null
                // }
                onChange={(e) => {
                  setSpecialization(e.target.value);
                  setErr((prev) => ({
                    ...prev,
                    ["specialization"]: "",
                  }));
                }}
                labelId="lead-source-label"
                id="lead-source-select"
                className="formControlSelectRole"
                name={name}
                sx={{
                  width: null,
                  backgroundColor: "white",
                  // border:"1px solid #c4c5d6",
                  height: "46px",
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "100%",
                  },
                  "& .MuiInputBase-root": {
                    borderWidth: "1px",
                    borderRadius: "4px",
                    borderColor: "#c4c5d6",
                    border: "none",

                    "&:hover": {
                      border: "none",
                    },
                    "&.Mui-focused": {
                      border: "none",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    boxSizing: "border-box",
                    py: "0px",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {categoryList?.map((data) => (
                  <MenuItem
                    key={data.id}
                    value={data}
                    style={{ minHeight: "20px" }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    {data.CategoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {err.specialization ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.specialization}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="PAN"
              handleChange={(e) => {
                setPanNumber(e.target.value);
                setErr((prev) => ({
                  ...prev,
                  ["pan"]: "",
                }));
              }}
              value={panNumber}
              widthSize="98.4%"
            />
            {err.pan ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                {err.pan}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Aadhar"
              handleChange={(e) => {
                setAdharNumber(e.target.value);
                setErr((prev) => ({
                  ...prev,
                  ["adharNumber"]: "",
                }));
              }}
              value={adharNumber}
              widthSize="98.4%"
            />
            {err.adharNumber ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                {err.adharNumber}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <TypographyText1 variant="h6" title="Login Details" />
        </Box>

        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <InputField
              label="User name"
              handleChange={(e) => {
                setUserName(e.target.value);
                setErr((prev) => ({
                  ...prev,
                  ["userName"]: "",
                }));
              }}
              value={userName}
              widthSize="99%"
              autocomplete="false"
            />
            {err.userName ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  mb: "1rem",
                }}
              >
                {err.userName}
              </Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid item lg={6} xs={12}>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <InputField
                label="Password"
                handleChange={handlePasswordChange}
                value={password}
                InputType={type ? "password" : "text"}
                widthSize="99%"
                autocomplete="false"
              />

              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  zIndex: "999",
                }}
              >
                {!type ? (
                  <VisibilityIcon
                    onClick={() => setType(true)}
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setType(false)}
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
            </Box>
            {err.password ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  mt: "-12px",
                  // mb: "1rem",
                }}
              >
                {err.password}
              </Typography>
            ) : (
              ""
            )}
            {!valid && (
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  fontWeight: 300,
                  // marginTop: "-12px",
                  // marginBottom: "1rem",
                  display: "block",
                }}
              >
                Password must have at least 6 characters and contain at least
                one symbol (!@#$%^&*)
              </span>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "right", mb: 1, mt: 1 }}>
          <SubmitButton
            title="Save"
            submit="submit"
            widthSize="75px"
            heightSize="42px"
            type="click"
            handleSubmit={handleFormSubmit}
          />
        </Grid>
      </div>
    </MainContainer>
  );
}

export default IeltsTeacherAddForm;
