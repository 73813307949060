import {
  CHAT_WITH_COUNSELOR_ERR,
  CHAT_WITH_COUNSELOR_NOTIFICATION_ERR,
  CHAT_WITH_COUNSELOR_NOTIFICATION_REQUEST,
  CHAT_WITH_COUNSELOR_NOTIFICATION_SUCCESS,
  CHAT_WITH_COUNSELOR_REQUEST,
  CHAT_WITH_COUNSELOR_STATUS_UPDATE_ERR,
  CHAT_WITH_COUNSELOR_STATUS_UPDATE_REQUEST,
  CHAT_WITH_COUNSELOR_STATUS_UPDATE_SUCCESS,
  CHAT_WITH_COUNSELOR_SUCCESS,
  COUNSELOR_NOTIFICATION_UPDATE_ERR,
  COUNSELOR_NOTIFICATION_UPDATE_REQUEST,
  COUNSELOR_NOTIFICATION_UPDATE_SUCCESS,
  GET_CHAT_WITH_COUNSELOR_ERR,
  GET_CHAT_WITH_COUNSELOR_REQUEST,
  GET_CHAT_WITH_COUNSELOR_SUCCESS,
} from "../constants/leadChatConstants";

//  chat with counselor Reducer

export const chatWithCounsilorReducer = (state = {}, action) => {
  switch (action.type) {
    case CHAT_WITH_COUNSELOR_REQUEST:
      return {
        ...state,
        chatWithCounselorLoading: true,
      };
    case CHAT_WITH_COUNSELOR_SUCCESS:
      return {
        ...state,
        chatWithCounselorLoading: false,
        chatWithCounselorSuccess: action.payload,
      };
    case CHAT_WITH_COUNSELOR_ERR:
      return {
        ...state,
        chatWithCounselorLoading: false,
        chatWithCounselorErr: action.payload,
      };
    default:
      return state;
  }
};

//  get chat with counselor Reducer

export const getChatWithCounsilorReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHAT_WITH_COUNSELOR_REQUEST:
      return {
        ...state,
        getChatWithCounselorLoading: true,
      };
    case GET_CHAT_WITH_COUNSELOR_SUCCESS:
      return {
        ...state,
        getChatWithCounselorLoading: false,
        getChatWithCounselorSuccess: action.payload,
      };
    case GET_CHAT_WITH_COUNSELOR_ERR:
      return {
        ...state,
        getChatWithCounselorLoading: false,
        getChatWithCounselorErr: action.payload,
      };
    default:
      return state;
  }
};

//   chat with counselor notification Reducer

export const ChatWithCounsilorNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case CHAT_WITH_COUNSELOR_NOTIFICATION_REQUEST:
      return {
        ...state,
        chatWithCounselorNotificationLoading: true,
      };
    case CHAT_WITH_COUNSELOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        chatWithCounselorNotificationLoading: false,
        chatWithCounselorNotificationSuccess: action.payload,
      };
    case CHAT_WITH_COUNSELOR_NOTIFICATION_ERR:
      return {
        ...state,
        chatWithCounselorNotificationLoading: false,
        chatWithCounselorNotificationErr: action.payload,
      };
    default:
      return state;
  }
};

// chat with cousilor status change reducer

export const ChatWithCounsilorStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case CHAT_WITH_COUNSELOR_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        chatWithCounselorStatusChangeLoading: true,
      };
    case CHAT_WITH_COUNSELOR_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        chatWithCounselorStatusChangeLoading: false,
        chatWithCounselorStatusChangeSuccess: action.payload,
      };
    case CHAT_WITH_COUNSELOR_STATUS_UPDATE_ERR:
      return {
        ...state,
        chatWithCounselorStatusChangeLoading: false,
        chatWithCounselorStatusChangeErr: action.payload,
      };
    default:
      return state;
  }
};

// counsilorNotificationClearReducer
export const counsilorNotificationClearReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNSELOR_NOTIFICATION_UPDATE_REQUEST:
      return {
        ...state,
        counselorStatusChangeLoading: true,
      };
    case COUNSELOR_NOTIFICATION_UPDATE_SUCCESS:
      return {
        ...state,
        counselorStatusChangeLoading: false,
        counselorStatusChangeSuccess: action.payload,
      };
    case COUNSELOR_NOTIFICATION_UPDATE_ERR:
      return {
        ...state,
        counselorStatusChangeLoading: false,
        counselorStatusChangeErr: action.payload,
      };
    default:
      return state;
  }
};
