import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";

function TeacherModalListItems({
  item,
  handleCheckboxChange,
  selectedCheckboxes,
  listview,
  handleDeleteTeacher,
  id,
}) {
  return (
    <>
      <Box
        key={item?.id}
        sx={{
          padding: "8px",
          boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
          borderRadius: "8px",
          display: "grid",
          gridTemplateColumns: "80px 1fr",
          gap: "12px",
          position: "relative",
          "& .delete": {
            position: "absolute",
            right: "10px",
            top: "10px",
          },
        }}
      >
        {listview && (
          <svg
            onClick={() => handleDeleteTeacher(item?.teacherId)}
            cursor="pointer"
            className="delete"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7962_14386)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.00476 0.771182C5.00478 0.665172 5.04861 0.563509 5.12662 0.48853C5.20462 0.413552 5.31043 0.371394 5.42077 0.371319L9.58066 0.371094C9.69104 0.37134 9.79681 0.413615 9.87479 0.488653C9.95277 0.563692 9.9966 0.665372 9.99668 0.771407V1.75979H5.00476V0.771182ZM11.8444 14.209C11.8338 14.3649 11.7616 14.5109 11.6426 14.6173C11.5235 14.7237 11.3667 14.7823 11.2041 14.7812H3.7495C3.58698 14.7808 3.43064 14.7213 3.31188 14.6148C3.19312 14.5082 3.12073 14.3624 3.10925 14.2066L2.47134 5.24178H12.5253L11.8446 14.2089L11.8444 14.209ZM13.5017 4.42984H1.5V3.50104C1.50018 3.2547 1.6021 3.0185 1.7834 2.8443C1.96469 2.67009 2.21053 2.57212 2.46694 2.5719L12.5346 2.57159C12.791 2.57197 13.0368 2.67003 13.218 2.84425C13.3993 3.01847 13.5012 3.25464 13.5014 3.50095V4.42976L13.5017 4.42984ZM5.5684 12.6441C5.5684 12.6974 5.57933 12.7502 5.60056 12.7995C5.62179 12.8487 5.65292 12.8935 5.69215 12.9311C5.73139 12.9688 5.77796 12.9987 5.82923 13.0191C5.88049 13.0395 5.93543 13.05 5.99092 13.05C6.0464 13.05 6.10135 13.0395 6.15261 13.0191C6.20387 12.9987 6.25045 12.9688 6.28968 12.9311C6.32892 12.8935 6.36004 12.8487 6.38128 12.7995C6.40251 12.7502 6.41344 12.6974 6.41344 12.6441V6.95179C6.41255 6.84473 6.36767 6.74236 6.28857 6.66695C6.20948 6.59154 6.10257 6.5492 5.99114 6.54916C5.8797 6.54913 5.77277 6.59139 5.69361 6.66675C5.61446 6.7421 5.56951 6.84445 5.56855 6.9515V12.6441H5.5684ZM8.58305 12.6441C8.58305 12.7518 8.62757 12.8551 8.70682 12.9312C8.78607 13.0073 8.89355 13.0501 9.00563 13.0501C9.1177 13.0501 9.22518 13.0073 9.30443 12.9312C9.38368 12.8551 9.4282 12.7518 9.4282 12.6441V6.95179C9.42731 6.84472 9.38243 6.74233 9.30332 6.66691C9.22422 6.59148 9.11729 6.54915 9.00584 6.54911C8.89439 6.54907 8.78744 6.59134 8.70828 6.66671C8.62912 6.74208 8.58416 6.84444 8.58319 6.9515L8.58305 12.6441Z"
                fill="#141E3C"
              />
            </g>
            <defs>
              <clipPath id="clip0_7962_14386">
                <rect
                  width="15"
                  height="14.4105"
                  fill="white"
                  transform="translate(0 0.371094)"
                />
              </clipPath>
            </defs>
          </svg>
        )}

        <Box
          sx={{
            width: "80px",
            height: "76px",
            "& img": {
              borderRadius: "8px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          }}
        >
          <img
            src={
              item?.ielts_teacher?.imageUrl || item?.imageUrl
              // "https://images.pexels.com/photos/1391498/pexels-photo-1391498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
            alt="teacher image"
          />
        </Box>
        <Box>
          <Typography
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
              fontSize: "16px",
              fontWeight: "700",
              color: "#141E3C",
              pr:"25px",
            }}
          >
            {item?.ielts_teacher?.teacherName || item?.teacherName}
          </Typography>
          <Typography
            sx={{
              color: "#696E72",
              fontSize: "16px",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
            }}
          >
            {item?.ielts_teacher?.teacherSpecialization ||
              item?.teacherSpecialization}
          </Typography>
          {!listview && (
            <Box
              sx={{
                textAlign: "right",
              }}
            >
              <Checkbox
                checked={
                  selectedCheckboxes?.includes(
                    item?.ielts_teacher?.id || item?.id
                  )
                    ? true
                    : false
                }
                onChange={() =>
                  handleCheckboxChange(item?.ielts_teacher?.id || item?.id)
                }
                sx={{
                  color: "rgba(254, 11, 122, 1)",
                  "&.Mui-checked": {
                    color: "rgba(254, 11, 122, 1)",
                  },
                }}
              />
            </Box>
          )}

          {selectedCheckboxes?.length > 0 &&
            selectedCheckboxes?.includes(
              item?.id || item?.audio_id || item?.pdf_id
            ) && (
              <Typography
                sx={{
                  color: "#fff",
                  right: "-10px",
                  top: "-10px",
                  fontSize: "14px",
                  position: "absolute",
                  borderRadius: "50%",
                  padding: "10px",
                  backgroundColor: "rgba(254, 11, 122, 1)",
                  width: "15px",
                  height: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                }}
              >
                {selectedCheckboxes?.indexOf(
                  item?.id || item?.audio_id || item?.pdf_id
                ) + 1}
              </Typography>
            )}
        </Box>
      </Box>
    </>
  );
}

export default TeacherModalListItems;
