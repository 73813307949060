import {
  EMPLOYEE_FIND_REQUEST,
  EMPLOYEE_FIND_SUCCESS,
  EMPLOYEE_FIND_ERR,
  EMPLOYEE_ATTENDANCE_CREATE_REQUEST,
  EMPLOYEE_ATTENDANCE_CREATE_SUCCESS,
  EMPLOYEE_ATTENDANCE_CREATE_ERR,
  EMPLOYEE_HOLIDAY_FIND_REQUEST,
  EMPLOYEE_HOLIDAY_FIND_SUCCESS,
  EMPLOYEE_HOLIDAY_FIND_ERR,
  EMPLOYEE_HOLIDAY_CREATE_REQUEST,
  EMPLOYEE_HOLIDAY_CREATE_SUCCESS,
  EMPLOYEE_HOLIDAY_CREATE_ERR,
  EMPLOYEE_HOLIDAY_DELETE_REQUEST,
  EMPLOYEE_HOLIDAY_DELETE_SUCCESS,
  EMPLOYEE_HOLIDAY_DELETE_ERR,
  EMPLOYEE_HOLIDAY_EDIT_REQUEST,
  EMPLOYEE_HOLIDAY_EDIT_SUCCESS,
  EMPLOYEE_HOLIDAY_EDIT_ERR,
  EMPLOYEE_HOLIDAY_CREATE_EDIT_ERR,
  EMPLOYEE_HOLIDAY_CREATE_EDIT_SUCCESS,
  EMPLOYEE_HOLIDAY_CREATE_EDIT_REQUEST,
  EMPLOYEE_LEAVE_APPLICATION_FIND_REQUEST,
  EMPLOYEE_LEAVE_APPLICATION_FIND_SUCCESS,
  EMPLOYEE_LEAVE_APPLICATION_FIND_ERR,
  EMPLOYEE_PAYSLIP_FIND_REQUEST,
  EMPLOYEE_PAYSLIP_FIND_SUCCESS,
  EMPLOYEE_PAYSLIP_FIND_ERR,
  EMPLOYEE_PAYSLIP_CREATE_REQUEST,
  EMPLOYEE_PAYSLIP_CREATE_SUCCESS,
  EMPLOYEE_PAYSLIP_CREATE_ERR,
  EMPLOYEE_JOB_CREATE_REQUEST,
  EMPLOYEE_JOB_CREATE_SUCCESS,
  EMPLOYEE_JOB_CREATE_ERR,
  EMPLOYEE_JOB_FIND_SUCCESS,
  EMPLOYEE_JOB_FIND_REQUEST,
  EMPLOYEE_JOB_FIND_ERR,
  EMPLOYEE_JOB_DELETE_REQUEST,
  EMPLOYEE_JOB_DELETE_ERR,
  EMPLOYEE_JOB_DELETE_SUCCESS,
  EMPLOYEE_JOB_EDIT_REQUEST,
  EMPLOYEE_JOB_EDIT_SUCCESS,
  EMPLOYEE_JOB_EDIT_ERR,
  EMPLOYEE_JOB_UPDATE_REQUEST,
  EMPLOYEE_JOB_UPDATE_SUCCESS,
  EMPLOYEE_JOB_UPDATE_ERR,
  EMPLOYEE_ATTENDANCE_SEARCH_REQUEST,
  EMPLOYEE_ATTENDANCE_SEARCH_SUCCESS,
  EMPLOYEE_ATTENDANCE_SEARCH_ERR,
  EMPLOYEE_PAYSLIP_DELETE_REQUEST,
  EMPLOYEE_PAYSLIP_DELETE_SUCCESS,
  EMPLOYEE_PAYSLIP_DELETE_ERR,
  EMPLOYEE_JOB_FIND_BY_ID_REQUEST,
  EMPLOYEE_JOB_FIND_BY_ID_SUCCESS,
  EMPLOYEE_JOB_FIND_BY_ID_ERR,
  JOB_APPLIED_LIST_REQUEST,
  JOB_APPLIED_LIST_SUCCESS,
  JOB_APPLIED_LIST_ERR,
  JOB_STATUS_CHANGE_REQUEST,
  JOB_STATUS_CHANGE_SUCCESS,
  JOB_STATUS_CHANGE_ERR,
  APPLIED_JOB_STATUS_CHANGE_REQUEST,
  APPLIED_JOB_STATUS_CHANGE_SUCCESS,
  APPLIED_JOB_STATUS_CHANGE_ERR,
} from "../../constants/Hr/EmployeeManagmentConstant";

import axios from "axios";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../constants/roleManagmentConstant";

// Employee_Find_Request

export const hrEmployeeFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/hrEmployee/hrFindEmployee",
      config
    );
    dispatch({ type: EMPLOYEE_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EMPLOYEE_FIND_ERR, payload: error.response.data });
  }
};

// Employee_Attendance_Creation

export const hrEmployeeAttendanceAction = (
  Employeeid,
  isChecked,
  status
) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_ATTENDANCE_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/hrEmployee/hrCreateEmployeeAttendance",
      {
        Employeeid,
        isChecked,
        status,
      },
      config
    );
    dispatch({ type: EMPLOYEE_ATTENDANCE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_ATTENDANCE_CREATE_ERR,
      payload: error.response.data,
    });
  }
};

// Employee_Attendance_Creation

export const employeeSearchAction = (branchName, date) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EMPLOYEE_ATTENDANCE_SEARCH_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/hrEmployee/employeeSearch?branchName=${branchName}&dateValue=${date}`,
      config
    );
    dispatch({ type: EMPLOYEE_ATTENDANCE_SEARCH_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_ATTENDANCE_SEARCH_ERR,
      payload: error.response.data,
    });
  }
};

//// HR_EMPLOYEE_HOLIDAY_FIND

export const hrEmployeeHolidayFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_HOLIDAY_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/hrEmployee/hrFindEmployeeHoliday",

      config
    );
    dispatch({ type: EMPLOYEE_HOLIDAY_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EMPLOYEE_HOLIDAY_FIND_ERR, payload: error.response.data });
  }
};

// HR_EMPLOYEE_HOLIDAY_CREATION

export const hrEmployeeHolidayCreateAction = (
  holidaymonth,
  LeaveCount,
  Holidayname,
  HolidayDate
) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_HOLIDAY_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/hrEmployee/hrCreateEmployeeHoliday",
      {
        holidaymonth,
        LeaveCount,
        Holidayname,
        HolidayDate,
        username,
      },
      config
    );
    dispatch({ type: EMPLOYEE_HOLIDAY_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_HOLIDAY_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// HR_EMPLOYEE_HOLIDAY_DELETION

export const hrEmployeeHolidayDeleteAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EMPLOYEE_HOLIDAY_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/hrEmployee/hrDeleteEmployeeHoliday?id=${id}`,
      config
    );
    dispatch({ type: EMPLOYEE_HOLIDAY_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_HOLIDAY_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

//// HR_EMPLOYEE_HOLIDAY_FIND_EDITE

export const hrEmployeeHolidayEditAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EMPLOYEE_HOLIDAY_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(
      `/api/admin/hrEmployee/hrFindEditEmployeeHoliday?id=${id}`,
      config
    );
    dispatch({ type: EMPLOYEE_HOLIDAY_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_HOLIDAY_EDIT_ERR,
      payload: error.response.data,
    });
  }
};

//// HR_EMPLOYEE_HOLIDAY_CREATE_EDITE

export const hrEmployeeHolidayCreateEditAction = (
  holidaymonth,
  LeaveCount,
  Holidayname,
  HolidayDate,
  Id
) => async (dispatch, getState) => {
  try {
    dispatch({ type: EMPLOYEE_HOLIDAY_CREATE_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.put(
      `/api/admin/hrEmployee/hrEditEmployeeHoliday?Id=${Id}`,
      {
        holidaymonth,
        LeaveCount,
        Holidayname,
        HolidayDate,
        username,
      },
      config
    );
    dispatch({ type: EMPLOYEE_HOLIDAY_CREATE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_HOLIDAY_CREATE_EDIT_ERR,
      payload: error.response.data,
    });
  }
};

//LEAVE_APPLICATION_TAB_ACTION ///

// HR_EMPLOYEE_LEAVE_APPLICATION_FIND

export const hrEmployeeLeaveApplicationFindAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EMPLOYEE_LEAVE_APPLICATION_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/hrEmployee/hrFindEmployeeLeaveApplication",

      config
    );
    dispatch({
      type: EMPLOYEE_LEAVE_APPLICATION_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_LEAVE_APPLICATION_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// PAYSLP_APPLICATION_TAB_ACTION //

//HR_EMPLOYEE_PAYSLIP_APPLICATION_FIND

export const hrEmployeePayslipFindAction = (Date) => async (
  dispatch,
  getState
) => {
  try {
    console.log(Date, "&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7");
    dispatch({ type: EMPLOYEE_PAYSLIP_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/hrEmployee/hrFindEmployeePayslip?Id=${Date}`,

      config
    );
    dispatch({ type: EMPLOYEE_PAYSLIP_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_PAYSLIP_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_PAYSLIP_CREATE

export const hrEmployeePayslipCreateAction = (
  Payslip,
  EmploayeeId,
  Month
) => async (dispatch, getState) => {
  try {
    console.log("Action keri");
    dispatch({ type: EMPLOYEE_PAYSLIP_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/hrEmployee/hrCreateEmployeePayslip",
      {
        Payslip,
        EmploayeeId,
        Month,
      },

      config
    );
    dispatch({ type: EMPLOYEE_PAYSLIP_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_PAYSLIP_CREATE_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_PAYSLIP_DELETE

export const hrEmployeePayslipDeleteAction = (id, monthName) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EMPLOYEE_PAYSLIP_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/hrEmployee/hrDeleteEmployeePayslip",
      {
        id,
        monthName,
      },

      config
    );
    dispatch({ type: EMPLOYEE_PAYSLIP_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_PAYSLIP_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_JOB_CREATE

export const hrEmployeeJobCreateAction = (
  JobTitle,
  JobLocation,
  JobType,
  Experience,
  Vacancies,
  htmlContent
) => async (dispatch, getState) => {
  try {
    console.log("Jobcreationil keri");
    dispatch({ type: EMPLOYEE_JOB_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/hrEmployee/hrCreateEmployeeJobs",
      {
        JobTitle,
        JobLocation,
        JobType,
        Experience,
        Vacancies,
        htmlContent,
      },

      config
    );
    dispatch({ type: EMPLOYEE_JOB_CREATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EMPLOYEE_JOB_CREATE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// HR_EMPLOYEE_JOB_FIND

export const hrEmployeeJobFindAction = () => async (dispatch, getState) => {
  try {
    console.log("Jobfindil keri");
    dispatch({ type: EMPLOYEE_JOB_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/hrEmployee/hrFindEmployeeJobs",
      config
    );
    dispatch({ type: EMPLOYEE_JOB_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EMPLOYEE_JOB_FIND_ERR, payload: error.response.data });
  }
};

// HR_EMPLOYEE_JOB_FIND_BY_ID

export const hrEmployeeJobFindByIdAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EMPLOYEE_JOB_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/hrEmployee/hrFindEmployeeJobsById?id=${id}`,
      config
    );
    dispatch({ type: EMPLOYEE_JOB_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_JOB_FIND_BY_ID_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_JOB_DELETE

export const hrEmployeeJobDeleteAction = (id) => async (dispatch, getState) => {
  try {
    console.log("delete");
    dispatch({ type: EMPLOYEE_JOB_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/hrEmployee/hrDeleteEmployeeJob?id=${id}`,
      config
    );
    dispatch({ type: EMPLOYEE_JOB_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_JOB_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_JOB_FIND_TO_EDIT

export const hrEmployeeJobEditAction = (id) => async (dispatch, getState) => {
  try {
    console.log(id, "KKKKKKKKKKKKKK");
    dispatch({ type: EMPLOYEE_JOB_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(
      `/api/admin/hrEmployee/hrFindEditEmployeeJob?id=${id}`,
      config
    );
    dispatch({ type: EMPLOYEE_JOB_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_JOB_EDIT_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_JOB_UPDATE

export const hrEmployeeJobUpdateAction = (
  id,
  JobTitle,
  JobLocation,
  JobType,
  Experience,
  Vacancies,
  htmlContent
) => async (dispatch, getState) => {
  try {
    console.log(id, "KKKKKKKKKKKKKK");
    dispatch({ type: EMPLOYEE_JOB_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    let username = isUserExist.name;
    console.log(username, "username kitty");
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.put(
      `/api/admin/hrEmployee/hrUpdateEmployeeJob?Id=${id}`,
      {
        JobTitle,
        JobLocation,
        JobType,
        Experience,
        Vacancies,
        htmlContent,
      },
      config
    );
    dispatch({ type: EMPLOYEE_JOB_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: EMPLOYEE_JOB_UPDATE_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_JOB_UPDATE

export const jobApplyListFindAction = (jobId) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOB_APPLIED_LIST_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get(
      `/api/admin/hrEmployee/jobAppliedDetails?jobId=${jobId}`,
      config
    );

    console.log(data, "datadatadata");
    dispatch({ type: JOB_APPLIED_LIST_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: JOB_APPLIED_LIST_ERR,
      payload: error.response.data,
    });
  }
};

// HR_EMPLOYEE_JOB_STATUS_UPDATE

export const jobStatusChangeAction = (status, id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: JOB_STATUS_CHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.put(
      `/api/admin/hrEmployee/jobStatusChange?status=${status}&id=${id}`,
      config
    );

    console.log(data, "datadatadata");
    dispatch({ type: JOB_STATUS_CHANGE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: JOB_STATUS_CHANGE_ERR,
      payload: error.response.data,
    });
  }
};

export const appliedJobStatusChangeAction = (status, id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: APPLIED_JOB_STATUS_CHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.put(
      `/api/admin/hrEmployee/appliedJobStatusChange?status=${status}&id=${id}`,
      config
    );

    dispatch({ type: APPLIED_JOB_STATUS_CHANGE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: APPLIED_JOB_STATUS_CHANGE_ERR,
      payload: error.response.data,
    });
  }
};
