import {
  NOTIFICATION_DELETE_ERR,
  NOTIFICATION_DELETE_REQUEST,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_EDIT_ERR,
  NOTIFICATION_EDIT_REQUEST,
  NOTIFICATION_EDIT_SUCCESS,
  PARTNER_APPROVAL_ERR,
  PARTNER_APPROVAL_REQUEST,
  PARTNER_APPROVAL_SUCCESS,
  PARTNER_COMMISSIONID_UPDATE_ERR,
  PARTNER_COMMISSIONID_UPDATE_REQUEST,
  PARTNER_COMMISSIONID_UPDATE_SUCCESS,
  PARTNER_COMMISSION_CATEGORY_ERR,
  PARTNER_COMMISSION_CATEGORY_REQUEST,
  PARTNER_COMMISSION_CATEGORY_SUCCESS,
  PARTNER_CREATE_ERR,
  PARTNER_CREATE_REQUEST,
  PARTNER_CREATE_SUCCESS,
  PARTNER_DELETE_ERR,
  PARTNER_DELETE_REQUEST,
  PARTNER_DELETE_SUCCESS,
  PARTNER_EDIT_DETAIL_ERR,
  PARTNER_EDIT_DETAIL_REQUEST,
  PARTNER_EDIT_DETAIL_SUCCESS,
  PARTNER_GET_ALL_ERR,
  PARTNER_GET_ALL_REQUEST,
  PARTNER_GET_ALL_SUCCESS,
  PARTNER_GET_DETAIL_REQUEST,
  PARTNER_GET_DETAIL_SUCCESS,
  PARTNER_LEAD_ENTROLLED_FIND_ERR,
  PARTNER_LEAD_ENTROLLED_FIND_REQUEST,
  PARTNER_LEAD_ENTROLLED_FIND_SUCCESS,
  PARTNER_LEAD_FIND_ERR,
  PARTNER_LEAD_FIND_REQUEST,
  PARTNER_LEAD_FIND_SUCCESS,
  PARTNER_NOTIFICATION_CREATE_ERR,
  PARTNER_NOTIFICATION_CREATE_REQUEST,
  PARTNER_NOTIFICATION_CREATE_SUCCESS,
  PARTNER_NOTIFICATION_FIND_ALL_ERR,
  PARTNER_NOTIFICATION_FIND_ALL_REQUEST,
  PARTNER_NOTIFICATION_FIND_ALL_SUCCESS,
  PARTNER_NOTIFICATION_FIND_ONE_ERR,
  PARTNER_NOTIFICATION_FIND_ONE_REQUEST,
  PARTNER_NOTIFICATION_FIND_ONE_SUCCESS,
  PARTNER_OTP_VERIFICATION_ERR,
  PARTNER_OTP_VERIFICATION_REQUEST,
  PARTNER_OTP_VERIFICATION_SUCCESS,
} from "../../constants/partnerConstant";

// findEnquiriedPartnerReducer
export const findAllPartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_GET_ALL_REQUEST:
      return {
        ...state,
        partnerFindLoading: true,
      };
    case PARTNER_GET_ALL_SUCCESS:
      return {
        ...state,
        partnerFindLoading: false,
        partnerFindSuccess: action.payload,
      };
    case PARTNER_GET_ALL_ERR:
      return {
        ...state,
        partnerFindLoading: false,
        partnerFindError: action.payload,
      };
    default:
      return state;
  }
};

// findEntrolledPartnerLeadReducer
export const findAllPartnerEntrolledLeadReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_LEAD_ENTROLLED_FIND_REQUEST:
      return {
        ...state,
        partnerLeadEntrolledFindLoading: true,
      };
    case PARTNER_LEAD_ENTROLLED_FIND_SUCCESS:
      return {
        ...state,
        partnerLeadEntrolledFindLoading: false,
        partnerLeadEntrolledFindSuccess: action.payload,
      };
    case PARTNER_LEAD_ENTROLLED_FIND_ERR:
      return {
        ...state,
        partnerLeadEntrolledFindLoading: false,
        partnerLeadEntrolledFindError: action.payload,
      };
    default:
      return state;
  }
};

// partnerKycDetailFindReducer
export const partnerKycDetailFindReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_GET_DETAIL_REQUEST:
      return {
        ...state,
        partnerDetailFindLoading: true,
      };
    case PARTNER_GET_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetailFindLoading: false,
        partnerDetailFindSuccess: action.payload,
      };
    case PARTNER_GET_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetailFindLoading: false,
        partnerDetailFindError: action.payload,
      };
    default:
      return state;
  }
};

// partnerKycApprovalReducer
export const partnerKycApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_APPROVAL_REQUEST:
      return {
        ...state,
        partnerKycApprovalLoading: true,
      };
    case PARTNER_APPROVAL_SUCCESS:
      return {
        ...state,
        partnerKycApprovalLoading: false,
        partnerKycApprovalSuccess: action.payload,
      };
    case PARTNER_APPROVAL_ERR:
      return {
        ...state,
        partnerKycApprovalLoading: false,
        partnerKycApprovalError: action.payload,
      };
    default:
      return state;
  }
};

// partnerAddReducer
export const partnerAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_CREATE_REQUEST:
      return {
        ...state,
        partnerCreateLoading: true,
      };
    case PARTNER_CREATE_SUCCESS:
      return {
        ...state,
        partnerCreateLoading: false,
        partnerCreateSuccess: action.payload,
      };
    case PARTNER_CREATE_ERR:
      return {
        ...state,
        partnerCreateLoading: false,
        partnerCreateError: action.payload,
      };
    default:
      return state;
  }
};

// partnerOtpVerifyReducer
export const partnerOtpVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_OTP_VERIFICATION_REQUEST:
      return {
        ...state,
        partnerOtpVerifyLoading: true,
      };
    case PARTNER_OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        partnerOtpVerifyLoading: false,
        partnerOtpVerifySuccess: action.payload,
      };
    case PARTNER_OTP_VERIFICATION_ERR:
      return {
        ...state,
        partnerOtpVerifyLoading: false,
        partnerOtpVerifyError: action.payload,
      };
    default:
      return state;
  }
};

// deletePartnerReducer
export const deletePartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_DELETE_REQUEST:
      return {
        ...state,
        partnerDeleteLoading: true,
      };
    case PARTNER_DELETE_SUCCESS:
      return {
        ...state,
        partnerDeleteLoading: false,
        partnerDeleteSuccess: action.payload,
      };
    case PARTNER_DELETE_ERR:
      return {
        ...state,
        partnerDeleteLoading: false,
        partnerDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// kycDetailsEditReducer
export const kycDetailsEditReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_EDIT_DETAIL_REQUEST:
      return {
        ...state,
        partnerKycApprovalLoading: true,
      };
    case PARTNER_EDIT_DETAIL_SUCCESS:
      return {
        ...state,
        partnerKycEditLoading: false,
        partnerKycEditSuccess: action.payload,
      };
    case PARTNER_EDIT_DETAIL_ERR:
      return {
        ...state,
        partnerKycEditLoading: false,
        partnerKycEditError: action.payload,
      };
    default:
      return state;
  }
};

// partnerLeadFindReducer
export const partnerLeadFindReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_LEAD_FIND_REQUEST:
      return {
        ...state,
        partnerLeadFindLoading: true,
      };
    case PARTNER_LEAD_FIND_SUCCESS:
      return {
        ...state,
        partnerLeadFindLoading: false,
        partnerLeadFindSuccess: action.payload,
      };
    case PARTNER_LEAD_FIND_ERR:
      return {
        ...state,
        partnerLeadFindLoading: false,
        partnerLeadFindError: action.payload,
      };
    default:
      return state;
  }
};

// allPartnerCommissionCategoryFindReducer
export const allPartnerCommissionCategoryFindReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_COMMISSION_CATEGORY_REQUEST:
      return {
        ...state,
        partnerCommissionFindLoading: true,
      };
    case PARTNER_COMMISSION_CATEGORY_SUCCESS:
      return {
        ...state,
        partnerCommissionFindLoading: false,
        partnerCommissionFindSuccess: action.payload,
      };
    case PARTNER_COMMISSION_CATEGORY_ERR:
      return {
        ...state,
        partnerCommissionFindLoading: false,
        partnerCommissionFindError: action.payload,
      };
    default:
      return state;
  }
};

// partnerCommissionIdUpdateReducer
export const partnerCommissionIdUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_COMMISSIONID_UPDATE_REQUEST:
      return {
        ...state,
        partnerCommissionIdUpdateLoading: true,
      };
    case PARTNER_COMMISSIONID_UPDATE_SUCCESS:
      return {
        ...state,
        partnerCommissionIdUpdateLoading: false,
        partnerCommissionIdUpdateSuccess: action.payload,
      };
    case PARTNER_COMMISSIONID_UPDATE_ERR:
      return {
        ...state,
        partnerCommissionIdUpdateLoading: false,
        partnerCommissionIdUpdateError: action.payload,
      };
    default:
      return state;
  }
};

// Partner Notification

export const partnerNotificationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_NOTIFICATION_CREATE_REQUEST:
      return {
        ...state,
        partnerNotificationCreateLoading: true,
      };
    case PARTNER_NOTIFICATION_CREATE_SUCCESS:
      return {
        ...state,
        partnerNotificationCreateLoading: false,
        partnerNotificationCreateSuccess: action.payload,
      };
    case PARTNER_NOTIFICATION_CREATE_ERR:
      return {
        ...state,
        partnerNotificationCreateLoading: false,
        partnerNotificationCreateError: action.payload,
      };
    default:
      return state;
  }
};

export const partnerNotificationFindAllReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_NOTIFICATION_FIND_ALL_REQUEST:
      return {
        ...state,
        partnerNotificationFindAllLoading: true,
      };
    case PARTNER_NOTIFICATION_FIND_ALL_SUCCESS:
      return {
        ...state,
        partnerNotificationFindAllLoading: false,
        partnerNotificationFindAllSuccess: action.payload,
      };
    case PARTNER_NOTIFICATION_FIND_ALL_ERR:
      return {
        ...state,
        partnerNotificationFindAllLoading: false,
        partnerNotificationFindAllError: action.payload,
      };
    default:
      return state;
  }
};

export const partnerNotificationFindOneReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_NOTIFICATION_FIND_ONE_REQUEST:
      return {
        ...state,
        partnerNotificationFindOneLoading: true,
      };
    case PARTNER_NOTIFICATION_FIND_ONE_SUCCESS:
      return {
        ...state,
        partnerNotificationFindOneLoading: false,
        partnerNotificationFindOneSuccess: action.payload,
      };
    case PARTNER_NOTIFICATION_FIND_ONE_ERR:
      return {
        ...state,
        partnerNotificationFindOneLoading: false,
        partnerNotificationFindOneError: action.payload,
      };
    default:
      return state;
  }
};

export const partnerNotificationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_DELETE_REQUEST:
      return {
        ...state,
        partnerNotificationDeleteLoading: true,
      };
    case NOTIFICATION_DELETE_SUCCESS:
      return {
        ...state,
        partnerNotificationDeleteLoading: false,
        partnerNotificationDeleteSuccess: action.payload,
      };
    case NOTIFICATION_DELETE_ERR:
      return {
        ...state,
        partnerNotificationDeleteLoading: false,
        partnerNotificationDeleteError: action.payload,
      };
    default:
      return state;
  }
};

export const partnerNotificationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_EDIT_REQUEST:
      return {
        ...state,
        partnerNotificationUpdateLoading: true,
      };
    case NOTIFICATION_EDIT_SUCCESS:
      return {
        ...state,
        partnerNotificationUpdateLoading: false,
        partnerNotificationUpdateSuccess: action.payload,
      };
    case NOTIFICATION_EDIT_ERR:
      return {
        ...state,
        partnerNotificationUpdateLoading: false,
        partnerNotificationUpdateError: action.payload,
      };
    default:
      return state;
  }
};
