import { Box } from "@mui/system";
import React, { useState } from "react";
import { MainContainer } from "../content/content.element";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ApplicationDetails from "./HomePageComponents/ApplicationDetails";
import CommissonCard from "./HomePageComponents/CommissonCard";
import PreveousYearIncom from "./HomePageComponents/PreveousYearIncom";
import ApplicationAndStudentsTable from "./HomePageComponents/ApplicationAndStudentsTable";
import EstimatedEnrollmentTable from "./HomePageComponents/EstimatedEnrollmentTable";
import Cards from "./HomePageComponents/Cards";
import {
  Button,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import LeadCharts from "./HomePageComponents/LeadCharts";
import ApplicationAndEnrollmentChart from "./HomePageComponents/ApplicationAndEnrollmentChart";
import UniversityApplication from "./HomePageComponents/UniversityApplication";
import TaskTable from "./HomePageComponents/TaskTable";
import { findUserAction } from "../../actions/adminAuthAction";
import { useDispatch, useSelector } from "react-redux";
import LeaveCalender from "../leaveCalender";
import NoteTable from "./HomePageComponents/NoteTable";
import { noteFindAction } from "../../actions/dashBoardAction";

function HOME(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isUserExist, setIsExist] = useState();
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [counsilorNote,setCounsilorNote] = useState([])
  const [TeleCallerNote,setTeleCallerNote] = useState([])


  // DashBoard Note find reducer

  let {noteFindSuccess,} = useSelector((state) => state.noteFind);

  useEffect(() => {
    setIsExist(
      localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null
    );
  }, [localStorage]);

  useEffect(() => {
    dispatch(findUserAction());
    dispatch(noteFindAction());
  }, [dispatch, isUserExist]);


  useEffect(()=>{
    if(noteFindSuccess){
      setCounsilorNote(noteFindSuccess?.data?.counsilorNote)
      setTeleCallerNote(noteFindSuccess?.data?.teleCallerNote)
    }
  },[noteFindSuccess])

  console.log(noteFindSuccess,"noteFindSuccessnoteFindSuccessnoteFindSuccess")

  useEffect(() => {
    if (isUserExist?.name == "teacher") {
      navigate("/ielts/teacherdashboard/");
    }
  }, [isUserExist]);

  const card1 = {
    img: "/Users.svg",
    firstTitle: "Applications",
    firstTitleCount: "778",
    secondTitle: "Students",
    secondTitleCount: "425",
  };

  const card2 = {
    img: "/Check.svg",
    firstTitle: "Final Choiced Students",
    firstTitleCount: "33",
    secondTitle: "Deposits Paid",
    secondTitleCount: "33",
  };

  const card3 = {
    img: "/Discount.svg",
    Title: "Expected Commission Income",
    Count: "1,26,52,456",
  };

  function createData(
    subject,
    dueDate,
    status,
    priority,
    related,
    counsellor,
    taskOwner
  ) {
    return {
      subject,
      dueDate,
      status,
      priority,
      related,
      counsellor,
      taskOwner,
    };
  }

  const rows = [
    createData(
      "Follow up Shabeer Muhammed on 15 / 02 / 2023",
      "15 / 02 / 2023",
      "22",
      "Normal",
      "Shabeer Muhammed",
      "Forose Bava",
      "Shibili Poonthiruthi"
    ),
    createData(
      "Follow up Shabeer Muhammed on 15 / 02 / 2023",
      "15 / 02 / 2023",
      "22",
      "Normal",
      "Shabeer Muhammed",
      "Forose Bava",
      "Shibili Poonthiruthi"
    ),
    createData(
      "Follow up Shabeer Muhammed on 15 / 02 / 2023",
      "15 / 02 / 2023",
      "22",
      "Normal",
      "Shabeer Muhammed",
      "Forose Bava",
      "Shibili Poonthiruthi"
    ),
    createData(
      "Follow up Shabeer Muhammed on 15 / 02 / 2023",
      "15 / 02 / 2023",
      "22",
      "Normal",
      "Shabeer Muhammed",
      "Forose Bava",
      "Shibili Poonthiruthi"
    ),
    createData(
      "Follow up Shabeer Muhammed on 15 / 02 / 2023",
      "15 / 02 / 2023",
      "22",
      "Normal",
      "Shabeer Muhammed",
      "Forose Bava",
      "Shibili Poonthiruthi"
    ),
    createData(
      "Follow up Shabeer Muhammed on 15 / 02 / 2023",
      "15 / 02 / 2023",
      "22",
      "Normal",
      "Shabeer Muhammed",
      "Forose Bava",
      "Shibili Poonthiruthi"
    ),
  ];

  return (
    <MainContainer active={props.toggle}>
      <Box
        sx={{
          margin: "auto",
          mt: "40px",
          overflow: "hidden",
          // ml: "56px",
          background: "#FAFAFA",
          padding: "10px",
        }}
      >
        <>
          {/* <Typography sx={{ width: "100%", textAlign: "right" }}>
            Leave calender
          </Typography> */}
          <Button
            // type={submit}
            onClick={() => setCalenderOpen(!calenderOpen)}
            sx={{
              width: "auto",
              textAlign: "right",
              color: "white",
              backgroundColor: "rgba(20, 30, 60, 1)",
              borderRadius: "8px",
              fontWeight: 600,
              fontSize: "12px",
              "&:hover": {
                backgroundColor: "rgba(20, 30, 60, 0.9)",
              },
              alignSelf: "flex-end",
            }}
          >
            Leave calender
          </Button>
          {calenderOpen && (
            <LeaveCalender
              open={calenderOpen}
              handleClose={() => setCalenderOpen(false)}
              // handleSubmit={handleBulkUploadSubmit}
              title="Bulk Upload leads"
              downloadurl="https://gsl-dev2.s3.ap-south-1.amazonaws.com/bulkUpload/bulkUpload176"
            />
          )}

          <Box
            sx={{
              borderRadius: "4px ",
              backgroundColor: "#fff",
              padding: "32px 32px 24px",
              display: "flex",
              alignItems: "center",
              gap: "40px",
              width: "fit-content",
              // justifyContent: "space-between",
              flexWrap: "wrap",
              transition: "2s ease",
            }}
          >
            <ApplicationDetails />
            {/* <Box
              sx={{
                display: "flex",
                gap: "24px",
                flexWrap: "wrap",
              }}
            > */}
            <Cards Data={card1} />
            <Cards Data={card2} />
            <CommissonCard Data={card3} />
            {/* </Box> */}
          </Box>

          <Box
            sx={{
              mt: "44px",
              mb: "24px",
            }}
          >
            <ApplicationAndStudentsTable />
          </Box>

          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  lg: "1fr 1fr",
                },
                gap: "20px",
                mb: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <LeadCharts />
                <ApplicationAndEnrollmentChart />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "5px",
                    padding: "18px 13px 10px 25px",
                    background: "#fff",
                    height: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#05050FBF",
                        fontSize: "16px",
                      }}
                    >
                      Top Lead Sources
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={10}
                      sx={{
                        borderRadius: "30px",
                        "& .MuiOutlinedInput-input": {
                          p: "5px",
                          pl: "15px",
                          pr: "45px !important",
                        },
                      }}
                    >
                      <MenuItem value={10}>Intake</MenuItem>
                    </Select>
                  </Box>
                  <List>
                    {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(
                      (item) => (
                        <ListItem sx={{ paddingX: "0" }}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <Typography
                              sx={{ color: "#05050F", fontSize: "16px" }}
                            >
                              1.Facebook Lead
                            </Typography>
                            <Typography
                              sx={{ color: "#05050F", fontSize: "16px" }}
                            >
                              1232
                            </Typography>
                          </Box>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>

                <Box
                  sx={{
                    borderRadius: "5px",
                    padding: "18px 13px 10px 25px",
                    background: "#fff",
                    height: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#05050FBF",
                        fontSize: "16px",
                      }}
                    >
                      Top Lead Sources
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={10}
                      sx={{
                        borderRadius: "30px",
                        "& .MuiOutlinedInput-input": {
                          p: "5px",
                          pl: "15px",
                          pr: "45px !important",
                        },
                      }}
                    >
                      <MenuItem value={10}>Intake</MenuItem>
                    </Select>
                  </Box>
                  <List>
                    {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(
                      (item) => (
                        <ListItem sx={{ paddingX: "0" }}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <Typography
                              sx={{ color: "#05050F", fontSize: "16px" }}
                            >
                              1.Facebook Lead
                            </Typography>
                            <Typography
                              sx={{ color: "#05050F", fontSize: "16px" }}
                            >
                              1232
                            </Typography>
                          </Box>
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "32px" }}>
            {/* <>
                <Box
                  sx={{
                    maxWidth: "834px",
                    backgroundColor: "#FFF",
                    borderRadius: "4px",
                    padding: "20px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "rgba(5, 5, 15, 0.75)",
                      }}
                    >
                      Leads
                    </Typography>
                  </Box>
                  <Box sx={{ pl: "20px" }}>
                    <Charts />
                  </Box>
                </Box>
                <Box>
                  <LeadSource />
                </Box>
                <Box>
                  <LeadOwners />
                </Box>
              </> */}
            <EstimatedEnrollmentTable />
          </Box>

          <Box>
            <Typography
              sx={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "700",
                // fontFamily: "Open-Sans",
              }}
            >
              Previous Year Income
            </Typography>
            <PreveousYearIncom />
          </Box>

          <Box
            sx={{
              mt: "30px",
              backgroundColor: "#fff",
            }}
          >
            <Box>
              <Typography
                sx={{
                  padding: "20px",
                  pb: "20px",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "700",
                  // fontFamily: "Open-Sans",
                }}
              >
                University Wise Application
              </Typography>
            </Box>

            <UniversityApplication />
          </Box>

          <Box sx={{ mb: "30px" }}>

          <Box
              sx={{
                mt: "30px",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "700",
                  // fontFamily: "Open-Sans",
                }}
              >
                TeleCaller Note
              </Typography>

              <NoteTable rows={TeleCallerNote} component={"teleCaller"} />
            </Box>


            <Box
              sx={{
                mt: "30px",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "700",
                  // fontFamily: "Open-Sans",
                }}
              >
                Counsilor Note
              </Typography>

              <NoteTable rows={counsilorNote} component={"counsilor"} />
            </Box>



            <Box
              sx={{
                mt: "30px",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "700",
                  // fontFamily: "Open-Sans",
                }}
              >
                Open Task
              </Typography>

              <TaskTable rows={rows} />
            </Box>



            <Box
              sx={{
                mt: "30px",
              }}
            >
              <Typography
                sx={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "10px 10px 0 0",
                  width: "100%",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "700",
                  // fontFamily: "Open-Sans",
                }}
              >
                Overdue Task
              </Typography>

              <TaskTable rows={rows} />
            </Box>
          </Box>
        </>
      </Box>
    </MainContainer>
  );
}

export default HOME;
