import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Menu,
  Pagination,
  Button,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import {
  TalkToExpertChangeStatusAction,
  scholarshipApplicationConvertToLeadAction,
} from "../../../actions/LandingPageAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function ScholarshipTable({
  data,
  columns,
  component,
  selectBoxIdPassHandler,
  pageNumber,
  subMenu,
}) {
  console.log(data, "hfjksdfbjdskfjdsbfds");
  useEffect(() => {
    if (pageNumber > 10) {
      setRowsPerPage(pageNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [pageNumber]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isConvertleadPrevilage } = usePrivilegeCheck();

  const [status, setStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // Sort icon

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // Handle status change
  const handleStatusChange = (status, id) => {
    console.log(status, id);
    dispatch(TalkToExpertChangeStatusAction(status, id));
    setStatus(status);
  };

  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    //  setIdsFunction(updatedSelectedRows);
  };

  //  Table actions

  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);
  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };
  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [openDailogue, setOpenDailogue] = useState(false);

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      {/* {openDailogue === true && (
        <Dialogue
          openTrue={openDailogue}
          handleClose={() => setOpenDailogue(false)}
          headerName="Zoom Configuration Details"
          Dailogcomponent="ZoomConfiguration"
          width="1100px"
          maxWidth="100%"
          height={"auto"}
        />
      )} */}
      <Box
        sx={{
          overflow: "hidden",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingRight: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Showing{" "}
          <Typography
            component={"span"}
            sx={{
              backgroundColor: "#fff",
              padding: "5px",
              paddingLeft: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              // lineHeight: "24px",
              fontWeight: "700",
            }}
          >
            {
              sortedData?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )?.length
            }{" "}
            Result
          </Typography>
        </Typography>

        <Box
          sx={{
            overflowX: "scroll",
          }}
        >
          <Table
            className="masterTable"
            sx={{
              minWidth: "800px",
            }}
          >
            <TableHead
              sx={{
                background: "#F9F9FB",
              }}
            >
              <TableRow className="msterTableCell">
                <TableCell>
                  <Checkbox
                    checked={selectedRows?.length === sortedData?.length}
                    onChange={handleSelectAllRows}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                {columns?.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  return (
                    <TableCell key={column.field}>
                      <TableSortLabel
                        className="headerText"
                        active={column === sortBy}
                        direction={sortOrder}
                        onClick={() => requestSort(column)}
                        IconComponent={
                          column.field !== "action" && CustomSortIcon
                        }
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                ?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item, indx) => (
                  <TableRow className="tableRow" key={indx}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(item?.id)}
                        onChange={() => handleSelectRow(item?.id)}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    <TableCell>{indx + 1}</TableCell>
                    <TableCell>{item?.fullName}</TableCell>
                    <TableCell>
                      {item?.countryCode + " " + item?.phoneNumber}
                    </TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell sx={{ maxWidth: "400px" }}>
                      {item?.landing_TopNavigation?.topNavContent}
                    </TableCell>
                    <TableCell sx={{ maxWidth: "400px" }}>
                      {item?.reasonToApply}
                    </TableCell>
                    <TableCell sx={{ maxWidth: "400px" }}>
                      {item?.status == "Convert As Lead" ? (
                        <Button
                          onClick={() => {
                            if (isConvertleadPrevilage(subMenu)) {
                              dispatch(
                                scholarshipApplicationConvertToLeadAction(
                                  item?.fullName,
                                  item?.email,
                                  item?.phoneNumber,
                                  item?.countryCode
                                )
                              );
                              dispatch(successTrueMsgAction(true));
                            } else {
                              setOpenAlert(true);
                            }
                          }}
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(270deg, #0098BE 19.44%, #0079BE 81.9%)",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            py: "0px",
                            px: "10px",
                          }}
                        >
                          {item?.status}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{
                            background:
                              "linear-gradient(270deg, #60d399 19.44%, #22bd7e 81.9%)",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            py: "0px",
                            px: "10px",
                          }}
                        >
                          {item?.status}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter />
          </Table>
        </Box>

        <Pagination
          sx={{
            mt: "28px",
            "& .Mui-selected": {
              backgroundColor: "#f81b82 !important",
              color: "#fff",
            },
            "& .MuiPagination-ul": {
              justifyContent: "end",
            },
          }}
          count={Math.ceil(data?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default ScholarshipTable;
