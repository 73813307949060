import axios from "axios";
import {
  BRANCH_MASTER_ADD_ERR,
  BRANCH_MASTER_ADD_REQUEST,
  BRANCH_MASTER_ADD_SUCCESS,
  BRANCH_MASTER_DELETE_ERR,
  BRANCH_MASTER_DELETE_REQUEST,
  BRANCH_MASTER_DELETE_SUCCESS,
  BRANCH_MASTER_EDIT_ERR,
  BRANCH_MASTER_EDIT_REQUEST,
  BRANCH_MASTER_EDIT_SUCCESS,
  BRANCH_MASTER_FIND_ERR,
  BRANCH_MASTER_FIND_REQUEST,
  BRANCH_MASTER_FIND_SUCCESS,
} from "../constants/branchMasterConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

// branchMasterAddAction
export const branchMasterAddAction =
  (branchName, location, countryCode, phoneNumber, email) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BRANCH_MASTER_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/branch/addBranchMaster",
        { branchName, location, countryCode, phoneNumber, email },
        config
      );

      dispatch({ type: BRANCH_MASTER_ADD_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: BRANCH_MASTER_ADD_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true});
    }
  };

// branchMasterEditAction
export const branchMasterEditAction =
  (id, branchName, location, countryCode, phoneNumber, email) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BRANCH_MASTER_EDIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/branch/editBranchMaster?id=${id}`,
        { branchName, location, countryCode, phoneNumber, email },
        config
      );

      dispatch({ type: BRANCH_MASTER_EDIT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: BRANCH_MASTER_EDIT_ERR, payload: error });
    }
  };

// branchMasterAddAction
export const findBranchMasterAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BRANCH_MASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/branch/findBranchMaster",
      config
    );

    dispatch({ type: BRANCH_MASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BRANCH_MASTER_FIND_ERR, payload: error });
  }
};

// branchMasterDeleteAction
export const branchMasterDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BRANCH_MASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/branch/deleteBranchMaster?id=${id}`,
      config
    );
    console.log(data)

    dispatch({ type: BRANCH_MASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BRANCH_MASTER_DELETE_ERR, payload: error });
  }
};
