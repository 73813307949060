export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERR = "USER_LOGIN_ERR";

export const USER_FIND_REQUEST = "USER_FIND_REQUEST";
export const USER_FIND_SUCCESS = "USER_FIND_SUCCESS";
export const USER_FIND_ERR = "USER_FIND_ERR";

export const USER_OTP_REQUEST = "USER_OTP_REQUEST";
export const USER_OTP_SUCCESS = "USER_OTP_SUCCESS";
export const USER_OTP_ERR = "USER_OTP_ERR";

export const USER_CONFIRM_OTP_REQUEST = "USER_CONFIRM_OTP_REQUEST";
export const USER_CONFIRM_OTP_SUCCESS = "USER_CONFIRM_OTP_SUCCESS";
export const USER_CONFIRM_OTP_ERR = "USER_CONFIRM_OTP_ERR";

export const USER_SET_NEW_PASSWORD_REQUEST = "USER_SET_NEW_PASSWORD_REQUEST";
export const USER_SET_NEW_PASSWORD_SUCCESS = "USER_SET_NEW_PASSWORD_SUCCESS";
export const USER_SET_NEW_PASSWORD_ERR = "USER_SET_NEW_PASSWORD_ERR";
