import React, { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Paper,
} from "@mui/material";

import { makeStyles } from "@material-ui/core";

import { SlOptionsVertical } from "react-icons/sl";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Modal,
  TableContainer,
  Typography,
} from "@mui/material";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
// import { roleDeleteAction } from "../../actions/roleMangmentAction";
import { useDispatch } from "react-redux";
import { deleteCourseAction } from "../../../actions/universityActions/courseActions/universityCourseAction";
import UniversityCourseFilter from "./UniversityCourseFilter";
import { TbEdit } from "react-icons/tb";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

// import "./Table.css";

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    border: "1px solid #ececec",
    padding: 0, // Set padding to 0 to remove excessive padding
    "& th, td": {
      padding: "4px", // Add desired padding to individual cells
    },
  },
  tableCell: {
    backgroundColor: "#fafafa",
    color: "#9499A4",
    fontFamily: "'Akatab', sans-serif",
    fontSize: "-16px",
    fontWeight: 500,
    height: "20px",
  },

  tableEachCell: {
    height: "18px",
  },
  headerText: {
    fontSize: "13px",
  },
  tableRow: {
    "& > *": {
      border: "none",
    },
    height: "8px",
  },
});

// show role details popup
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 600,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

export const CourseViewTable = ({
  columns,
  data,
  allRoleFindSuccess,
  component,
  showUrl,
  handleResetData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  console.log(data,"datadatadatadata")

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [roleData, setRoleData] = useState(allRoleFindSuccess);
  const [popupData, setPopupData] = useState({});
  const [open, setOpen] = useState(false);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // navigate to Edit page
  const rollEditHandler = (tableId) => {
    navigate(`/Administration/editRole/${tableId}`);
  };

  const RoledeleteHandler = (role) => {
    setActionButtonShow(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch(roleDeleteAction(role));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const handleOpen = (id) => {
    if (component === "employee") {
      navigate(`${showUrl}/${id}`);
    } else {
      let arrData;
      if (roleData.some((obj) => obj.id === id)) {
        arrData = roleData.find((obj) => obj.id === id);
      }

      console.log(roleData, "arrDataarrDataarrDataarrData");
      setPopupData(arrData);
      setOpen(true);
    }
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  const handleClose = () => setOpen(false);

  return (
    <>
      {sortedData?.length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <UniversityCourseFilter handleResetData={handleResetData} />
          </Grid>
          <Grid item xs={9}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">
                    <Checkbox
                      // checked={selectedRows.length === sortedData.length}
                      // onChange={handleSelectAllRows}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  {columns?.map((column) => {
                    if (column.hidden) {
                      return null;
                    }
                    return (
                      <TableCell key={column.field} className="tableCell">
                        <TableSortLabel
                          className="headerText"
                          active={column === sortBy}
                          direction={sortOrder}
                          onClick={() => requestSort(column)}
                        >
                          {column.title}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData?.map((item, indx) => (
                  <TableRow className={classes.tableRow} key={indx}>
                    <TableCell>
                      <Checkbox
                        // checked={selectedRows.includes(item.id)}
                        // onChange={() => handleSelectRow(item.id)}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    <TableCell>{item.sl}</TableCell>
                    <TableCell>{item.name}</TableCell>

                    <TableCell>{item.CourseDuration}</TableCell>

                    <TableCell>{item.totalFee}</TableCell>

                    <TableCell>
                      <SlOptionsVertical
                        id="demo-positioned-button"
                        aria-controls={
                          openActionButton ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openActionButton ? "true" : undefined}
                        onClick={(e) => {
                          handleClickAction(e, item.id);
                        }}
                        variant="outlined"
                        style={{ marginLeft: "10px", color: "#F80B7A" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter />
            </Table>

            {/* TABLE ACTION MENU START*/}

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={actionButtonShow}
              open={openActionButton}
              onClose={handleCloseAction}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                borderColor: "black",
                borderRadius: "24px",
                marginLeft: "-81px",
              }}
            >
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <MenuItem>
                  <AiOutlineEye
                    style={{ fontSize: "lg", width: "50px", height: "23px" }}
                    onClick={() =>
                      navigate(
                        `/university/courses/courseDetails/${tableId}/${id}`
                      )
                    }
                  />
                </MenuItem>

                <MenuItem>
                  <TbEdit
                    style={{ fontSize: "lg", width: "50px", height: "23px" }}
                    onClick={() =>
                      navigate(
                        `/university/courses/editCourse/${tableId}/${id}`
                      )
                    }
                    className="icons"
                  />
                </MenuItem>

                <MenuItem>
                  <RiDeleteBin6Line
                    style={{ fontSize: "lg", width: "50px", height: "23px" }}
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          dispatch(deleteCourseAction(tableId));
                        }
                      });
                    }}
                  />
                </MenuItem>
              </Box>
            </Menu>
            {/* {/*TABLE ACTION MENU END */}

            {/*ROLE DETAILS POPUP START  */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Role Name :{popupData.roleName}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  variant="h6"
                  component="h2"
                >
                  Description : {popupData.roleDescription}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{ height: "500px", overflow: "scroll" }}
                >
                  <Grid item lg={12} xs={12}>
                    <Paper>
                      <TableContainer>
                        <Table>
                          <TableHead sx={{ backgroundColor: "#F9F9FB" }}>
                            <TableRow>
                              <TableCell>Category Name</TableCell>
                              <TableCell>update</TableCell>
                              <TableCell>view</TableCell>
                              <TableCell>Delete</TableCell>
                              <TableCell>All</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* Add table rows with data */}
                            <TableRow>
                              <TableCell>Lead Managment</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isLeadUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isLeadView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isLeadDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isLeadAll}
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Administration</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isadministrationUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isadministrationView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isadministrationDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isadministrationAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Application</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isApplicationUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isApplicationView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isApplicationDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isApplicationAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>University</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isUniversityUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isUniversityView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isUniversityDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isUniversityAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Course</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isCoursUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isCoursView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isCoursDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isCoursAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>ticket</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isTicketUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isTicketView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isTicketDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isTicketAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>enquiry</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isEnquiryUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isEnquiryView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isEnquiryDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isEnquiryAll}
                                />
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Website</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isWebsitUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isWebsitView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isWebsitDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isWebsitAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>HR Managment</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isHRmanagmentUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isHRmanagmentView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isHRmanagmentDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isHRmanagmentAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>IELTS</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isIeltsUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isIeltsView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isIeltsDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isIeltsAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Accounts</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isAccountsUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isAccountsView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isAccountsDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isAccountsAll}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Settings</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isSettingsUpdate}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isSettingsView}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isSettingsDelete}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{
                                    color: "#f81b82",
                                    "&.Mui-checked": {
                                      color: "#f81b82",
                                    },
                                  }}
                                  checked={popupData.isSettingsAll}
                                />
                              </TableCell>
                            </TableRow>
                            {/* Add more rows as needed */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/*ROLE DETAILS POPUP END*/}
          </Grid>
        </Grid>
      ) : (
        <TableNoItemComponent />
      )}
    </>
  );
};
