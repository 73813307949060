import axios from "axios";

import {
  UNIVERSITYANDCOURSE_BULKUPLOAD_ERR,
  UNIVERSITYANDCOURSE_BULKUPLOAD_REQUEST,
  UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS,
  UNIVERSITY_DELETE_ERR,
  UNIVERSITY_DELETE_REQUEST,
  UNIVERSITY_DELETE_SUCCESS,
  UNIVERSITY_EDIT_ERR,
  UNIVERSITY_EDIT_REQUEST,
  UNIVERSITY_EDIT_SUCCESS,
  UNIVERSITY_FIND_ERR,
  UNIVERSITY_FIND_REQUEST,
  UNIVERSITY_FIND_SUCCESS,
  UNIVERSITY_IMAGE_DELETE_ERR,
  UNIVERSITY_IMAGE_DELETE_REQUEST,
  UNIVERSITY_IMAGE_DELETE_SUCCESS,
  UNIVERSITY_IMAGE_UPLOAD_ERR,
  UNIVERSITY_IMAGE_UPLOAD_REQUEST,
  UNIVERSITY_IMAGE_UPLOAD_SUCCESS,
  UNIVERSITY_NULL_IMAGE_ERR,
  UNIVERSITY_NULL_IMAGE_REQUEST,
  UNIVERSITY_NULL_IMAGE_SUCCESS,
  UNIVERSITY_SINGLE_VIEW_ERR,
  UNIVERSITY_SINGLE_VIEW_REQUEST,
  UNIVERSITY_SINGLE_VIEW_SUCCESS,
  UNIVERSITY_SUBMIT_ERR,
  UNIVERSITY_SUBMIT_REQUEST,
  UNIVERSITY_SUBMIT_SUCCESS,
} from "../../constants/university/universityManagentConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../constants/roleManagmentConstant";

// uploadUniversityImageAction
export const uploadUniversityImageAction = (imageUrl) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_IMAGE_UPLOAD_REQUEST });

    let { data } = await axios.post(
      "/api/admin/university/uploadUniversityImage",
      { imageUrl },
      config
    );

    dispatch({ type: UNIVERSITY_IMAGE_UPLOAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_IMAGE_UPLOAD_ERR,
      payload: error.response.data,
    });
  }
};

// nullUniversityImageShowAction
export const nullUniversityImageShowAction = () => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_NULL_IMAGE_REQUEST });

    let { data } = await axios.get(
      "/api/admin/university/nullUniversityImageShow",
      config
    );

    dispatch({ type: UNIVERSITY_NULL_IMAGE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_NULL_IMAGE_ERR,
      payload: error.response.data,
    });
  }
};

// deleteUniversityImageAction
export const deleteUniversityImageAction = (fileId) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_IMAGE_DELETE_REQUEST });

    let { data } = await axios.delete(
      `/api/admin/university/nullUniversityImageDelete?id=${fileId}`,
      config
    );

    dispatch({ type: UNIVERSITY_IMAGE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_IMAGE_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

// universityAddSumitAction
export const universityAddSubmitAction = ({
  universityName,
  country,
  state,
  city,
  location,
  ranking,
  rank,
  websiteLink,
  affiliation,
  facilities,
  types,
  establishedYear,
  universityAbout,
  filesVideo,
  internationalFee,
  eligibilityQualification,
  eligibilityTest,
  eligibilityWorkExperience,
  eligibilityRemark,
  documentRequired,
  brochurFile,
  logoFile,
  isTopUniversity,
  applicationFee,
  universityCoverImage,
  universityHandler
}) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_SUBMIT_REQUEST });

    let { data } = await axios.post(
      "/api/admin/university/addUniversity",
      {
        universityName,
        country,
        state,
        city,
        location,
        ranking,
        rank,
        websiteLink,
        affiliation,
        facilities,
        types,
        establishedYear,
        universityAbout,
        filesVideo,
        internationalFee,
        eligibilityQualification,
        eligibilityTest,
        eligibilityWorkExperience,
        eligibilityRemark,
        documentRequired,
        brochurFile,
        logoFile,
        isTopUniversity,
        applicationFee,
        universityCoverImage,
        universityHandler
      },
      config
    );

    dispatch({ type: UNIVERSITY_SUBMIT_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({ type: UNIVERSITY_SUBMIT_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findUniversity
export const findUniversityAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_FIND_REQUEST });

    let { data } = await axios.get(
      "/api/admin/university/findUniversity",
      config
    );

    dispatch({ type: UNIVERSITY_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UNIVERSITY_FIND_ERR, payload: error.response.data });
  }
};

// universityViewAction

export const universityViewAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_SINGLE_VIEW_REQUEST });

    let { data } = await axios.get(
      `/api/admin/university/viewUniversity?id=${id}`,
      config
    );

    dispatch({ type: UNIVERSITY_SINGLE_VIEW_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_SINGLE_VIEW_ERR,
      payload: error.response.data,
    });
  }
};

// universityEditAction

export const universityEditAction = ({
  universityName,
  country,
  state,
  city,
  location,
  ranking,
  rank,
  websiteLink,
  affiliation,
  facilities,
  types,
  establishedYear,
  universityAbout,
  filesVideo,
  internationalFee,
  eligibilityQualification,
  eligibilityTest,
  eligibilityWorkExperience,
  eligibilityRemark,
  documentRequired,
  brochurFile,
  logoFile,
  isTopUniversity,
  applicationFee,
  universityCoverImage,
  universityId,
  universityHandler
}) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_EDIT_REQUEST });

    let { data } = await axios.post(
      "/api/admin/university/editUniversity",
      {
        universityName,
        country,
        state,
        city,
        location,
        ranking,
        rank,
        websiteLink,
        affiliation,
        facilities,
        types,
        establishedYear,
        universityAbout,
        filesVideo,
        internationalFee,
        eligibilityQualification,
        eligibilityTest,
        eligibilityWorkExperience,
        eligibilityRemark,
        documentRequired,
        brochurFile,
        logoFile,
        isTopUniversity,
        applicationFee,
        universityCoverImage,
        universityId,
        universityHandler
      },
      config
    );

    dispatch({ type: UNIVERSITY_EDIT_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    dispatch({ type: UNIVERSITY_EDIT_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// universityDeleteAction

export const universityDeleteAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_DELETE_REQUEST });

    let { data } = await axios.delete(
      `/api/admin/university/deleteUniversity?universityID=${id}`,
      config
    );

    dispatch({ type: UNIVERSITY_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

// UniversityBulkUpload action :::::::::::::::::::::::

export const universityAndCourseBulkUploadAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch({ type: UNIVERSITYANDCOURSE_BULKUPLOAD_REQUEST });

    let { data } = await axios.post(
      "/api/admin/university/bulkUploadUniversity",
      formData,
      config
    );

    dispatch({ type: UNIVERSITYANDCOURSE_BULKUPLOAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITYANDCOURSE_BULKUPLOAD_ERR,
      payload: error.response.data,
    });
  }
};
