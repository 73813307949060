import React, { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Chip,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, MenuItem } from "@mui/material";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

// import "./EmployeeIncentiveTable.css";

import { MdNotInterested } from "react-icons/md";

import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck.js";
import { partnerCommissionDeleteAction } from "../../../actions/IncentiveCategoryActions.js";
import { TypographyText } from "../../customComponent/Typography.js";
import PremissionDialog from "../../customComponent/premissionDialog.js";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent.js";

export const EmployeeIncentiveTable = ({
  columns,
  data,
  incentiveData,
  component,
  showUrl,
  subMenu,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);
  // custom Hooks
  const { isDeletePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [incentiveMapData, setIncentiveMapData] = useState(incentiveData);
  const [slab, setSlab] = useState("Incentive Category");

  // const [popupData, setPopupData] = useState({});
  const [open, setOpen] = useState(false);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setTableId(id);
  };

  const handleClose = (event, id) => {
    setOpen(false);
  };

  const handleClick = (value) => {
    setSlab(value);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="incentiveCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="incentiveCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // check handler

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    // setIdsFunction(updatedSelectedRows);
  };

  return (
    <>
      {sortedData.length > 0 ? (
        <Table className="incentiveTable">
          <TableHead>
            <TableRow className="incentiveTableCell">
              <TableCell>
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field} className="incentiveTableCell">
                    <TableSortLabel
                      className="incentiveHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="incentiveTable" key={indx}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(item.id)}
                    onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                <TableCell>{item.sl}</TableCell>
                {item.column1 && <TableCell>{item.column1}</TableCell>}

                {item.column2 && <TableCell>{`${item.column2}`}</TableCell>}

                {item.column3 && <TableCell>{item.column3}</TableCell>}

                {item.column4 && <TableCell>{item.column4}</TableCell>}
                <TableCell>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      openActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openActionButton ? "true" : undefined}
                    onClick={(e) => {
                      handleClickAction(e, item.id);
                    }}
                    variant="outlined"
                    style={{ marginLeft: "10px", color: "#F80B7A" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}

      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <AiOutlineEye
              onClick={() => handleOpen(tableId)}
              className="icons"
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
            />
          </MenuItem>

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => {
                if (isDeletePrevilage(subMenu)) {
                  setActionButtonShow(false);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      dispatch(partnerCommissionDeleteAction(tableId));
                      Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    }
                  });
                } else {
                  setOpenAlert(true);
                }
              }}
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              className="icons"
            />
          </MenuItem>
        </Box>
      </Menu>
      {/* {/*TABLE ACTION MENU END */}

      {/* {/*DIALOGUE MENU */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            bottom: "3vh",
            width: "100%", // Increase width here
            padding: "50px",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            marginTop: "-32px",
          }}
        >
          <TypographyText title={"Employee Incentive Details"} />
        </DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            textAlign: "center",
            marginLeft: "-20px",
          }}
        >
          <DialogContentText mt={1} id="alert-dialog-description">
            <div style={{ display: "flex" }}>
              <Chip
                style={{
                  backgroundColor: slab === "Incentive Category" && "#FE0B7A",
                  color: slab === "Incentive Category" && "white",
                }}
                label="Incentive Category"
                component="a"
                clickable
                sx={{ m: 1, width: "auto", fontSize: "8px !important" }} // Reduced font size to 8px
                onClick={() => handleClick("Incentive Category")}
              />

              <Chip
                style={{
                  backgroundColor: slab === "Direct University" && "#FE0B7A",
                  color: slab === "Direct University" && "white",
                }}
                label="Direct University"
                component="a"
                clickable
                sx={{ m: 1 }}
                onClick={() => handleClick("Direct University")}
              />

              <Chip
                style={{
                  backgroundColor: slab === "Other Study Abroad" && "#FE0B7A",
                  color: slab === "Other Study Abroad" && "white",
                }}
                label="Other Study Abroad"
                component="a"
                clickable
                sx={{ m: 1 }}
                onClick={() => handleClick("Other Study Abroad")}
              />
              <Chip
                style={{
                  backgroundColor: slab === "Bonus" && "#FE0B7A",
                  color: slab === "Bonus" && "white",
                }}
                label="Bonus"
                component="a"
                clickable
                sx={{ m: 1 }}
                onClick={() => handleClick("Bonus")}
              />
            </div>
          </DialogContentText>

          {slab === "Incentive Category" ? (
            <Table
              className="incentiveTable"
              style={{ margin: "10px", width: "100%" }}
            >
              <TableHead>
                <TableRow className="incentiveTableCell">
                  <TableCell className="incentiveTableCell">Sl No</TableCell>
                  <TableCell className="incentiveTableCell">type</TableCell>
                  <TableCell className="incentiveTableCell">rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incentiveData &&
                  incentiveData?.map((item) =>
                    item.employee_incentive_category_wises.map(
                      (category, indx) => (
                        <TableRow className="incentiveTable" key={indx}>
                          <TableCell>{indx + 1}</TableCell>
                          <TableCell>{category.category}</TableCell>
                          <TableCell>{category.payment}</TableCell>
                        </TableRow>
                      )
                    )
                  )}
              </TableBody>
              <TableFooter />
            </Table>
          ) : slab === "Direct University" ? (
            <Table className="incentiveTable" m={1} style={{ margin: "10px" }}>
              <TableHead>
                <TableRow className="incentiveTableCell">
                  <TableCell className="incentiveTableCell">Sl No</TableCell>
                  <TableCell className="incentiveTableCell">
                    applications
                  </TableCell>
                  <TableCell className="incentiveTableCell">rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incentiveData &&
                  incentiveData?.map((item) =>
                    item.employee_incentive_direct_universities.map(
                      (category, indx) => (
                        <TableRow className="incentiveTable" key={indx}>
                          <TableCell>{indx + 1}</TableCell>
                          <TableCell>{`${category.applicationFrom}-${category.applicationTo}`}</TableCell>
                          <TableCell>{category.incentiveAmmount}</TableCell>
                        </TableRow>
                      )
                    )
                  )}
              </TableBody>
              <TableFooter />
            </Table>
          ) : slab === "Other Study Abroad" ? (
            <Table className="incentiveTable" m={1} style={{ margin: "10px" }}>
              <TableHead>
                <TableRow className="incentiveTableCell">
                  <TableCell className="incentiveTableCell">Sl No</TableCell>
                  <TableCell className="incentiveTableCell">
                    applications
                  </TableCell>
                  <TableCell className="incentiveTableCell">rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incentiveData &&
                  incentiveData?.map((item) =>
                    item.employee_other_study_abroad_incentives.map(
                      (category, indx) => (
                        <TableRow className="incentiveTable" key={indx}>
                          <TableCell>{indx + 1}</TableCell>
                          <TableCell>{`${category.applicationFrom}-${category.applicationTo}`}</TableCell>
                          <TableCell>{category.studyAbroadAmmount}</TableCell>
                        </TableRow>
                      )
                    )
                  )}
              </TableBody>
              <TableFooter />
            </Table>
          ) : slab === "Bonus" ? (
            <Table className="incentiveTable" m={1} style={{ margin: "10px" }}>
              <TableHead>
                <TableRow className="incentiveTableCell">
                  <TableCell className="incentiveTableCell">Sl No</TableCell>
                  <TableCell className="incentiveTableCell">
                    applications
                  </TableCell>
                  <TableCell className="incentiveTableCell">rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incentiveData &&
                  incentiveData?.map((item) =>
                    item.employee_bonus_incentives.map((category, indx) => (
                      <TableRow className="incentiveTable" key={indx}>
                        <TableCell>{indx + 1}</TableCell>
                        <TableCell>{`${category.applicationFrom}-${category.applicationTo}`}</TableCell>
                        <TableCell>{category.bonusAmmount}</TableCell>
                      </TableRow>
                    ))
                  )}
              </TableBody>
              <TableFooter />
            </Table>
          ) : null}
        </DialogContent>
      </Dialog>
      {/* {/*DIALOGUE MENU */}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
