import {
  ADD_ALL_EMPLOYEE_FIND_ERR,
  ADD_ALL_EMPLOYEE_FIND_REQUEST,
  ADD_ALL_EMPLOYEE_FIND_SUCCESS,
  BRANCH_MANAGER_FETCH_ERR,
  BRANCH_MANAGER_FETCH_REQUEST,
  BRANCH_MANAGER_FETCH_SUCCESS,
  EMPLOYEE_ADD_ERR,
  EMPLOYEE_ADD_REQUEST,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_BULKUPLOAD_ERR,
  EMPLOYEE_BULKUPLOAD_REQUEST,
  EMPLOYEE_BULKUPLOAD_SUCCESS,
  EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_ERR,
  EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_REQUEST,
  EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_SUCCESS,
  EMPLOYEE_CHANGE_PASSWORD_ERR,
  EMPLOYEE_CHANGE_PASSWORD_REQUEST,
  EMPLOYEE_CHANGE_PASSWORD_SUCCESS,
  EMPLOYEE_DELETE_ERR,
  EMPLOYEE_DELETE_LEAVE_APPLICATION_ERR,
  EMPLOYEE_DELETE_LEAVE_APPLICATION_REQUEST,
  EMPLOYEE_DELETE_LEAVE_APPLICATION_SUCCESS,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DETAILS_EDIT_ERR,
  EMPLOYEE_DETAILS_EDIT_REQUEST,
  EMPLOYEE_DETAILS_EDIT_SUCCESS,
  EMPLOYEE_DETAILS_SHOW_ERR,
  EMPLOYEE_DETAILS_SHOW_REQUEST,
  EMPLOYEE_DETAILS_SHOW_SUCCESS,
  EMPLOYEE_LEAVE_FIND_ERR,
  EMPLOYEE_LEAVE_FIND_REQUEST,
  EMPLOYEE_LEAVE_FIND_SUCCESS,
  EMPLOYEE_PRIVILEGE_CHECK_ERR,
  EMPLOYEE_PRIVILEGE_CHECK_REQUEST,
  EMPLOYEE_PRIVILEGE_CHECK_SUCCESS,
  EMPLOYEE_SALARY_SLIP_FIND_ERR,
  EMPLOYEE_SALARY_SLIP_FIND_REQUEST,
  EMPLOYEE_SALARY_SLIP_FIND_SUCCESS,
  EMPLOYEE_STATUS_UPDATE_ERR,
  EMPLOYEE_STATUS_UPDATE_REQUEST,
  EMPLOYEE_STATUS_UPDATE_SUCCESS,
  MATCHED_ROLE_EMPLOYEE_FETCH_ERR,
  MATCHED_ROLE_EMPLOYEE_FETCH_REQUEST,
  MATCHED_ROLE_EMPLOYEE_FETCH_SUCCESS,
  NOTIFICATION_LIST_FIND_ERR,
  NOTIFICATION_LIST_FIND_REQUEST,
  NOTIFICATION_LIST_FIND_SUCCESS,
} from "../constants/employeeManagmentConstant";

// findAllEmployeeReducer
export const findAllEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ALL_EMPLOYEE_FIND_REQUEST:
      return {
        ...state,
        findEmployeeLoading: true,
      };
    case ADD_ALL_EMPLOYEE_FIND_SUCCESS:
      return {
        ...state,
        findEmployeeLoading: false,
        findEmployeeSuccess: action.payload,
      };
    case ADD_ALL_EMPLOYEE_FIND_ERR:
      return {
        ...state,
        findEmployeeLoading: false,
        findEmployeeErr: action.payload,
      };
    default:
      return state;
  }
};

// Employee status update Reducer
export const updateEmployeeStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        employeeStatusUpdateLoading: true,
      };
    case EMPLOYEE_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        employeeStatusUpdateLoading: false,
        employeeStatusUpdateSuccess: action.payload,
      };
    case EMPLOYEE_STATUS_UPDATE_ERR:
      return {
        ...state,
        employeeStatusUpdateLoading: false,
        employeeStatusUpdateErr: action.payload,
      };
    default:
      return state;
  }
};

// addEmployeeReducer
export const addEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_ADD_REQUEST:
      return {
        ...state,
        addEmployeeLoading: true,
      };
    case EMPLOYEE_ADD_SUCCESS:
      return {
        ...state,
        addEmployeeLoading: false,
        addEmployeeSuccess: action.payload,
      };
    case EMPLOYEE_ADD_ERR:
      return {
        ...state,
        addEmployeeLoading: false,
        addEmployeeErr: action.payload,
      };
    default:
      return state;
  }
};

// checkEmployeePrivilegeReducer
export const checkEmployeePrivilegeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_PRIVILEGE_CHECK_REQUEST:
      return {
        ...state,
        checkEmployeePrivilegeLoading: true,
      };
    case EMPLOYEE_PRIVILEGE_CHECK_SUCCESS:
      return {
        ...state,
        checkEmployeePrivilegeLoading: false,
        checkEmployeePrivilegeSuccess: action.payload,
      };
    case EMPLOYEE_PRIVILEGE_CHECK_ERR:
      return {
        ...state,
        checkEmployeePrivilegeLoading: false,
        checkEmployeePrivilegeErr: action.payload,
      };
    default:
      return state;
  }
};

// employeedetailsShowReducer
export const employeedetailsShowReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_DETAILS_SHOW_REQUEST:
      return {
        ...state,
        showDetailsEmployeeLoading: true,
      };
    case EMPLOYEE_DETAILS_SHOW_SUCCESS:
      return {
        ...state,
        showDetailsEmployeeLoading: false,
        showDetailsEmployeeSuccess: action.payload,
      };
    case EMPLOYEE_DETAILS_SHOW_ERR:
      return {
        ...state,
        showDetailsEmployeeLoading: false,
        showDetailsEmployeeErr: action.payload,
      };
    default:
      return state;
  }
};

// findLeaveReducer
export const findLeaveReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_LEAVE_FIND_REQUEST:
      return {
        ...state,
        findLeaveLoading: true,
      };
    case EMPLOYEE_LEAVE_FIND_SUCCESS:
      return {
        ...state,
        findLeaveLoading: false,
        findLeaveSuccess: action.payload,
      };
    case EMPLOYEE_LEAVE_FIND_ERR:
      return {
        ...state,
        findLeaveLoading: false,
        findLeaveErr: action.payload,
      };
    default:
      return state;
  }
};

// findEmployeeSalaryPaySlipReducer
export const findEmployeeSalaryPaySlipReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_SALARY_SLIP_FIND_REQUEST:
      return {
        ...state,
        findEmployeePaySlipLoading: true,
      };
    case EMPLOYEE_SALARY_SLIP_FIND_SUCCESS:
      return {
        ...state,
        findEmployeePaySlipLoading: false,
        findEmployeePaySlipSuccess: action.payload,
      };
    case EMPLOYEE_SALARY_SLIP_FIND_ERR:
      return {
        ...state,
        findEmployeePaySlipLoading: false,
        findEmployeePaySlipErr: action.payload,
      };
    default:
      return state;
  }
};

// changePasswordReducer
export const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassLoading: true,
      };
    case EMPLOYEE_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassLoading: false,
        changePassSuccess: action.payload,
      };
    case EMPLOYEE_CHANGE_PASSWORD_ERR:
      return {
        ...state,
        changePassLoading: false,
        changePassErr: action.payload,
      };
    default:
      return state;
  }
};

// deleteEmployeeReducer
export const deleteEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_DELETE_REQUEST:
      return {
        ...state,
        deleteEmployeeLoading: true,
      };
    case EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        deleteEmployeeLoading: false,
        deleteEmployeeSuccess: action.payload,
      };
    case EMPLOYEE_DELETE_ERR:
      return {
        ...state,
        deleteEmployeeLoading: false,
        deleteEmployeeErr: action.payload,
      };
    default:
      return state;
  }
};

// leaveApplicationStatusChangeReducer
export const leaveApplicationStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        leaveApplicationStatusChangeLoading: true,
      };
    case EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        leaveApplicationStatusChangeLoading: false,
        leaveApplicationStatusChangeSuccess: action.payload,
      };
    case EMPLOYEE_CHANGE_LEAVE_STATUS_CHANGE_ERR:
      return {
        ...state,
        leaveApplicationStatusChangeLoading: false,
        leaveApplicationStatusChangeError: action.payload,
      };
    default:
      return state;
  }
};

// leaveApplicationDeleteReducer
export const leaveApplicationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_DELETE_LEAVE_APPLICATION_REQUEST:
      return {
        ...state,
        leaveApplicationDeleteLoading: true,
      };
    case EMPLOYEE_DELETE_LEAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        leaveApplicationDeleteLoading: false,
        leaveApplicationDeleteSuccess: action.payload,
      };
    case EMPLOYEE_DELETE_LEAVE_APPLICATION_ERR:
      return {
        ...state,
        leaveApplicationDeleteLoading: false,
        leaveApplicationDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// branchManagerFetchReducer
export const branchManagerFetchReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANCH_MANAGER_FETCH_REQUEST:
      return {
        ...state,
        branchManagerLoading: true,
      };
    case BRANCH_MANAGER_FETCH_SUCCESS:
      return {
        ...state,
        branchManagerLoading: false,
        branchManagerSuccess: action.payload,
      };
    case BRANCH_MANAGER_FETCH_ERR:
      return {
        ...state,
        branchManagerLoading: false,
        branchManagerError: action.payload,
      };
    default:
      return state;
  }
};

// findEmployeeMatchedRoleReducer
export const findEmployeeMatchedRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case MATCHED_ROLE_EMPLOYEE_FETCH_REQUEST:
      return {
        ...state,
        findEmployeeMatchedRoleLoading: true,
      };
    case MATCHED_ROLE_EMPLOYEE_FETCH_SUCCESS:
      return {
        ...state,
        findEmployeeMatchedRoleLoading: false,
        findEmployeeMatchedRoleSuccess: action.payload,
      };
    case MATCHED_ROLE_EMPLOYEE_FETCH_ERR:
      return {
        ...state,
        findEmployeeMatchedRoleLoading: false,
        findEmployeeMatchedRoleError: action.payload,
      };
    default:
      return state;
  }
};

// findEmployeeNotificationReducer
export const findEmployeeNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_LIST_FIND_REQUEST:
      return {
        ...state,
        findEmployeeNotificationLoading: true,
      };
    case NOTIFICATION_LIST_FIND_SUCCESS:
      return {
        ...state,
        findEmployeeNotificationLoading: false,
        findEmployeeNotificationSuccess: action.payload,
      };
    case NOTIFICATION_LIST_FIND_ERR:
      return {
        ...state,
        findEmployeeNotificationLoading: false,
        findEmployeeNotificationError: action.payload,
      };
    default:
      return state;
  }
};
// findEmployeeNotificationReducer
export const editedEmployeeDataReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_DETAILS_EDIT_REQUEST:
      return {
        ...state,
        editedEmployeeDataLoading: true,
      };
    case EMPLOYEE_DETAILS_EDIT_SUCCESS:
      return {
        ...state,
        editedEmployeeDataLoading: false,
        editedEmployeeDataSuccess: action.payload,
      };
    case EMPLOYEE_DETAILS_EDIT_ERR:
      return {
        ...state,
        editedEmployeeDataLoading: false,
        editedEmployeeDataError: action.payload,
      };
    default:
      return state;
  }
};


// EmployeeManagmentBulkUploadReducer

export const employeeBulkUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_BULKUPLOAD_REQUEST:
      return {
        ...state,
        employeeBulkUploadLoading: true,
      };
    case EMPLOYEE_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        employeeBulkUploadLoading: false,
        employeeBulkUploadSuccess: action.payload,
      };
    case EMPLOYEE_BULKUPLOAD_ERR:
      return {
        ...state,
        employeeBulkUploadLoading: false,
        employeeBulkUploadError: action.payload,
      };
    default:
      return state;
  }
};
