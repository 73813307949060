export const PARTNER_COMMISSION_CREATE_REQUEST =
  "PARTNER_COMMISSION_CREATE_REQUEST";
export const PARTNER_COMMISSION_CREATE_SUCCESS =
  "PARTNER_COMMISSION_CREATE_SUCCESS";
export const PARTNER_COMMISSION_CREATE_ERR = "PARTNER_COMMISSION_CREATE_ERR";

export const PARTNER_COMMISSION_FIND_REQUEST =
  "PARTNER_COMMISSION_FIND_REQUEST";
export const PARTNER_COMMISSION_FIND_SUCCESS =
  "PARTNER_COMMISSION_FIND_SUCCESS";
export const PARTNER_COMMISSION_FIND_ERR = "PARTNER_COMMISSION_FIND_ERR";

export const PARTNER_COMMISSION_DELETE_REQUEST =
  "PARTNER_COMMISSION_DELETE_REQUEST";
export const PARTNER_COMMISSION_DELETE_SUCCESS =
  "PARTNER_COMMISSION_DELETE_SUCCESS";
export const PARTNER_COMMISSION_DELETE_ERR = "PARTNER_COMMISSION_DELETE_ERR";

// EMPLOYEE INCENTIVE CATEGORY
export const EMPLOYEE_INCENTIVE_CREATE_REQUEST =
  "EMPLOYEE_INCENTIVE_CREATE_REQUEST";
export const EMPLOYEE_INCENTIVE_CREATE_SUCCESS =
  "EMPLOYEE_INCENTIVE_CREATE_SUCCESS";
export const EMPLOYEE_INCENTIVE_CREATE_ERR = "EMPLOYEE_INCENTIVE_CREATE_ERR";

export const EMPLOYEE_INCENTIVE_FIND_REQUEST =
  "EMPLOYEE_INCENTIVE_FIND_REQUEST";
export const EMPLOYEE_INCENTIVE_FIND_SUCCESS =
  "EMPLOYEE_INCENTIVE_FIND_SUCCESS";
export const EMPLOYEE_INCENTIVE_FIND_ERR = "EMPLOYEE_INCENTIVE_FIND_ERR";
