import React, { useEffect, useState } from "react";
import wcc from "world-countries-capitals";
import { Alert, AlertTitle, Grid } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import SearchHeader from "../SearchHeader";
import {
  DestinationFindAction,
  LandingServiceFindAction,
} from "../../../actions/LandingPageAction";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { LandingTable } from "../LandingTable";

function Services(props) {
  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  useEffect(() => {
    dispatch(DestinationFindAction());
  }, [dispatch]);

  let { serviceCreateSuccess } = useSelector((state) => {
    return state.LandingServiceAdd;
  });

  let { serviceFindSuccess } = useSelector((state) => {
    return state.LandingServiceFind;
  });

  useEffect(() => {
    if (serviceFindSuccess) {
      setServiceList(serviceFindSuccess);
    }
  }, [serviceFindSuccess]);

  useEffect(() => {
    dispatch(LandingServiceFindAction());
  }, [serviceCreateSuccess, dispatch]);

  useEffect(() => {
    setData(
      serviceList.map((service, index) => ({
        id: service.id,
        sl: index + 1,
        coloum1: service.serviceName,
        // coloum2: service.description,
        // coloum3: service.description1,
        // coloum4: service.description2,
      }))
    );
  }, [serviceList]);

  let columns = [
    {
      title: "SL NO",
      field: "SL NO",
    },
    {
      title: "Service Name",
      field: "Service Name",
    },
    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          const name = item.name ? item.name.toLowerCase() : "";
          const phone = item.phone ? item.phone.toLowerCase() : "";
          const preferredCountry = item.preferredCountry
            ? item.preferredCountry.toLowerCase()
            : "";
          const leadSource = item.leadSource
            ? item.leadSource.toLowerCase()
            : "";
          const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          const status = item.status ? item.status.toLowerCase() : "";
          const education = item.education ? item.education.toLowerCase() : "";

          const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          const leadBranch = item.branchMaster
            ? item.leadCity.toLowerCase()
            : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase()) ||
            preferredCountry.includes(e.target.value.toLowerCase()) ||
            leadSource.includes(e.target.value.toLowerCase()) ||
            leadOwner.includes(e.target.value.toLowerCase()) ||
            status.includes(e.target.value.toLowerCase()) ||
            education.includes(e.target.value.toLowerCase()) ||
            leadCity.includes(e.target.value.toLowerCase()) ||
            leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        leadData.map((data, index) => ({
          id: data.id,
          sl: index + 1,
          name: data.firstName,
          phone: data.mobileNumber,
          preferredCountry: data.preferredCountryId,
          leadSource: data.leadSource,
          leadOwner: data.leadOwner,
          status: data.status,
          priority: data.priority ? "-" : "-",
          leadCity: data.location,
          leadBranch: data?.branch_master?.branchName,
        }))
      );
    }
  };

  // Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] =
    useState(false);

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }
    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }
    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadType") {
      setColumnName(column);
      setShowLeadTypeColumn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "education") {
      setColumnName(column);
      setShowEducationColumn(status);
    }

    if (column === "universityPlace") {
      setColumnName(column);
      setShowUniversityPlaceColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("name");
    const storedPhoneColumn = localStorage.getItem("mobile");
    const storedPrefferedCountryColumn =
      localStorage.getItem("prefferedCountry");
    const storedLeadSourceColumn = localStorage.getItem("leadSource");
    const storedLeadTypeColumn = localStorage.getItem("leadType");
    const storedStatusColumn = localStorage.getItem("status");
    const storedEducationColumn = localStorage.getItem("education");
    const storedUniversityTypeColumn = localStorage.getItem("universityPlace");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }
    // if (storedDescriptionNameColumn !== null) {
    //   setColumnName("email");
    //   setShowPrefferedColumn(JSON.parse(storedDescriptionNameColumn));
    // }
    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }
    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadTypeColumn !== null) {
      setColumnName("leadType");
      setShowLeadTypeColumn(JSON.parse(storedLeadTypeColumn));
    }
    if (storedStatusColumn !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatusColumn));
    }

    if (storedEducationColumn !== null) {
      setColumnName("education");
      setShowEducationColumn(JSON.parse(storedEducationColumn));
    }

    if (storedUniversityTypeColumn !== null) {
      setColumnName("universityPlace");
      setShowUniversityPlaceColumn(JSON.parse(storedUniversityTypeColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("name", JSON.stringify(showNameColumn));
    localStorage.setItem("email", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("mobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem(
      "prefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem("leadSource", JSON.stringify(showLeadSourceColumn));
    localStorage.setItem("leadType", JSON.stringify(showLeadTypeColumn));
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
    localStorage.setItem("education", JSON.stringify(showEducationColumn));
    localStorage.setItem(
      "universityPlace",
      JSON.stringify(showUniversityPlaceColumn)
    );
  }, [
    showNameColumn,
    showPhoneColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadTypeColumn,
    showStatusColumn,
    showEducationColumn,
    showUniversityPlaceColumn,
    columnName,
  ]);

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("services") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  return (
    <>
      <SearchHeader
        columnVisibleHandler={toggleNameColumn}
        showNameColumn={showNameColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadTypeColumn={showLeadTypeColumn}
        showStatusColumn={showStatusColumn}
        showEducationColumn={showEducationColumn}
        showUniversityPlaceColumn={showUniversityPlaceColumn}
        // handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
      />

      <Grid item xs={12} md={10} lg={12} style={{ marginLeft: "-25px" }}>
        <LandingTable
          toggle={props.toggle}
          showNameColumn={showNameColumn}
          showPrefferedColumn={showPrefferedColumn}
          showPhoneColumn={showPhoneColumn}
          showLeadSourceColumn={showLeadSourceColumn}
          showLeadTypeColumn={showLeadTypeColumn}
          showStatusColumn={showStatusColumn}
          showEducationColumn={showEducationColumn}
          showUniversityPlaceColumn={showUniversityPlaceColumn}
          // reset={reset}
          setIdsFunction={setIdsFunction}
          data={data}
          columns={columns}
          // leadFindLoading={leadFindLoading}
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          // isExistCounsilor={loginFindSuccess}
          component="serviceTab"
          subMenu = "services"
        />
      </Grid>
    </>
  );
}

export default Services;
