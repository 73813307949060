import React, { useEffect, useState } from "react";
import wcc from "world-countries-capitals";
import { Alert, AlertTitle, Box, Grid, TextField } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import SearchHeader from "../SearchHeader";
import DestinationTable from "./DestinationTable";
import { DestinationFindAction } from "../../../actions/LandingPageAction";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import Swal from "sweetalert2";
import {
  DESTINATION_DELETE_ERR,
  DESTINATION_DELETE_SUCCESS,
} from "../../../constants/LandingPage/LandingPageConstant";

function AllDestination(props) {
  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [destinationList, SetDestinationList] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  let { destinationFindSuccess } = useSelector((state) => {
    return state.destinationFind;
  });

  // Destination delete reducer

  let { destinationDeleteSuccess, destinationDeleteErr } = useSelector(
    (state) => {
      return state.DestinationDelete;
    }
  );

  useEffect(() => {
    dispatch(DestinationFindAction());
  }, [dispatch, destinationDeleteSuccess]);

  useEffect(() => {
    if (destinationFindSuccess) {
      SetDestinationList(destinationFindSuccess);
    }
  }, [destinationFindSuccess]);

  // Success msg

  useEffect(() => {
    if (destinationDeleteSuccess) {
      Swal.fire("Success", "Deleted successfully", "success");
      dispatch({ type: DESTINATION_DELETE_SUCCESS, payload: false });
    }
    if (destinationDeleteErr) {
      Swal.fire("Error", "Something went wrong", "Error");
      dispatch({ type: DESTINATION_DELETE_ERR, payload: false });
    }
  }, [destinationDeleteSuccess, destinationDeleteErr]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(
        destinationList?.map(async (destination, index) => {
          try {
            const countryDetails = await wcc.getCountryDetailsByName(
              destination.destinationName.split(" (")[0]
            );

            // Use .map() to create a new array of mapped objects
            const mappedDetails = countryDetails.map((details) => ({
              sl: index + 1,
              id: destination.id,
              coloum1: destination.destinationName,
              coloum2: details.capital,
              coloum3: details.native_language[0],
              coloum4:
                details.currency === "indian rupee"
                  ? "₹ Rupee"
                  : details.currency,
            }));

            return mappedDetails;
          } catch (error) {
            console.error(error);
            return []; // Handle errors as needed by returning an empty array
          }
        })
      );

      // Flatten the array of arrays into a single array
      const flattenedData = data.flat();

      // Set the data
      setData(flattenedData);
    };

    fetchData();
  }, [destinationList]);

  console.log(data, "::::::::::::::::::::::");

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          const name = item.name ? item.name.toLowerCase() : "";
          const phone = item.phone ? item.phone.toLowerCase() : "";
          const preferredCountry = item.preferredCountry
            ? item.preferredCountry.toLowerCase()
            : "";
          const leadSource = item.leadSource
            ? item.leadSource.toLowerCase()
            : "";
          const leadOwner = item.leadOwner ? item.leadOwner.toLowerCase() : "";
          const status = item.status ? item.status.toLowerCase() : "";
          const education = item.education ? item.education.toLowerCase() : "";

          const leadCity = item.leadCity ? item.leadCity.toLowerCase() : "";
          const leadBranch = item.branchMaster
            ? item.leadCity.toLowerCase()
            : "";

          // :lead?.branch_master?.branchName
          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(e.target.value.toLowerCase()) ||
            preferredCountry.includes(e.target.value.toLowerCase()) ||
            leadSource.includes(e.target.value.toLowerCase()) ||
            leadOwner.includes(e.target.value.toLowerCase()) ||
            status.includes(e.target.value.toLowerCase()) ||
            education.includes(e.target.value.toLowerCase()) ||
            leadCity.includes(e.target.value.toLowerCase()) ||
            leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        leadData.map((data, index) => ({
          id: data.id,
          sl: index + 1,
          name: data.firstName,
          phone: data.mobileNumber,
          preferredCountry: data.preferredCountryId,
          leadSource: data.leadSource,
          leadOwner: data.leadOwner,
          status: data.status,
          priority: data.priority ? "-" : "-",
          leadCity: data.location,
          leadBranch: data?.branch_master?.branchName,
        }))
      );
    }
  };

  // Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] = useState(
    false
  );

  const [columnName, setColumnName] = useState("");
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }
    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }
    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadType") {
      setColumnName(column);
      setShowLeadTypeColumn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "education") {
      setColumnName(column);
      setShowEducationColumn(status);
    }

    if (column === "universityPlace") {
      setColumnName(column);
      setShowUniversityPlaceColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("name");
    const storedDescriptionNameColumn = localStorage.getItem("email");
    const storedPhoneColumn = localStorage.getItem("mobile");
    const storedPrefferedCountryColumn = localStorage.getItem(
      "prefferedCountry"
    );
    const storedLeadSourceColumn = localStorage.getItem("leadSource");
    const storedLeadTypeColumn = localStorage.getItem("leadType");
    const storedStatusColumn = localStorage.getItem("status");
    const storedEducationColumn = localStorage.getItem("education");
    const storedUniversityTypeColumn = localStorage.getItem("universityPlace");

    if (storedRollNameColumn !== null) {
      setColumnName("name");
      setShowNameColumn(JSON.parse(storedRollNameColumn));
    }
    // if (storedDescriptionNameColumn !== null) {
    //   setColumnName("email");
    //   setShowPrefferedColumn(JSON.parse(storedDescriptionNameColumn));
    // }
    if (storedPhoneColumn !== null) {
      setColumnName("mobile");
      setShowPhoneColumn(JSON.parse(storedPhoneColumn));
    }
    if (storedPrefferedCountryColumn !== null) {
      setColumnName("prefferedCountry");
      setShowPrefferedColumn(JSON.parse(storedPrefferedCountryColumn));
    }
    if (storedLeadSourceColumn !== null) {
      setColumnName("leadSource");
      setShowLeadSourceColumn(JSON.parse(storedLeadSourceColumn));
    }

    if (storedLeadTypeColumn !== null) {
      setColumnName("leadType");
      setShowLeadTypeColumn(JSON.parse(storedLeadTypeColumn));
    }
    if (storedStatusColumn !== null) {
      setColumnName("status");
      setShowStatusColumn(JSON.parse(storedStatusColumn));
    }

    if (storedEducationColumn !== null) {
      setColumnName("education");
      setShowEducationColumn(JSON.parse(storedEducationColumn));
    }

    if (storedUniversityTypeColumn !== null) {
      setColumnName("universityPlace");
      setShowUniversityPlaceColumn(JSON.parse(storedUniversityTypeColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("name", JSON.stringify(showNameColumn));
    localStorage.setItem("email", JSON.stringify(showPrefferedColumn));
    localStorage.setItem("mobile", JSON.stringify(showPhoneColumn));
    localStorage.setItem(
      "prefferedCountry",
      JSON.stringify(showPrefferedColumn)
    );
    localStorage.setItem("leadSource", JSON.stringify(showLeadSourceColumn));
    localStorage.setItem("leadType", JSON.stringify(showLeadTypeColumn));
    localStorage.setItem("status", JSON.stringify(showStatusColumn));
    localStorage.setItem("education", JSON.stringify(showEducationColumn));
    localStorage.setItem(
      "universityPlace",
      JSON.stringify(showUniversityPlaceColumn)
    );
  }, [
    showNameColumn,
    showPhoneColumn,
    showPrefferedColumn,
    showLeadSourceColumn,
    showLeadTypeColumn,
    showStatusColumn,
    showEducationColumn,
    showUniversityPlaceColumn,
    columnName,
  ]);

  //   const LeadFilterFunction = (data, filterOption, checkedStatus) => {
  //     alert(data);
  //     if (filterOption === "city") {
  //       setLeadData(
  //         leadData?.filter((item) => {
  //           return item.location.toLowerCase().includes(data.toLowerCase());
  //         })
  //       );

  //       setwhichFilter(filterOption);
  //       setFilterPassData(data);
  //     }
  //     if (filterOption === "preffered country") {
  //       if (checkedStatus === true) {
  //         setLeadData(
  //           leadData?.filter((item) => {
  //             return item.preferredCountryId
  //               .toLowerCase()
  //               .includes(data.toLowerCase());
  //           })
  //         );
  //         setwhichFilter(filterOption);
  //         setFilterPassData(data);
  //       } else {
  //         setwhichFilter(null);
  //         setFilterPassData(null);
  //         setLeadData(leadFindSuccess);
  //       }
  //     }
  //   };

  //   const handleResetData = (status) => {
  //     setwhichFilter(null);
  //     setFilterPassData(null);
  //     setReset(!reset);
  //     setwhichFilter(null);
  //     setFilterPassData(null);
  //   };

  const setIdsFunction = (leadsId) => {
    setLeadId(leadsId);
  };
  //   const handleDeleteClick = () => {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         dispatch(deleteLeadAction(leadId));
  //       }
  //     });
  //   };

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("destination") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <SearchHeader
        columnVisibleHandler={toggleNameColumn}
        showNameColumn={showNameColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadTypeColumn={showLeadTypeColumn}
        showStatusColumn={showStatusColumn}
        showEducationColumn={showEducationColumn}
        showUniversityPlaceColumn={showUniversityPlaceColumn}
        // handleResetData={handleResetData}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        component="alldestination"
      />

      <Grid item xs={12} md={10} lg={12} style={{ marginLeft: "-25px" }}>
        <DestinationTable
          toggle={props.toggle}
          showNameColumn={showNameColumn}
          showPrefferedColumn={showPrefferedColumn}
          showPhoneColumn={showPhoneColumn}
          showLeadSourceColumn={showLeadSourceColumn}
          showLeadTypeColumn={showLeadTypeColumn}
          showStatusColumn={showStatusColumn}
          showEducationColumn={showEducationColumn}
          showUniversityPlaceColumn={showUniversityPlaceColumn}
          // reset={reset}
          setIdsFunction={setIdsFunction}
          data={data}
          // leadFindLoading={leadFindLoading}
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          // isExistCounsilor={loginFindSuccess}
          component="EmployeeJob"
          subMenu="destination"
        />
      </Grid>
    </>
  );
}

export default AllDestination;
