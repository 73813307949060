import React, { useState } from "react";
import { MdClear } from "react-icons/md";

import { Button, FormControl } from "@mui/material";
import { InputLabel, Select, MenuItem } from "@mui/material";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { courseTableFilterAction } from "../../../actions/universityActions/courseActions/universityCourseAction";
import { useParams } from "react-router-dom";

const inteshipData = [
  "Less than 1 year",
  "1 year",
  "2 years",
  "3 Years",
  "4 Years",
  "5 Years",
  "6 Years",
];

function UniversityCourseFilter(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, seventhListOpen] = useState(false);
  const [categoryDuration, setCategoryDuration] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [applicationFee, setApplicationFee] = useState("");

  const handleClearClick = () => {
    setSelectedDate("");
    setCategoryDuration("");
    setApplicationFee("");
    props.handleResetData(true);
  };
  const handleDateChange = (e) => {
    console.log(e.target.value);
    setSelectedDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedDate);
    dispatch(courseTableFilterAction(selectedDate, id, "intake"));
    setSelectedDate("");
  };

  const handleDurationSubmit = (e) => {
    e.preventDefault();
    dispatch(courseTableFilterAction(categoryDuration, id, "duration"));
    setCategoryDuration("");
  };

  const handleApplicationFeeSubmit = (e) => {
    e.preventDefault();
    dispatch(courseTableFilterAction(applicationFee, id, "applicationFee"));
    setApplicationFee("");
    // Add your logic here to handle the form submission with the entered application fee
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick4 = () => {
    setFourthListOpen(!fourthListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    seventhListOpen(!sevenListopen);
  };

  return (
    <>
      <List
        sx={{
          maxWidth: "310px",
          //   height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          //   overflow: "scroll",
          overflowX: "hidden",
          border: "1px solid #ECECEC",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        {/* first List */}
        <ListItemButton onClick={handleClick}>
          <ListItemText primary="Intake" />
          {open ? (
            <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
          )}
        </ListItemButton>

        {/* collapse */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="select intake month"
                  type="month"
                  value={selectedDate}
                  fullWidth
                  onChange={handleDateChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="body1">
                          {selectedDate ? selectedDate : "MM/YYYY"}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ backgroundColor: "white", margin: 1, width: "280px" }}
                />
                <Button
                  type="submit"
                  sx={{
                    fontSize: "15px",
                    color: "white",
                    backgroundColor: "#141E3C",
                    width: "80px",
                    height: "35px",
                    borderRadius: "7px",
                    fontWeight: 700,
                    mt: 1,
                  }}
                >
                  Submit
                </Button>
              </form>
            </ListItemButton>
          </List>
        </Collapse>

        {/* second List */}
        <ListItemButton onClick={handleClick2}>
          <ListItemText primary="Duration" />
          {secListopen ? (
            <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
          )}
        </ListItemButton>

        <Collapse in={secListopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <form onSubmit={handleDurationSubmit}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Choose Duration
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryDuration}
                    label="Total Duration"
                    required
                    onChange={(e) => setCategoryDuration(e.target.value)}
                    sx={{ backgroundColor: "white", margin: 1, width: "280px" }}
                  >
                    {inteshipData.map((data) => (
                      <MenuItem key={data} value={data}>
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  sx={{
                    fontSize: "15px",
                    color: "white",
                    backgroundColor: "#141E3C",
                    width: "80px",
                    height: "35px",
                    borderRadius: "7px",
                    fontWeight: 700,
                    mt: 1,
                  }}
                >
                  Submit
                </Button>
              </form>
            </ListItemButton>
          </List>
        </Collapse>

        {/* third List
        // <ListItemButton onClick={handleClick3}>
        //   <ListItemText primary="Application Fee" />
        //   {thirdListopen ? (
        //     <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
        //   ) : (
        //     <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
        //   )}
        // </ListItemButton>

        {/* collapse */}
        {/* <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <form onSubmit={handleApplicationFeeSubmit}>
                <TextField
                  id="application-fee"
                  label="Application Fee"
                  variant="outlined"
                  value={applicationFee}
                  onChange={(e) => setApplicationFee(e.target.value)}
                  sx={{ backgroundColor: "white", margin: 1, width: "280px" }}
                />
                <Button
                  sx={{
                    fontSize: "15px",
                    color: "white",
                    backgroundColor: "#141E3C",
                    width: "80px",
                    height: "35px",
                    borderRadius: "7px",
                    fontWeight: 700,
                    mt: 1,
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </form>
            </ListItemButton>
          </List>
        </Collapse>  */}
      </List>
    </>
  );
}

export default UniversityCourseFilter;
