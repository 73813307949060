import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";

import Swal from "sweetalert2";

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import { MainContainer } from "../../content/content.element";
import { useNavigate, useParams } from "react-router-dom";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { IoKeyOutline } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAction,
  employeedetailsShowAction
} from "../../../actions/employeeManagmentAction";
import LeaveShowTable from "./employeeLeaveApply/LeaveShowTable";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import EmployeePaySlip from "./employeeTabs/EmployeePaySlip";
import EmployeeHoliday from "./employeeTabs/employeeHoliday/EmployeeHoliday";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import AccessDeniedIcon from "../../Leads/createLeads/AccessDeniedIcon";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { EMPLOYEE_CHANGE_PASSWORD_SUCCESS } from "../../../constants/employeeManagmentConstant";

// dialogue transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EmployeeDetails(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const [confirmview, setConfirmView] = useState(false);

  // Coustom hook :::::::::::::::::::
  const {
    privilege,
    hasPrivilege,
    isUpdatePrevilage,
    isDeletePrevilage,
    isStatusChangePrevilage
  } = usePrivilegeCheck();

  const dispatch = useDispatch();

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "black"
      // borderBottom: "3px solid black",
    }
  });

  //  selectors
  let {
    showDetailsEmployeeLoading,
    showDetailsEmployeeSuccess,
    showDetailsEmployeeErr
  } = useSelector((state) => {
    return state.employeedetailsShow;
  });

  let { changePassLoading, changePassSuccess, changePassErr } = useSelector(
    (state) => {
      return state.changePassword;
    }
  );

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `<p>${changePassErr}</p>`
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, changePassErr, dispatch]);

  useEffect(() => {
    if (changePassSuccess) {
      Swal.fire("Good job!", `Password Changed`, "success");
      dispatch({ type: EMPLOYEE_CHANGE_PASSWORD_SUCCESS, payload: false });
    }
  }, [changePassSuccess, dispatch]);

  useEffect(() => {
    dispatch(employeedetailsShowAction(id));
  }, [dispatch]);

  // tabs handle
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // change Password modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (isUpdatePrevilage("employee managment")) {
      setOpen(true);
    } else {
      setOpenAlert(true);
    }
  };
  const handleClose = () => setOpen(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [valid, setValid] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    dispatch(changePasswordAction(false,newPassword, confirmPassword, id));
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    // Reset error
    setError("");
    setOpen(false);
  };

  console.log(
    showDetailsEmployeeSuccess,
    "showDetailsEmployeeSuccessshowDetailsEmployeeSuccessshowDetailsEmployeeSuccess"
  );

  const validatePassword = (password) => {
    // Check if the password contains at least 6 characters and one symbol
    const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
    return regex.test(password);
  };

  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="Employee Details" url="/Administration" />
        <Paper
          elevation={0}
          sx={{ height: "175px", border: "0.5px solid #ECECEC", mt: 2 }}
        >
          <Grid container spacing={1} sx={{ mt: 0.5 }}>
            <Grid xs={12}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={showDetailsEmployeeSuccess?.users?.img_url}
                    sx={{
                      width: 105,
                      height: 111,
                      margin: "23px",
                      left: "10px"
                    }}
                  />

                  <div
                    style={{
                      marginTop: "30px",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 600,
                        fontSize: "24px",
                        marginTop: "5px"
                      }}
                    >
                      {showDetailsEmployeeSuccess?.users?.firstName}
                    </Typography>{" "}
                    &nbsp;
                    <div
                      style={{
                        marginTop: "-15px"
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "'Montserrat', sans-serif",
                          fontWeight: 400,
                          fontSize: "16px"
                        }}
                      >
                        {showDetailsEmployeeSuccess?.users?.role?.roleName}
                      </Typography>
                    </div>
                    <div
                      style={{
                        marginTop: "7px"
                      }}
                    >
                      <Typography
                        onClick={() =>
                          navigate(
                            `/Administration/employee/details/track/${id}`
                          )
                        }
                        sx={{
                          color: "#141E3C",
                          fontWeight: 700,
                          fontSize: "15px",
                          fontFamily: "'Open Sans', sans-serif",
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                      >
                        Active Log
                      </Typography>
                    </div>
                  </div>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    height="150px"
                    sx={{ marginLeft: "50px" }}
                    flexItem
                  />

                  <div
                    style={{
                      display: "flex",
                      marginLeft: "50px"
                    }}
                  >
                    <div>
                      <p>
                        <BsTelephone />
                      </p>
                      <p>
                        <MdOutlineEmail />
                      </p>
                      <p>
                        <CiLocationOn />
                      </p>
                      <p>
                        <IoKeyOutline style={{ color: "#2a7ad3" }} />
                      </p>
                    </div>

                    <div
                      style={{
                        fontFamily: "Open Sans",
                        fontSize: "16px",
                        fontweight: 400,
                        letterSpacing: "0.4399999976158142px",
                        marginLeft: "10px"
                      }}
                    >
                      <p style={{ fontweight: 500, fontSize: "15px" }}>
                        {showDetailsEmployeeSuccess?.users?.mobile
                          ? showDetailsEmployeeSuccess?.users
                              ?.mobile_countryCode +
                            " " +
                            showDetailsEmployeeSuccess?.users?.mobile
                          : "-"}
                      </p>
                      <p style={{ fontweight: 500, fontSize: "15px" }}>
                        {showDetailsEmployeeSuccess?.users?.email}
                      </p>
                      <p style={{ fontweight: 500, fontSize: "15px" }}>
                        {showDetailsEmployeeSuccess?.users?.country_code
                          ? showDetailsEmployeeSuccess?.users?.country_code
                          : "-"}
                      </p>
                      <Button
                        style={{
                          marginTop: "-7px",
                          marginLeft: "-5px",
                          fontweight: 500,
                          fontSize: "15px"
                        }}
                        onClick={handleOpen}
                      >
                        Change Password
                      </Button>
                    </div>
                    <div>
                      {showDetailsEmployeeSuccess?.teamLeadBtoB !== null && (
                        <div style={{ display: "flex", marginLeft: "50px" }}>
                          <div>
                            <p>
                              {/* <BsTelephone /> */}
                              Team Lead B2B :
                            </p>
                          </div>
                          <div
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                              fontweight: 400,
                              letterSpacing: "0.4399999976158142px",
                              marginLeft: "10px"
                            }}
                          >
                            <p style={{ fontweight: 500, fontSize: "15px" }}>
                              {showDetailsEmployeeSuccess?.teamLeadBtoB
                                ?.firstName
                                ? showDetailsEmployeeSuccess?.teamLeadBtoB
                                    ?.firstName
                                : "-"}
                            </p>
                          </div>
                        </div>
                      )}

                      {showDetailsEmployeeSuccess?.teamLeadBtoC !== null && (
                        <div style={{ display: "flex", marginLeft: "50px" }}>
                          <div>
                            <p>
                              {/* <BsTelephone /> */}
                              Team Lead B2C :
                            </p>
                          </div>
                          <div
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                              fontweight: 400,
                              letterSpacing: "0.4399999976158142px",
                              marginLeft: "10px"
                            }}
                          >
                            <p style={{ fontweight: 500, fontSize: "15px" }}>
                              {showDetailsEmployeeSuccess?.teamLeadBtoC
                                ?.firstName
                                ? showDetailsEmployeeSuccess?.teamLeadBtoC
                                    ?.firstName
                                : "-"}
                            </p>
                          </div>
                        </div>
                      )}

                      {showDetailsEmployeeSuccess?.teleCallerManager !==
                        null && (
                        <div style={{ display: "flex", marginLeft: "50px" }}>
                          <div>
                            <p>
                              {/* <BsTelephone /> */}
                              Telecaller Manager :
                            </p>
                          </div>
                          <div
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                              fontweight: 400,
                              letterSpacing: "0.4399999976158142px",
                              marginLeft: "10px"
                            }}
                          >
                            <p style={{ fontweight: 500, fontSize: "15px" }}>
                              {showDetailsEmployeeSuccess?.teleCallerManager
                                ?.firstName
                                ? showDetailsEmployeeSuccess?.teleCallerManager
                                    ?.firstName
                                : "-"}
                            </p>
                          </div>
                        </div>
                      )}

                      {showDetailsEmployeeSuccess?.teleCallerTeamLead !==
                        null && (
                        <div style={{ display: "flex", marginLeft: "50px" }}>
                          <div>
                            <p>
                              {/* <BsTelephone /> */}
                              Telecaller Team Lead :
                            </p>
                          </div>
                          <div
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                              fontweight: 400,
                              letterSpacing: "0.4399999976158142px",
                              marginLeft: "10px"
                            }}
                          >
                            <p style={{ fontweight: 500, fontSize: "15px" }}>
                              {showDetailsEmployeeSuccess?.teleCallerTeamLead
                                ?.firstName
                                ? showDetailsEmployeeSuccess?.teleCallerTeamLead
                                    ?.firstName
                                : "-"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Box style={{ display: "flex", margin: "20px" }}>
                  <Box sx={{ m: 1 }}>
                    <OutLinedButton
                      title="Apply Leave"
                      handleClick={() => {
                        if (isUpdatePrevilage("employee managment")) {
                          navigate(
                            `/Administration/employee/details/applyLeave/${id}`
                          );
                        } else {
                          setOpenAlert(true);
                        }
                      }}
                      widthSize="120px"
                      heightSize="49px"
                    />
                  </Box>
                  <Box sx={{ m: 1 }}>
                    <SubmitButton
                      title="Edit Profile"
                      submit="click"
                      widthSize="110px"
                      heightSize="49px"
                      type="click"
                      handleSubmit={() => {
                        if (isUpdatePrevilage("employee managment")) {
                          navigate(
                            `/Administration/employee/details/edit/${id}`
                          );
                        } else {
                          setOpenAlert(true);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Paper>

        {/*employee tab */}
        <Box sx={{ width: "100%", typography: "body1", mt: 2 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {/* <StyledTab label={tab1Name} {...a11yProps(0)} /> */}
                <StyledTab label="Incentive" value="1" />
                <StyledTab label="Pay Slip" value="2" />
                <StyledTab label="Holiday List" value="3" />
                <StyledTab label="Leave Application" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">Incentive</TabPanel>
            <TabPanel value="2">
              <EmployeePaySlip
                role={showDetailsEmployeeSuccess?.users?.role?.roleName}
              />
            </TabPanel>
            <TabPanel value="3">
              <EmployeeHoliday />
            </TabPanel>
            <TabPanel value="4">
              <LeaveShowTable
                employeeId={showDetailsEmployeeSuccess?.users?.id}
              />
            </TabPanel>
          </TabContext>
        </Box>

        {/* change password modal */}
        {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      
      </Modal> */}

        {/* dialogue */}

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ width: "100%" }}
        >
          <DialogTitle>{"Change Password"}</DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6} md={4} lg={12}>
                <form onSubmit={handleSubmit}>
                  {/* <TextField
                    type="password"
                    label="Old Password"
                    fullWidth
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                    margin="normal"
                  /> */}

                  <TextField
                    type={view ? "text" : "password"}
                    label="New Password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setValid(validatePassword(newPassword));
                    }}
                    required
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setView(!view)}>
                            {view ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {!valid && (
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        fontWeight: 300,
                        paddingTop: "-20px"
                      }}
                    >
                      Password must have at least 6 characters and contain at
                      least one symbol (!@#$%^&*)
                    </span>
                  )}

                  <TextField
                    type={confirmview ? "text" : "password"}
                    label="Confirm Password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    margin="normal"
                    error={error.length > 0}
                    helperText={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setConfirmView(!confirmview)}
                          >
                            {confirmview ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <Box mt={2}>
                    <Button
                      type="submit"
                      sx={{
                        width: "auto",
                        height: "auto",
                        color: "white",
                        p: 2,
                        backgroundColor: "rgba(20, 30, 60, 1)",
                        borderRadius: "8px",
                        fontWeight: 600,
                        fontSize: "12px",
                        "&:hover": {
                          backgroundColor: "rgba(20, 30, 60, 0.9)"
                        }
                      }}
                    >
                      Change Password
                    </Button>
                  </Box>
                </form>
                {/* </Paper> */}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button> */}
          </DialogActions>
        </Dialog>
      </MainContainer>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Close
            sx={{
              cursor: "pointer"
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700"
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px"
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px"
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
}

export default EmployeeDetails;
