import React, { useEffect, useState } from "react";
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { CiRepeat } from "react-icons/ci";
import { GoDownload } from "react-icons/go";
import { AiTwotoneDelete } from "react-icons/ai";

import { CommonDocumentApplicationFindAction } from "../../../actions/knowledgeBankActions/CommonDocsAction";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import {
  changeSopApplicationStatusAction,
  countryAllAdmissionDocsFindAction,
  leadCountryCommonDocsFindDocsAction,
  leadCountrycommonDocUploadingAction,
  leadCountrySpecificDocsFindingDocsAction,
  leadCountrySpecificDocUploadingAction,
  leadDocReUploadingAction,
  leadDocumentDeleteAction,
  leadOtherDocDeleteAction,
  leadOtherDocFindAction,
  leadOtherDocUploadingAction
} from "../../../actions/leadActions/leadDocsActions";

import "./CountryDocs.css";
import CountryFlag from "../../customComponent/CountryFlag";
import Swal from "sweetalert2";
import {
  LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS,
  LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS,
  LEAD_DOCUMENT_DELETE_SUCCESS,
  LEAD_OTHER_DOCUMENT_UPLOADING_SUCCESS
} from "../../../constants/leadConstant";

function CountryDocs({ handleNext, handleCompleteTick }) {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleMouseEnter = (icon) => setHoveredIcon(icon);
  const handleMouseLeave = () => setHoveredIcon(null);

  const iconStyle = {
    width: "22px",
    height: "22px",
    marginRight: "10px",
    transition: "color 0.3s ease" // Smooth color transition
  };

  const iconHoverStyle = {
    color: "red" // Color on hover
  };

  let { id } = useParams();
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [countryDoc, setCountryDoc] = useState([]);
  const [commonDoc, setCommonDoc] = useState([]);
  const [commonAddedDoc, setCommonAddedDoc] = useState([]);
  const [countrySpecificAddedDoc, setCountrySpeficAddedDoc] = useState([]);
  const [CountryId, setCountryId] = useState();
  const [uplodedOtherDoc, setUplodedOtherDoc] = useState([]);

  let { commonDocFindSuccess } = useSelector((state) => {
    return state.CommonDocumentApplicationFind;
  });

  let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let {
    countryAdmissionFindLoading,
    countryAdmissionFindSuccess,
    countryAdmissionFinderror
  } = useSelector((state) => {
    return state.countryAllAdmissionDocsFind;
  });

  let {
    leadCountryCommonDocsFindLoading,
    leadCountryCommonDocsFindSuccess,
    leadCountryCommonDocsFinderror
  } = useSelector((state) => {
    return state.leadCountryCommonDocsFindDocs;
  });

  let {
    leadCountryCommonDocsUploadingLoading,
    leadCountryCommonDocsUploadingSuccess,
    leadCountryCommonDocsUploadingerror
  } = useSelector((state) => {
    return state.leadCountrycommonDocUploading;
  });

  let {
    leadCountrySpecificDocsFindingLoading,
    leadCountrySpecificDocsFindingSuccess,
    leadCountrySpecificDocsFindingerror
  } = useSelector((state) => {
    return state.leadCountrySpecificDocsFindingDocs;
  });

  let {
    leadCountrySpecificDocsUploadingLoading,
    leadCountrySpecificDocsUploadingSuccess,
    leadCountrySpecificDocsUploadingerror
  } = useSelector((state) => {
    return state.leadCountrySpecificDocUploading;
  });

  let { leadOtherDocsFindSuccess } = useSelector((state) => {
    return state.leadOtherDocFind;
  });

  let { leadOtherDocsUploadingSuccess } = useSelector((state) => {
    return state.leadOtherDocUploading;
  });

  let { leadOtherDocsDeleteSuccess } = useSelector((state) => {
    return state.leadOtherDocDelete;
  });

  let { leadSopStatusChangeSuccess } = useSelector((state) => {
    return state.changeSopApplicationStatus;
  });

  let { leadDocumentDeleteSuccess } = useSelector((state) => {
    return state.leadDocumentDelete;
  });

  // Reupload successState

  let { leadDocumentReUploadingSuccess } = useSelector((state) => {
    return state.leadDocumentReupload;
  });

  console.log(countryDoc, "countryDoccountryDoccountryDoc");

  useEffect(() => {
    dispatch(countryAllAdmissionDocsFindAction(id));
  }, [
    dispatch,
    leadCountryCommonDocsUploadingSuccess,
    leadCountrySpecificDocsUploadingSuccess,
    id,
    leadSopStatusChangeSuccess
  ]);

  useEffect(() => {
    dispatch(leadCountryCommonDocsFindDocsAction(id));
  }, [
    dispatch,
    id,
    leadCountryCommonDocsUploadingSuccess,
    leadCountrySpecificDocsUploadingSuccess,
    leadSopStatusChangeSuccess,
    leadDocumentReUploadingSuccess,
    leadDocumentDeleteSuccess
  ]);

  useEffect(() => {
    if (commonDocFindSuccess) {
      setCountryList(commonDocFindSuccess);
    }
  }, [commonDocFindSuccess]);

  useEffect(() => {
    if (leadCountryCommonDocsFindSuccess) {
      const uniqueDocumentIds = new Set();
      setCommonAddedDoc(
        leadCountryCommonDocsFindSuccess?.filter((specificData) => {
          if (!uniqueDocumentIds.has(specificData.CommonDocId)) {
            uniqueDocumentIds.add(specificData.CommonDocId);
            return true;
          }
          return false;
        })
      );
    }
  }, [leadCountryCommonDocsFindSuccess]);

  useEffect(() => {
    if (leadCountrySpecificDocsFindingSuccess) {
      const uniqueDocumentIds = new Set();
      setCountrySpeficAddedDoc(
        leadCountrySpecificDocsFindingSuccess?.filter((specificData) => {
          if (!uniqueDocumentIds.has(specificData.DocId)) {
            uniqueDocumentIds.add(specificData.DocId);
            return true;
          }
          return false;
        })
      );
    }
  }, [leadCountrySpecificDocsFindingSuccess]);

  useEffect(() => {
    if (leadCountryCommonDocsFindSuccess && commonDocFindSuccess) {
      const notEqualCountries = commonDocFindSuccess.filter((country) => {
        return !leadCountryCommonDocsFindSuccess.some(
          (sop) => country.id == sop.CommonDocId
        );
      });
      setCommonDoc(notEqualCountries);
    }
  }, [commonDocFindSuccess, leadCountryCommonDocsFindSuccess]);

  useEffect(() => {
    if (countryAdmissionFindSuccess && leadCountrySpecificDocsFindingSuccess) {
      const filterCountryDocs = countryAdmissionFindSuccess?.filter(
        (country) => {
          return !leadCountrySpecificDocsFindingSuccess.some(
            (sop) => country.id == sop.DocId
          );
        }
      );
      setCountryDoc(filterCountryDocs);
    }
  }, [countryAdmissionFindSuccess, leadCountrySpecificDocsFindingSuccess]);

  console.log(
    countryAdmissionFindSuccess,
    "countryAdmissionFindSuccesscountryAdmissionFindSuccesscountryAdmissionFindSuccesscountryAdmissionFindSuccess"
  );

  useEffect(() => {
    dispatch(CommonDocumentApplicationFindAction());
  }, [
    dispatch,
    leadCountrySpecificDocsUploadingSuccess,
    leadCountryCommonDocsUploadingSuccess,
    leadSopStatusChangeSuccess,
    leadDocumentReUploadingSuccess
  ]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction(id));
  }, [
    dispatch,
    leadCountrySpecificDocsUploadingSuccess,
    leadCountryCommonDocsUploadingSuccess,
    leadSopStatusChangeSuccess
  ]);

  useEffect(() => {
    dispatch(leadCountrySpecificDocsFindingDocsAction(id));
  }, [
    dispatch,
    id,
    leadCountrySpecificDocsUploadingSuccess,
    leadCountryCommonDocsUploadingSuccess,
    leadSopStatusChangeSuccess,
    leadDocumentReUploadingSuccess,
    leadDocumentDeleteSuccess
  ]);

  useEffect(() => {
    dispatch(leadOtherDocFindAction(id));
  }, [
    ,
    dispatch,
    leadOtherDocsUploadingSuccess,
    uplodedOtherDoc,
    leadOtherDocsDeleteSuccess
  ]);

  useEffect(() => {
    if (leadOtherDocsUploadingSuccess) {
      Swal.fire({
        icon: "success",
        title: "Good job \u{1F44D} ",
        text: "Other Document Uploaded Successfully"
      });
      dispatch({
        type: LEAD_OTHER_DOCUMENT_UPLOADING_SUCCESS,
        payload: false
      });
    }
  }, [leadOtherDocsUploadingSuccess]);

  // useEffect(() => {
  //   if (
  //     countryAdmissionFindSuccess !== undefined &&
  //     countryAdmissionFindSuccess.length !== 0
  //   ) {
  //     if (commonDoc.length === 0 && countryDoc.length === 0) {
  //       handleCompleteTick();
  //     }
  //   }
  // }, [commonDoc, countryDoc, handleCompleteTick, countryAdmissionFindSuccess]);

  // useEffect(()=>{

  // },[leadDocumentReUploadingSuccess])

  const handleUploadCommonDocsChange = (event, CommonDocId, countryName) => {
    const selectedFiles = event.target.files;
    console.log(selectedFiles, "selectedFileeeeeeeeeeeessssssssssssssssssss");
    if (selectedFiles.length <= 6) {
      // Read each image file as a data URL
      const readers = Array.from(selectedFiles).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return reader;
      });

      // Set the previewImages state when all readers have finished loading the images
      Promise.all(
        readers.map(
          (reader) =>
            new Promise((resolve) => {
              reader.onloadend = () => resolve(reader.result);
            })
        )
      ).then((results) => {
        console.log(results, "lkjfgkldfjglkfdjglkj");
        dispatch(
          leadCountrycommonDocUploadingAction(
            results,
            CountryId,
            countryName.id,
            id
          )
        );
        // setPreviewImages([...previewImages, ...results]);
      });
    } else {
      // Handle the case when the number of selected files is less than 6
      alert("you can select only 6 files.");
    }
  };

  // country Doc changer

  const handleCountrySpecificDocChange = (event, DocId, data) => {
    console.log("filessssssssssssssssss");
    const files = event.target.files;

    if (files && files.length <= 6) {
      // Read each image file as a data URL
      const readers = Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return reader;
      });

      Promise.all(
        readers.map(
          (reader) =>
            new Promise((resolve, reject) => {
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = () => reject(new Error("Error reading file"));
            })
        )
      )
        .then((results) => {
          console.log(results, "lkjfgkldfjglkfdjglkj");
          console.log(
            data,
            "ddddddddddddddaaaaaaaaaaaaaaaaattttttttttttttttttaaaaaaaaaaaaaaaaaaaaaaaaa"
          );
          dispatch(
            leadCountrySpecificDocUploadingAction(results, DocId, id, data)
          );
        })
        .catch((error) => {
          console.error("Error reading files: ", error);
        });
    } else {
      console.error("No files selected or too many files (more than 6)");
    }
  };

  const handleCountrySpecificDocReupload = (event, DocId, data) => {
    const files = event.target.files[0];
    console.log(files, "filesssssssss");
    let status;

    if (data.Common_Doc) {
      status = "CommonDoc";
    } else {
      status = "CountryDoc";
    }
    // Read each image file as a data URL
    const reader = new FileReader();
    reader.readAsDataURL(files);

    console.log(reader.result, "readddddddddddddddddd");
    reader.onloadend = () => {
      dispatch(leadDocReUploadingAction(reader.result, DocId, data, status));
    };
  };

  // document visible click
  const [open, setOpen] = React.useState(false);
  const [otherDocOpenView, setOtherDocOpenView] = React.useState(false);

  const [numPages, setNumPages] = useState(null);
  const [url, setUrl] = React.useState("");
  const [scroll, setScroll] = React.useState("paper");
  const [statusVerify, setStatusVerify] = useState(false);
  const [docStatusVerify, setDocStatusVerify] = useState(false);
  const [commonDocList, setCommonDocList] = useState([]);
  const [listDocuments, setListDocuments] = useState([]);

  const handleClickOpen = (scrollType, pdfUrl, CommonDocId, section) => () => {
    // alert(CommonDocId);
    let listDoc;
    if (section === "country") {
      listDoc =
        leadCountrySpecificDocsFindingSuccess &&
        leadCountrySpecificDocsFindingSuccess?.filter(
          (item) => item.DocId === CommonDocId
        );
    } else {
      listDoc =
        leadCountryCommonDocsFindSuccess &&
        leadCountryCommonDocsFindSuccess?.filter(
          (item) => item.CommonDocId === CommonDocId
        );
    }

    setCommonDocList(listDoc);
    setUrl(pdfUrl);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
    setCommonDocList([]);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, otherDocOpenView]);

  useEffect(() => {
    if (
      leadCountryCommonDocsUploadingSuccess ||
      leadCountrySpecificDocsUploadingSuccess
    ) {
      Swal.fire({
        icon: "success",
        title: "Good job \u{1F44D} ",
        text: "Document Uploaded Successfully"
      });
      dispatch({
        type: LEAD_COUNTRY_COMMON_DOCS_UPLOADING_SUCCESS,
        payload: false
      });

      dispatch({
        type: LEAD_COUNTRY_SPECIFIC_DOCS_UPLOADING_SUCCESS,
        payload: false
      });
    }
  }, [
    leadCountryCommonDocsUploadingSuccess,
    leadCountrySpecificDocsUploadingSuccess
  ]);

  // Other Document upload

  const handleOtherDocumentChange = (event) => {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;
    let taskOwner = isUserExist?.name;

    console.log(event, "eventeventevent");
    const files = event;
    if (files && files.length <= 6) {
      setUplodedOtherDoc(Array.from(event));
      // Read each image file as a data URL
      const formData = new FormData();
      Array.from(event)?.forEach((file, index) => {
        formData.append("image", file);
      });
      formData.append("leadId", id);
      formData.append("taskowner", taskOwner);

      dispatch(leadOtherDocUploadingAction(formData));
    } else {
      console.error("No files selected or too many files (more than 6)");
    }
  };

  console.log(
    leadOtherDocsFindSuccess,
    "leadOtherDocsFindSuccessleadOtherDocsFindSuccess"
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 1, mt: 1 }}>
          <Typography sx={{ fontWeight: 700 }} variant="h6">
            Document
          </Typography>
        </Grid>

        {commonDoc?.length == 0 &&
        countryDoc?.lengtth == 0 &&
        commonAddedDoc?.length == 0 &&
        countrySpecificAddedDoc?.length == 0 ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "24px",
              fontFamily: "Montserrat,sans-serif",
              textAlign: "center",
              mt: 10,
              ml: 5,
              mt: 5
            }}
          >
            No documents to show. Preferred country is required to show
            Documents !
          </Typography>
        ) : (
          ""
        )}
        {commonDoc?.map((data) => (
          <Grid item xs={6} md={4} lg={3} key={data?.id}>
            <Box
              sx={{
                width: "auto",
                height: "auto",
                backgroundColor: "white",
                border: "1.5px dashed rgba(25, 118, 210, 0.5)",
                borderRadius: "5px",
                padding: "10px"
              }}
            >
              <label
                htmlFor={`commonDoc_file${data?.id}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
                onClick={() => setCountryId(data.id)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/menuIcons/docs.png"
                    alt="gsl"
                    style={{
                      width: "auto",
                      height: "auto",
                      cursor: "pointer",
                      color: "#4086f4"
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography>{data.DocName}</Typography>
                    {/* <Typography
                      sx={{
                        color: "#ACB1C6",
                        fontWeight: 300,
                        fontSize: "15px",
                        lineHeight: "20px"
                      }}
                    >
                      Common Documents
                    </Typography> */}
                  </Box>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/menuIcons/uploadBlue.png"
                    alt="gsl_download"
                    style={{ cursor: "pointer", color: "#1976D2" }}
                  />
                  <div
                    style={{
                      marginLeft: "8px",
                      color: "#1976D2",
                      fontWeight: 400,
                      fontFamily: "Montserrat,sans-serif",
                      fontSize: "20px"
                    }}
                  >
                    Upload
                  </div>
                </div>
              </label>
            </Box>

            <input
              type="file"
              accept=".pdf,.doc,.docx,.xlsx"
              id={`commonDoc_file${data?.id}`}
              style={{ display: "none" }}
              multiple
              onChange={(e) => handleUploadCommonDocsChange(e, data.id, data)}
            />
          </Grid>
        ))}

        {commonAddedDoc?.map((addedDoc, index) => (
          <Grid item xs={6} md={4} lg={3}>
            <Box position="relative">
              <Box
                // id="container"
                sx={{
                  width: "auto",
                  height: "82px",
                  backgroundColor: "white",
                  border:
                    addedDoc.status === "verified" ||
                    addedDoc.status === "approved"
                      ? "1.5px dashed #ECECEC"
                      : "1.5px dashed rgba(255, 153, 0, 1)"
                }}
              >
                <div style={{ display: "flex", margin: "10px" }}>
                  <img
                    src="/menuIcons/docs.png"
                    alt="gsl"
                    sx={{
                      width: "62px",
                      height: 60,
                      cursor: "pointer",
                      color: "#4086f4"
                    }}
                  />
                  {/* {sopData.countryName.split("(")[0]} */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ mt: 1, mb: 1 }}>
                      {addedDoc.Common_Doc.DocName}
                    </Typography>
                    {/* <br /> */}
                    <Box sx={{ display: "flex" }}>
                      {statusVerify[index] !== true && (
                        <>
                          <Tooltip title="view">
                            <IconButton
                              sx={{ padding: 0, marginRight: "10px" }}
                            >
                              <IoEyeOutline
                                style={{
                                  ...iconStyle,
                                  color:
                                    hoveredIcon === "eye"
                                      ? iconHoverStyle.color
                                      : "black",
                                  cursor: "pointer"
                                }}
                                onMouseEnter={() => handleMouseEnter("eye")}
                                onMouseLeave={handleMouseLeave}
                                onClick={handleClickOpen(
                                  "paper",
                                  addedDoc.pdfUrl,
                                  addedDoc.CommonDocId,
                                  "common"
                                )}
                              />
                            </IconButton>
                          </Tooltip>

                          {addedDoc.status !== "approved" && (
                            <Tooltip title="Change status">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <CiRepeat
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "repeat"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("repeat")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() =>
                                    setStatusVerify((prev) => ({
                                      ...prev,
                                      [index]: true
                                    }))
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          <a
                            href={addedDoc.pdfUrl}
                            download={addedDoc.pdfUrl}
                            target="blank"
                            style={{
                              textDecoration: "none",
                              color: "black"
                            }}
                          >
                            <Tooltip title="Download">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <GoDownload
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "download"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("download")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                />
                              </IconButton>
                            </Tooltip>
                          </a>

                          {addedDoc.status !== "verified" &&
                          addedDoc.status !== "approved" ? (
                            <Tooltip title="Delete">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <AiTwotoneDelete
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "delete"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("delete")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!"
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        dispatch(
                                          leadDocumentDeleteAction(
                                            addedDoc.id,
                                            id,
                                            "commonDocs"
                                          )
                                        );
                                        Swal.fire(
                                          "Deleted!",
                                          "Your file has been deleted.\u{1F44D}",
                                          "success"
                                        );
                                      }
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </>
                      )}

                      {statusVerify[index] === true && (
                        <>
                          <Box
                            id="icon"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backdropFilter: "blur(8px)",
                              opacity: 0,
                              transition: "opacity 0.3s",
                              zIndex: 1,
                              " &:hover ": {
                                opacity: 1
                              }
                            }}
                          >
                            <Chip
                              label="Reupload"
                              color="warning"
                              variant="outlined"
                              style={{
                                marginLeft: "-10px",
                                fontSize: "1em",
                                marginRight: "10px",
                                cursor: "pointer",
                                color: "rgba(255, 153, 0, 1)",
                                borderColor: "rgba(255, 153, 0, 1)",
                                position: "relative",
                                zIndex: 2
                              }}
                              onClick={() => {
                                dispatch(
                                  changeSopApplicationStatusAction(
                                    addedDoc.leadId,
                                    addedDoc.CommonDocId,
                                    "reupload",
                                    "Docs"
                                  )
                                );
                                setStatusVerify((prevState) => ({
                                  ...prevState,
                                  [index]: false
                                }));
                              }}
                            />
                            <Chip
                              label="Verify"
                              color="success"
                              variant="outlined"
                              style={{
                                color: "rgba(50, 157, 0, 1)",
                                borderColor: "rgba(50, 157, 0, 1)",
                                marginLeft: "10px",
                                fontSize: "1em",
                                position: "relative", // Maintain relative positioning within the Box
                                zIndex: 2,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                dispatch(
                                  changeSopApplicationStatusAction(
                                    addedDoc.leadId,
                                    addedDoc.CommonDocId,
                                    "verified",
                                    "Docs"
                                  )
                                );
                                setStatusVerify((prevState) => ({
                                  ...prevState,
                                  [index]: false
                                }));
                              }}
                            />
                            <Chip
                              label="close"
                              color="success"
                              variant="outlined"
                              style={{
                                color: "rgba(227, 71, 43)",
                                borderColor: "rgba(227, 71, 43)",
                                marginLeft: "10px",
                                fontSize: "1em",
                                position: "relative", // Maintain relative positioning within the Box
                                zIndex: 2,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                setStatusVerify((prevState) => ({
                                  ...prevState,
                                  [index]: false
                                }));
                              }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </div>

                  <div
                    className="rounded-flag"
                    style={{
                      marginLeft: "auto",
                      paddingLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end"
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        color: "#329D00",
                        fontWeight: 400,
                        fontFamily: "Montserrat, sans-serif",
                        fontSize: "20px"
                      }}
                    >
                      Uploaded
                    </div>
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        color:
                          addedDoc.status === "verified" ||
                          addedDoc.status === "approved"
                            ? "#1976D2"
                            : "rgba(255, 153, 0, 1)"
                      }}
                    >
                      {addedDoc.status}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} sx={{ mb: 1, mt: 1 }}>
          <Typography sx={{ fontWeight: 700 }} variant="h6">
            Country Document
          </Typography>
        </Grid>

        {countryDoc?.map((data) => (
          <Grid item xs={6} md={4} lg={3} key={data.id}>
            <Box
              sx={{
                width: "auto",
                height: "auto",
                backgroundColor: "white",
                border: "1.5px dashed rgba(25, 118, 210, 0.5)",
                borderRadius: "5px",
                padding: "10px"
              }}
            >
              <label
                htmlFor={`countryDoc-file${data.id}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/menuIcons/docs.png"
                    alt="gsl"
                    style={{
                      width: "auto",
                      height: "auto",
                      cursor: "pointer",
                      color: "#4086f4"
                    }}
                  />
                  <Typography>
                    {data.docName} <br />
                    <Typography sx={{ color: "#ACB1C6" }}>
                      {data.Country_Doc.DocName.split("(")[0]}
                    </Typography>
                  </Typography>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="/menuIcons/uploadBlue.png"
                    alt="gsl_download"
                    style={{ cursor: "pointer", color: "#1976D2" }}
                  />
                  <div
                    style={{
                      marginLeft: "8px",
                      color: "#1976D2",
                      fontWeight: 400,
                      fontFamily: "Montserrat,sans-serif",
                      fontSize: "20px"
                    }}
                  >
                    Upload
                  </div>
                </div>
              </label>

              <input
                type="file"
                accept=".pdf,.doc,.docx,.xlsx"
                multiple
                id={`countryDoc-file${data.id}`}
                style={{ display: "none" }}
                onChange={(e) => {
                  handleCountrySpecificDocChange(e, data.id, data);
                }}
              />
            </Box>
          </Grid>
        ))}

        {countrySpecificAddedDoc?.map((specificData, index) => (
          <Grid item xs={6} md={4} lg={3}>
            <Box position="relative">
              <Box
                // id="container"
                sx={{
                  width: "auto",
                  height: "82px",
                  backgroundColor: "white",
                  border:
                    specificData.status === "verified" ||
                    specificData.status === "approved"
                      ? "1.5px dashed #ECECEC"
                      : "1.5px dashed rgba(255, 153, 0, 1)"
                }}
              >
                <div style={{ display: "flex", margin: "10px" }}>
                  <div className="rounded-flag" style={{ marginTop: "9px" }}>
                    <CountryFlag
                      countryCode={specificData.countryCode}
                      component="stepper"
                      width="39px"
                      heigth="39px"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography sx={{ mt: 1, mb: 1 }}>
                      {specificData.docName}
                    </Typography>

                    <Box sx={{ display: "flex" }}>
                      {docStatusVerify[index] !== true && (
                        <>
                          <Tooltip title="view">
                            <IconButton
                              sx={{ padding: 0, marginRight: "10px" }}
                            >
                              <IoEyeOutline
                                style={{
                                  ...iconStyle,
                                  color:
                                    hoveredIcon === "eye"
                                      ? iconHoverStyle.color
                                      : "black",
                                  cursor: "pointer"
                                }}
                                onMouseEnter={() => handleMouseEnter("eye")}
                                onMouseLeave={handleMouseLeave}
                                onClick={handleClickOpen(
                                  "paper",
                                  specificData.pdfUrl,
                                  specificData.DocId,
                                  "country"
                                )}
                              />
                            </IconButton>
                          </Tooltip>

                          {specificData.status !== "approved" && (
                            <Tooltip title="Change status">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <CiRepeat
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "repeat"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("repeat")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() =>
                                    setDocStatusVerify((prevStatus) => ({
                                      ...prevStatus,
                                      [index]: true
                                    }))
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                          <a
                            href={specificData.pdfUrl}
                            download={specificData.pdfUrl}
                            target="blank"
                            style={{
                              textDecoration: "none",
                              color: "black"
                            }}
                          >
                            <Tooltip title="Download">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <GoDownload
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "download"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("download")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                />
                              </IconButton>
                            </Tooltip>
                          </a>

                          {specificData.status !== "verified" &&
                          specificData.status !== "approved" ? (
                            <Tooltip title="Delete">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <AiTwotoneDelete
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "delete"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("delete")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!"
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        dispatch(
                                          leadDocumentDeleteAction(
                                            specificData.id,
                                            id,
                                            "countryDoc"
                                          )
                                        );
                                        Swal.fire(
                                          "Deleted!",
                                          "Your file has been deleted.\u{1F44D}",
                                          "success"
                                        );
                                      }
                                    });
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      {docStatusVerify[index] === true && (
                        <Box
                          id="icon"
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backdropFilter: "blur(8px)",
                            opacity: 0,
                            transition: "opacity 0.3s",
                            zIndex: 1,
                            " &:hover ": {
                              opacity: 1
                            }
                          }}
                        >
                          <>
                            <Chip
                              label="Reupload"
                              color="warning"
                              variant="outlined"
                              style={{
                                marginLeft: "-10px",
                                fontSize: "1em",
                                marginRight: "10px",
                                cursor: "pointer",
                                color: "rgba(255, 153, 0, 1)",
                                borderColor: "rgba(255, 153, 0, 1)",
                                position: "relative",
                                zIndex: 2
                              }}
                              onClick={() => {
                                // alert("sdfsdfjklk");
                                dispatch(
                                  changeSopApplicationStatusAction(
                                    specificData.leadId,
                                    specificData.DocId,
                                    "reupload",
                                    "countryDocs"
                                  )
                                );
                                setDocStatusVerify((prevStatus) => ({
                                  ...prevStatus,
                                  [index]: false
                                }));
                              }}
                            />
                            <Chip
                              label="Verify"
                              color="success"
                              variant="outlined"
                              style={{
                                color: "rgba(50, 157, 0, 1)",
                                borderColor: "rgba(50, 157, 0, 1)",
                                marginLeft: "10px",
                                fontSize: "1em",
                                position: "relative",
                                zIndex: 2,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                dispatch(
                                  changeSopApplicationStatusAction(
                                    specificData.leadId,
                                    specificData.DocId,
                                    "verified",
                                    "countryDocs"
                                  )
                                );
                                setDocStatusVerify((prevStatus) => ({
                                  ...prevStatus,
                                  [index]: false
                                }));
                              }}
                            />

                            <Chip
                              label="close"
                              color="success"
                              variant="outlined"
                              style={{
                                color: "rgba(227, 71, 43)",
                                borderColor: "rgba(227, 71, 43)",
                                marginLeft: "10px",
                                fontSize: "1em",
                                position: "relative",
                                zIndex: 2,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                setDocStatusVerify((prevStatus) => ({
                                  ...prevStatus,
                                  [index]: false
                                }));
                              }}
                            />
                          </>
                        </Box>
                      )}
                    </Box>
                  </div>
                  <div
                    className="rounded-flag"
                    style={{
                      marginLeft: "auto",
                      paddingLeft: "auto",
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end"
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        color: "#329D00",
                        fontWeight: 400,
                        fontFamily: "Montserrat,sans-serif",
                        fontSize: "20px"
                      }}
                    >
                      Uploaded
                    </div>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color:
                          specificData.status === "verified" ||
                          specificData.status === "approved"
                            ? "#1976D2"
                            : "rgba(255, 153, 0, 1)"
                      }}
                    >
                      {specificData.status}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} sx={{ mb: 1, mt: 1 }}>
          <Typography sx={{ fontWeight: 700 }} variant="h6">
            Other Document
          </Typography>
        </Grid>
      </Grid>

      {leadOtherDocsFindSuccess?.data?.length == 0 ? (
        <Grid item xs={6} md={4} lg={3}>
          <Box
            sx={{
              width: "auto",
              height: "auto",
              backgroundColor: "white",
              border: "1.5px dashed rgba(25, 118, 210, 0.5)",
              borderRadius: "5px",
              padding: "10px"
            }}
          >
            <label
              htmlFor={`countryDoc-file`}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/menuIcons/docs.png"
                  alt="gsl"
                  style={{
                    width: "auto",
                    height: "auto",
                    cursor: "pointer",
                    color: "#4086f4"
                  }}
                />
                <Typography>Other Document</Typography>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/menuIcons/uploadBlue.png"
                  alt="gsl_download"
                  style={{ cursor: "pointer", color: "#1976D2" }}
                />
                <div
                  style={{
                    marginLeft: "8px",
                    color: "#1976D2",
                    fontWeight: 400,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "20px"
                  }}
                >
                  Upload
                </div>
              </div>
            </label>

            <input
              type="file"
              accept=".pdf,.doc,.docx,.xlsx"
              multiple
              id="countryDoc-file"
              style={{ display: "none" }}
              onChange={(e) => {
                handleOtherDocumentChange(e.target.files);
              }}
            />
          </Box>
        </Grid>
      ) : (
        <Grid item xs={6} md={4} lg={3}>
          <Box position="relative">
            <Box
              // id="container"
              sx={{
                width: "auto",
                height: "82px",
                backgroundColor: "white",
                border: "1.5px dashed rgba(255, 153, 0, 1)"
              }}
            >
              <div style={{ display: "flex", margin: "10px" }}>
                <img
                  src="/menuIcons/docs.png"
                  alt="gsl"
                  sx={{
                    width: "62px",
                    height: 60,
                    cursor: "pointer",
                    color: "#4086f4"
                  }}
                />
                {/* {sopData.countryName.split("(")[0]} */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ mt: 1, mb: 1 }}>Other document</Typography>
                  {/* <br /> */}
                  <Box sx={{ display: "flex" }}>
                    <>
                      <Tooltip title="view">
                        <IconButton sx={{ padding: 0, marginRight: "10px" }}>
                          <IoEyeOutline
                            style={{
                              ...iconStyle,
                              color:
                                hoveredIcon === "eye"
                                  ? iconHoverStyle.color
                                  : "black",
                              cursor: "pointer"
                            }}
                            onMouseEnter={() => handleMouseEnter("eye")}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => setOtherDocOpenView(true)}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton sx={{ padding: 0, marginRight: "10px" }}>
                          <AiTwotoneDelete
                            style={{
                              ...iconStyle,
                              color:
                                hoveredIcon === "delete"
                                  ? iconHoverStyle.color
                                  : "black",
                              cursor: "pointer"
                            }}
                            onMouseEnter={() => handleMouseEnter("delete")}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!"
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  dispatch(leadOtherDocDeleteAction(id));
                                  Swal.fire(
                                    "Deleted!",
                                    "Your file has been deleted.\u{1F44D}",
                                    "success"
                                  );
                                }
                              });
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  </Box>
                </div>

                <div
                  className="rounded-flag"
                  style={{
                    marginLeft: "auto",
                    paddingLeft: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end"
                  }}
                >
                  <div
                    style={{
                      marginLeft: "8px",
                      color: "#329D00",
                      fontWeight: 400,
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "20px"
                    }}
                  >
                    Uploaded
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </Grid>
      )}

      {/* document visible dilogues */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            marginTop: "10vh",
            alignSelf: "flex-start",
            width: "100%",
            padding: "20px"
          }
        }}
      >
        <DialogTitle id="scroll-dialog-title">Pdf View</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              padding: "20px"
            }}
          >
            {/* <iframe
              src={url}
              width="100%"
              height="100%"
              title="PDF Preview"
              // sandbox="allow-scripts allow-same-origin"
            /> */}
            {/* <iframe src={url} style={{width:"600px", height:"500px"}} frameBorder="0"/> */}
            <Grid container spacing={2}>
              {commonDocList &&
                commonDocList?.map((item, index) => (
                  <>
                    <Grid item xs={12} key={index}>
                      <Paper
                        elevation={1}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: ".5px solid #F9F9FB",
                          padding: "10px"
                        }}
                      >
                        <div>
                          <Typography>Document {index + 1}.pdf</Typography>
                        </div>
                        <div>
                          {/* <label>
                            <img
                              src="/menuIcons/uploadDoc.png"
                              alt="gsl"
                              style={{
                                fontSize: "2em",
                                marginRight: "15px",
                                cursor: "pointer",
                                color: "#f81b82"
                              }}
                            />

                            <input
                              type="file"
                              accept=".pdf,.doc,.docx,.xlsx"
                              id="ReUpload-file"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleCountrySpecificDocReupload(
                                  e,
                                  item.id,
                                  item
                                );
                              }}
                            />
                          </label> */}
                          <a
                            href={item.pdfUrl}
                            download={item.pdfUrl}
                            target="blank"
                          >
                            <img
                              src="/menuIcons/visibleDoc.png"
                              alt="gsl"
                              style={{
                                fontSize: "2.5em",
                                marginRight: "20px",
                                cursor: "pointer",
                                color: "#f81b82"
                              }}
                              // onClick={handleClickOpen("paper", specificData.pdfUrl)}
                            />
                          </a>

                          {/* <img
                            src="/menuIcons/statusChange.png"
                            alt="gsl"
                            style={{
                              fontSize: "2.5em",
                              marginRight: "10px",
                              cursor: "pointer",
                              color: "#f81b82",
                            }}
                            onClick={() => setDocStatusVerify(true)}
                          /> */}
                        </div>
                      </Paper>
                    </Grid>
                    <Divider />
                  </>
                ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Other document visible dilogues */}
      <Dialog
        open={otherDocOpenView}
        onClose={() => setOtherDocOpenView(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            marginTop: "10vh",
            alignSelf: "flex-start",
            width: "100%",
            padding: "20px"
          }
        }}
      >
        <DialogTitle id="scroll-dialog-title">Pdf View</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{
              padding: "20px"
            }}
          >
            <Grid container spacing={2}>
              {leadOtherDocsFindSuccess &&
                leadOtherDocsFindSuccess?.data?.map((item, index) => (
                  <>
                    <Grid item xs={12} key={index}>
                      <Paper
                        elevation={1}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          border: ".5px solid #F9F9FB",
                          padding: "10px"
                        }}
                      >
                        <div>
                          <Typography>
                            {item?.documentName?.split(".")[0]}
                          </Typography>
                        </div>
                        <div>
                          <a
                            href={item.pdfUrl}
                            download={item.pdfUrl}
                            target="blank"
                          >
                            <img
                              src="/menuIcons/visibleDoc.png"
                              alt="gsl"
                              style={{
                                fontSize: "2.5em",
                                marginRight: "20px",
                                cursor: "pointer",
                                color: "#f81b82"
                              }}
                            />
                          </a>

                          {/* <img
                            src="/menuIcons/statusChange.png"
                            alt="gsl"
                            style={{
                              fontSize: "2.5em",
                              marginRight: "10px",
                              cursor: "pointer",
                              color: "#f81b82",
                            }}
                            onClick={() => setDocStatusVerify(true)}
                          /> */}
                        </div>
                      </Paper>
                    </Grid>
                    <Divider />
                  </>
                ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOtherDocOpenView(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CountryDocs;
