import React, { useEffect, useState } from "react";

import { AiOutlineDownload, AiOutlinePlus } from "react-icons/ai";

import { SiMicrosoftexcel } from "react-icons/si";

import { RiSearch2Line } from "react-icons/ri";

import CheckIcon from "@mui/icons-material/Check";

import {
  Box,
  Checkbox,
  Grid,
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    // marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));

function IeltsTeacherDashBoardHeader({
  columnVisibleHandler,
  excelDownloadClick,
  data,
  searchHandleChange,
  pdfDownloadClick,
  component,
  showNameColumn,
  showDobColumn,
  showGenderColumn,
  showEmailColumn,
  showPhoneColumn,
  showWhatsAppColumn,
  showCourseColumn,
  showCenterColumn,
  showPaymentColumn,
  showStatusColumn


  // showWhatsAppColumn,
  // showEducationColumn,
  // showUniversityPlaceColumn,
  // filterPassData,
  // whichFilter,
  // handleResetData,
  // handleDeleteClick,
  // ids,
  // leadId,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // button Export

  const [exportShow, setExportShow] = React.useState(null);
  const openExport = Boolean(exportShow);

  const handleClickExport = (event) => {
    setExportShow(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportShow(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Grid>
          <div className={classes.searchContainer}>
            <TextField
              className={classes.searchInput}
              variant="outlined"
              placeholder="Search Here"
              sx={{
                "& .MuiInputBase-input": {
                  borderRadius: "20px",
                  height: "1px",
                },
                "& .MuiFormLabel-root": {
                  lineHeight: "5px",
                },

                "& .MuiInputBase-root": {
                  borderRadius: "3px",
                  borderColor: "#fafafa",
                },
              }}
              onChange={(e) => searchHandleChange(e.target.value)}
            />
            <>
              <Box
                sx={{
                  width: "40px",
                  height: "16px",
                  pt: 1.9,
                  textAlign: "center",
                  border: "1px solid #9d9fa1",
                  borderBottomRightRadius: "5px",
                  WebkitBorderTopRightRadius: "5px",
                  fontSize: "24px",
                }}
              >
                <InputAdornment position="end">
                  <RiSearch2Line />
                </InputAdornment>
              </Box>
            </>
          </div>
        </Grid>
        <Grid
          sx={{
            margin: 1,
          }}
        >
          <OutLinedButton
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            title="Column visibility"
            handleClick={handleClick}
            widthSize="182px"
            heightSize="30px"
          />
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              borderColor: "black",
              borderRadius: "24px",
              mt: 6,
              "& ul": {
                maxHeight: "250px",
                overflow: "scroll",
              },
              "& ul::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showNameColumn === true ? true : false}
                onChange={(e) => columnVisibleHandler(e.target.checked, "name")}
              />
              Name
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showDobColumn === true ? true : false}
                onChange={(e) => columnVisibleHandler(e.target.checked, "dob")}
              />
              Dob
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showGenderColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "gender")
                }
              />
              Gender
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showEmailColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "email")
                }
              />
              Email
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showPhoneColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "phone")
                }
              />
              Phone
            </MenuItem>

            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showWhatsAppColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "whatsapp")
                }
              />
              WhatsApp No
            </MenuItem>
            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showCourseColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "course")
                }
              />
              Course
            </MenuItem>

            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showCenterColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "center")
                }
              />
              Center
            </MenuItem>

            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showPaymentColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "payment")
                }
              />
              Payment
            </MenuItem>

            <MenuItem>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                }}
                checked={showStatusColumn === true ? true : false}
                onChange={(e) =>
                  columnVisibleHandler(e.target.checked, "status")
                }
              />
              Status
            </MenuItem>
          </Menu>
        </Grid>

        {/* export Button */}

        <Grid sx={{ margin: 1 }}>
          <OutLinedButton
            title="Export"
            handleClick={handleClickExport}
            widthSize="auto"
            heightSize="30px"
          />

          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={exportShow}
            open={openExport}
            onClose={handleCloseExport}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
          >
            <MenuItem onClick={pdfDownloadClick}>
              <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                <AiOutlineDownload
                  style={{ color: "#141E3C", fontSize: "24px" }}
                />
              </Icon>
              Pdf
            </MenuItem>
            <MenuItem onClick={excelDownloadClick}>
              <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                <SiMicrosoftexcel
                  style={{ color: "#141E3C", fontSize: "24px" }}
                />
              </Icon>
              Excel
            </MenuItem>
          </Menu>
        </Grid>
        {/* Submit Button */}

        {/* Submit Button */}
        <Grid sx={{ margin: 1 }}>
          <SubmitButton
            title="Add"
            submit=""
            widthSize="75px"
            heightSize="30px"
            type="click"
            handleSubmit={() => navigate("/ielts/teacherManagment/addTeacher")}
          />
        </Grid>
      </Box>
    </>
  );
}

export default IeltsTeacherDashBoardHeader;
