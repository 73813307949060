import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";

import { countries } from "countries-list";

import { useDispatch, useSelector } from "react-redux";
import InputField, {
  SelectInputField,
} from "../../../customComponent/InputField";
import { branchMasterEditAction } from "../../../../actions/branchMasterActions";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";

const allCountries = Object.entries(countries).map(([code, country]) => ({
  value: code,
  label: country.name,
  phone: `+${country.phone}`,
}));

function EditBranchForm({ status, addMasterCategryHandler, data }) {
  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { branchMasterEditSuccess } = useSelector((state) => {
    return state.branchMasterEdit;
  });

  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [errorMsg, setErrorMsg] = useState({});
  const [branch, setBranch] = useState(data[0].column1);
  const [location, setLocation] = useState(data[0].column2);
  const [phoneNumber, setPhoneNumber] = useState(data[0].column3);
  const [email, setEmail] = useState(data[0].branchEmail);
  const [countryCode, setCountryCode] = useState(data[0].countryCode);

  useEffect(() => {
    if (branchMasterEditSuccess && successAlertMsgSuccess) {
      Swal.fire("Updated", `Branch updated successfully`, "success");
      dispatch(successTrueMsgAction(false));
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
      handleClose();
    }
  }, [branchMasterEditSuccess, successAlertMsgSuccess]);

  const handlePhoneNumberChange = (e) => {
    let number = e.target.value;
    if (/^\d{0,16}$/.test(number)) {
      setPhoneNumber(number);
      setErrorMsg((prev) => ({
        ...prev,
        ["phoneNumber"]: "",
      }));
    }
  };

  const handleCountryCodeChange = (event) => {
    let value = event.target.value;
    if (value) {
      setCountryCode(value);
    }
  };

  const handleClose = () => {
    setOpen(false);
    addMasterCategryHandler(status, false);
  };

  const validate = () => {
    let error = {};

    if (!branch) {
      error.branch = "Please enter a branch name";
    }
    if (!location) {
      error.location = "Please enter a location";
    }
    if (phoneNumber && !countryCode) {
      error.phoneNumber = "Please choose a country code";
    }

    // if (phoneNumber && !/^\d{8,16}$/.test(phoneNumber)) {
    //   error.phoneNumber = "Please enter a valid phone number";
    // }

    // if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    //   error.email = "Please enter a vaid email address";
    // }

    setErrorMsg(error);
    return Object.keys(error).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      dispatch(
        branchMasterEditAction(
          data[0].id,
          branch,
          location,
          countryCode,
          phoneNumber,
          email
        )
      );
      dispatch(successTrueMsgAction(true));
      setBranch(null);
      setErrorMsg(false);
    } else {
      // setErrorMsg(true);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            minHeight: "320px",
            maxHeight: "calc(100vh - 64px)",
            width: "600px",
            bottom: "20vh",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Edit Branch</DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText id="alert-dialog-description" mt={2}>
            {/* {errorMsg && (
              <Typography align="center" color="error">
                Please fill all details
              </Typography>
            )} */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <InputField
                label="Branch Name"
                handleChange={(event) => {
                  setBranch(event.target.value);
                  setErrorMsg((prev) => ({
                    ...prev,
                    ["branch"]: "",
                  }));
                }}
                value={branch}
                widthSize="99%"
                required
                InputType="text"
                error={Boolean(errorMsg?.branch)}
                helperText={errorMsg?.branch}
              />

              <InputField
                label="Location"
                handleChange={(event) => {
                  setLocation(event.target.value);
                  setErrorMsg((prev) => ({
                    ...prev,
                    ["location"]: "",
                  }));
                }}
                value={location}
                widthSize="99%"
                required
                InputType="text"
                error={Boolean(errorMsg?.location)}
                helperText={errorMsg?.location}
              />
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <SelectInputField
                    handleSelectChange={handleCountryCodeChange}
                    value={countryCode}
                    mapValue={allCountries}
                    mapMethod="countryCode"
                    error={Boolean(errorMsg?.countryCode)}
                    helperText={errorMsg?.countryCode}
                  />
                </Grid>
                <Grid item xs={10}>
                  <InputField
                    label="Mobile Number"
                    handleChange={handlePhoneNumberChange}
                    value={phoneNumber}
                    widthSize="99%"
                    // required
                    InputType="number"
                    // error={Boolean(errorMsg?.phoneNumber)}
                    // helperText={errorMsg?.phoneNumber}
                  />
                </Grid>
              </Grid>

              <InputField
                label="Email"
                handleChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMsg((prev) => ({
                    ...prev,
                    ["email"]: "",
                  }));
                }}
                value={email}
                widthSize="99%"
                // required
                InputType="email"
                // error={Boolean(errorMsg?.email)}
                // helperText={errorMsg?.email}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#141E3C",
              color: "#141E3C",
              height: "38px",
              width: "110px",
              fontWeight: 700,
              fontSize: "12px",
              display: "flex",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              color: "white",
              backgroundColor: "#141E3C",
              height: "38px",
              width: "110px",
              fontWeight: 700,
              fontSize: "12px",
              display: "flex",
              marginLeft: "auto",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditBranchForm;
