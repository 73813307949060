import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";

import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  findEmployeeSalaryAction,
  findEmployeeSalaryPaySlipAction,
  findLeaveAction,
  leaveApplicationDeleteAction,
  leaveApplicationStatusChangeAction,
} from "../../../../actions/employeeManagmentAction";
import { SlOptionsVertical } from "react-icons/sl";
import { FcCancel } from "react-icons/fc";
import { AiOutlineDelete } from "react-icons/ai";
import Swal from "sweetalert2";

import "./EmployeePaySlipTable.css";
import { BiDownload } from "react-icons/bi";

const useStyles = makeStyles({
  table: {
    borderCollapse: "separate",
    borderSpacing: "18px",
    border: "1px solid #ececec",
  },
  tableRow: {
    "& > *": {
      border: "none",
    },
  },
});
function EmployeePaySlipTable({role}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  const [employeePaySlipData, setEmployeePaySlipData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(findEmployeeSalaryPaySlipAction(id));
  }, [dispatch, id]);

  let {
    findEmployeePaySlipLoading,
    findEmployeePaySlipSuccess,
    findEmployeePaySlipErr,
  } = useSelector((state) => {
    return state.findEmployeeSalaryPaySlip;
  });

  useEffect(() => {
    if (findEmployeePaySlipSuccess) {
      setEmployeePaySlipData(findEmployeePaySlipSuccess);
    }
  }, [findEmployeePaySlipSuccess]);

  console.log(
    employeePaySlipData,
    "findEmployeePaySlipSuccessfindEmployeePaySlipSuccessfindEmployeePaySlipSuccess"
  );
  // table action
  const [employeeActionButtonShow, setEmployeeActionButtonShow] =
    useState(null);
  const [tableId, setTableId] = useState("");

  const employeeOpenActionButton = Boolean(employeeActionButtonShow);

  const handleClickAction = (event, id) => {
    console.log(id, " filtering: false, filtering: false,");
    setEmployeeActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setEmployeeActionButtonShow(null);
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      //   hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Employee Name",
      field: "name",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "ID",
      field: "id",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Role",
      field: "Role",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Month",
      field: "Month",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Payslip",
      field: "Payslip",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  useEffect(() => {
    if (employeePaySlipData.length !== 0){
        setData(
            employeePaySlipData &&
              employeePaySlipData.map((Payslip, index) => ({
                sl: index + 1,
                id: Payslip.paySlipId,
                userId: Payslip.UserId,
                name: Payslip.employeeName,
                employeeId: Payslip.employeeId,
                role: role,
                month: Payslip.dataValues.Month,
                paySlip: Payslip.dataValues.Payslip,
                status: Payslip.date,
              }))
          );
    }
      
  }, [employeePaySlipData]);

  return (
    <Table className="leaveShowTable">
      <TableHead>
        <TableRow className="leaveShowTableCell">
          <TableCell>
            <Checkbox
              // checked={selectedRows.length === sortedData.length}
              // onChange={handleSelectAllRows}
              style={{ color: "#f81b82" }}
            />
          </TableCell>
          {columns?.map((column) => {
            if (column.hidden) {
              return null;
            }
            return (
              <TableCell key={column.field} className="leaveShowTableCell">
                <TableSortLabel
                  className="leaveShowHeaderText"
                  // active={column === sortBy}
                  // direction={sortOrder}
                  // onClick={() => requestSort(column)}
                >
                  {column.title}
                </TableSortLabel>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((item, indx) => (
          <TableRow className="leaveShowTable" key={indx}>
            <TableCell>
              <Checkbox
                // checked={selectedRows.includes(item.id)}
                // onChange={() => handleSelectRow(item.id)}
                style={{ color: "#f81b82" }}
              />
            </TableCell>
            <TableCell>{item.sl}</TableCell>
            <TableCell>{item.name}</TableCell>

            <TableCell>{item.employeeId}</TableCell>

            <TableCell>{item.role}</TableCell>

            <TableCell>{item.month}</TableCell>
            <TableCell><a
                    style={{
                      color: "white",
                      backgroundColor: "#ACB1C6",
                      padding: "5px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                    href={item.paySlip}
                    download={item.paySlip}
                    target="blank"
                  >
                    <BiDownload
                      style={{
                        marginRight: "10px",
                        height: "20px",
                        width: "25px",
                      }}
                    />
                    Download
                    {/* {item.coloumn4 ==null ? "Upload" : "Download"} */}
                  </a></TableCell>
            {/* <TableCell>{item.duration}</TableCell>
            <TableCell>{item.status}</TableCell> */}
            {/* <TableCell>
              <SlOptionsVertical
                id="demo-positioned-button"
                aria-controls={
                  employeeOpenActionButton ? "demo-positioned-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={employeeOpenActionButton ? "true" : undefined}
                onClick={(e) => {
                  handleClickAction(e, item.id);
                }}
                variant="outlined"
                style={{ marginLeft: "10px", color: "#F80B7A" }}
              />
              <Menu
                open={employeeOpenActionButton}
                onClose={handleCloseAction}
                anchorEl={employeeActionButtonShow}
                placement="bottom"
                disablePortal
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                }}
                style={{
                  marginLeft: "-59px",
                  marginTop: "10px",
                  color: "#F80B7A",
                }}
              >
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <MenuItem>
                    <FcCancel
                      style={{ fontSize: "lg", width: "50px", height: "23px" }}
                      onClick={() =>
                        dispatch(leaveApplicationStatusChangeAction(tableId))
                      }
                    />
                  </MenuItem>

                  <MenuItem>
                    <AiOutlineDelete
                      style={{ fontSize: "lg", width: "50px", height: "23px" }}
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(leaveApplicationDeleteAction(tableId));
                            Swal.fire(
                              "Deleted!",
                              "Your file has been deleted.",
                              "success"
                            );
                          }
                        });
                      }}
                    />
                  </MenuItem>
                </Box>
              </Menu>
            </TableCell> */}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter />
    </Table>
  );
}

export default EmployeePaySlipTable;
