import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { hrEmployeeHolidayFindAction } from "../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";

function LeaveCalender({
  open,
  handleClose,
  handleSubmit,
  title,
  downloadurl,
  name,
}) {
  const [bulkUploadFile, setBulkUploadFile] = useState("");
  const [bulkUploadError, setBulkUploadError] = useState("");
  const [leaveCount, setLeaveCount] = useState({});
  const [leaveMonth, setLeaveMonth] = useState([]);

  const dispatch = useDispatch();

  const handleUpload = () => {
    if (bulkUploadFile == "") {
      setBulkUploadError("excel sheet is manadatory");
    } else {
      handleSubmit(bulkUploadFile, name);
      setBulkUploadError("");
      handleClose();
    }
  };

  const handleCloseButton = () => {
    setBulkUploadError("");
    setBulkUploadFile("");
    handleClose();
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const leaveDate = [
    { month: "JAN", totalLeaves: 3, dates: "JAN 1, 17, 23" },
    { month: "FEB", totalLeaves: 3, dates: "FEB 1, 17, 23" },
    { month: "MAR", totalLeaves: 3, dates: "MAR 1, 17, 23" },
    { month: "APR", totalLeaves: 3, dates: "APR 1, 17, 23" },
    { month: "MAY", totalLeaves: 3, dates: "MAY 1, 17, 23" },
    { month: "JUN", totalLeaves: 3, dates: "JUN 1, 17, 23" },
    { month: "JUL", totalLeaves: 3, dates: "JUL 1, 17, 23" },
    { month: "AUG", totalLeaves: 3, dates: "JUL 1, 17, 23" },
    { month: "SEP", totalLeaves: 3, dates: "JUL 1, 17, 23" },
    { month: "OCT", totalLeaves: 3, dates: "JUL 1, 17, 23" },
    { month: "NOV", totalLeaves: 3, dates: "JUL 1, 17, 23" },
    { month: "DEC", totalLeaves: 3, dates: "JUL 1, 17, 23" },
  ];

  let { hrEmployeeHolidaySuccess } = useSelector((state) => {
    return state.hrEmployeeHolidayFind;
  });

  useEffect(() => {
    dispatch(hrEmployeeHolidayFindAction());
  }, [dispatch]);

  const getHolidayMonthCounts = (holidays) => {
    if (!Array.isArray(holidays)) {
      console.error("Expected an array but got:", holidays);
      return {};
    }

    return holidays.reduce((acc, holiday) => {
      if (!holiday.Holiday_Month) {
        console.error("Holiday_Month is undefined for", holiday);
        return acc;
      }
      acc[holiday.Holiday_Month] = (acc[holiday.Holiday_Month] || 0) + 1;
      return acc;
    }, {});
  };

  // Getting holiday counts by month

  useEffect(() => {
    const holidayMonthCounts = getHolidayMonthCounts(hrEmployeeHolidaySuccess);
    setLeaveCount(holidayMonthCounts);
    let mySetWithValues = new Set(
      hrEmployeeHolidaySuccess?.map((value) => value.Holiday_Month)
    );
    let uniqueBrandsArray = Array.from(mySetWithValues);
    let result = uniqueBrandsArray.map((month) => ({
      monthName: month,
      leaveCount: holidayMonthCounts[month],
    }));

    setLeaveMonth(result);
    console.log(result, "resultresultresult");
  }, [hrEmployeeHolidaySuccess]);

  console.log(
    hrEmployeeHolidaySuccess,
    "hrEmployeeHolidaySuccesshrEmployeeHolidaySuccess"
  );

  function extractDatesByMonth(holidays, month) {
    let filteredHolidays = holidays.filter(
      (holiday) => holiday.Holiday_Month === month
    );

    let datesArray = filteredHolidays.map((holiday) => holiday.Holiday_Date);

    let daysArray = datesArray.map((date) => date.split("-")[2]);
    let commaSeparatedDays = daysArray.join(", ");

    return commaSeparatedDays;
  }

  function extractNameByMonth(holidays, month) {
    let filteredHolidays = holidays.filter(
      (holiday) => holiday.Holiday_Month === month
    );

    let holidaysInfo = filteredHolidays.map((holiday) => ({
      month: holiday.Holiday_Month,
      name: holiday.Holiday_Name,
      date: holiday.Holiday_Date,
    }));

    // let commaSeparatedInfo = holidaysInfo
    //   .map((info) => `${info.name} (${info.date})`)
    //   .join(", ");

    console.log(holidaysInfo, "holidaysInfoholidaysInfo");

    return holidaysInfo;
  }

  // setLeaveCalender()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "100%",
            width: "850px",
            mt: "0px",
          },
          "& .MuiDialog-container": {
            alignItems: "center",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#05050F",
                fontWeight: "700",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              Leave Calender
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "grid",
            gap: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "50px",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              {leaveMonth?.map((leave, index) => (
                <Accordion
                  key={leave.monthName}
                  expanded={expanded === leave.monthName}
                  onChange={handleChange(leave.monthName)}
                  sx={{
                    "&.MuiAccordion-root": {
                      background: "#FFFFFF",
                      mb: "20px",
                      borderColor: "#ECECEC",
                      borderRadius: "15px",
                    },
                    "& .MuiAccordionSummary-root": {
                      minHeight: "40px",
                    },
                    "& .MuiAccordionSummary-content": {
                      margin: "10px 0px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${leave.monthName}-content`}
                    id={`${leave.monthName}-header`}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Box sx={{ borderRight: "2px solid #EFEFEF", pr: 2 }}>
                        <Typography
                          sx={{
                            fontSize: "24px",
                            lineHeight: "46.75px",
                            color: "#FE0B7A",
                            fontWeight: "400",
                          }}
                        >
                          {leave.monthName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRight: "2px solid #EFEFEF",
                          pr: 2,
                          gap: 1,
                        }}
                      >
                        <Box>
                          <img
                            style={{ paddingRight: "13px" }}
                            src="/calendar-remove.png"
                            alt="calendar"
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#05050F",
                              fontWeight: "400",
                              letterSpacing: "0.44px",
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            Total Leaves : {leave.leaveCount}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box>
                          <img
                            style={{ paddingRight: "13px" }}
                            src="/calendar-remove.png"
                            alt="calendar"
                          />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              lineHeight: "24px",
                              color: "#05050F",
                              fontWeight: "400",
                              letterSpacing: "0.44px",
                              fontFamily: "'Montserrat', sans-serif",
                            }}
                          >
                            {leave.monthName}{" "}
                            {extractDatesByMonth(
                              hrEmployeeHolidaySuccess,
                              leave.monthName
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      background: "#FFFFFF",
                      borderTop: "1px solid #141E3C",
                      padding: "24px",
                      display: "grid",
                      gap: "20px",
                    }}
                  >
                    {extractNameByMonth(
                      hrEmployeeHolidaySuccess,
                      leave.monthName
                    ).map((value) => (
                      <Typography>
                        {value?.month} {value?.date.split("-")[2]}
                        {" - "}
                        {value?.name}
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LeaveCalender;
