// CreateBanner

import axios from "axios";
import {
  BANNER_CREATE_ERR,
  BANNER_CREATE_REQUEST,
  BANNER_CREATE_SUCCESS,
  BANNER_DELETE_ERR,
  BANNER_DELETE_REQUEST,
  BANNER_DELETE_SUCCESS,
  BANNER_FIND_ERR,
  BANNER_FIND_REQUEST,
  BANNER_FIND_SUCCESS,
} from "../constants/bannerContants";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

// Banner create action

export const bannerCreateAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: BANNER_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/bannerCreate",
      formData,
      config
    );

    dispatch({ type: BANNER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BANNER_CREATE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// Banner delete action

export const bannerDeleteAction = (id) => async (dispatch, getState) => {
  try {
    
    dispatch({ type: BANNER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/bannerDeletion?id=${id}`,
      config
    );

    dispatch({ type: BANNER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BANNER_DELETE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// Banner list action

export const bannerListAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BANNER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get("/api/admin/landingPage/bannerList",config);

    dispatch({ type: BANNER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BANNER_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
