import {
  Box,
  Typography,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";

function AssignedStudentTable({
  data,
  columns,
  selectBoxIdPassHandler,
  columnVisibility,
  pageNumber,
}) {
  useEffect(() => {
    if (pageNumber > 10) {
      setRowsPerPage(pageNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [pageNumber]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Sort icon

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData?.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:

  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <Box sx={{ overflow: "hidden" }}>
      <Typography
        sx={{
          backgroundColor: "#fff",
          padding: "5px",
          paddingRight: "0px",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        Showing{" "}
        <Typography
          component={"span"}
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingLeft: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            // lineHeight: "24px",
            fontWeight: "700",
          }}
        >
          {
            sortedData?.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )?.length
          }{" "}
          Result
        </Typography>
      </Typography>

      <Box
        sx={{
          overflowX: "scroll",
          maxWidth: "100%",
        }}
      >
        <Table className="masterTable" sx={{ minWidth: "1500px" }}>
          <TableHead
            sx={{
              background: "#F9F9FB",
            }}
          >
            <TableRow className="msterTableCell">
              <TableCell>
                <Checkbox
                  checked={selectedRows?.length === sortedData?.length}
                  onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                let visibility = columnVisibility.find(
                  (visible) => visible.title === column.title
                );
                return (
                  visibility?.value && (
                    <TableCell key={column.field}>
                      <TableSortLabel
                        className="headerText"
                        active={column === sortBy}
                        direction={sortOrder}
                        onClick={() => requestSort(column)}
                        IconComponent={
                          column.field !== "action" && CustomSortIcon
                        }
                      >
                        {column.title}
                      </TableSortLabel>
                    </TableCell>
                  )
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, indx) => (
                <TableRow className="tableRow" key={indx}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(item.id)}
                      onChange={() => handleSelectRow(item.id)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  {columnVisibility[0]?.value && (
                    <TableCell>{indx + 1}</TableCell>
                  )}
                  {columnVisibility[1]?.value && (
                    <TableCell>
                      {item?.ielts_entrolled_student?.fullName}
                    </TableCell>
                  )}
                  {columnVisibility[2]?.value && (
                    <TableCell>{item?.ielts_entrolled_student?.dob}</TableCell>
                  )}
                  {columnVisibility[3]?.value && (
                    <TableCell>
                      {item?.ielts_entrolled_student?.gender}
                    </TableCell>
                  )}
                  {columnVisibility[4]?.value && (
                    <TableCell>
                      {item?.ielts_entrolled_student?.email}
                    </TableCell>
                  )}
                  {columnVisibility[5]?.value && (
                    <TableCell>
                      {item?.ielts_entrolled_student?.phoneNumber}
                    </TableCell>
                  )}
                  {columnVisibility[6]?.value && (
                    <TableCell>
                      {item?.ielts_entrolled_student?.whatsAppNumber}
                    </TableCell>
                  )}
                  {columnVisibility[7]?.value && (
                    <TableCell>{item?.ielts_course?.CourseName}</TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter />
        </Table>
      </Box>
      <Pagination
        sx={{
          mt: "28px",
          "& .Mui-selected": {
            backgroundColor: "#f81b82 !important",
            color: "#fff",
          },
          "& .MuiPagination-ul": {
            justifyContent: "end",
          },
        }}
        count={Math.ceil(data?.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
      />
    </Box>
  );
}

export default AssignedStudentTable;
