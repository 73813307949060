import { KeyboardBackspace } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainContainer } from "../../content/content.element";
import { partnerNotificationFindOneAction } from "../../../actions/partnerAction/partnerAction";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

function NotificationSingle({ toggle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [data, setData] = useState();

  let { partnerNotificationFindOneSuccess } = useSelector(
    (state) => state.partnerNotificationFindOne
  );

  useEffect(() => {
    dispatch(partnerNotificationFindOneAction(id));
  }, []);

  useEffect(() => {
    if (partnerNotificationFindOneSuccess) {
      console.log(partnerNotificationFindOneSuccess);
      setData(partnerNotificationFindOneSuccess.data);
    }
  }, [partnerNotificationFindOneSuccess]);

  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          borderBottom: "1px solid rgba(236, 236, 236, 1)",
          mb: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontWeight: "700",
            fontSize: "24px",
            pt: "10px",
            pb: "10px",
            width: "fit-content",
            cursor: "pointer",
          }}
        >
          <KeyboardBackspace onClick={() => navigate(-1)} />
          Notification Details
        </Box>
      </Box>
      <Box
        sx={{
          mt: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "150px 1fr",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            Recipient :
          </Typography>
          <Typography
            sx={{
              color: "#838383",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            {data?.selectedPartner ? data?.selectedPartner : data?.targetedUser}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "150px 1fr",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            Date :
          </Typography>
          <Typography
            sx={{
              color: "#838383",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            {data?.createdAt
              ? format(parseISO(data?.createdAt), "dd-MM-yyyy")
              : null}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "150px 1fr",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            Subject :
          </Typography>
          <Typography
            sx={{
              color: "#838383",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            {data?.subject}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "150px 1fr",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            Message :
          </Typography>
          {/* <Typography
            sx={{
              color: "#838383",
              fontSize: "14px",
              lineHeight: "21px",
              maxWidth: "1090px",
            }}
          >
          </Typography> */}
          <Box
            sx={{
              "& *": {
                padding: 0,
                margin: 0,
                color: "#838383",
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "150px 1fr",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            Attachments :
          </Typography>
          <Box
            sx={{
              maxWidth: "240px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                padding: "28px 88px",
                backgroundColor: "#F8F8F8",
                border: "1px solid #EFEFEF",
                borderBottom: "0",
                width: "fit-content",
              }}
            >
              <svg
                width="60"
                height="78"
                viewBox="0 0 60 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.9874 0H37.0653L59.1673 23.0375V67.8456C59.1673 73.4588 54.6261 78 49.0323 78H10.9874C5.37422 78 0.833008 73.4588 0.833008 67.8456V10.1544C0.832909 4.54121 5.37412 0 10.9874 0Z"
                  fill="#E5252A"
                />
                <path
                  opacity="0.302"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.0459 0V22.8621H59.1674L37.0459 0Z"
                  fill="white"
                />
                <path
                  d="M12.1182 58.2005V43.9531H18.1797C19.6804 43.9531 20.8694 44.3624 21.7659 45.2005C22.6624 46.0191 23.1107 47.1301 23.1107 48.5138C23.1107 49.8976 22.6624 51.0086 21.7659 51.8272C20.8694 52.6653 19.6804 53.0746 18.1797 53.0746H15.7628V58.2005H12.1182ZM15.7628 49.9757H17.7704C18.3161 49.9757 18.7449 49.8587 19.0373 49.5859C19.3296 49.3325 19.4856 48.9817 19.4856 48.5139C19.4856 48.0462 19.3297 47.6954 19.0373 47.442C18.745 47.1691 18.3161 47.0522 17.7704 47.0522H15.7628V49.9757ZM24.6114 58.2005V43.9531H29.6594C30.6534 43.9531 31.5889 44.0895 32.466 44.3819C33.343 44.6742 34.1421 45.0836 34.8437 45.6488C35.5454 46.1945 36.1106 46.9351 36.5199 47.8707C36.9097 48.8063 37.1242 49.8782 37.1242 51.0866C37.1242 52.2755 36.9098 53.3475 36.5199 54.2829C36.1106 55.2185 35.5454 55.9591 34.8437 56.5048C34.1421 57.07 33.343 57.4793 32.466 57.7717C31.5889 58.064 30.6534 58.2005 29.6594 58.2005H24.6114ZM28.1781 55.1016H29.2306C29.7958 55.1016 30.322 55.0432 30.8093 54.9067C31.277 54.7703 31.7253 54.5558 32.1541 54.2635C32.5634 53.9712 32.8948 53.5618 33.1286 53.0161C33.3625 52.4704 33.4795 51.8272 33.4795 51.0866C33.4795 50.3264 33.3625 49.6833 33.1286 49.1376C32.8948 48.5919 32.5634 48.1826 32.1541 47.8902C31.7253 47.5979 31.2771 47.3834 30.8093 47.2471C30.322 47.1107 29.7958 47.0521 29.2306 47.0521H28.1781V55.1016ZM38.9563 58.2005V43.9531H49.0912V47.0521H42.6009V49.3324H47.7853V52.4119H42.6009V58.2005H38.9563Z"
                  fill="white"
                />
              </svg>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                border: "1px solid #EFEFEF",
                borderTop: "1px solid #EFEFEF",
                padding: "16px 16px 7px 16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "600",
                }}
              >
                Uploaded Documents
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  mt: "10px",
                }}
              >
                <Typography
                  target="_blank"
                  component={"a"}
                  sx={{
                    color: "#141E3C",
                    fz: "16px",
                    lineHeight: "24px",
                    cursor: "pointer",
                    textDecoration:"none"
                  }}
                  href={data?.attchedFile}
                >
                  View
                </Typography>
                <Typography
                  target="_blank"
                  component={"a"}
                  sx={{
                    color: "#FE0B7A",
                    fz: "16px",
                    lineHeight: "24px",
                    textDecoration:"none",
                    cursor: "pointer",
                  }}
                  href={data?.attchedFile}
                >
                  Download
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default NotificationSingle;
