import React, { useState } from "react";
import { MainContainer } from "../content/content.element";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import BasicTabs from "../Tabs/TabsMain";
import { Box, Grid } from "@mui/material";
import { SubmitButton } from "../customComponent/Buttons";
import { useNavigate } from "react-router-dom";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

let index;
function LandingPageMain(props) {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState("");
  const { isCreatePrevilage } = usePrivilegeCheck();

  const handleIndex = (newvalue) => {
    index = newvalue;
    setTabIndex(index);
  };

  console.log(props.tabLabels[index], "indexxx");

  return (
    <MainContainer active={props.toggle}>
      <Box>
        <HeadBreadcrumbs
          head="Home"
          sechead="Landing Page"
          first="Landing Page"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4} lg={12} style={{ position: "relative" }}>
          <Box
            sx={{
              "& [aria-label='basic tabs example']": {
                width: "1510px",
              },
              "& .MuiTabs-scroller::-webkit-scrollbar ": {
                // width:"5px",
                // display: "none",
                // width: "5px",
                height: "2px",
                // WebkitBorderRadius: "10px",
                // borderRadius: "10px",
                background: "rgba(20, 30, 60, 0.9)",
              },
              // "& .MuiTabs-scroller::-webkit-scrollbar-thumb": {
              //   WebkitBorderRadius: "10px",
              //   borderRadius: "10px",
              //   background: "#6d6d6d",
              // },
              "& .MuiTabs-scroller": {
                maxWidth: "calc(100% - 120px)",
                overflowX: "scroll !important",
              },
            }}
          >
            <BasicTabs
              tab1Name="Top navigation"
              tab2Name="Destination"
              tab3Name="Services"
              tab4Name="News"
              tab5Name="Events"
              tab6Name="Blogs"
              tab7Name="Testimonials"
              tab8Name="Featured Ads"
              tab9Name="Talk To Expert"
              tab10Name="Scholarship List"
              tab11Name="Office List"
              tab12Name="Gallery"
              // tab13Name="Contact"
              tab1={props.tab1}
              tab2={props.tab2}
              tab3={props.tab3}
              tab4={props.tab4}
              tab5={props.tab5}
              tab6={props.tab6}
              tab7={props.tab7}
              tab8={props.tab8}
              tab9={props.tab9}
              tab10={props.tab10}
              tab11={props.tab11}
              tab12={props.tab12}
              tab13={props.tab13}
              callback={handleIndex}
            />
          </Box>
        </Grid>

        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "35px",
            position: "absolute",
            right: 0,
            marginRight: "18px",
          }}
        >
          {isCreatePrevilage(props.tabLabels[index]) && (
            <SubmitButton
              title="Create"
              submit=""
              widthSize="100px"
              heightSize="38px"
              type="click"
              handleSubmit={() => {
                navigate(
                  `/landingPage${
                    index == "0" || index == undefined
                      ? props.url1
                      : index == "1"
                      ? props.url2
                      : index == "2"
                      ? props.url3
                      : index == "3"
                      ? props.url4
                      : index == "4"
                      ? props.url5
                      : index == "5"
                      ? props.url6
                      : index == "6"
                      ? props.url7
                      : index == "7"
                      ? props.url8
                      : index == "8"
                      ? props.url9
                      : index == "9"
                      ? props.url10
                      : index == "10"
                      ? props.url11
                      : index == "11"
                      ? props.url12
                      : index == "12"
                      ? props.url13
                      : ""
                  }`
                );
              }}
            />
          )}
        </Grid>
      </Grid>
    </MainContainer>
  );
}

export default LandingPageMain;
