import React from "react";
import BasicTabs from "../../../../../Tabs/TabsMain";
import { Box } from "@mui/material";
import OnlineTabItem from "./OnlineTabItem";

function OnlineTab({ selectedTab }) {

  return (
    <Box
      sx={{
        mt: "-30px",
        ml: "-10px",
        mr: "-10px",
      }}
    >
      <BasicTabs
        // selected={true}
        tab1Name={selectedTab ? selectedTab.speaking && "Speaking" : null}
        tab2Name={selectedTab ? selectedTab.reading && "Reading" : null}
        tab3Name={selectedTab ? selectedTab.listening && "Listening" : null}
        tab4Name={selectedTab ? selectedTab.writing && "Writing" : null}
        tab5Name={selectedTab ? selectedTab.grammar && "Grammar" : null}
        tab1={
          selectedTab.speaking ? (
            <OnlineTabItem type={"Speaking"} />
          ) : selectedTab.reading ? (
            <OnlineTabItem type={"Reading"} />
          ) : selectedTab.listening ? (
            <OnlineTabItem type={"Listening"} />
          ) : selectedTab.writing ? (
            <OnlineTabItem type={"Writing"} />
          ) : selectedTab.grammar ? (
            <OnlineTabItem type={"Grammar"} />
          ) : (
            <></>
          )
        }
        tab2={
          selectedTab.reading ? (
            <OnlineTabItem type={"Reading"} />
          ) : selectedTab.listening ? (
            <OnlineTabItem type={"Listening"} />
          ) : selectedTab.writing ? (
            <OnlineTabItem type={"Writing"} />
          ) : selectedTab.grammar ? (
            <OnlineTabItem type={"Grammar"} />
          ) : (
            <></>
          )
        }
        tab3={
          selectedTab.listening ? (
            <OnlineTabItem type={"Listening"} />
          ) : selectedTab.writing ? (
            <OnlineTabItem type={"Writing"} />
          ) : selectedTab.grammar ? (
            <OnlineTabItem type={"Grammar"} />
          ) : (
            <></>
          )
        }
        tab4={
          selectedTab.writing ? (
            <OnlineTabItem type={"Writing"} />
          ) : selectedTab.grammar ? (
            <OnlineTabItem type={"Grammar"} />
          ) : (
            <></>
          )
        }
        tab5={<OnlineTabItem type={"Grammar"} />}
      />
    </Box>
  );
}

export default OnlineTab;
