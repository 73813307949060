import {
  PARTNER_APPROVAL_STATUS_UPDATE_ERR,
  PARTNER_APPROVAL_STATUS_UPDATE_REQUEST,
  PARTNER_APPROVAL_STATUS_UPDATE_SUCCESS,
  PARTNER_ENQUIRY_FIND_ERR,
  PARTNER_ENQUIRY_FIND_REQUEST,
  PARTNER_ENQUIRY_FIND_SUCCESS,
} from "../../constants/partnerConstant";

// findEnquiriedPartnerReducer
export const findEnquiriedPartnerReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_ENQUIRY_FIND_REQUEST:
      return {
        ...state,
        partnerEnquiryFindLoading: true,
      };
    case PARTNER_ENQUIRY_FIND_SUCCESS:
      return {
        ...state,
        partnerEnquiryFindLoading: false,
        partnerEnquiryFindSuccess: action.payload,
      };
    case PARTNER_ENQUIRY_FIND_ERR:
      return {
        ...state,
        partnerEnquiryFindLoading: false,
        partnerEnquiryFindError: action.payload,
      };
    default:
      return state;
  }
};


// partnerApprovalStatusUpdateReducer
export const partnerApprovalStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_APPROVAL_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        partnerEnquiryStatusLoading: true,
      };
    case PARTNER_APPROVAL_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        partnerEnquiryStatusLoading: false,
        partnerEnquiryStatusSuccess: action.payload,
      };
    case PARTNER_APPROVAL_STATUS_UPDATE_ERR:
      return {
        ...state,
        partnerEnquiryStatusLoading: false,
        partnerEnquiryStatusError: action.payload,
      };
    default:
      return state;
  }
};