import { Box } from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from "recharts";

function UniversityApplication() {
  const data = [
    {
      name: "Kerala University",
      tl: 130,
    },
    {
      name: "MG University",
      tl: 140,
    },
    {
      name: "NIT Calicut",
      tl: 100,
    },
    {
      name: "CUSAT",
      tl: 170,
    },
    {
      name: "Calicut University",
      tl: 150,
    },
    {
      name: "University College",
      tl: 130,
    },
    {
      name: "Rajagiri College of Social Sciences",
      tl: 160,
    },
    {
      name: "St. Teresa’s College",
      tl: 200,
    },
    {
      name: "Mar Ivanios College",
      tl: 120,
    },
    {
      name: "Maharaja’s College",
      tl: 220,
    },
    {
      name: "Bishop Moore College",
      tl: 290,
    },
    {
      name: "St. Thomas College",
      tl: 130,
    },
    {
      name: "SB College",
      tl: 210,
    },
    {
      name: "St. Joseph’s College",
      tl: 260,
    },
    {
      name: "Govt. College for Women",
      tl: 80,
    },
    {
      name: "UC College",
      tl: 200,
    },
    {
      name: "CMS College",
      tl: 50,
    },
    {
      name: "MA College",
      tl: 150,
    },
    {
      name: "NIT",
      tl: 170,
    },
    {
      name: "IISST",
      tl: 300,
    },
    {
      name: "IIT",
      tl: 220,
    },
    {
      name: "IIM",
      tl: 300,
    },
    {
      name: "NIT",
      tl: 220,
    },
    {
      name: "Rajagiri Business School",
      tl: 130,
    },
    {
      name: "SCTIMST",
      tl: 210,
    },
    {
      name: "Govt. Medical College",
      tl: 150,
    },
    {
      name: "Govt. Dental College",
      tl: 210,
    },
    {
      name: "NIT",
      tl: 160,
    },
    {
      name: "College of Engineering",
      tl: 290,
    },
    {
      name: "KUFOS",
      tl: 210,
    },
  ];

  return (
    <Box
      sx={{
        mt: "30px",
        height: "300px",
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={1000}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend />
          <YAxis dataKey="tl" />
          <Bar barSize={12} dataKey="tl" stackId="a" fill="#2D2F7B">
            {/* <LabelList dataKey="name" width={"100%"} /> */}
            <LabelList
              dataKey="name"
              position="top"
              style={{
                fontSize: "8px",
                display:"flex",
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default UniversityApplication;
