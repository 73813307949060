import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

import Select from "react-select";
import { City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";

import { findcountryMasterAddAction } from "../../../../actions/countryMasterAction";
import { findStateMasterAction } from "../../../../actions/StateMasterAction";
import { cityMasterAddAction } from "../../../../actions/cityManagmentAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import { TypographyText } from "../../../customComponent/Typography";
import { successTrueMsgAction } from "../../../../actions/globalAction";

// const allStates = stateFindSuccess.map((city) => ({
//   value: city.stateName,
// }));

// const allStates = State.getAllStates();

function AddCity({ status, addMasterCategryHandler }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [states, setStates] = useState([]);
  const [error, setError] = useState({});

  const [stateName, setStateName] = useState([]);
  const [stateId, setStateId] = useState("");

  // selectors

  const { stateFindLoading, stateFindSuccess, stateFindErr } = useSelector(
    (state) => {
      return state.findStateMaster;
    }
  );

  useEffect(() => {
    dispatch(findStateMasterAction());
  }, [dispatch]);

  useEffect(() => {
    setStateName(stateFindSuccess);
  }, [stateFindSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch]);

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setErrorMsg(false);
    setStateId(selectedOption.value.id);
    setSelectedState(selectedOption);
    console.log(selectedOption,"cdcsdcsdcsdcsdcd")
    const stateCities = City.getCitiesOfState(
      selectedOption.value.countryCode,
      selectedOption.value.isoCode
    );
    setStates(stateCities);
    setSelectedCity(null);
    setCountryError(false);
    setError((prev) => ({
      ...prev,
      ["state"]: "",
    }));
  };

  const handleClose = () => {
    addMasterCategryHandler(status, false);
    setOpen(false);
  };

  const validate = () => {
    let error = {};

    if (!selectedState) {
      error.state = "please select a state";
    }

    if (!selectedCity) {
      error.city = "please select a city";
    }

    setError(error);
    return Object.keys(error).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      if (
        selectedState &&
        selectedCity &&
        selectedState !== null &&
        selectedCity !== null
      ) {
        dispatch(cityMasterAddAction(selectedState, selectedCity, stateId));
        dispatch(successTrueMsgAction(true));
        addMasterCategryHandler(status, false);
        setOpen(false);
        setSelectedState(null);
        setErrorMsg(false);
        setOpen(false);
      } else {
        setErrorMsg(true);
      }
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <TypographyText title="Add New City" variant="h5" />
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose City
                </Typography>
              )}
              {countryError && (
                <Typography align="center" color="error">
                  Please Choose city You Added
                </Typography>
              )}
              <Select
                options={stateFindSuccess?.map((state) => ({
                  value: state,
                  label: state.stateName,
                }))}
                value={selectedState}
                onChange={handleStateChange}
                placeholder="Select a state"
              />
              {Boolean(error?.state) && (
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                  color="error"
                >
                  {error?.state}
                </Typography>
              )}

              <h3>Select City:</h3>
              <Select
                options={states.map((state) => ({
                  value: state.isoCode,
                  label: state.name,
                }))}
                value={selectedCity}
                onChange={(selectedOption) => {
                  setSelectedCity(selectedOption);
                  setError((prev) => ({
                    ...prev,
                    ["city"]: "",
                  }));
                }}
                isDisabled={!selectedState}
                placeholder="Select a city"
              />
              {Boolean(error?.city) && (
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                  color="error"
                >
                  {error?.city}
                </Typography>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AddCity;
