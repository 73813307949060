import React, { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Alert,
  AlertTitle,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

import "./LandingTable.css";

import {
  blogDeleteAction,
  deleteOfficeByIdAction,
  eventDeleteAction,
  featuredAdsDeleteAction,
  newsDeleteAction,
  testimonialsDeleteAction,
} from "../../actions/LandingPageAction";
import PremissionDialog from "../customComponent/premissionDialog";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

export const LandingTable = ({
  columns,
  data,
  component,
  subMenu,
  //   isPresentMap,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // custom Hooks
  const { privilege, isUpdatePrevilage, isDeletePrevilage } =
    usePrivilegeCheck();

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // Delete Table

  const handleDeleteNavigation = (id) => {
    if (component === "testimonialsJob") {
      dispatch(testimonialsDeleteAction(id));
    }

    if (component === "featuredAds") {
      dispatch(featuredAdsDeleteAction(id));
    }
    if (component == "blogTab") {
      dispatch(blogDeleteAction(id));
    }

    if (component == "events") {
      dispatch(eventDeleteAction(id));
    }

    if (component == "officeTab") {
      dispatch(deleteOfficeByIdAction(id));
    }

    if (component == "news") {
      dispatch(newsDeleteAction(id));
    }
  };

  // navigate to Edit page

  const topNavigationEditHandler = (id) => {
    if (component == "blogTab") {
      navigate(`/landingPage/blogPageEdit/${id}`);
    }
    if (component == "serviceTab") {
      navigate(`/landingPage/serviceEditForm/${id}`);
    }
    if (component == "news") {
      navigate(`/landingPage/newsEditForm/${id}`);
    }

    if (component == "events") {
      navigate(`/landingPage/eventsEditForm/${id}`);
    }

    if (component === "testimonialsJob") {
      navigate(`/landingPage/testmonialsEditForm/${id}`);
    }

    if (component === "featuredAds") {
      navigate(`/landingPage/featuredAdsEditForm/${id}`);
    }

    if (component === "officeTab") {
      navigate(`/landingPage/officeEditForm/${id}`);
    }
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };
  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  //   const handleClose = () => setOpen(false);

  // Create a function to handle selecting individual rows:

  // if (!hasPrivilege("isLandingView") && privilege !== null) {
  //   return (
  //     <Alert severity="error">
  //       <AlertTitle>warning</AlertTitle>
  //       You don't have permission to access — <strong>this page.!</strong>
  //     </Alert>
  //   );
  // }

  // Close dialog Box

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  return (
    <>
      {sortedData?.length > 0 ? (
        <Table className="landingTable">
          <TableHead sx={{ backgroundColor: "#F9F9FB" }}>
            <TableRow className="landingTableCell">
              <TableCell>
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell key={column.field} className="landingTableCell">
                    <TableSortLabel
                      className="landingHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              {/* <TableCell className="landingTableCell"></TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedData?.map((item, indx) => (
              <TableRow className="landingTable" key={indx}>
                <TableCell>
                  <Checkbox
                    // checked={selectedRows.includes(item.id)}
                    // onChange={() => handleSelectRow(item.id)}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>

                <TableCell>{item.sl}</TableCell>

                {item.coloum4 === "image" ? (
                  <TableCell>
                    <img src={item.coloum1} alt="gsl" width="50px" />
                  </TableCell>
                ) : (
                  item.coloum1 && <TableCell>{item.coloum1}</TableCell>
                )}

                {item.coloum2 && <TableCell>{item.coloum2}</TableCell>}

                {item.coloum3 && <TableCell>{item.coloum3}</TableCell>}

                {item.coloum4 && item.coloum4 !== "image" && (
                  <TableCell>{item.coloum4}</TableCell>
                )}

                {item.coloum5 == "View list" ? (
                  <TableCell>
                    <Box
                      sx={{
                        bgcolor: "#E3EAFF",
                        width: "80px",
                        height: "24px",
                        borderRadius: "10px",
                        textAlign: "center",
                        fontSize: "14px",
                        boxShadow: "-48px -14px 141px -92px rgba(0,0,0,0.75);",
                      }}
                    >
                      {item.coloum5}
                    </Box>
                  </TableCell>
                ) : (
                  item.coloum5 && (
                    <TableCell sx={{ maxWidth: "350px" }}>
                      {item.coloum5}
                    </TableCell>
                  )
                )}
                {item.coloum6 && <TableCell>{item.coloum6}</TableCell>}
                <TableCell>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      openActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openActionButton ? "true" : undefined}
                    onClick={(e) => {
                      handleClickAction(e, item.id);
                    }}
                    variant="outlined"
                    style={{ marginLeft: "10px", color: "#F80B7A" }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter />
        </Table>
      ) : (
        <TableNoItemComponent />
      )}
      {/* TABLE ACTION MENU START*/}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <TbEdit
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              onClick={() => {
                if (isUpdatePrevilage(subMenu)) {
                  topNavigationEditHandler(tableId);
                } else {
                  setOpenAlert(true);
                }
              }}
              className="icons"
            />
          </MenuItem>

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => {
                if (isDeletePrevilage(subMenu)) {
                  setActionButtonShow(false);
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleDeleteNavigation(tableId);
                      Swal.fire(
                        "Deleted!",
                        "Your file has been deleted.",
                        "success"
                      );
                    }
                  });
                } else {
                  setOpenAlert(true);
                }
              }}
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
              className="icons"
            />
          </MenuItem>
        </Box>
      </Menu>
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};
