import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import ExamTable from "./ExamTable";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentExamtipsFindAction } from "../../../../actions/IeltsActions/courseManagmentAction";
import usePrivilegeCheck from "../../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../../customComponent/premissionDialog";

function ExamTipsMain({ subMenu }) {
  const dispatch = useDispatch();

  const [examTipsList, setExamTipsList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  let {
    ieltsCourseManagmentExamtipsFindSuccess,
    ieltsCourseManagmentExamtipsFinderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamTipsFind;
  });
  let {
    ieltsCourseManagmentExamtipsCreateSuccess,
    ieltsCourseManagmentExamtipsCreateerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamTipsCreate;
  });

  let {
    ieltsCourseManagmentExamtipsStatusSuccess,
    ieltsCourseManagmentExamtipsStatuserror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamTipsStatusChange;
  });

  let {
    ieltsCourseManagmentExamtipsDeleteSuccess,
    ieltsCourseManagmentExamtipsDeleteerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamtipsDelete;
  });

  let {
    ieltsCourseManagmentExamtipsUpdateSuccess,
    ieltsCourseManagmentExamtipsUpdateerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamTipsUpdate;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentExamtipsFindAction());
  }, [
    dispatch,
    ieltsCourseManagmentExamtipsStatusSuccess,
    ieltsCourseManagmentExamtipsCreateSuccess,
    ieltsCourseManagmentExamtipsDeleteSuccess,
    ieltsCourseManagmentExamtipsUpdateSuccess,
  ]);

  useEffect(() => {
    if (ieltsCourseManagmentExamtipsFindSuccess) {
      setExamTipsList(ieltsCourseManagmentExamtipsFindSuccess.data);
    }
  }, [dispatch, ieltsCourseManagmentExamtipsFindSuccess]);

  console.log(
    ieltsCourseManagmentExamtipsStatusSuccess,
    "ieltsCourseManagmentExamtipsStatusSuccessieltsCourseManagmentExamtipsStatusSuccess"
  );

  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "TITLE", field: "title" },
    { title: "CATEGORY", field: "category Name" },
    { title: "STATUS", field: "status" },
  ];

  const [secListopen, setSecListOpen] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [categoryList, setCategoyList] = useState([
    "Listening",
    "Reading",
    "Speaking",
    "Writing",
    "Grammar",
    "Exam Tips",
    "Question Bank",
  ]);

  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus) => {
    setSelectedCategory(filterValue === selectedCategory ? null : filterValue);
    if (checkedStatus) {
      setExamTipsList(
        ieltsCourseManagmentExamtipsFindSuccess?.data?.filter(
          (topics, index) => {
            return filterValue == topics.category;
          }
        )
      );
    } else {
      setExamTipsList(ieltsCourseManagmentExamtipsFindSuccess.data);
    }
  };

  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setExamTipsList(ieltsCourseManagmentExamtipsFindSuccess?.data);
    setSelectedCategory(null);
  };

  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };

  const [ids, setIds] = useState([]);

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };

  return (
    <>
      <Box
        sx={{
          mt: 2,
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            lg: "330px 1fr",
          },
          gap: "25px",
        }}
      >
        <Box>
          {selectedCategory && (
            <Chip
              sx={{
                "&.MuiChip-root": {
                  pl: "5px",
                  pr: "5px",
                },
              }}
              label={
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "700",
                    color: "rgba(88, 94, 106, 1)",
                  }}
                >
                  Category:{" "}
                  <Typography
                    sx={{
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                    component={"span"}
                  >
                    {selectedCategory}
                  </Typography>
                </Typography>
              }
              onDelete={handleClearAllFunction}
            />
          )}
          <Box
            sx={{
              " & .MuiListSubheader-root": {
                backgroundColor: "#F2F2F2",
                mt: "24px ",
              },
            }}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>Filter</div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      //   onClick={handleClearClick}
                    >
                      <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                      <Typography onClick={handleClearAllFunction}>
                        Clear All
                      </Typography>
                    </div>
                  </div>
                </ListSubheader>
              }
            >
              <Collapse
                in={secListopen}
                timeout="auto"
                unmountOnExit
                sx={{
                  mt: "20px",
                }}
              >
                <List component="div" disablePadding>
                  <ListItemButton>
                    <FormGroup
                      sx={{
                        "& .MuiFormControlLabel-root": {
                          width: "100%",
                        },
                        width: "100%",
                      }}
                    >
                      {categoryList &&
                        categoryList?.map((category, i) => (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A",
                                  },
                                }}
                                checked={category === selectedCategory}
                                onChange={(e) => {
                                  handleFilterFunction(
                                    category,
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                            label={category}
                          />
                        ))}
                    </FormGroup>
                  </ListItemButton>
                </List>
              </Collapse>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  mt: "20px",
                  "& input": {
                    width: "30px",
                    outline: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                  }}
                >
                  Rows Per Page :{" "}
                </Typography>
                <input type="number" value={page} onChange={handlePageSearch} />
              </Box>
              {pageError && (
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "red",
                  }}
                >
                  {pageError}
                </Typography>
              )}
            </List>
          </Box>
        </Box>

        <ExamTable
          data={examTipsList}
          columns={columns}
          component="ieltsCategory"
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          pageNumber={page}
          subMenu={subMenu}
        />
      </Box>
    </>
  );
}

export default ExamTipsMain;
