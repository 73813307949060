import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import InputField, { SelectInputField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { createOfficeAction } from "../../../actions/LandingPageAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";

function CreateOfficeList(props) {
  const dispatch = useDispatch();
  let { addNewOfficeSuccess, addNewOfficeErr } = useSelector((state) => {
    return state.addNewOffice;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (addNewOfficeSuccess && successAlertMsgSuccess) {
      setFormData({
        officeName: "",
        address: "",
        phone: "",
        email: "",
        location: "",
        direction: "",
      });
      Swal.fire("created!", addNewOfficeSuccess.message, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [addNewOfficeSuccess]);
  
  useEffect(() => {
    if (addNewOfficeErr && successAlertMsgSuccess) {
      Swal.fire("Something Went Wrong!", addNewOfficeErr.message, "error");
      dispatch(successTrueMsgAction(false));
    }
  }, [addNewOfficeErr]);

  const [formData, setFormData] = useState({
    officeName: "",
    address: "",
    phone: "",
    email: "",
    location: "",
    direction: "",
  });
  const [errorData, setErrorData] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorData((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validate = () => {
    let error = {};
    if (!formData.officeName) {
      error.officeName = "please enter a name";
    }
    if (!formData.address) {
      error.address = "please enter the address";
    }
    if (!formData.phone) {
      error.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      error.phone = "Phone number must be 10 digits";
    }
    if (!formData.email) {
      error.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      error.email = "Invalid email address";
    }
    if (!formData.direction) {
      error.direction = "Please enter a location";
    }
    if (!formData.location) {
      error.location = "Please enter a location";
    }

    setErrorData(error);
    console.log(error);
    return Object.keys(error).length === 0;
  };

  const submitForm = () => {
    if (validate()) {
      dispatch(createOfficeAction(formData));
      dispatch(successTrueMsgAction(true));
    }
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Add New Office" url={"/landingPage"} />
      </div>
      <Divider />

      <Box
        sx={{
          pt: "20px",
          "& input": {
            maxWidth: "calc(100% - 12px)",
          },
          display: "grid",
          gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
          gap: "20px",
          "& .input-group": {
            mb: "0",
          },
          "& .input-group ~ div": {
            mt: "0",
          },
        }}
      >
        <InputField
          label="Office Name"
          value={formData?.officeName}
          error={Boolean(errorData.officeName)}
          name="officeName"
          helperText={errorData.officeName}
          handleChange={handleChange}
          required
          paramses
        ></InputField>
        <InputField
          label="Address"
          value={formData?.address}
          error={Boolean(errorData.address)}
          name="address"
          helperText={errorData.address}
          handleChange={handleChange}
          paramses
        ></InputField>
        <InputField
          label="Phone"
          value={formData?.phone}
          error={Boolean(errorData.phone)}
          name="phone"
          helperText={errorData.phone}
          handleChange={handleChange}
          required
          paramses
        ></InputField>
        <InputField
          label="Email"
          value={formData?.email}
          error={Boolean(errorData.email)}
          name="email"
          helperText={errorData.email}
          handleChange={handleChange}
          required
          paramses
        ></InputField>
        <InputField
          label="Direction"
          value={formData?.direction}
          error={Boolean(errorData.direction)}
          name="direction"
          helperText={errorData.direction}
          handleChange={handleChange}
          paramses
        ></InputField>
        <Box>
          <SelectInputField
            label="Location"
            value={formData?.location}
            name="location"
            handleSelectChange={handleChange}
            mapValue={["India", "Kerela", "International"]}
            mapMethod={"office"}
          ></SelectInputField>
          {Boolean(errorData.location) && (
            <Box
              sx={{
                ml: "4px",
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  color: "#FF2400",
                  fontSize: "12px",
                }}
              >
                {errorData.location}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: "20px" }}></Box>

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            background: "#141E3C",
            border: "1px solid #141E3C",
            padding: "5px 20px",
            borderRadius: "12px",
            fontSize: "14px",
            fontWeight: "700",
            textTransform: "capitalize",
            color: "#FFF",
            "&:hover": {
              color: "#141E3C",
              backgroundColor: "#fff",
            },
          }}
          onClick={submitForm}
        >
          Create
        </Button>
      </Box>
    </MainContainer>
  );
}

export default CreateOfficeList;
