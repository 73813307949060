import React, { useState } from "react";
import { MainContainer } from "../content/content.element";
import { useNavigate } from "react-router-dom";
import { InfoHeader } from "../customComponent/InfoHeader";
import { Divider, Grid } from "@mui/material";
import { SubmitButton } from "../customComponent/Buttons";
import { hrEmployeeJobCreateAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch } from "react-redux";
import InputField from "../customComponent/InputField";
import { TopNavigationCreateAction } from "../../actions/LandingPageAction";

function TopNavigationCreateForm(props) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [TopNavContent, setTopNavContent] = useState("");
  const [CallToAction, setCallToAction] = useState("");
  const [CallToActionLink, setCallToActionLink] = useState("");
  const [ExpireDate, setExpireDate] = useState(date);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  // Handle_createTopNavigation_Button
  const validate = () => {
    let valid = {};

    if (!TopNavContent) {
      valid.TopNavContent = "please enter a content";
    }

    if (!CallToActionLink) {
      valid.CallToActionLink = "please enter link";
    }

    if (!CallToAction) {
      valid.CallToAction = "please enter a action link";
    }

    if (ExpireDate === date) {
      valid.ExpireDate = "please enter a expire date";
    }

    setError(valid);
    return Object.keys(valid).length === 0;
  };

  const HandleCreate = () => {
    if (validate()) {
      dispatch(
        TopNavigationCreateAction(
          TopNavContent,
          CallToAction,
          CallToActionLink,
          ExpireDate
        )
      );
      setTopNavContent("");
      setCallToAction("");
      setCallToActionLink("");
      setExpireDate(date);
    }
  };
  console.log(ExpireDate, "expiredate");
  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Navigation" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={12}>
          <InputField
            label="Top Nav Content"
            handleChange={(e) => {
              setTopNavContent(e.target.value);
              setError((prev) => ({
                ...prev,
                ["TopNavContent"]: "",
              }));
            }}
            widthSize="98%"
            value={TopNavContent}
            required
            paramses
            error={Boolean(error.TopNavContent)}
            helperText={error.TopNavContent}
          />
        </Grid>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Call To Action"
              handleChange={(e) => {
                setCallToAction(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["CallToAction"]: "",
                }));
              }}
              widthSize="95%"
              value={CallToAction}
              required
              paramses
              error={Boolean(error.CallToAction)}
              helperText={error.CallToAction}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Call To Action Link"
              handleChange={(e) => {
                setCallToActionLink(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["CallToActionLink"]: "",
                }));
              }}
              widthSize="95%"
              value={CallToActionLink}
              required
              paramses
              error={Boolean(error.CallToActionLink)}
              helperText={error.CallToActionLink}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Expire Date"
              handleChange={(e) => {
                setExpireDate(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["ExpireDate"]: "",
                }));
              }}
              widthSize="95%"
              value={ExpireDate}
              InputType="date"
              required
              paramses
              error={Boolean(error.ExpireDate)}
              helperText={error.ExpireDate}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default TopNavigationCreateForm;
