import { Box, Button, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { kycDetailsAddAction } from "../../redux/action/AuthAction";

import { useParams } from "react-router-dom";
import InputField, {
  SelectinputFieldCustom,
} from "../../customComponent/InputField";
import { kycDetailsEditAction } from "../../../actions/partnerAction/partnerAction";

function CompanyInformationForm({ pageNumber, partnerData }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    id: partnerData?.id,
    organizationName: partnerData?.name,
    typeOfOrganization: partnerData?.typeOfOrganization,
    companyEmail: partnerData?.email,
    websiteURL: partnerData?.websiteUrl,
    gstNumber: partnerData?.GSTorVAT,
    incorporationDate: partnerData?.dateOfIncorporation,
    establishedYear: partnerData?.establishedYear,
    phoneNumber: partnerData?.phone,
    logoFile: partnerData?.logoUrl,
    associatedAgents: partnerData?.assosiateWithOther,
    section: "companyInform",
  });

  useEffect(() => {
    if (partnerData) {
      setFormData({
        id: partnerData.id,
        organizationName: partnerData?.name,
        typeOfOrganization: partnerData?.typeOfOrganization,
        companyEmail: partnerData?.email,
        websiteURL: partnerData?.websiteUrl,
        gstNumber: partnerData?.GSTorVAT,
        incorporationDate: partnerData?.dateOfIncorporation,
        establishedYear: partnerData?.establishedYear,
        phoneNumber: partnerData?.phone,
        logoFile: partnerData?.logoUrl,
        associatedAgents: partnerData?.assosiateWithOther,
        section: "companyInform",
      });
    }
  }, [partnerData]);

  console.log(formData);

  console.log(formData, "formData");

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.organizationName) {
      errors.organizationName = "Organization name is required";
    }

    if (!formData.companyEmail) {
      errors.companyEmail = "Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.companyEmail)) {
        errors.companyEmail = "Invalid email address";
      }
    }

      // if (!formData.websiteURL) {
      //   errors.websiteURL = "Website url is required";
      // }

      // if (!formData.gstNumber) {
      //   errors.gstNumber = "Gst number is required";
      // }

      // if (!formData.incorporationDate) {
      //   errors.incorporationDate = "Incorporation date is required";
      // }

      // if (!formData.establishedYear) {
      //   errors.establishedYear = "Established year is required";
      // }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(formData.phoneNumber)) {
        errors.phoneNumber = "Invalid phone number";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      console.log("Form submitted:", formData);

      let formDetail = new FormData();
      formDetail.append("formDetail", JSON.stringify(formData));
      formDetail.append("logo", formData.logoFile);

      dispatch(kycDetailsEditAction(formData.id, formDetail));
      // localStorage.setItem("CompanyInformationData", JSON.stringify(formData));
      pageNumber(2);
    } else {
      console.log(formErrors, "Form validation failed");
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "32px",
          rowGap: "24px",
          mt: "50px",
          "& input":{
            maxWidth:"calc(100% - 10px)",
          }
        }}
      >
        <InputField
          label={"Name of Organization"}
          handleChange={handleChange}
          value={formData.organizationName}
          required
          InputType="text"
          error={formErrors.organizationName}
          name={"organizationName"}
          color="#05050F"
        />

        <SelectinputFieldCustom
          handleSelectChange={handleChange}
          name="typeOfOrganization"
          label={"Type of Organization"}
          value={formData?.typeOfOrganization}
          color="#05050F"
          array={["IELTS", "Pvt Ltd", "Proprietorship", "Education", "Other"]}
        />

        <InputField
          label={"Company Email Address"}
          handleChange={handleChange}
          //  widthSize,
          value={formData.companyEmail}
          required
          InputType="text"
          error={formErrors.organizationName}
          name="companyEmail"
          color="#05050F"
        />

        <InputField
          label={"Website URL"}
          handleChange={handleChange}
          //  widthSize,
          value={formData.websiteURL}
          // required
          InputType="text"
          error={formErrors.websiteURL}
          name="websiteURL"
          color="#05050F"
        />

        <InputField
          label={"GST/VAT Number"}
          handleChange={handleChange}
          //  widthSize,
          value={formData.gstNumber}
          // required
          InputType="text"
          error={formErrors.websiteURL}
          name="gstNumber"
          color="#05050F"
        />

        <InputField
          label={"Date of incorporation"}
          handleChange={handleChange}
          //  widthSize,
          value={formData.incorporationDate}
          // required
          InputType="date"
          error={formErrors.incorporationDate}
          name={"incorporationDate"}
          color="#05050F"
        />

        <InputField
          label={"Established Year"}
          handleChange={handleChange}
          //  widthSize,
          value={formData.establishedYear}
          // required
          InputType="text"
          error={formErrors.establishedYear}
          name="establishedYear"
          color="#05050F"
        />

        <InputField
          label={"Phone Number"}
          handleChange={handleChange}
          //  widthSize,
          value={formData.phoneNumber}
          // required
          InputType="text"
          error={formErrors.phoneNumber}
          name="phoneNumber"
          color="#05050F"
        />
        <FormLabel
          sx={{
            width: "99%",
            border: "1px solid #C4C4C4",
            display: "flex",
            alignItems: "center",
            p: "1px",
            pl:"10px",
          }}
          onChange={handleChange}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "24px",
              color: formData.logoFile ? "#05050F" : "#ACB1C6",
              textAlign: "left",
              width: "100%",
            }}
          >
            {formData?.logoFile != null ? (
              <img
                src={
                  typeof formData?.logoFile !== "string"
                    ? URL.createObjectURL(formData?.logoFile)
                    : formData?.logoFile
                }
              />
            ) : (
              "Upload Logo"
            )}
          </Typography>
          <input type="file" hidden name="logoFile" />
          <Box
            sx={{
              cursor: "pointer",
              padding: "12px 32px",
              background: "#EBEBEB",
              color: "#010101",
            }}
          >
            Browse
          </Box>
        </FormLabel>

        <SelectinputFieldCustom
          handleChange={handleChange}
          name="associatedAgents"
          label={"Are you associated with any other agents for UK filing?"}
          value={formData?.associatedAgents}
          color="#05050F"
          array={["Yes", "No"]}
        />
      </Box>
      <Box
        sx={{
          textAlign: "right",
        }}
      >
        <Button
          type="submit"
          sx={{
            padding: "16px 24px",
            borderRadius: "8px",
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "Open Sans  !important",
            border: "1px solid #141E3C !important",
            background: "#141E3C !important",
            color: "#fff !important",
            mt: "82px",
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default CompanyInformationForm;
