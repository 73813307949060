import axios from "axios";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";
import {
  IELTS_CENTERMANAGMENT_CENTER_CREATE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_CREATE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_CREATE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_DELETE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_DELETE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_DELETE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_FINDBYID_ERR,
  IELTS_CENTERMANAGMENT_CENTER_FINDBYID_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_FINDBYID_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_FIND_ERR,
  IELTS_CENTERMANAGMENT_CENTER_FIND_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_FIND_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_SUCCESS,
  IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_ERR,
  IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_REQUEST,
  IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_SUCCESS,
  IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_ERR,
  IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_REQUEST,
  IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsCenterManagmentConstants";

// CENTER MANAGMENT CENTER CREATE ACTION

export const ieltsCenterManagmentCenterCreateAction = (formData) => async (
  dispatch
) => {
  try {
    // alert("llllll");
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/centerManagment/CreateCenter",
      formData,
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER MANAGMENT CENTER FIND ACTION

export const ieltsCenterManagmentCenterFindAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/centerManagment/FindCenter",
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER MANAGMENT CENTER FINDBYID ACTION

export const ieltsCenterManagmentCenterFindByIdAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_FINDBYID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/centerManagment/FindByIdCenter?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_FINDBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_FINDBYID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER MANAGMENT CENTER STATUSCHANGE ACTION

export const ieltsCenterManagmentCenterStatusChangeAction = (
  status,
  id
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/centerManagment/statusChange",
      {
        status,
        id,
      },
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER MANAGMENT CENTER SLOTUPDATE ACTION

export const ieltsCenterManagmentCenterSlotUpdateAction = (slotData) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/centerManagment/updateCenterslots",
      {
        slotData,
      },
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_SLOTUPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER MANAGMENT CENTER DELETE ACTION

export const ieltsCenterManagmentCenterDeleteAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/centerManagment/deleteCenter?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER MANAGMENT CENTER TEACHER DELETE ACTION

export const ieltsCenterManagmentCenterTeacherDeleteAction = (
  teacherId,
  centerId
) => async (dispatch) => {
  try {
    // alert("action");
    dispatch({ type: IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/centerManagment/deleteCenterTeacher?teacherId=${teacherId}&centerId=${centerId}`,
      config
    );

    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CENTERMANAGMENT_CENTER_TEACHER_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CENTER ADD TEACHER MODAL UPDATE ACTION

export const ieltsCenterAddTeacherModalUpdateAction =
  (formData) => async (dispatch) => {
    try {
      dispatch({ type: IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/ielts/centerManagment/updateCenterTeacher",
        formData,
        config
      );

      dispatch({
        type: IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: IELTS_CENTER_ADD_TEACHER_MODAL_UPDATE_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };
