import React, { useEffect } from "react";

import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import Header from "../../Header/Header";
import { MainContainer } from "../../content/content.element";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  findAllAsignedStaffAction,
  findByIdLeadApplicationAction,
  findStaffIncentiveAction,
  staffIncentiveAmmountAddAction,
} from "../../../actions/aplicationActions/applicationsAction";
import { useState } from "react";
import { SUCCESS_TRUE_MSG_SUCCESS } from "../../../constants/roleManagmentConstant";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";

function InsentiveDetailedPage(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [applicationIncentive, setApplicationIncentive] = useState([]);
  const [asignedStaf, setAsignedStaf] = useState();
  const [incentiveAmount, setIncentiveAmount] = useState([]);

  let { applicationFindByIdSuccess } = useSelector((state) => {
    return state.findByIdLeadApplication;
  });

  let { asignedStafFindSuccess } = useSelector((state) => {
    return state.findAllAsignedStaff;
  });

  let { findStaffIncentiveSuccess } = useSelector((state) => {
    return state.findStaffIncentive;
  });

  let { addStaffIncentiveSuccess } = useSelector((state) => {
    return state.staffIncentiveAmmountAdd;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });
  useEffect(() => {
    if (findStaffIncentiveSuccess) {
      // setAsignedStaf(asignedStafFindSuccess);
      setIncentiveAmount(
        findStaffIncentiveSuccess.map((item) => ({
          id:
            item?.role == "branch manager"
              ? item.userId
              : item?.role == "counsellor"
              ? item.userId
              : item?.role == "Application Handler"
              ? item.userId
              : item?.role == "University Handler"
              ? item.userId
              : item?.role == "Tele Caller"
              ? item.userId
              : item?.role == "partner"
              ? item.userId
              : null,
          incentiveAmount: item.incentiveAmount,
          applicationId: item.applicationId,
          role: item.role,
          leadId: item.leadId,
          userId: item.userId,
          userName: item.userName,
          additionalIncentive: item.additionalIncentive,
        }))
      );
    }
  }, [findStaffIncentiveSuccess]);
  console.log(
    incentiveAmount,
    "incentiveAmountincentiveAmountincentiveAmountincentiveAmountincentiveAmount"
  );

  useEffect(() => {
    if (applicationFindByIdSuccess) {
      setApplicationIncentive(applicationFindByIdSuccess);
    }
  }, [applicationFindByIdSuccess]);

  useEffect(() => {
    if (asignedStafFindSuccess) {
      setAsignedStaf(asignedStafFindSuccess);
    }
  }, [asignedStafFindSuccess]);

  useEffect(() => {
    if (addStaffIncentiveSuccess && successAlertMsgSuccess) {
      Swal.fire("Added!", "Incentive Added Successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [addStaffIncentiveSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    dispatch(findByIdLeadApplicationAction(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(findAllAsignedStaffAction(id));
  }, [dispatch, addStaffIncentiveSuccess]);

  useEffect(() => {
    dispatch(findStaffIncentiveAction(id));
  }, [dispatch, addStaffIncentiveSuccess]);

  console.log(asignedStafFindSuccess,"asignedStafFindSuccessasignedStafFindSuccess")

  return (
    <MainContainer active={props.toggle}>
      <Box sx={{ background: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: "33px",
            backgroundColor: "#ffffff",
            mt: "1px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <WestIcon
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/Accounts")}
            />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              Incentive Details
            </Typography>
          </Box>
          {/* <Button
            sx={{
              background: "rgba(20, 30, 60, 1)",
              color: "#fff",
              border: "1px solid rgba(20, 30, 60, 1)",
              borderRadius: "5px",
              fontWeight: "600",
              "&:hover": {
                color: "rgba(20, 30, 60, 1)",
              },
            }}
            // onClick={() => setOpen(true)}
          >
            Add New
          </Button> */}
        </Box>

        <Box
          sx={{
            px: "33px",
          }}
        >
          <Box
            sx={{
              border: "1px solid rgba(236, 236, 236, 1)",
              p: "33px",
              display: "flex",
              gap: "20px",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Application ID
                {/* Name */}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {applicationIncentive?.applicationId}
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Lead Name
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {applicationIncentive?.lead?.firstName}
              </Typography>
            </Box>

            {/* <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Date
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {applicationIncentive?.courseName}
              </Typography>
            </Box> */}

            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                University
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {applicationIncentive?.universityName}
              </Typography>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Course
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {applicationIncentive?.courseName}
              </Typography>
            </Box>

            {/* <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Lead Name
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                Lubshad Pattarathil
              </Typography>
            </Box> */}

            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                Status
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              >
                {" "}
                {applicationIncentive?.methodStatus}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              overflowX: "scroll",
              mt: "32px",
            }}
          >
            <Table className="landingTable" sx={{ minWidth: "990px" }}>
              <TableHead sx={{ backgroundColor: "#F9F9FB" }}>
                <TableRow className="landingTableCell">
                  {/* <TableCell
                    sx={{
                      borderBottom: "0",
                      py: "5px",
                    }}
                  >
                    <Checkbox
                      // checked={selectedRows.length === sortedData.length}
                      // onChange={handleSelectAllRows}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell> */}
                  {[
                    "sl no",
                    "employee Name",
                    "employee role",
                    "Amount",
                    "Additional incentive",
                  ]?.map((column) => {
                    return (
                      <TableCell
                        key={column.field}
                        className="landingTableCell"
                        sx={{
                          textTransform: "uppercase",
                          color: "#666E7D",
                          fontWeight: "600",
                          borderBottom: "0",
                          py: "5px",
                        }}
                      >
                        <TableSortLabel
                          className="landingHeaderText"
                          // active={column === sortBy}
                          // direction={sortOrder}
                          // onClick={() => requestSort(column)}
                          // IconComponent={
                          //   column.field !== "action" && CustomSortIcon
                          // }
                        >
                          {column}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {asignedStaf?.map((item, index) => (
                  <TableRow
                    className="landingTable"
                    sx={{
                      "& .MuiTableCell-root ": {
                        borderBottom: "0",
                        py: "5px",
                      },
                    }}
                  >
                    {/* <TableCell>
                    <Checkbox style={{ color: "#f81b82" }} />
                  </TableCell> */}
                    <TableCell>{index + 1}</TableCell>

                    <TableCell>{item?.firstName}</TableCell>
                    <TableCell>{item?.role?.roleName}</TableCell>
                    <TableCell
                      sx={{
                        "& input": {
                          maxWidth: "148px",
                          border: "1px solid rgba(236, 236, 236, 1)",
                          height: "35px",
                          px: "10px",
                          outline: "none",
                        },
                      }}
                    >
                      {
                        <input
                          type="text"
                          value={
                            incentiveAmount[
                              incentiveAmount.findIndex(
                                (data) => data.userId == item.id
                              )
                            ]?.incentiveAmount
                          }
                          onChange={(e) => {
                            const existingIndex = incentiveAmount.findIndex(
                              (data) => data.id == item.id
                            );
                            if (existingIndex !== -1) {
                              const updatedIncentiveAmount = [
                                ...incentiveAmount,
                              ];
                              updatedIncentiveAmount[existingIndex] = {
                                ...updatedIncentiveAmount[existingIndex],
                                incentiveAmount: e.target.value,
                              };
                              setIncentiveAmount(updatedIncentiveAmount);
                            } else {
                              setIncentiveAmount([
                                ...incentiveAmount,
                                {
                                  id: item.id,
                                  incentiveAmount: e.target.value,
                                  applicationId: id,
                                  role: item?.role?.roleName,
                                  leadId: "",
                                  userId: `${item.id}`,
                                  userName: item?.firstName,
                                  additionalIncentive: "",
                                },
                              ]);
                            }
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell
                      sx={{
                        "& input": {
                          maxWidth: "148px",
                          border: "1px solid rgba(236, 236, 236, 1)",
                          height: "35px",
                          px: "10px",
                          outline: "none",
                        },
                      }}
                    >
                      {
                        <input
                          type="text"
                          value={
                            incentiveAmount[
                              incentiveAmount.findIndex(
                                (data) => data.userId == item.id
                              )
                            ]?.additionalIncentive
                          }
                          onChange={(e) => {
                            console.log(
                              incentiveAmount,
                              "jhkjh65afgjflaksjdflkasjhkjh65afgjflaksjdflkasjhkjh65afgjflaksjdflkas"
                            );
                            const existingIndex = incentiveAmount.findIndex(
                              (data) => data.id === item.id
                            );
                            if (existingIndex !== -1) {
                              const updatedIncentiveAmount = [
                                ...incentiveAmount,
                              ];

                              updatedIncentiveAmount[existingIndex] = {
                                ...updatedIncentiveAmount[existingIndex],
                                additionalIncentive: e.target.value,
                              };
                              setIncentiveAmount(updatedIncentiveAmount);
                            } else {
                              setIncentiveAmount([
                                // ...incentiveAmount,
                                {
                                  id: item.id,
                                  incentiveAmount: "",
                                  applicationId: id,
                                  role: item?.role?.roleName,
                                  leadId: "",
                                  userId: `${item.id}`,
                                  userName: item?.firstName,
                                  additionalIncentive: e.target.value,
                                },
                              ]);
                            }
                          }}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter />
            </Table>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: "15px",
              backgroundColor: "#ffffff",
              mt: "1px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {/*  */}
            </Box>
            <Button
              sx={{
                background: "rgba(20, 30, 60, 1)",
                color: "#fff",
                border: "1px solid rgba(20, 30, 60, 1)",
                borderRadius: "5px",
                fontWeight: "600",
                "&:hover": {
                  color: "rgba(20, 30, 60, 1)",
                },
              }}
              onClick={() =>
                dispatch(staffIncentiveAmmountAddAction(incentiveAmount))
              }
            >
              Add New
            </Button>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default InsentiveDetailedPage;
