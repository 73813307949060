export const UNIVERSITY_COURSE_ADD_REQUEST = "UNIVERSITY_COURSE_ADD_REQUEST";
export const UNIVERSITY_COURSE_ADD_SUCCESS = "UNIVERSITY_COURSE_ADD_SUCCESS";
export const UNIVERSITY_COURSE_ADD_ERR = "UNIVERSITY_COURSE_ADD_ERR";


export const UNIVERSITY_COURSE_FIND_REQUEST = "UNIVERSITY_COURSE_FIND_REQUEST";
export const UNIVERSITY_COURSE_FIND_SUCCESS = "UNIVERSITY_COURSE_FIND_SUCCESS";
export const UNIVERSITY_COURSE_FIND_ERR = "UNIVERSITY_COURSE_FIND_ERR";


export const ALL_COURSE_FIND_REQUEST = "ALL_COURSE_FIND_REQUEST";
export const ALL_COURSE_FIND_SUCCESS = "ALL_COURSE_FIND_SUCCESS";
export const ALL_COURSE_FIND_ERR = "ALL_COURSE_FIND_ERR";

export const UNIVERSITY_COURSE_DELETE_REQUEST = "UNIVERSITY_COURSE_DELETE_REQUEST";
export const UNIVERSITY_COURSE_DELETE_SUCCESS = "UNIVERSITY_COURSE_DELETE_SUCCESS";
export const UNIVERSITY_COURSE_DELETE_ERR = "UNIVERSITY_COURSE_DELETE_ERR";


export const UNIVERSITY_COURSE_TABLE_FILTER_REQUEST = "UNIVERSITY_COURSE_TABLE_FILTER_REQUEST";
export const UNIVERSITY_COURSE_TABLE_FILTER_SUCCESS = "UNIVERSITY_COURSE_TABLE_FILTER_SUCCESS";
export const UNIVERSITY_COURSE_TABLE_FILTER_ERR = "UNIVERSITY_COURSE_TABLE_FILTER_ERR";

export const UNIVERSITY_COURSE_EDITFIND_REQUEST = "UNIVERSITY_COURSE_EDITFIND_REQUEST";
export const UNIVERSITY_COURSE_EDITFIND_SUCCESS = "UNIVERSITY_COURSE_EDITFIND_SUCCESS";
export const UNIVERSITY_COURSE_EDITFIND_ERR = "UNIVERSITY_COURSE_EDITFIND_ERR";


export const UNIVERSITY_COURSE_EDIT_REQUEST = "UNIVERSITY_COURSE_EDIT_REQUEST";
export const UNIVERSITY_COURSE_EDIT_SUCCESS = "UNIVERSITY_COURSE_EDIT_SUCCESS";
export const UNIVERSITY_COURSE_EDIT_ERR = "UNIVERSITY_COURSE_EDIT_ERR";


export const UNIVERSITY_COURSE_BULKUPLOAD_REQUEST = "UNIVERSITY_COURSE_BULKUPLOAD_REQUEST";
export const UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS = "UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS";
export const UNIVERSITY_COURSE_BULKUPLOAD_ERR = "UNIVERSITY_COURSE_BULKUPLOAD_ERR";

