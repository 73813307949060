import * as React from "react";
// import Typography from '@mui/material/Typography';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import { Box } from "@material-ui/core";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function HeadBreadcrumbs(props) {
  // theme Provider

  return (
    <Box role="presentation" onClick={handleClick} mt={2}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ m: 1 }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "21px",
            fontWeight: 600,
            color:"black"
          }}
        >
          {props?.first}
        </Typography>
        <Link sx={{fontSize:"12px",fontWeight:400,}} underline="hover" color="inherit" href={props?.head}>
          {props?.head}
        </Link>
        <Link sx={{fontSize:"12px",fontWeight:400,}} underline="hover" color="inherit" href={props?.sechead}>
          {props?.sechead}
        </Link>
        <Typography sx={{fontSize:"12px",fontWeight:400}} color="text.primary">{props?.main}</Typography>
      </Breadcrumbs>
    </Box>
  );
}
