import {
  EMPLOYEE_INCENTIVE_CREATE_ERR,
  EMPLOYEE_INCENTIVE_CREATE_REQUEST,
  EMPLOYEE_INCENTIVE_CREATE_SUCCESS,
  EMPLOYEE_INCENTIVE_FIND_ERR,
  EMPLOYEE_INCENTIVE_FIND_REQUEST,
  EMPLOYEE_INCENTIVE_FIND_SUCCESS,
  PARTNER_COMMISSION_CREATE_ERR,
  PARTNER_COMMISSION_CREATE_REQUEST,
  PARTNER_COMMISSION_CREATE_SUCCESS,
  PARTNER_COMMISSION_DELETE_ERR,
  PARTNER_COMMISSION_DELETE_REQUEST,
  PARTNER_COMMISSION_DELETE_SUCCESS,
  PARTNER_COMMISSION_FIND_ERR,
  PARTNER_COMMISSION_FIND_REQUEST,
  PARTNER_COMMISSION_FIND_SUCCESS,
} from "../constants/incentiveConstant";

// partnerCommissionCreateReducer
export const partnerCommissionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_COMMISSION_CREATE_REQUEST:
      return {
        ...state,
        partnerCommissionCreateLoading: true,
      };
    case PARTNER_COMMISSION_CREATE_SUCCESS:
      return {
        ...state,
        partnerCommissionCreateLoading: false,
        partnerCommissionCreateSuccess: action.payload,
      };
    case PARTNER_COMMISSION_CREATE_ERR:
      return {
        ...state,
        partnerCommissionCreateLoading: false,
        partnerCommissionCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// partnerCommissionFindReducer
export const partnerCommissionFindReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_COMMISSION_FIND_REQUEST:
      return {
        ...state,
        partnerCommissionFindLoading: true,
      };
    case PARTNER_COMMISSION_FIND_SUCCESS:
      return {
        ...state,
        partnerCommissionFindLoading: false,
        partnerCommissionFindSuccess: action.payload,
      };
    case PARTNER_COMMISSION_FIND_ERR:
      return {
        ...state,
        partnerCommissionFindLoading: false,
        partnerCommissionFindErr: action.payload,
      };
    default:
      return state;
  }
};

// partnerCommissionDeleteReducer
export const partnerCommissionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_COMMISSION_DELETE_REQUEST:
      return {
        ...state,
        partnerCommissionDeleteLoading: true,
      };
    case PARTNER_COMMISSION_DELETE_SUCCESS:
      return {
        ...state,
        partnerCommissionDeleteLoading: false,
        partnerCommissionDeleteSuccess: action.payload,
      };
    case PARTNER_COMMISSION_DELETE_ERR:
      return {
        ...state,
        partnerCommissionDeleteLoading: false,
        partnerCommissionDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// employeeIncentiveCreateReducer
export const employeeIncentiveCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_INCENTIVE_CREATE_REQUEST:
      return {
        ...state,
        employeeIncentiveCreateLoading: true,
      };
    case EMPLOYEE_INCENTIVE_CREATE_SUCCESS:
      return {
        ...state,
        employeeIncentiveCreateLoading: false,
        employeeIncentiveCreateSuccess: action.payload,
      };
    case EMPLOYEE_INCENTIVE_CREATE_ERR:
      return {
        ...state,
        employeeIncentiveCreateLoading: false,
        employeeIncentiveCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// employeeIncentiveFindReducer
export const employeeIncentiveFindReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_INCENTIVE_FIND_REQUEST:
      return {
        ...state,
        employeeIncentiveFindLoading: true,
      };
    case EMPLOYEE_INCENTIVE_FIND_SUCCESS:
      return {
        ...state,
        employeeIncentiveFindLoading: false,
        employeeIncentiveFindSuccess: action.payload,
      };
    case EMPLOYEE_INCENTIVE_FIND_ERR:
      return {
        ...state,
        employeeIncentiveFindLoading: false,
        employeeIncentiveFindErr: action.payload,
      };
    default:
      return state;
  }
};
