import axios from "axios";

// des:List Category of Ielts

import {
  IELTS_CATEGORY_FIND_ERR,
  IELTS_CATEGORY_FIND_REQUEST,
  IELTS_CATEGORY_FIND_SUCCESS,
  IELTS_CATEGORY_STATUSCHANGE_ERR,
  IELTS_CATEGORY_STATUSCHANGE_REQUEST,
  IELTS_CATEGORY_STATUSCHANGE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_ERR,
  IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_REQUEST,
  IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_SUCCESS,
  IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_ERR,
  IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_REQUEST,
  IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_ERR,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_REQUEST,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_SUCCESS,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_ERR,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_REQUEST,
  IELTS_LEARNINGMANAGMENT_FIND_TOPIC_SUCCESS,
  IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_ERR,
  IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_REQUEST,
  IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_ERR,
  IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_REQUEST,
  IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_SUCCESS,
  IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_ERR,
  IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_REQUEST,
  IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsConstants";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

export const ieltsCategoryFindAction = (component) => async (dispatch) => {
  try {
    
    dispatch({ type: IELTS_CATEGORY_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/findCategory?component=${component}`,
      config
    );

    dispatch({ type: IELTS_CATEGORY_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CATEGORY_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// des:Category status change Action

export const ieltsCategoryStatusChangeAction = (status, id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_CATEGORY_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/updateCategoryStatus",
      { status, id },
      config
    );

    dispatch({ type: IELTS_CATEGORY_STATUSCHANGE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_CATEGORY_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
  }
};

// des:learning Managment

export const ieltsLearningManagmentCreateTopicAction = (formData) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/learningManagment/CreateTopic",
      formData,
      config
    );
    console.log(data, "datatatattatt");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:"learning managment findTopic"

export const ieltsLearningManagmentFindTopicAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_FIND_TOPIC_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/learningManagment/FindTopic",
      config
    );

    dispatch({
      type: IELTS_LEARNINGMANAGMENT_FIND_TOPIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_FIND_TOPIC_ERR,
      payload: error.response.data,
    });
  }
};

// des:"learning managment findTopicById"

export const ieltsLearningManagmentFindTopicByIdAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/learningManagment/FindTopicById?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_ERR,
      payload: error.response.data,
    });
  }
};

// des:"learning managment topicStatusChange"

export const ieltsLearningManagmentTopicstatusChangeAction = (
  status,
  id
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/learningManagment/topicStatusChange",
      {
        status,
        id,
      },
      config
    );

    dispatch({
      type: IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
  }
};

// des:learning Managment topic update action

export const ieltsLearningManagmentUpdateTopicAction = (formData) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/learningManagment/updateTopics",
      formData,
      config
    );
    console.log(data, "datadatadatadatadata");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:learning Managment topic delete action

export const ieltsLearningManagmentDeleteTopicAction = (id, name) => async (
  dispatch
) => {
  // alert("jjjj");
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/learningManagment/deleteTopicsVideo",
      {
        id,
        name,
      },
      config
    );

    dispatch({
      type: IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_ERR,
      payload: error?.response?.data,
    });
  }
};

// des:learning Managment topic delete action

export const ieltsLearningManagmentDeleteEntireTopicAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/learningManagment/deleteTopics?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_ERR,
      payload: error.response.data,
    });
  }
};
