import axios from "axios";

// Note find action

import { NOTES_FIND_ERR, NOTES_FIND_REQUEST, NOTES_FIND_SUCCESS } from "../constants/dashBoardConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

export const noteFindAction = () => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTES_FIND_REQUEST });
  
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;
  
      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
  
      let { data } = await axios.get(
        "/api/admin/dashBoard/noteFind",
        config
      );
  
      dispatch({ type: NOTES_FIND_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: NOTES_FIND_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };
  