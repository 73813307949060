import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Button,
  Chip
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, Grid, MenuItem, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Skeleton } from "@mui/material";
import { BiSortAlt2 } from "react-icons/bi";
import { ContentState, convertFromHTML } from "draft-js";
import { TbEdit } from "react-icons/tb";
import { ieltsTeacherManagmentStatusChangeAction } from "../../../actions/IeltsActions/teacherManagmentAction";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import {
  paymentApprovalRegistrationFeeApproveAction,
  tutionFeepaymentApprovalApproveAction
} from "../../../actions/paymentApprovalAction";
import Swal from "sweetalert2";
import PremissionDialog from "../../customComponent/premissionDialog";
import {
  leadOwnerChangeAction,
  leadRefundApprovalAction
} from "../../../actions/leadActions/leadActions";
import Dialogue from "../../customComponent/Dialogue";

function RefundApprovalTable({
  setIdsFunction,
  data,
  leadFindLoading,
  selectBoxIdPassHandler,
  columns,
  subMenu
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [ids, setId] = useState("");
  const [editRefund, setEditRefund] = useState(false);
  const [editRefundData, setEditRefundData] = useState({});

  // custom Hooks
  const { isApprovePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  //Function to handle Description

  function convertHTMLToPlainText(html) {
    // Convert HTML to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    // Convert Draft.js ContentState to plain text
    const plainText = contentState.getPlainText();

    return plainText;
  }

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  // Function_to_handle_EditForm

  const EmployeeJobEditHandler = (id) => {
    navigate(`/hrmanagment/Editjobs/${id}`);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  console.log(sortedData, "sortedDatasortedData");

  function formatDate(dateString) {
    const date = new Date(dateString.replace(" ", "T"));
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true // Ensure AM/PM format
    };

    return date.toLocaleString("en-US", options).replace(",", " at");
  }

  // Handle Edit refundAmount

  const handleEditRefund = (item) => {
    setEditRefund(true);
    setEditRefundData({
      currency: item?.currency,
      amount: item?.refundFee,
      id: item?.id
    });
  };

  console.log(sortedData, "sortedDatasortedDatasortedData");
  console.log(editRefundData, "editRefundDataeditRefundData");

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
        <Grid item xs={12} lg={12}>
          {leadFindLoading ? (
            <>
              {/* <Skeleton /> */}
              <Typography variant="h1">
                <Skeleton animation={false} />
              </Typography>

              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  // fontFamily: "Inter",
                  lineHeight: "20px",
                  color: "rgba(5, 5, 15, 1)",
                  fontWeight: "400",
                  mb: "10px"
                }}
              >
                Showing{" "}
                <Typography
                  component={"span"}
                  sx={{
                    color: "rgba(5, 5, 15, 1)",
                    fontSize: "14px",
                    // fontFamily: "Inter",
                    lineHeight: "20px",
                    fontWeight: "700"
                  }}
                >
                  {
                    sortedData?.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    ).length
                  }{" "}
                  Result
                </Typography>
              </Typography>
              <div
                style={{
                  overflowX: "scroll",
                  border: "0px solid #ffff"
                }}
                className="scrollBar"
              >
                <Table className="EmployeeJobTable">
                  <TableHead>
                    <TableRow className="EmployeeJobTableCell">
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 1, // To ensure it's above other cells
                          padding: "1px",
                          border: "1px solid #ddd",
                          // borderLeft: "1px solid #ddd",
                          backgroundColor: "#fafafa"
                        }}
                      >
                        <Checkbox
                          checked={selectedRows?.length === sortedData?.length}
                          onChange={handleSelectAllRows}
                          style={{ color: "#f81b82" }}
                        />
                      </TableCell>
                      {columns?.map((column) => {
                        if (column.hidden) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={column.field}
                            className="EmployeeJobTableCell LeadCommon"
                            style={{
                              padding: "0 16px",
                              whiteSpace: "nowrap",
                              textTransform: "uppercase",
                              fontWeight: "700"
                            }}
                          >
                            <TableSortLabel
                              className="LeadHeaderText"
                              active={column === sortBy}
                              direction={sortOrder}
                              onClick={() => requestSort(column)}
                              IconComponent={
                                column.field !== "action" && CustomSortIcon
                              }
                            >
                              {column.title}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData?.map((item, indx) => (
                      <TableRow className="EmployeeJobTable" key={indx}>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1, // To ensure it's above other cells
                            backgroundColor: "white", // Adjust as needed
                            padding: "1px",
                            border: "1px solid #ddd"
                          }}
                        >
                          <Checkbox
                            checked={selectedRows.includes(indx)}
                            onChange={() => handleSelectRow(indx)}
                            style={{ color: "#f81b82" }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {indx + 1}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead_applied_short_list?.universityGivenId ||
                            "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <span
                            style={{
                              color: "blue",
                              fontSize: "14px",
                              textDecoration: "underline",
                              cursor:"pointer"
                            }}
                            onClick={() =>
                              navigate(
                                `/Leads/details/${
                                  item?.leadId
                                }/${"Deals"}/${"Deals"}/${"accounts"}`
                              )
                            }
                          >
                            {item?.lead?.firstName + " " + item?.lead?.lastName}
                          </span>
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead?.lead_personal_details[0]
                            ?.passportNumber || ""}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead?.email || ""}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead?.countryId +
                            " " +
                            item?.lead?.mobileNumber || ""}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.lead_applied_short_list?.applicationId || "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead_applied_short_list?.universityCountry}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead_applied_short_list?.universityName || "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead_applied_short_list?.university
                            ?.universityType || "-"}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.lead_applied_short_list?.courseName || "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.currency +
                            " " +
                            item?.lead_applied_short_list
                              ?.universityInternationalFee || "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.lead_applied_short_list?.totalFeePaid || "-"}
                        </TableCell>

                        <TableCell
                          style={{
                            padding: "0 16px",
                            marginTop: "10px",
                            whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "none"
                          }}
                        >
                          <span style={{ textDecoration: "none" }}>
                            {item?.currency + " " + item?.refundFee || "-"}
                          </span>

                          <Box sx={{ ml: 1, mt: 0.5 }}>
                            <TbEdit
                              style={{
                                fontSize: "lg",
                                width: "20px",
                                height: "19px",
                                textDecoration: "none",
                                cursor: "pointer"
                              }}
                              onClick={() => handleEditRefund(item)}
                              className="icons"
                            />
                          </Box>
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item?.user?.firstName == "admin"
                            ? item?.user?.firstName
                            : item?.user?.roleName || "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {formatDate(item?.createdAt) || "-"}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <Button
                            sx={{
                              backgroundColor: "#228200",
                              color: "white",
                              width: "76px",
                              height: "25px",
                              fontWeight: 400,
                              fontSize: "12px",
                              mr: 2,
                              "&:hover": {
                                backgroundColor: "rgba(34, 110, 0, 0.5)"
                              }
                            }}
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "do You want to approve this ?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, approve it!"
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  if (isApprovePrevilage(subMenu)) {
                                    dispatch(leadRefundApprovalAction(item.id));
                                    dispatch(
                                      leadOwnerChangeAction(
                                        "",
                                        "",
                                        "",
                                        "",
                                        "Refund Credited",
                                        item?.aplicationID
                                      )
                                    );
                                    Swal.fire(
                                      "Approved!",
                                      "This payment is approved.",
                                      "success"
                                    );
                                  } else {
                                    setOpenAlert(true);
                                  }
                                }
                              });
                            }}
                          >
                            Approve
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter />
                </Table>
              </div>
            </>
          )}
        </Grid>
      </Grid>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={() => setOpenAlert(false)}
        />
      )}

      {editRefund === true && (
        <Dialogue
          openTrue={editRefund}
          handleClose={() => setEditRefund(false)}
          headerName={"Edit Refund Amount"}
          Dailogcomponent={"editRefundPopup"}
          // appliedApplicationId={appliedApplicationId}
          modalData={editRefundData}
          // leadID={leadId}
        />
      )}
    </>
  );
}

export default RefundApprovalTable;
