import React, { useEffect, useState } from "react";
import {
  Player
} from "video-react";
import "video-react/dist/video-react.css";
import {
  Box
} from "@mui/material";



function VideoPlayer({ url }) {
  useEffect(() => {
    if (url) {
      setSlectedVideo(url);
    }
  }, [url]);

  const [selectedVideo, setSlectedVideo] = useState(url);
  // const [isplaying, setisplaying] = useState(true);
  // const videoRefs = useRef(null);

  // const nextVideo = () => {
  //   videoRefs.current.pause();
  //   setisplaying(true);
  // };
  // const prevVideo = () => {
  //   videoRefs.current.pause();
  //   setisplaying(true);
  // };

  // // handle state when completed playing the video
  // const myCallback = () => {
  //   setisplaying(true);
  //   console.log("first");
  // };

  // // play the video
  // const handlePlay = () => {
  //   videoRefs.current.play();
  //   setisplaying(true);
  // };

  // // pause the video
  // const handlePause = () => {
  //   videoRefs.current.pause();
  //   setisplaying(false);
  // };

  // useEffect(() => {
  //   if (isplaying) {
  //     videoRefs?.current?.pause();
  //   } else {
  //     videoRefs?.current?.play();
  //   }
  // }, [isplaying]);


  return (
    <Box sx={{height:"500px",width:"100%",maxWidth:"1000px"}}>

      <Player
        playsInline
        src={selectedVideo}
        autoPlay={true}
      />

      {/* <Player src={selectedVideo}>

        <ControlBar>
          <ReplayControl seconds={10} order={1.1} />
          <ForwardControl seconds={30} order={1.2} />
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
          <VolumeMenuButton disabled />
        </ControlBar>
      </Player> */}

      {/* <Box
        sx={{
          position: "relative",
          borderRadius: "16px",
          overflow: "hidden",
          maxWidth: "786px",
          width: "100%",
          "& video": {
            maxHeight: "450px",
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "cover",
          },
          "&:hover > div": {
            opacity: "1",
          },
        }}
      >
        <video
          src={selectedVideo}
          onEnded={myCallback}
          ref={videoRefs}
          alt={selectedVideo}
        ></video>
        <Box
          sx={{
            position: "absolute",
            opacity: "0",
            transition: "0.5s ease",
            top: "0",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: "1",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mb: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  minWidth: "40px",
                  cursor: "pointer",
                  background: "#FFF !important",
                  outline: "none !important",
                  border: "none !important",
                }}
                onClick={prevVideo}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7417_50674)">
                    <path
                      d="M4.46662 6.51116L13.3618 0.392749C13.7273 0.14079 14.1074 0.00781237 14.4328 0.0078124C15.1474 0.00781246 15.6211 0.607121 15.6211 1.57154L15.6211 14.4363C15.6211 14.9074 15.4841 15.3011 15.281 15.5751C15.0748 15.8528 14.7522 15.9998 14.4037 15.9998C14.0784 15.9998 13.7133 15.8665 13.3479 15.615L4.46079 9.49656C3.88948 9.10281 3.57622 8.57284 3.57635 8.0036C3.57635 7.43462 3.89453 6.90465 4.46662 6.51116Z"
                      fill="#141E3C"
                    />
                    <path
                      d="M1.23594 -1.10678e-07L1.60364 0.00103679C2.08513 0.00103683 2.50195 0.39349 2.50195 0.875633L2.50195 15.1121C2.50195 15.5947 2.092 15.9909 1.60986 15.9909L1.21925 15.9959C0.73954 15.9933 0.378193 15.5937 0.378193 15.1128L0.378194 0.874725C0.378194 0.392583 0.754058 -1.52806e-07 1.23594 -1.10678e-07Z"
                      fill="#141E3C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7417_50674">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 16 0)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </Box>

            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                background: "#FFF !important",
                cursor: "pointer",
                marginRight: "24px",
                marginLeft: "18px",
                outline: "none !important",
                border: "none !important",
              }}
              onClick={isplaying ? handlePause : handlePlay}
            >
              {!isplaying && (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.66699 7.99935C2.66699 5.48468 2.66699 4.22868 3.44833 3.44735C4.22966 2.66602 5.48566 2.66602 8.00033 2.66602C10.515 2.66602 11.771 2.66602 12.5523 3.44735C13.3337 4.22868 13.3337 5.48468 13.3337 7.99935V23.9993C13.3337 26.514 13.3337 27.77 12.5523 28.5513C11.771 29.3327 10.515 29.3327 8.00033 29.3327C5.48566 29.3327 4.22966 29.3327 3.44833 28.5513C2.66699 27.77 2.66699 26.514 2.66699 23.9993V7.99935ZM18.667 7.99935C18.667 5.48468 18.667 4.22868 19.4483 3.44735C20.2297 2.66602 21.4857 2.66602 24.0003 2.66602C26.515 2.66602 27.771 2.66602 28.5523 3.44735C29.3337 4.22868 29.3337 5.48468 29.3337 7.99935V23.9993C29.3337 26.514 29.3337 27.77 28.5523 28.5513C27.771 29.3327 26.515 29.3327 24.0003 29.3327C21.4857 29.3327 20.2297 29.3327 19.4483 28.5513C18.667 27.77 18.667 26.514 18.667 23.9993V7.99935Z"
                    fill="#141E3C"
                  />
                </svg>
              )}
              {isplaying && (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.34822 3.25399C5.94687 1.87494 4 3.00468 4 5.77531V26.2214C4 28.9948 5.94687 30.1231 8.34822 28.7453L26.1984 18.4965C28.6005 17.1169 28.6005 14.8819 26.1984 13.5027L8.34822 3.25399Z"
                    fill="#141E3C"
                  />
                </svg>
              )}
            </Button>

            <Box>
              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px !important",
                  height: "40px !important",
                  minWidth: "40px !important",
                  borderRadius: "50%",
                  cursor: "pointer",
                  background: "#FFF !important",
                  outline: "none !important",
                  border: "none !important",
                }}
                onClick={nextVideo}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7417_50685)">
                    <path
                      d="M11.5334 9.48884L2.63824 15.6073C2.27274 15.8592 1.8926 15.9922 1.56715 15.9922C0.852624 15.9922 0.378906 15.3929 0.378906 14.4285V1.56369C0.378906 1.09256 0.515902 0.69894 0.718998 0.424949C0.925205 0.147198 1.2478 0.000222206 1.59632 0.000222206C1.92163 0.000222206 2.28674 0.133459 2.6521 0.385028L11.5392 6.50344C12.1105 6.89719 12.4238 7.42716 12.4237 7.9964C12.4237 8.56538 12.1055 9.09535 11.5334 9.48884Z"
                      fill="#141E3C"
                    />
                    <path
                      d="M14.7641 16L14.3964 15.999C13.9149 15.999 13.498 15.6065 13.498 15.1244V0.887945C13.498 0.405284 13.908 0.0090723 14.3901 0.0090723L14.7807 0.00414658C15.2605 0.00670624 15.6218 0.406322 15.6218 0.887168V15.1253C15.6218 15.6074 15.2459 16 14.7641 16Z"
                      fill="#141E3C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7417_50685">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="matrix(1 0 0 -1 0 16)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
}

export default VideoPlayer;
