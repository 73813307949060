import React, { useEffect } from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { TypographyText1 } from "../../customComponent/Typography";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { useState } from "react";
import { SubmitButton } from "../../customComponent/Buttons";
import "../../customComponent/inputField.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ieltsTeacherManagmentCreateAction,
  ieltsTeacherManagmentFindByIdAction,
  ieltsTeacherManagmentUpdateAction,
} from "../../../actions/IeltsActions/teacherManagmentAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { ieltsCategoryFindAction } from "../../../actions/IeltsActions/learningManadmentAction";

function IeltsTeacherEditForm({ toggle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState("");
  const [avatarImage, setAvatarImage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState("");
  const [specialization, setSpecialization] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [adharNumber, setAdharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(true);
  const [err, setErr] = useState({
    name: "",
    phone: "",
    email: "",
    specialization: "",
    adharNumber: "",
    pan: "",
    userName: "",
    password: "",
  });

  // Handle image upload

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setProfileImage(file);
    const reader = new FileReader();
    // Read the image file as a data URL
    reader.readAsDataURL(file);

    // Set the previewImage state when the reader has finished loading the image
    reader.onloadend = () => {
      setAvatarImage(reader.result);
    };
  };

  // handle Password

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setValid(validatePassword(newPassword));
    setPassword(newPassword);
  };

  const validatePassword = (password) => {
    // Check if the password contains at least 6 characters and one symbol
    const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
    return regex.test(password);
  };

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  // console.log(
  //   specialization,
  //   "specializationspecializationspecializationspecializationspecialization"
  // );

  let {
    ieltsTeacherManagmentTeacherUpdateSuccess,
    ieltsTeacherManagmentTeacherUpdateerror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherUpdate;
  });

  let {
    ieltsTeacherManagmentTeacherFindByIdSuccess,
    ieltsTeacherManagmentTeacherFindByIderror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherFindById;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsTeacherManagmentTeacherUpdateSuccess?.status == true
    ) {
      Swal.fire(
        "Updated",
        ieltsTeacherManagmentTeacherUpdateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsTeacherManagmentTeacherUpdateSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsTeacherManagmentTeacherUpdateSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsTeacherManagmentTeacherUpdateSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    dispatch(ieltsTeacherManagmentFindByIdAction(id));
  }, [dispatch, ieltsTeacherManagmentTeacherUpdateSuccess]);

  useEffect(() => {
    if (ieltsTeacherManagmentTeacherFindByIdSuccess) {
      setProfileImage(
        ieltsTeacherManagmentTeacherFindByIdSuccess?.data.imageUrl
      );
      setName(ieltsTeacherManagmentTeacherFindByIdSuccess?.data.teacherName);
      setPhone(ieltsTeacherManagmentTeacherFindByIdSuccess?.data.teacherPhone);
      setEmail(ieltsTeacherManagmentTeacherFindByIdSuccess?.data.teacherEmail);

      setSpecialization(
        ieltsTeacherManagmentTeacherFindByIdSuccess?.data?.ielts_teachers_specializations?.map(
          (value) => value?.ielts_category?.CategoryName
        )
      );

      // console.log(
      //   ieltsTeacherManagmentTeacherFindByIdSuccess?.data?.ielts_teachers_specializations?.map(
      //     (value) => value?.ielts_category?.CategoryName
      //   ),
      //   "dfdsfgf"
      // );

      setPanNumber(
        ieltsTeacherManagmentTeacherFindByIdSuccess?.data.teacherPanNo
      );
      setAdharNumber(
        ieltsTeacherManagmentTeacherFindByIdSuccess?.data.teacherAadharNo
      );
      setUserName(
        ieltsTeacherManagmentTeacherFindByIdSuccess?.data.teacherUserName
      );
      setPassword(ieltsTeacherManagmentTeacherFindByIdSuccess?.data.Password);
    }
  }, [ieltsTeacherManagmentTeacherFindByIdSuccess]);

  // console.log(specialization, "specializationspecialization");
  // console.log(avatarImage, "avatar");

  // Category find action

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("teacherTable"));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  //   HandleForm submit function

  const handleFormSubmit = () => {
    let isValid = true;
    const currentErr = { ...err };

    if (!name) {
      currentErr.name = "Enter teacher name";
      isValid = false;
    }
    if (!phone) {
      currentErr.title = "Enter teacher phonenumber";
      isValid = false;
    }
    if (!email) {
      currentErr.email = "Enter teacher email";
      isValid = false;
    }
    if (specialization?.length == 0) {
      currentErr.specialization = "Enter teacher specialization";
      isValid = false;
    }
    if (!panNumber) {
      currentErr.pan = "Enter teacher panNumber";
      isValid = false;
    }
    if (!adharNumber) {
      currentErr.adharNumber = "Enter teacher adharNumber";
      isValid = false;
    }
    if (!userName) {
      currentErr.userName = "Enter teacher userName";
      isValid = false;
    }
    if (!password) {
      currentErr.password = "create teacher password";
      isValid = false;
    }
    setErr(currentErr);
    if (!isValid) {
      return;
    }

    let cat = categoryList.filter((category) =>
      specialization.includes(category.CategoryName)
    );

    let categoryname = cat.map(item => item.CategoryName);
    let specializationId = cat.map(item => item.id);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("category", categoryname);
    formData.append("specialization", specializationId);

    // cat.forEach((item) => {
    //   formData.append("category", item.CategoryName);
    // });
    // cat.forEach((item) => {
    //   formData.append("specialization", item.id);
    // });

    formData.append("pan", panNumber);
    formData.append("adhar", adharNumber);
    formData.append("username", userName);
    formData.append("password", password);
    formData.append("image", profileImage);
    formData.append("id", id);
    dispatch(ieltsTeacherManagmentUpdateAction(formData));
    dispatch(successTrueMsgAction(true));
  };

  // console.log(ieltsTeacherManagmentTeacherFindByIdSuccess?.data, "specializationspecialization");
  // console.log(specialization, "specialization");
  // console.log(categoryList, "categoryListcategoryList");

  const handleSpecialization = (e) => {
    if (!specialization.includes(e.target.value)) {
      setSpecialization(e.target.value);
    }
  };

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Edit Teacher" url="/ielts" />
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
          paddingLeft: "16px",
          marginTop: "18px",
        }}
      >
        <TypographyText1 variant="h6" title="Enter Profile Details" />
      </Box>
      <Typography
        sx={{
          fontWeight: 400,
          fontFamily: "'Open Sans', sans-serif",
          color: "rgba(172, 177, 198, 1)",
          ml: 1.5,
        }}
      >
        Profile Picture
      </Typography>

      <Box mt={3} mb={5}>
        <Avatar
          src={avatarImage ? avatarImage : profileImage}
          alt="Avatar"
          sx={{ width: 120, height: 120, backgroundColor: "#F4F4F4", m: 1.5 }}
        >
          {!avatarImage && (
            <label htmlFor="avatar-file">
              <img
                src="/menuIcons/fileUploadCenter.png"
                alt="gsl"
                style={{
                  width: 35,
                  height: 35,
                  cursor: "pointer",
                }}
              />
            </label>
          )}
        </Avatar>
        <input
          type="file"
          accept="image/*"
          id="avatar-file"
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />
      </Box>
      <div>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Name"
              handleChange={(e) => {
                setName(e.target.value);
              }}
              widthSize="98.4%"
              value={name}
              required
              paramses
              InputType="text"
              dispcriptionHeight
            />
            {err.name ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.name}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Mobile number"
              widthSize="98.4%"
              handleChange={(e) => {
                if (/^\d{0,16}$/.test(e.target.value)) {
                  /^\d{0,9}$/.test(e.target.value)
                    ? setPhoneError(true)
                    : setPhoneError(false);
                  setPhone(e.target.value);
                } else {
                  setPhoneError(false);
                }
              }}
              value={phone}
              InputType="number"
            />
            {phoneError ? (
              <span
                style={{
                  color: "red",
                  fontSize: "13px",
                  fontWeight: 300,
                  paddingTop: "-20px",
                }}
              >
                Please Enter Atleast 10 Numbers
              </span>
            ) : null}
            {err.phone ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.phone}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Email address"
              handleChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
              widthSize="98.4%"
            />
            {err.email ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.email}
              </Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid item lg={6} xs={12}>
            <FormControl
              label="skajfhkasdfhjlas"
              sx={{
                width: "100%",
                // maxWidth: widthSize,
                "& .MuiOutlinedInput-input": {
                  boxSizing: "border-box",
                  py: "0px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              fullWidth
            >
              <InputLabel
                id="lead-source-label"
                className="formControlLabelRole"
                sx={{
                  fontWeight: 400,
                  color: "black",
                  fontSize: "14px",
                }}
              >
                Specialization
              </InputLabel>
              <Select
                value={specialization}
                multiple
                // renderValue={(selected) =>
                //   selected.length === 0
                //     ? "Select Specialisation"
                //     : selected.map((item) => item?.CategoryName)?.join(", ")
                // }
                onChange={handleSpecialization}
                labelId="lead-source-label"
                id="lead-source-select"
                className="formControlSelectRole"
                name={name}
                sx={{
                  width: null,
                  backgroundColor: "white",
                  // border:"1px solid #c4c5d6",
                  height: "46px",
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "100%",
                  },
                  "& .MuiInputBase-root": {
                    borderWidth: "1px",
                    borderRadius: "4px",
                    borderColor: "#c4c5d6",
                    border: "none",

                    "&:hover": {
                      border: "none",
                    },
                    "&.Mui-focused": {
                      border: "none",
                    },
                  },
                }}
              >
                {categoryList?.map((data) => (
                  <MenuItem
                    key={data.id}
                    value={data.CategoryName}
                    // selected={specialization.some(
                    //   (item) => item.id === data.id
                    // )}
                    selected={true}
                    style={{ minHeight: "20px" }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    {data.CategoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {err.specialization ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.specialization}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="PAN"
              handleChange={(e) => {
                setPanNumber(e.target.value);
              }}
              value={panNumber}
              widthSize="98.4%"
            />
            {err.pan ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.pan}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Aadhar"
              handleChange={(e) => {
                setAdharNumber(e.target.value);
              }}
              value={adharNumber}
              widthSize="98.4%"
            />
            {err.adharNumber ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.adharNumber}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <TypographyText1 variant="h6" title="Login Details" />
        </Box>

        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <InputField
              label="User name"
              handleChange={(e) => setUserName(e.target.value)}
              value={userName}
              widthSize="99%"
            />
            {err.userName ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.userName}
              </Typography>
            ) : (
              ""
            )}
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputField
              label="Password"
              handleChange={handlePasswordChange}
              value={password}
              InputType="password"
              widthSize="99%"
            />
            {!valid && (
              <span
                style={{
                  color: "red",
                  fontSize: "14px",
                  fontWeight: 300,
                  paddingTop: "-20px",
                }}
              >
                Password must have at least 6 characters and contain at least
                one symbol (!@#$%^&*)
              </span>
            )}
            {err.password ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                }}
              >
                {err.password}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "right", mb: 1 }}>
          <SubmitButton
            title="Save"
            submit="submit"
            widthSize="75px"
            heightSize="42px"
            type="click"
            handleSubmit={handleFormSubmit}
          />
        </Grid>
      </div>
    </MainContainer>
  );
}

export default IeltsTeacherEditForm;
