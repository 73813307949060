import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography
} from "@mui/material";
import { CustomDateField } from "../../customComponent/InputField";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

function ApprovalFilterSearch(props) {
  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, setseventhListOpen] = useState(false);
  const [eightListopen, seteightListOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [firstListopen, setFirstListOpen] = useState(false);
  const [dateListopen, setDateListOpen] = useState(false);
  const [date, setDate] = useState("");
  const statusList = ["Pending", "Contacted", "Closed"];

  const handleClearAllFunction = () => {
    setSelectedCategory(null);
  };

  const handleClick4 = () => {
    setDateListOpen(!dateListopen);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    setseventhListOpen(!sevenListopen);
  };
  const handleClick8 = () => {
    seteightListOpen(!eightListopen);
  };

  const handleClick1 = () => {
    setFirstListOpen(!firstListopen);
  };

  const handleClearClick = () => {
    setSelectedCategory("");
    setDate("");
    props.handleResetData(true);
    setDateListOpen(false);
    setFirstListOpen(false);
  };

  const handleCheck = (event, specialization, CheckedValue) => {
    console.log(event, "eventttttttttttttttt");
    if (CheckedValue !== "paymentDate") {
      setSelectedCategory(specialization);
      setSelectedCategory(
        specialization === selectedCategory ? null : specialization
      );
      props.LeadFilterFunction(
        specialization,
        CheckedValue,
        event.target.checked
      );
    } else {
      props.LeadFilterFunction(specialization, CheckedValue, event);
    }
  };

  const handleDate = (value) => {
    setDate(value);
    props.LeadFilterFunction(value, "Date", "");
  };

  // Course :::::::::::::

  const uniqueCourse = Array.from(
    new Set(
      props?.data
        ?.map((item) => item.lead_applied_short_list?.courseName)
        .filter((courseName) => courseName)
    )
  );

  // University :::::::::::::::

  const uniqueUniversity = Array.from(
    new Set(
      props?.data
        ?.map((item) => item.lead_applied_short_list?.universityName)
        .filter((courseName) => courseName)
    )
  );

  // Country :::::::::::::::

  const uniqueCountry = Array.from(
    new Set(
      props?.data
        ?.map((item) => item.lead_applied_short_list?.universityCountry)
        .filter((courseName) => courseName)
    )
  );

  console.log(uniqueCourse, "uniqueCourseuniqueCourseuniqueCourse");

  return (
    <>
      {selectedCategory && (
        <Chip
          sx={{
            "&.MuiChip-root": {
              pl: "5px",
              pr: "5px"
            }
          }}
          label={
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "700",
                color: "rgba(88, 94, 106, 1)"
              }}
            >
              Category:{" "}
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400"
                }}
                component={"span"}
              >
                {selectedCategory}
              </Typography>
            </Typography>
          }
          onDelete={handleClearAllFunction}
        />
      )}
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer"
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        {props.component !== "tutionFeeApproval" &&
        props.component !== "refundApproval" ? (
          <>
            <ListItemButton onClick={handleClick4}>
              <ListItemText primary="Branch" />
              {dateListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={dateListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {statusList &&
                      statusList?.map((status) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              checked={status === selectedCategory}
                              onChange={(e) => {
                                handleCheck(e, status, "status");
                              }}
                            />
                          }
                          label={status}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleClick1}>
              <ListItemText primary="Location" />
              {firstListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={firstListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    "& .example-custom-input > p": {
                      color: date ? "#000" : "#ACB1C6"
                    }
                  }}
                >
                  <CustomDateField
                    placeholder={"DD/MM/YY"}
                    handleChange={handleDate}
                    value={date}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        ) : (
          ""
        )}

        {props.component == "tutionFeeApproval" ||
        props.component == "refundApproval" ? (
          <>
            <ListItemButton onClick={handleClick1}>
              <ListItemText primary="Course" />
              {firstListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={firstListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {uniqueCourse &&
                      uniqueCourse?.map((status) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              checked={status === selectedCategory}
                              onChange={(e) => {
                                handleCheck(e, status, "course");
                              }}
                            />
                          }
                          label={status}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleClick2}>
              <ListItemText primary="University" />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {uniqueUniversity &&
                      uniqueUniversity?.map((status) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              checked={status === selectedCategory}
                              onChange={(e) => {
                                handleCheck(e, status, "university");
                              }}
                            />
                          }
                          label={status}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleClick3}>
              <ListItemText primary="Country" />
              {thirdListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {uniqueCountry &&
                      uniqueCountry?.map((status) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A"
                                }
                              }}
                              checked={status === selectedCategory}
                              onChange={(e) => {
                                handleCheck(e, status, "Country");
                              }}
                            />
                          }
                          label={status}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            {props.component !== "refundApproval" && (
              <>
                <ListItemButton onClick={handleClick7}>
                  <ListItemText primary="Payment type" />
                  {sevenListopen ? (
                    <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  ) : (
                    <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
                  )}
                </ListItemButton>
                <Collapse in={sevenListopen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <FormGroup>
                        {["Deposite", "Completed"]?.map((status) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  color: "#F8469A",
                                  "&.Mui-checked": {
                                    color: "#F8469A"
                                  }
                                }}
                                checked={status === selectedCategory}
                                onChange={(e) => {
                                  handleCheck(e, status, "paymentType");
                                }}
                              />
                            }
                            label={status}
                          />
                        ))}
                      </FormGroup>
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}

            <ListItemButton onClick={handleClick5}>
              <ListItemText primary="Universty type" />
              {fifthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={fifthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {["Indirect", "Direct"]?.map((status) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "#F8469A",
                              "&.Mui-checked": {
                                color: "#F8469A"
                              }
                            }}
                            checked={status === selectedCategory}
                            onChange={(e) => {
                              handleCheck(e, status, "universityType");
                            }}
                          />
                        }
                        label={status}
                      />
                    ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton onClick={handleClick6}>
              <ListItemText primary="Payment Date" />
              {sixthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={sixthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: 2
                  }}
                >
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      onChange={(e) => {
                        handleCheck(e, "", "paymentDate");
                      }}
                      allowClear={false}
                    />
                  </Space>

                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ width: "100%" }}
                    >
                      <DatePicker
                        label="Basic date picker"
                        sx={{ width: "250px" }} // Set width to your desired value
                      />
                    </DemoContainer>
                    <DemoContainer
                      components={["DatePicker"]}
                      sx={{ width: "100%" }}
                    >
                      <DatePicker
                        label="Basic date picker"
                        sx={{ width: "250px" }} // Set width to your desired value
                      />
                    </DemoContainer>
                  </LocalizationProvider> */}
                </ListItemButton>
              </List>
            </Collapse>
          </>
        ) : (
          ""
        )}
      </List>
    </>
  );
}

export default ApprovalFilterSearch;
