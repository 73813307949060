import React, { useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, Modal, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { FaqTable } from "./FaqTable";

import { universityFindCourseAction } from "../../../actions/universityActions/courseActions/universityCourseAction";
import {
  CountryDocFindAction,
  countryDocumentAdmissionApplicationFindAction,
} from "../../../actions/knowledgeBankActions/CountryDocsActions";
import { DestinationFormCreateAction } from "../../../actions/LandingPageAction";
import { findUniversityAction } from "../../../actions/universityActions/universityManagmentAction";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import Swal from "sweetalert2";
import { GetCountries } from "react-country-state-city";
import {
  DESTINATION_CREATE_ERR,
  DESTINATION_CREATE_SUCCESS,
} from "../../../constants/LandingPage/LandingPageConstant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function DestinationCreateForm(props) {
  const dispatch = useDispatch();
  const [DestinationName, setDestinationName] = useState("");
  const [DestinationImage, setDestinationImage] = useState("");
  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [Guide, setGuide] = useState("");
  const [GuideValue, setGuideValue] = useState("");
  const [Description, setDescription] = useState("");
  const [admissionProcedure, setAdmissionProcedure] = useState("");
  const [costEstimate, setCostestimate] = useState("");
  const [TopUniversities, setTopUniversities] = useState([]);
  const [TopCourses, setTopCourses] = useState([]);
  const [AdmissionDocuments, setAdmissionDocuments] = useState([]);
  const [Faq, setFaq] = useState([]);
  const [faqValue, setFaqValue] = useState("");
  const [faqAnswer, setFaqAnswer] = useState("");

  const [AllAdmissionDoc, setAllAdmissionDoc] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [UniversityList, SetTopUniversityList] = useState([]);
  const [universityData, setUniversityData] = useState([]);
  const [error, setError] = useState({});
  const [symbolArray, setSymbolArray] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState("");

  const [AllCourses, setAllCourses] = useState([]);

  const [open, setOpen] = useState(false);

  const Guideimage = useRef();
  const Destinationimage = useRef();

  const handleGuidUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setGuideValue(file.name);
      const reader = new FileReader();

      // Read the image file as a data URL
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const UploadGuid = reader.result;

        setGuide(UploadGuid);
      };
    }
  };

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDestinationimageValue(file.name);
      const reader = new FileReader();

      // Read the image file as a data URL
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const UploadDestinationimage = reader.result;
        setDestinationImage(UploadDestinationimage);
        setError((prev) => ({
          ...prev,
          ["DestinationImage"]: "",
        }));
      };
    }
  };

  // List country

  // let { countryDocFindSuccess } = useSelector((state) => {
  //   return state.findcountryMasterAdd;
  // });

  let {
    countryDocFindLoading,
    countryDocFindSuccess,
    countryDocFindError,
  } = useSelector((state) => {
    return state.CountryDocFind;
  });

  console.log(
    countryDocFindSuccess,
    "countryDocFindSuccesscountryDocFindSuccess"
  );

  let { universityFindSuccess } = useSelector((state) => {
    return state.findUniversity;
  });

  // Destination Create reducer

  let { destinationCreateSuccess, destinationCreateErr } = useSelector(
    (state) => {
      return state.destinationCreate;
    }
  );
  useEffect(() => {
    if (countryDocFindSuccess) {
      setAllCountry(countryDocFindSuccess);
    }
  }, [countryDocFindSuccess]);

  useEffect(() => {
    dispatch(CountryDocFindAction());
    GetCountries().then((result) => {
      setSymbolArray(result);
    });
  }, [dispatch]);

  useEffect(() => {
    if (universityFindSuccess) {
      setUniversityData(universityFindSuccess);
    }
  }, [universityFindSuccess]);

  useEffect(() => {
    if (universityData && universityData.length !== 0) {
      SetTopUniversityList(
        universityData
          .filter(
            (master) =>
              master.universityCountry?.split("(")[0] ===
              DestinationName.DocName?.split("(")[0]
          )
          .map((country, index) => ({
            universityname: country.universityName,
            id: country.id,
          }))
      );
    }
  }, [universityData]);

  // Admission_Documents

  const { countryDocAdmissionFindSuccess } = useSelector((state) => {
    return state.countryDocumentAdmissionApplicationFind;
  });

  useEffect(() => {
    if (countryDocAdmissionFindSuccess) {
      setAllAdmissionDoc(countryDocAdmissionFindSuccess);
    }
  }, [countryDocAdmissionFindSuccess]);

  // Destination_Name_handle _function

  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;
    setDestinationName(value);
    setError((prev) => ({
      ...prev,
      ["DestinationName"]: "",
    }));
    dispatch(findUniversityAction());
    dispatch(countryDocumentAdmissionApplicationFindAction(value?.id));
  };

  // TopCourses_reducer

  let { universityCourseFindSuccess } = useSelector((state) => {
    return state.universityFindCourse;
  });

  useEffect(() => {
    if (universityCourseFindSuccess) {
      setAllCourses((prevCourses) => {
        const updatedCourses = [...prevCourses];
        universityCourseFindSuccess.forEach((newCourse) => {
          const existingIndex = updatedCourses.findIndex(
            (course) => course.id === newCourse.id
          );
          if (existingIndex !== -1) {
            // Update existing course
            updatedCourses[existingIndex] = {
              id: newCourse.id,
              name: newCourse.courseTitle,
            };
          } else {
            // Add new course
            updatedCourses.push({
              id: newCourse.id,
              name: newCourse.courseTitle,
            });
          }
        });
        return updatedCourses;
      });
    }
  }, [universityCourseFindSuccess]);

  // FAQ

  const handleFaq = (event) => {
    setFaqValue(event.target.value);
  };

  const handleSave = () => {
    if (faqValue) {
      const newItem = {
        slNo: Faq.length + 1,
        value: faqValue,
        answer: faqAnswer,
      };
      setFaq((prevData) => [...prevData, newItem]);
      setFaqValue("");
      setOpen(false); // Clear the input field after saving
    }
  };

  const validate = () => {
    let errors = {};

    if (DestinationName === "") {
      errors.DestinationName = "Destination is required";
    }

    if (DestinationImage === "") {
      errors.DestinationImage = "DestinationImage is required";
    }
    if (Description === "") {
      errors.Description = "Description is required";
    }
    if (TopUniversities.length == 0) {
      errors.TopUniversities = "TopUniversities is required";
    }
    if (TopCourses.length == 0) {
      errors.TopCourses = "TopCourses is required";
    }

    if (AdmissionDocuments.length == 0) {
      errors.AdmissionDocuments = "AdmissionDocuments is required";
    }
    if (admissionProcedure === "") {
      errors.admissionProcedure = "admissionProcedure is required";
    }

    if (costEstimate === "") {
      errors.costEstimate = "costEstimate is required";
    }

    setError(errors);
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  // HandleCreate

  const HandleCreate = () => {
    if (validate()) {
      dispatch(
        DestinationFormCreateAction(
          DestinationName,
          DestinationImage,
          Guide,
          Description,
          TopUniversities,
          TopCourses,
          AdmissionDocuments,
          admissionProcedure,
          costEstimate,
          Faq,
          currencySymbol
        )
      );
      setUniversityData([]);
      setDestinationimageValue("");
      setGuideValue("");
      setDescription("");
      setTopUniversities([]);
      setTopCourses([]);
      setAdmissionDocuments([]);
      setAdmissionProcedure("");
      setCostestimate("");
      setFaq([]);
      setCurrencySymbol("");
    }
  };

  const handleClose = () => setOpen(false);

  // Success msg

  useEffect(() => {
    if (destinationCreateSuccess) {
      Swal.fire("Success", "Create successfully", "success");
      dispatch({ type: DESTINATION_CREATE_SUCCESS, payload: false });
    }
    if (destinationCreateErr) {
      Swal.fire("Error", destinationCreateErr?.message, "Error");
      dispatch({ type: DESTINATION_CREATE_ERR, payload: false });
    }
  }, [destinationCreateSuccess, destinationCreateErr]);
  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Destination" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={4} style={{}}>
          <SelectInputField
            label="Destination Name"
            handleSelectChange={handleDestinationChange}
            widthSize="120px"
            value={DestinationName}
            mapValue={allCountry}
            mapMethod="DestinationName"
            widthSelect="95%"
            requiredSymbol
          />
          <span
            style={{
              color: "#FF2400",
              fontSize: "12px",
            }}
          >
            {error?.DestinationName}
          </span>
        </Grid>
        <Grid item xs={12} lg={4} style={{ position: "relative" }}>
          <Grid>
            <InputField
              label="Destination Image"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
              error={Boolean(error?.DestinationImage)}
              helperText={error?.DestinationImage}
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 29 }}>
            <button
              onClick={() => Destinationimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={Destinationimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
            <span
              style={{
                color: "#FF2400",
                fontSize: "12px",
              }}
            >
              {error?.Destinationimage}
            </span>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4} style={{ position: "relative" }}>
          <InputField
            label="Guide"
            widthSize="92%"
            value={GuideValue}
            paramses
          ></InputField>
        </Grid>
        <Grid style={{ position: "absolute", top: 173, right: 49 }}>
          <button
            onClick={() => Guideimage.current.click()}
            style={{
              width: "100px",
              height: "40px",
              fontSize: "13px",
              fontFamily: "monospace",
              border: "none",
            }}
          >
            Browse
          </button>
          <input
            ref={Guideimage}
            type="file"
            accept="application/pdf"
            onChange={(event) => handleGuidUpload(event)}
            style={{ display: "none" }}
          ></input>
          <span
            style={{
              color: "#FF2400",
              fontSize: "12px",
            }}
          >
            {error?.Guideimage}
          </span>
        </Grid>

        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Description"
            handleChange={(e) => {
              setDescription(e.target.value);
              setError((prev) => ({
                ...prev,
                ["Description"]: "",
              }));
            }}
            widthSize="98%"
            value={Description}
            InputType="discription"
            dispcriptionHeight="95px"
            required
            paramses
            error={Boolean(error?.Description)}
            helperText={error?.Description}
          />
        </Grid>

        {/* List universities  */}

        <Grid item xs={12} lg={6} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Top Universities
          </label>
          <div style={{ marginTop: "20px" }}>
            <SelectInputField
              label="Select Universities"
              docName
              handleSelectChange={(event) => {
                console.log(event, "eventeventevent");
                // const {
                //   target: { value },
                // } = event;
                // console.log(value, "valuevalue");
                // const filteredArray = value.filter((obj) =>
                //   obj.hasOwnProperty("universityname")
                // );
                // setTopUniversities((prevRanking) => {
                //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

                //   filteredArray.forEach((selectedItem) => {
                //     const existingIndex = updatedRanking.findIndex(
                //       (item) => item.id === selectedItem.id
                //     );
                //     if (existingIndex !== -1) {
                //       // Item already exists in the ranking, remove it
                //       updatedRanking.splice(existingIndex, 1);
                //     } else {
                //       // Item doesn't exist in the ranking, add it

                //       updatedRanking.push({
                //         id: selectedItem.id,
                //         name: selectedItem.universityname,
                //       });
                //     }
                //     dispatch(universityFindCourseAction(selectedItem.id));
                //   });
                //   setError((prev) => ({
                //     ...prev,
                //     ["TopUniversities"]: "",
                //   }));
                //   return updatedRanking;
                // });
                setTopUniversities(event);
                event?.forEach((value) => {
                  dispatch(universityFindCourseAction(value?.id));
                });
                setError((prev) => ({
                  ...prev,
                  ["TopUniversities"]: "",
                }));
              }}
              widthSize="120px"
              value={TopUniversities}
              mapValue={UniversityList}
              mapMethod="alluniversities"
              multiple="true"
              requiredSymbol
            />

            <span
              style={{
                color: "#FF2400",
                fontSize: "12px",
              }}
            >
              {error?.TopUniversities}
            </span>
          </div>
        </Grid>

        <Grid item xs={12} lg={5} sx={{ mt: 4, ml: 3 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Top Courses
          </label>
          <div style={{ marginTop: "20px", width: "113%" }}>
            <SelectInputField
              label="Select Top Course"
              handleSelectChange={(event) => {
                setTopCourses(event);
                setError((prev) => ({
                  ...prev,
                  ["TopCourses"]: "",
                }));
              }}
              widthSize="120px"
              value={TopCourses}
              mapValue={AllCourses}
              mapMethod="allCourses"
              multiple="true"
              requiredSymbol
            />

            <span
              style={{
                color: "#FF2400",
                fontSize: "12px",
              }}
            >
              {error?.TopCourses}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Admission Requirement Documents
          </label>
          <div style={{ marginTop: "20px", width: "99%" }}>
            <SelectInputField
              label="Select Documents Needed"
              handleSelectChange={(event) => {
                setAdmissionDocuments(event);

                setError((prev) => ({
                  ...prev,
                  ["AdmissionDocuments"]: "",
                }));

                // return updateDoc;
                // });
              }}
              widthSize="200px"
              value={AdmissionDocuments}
              mapValue={AllAdmissionDoc}
              mapMethod="allAdmissionDoc"
              multiple="true"
              requiredSymbol
            />

            <span
              style={{
                color: "#FF2400",
                fontSize: "12px",
              }}
            >
              {error?.AdmissionDocuments}
            </span>
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Admission Procedure
          </label>
          <div style={{ marginTop: "20px" }}>
            <InputField
              label="Admission Procedure"
              handleChange={(e) => {
                setAdmissionProcedure(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["admissionProcedure"]: "",
                }));
              }}
              widthSize="98%"
              value={admissionProcedure}
              InputType="discription"
              dispcriptionHeight="95px"
              required
              paramses
              error={Boolean(error?.admissionProcedure)}
              helperText={error?.admissionProcedure}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <label
            style={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}
          >
            Cost estimated
          </label>
          <div style={{ marginTop: "20px", display: "flex" }}>
            <Grid sx={{ width: "25%", mr: 2 }}>
              <SelectInputField
                label="SelectCurrency"
                handleSelectChange={(e) => setCurrencySymbol(e.target.value)}
                widthSize="10px"
                value={currencySymbol}
                mapValue={symbolArray}
                mapMethod="currencyList"
                requiredSymbol
                required
              />
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <InputField
                label="Cost"
                handleChange={(e) => {
                  setCostestimate(e.target.value);
                  setError((prev) => ({
                    ...prev,
                    ["costEstimate"]: "",
                  }));
                }}
                widthSize="98%"
                value={costEstimate}
                required
                paramses
                error={Boolean(error?.costEstimate)}
                helperText={error?.costEstimate}
              />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 4 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label
              style={{
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              FAQ
            </label>
            <div>
              <button
                style={{
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  fontWeight: "700",
                  border: "1px solid",
                }}
                onClick={() => setOpen(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <FaqTable
              columns={[
                {
                  title: "SL NO",
                  field: "SL NO",
                },
                {
                  title: "QUESTION",
                  field: "QUESTION",
                },
                {
                  title: "ANSWER",
                  field: "ANSWER",
                },
              ]}
              data={Faq}
              component="FaqTable"
              isPresentMap={setFaq}
            />
          </div>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "50px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>

      {/* FAQ modal */}

      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: "bold" }}
            >
              FAQ:
            </Typography>

            <div
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                width: "500px",
              }}
            >
              <InputField
                label="Question"
                handleChange={handleFaq}
                widthSize="490px"
                value={faqValue}
                InputType="discription"
                dispcriptionHeight="42px"
                required
                paramses
              />
              <InputField
                label="Answer"
                handleChange={(e) => setFaqAnswer(e.target.value)}
                widthSize="490px"
                InputType="discription"
                value={faqAnswer}
                dispcriptionHeight="220px"
                required
                paramses
              />
            </div>
            <Grid
              item
              xs={4}
              lg={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "10px",
              }}
            >
              <SubmitButton
                title="Save"
                submit=""
                widthSize="100px"
                heightSize="38px"
                type="click"
                handleSubmit={handleSave}
              />
            </Grid>
          </Box>
        </Modal>
      )}
    </MainContainer>
  );
}

export default DestinationCreateForm;
