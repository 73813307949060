import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import {
  NormalInputField,
  NormalSelect,
} from "../../customComponent/InputField";

import { useDispatch, useSelector } from "react-redux";
import {
  developerCreateMenuAction,
  developerFindAllMenu,
} from "../../../actions/developersActions";

function MenuTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [data, setData] = useState([]);

  const { findAllMenuSuccess } = useSelector((state) => {
    return state.findAllMenuInDeveloperTab;
  });

  const { createNewMenuSuccess } = useSelector((state) => {
    return state.createMenuInDeveloperTab;
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "name, value");
    setModalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(developerCreateMenuAction(modalData));
  };

  const convertToDate = (date) => {
    let timestamp = new Date(date);
    return timestamp.toLocaleDateString();
  };

  const columns = [
    {
      title: "SL NO",
    },
    {
      title: "menu",
    },
    {
      title: "path",
    },
    {
      title: "created date",
    },
    {
      title: "updated date",
    },
    {
      title: "action",
    },
  ];

  useEffect(() => {
    dispatch(developerFindAllMenu());
  }, [createNewMenuSuccess]);

  useEffect(() => {
    if (findAllMenuSuccess) {
      setData(
        findAllMenuSuccess?.map((item) => ({
          name: item?.name,
          createdAt: item?.createdAt,
          updatedAt: item?.updatedAt,
          path: item?.path,
        }))
      );
    }
  }, [findAllMenuSuccess]);

  // const data = [
  //   {
  //     name: "Home",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  //   {
  //     name: "Leads",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  //   {
  //     name: "Document Upload",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  //   {
  //     name: "Partner",
  //     createdAt: "Thu Apr 01 2024 12:21:51 GMT+0530 (India Standard Time) ",
  //     updatedAt: "Thu Apr 04 2024 12:00:00 GMT+0530 (India Standard Time) ",
  //     path: "/",
  //   },
  // ];

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            margin: "0",
            width: "calc(100% - 30px)",
            maxWidth: "850px",
            borderRadius: "4px",
          },
        }}
        PaperProps={{
          style: {
            alignSelf: "flex-start",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#05050F",
              }}
            >
              Add new menu
            </Typography>
            <CloseIcon
              sx={{
                fontSize: "30px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>

          <DialogContent
            sx={{
              "& input": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                color: "#ACB1C6",
              },
              "& textarea": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                resize: "none",
                borderRadius: "4px",
                // mt: "24px",
              },
              "& textarea::placeholder": {
                color: "#ACB1C6",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mt: "13px",
                "& input": {
                  boxSizing: "content-box",
                },
              }}
            >
              <NormalInputField
                label="Menu"
                value={modalData?.name}
                name="name"
                handleChange={handleChange}
              />
              <NormalInputField
                label="Path"
                value={modalData?.path}
                name="path"
                handleChange={handleChange}
              />
            </Box>
          </DialogContent>

          <DialogActions
            sx={{
              mb: "20px",
              mr: "15px",
            }}
          >
            <Button
              type={"text"}
              sx={{
                padding: "16px 24px",
                color: "white",
                backgroundColor: "rgba(20, 30, 60, 1)",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#141E3C !important",
            color: "#fff",
            fontWeight: "700",
            textTransform: "capitalize",
            px: "15px",
            mb: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          Create
        </Button>
      </Box>

      <div
        style={{
          overflowX: "scroll",
          border: "0px solid #ffff",
        }}
        className="scrollBar"
      >
        <Table className="EmployeeJobTable">
          <TableHead>
            <TableRow className="EmployeeJobTableCell">
              {columns?.map((column) => {
                return (
                  <TableCell
                    key={column.field}
                    className="EmployeeJobTableCell LeadCommon"
                    style={{ padding: "10px 16px", whiteSpace: "nowrap" }}
                  >
                    <TableSortLabel
                      className="LeadHeaderText"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: "700",
                        color: "#666E7D",
                      }}
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, indx) => (
              <TableRow className="EmployeeJobTable" key={indx}>
                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {indx + 1}
                </TableCell>
                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {item.name}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {item.path}
                </TableCell>
                
                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {convertToDate(item.createdAt)}
                </TableCell>

                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  {convertToDate(item.updatedAt)}
                </TableCell>

                <TableCell
                  style={{
                    padding: "5px 16px",
                    whiteSpace: "nowrap",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "#141E3C !important",
                      color: "#fff",
                      textTransform: "capitalize",
                      py: "4px",
                      px: "8px",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#fff !important",
                      color: "#FF3333",
                      textTransform: "capitalize",
                      border: "1px solid #FF3333",
                      py: "4px",
                      px: "8px",
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter />
        </Table>
      </div>
    </div>
  );
}

export default MenuTable;
