import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeadBreadcrumbs from "../../breadcrumbs/breadcrumbs";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import BasicTabs from "../../Tabs/TabsMain";
import { courseLevelMasterFindAction } from "../../../actions/courseMasterAction";
import { useDispatch, useSelector } from "react-redux";
import CourseManagment from "./CourseManagment";

function UniversityCourseMain(props) {
  const [tabValue, setTabValue] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(courseLevelMasterFindAction());
  }, []);

  const { courseLevelFindSuccess } = useSelector((state) => {
    return state.courseLevelMasterFind;
  });

  const tabProps = {};

  courseLevelFindSuccess?.data?.forEach((value, index) => {
    tabProps[`tab${index + 1}`] = (
      <CourseManagment tabName={value?.courseLevel} />
    );
    tabProps[`tab${index + 1}Name`] = value?.courseLevel;
  });

  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader
          headTitle="Course List"
          url={`/university/addUniversity/ViewUniversity/${id}`}
        />
        {/* {tabValue === 0 && (
          <HeadBreadcrumbs
            head="home"
            sechead="university"
            first="Course"
            main="course Managment"
          />
        )}

        {tabValue === 1 && (
          <HeadBreadcrumbs
            head="home"
            sechead="university"
            first="Course"
            main="course Managment"
          />
        )}

        {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="university"
            first="Course"
            main="course Managment"
          />
        )} */}

        <BasicTabs
          {...tabProps}
          callback={(data) => setTabValue(data)}
          // {...tabComponents}
          head="Home"
          sechead="Course"
        />
      </MainContainer>
    </>
  );
}

export default UniversityCourseMain;
