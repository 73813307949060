// LandingPage_Top_Navigation_Create

export const TOPNAVIGATION_CREATE_REQUEST = "TOPNAVIGATION_CREATE_REQUEST";
export const TOPNAVIGATION_CREATE_SUCCESS = "TOPNAVIGATION_CREATE_SUCCESS";
export const TOPNAVIGATION_CREATE_ERR = "TOPNAVIGATION_CREATE_ERR ";

// LandingPage_Top_Navigation_Find

export const TOPNAVIGATION_FIND_REQUEST = "TOPNAVIGATION_FIND_REQUEST";
export const TOPNAVIGATION_FIND_SUCCESS = "TOPNAVIGATION_FIND_SUCCESS";
export const TOPNAVIGATION_FIND_ERR = "TOPNAVIGATION_FIND_ERR ";

// LandingPage_Top_Navigation_Delete

export const TOPNAVIGATION_DELETE_REQUEST = "TOPNAVIGATION_DELETE_REQUEST";
export const TOPNAVIGATION_DELETE_SUCCESS = "TOPNAVIGATION_DELETE_SUCCESS";
export const TOPNAVIGATION_DELETE_ERR = "TOPNAVIGATION_DELETE_ERR ";

// LandingPage_Top_Navigation_FindDate_For_edit

export const TOPNAVIGATION_FINDEDIT_REQUEST = "TOPNAVIGATION_FINDEDIT_REQUEST";
export const TOPNAVIGATION_FINDEDIT_SUCCESS = "TOPNAVIGATION_FINDEDIT_SUCCESS";
export const TOPNAVIGATION_FINDEDIT_ERR = "TOPNAVIGATION_FINDEDIT_ERR ";

// LandingPage_Top_Navigation_Update

export const TOPNAVIGATION_UPDATE_REQUEST = "TOPNAVIGATION_UPDATE_REQUEST";
export const TOPNAVIGATION_UPDATE_SUCCESS = "TOPNAVIGATION_UPDATE_SUCCESS";
export const TOPNAVIGATION_UPDATE_ERR = "TOPNAVIGATION_UPDATE_ERR ";

// LandingPage_DestinationForm_create

export const DESTINATION_CREATE_REQUEST = "DESTINATION_CREATE_REQUEST";
export const DESTINATION_CREATE_SUCCESS = "DESTINATION_CREATE_SUCCESS";
export const DESTINATION_CREATE_ERR = "DESTINATION_CREATE_ERR ";

export const DESTINATION_EDIT_REQUEST = "DESTINATION_EDIT_REQUEST";
export const DESTINATION_EDIT_SUCCESS = "DESTINATION_EDIT_SUCCESS";
export const DESTINATION_EDIT_ERR = "DESTINATION_EDIT_ERR ";

// LandingPage_DestinationFind_Request

export const DESTINATION_FIND_REQUEST = "DESTINATION_FIND_REQUEST";
export const DESTINATION_FIND_SUCCESS = "DESTINATION_FIND_SUCCESS";
export const DESTINATION_FIND_ERR = "DESTINATION_FIND_ERR ";

export const DESTINATION_FIND_BY_ID_REQUEST = "DESTINATION_FIND_BY_ID_REQUEST";
export const DESTINATION_FIND_BY_ID_SUCCESS = "DESTINATION_FIND_BY_ID_SUCCESS";
export const DESTINATION_FIND_BY_ID_ERR = "DESTINATION_FIND_BY_ID_ERR ";


// LandingPage_DestinationDelete_Request

export const DESTINATION_DELETE_REQUEST = "DESTINATION_DELETE_REQUEST";
export const DESTINATION_DELETE_SUCCESS = "DESTINATION_DELETE_SUCCESS";
export const DESTINATION_DELETE_ERR = "DESTINATION_DELETE_ERR ";

// SERVICE START
export const SERVICE_CREATE_REQUEST = "SERVICE_CREATE_REQUEST";
export const SERVICE_CREATE_SUCCESS = "SERVICE_CREATE_SUCCESS";
export const SERVICE_CREATE_ERR = "SERVICE_CREATE_ERR ";

export const SERVICE_EDIT_REQUEST = "SERVICE_EDIT_REQUEST";
export const SERVICE_EDIT_SUCCESS = "SERVICE_EDIT_SUCCESS";
export const SERVICE_EDIT_ERR = "SERVICE_EDIT_ERR ";

export const SERVICE_FIND_REQUEST = "SERVICE_FIND_REQUEST";
export const SERVICE_FIND_SUCCESS = "SERVICE_FIND_SUCCESS";
export const SERVICE_FIND_ERR = "SERVICE_FIND_ERR ";

export const SERVICE_FIND_BY_ID_REQUEST = "SERVICE_FIND_BY_ID_REQUEST";
export const SERVICE_FIND_BY_ID_SUCCESS = "SERVICE_FIND_BY_ID_SUCCESS";
export const SERVICE_FIND_BY_ID_ERR = "SERVICE_FIND_BY_ID_ERR";

// BLOGS CONSTATNT

export const NEWS_CREATE_REQUEST = "NEWS_CREATE_REQUEST";
export const NEWS_CREATE_SUCCESS = "NEWS_CREATE_SUCCESS";
export const NEWS_CREATE_ERR = "NEWS_CREATE_ERR ";

export const NEWS_FIND_REQUEST = "NEWS_FIND_REQUEST";
export const NEWS_FIND_SUCCESS = "NEWS_FIND_SUCCESS";
export const NEWS_FIND_ERR = "NEWS_FIND_ERR ";

export const NEWS_FIND_BY_ID_REQUEST = "NEWS_FIND_BY_ID_REQUEST";
export const NEWS_FIND_BY_ID_SUCCESS = "NEWS_FIND_BY_ID_SUCCESS";
export const NEWS_FIND_BY_ID_ERR = "NEWS_FIND_BY_ID_ERR ";

export const NEWS_EDIT_REQUEST = "NEWS_EDIT_REQUEST";
export const NEWS_EDIT_SUCCESS = "NEWS_EDIT_SUCCESS";
export const NEWS_EDIT_ERR = "NEWS_EDIT_ERR ";

export const NEWS_DELETE_REQUEST = "NEWS_DELETE_REQUEST";
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_ERR = "NEWS_DELETE_ERR ";

// BLOGS CONSTANT

export const BLOGS_CREATE_REQUEST = "BLOGS_CREATE_REQUEST";
export const BLOGS_CREATE_SUCCESS = "BLOGS_CREATE_SUCCESS";
export const BLOGS_CREATE_ERR = "BLOGS_CREATE_ERR ";

export const BLOGS_EDIT_GET_REQUEST = "BLOGS_EDIT_GET_REQUEST";
export const BLOGS_EDIT_GET_SUCCESS = "BLOGS_EDIT_GET_SUCCESS";
export const BLOGS_EDIT_GET_ERR = "BLOGS_EDIT_GET_ERR ";

export const BLOGS_EDIT_REQUEST = "BLOGS_EDIT_REQUEST";
export const BLOGS_EDIT_SUCCESS = "BLOGS_EDIT_SUCCESS";
export const BLOGS_EDIT_ERR = "BLOGS_EDIT_ERR ";

export const BLOGS_FIND_REQUEST = "BLOGS_FIND_REQUEST";
export const BLOGS_FIND_SUCCESS = "BLOGS_FIND_SUCCESS";
export const BLOGS_FIND_ERR = "BLOGS_FIND_ERR ";

export const BLOGS_DELETE_REQUEST = "BLOGS_DELETE_REQUEST";
export const BLOGS_DELETE_SUCCESS = "BLOGS_DELETE_SUCCESS";
export const BLOGS_DELETE_ERR = "BLOGS_DELETE_ERR ";

// TESTIMONIAL CONSTANT

export const TESTIMONIAL_CREATE_REQUEST = "TESTIMONIAL_CREATE_REQUEST";
export const TESTIMONIAL_CREATE_SUCCESS = "TESTIMONIAL_CREATE_SUCCESS";
export const TESTIMONIAL_CREATE_ERR = "TESTIMONIAL_CREATE_ERR ";

export const TESTIMONIAL_FIND_REQUEST = "TESTIMONIAL_FIND_REQUEST";
export const TESTIMONIAL_FIND_SUCCESS = "TESTIMONIAL_FIND_SUCCESS";
export const TESTIMONIAL_FIND_ERR = "TESTIMONIAL_FIND_ERR";

export const TESTIMONIAL_DELETE_REQUEST = "TESTIMONIAL_DELETE_REQUEST";
export const TESTIMONIAL_DELETE_SUCCESS = "TESTIMONIAL_DELETE_SUCCESS";
export const TESTIMONIAL_DELETE_ERR = "TESTIMONIAL_DELETE_ERR";

export const TESTIMONIAL_FIND_BY_ID_REQUEST = "TESTIMONIAL_FIND_BY_ID_REQUEST";
export const TESTIMONIAL_FIND_BY_ID_SUCCESS = "TESTIMONIAL_FIND_BY_ID_SUCCESS";
export const TESTIMONIAL_FIND_BY_ID_ERR = "TESTIMONIAL_FIND_BY_ID_ERR";

export const TESTIMONIAL_EDIT_REQUEST = "TESTIMONIAL_EDIT_REQUEST";
export const TESTIMONIAL_EDIT_SUCCESS = "TESTIMONIAL_EDIT_SUCCESS";
export const TESTIMONIAL_EDIT_ERR = "TESTIMONIAL_EDIT_ERR ";

// FEATUREDADS CONSTANT
export const FEATUREDADS_CREATE_REQUEST = "FEATUREDADS_CREATE_REQUEST";
export const FEATUREDADS_CREATE_SUCCESS = "FEATUREDADS_CREATE_SUCCESS";
export const FEATUREDADS_CREATE_ERR = "FEATUREDADS_CREATE_ERR";

export const FEATUREDADS_FIND_REQUEST = "FEATUREDADS_FIND_REQUEST";
export const FEATUREDADS_FIND_SUCCESS = "FEATUREDADS_FIND_SUCCESS";
export const FEATUREDADS_FIND_ERR = "FEATUREDADS_FIND_ERR ";

export const FEATUREDADS_DELETE_REQUEST = "FEATUREDADS_DELETE_REQUEST";
export const FEATUREDADS_DELETE_SUCCESS = "FEATUREDADS_DELETE_SUCCESS";
export const FEATUREDADS_DELETE_ERR = "FEATUREDADS_DELETE_ERR ";

export const FEATURED_ADS_FIND_BY_ID_REQUEST =
  "FEATURED_ADS_FIND_BY_ID_REQUEST";
export const FEATURED_ADS_FIND_BY_ID_SUCCESS =
  "FEATURED_ADS_FIND_BY_ID_SUCCESS";
export const FEATURED_ADS_FIND_BY_ID_ERR = "FEATURED_ADS_FIND_BY_ID_ERR";

export const FEATURED_ADS_EDIT_REQUEST = "FEATURED_ADS_EDIT_REQUEST";
export const FEATURED_ADS_EDIT_SUCCESS = "FEATURED_ADS_EDIT_SUCCESS";
export const FEATURED_ADS_EDIT_ERR = "FEATURED_ADS_EDIT_ERR ";

// EVENTS CONSTANTS

export const EVENTS_CREATE_REQUEST = "EVENTS_CREATE_REQUEST";
export const EVENTS_CREATE_SUCCESS = "EVENTS_CREATE_SUCCESS";
export const EVENTS_CREATE_ERR = "EVENTS_CREATE_ERR";

export const EVENTS_FIND_REQUEST = "EVENTS_FIND_REQUEST";
export const EVENTS_FIND_SUCCESS = "EVENTS_FIND_SUCCESS";
export const EVENTS_FIND_ERR = "EVENTS_FIND_ERR";

export const EVENTS_FIND_BY_ID_REQUEST = "EVENTS_FIND_BY_ID_REQUEST";
export const EVENTS_FIND_BY_ID_SUCCESS = "EVENTS_FIND_BY_ID_SUCCESS";
export const EVENTS_FIND_BY_ID_ERR = "EVENTS_FIND_BY_ID_ERR";

export const EVENTS_EDIT_REQUEST = "EVENTS_EDIT_REQUEST";
export const EVENTS_EDIT_SUCCESS = "EVENTS_EDIT_SUCCESS";
export const EVENTS_EDIT_ERR = "EVENTS_EDIT_ERR";

export const EVENTS_DELETE_REQUEST = "EVENTS_DELETE_REQUEST";
export const EVENTS_DELETE_SUCCESS = "EVENTS_DELETE_SUCCESS";
export const EVENTS_DELETE_ERR = "EVENTS_DELETE_ERR";

// TALK TO EXPERT

export const TALK_TO_EXPERT_FIND_REQUEST = "TALK_TO_EXPERT_FIND_REQUEST";
export const TALK_TO_EXPERT_FIND_SUCCESS = "TALK_TO_EXPERT_FIND_SUCCESS";
export const TALK_TO_EXPERT_FIND_ERR = "TALK_TO_EXPERT_FIND_ERR";

export const TALK_TO_EXPERT_CHANGE_STATUS_REQUEST =
  "TALK_TO_EXPERT_CHANGE_STATUS_REQUEST";
export const TALK_TO_EXPERT_CHANGE_STATUS_SUCCESS =
  "TALK_TO_EXPERT_CHANGE_STATUS_SUCCESS";
export const TALK_TO_EXPERT_CHANGE_STATUS_ERR =
  "TALK_TO_EXPERT_CHANGE_STATUS_ERR";

export const TALK_TO_EXPERT_DELETE_STATUS_REQUEST =
  "TALK_TO_EXPERT_DELETE_STATUS_REQUEST";
export const TALK_TO_EXPERT_DELETE_STATUS_SUCCESS =
  "TALK_TO_EXPERT_DELETE_STATUS_SUCCESS";
export const TALK_TO_EXPERT_DELETE_STATUS_ERR =
  "TALK_TO_EXPERT_DELETE_STATUS_ERR";

// SCHOLARSHIP CONSTANTS

export const SCHOLARSHIP_APPLICATION_CREATE_REQUEST =
  "SCHOLARSHIP_APPLICATION_CREATE_REQUEST";
export const SCHOLARSHIP_APPLICATION_CREATE_SUCCESS =
  "SCHOLARSHIP_APPLICATION_CREATE_SUCCESS";
export const SCHOLARSHIP_APPLICATION_CREATE_ERR =
  "SCHOLARSHIP_APPLICATION_CREATE_ERR";

// SCHOLARSHIP CONVERT TO LEAD CONSTANT

export const SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_REQUEST =
  "SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_REQUEST";
export const SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_SUCCESS =
  "SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_SUCCESS";
export const SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_ERR =
  "SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_ERR";

// TALKTOEXPERT CONVERT TO LEAD CONSTANT

export const TALKTOEXPERT_CONVERT_TO_LEAD_REQUEST =
  "TALKTOEXPERT_CONVERT_TO_LEAD_REQUEST";
export const TALKTOEXPERT_CONVERT_TO_LEAD_SUCCESS =
  "TALKTOEXPERT_CONVERT_TO_LEAD_SUCCESS";
export const TALKTOEXPERT_CONVERT_TO_LEAD_ERR =
  "TALKTOEXPERT_CONVERT_TO_LEAD_ERR";

// Office create
export const CREATE_OFFICE_REQUEST = "CREATE_OFFICE_REQUEST";
export const CREATE_OFFICE_SUCCESS = "CREATE_OFFICE_SUCCESS";
export const CREATE_OFFICE_ERR = "CREATE_OFFICE_ERR";

// Office Edit
export const EDIT_OFFICE_REQUEST = "EDIT_OFFICE_REQUEST";
export const EDIT_OFFICE_SUCCESS = "EDIT_OFFICE_SUCCESS";
export const EDIT_OFFICE_ERR = "EDIT_OFFICE_ERR";

// Office list
export const GET_OFFICE_REQUEST = "GET_OFFICE_REQUEST";
export const GET_OFFICE_SUCCESS = "GET_OFFICE_SUCCESS";
export const GET_OFFICE_ERR = "GET_OFFICE_ERR";

// Office list by id
export const GET_OFFICE_BY_ID_REQUEST = "GET_OFFICE_BY_ID_REQUEST";
export const GET_OFFICE_BY_ID_SUCCESS = "GET_OFFICE_BY_ID_SUCCESS";
export const GET_OFFICE_BY_ID_ERR = "GET_OFFICE_BY_ID_ERR";

// Office delete by id
export const DELETE_OFFICE_BY_ID_REQUEST = "DELETE_OFFICE_BY_ID_REQUEST";
export const DELETE_OFFICE_BY_ID_SUCCESS = "DELETE_OFFICE_BY_ID_SUCCESS";
export const DELETE_OFFICE_BY_ID_ERR = "DELETE_OFFICE_BY_ID_ERR";

// add new gallery item
export const ADD_NEW_GALLERY_ITEMS_REQUEST = "ADD_NEW_GALLERY_ITEMS_REQUEST";
export const ADD_NEW_GALLERY_ITEMS_SUCCESS = "ADD_NEW_GALLERY_ITEMS_SUCCESS";
export const ADD_NEW_GALLERY_ITEMS_ERR = "ADD_NEW_GALLERY_ITEMS_ERR";

// find gallery item
export const FIND_GALLERY_ITEMS_REQUEST = "FIND_GALLERY_ITEMS_REQUEST";
export const FIND_GALLERY_ITEMS_SUCCESS = "FIND_GALLERY_ITEMS_SUCCESS";
export const FIND_GALLERY_ITEMS_ERR = "FIND_GALLERY_ITEMS_ERR";

// find gallery item by id
export const FIND_GALLERY_ITEMS_BY_ID_REQUEST = "FIND_GALLERY_ITEMS_BY_ID_REQUEST";
export const FIND_GALLERY_ITEMS_BY_ID_SUCCESS = "FIND_GALLERY_ITEMS_BY_ID_SUCCESS";
export const FIND_GALLERY_ITEMS_BY_ID_ERR = "FIND_GALLERY_ITEMS_BY_ID_ERR";

// update gallery item by id
export const UPDATE_GALLERY_ITEMS_BY_ID_REQUEST = "UPDATE_GALLERY_ITEMS_BY_ID_REQUEST";
export const UPDATE_GALLERY_ITEMS_BY_ID_SUCCESS = "UPDATE_GALLERY_ITEMS_BY_ID_SUCCESS";
export const UPDATE_GALLERY_ITEMS_BY_ID_ERR = "UPDATE_GALLERY_ITEMS_BY_ID_ERR";

// update gallery item by id
export const DELETE_GALLERY_ITEMS_BY_ID_REQUEST = "DELETE_GALLERY_ITEMS_BY_ID_REQUEST";
export const DELETE_GALLERY_ITEMS_BY_ID_SUCCESS = "DELETE_GALLERY_ITEMS_BY_ID_SUCCESS";
export const DELETE_GALLERY_ITEMS_BY_ID_ERR = "DELETE_GALLERY_ITEMS_BY_ID_ERR";
