import {
  Box,
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Reorder from "../Reorder";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUniversityCommissionAction,
  listUniversityCommissionAction,
} from "../../../actions/reportActions/reportsActions";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import Swal from "sweetalert2";
import UniversityCommessionPopUp from "./UniversityCommessionPopUp";
import { successTrueMsgAction } from "../../../actions/globalAction";

function UniversityCommesions() {
  let { listuniversityCommissionSuccess } = useSelector(
    (state) => state.listUniversityCommission
  );

  let { adduniversityCommissionSuccess } = useSelector(
    (state) => state.addUniversityCommission
  );

  let { deleteuniversityCommissionSuccess } = useSelector(
    (state) => state.deleteUniversityCommission
  );

  let { updateUniversityCommissionSuccess } = useSelector((state) => {
    return state.updateUniversityCommission;
  });

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listUniversityCommissionAction());
  }, [
    adduniversityCommissionSuccess,
    deleteuniversityCommissionSuccess,
    updateUniversityCommissionSuccess,
  ]);

  useEffect(() => {
    if (updateUniversityCommissionSuccess && successAlertMsgSuccess) {
      Swal.fire(
        "Updated!",
        "university commission updated Successfully.",
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [updateUniversityCommissionSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (listuniversityCommissionSuccess) {
      setRows(listuniversityCommissionSuccess.data);
    }
  }, [listuniversityCommissionSuccess]);

  const [rows, setRows] = useState([]);

  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [open, setOpen] = useState(false);
  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLeadActionButtonShow(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <UniversityCommessionPopUp
        handleClose={handleClose}
        open={open}
        id={tableId}
      />
      <Box
        sx={{
          width: "100%",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Table
          sx={{
            minWidth: "1700px",
            "& .MuiTableCell-root": {
              borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
            },
          }}
        >
          <TableHead
            sx={{
              background: "#F9F9FB",
              "& th": {
                textTransform: "uppercase",
                fontWeight: "600",
                fontFamily: "Inter",
                color: "#666E7D",
              },
            }}
          >
            <TableRow>
              <TableCell>
                University <Reorder />
              </TableCell>
              <TableCell>
                Territory <Reorder />
              </TableCell>
              <TableCell>
                Territory restrictions <Reorder />
              </TableCell>
              <TableCell>
                UG <Reorder />
              </TableCell>
              <TableCell>
                PG <Reorder />
              </TableCell>
              <TableCell>
                PS <Reorder />
              </TableCell>
              <TableCell>
                FO <Reorder />
              </TableCell>
              <TableCell>
                Pre-M <Reorder />
              </TableCell>
              <TableCell>
                PHD <Reorder />
              </TableCell>
              <TableCell>
                Authority Cases <Reorder />
              </TableCell>
              <TableCell>
                Course Type <Reorder />
              </TableCell>
              <TableCell>
                Course Name Exlusion <Reorder />
              </TableCell>
              <TableCell>
                Notes <Reorder />
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              background: "#fff",
              border: "1px solid rgba(220, 223, 227, 0.50)",
            }}
          >
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.universityName || "-"}</TableCell>
                <TableCell>{row.territory || "-"}</TableCell>
                <TableCell>{row.territory_Restriction || "-"}</TableCell>
                <TableCell>{row.ug ? row.ug + "% " : "-"}</TableCell>
                <TableCell>{row.pg ? row.pg + "% " : "-"}</TableCell>
                <TableCell>{row.ps || "-"}</TableCell>
                <TableCell>{row.fo ? row.fo + "% " : "-"}</TableCell>
                <TableCell>{row.preM || "-"}</TableCell>
                <TableCell>{row.phd ? row.phd + "% " : "-"}</TableCell>
                <TableCell>{row.authority_cases || "-"}</TableCell>
                <TableCell>{row.course_type || "-"}</TableCell>
                <TableCell>{row.course_name_exclusion || "-"}</TableCell>
                <TableCell>{row.notes || "-"}</TableCell>
                <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}>
                  <SlOptionsVertical
                    id="demo-positioned-button"
                    aria-controls={
                      leadOpenActionButton ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={leadOpenActionButton ? "true" : undefined}
                    onClick={(e) => {
                      handleClickAction(e, row.id);
                    }}
                    variant="outlined"
                    style={{
                      marginLeft: "10px",
                      color: "#F80B7A",
                      cursor: "pointer",
                    }}
                    sx={{
                      backgroundColor: "#141E3C",
                      size: "10px",
                      padding: 1,
                      margin: 3,
                      color: "#fff",
                      width: "75px",
                      height: "40px",
                    }}
                  />
                  {/* TABLE ACTION MENU START*/}
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    open={leadActionButtonShow}
                    onClose={handleCloseAction}
                    anchorEl={leadActionButtonShow}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom", // Change from "top" to "bottom"
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top", // Change from "top" to "bottom"
                      horizontal: "left",
                    }}
                    sx={{
                      borderColor: "black",
                      borderRadius: "24px",
                      mt: 1.5,
                      width: "auto",
                      height: "auto",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <MenuItem>
                        <AiOutlineEye
                          style={{
                            fontSize: "lg",
                            width: "20px",
                            height: "19px",
                          }}
                          onClick={handleClickOpen}
                        />
                      </MenuItem>

                      <MenuItem>
                        <RiDeleteBin6Line
                          onClick={() => {
                            handleCloseAction();
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You won't be able to revert this!",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, delete it!",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                dispatch(
                                  deleteUniversityCommissionAction(tableId)
                                );
                                Swal.fire(
                                  "Deleted!",
                                  "Your file has been deleted!.",
                                  "success"
                                );
                              }
                            });
                          }}
                          style={{
                            fontSize: "lg",
                            width: "20px",
                            height: "19px",
                          }}
                          className="icons"
                        />
                      </MenuItem>
                    </Box>
                  </Menu>{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

export default UniversityCommesions;
