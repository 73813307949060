import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  entrolledStudentsDetailsFindAction,
  entrolledStudentsStatusChangeAction,
} from "../../../actions/IeltsActions/entrolledStudentsAction";
import { useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function IeltsEntrolledStudentsView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  let {
    ieltsStudentsDetailsFindSuccess,
    ieltsStudentsDetailsFinderror,
  } = useSelector((state) => {
    return state.ieltsStudentsDetails;
  });

  let {
    ieltsStudentsStatusChangeSuccess,
    ieltsStudentsStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsStudentsStatusChange;
  });

  useEffect(() => {
    dispatch(entrolledStudentsDetailsFindAction(id));
  }, [dispatch, ieltsStudentsStatusChangeSuccess]);

  const { isStatusChangePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  console.log(
    ieltsStudentsDetailsFindSuccess,
    "ieltsStudentsDetailsFindSuccessieltsStudentsDetailsFindSuccess"
  );

  // Handle statusChange

  const handleStatus = (status) => {
    if (isStatusChangePrevilage("entrolled")) {
      dispatch(entrolledStudentsStatusChangeAction(status, id));
    } else {
      setOpenAlert(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          border: "1px solid #ECECEC",
          gap: 10,
          pt: 2,
          pb: 2,
          // flexWrap:"wrap"
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            gap: "100px",
            borderRight: "1px solid #ECECEC",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Name
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.fullName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                DOB
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.dob}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Email
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                  // maxWidth:"150px",
                  // overflow:"scroll"
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.email}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Gender
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.gender}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Phone Number
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.phoneNumberCountryCode +
                  ieltsStudentsDetailsFindSuccess?.phoneNumber}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Whatsapp Number
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.whatsAppNumberCountryCode +
                  ieltsStudentsDetailsFindSuccess?.whatsAppNumber}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Secondary Phone Number
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.secondaryPhoneNumberCountryCode +
                ieltsStudentsDetailsFindSuccess?.secondaryPhoneNumber
                  ? ieltsStudentsDetailsFindSuccess?.secondaryPhoneNumberCountryCode +
                    ieltsStudentsDetailsFindSuccess?.secondaryPhoneNumber
                  : "Nil"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Country
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.country
                  ? ieltsStudentsDetailsFindSuccess?.country
                  : "Nil"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                State
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.state
                  ? ieltsStudentsDetailsFindSuccess?.state
                  : "Nil"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            p: 3,
            display: "flex",
            justifyContent: "space-between",
            gap: "100px",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                City
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.city
                  ? ieltsStudentsDetailsFindSuccess?.city
                  : "Nil"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Student Status
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color:
                    ieltsStudentsDetailsFindSuccess?.status == "Inactive"
                      ? "red"
                      : "green",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.status
                  ? ieltsStudentsDetailsFindSuccess?.status
                  : "Nil"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Entry Created On
              </Typography>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  fontFamily: "'Poppins', sans-serif",
                  color: "#ACB1C6",
                }}
              >
                {ieltsStudentsDetailsFindSuccess?.createdAt
                  ? ieltsStudentsDetailsFindSuccess?.createdAt.split("T")[0]
                  : "Nil"}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", flexDirection: "column" }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Status
              </Typography>
              <FormControl
                fullWidth
                sx={{
                  mt: 1,
                  bgcolor:
                    ieltsStudentsDetailsFindSuccess?.status == "Active"
                      ? "#228200"
                      : "#E52900",
                  borderRadius: "8px",
                }}
              >
                <InputLabel
                  htmlFor="demo-simple-select-placeholder"
                  style={{
                    transform: "translate(10px, 10px)",
                    pointerEvents: "none",
                    zIndex: 1,
                    transition: "transform 0.25s, font-size 0.25s",
                    margin: "-6px",
                    color: "white",
                    fontWeight: 400,
                    paddingLeft: "10px",
                    border: "none !important",
                  }}
                >
                  {ieltsStudentsDetailsFindSuccess?.status
                    ? ieltsStudentsDetailsFindSuccess?.status
                    : "placeholder"}
                </InputLabel>
                <Select
                  value={null}
                  onChange={(e) => handleStatus(e.target.value)}
                  inputProps={{
                    // name: {item.coloum7},
                    id: "demo-simple-select-placeholder",
                  }}
                  sx={{
                    position: "relative",
                    width: "100px",
                    height: "30px",
                    // outline:"none !important",border:"4px solid #000",
                    ".MuiOutlinedInput-notchedOutline": {
                      outline: "none !important",
                      border: "none !important",
                    },
                  }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IeltsEntrolledStudentsView;
