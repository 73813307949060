import React, { useEffect, useState } from "react";
import { MainContainer } from "../content/content.element";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../customComponent/InfoHeader";
import { Divider, Grid } from "@mui/material";
import { SubmitButton } from "../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../customComponent/InputField";
import {
  TopNavigationEditAction,
  TopNavigationUpdateAction,
} from "../../actions/LandingPageAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../actions/globalAction";

function TopNavigationEdit(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [TopNavContent, setTopNavContent] = useState("");
  const [CallToAction, setCallToAction] = useState("");
  const [CallToActionLink, setCallToActionLink] = useState("");
  const [ExpireDate, setExpireDate] = useState("");
  const navigate = useNavigate();

  let { topNavigationFindEditSuccess } = useSelector((state) => {
    return state.topNavigationEdit;
  });

  let { topNavigationUpdateSuccess } = useSelector((state) => {
    return state.topNavigationUpdate;
  });

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (successAlertMsgSuccess && topNavigationUpdateSuccess) {
      Swal.fire("Good job!", `Edited successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [topNavigationUpdateSuccess, successAlertMsgSuccess, dispatch]);

  //Get_Data
  useEffect(() => {
    dispatch(TopNavigationEditAction(id));
  }, [dispatch, id, topNavigationUpdateSuccess]);

  useEffect(() => {
    if (topNavigationFindEditSuccess) {
      console.log(
        topNavigationFindEditSuccess,
        "topNavigationFindEditSuccesstopNavigationFindEditSuccesstopNavigationFindEditSuccesstopNavigationFindEditSuccess"
      );
      setTopNavContent(topNavigationFindEditSuccess?.topNavContent);
      setCallToAction(topNavigationFindEditSuccess?.callToAction);
      setCallToActionLink(topNavigationFindEditSuccess?.callToActionLink);
      setExpireDate(topNavigationFindEditSuccess?.ExpireDate);
    }
  }, [topNavigationFindEditSuccess]);

  // Handle_createTopNavigation_Button

  const handleCreate = () => {
    dispatch(
      TopNavigationUpdateAction(
        id,
        TopNavContent,
        CallToAction,
        CallToActionLink,
        ExpireDate,
        id
      )
    );
  };

  //   console.log(ExpireDate, "expiredate");
  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Edit Top Navigation" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={12}>
          <InputField
            label="Top Nav Content"
            handleChange={(e) => setTopNavContent(e.target.value)}
            widthSize="98%"
            value={TopNavContent}
            required
            paramses
          />
        </Grid>
        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Call To Action"
              handleChange={(e) => setCallToAction(e.target.value)}
              widthSize="95%"
              value={CallToAction}
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Call To Action Link"
              handleChange={(e) => setCallToActionLink(e.target.value)}
              widthSize="95%"
              value={CallToActionLink}
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Expire Date"
              handleChange={(e) => setExpireDate(e.target.value)}
              widthSize="95%"
              value={ExpireDate}
              InputType="date"
              required
              paramses
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Update"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={handleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default TopNavigationEdit;
