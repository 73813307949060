import { Alert, AlertTitle, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import HrSearchHeader from "./HrSearchHeader";
import { HrAttendanceTable } from "./HrAttendanceTable";
import {
  employeeSearchAction,
  hrEmployeeFindAction,
} from "../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";

function AllHrAttendance() {
  const dispatch = useDispatch();
  const [Employeelist, setEmployeelist] = useState([]);
  const [isPresent, setIsPresent] = useState([]);
  const [data, setData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // Get_Data_from_reducer

  let { hrEmployeeFindSuccess } = useSelector((state) => {
    return state.hrEmployeeFind;
  });

  let { hrEmployeeAttendanceSuccess } = useSelector((state) => {
    return state.hrEmployeeAttendanceCreation;
  });

  let {
    employeeSearchLoading,
    employeeSearchSuccess,
    employeeSearchErr,
  } = useSelector((state) => {
    return state.employeeSearch;
  });

  // fetch_Data
  useEffect(() => {
    dispatch(hrEmployeeFindAction());
  }, [dispatch, hrEmployeeAttendanceSuccess]);

  // Setdata_to_State
  useEffect(() => {
    if (hrEmployeeFindSuccess) {
      setIsPresent((prev) => [
        ...prev,
        hrEmployeeFindSuccess.map((item) => ({
          userId: item.id,
          isPresent:
            (item.employee_attendances &&
              item.employee_attendances.isPresent) ||
            false,
        })),
      ]);
      setEmployeelist(hrEmployeeFindSuccess);
    }
  }, [hrEmployeeFindSuccess]);

  // table data\
  useEffect(() => {
    let List = Employeelist.filter(
      (master) => master.fk_role_id !== "admin"
    ).map((employee, index) => ({
      sl: index + 1,
      id: employee.id,
      coloumn1: employee.firstName,
      coloumn2: employee.employeeId,
      coloumn3: employee.role !== null ? employee.role.roleName : "-",
      coloumn4: employee.user_Branches !== null ? employee.user_Branches : [],
      coloumn5: employee.ref_no !== null ? employee.ref_no.leaveLength : "0",
      coloumn6:
        employee.employee_attendance !== null
          ? employee.employee_attendance.isPresent
          : null,
      coloumn7:
        employee.employee_attendance !== null
          ? employee.employee_attendance.isAbsent
          : null,
      coloumn8:
        employee.employee_attendance !== null
          ? employee.employee_attendance.attendanceDate
          : null,
    }));
    setData(List);
    setResultData(List);
  }, [Employeelist]);

  // search data

  useEffect(() => {
    if (employeeSearchSuccess) {
      let result = employeeSearchSuccess.map((employee, index) => ({
        sl: index + 1,
        id: employee.id,
        coloumn1: employee.user.firstName,
        coloumn2: employee.user.employeeId,
        coloumn3:
          employee.user.role !== null ? employee.user.role.roleName : "-",
        coloumn4: employee.user_Branches !== null ? employee.user_Branches : [],
        coloumn5: "0",
        coloumn6: employee !== null ? employee.isPresent : null,
        coloumn7: employee !== null ? employee.isAbsent : null,
        coloumn8: employee !== null ? employee.attendanceDate : null,
      }));
      setData(result);
      setResultData(result);
    }
  }, [employeeSearchSuccess]);

  // search attendance
  const searchSubmitBranch = (branch, date) => {
    console.log(branch, date, "LLLLLLLLLLLLLLLLLLLLLLLl");
    // data.filter((attendance) => alert(attendance.coloumn8));
    dispatch(employeeSearchAction(branch, date));
  };

  const handleSearch = (value) => {
    setNameFilter(value);
    // if (value !== "") {
    //   setData(
    //     data?.filter((item) => {
    //       const coloumn1 =
    //         item.coloumn1 !== null ? item.coloumn1.toLowerCase() : "";
    //       const coloumn2 =
    //         item.coloumn2 !== null ? item.coloumn2.toLowerCase() : "";
    //       const coloumn3 =
    //         item.coloumn3 !== null ? item.coloumn3.toLowerCase() : "";
    //       const coloumn4 =
    //         item.coloumn4 !== null ? item.coloumn4.toLowerCase() : "";

    //       return (
    //         coloumn1.includes(value.toLowerCase()) ||
    //         coloumn2.includes(value.toLowerCase()) ||
    //         coloumn3.includes(value.toLowerCase()) ||
    //         coloumn4.includes(value.toLowerCase())
    //       );
    //     })
    //   );
    // } else {
    //   setData(
    //     Employeelist.filter((master) => master.branch_master !== null).map(
    //       (employee, index) => ({
    //         sl: index + 1,
    //         id: employee.id,
    //         coloumn1: employee.firstName,
    //         coloumn2: employee.employeeId,
    //         coloumn3: employee.role !== null ? employee.role.roleName : "-",
    //         coloumn4:
    //           employee.branch_master !== null
    //             ? employee.branch_master.branchName
    //             : "-",
    //         coloumn5:
    //           employee.ref_no !== null ? employee.ref_no.leaveLength : "0",
    //         coloumn6:
    //           employee.employee_attendance !== null
    //             ? employee.employee_attendance.isPresent
    //             : null,
    //         coloumn7:
    //           employee.employee_attendance !== null
    //             ? employee.employee_attendance.isAbsent
    //             : null,
    //         coloumn8:
    //           employee.employee_attendance !== null
    //             ? employee.employee_attendance.attendanceDate
    //             : null,
    //       })
    //     )
    //   );
    // }

    console.log(nameFilter, selectedBranchValue);
  };

  const [nameFilter, setNameFilter] = useState("");
  const [selectedBranchValue, setSelectedBranchValue] = useState("");

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedBranchValue(newValue);
  };

  useEffect(() => {
    console.log(data, "data");
    let filter = data?.filter((item) => {
      const nameMatch = item.coloumn1
        .toLowerCase()
        .includes(nameFilter.toLowerCase());
      // const dateMatch = item.coloumn3 === dateValue || dateFilter === "";
      const placeMatch =
        item.coloumn4 === selectedBranchValue ||
        selectedBranchValue === "" ||
        selectedBranchValue === "defaultOption";
      console.log(nameMatch, "nameMatch");
      console.log(placeMatch, "placeMatch");
      return nameMatch && placeMatch;
    });
    setResultData(filter);
  }, [selectedBranchValue, nameFilter]);

  if (!hasPrivilege("attendance") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }


  return (
    <>
      {/* {hasPrivilege("attendance") && privilege == null ? ( */}
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} lg={12}>
          <HrSearchHeader
            handleClickSubmit={searchSubmitBranch}
            searchEmployeeList={handleSearch}
            handleSelectChange={handleSelectChange}
            selectedBranchValue={selectedBranchValue}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <HrAttendanceTable
            columns={[
              {
                title: "Sl no",
                field: "sl",
              },
              {
                title: "Employee Name",
                field: "name",
              },
              {
                title: "ID",
                field: "id",
              },
              {
                title: "Role",
                field: "role",
              },
              {
                title: "Branch",
                field: "Branch",
              },
              {
                title: "PRESENT",
                field: "Present",
              },
              {
                title: "ABSENT",
                field: "absent",
              },
              {
                title: "TOTAL LEAVE",
                field: "Total leave",
              },
            ]}
            data={resultData}
            component="hr"
            isPresentMap={isPresent}
            subMenu="attendance"
          />
        </Grid>
      </Grid>
      {/* ) : (
        <Alert severity="error">
          <AlertTitle>warning</AlertTitle>
          You don't have permission to access — <strong>this page.!</strong>
        </Alert>
      )} */}
    </>
  );
}

export default AllHrAttendance;
