import React, { useEffect, useState } from "react";
import {
  Box,
  DialogContentText,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { MainContainer } from "../../content/content.element";

import Swal from "sweetalert2";

import { HiOutlinePlus } from "react-icons/hi";
import { IoMdCloudUpload } from "react-icons/io";

import { TextField, Button, Grid } from "@mui/material";

import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";

import { useDispatch, useSelector } from "react-redux";
import {
  universityCourseAddAction,
  universityCourseEditAction,
  universityCourseFindAction,
} from "../../../actions/universityActions/courseActions/universityCourseAction";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../../customComponent/InfoHeader";
import InputField, {
  SelectInputField,
  TopInputFieldBorderLabel,
} from "../../customComponent/InputField";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import { SubmitButton, SwitchButton } from "../../customComponent/Buttons";
import {
  courseLevelMasterFindAction,
  courseMasterFindAction,
  subjectMasterFindAction,
} from "../../../actions/courseMasterAction";
import currencyToSymbolMap from "currency-symbol-map/map";
import { GetCountries } from "react-country-state-city";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const boardList = ["IB", "ICSE", "CBSE", "State"];
const englishTest = [
  "IELTS",
  "OET",
  "TOEFL",
  "PTE",
  "GMAT",
  "GRE",
  "SAT",
  "Dulingo",
];
const inteshipData = ["1 month", "2 month", "3 month", "4 month", "5 month"];
const duration = [
  "Less than 1 year",
  "1 year",
  "2 years",
  "3 Years",
  "4 Years",
  "5 Years",
  "6 Years",
];
const subjects = ["Maths", "sciense", "maths", "sociology", "psycology"];
export const courseLevelOption = [
  "Under Graduate",
  "Post Graduate",
  "Research",
  "Pre-Masters",
  "FO",
  "PS",
  "Other(LS,SE)",
];

const extracurricularList = [
  "National level representation",
  "State level representation",
  "District level representation",
  "Inter school level representation",
  "Intra-school level representation",
  "No representation",
];

const EditUniversityCourse = (props) => {
  // const symbolsArray = Object.values(currencyToSymbolMap);
  const dispatch = useDispatch();
  const { id, universityId } = useParams();
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const [syllabusPdf, setSyllabusPdf] = useState("");
  const [courseLevel, setCourseLevel] = useState("");
  const [category, setCategory] = useState("");
  const [categoryDuration, setCategoryDuration] = useState("");
  const [intake, setIntake] = useState("");
  const [intakeDuration, setIntakeDuration] = useState();
  const [totalFee, setTotalFee] = useState("");
  const [boardMarkObtained, setBoardMarkObtained] = useState("");
  const [eligibleYear, setEligibleYear] = useState();
  const [test, setTest] = useState();
  const [testScore, setTestScore] = useState();
  const [board, setBoard] = useState("");
  // const [pgMarkObtain, setPgMarkObtain] = useState();
  const [internshipExperience, setInternshipExperience] = useState("");
  const [underDeplomo, setUnderDeplomo] = useState();
  const [errors, setErrors] = useState({});

  const [ngoEqExperience, setNgoEqExperience] = useState("");
  const [extracurricular, setExtracurricular] = useState("");
  const [placeMentDescription, setPlaceMentDescription] = useState("");

  const [placeMent, setPlaceMent] = useState(false);
  const [isMathamatic, setIsMathamatic] = useState(false);
  const [isFullTime, setIsFullTime] = useState(false);
  const [isPartTime, setIsPartTime] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  const [isTopCourse, setIsTopCourse] = useState(false);

  const [intakeData, setIntakeData] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [testData, setTestData] = useState([]);

  const [syllabusPdfUpload, setSyllabusPdfUpload] = useState(true);

  const [blobUrl, setBlobUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [feeFieldError, setFeeFieldError] = useState(false);
  const [courseMajorList, setCourseMajorList] = useState([]);
  const [courseMajor, setCourseMajor] = useState([]);

  const [freeData, setFeeData] = useState({
    applicationFee: null,
    livingExpense: null,
    registrationFee: null,
    tuitionFee: null,
    visaFee: null,
  });

  const [currencySymbol, setCurrencySymbol] = useState("");
  const [maximumFee, setMaximumFee] = useState("");
  const [minimumFee, setMinimumFee] = useState("");
  const [symbolArray, setSymbolArray] = useState([]);


  let {
    universityCourseEditLoading,
    universityCourseEditSuccess,
    universityCourseEditErr,
  } = useSelector((state) => {
    return state.courseEdit;
  });

  // course level master

  const { courseLevelFindSuccess, courseLevelFindErr } = useSelector(
    (state) => {
      return state.courseLevelMasterFind;
    }
  );

  // success message for university course edit find Action

  let {
    universityCourseEditFindLoading,
    universityCourseEditFindSuccess,
    universityCourseEditFindErr,
  } = useSelector((state) => {
    return state.courseEditFind;
  });

  const { successAlertMsgSuccess, successAlertMsgError } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  // CourseMajor Find response

  const { courseMajorFindSuccess, courseMajorFindErr } = useSelector(
    (state) => {
      return state.courseMajorMasterFind;
    }
  );

  // Response

  const { subjectMajorFindSuccess, subjectMajorFindErr } = useSelector(
    (state) => {
      return state.subjectMajorMasterFind;
    }
  );

  useEffect(() => {
    dispatch(subjectMasterFindAction());
    dispatch(courseLevelMasterFindAction());
    GetCountries().then((result) => {
      setSymbolArray(result);
    });
  }, [dispatch]);

  // FindCourse major

  useEffect(() => {
    dispatch(courseMasterFindAction());
    dispatch(universityCourseFindAction(id));
  }, [dispatch, universityCourseEditSuccess]);

  console.log(
    universityCourseEditFindSuccess,
    "universityCourseEditFindSuccessuniversityCourseEditFindSuccess"
  );

  // Handle the response of courseMajor Find

  useEffect(() => {
    if (courseMajorFindSuccess) {
      setCourseMajorList(courseMajorFindSuccess?.data);
      setCourseTitle(universityCourseEditFindSuccess?.data?.courseTitle);
      setCourseDescription(
        universityCourseEditFindSuccess?.data?.courseDescription
      );
      setSyllabus(universityCourseEditFindSuccess?.data?.syllabus);
      setCurrencySymbol(
        universityCourseEditFindSuccess?.data?.totalFee?.slice(0, 1)
      );
      setMinimumFee(
        universityCourseEditFindSuccess?.data?.totalFee.split("-")[0].slice(1)
      );
      setMaximumFee(
        universityCourseEditFindSuccess?.data?.totalFee.split("-")[1]
      );
      setPlaceMent(
        universityCourseEditFindSuccess?.data?.placeMentDescription
          ? true
          : false
      );
      setPlaceMentDescription(
        universityCourseEditFindSuccess?.data?.placeMentDescription
      );
      setIsFullTime(
        universityCourseEditFindSuccess?.data?.modeOfStudy == "full time"
          ? true
          : false
      );
      setIsOnline(
        universityCourseEditFindSuccess?.data?.modeOfStudy == "part time"
          ? true
          : false
      );
      setIsPartTime(
        universityCourseEditFindSuccess?.data?.modeOfStudy == "online"
          ? true
          : false
      );
      setIsMathamatic(
        universityCourseEditFindSuccess?.data?.WithoutMathamatics
      );
      setIsTopCourse(universityCourseEditFindSuccess?.data?.isTopCourse);
      setUnderDeplomo(universityCourseEditFindSuccess?.data?.underDeplomo);
      setInternshipExperience(
        universityCourseEditFindSuccess?.data?.internshipExperience
      );
      setExtracurricular(
        universityCourseEditFindSuccess?.data?.extracurricular
      );
      setNgoEqExperience(
        universityCourseEditFindSuccess?.data?.ngoEqExperience
      );

      setSyllabusPdfUpload(
        universityCourseEditFindSuccess?.data?.syllabusPdf ? false : true
      );
      setSyllabusPdf(universityCourseEditFindSuccess?.data?.syllabusPdf);
      setCourseLevel(universityCourseEditFindSuccess?.data?.courseLevel);
      setCategory(universityCourseEditFindSuccess?.data?.subject);
      setCourseMajor(universityCourseEditFindSuccess?.data?.courseMajor);
      setCategoryDuration(
        universityCourseEditFindSuccess?.data?.CourseDuration
      );

      setIntakeData(universityCourseEditFindSuccess?.data?.Courses_Intakes);
      setBoardData(universityCourseEditFindSuccess?.data?.Course_Boards);
      setTestData(universityCourseEditFindSuccess?.data?.Course_Tests);
    }
  }, [courseMajorFindSuccess, universityCourseEditFindSuccess]);

  console.log(courseMajor, "courseMajorcourseMajorcourseMajorcourseMajor");

  useEffect(() => {
    if (successAlertMsgError) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: universityCourseEditErr,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [universityCourseEditErr, dispatch, successAlertMsgError]);

  useEffect(() => {
    if (successAlertMsgSuccess) {
      Swal.fire("Good job!", `Course Edit Successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [universityCourseEditSuccess, successAlertMsgSuccess]);

  // handleInTakeClick
  const handleInTakeClick = () => {
    const intakeDate = new Date(intake);
    // const intakeDurationDate = new Date(intakeDuration);

    // if (intakeDate > intakeDurationDate) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "intake Greater than intake duration date!",
    //   });
    // } else

    if (intakeDate) {
      const intakeExists = intakeData.some(
        (data) => data.intakeDate === intake
      );

      if (!intakeExists) {
        setIntakeData([...intakeData, { intakeDate: intake }]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "this intake date already added!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "intake date need to be added",
      });
    }
  };

  const handleFeeClick = () => {
    console.log(freeData, "syllabusPdfsyllabusPdfsyllabusPdf");
    if (
      freeData?.applicationFee !== null &&
      freeData?.livingExpense !== null &&
      freeData?.registrationFee !== null &&
      freeData?.tuitionFee !== null &&
      freeData?.visaFee !== null
    ) {
      let total =
        parseInt(freeData?.applicationFee) +
        parseInt(freeData?.livingExpense) +
        parseInt(freeData?.registrationFee) +
        parseInt(freeData?.tuitionFee) +
        parseInt(freeData?.visaFee);
      // setFeeData({
      //   applicationFee: null,
      //   livingExpense: null,
      //   registrationFee: null,
      //   tuitionFee: null,
      //   visaFee: null,
      // });
      setOpen(false);
      setTotalFee(total);
      setFeeFieldError(false);
    } else {
      setFeeFieldError(true);
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Please Add  all the fields!",
      // });
    }
  };

  const handleInTakeDeleteClick = (intakeDate) => {
    const updatedIntakeData = intakeData.filter(
      (data) => data.intakeDate !== intakeDate
    );
    setIntakeData(updatedIntakeData);
  };

  // handleBoardClick
  const handleBoardClick = () => {
    if (board !== "" && boardMarkObtained !== "" && eligibleYear !== "") {
      const boardExists = boardData.some((data) => data.board === board);
      console.log(boardData, "boardDataboardData");
      if (!boardExists) {
        setBoardData([
          ...boardData,
          {
            board: board,
            boardScore: boardMarkObtained,
            eligibleYear: eligibleYear,
          },
        ]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "this board already added!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please choose a board!",
      });
    }
  };

  const handleBoardDeleteClick = (board) => {
    const updatedBoardData = boardData.filter((data) => data.board !== board);
    setBoardData(updatedBoardData);
  };

  // handleTestClick
  const handleTestClick = () => {
    const testExists = testData.some((data) => data.test === test);
    if (!testExists) {
      setTestData([...testData, { test: test, testScore: testScore }]);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "this test already added!",
      });
    }
  };

  const handleTestDeleteClick = (test) => {
    const updatedTestdData = testData.filter((data) => data.test !== test);
    setTestData(updatedTestdData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here

    dispatch(
      universityCourseEditAction({
        universityId,
        courseTitle,
        courseDescription,
        syllabus,
        syllabusPdf,
        courseLevel,
        category,
        categoryDuration,
        intake: intakeData,
        totalFee: currencySymbol + minimumFee + "-" + maximumFee,
        splitData: freeData,
        placeMentDescription,
        board: boardData,
        test: testData,
        internshipExperience,
        underDeplomo,
        ngoEqExperience,
        extracurricular,
        modeOfStudy: isFullTime
          ? "full time"
          : isPartTime
          ? "part time"
          : isOnline
          ? "online"
          : null,
        WithoutMathamatics: isMathamatic,
        isTopCourse,
        courseMajor,
        courseId: id,
      })
    );
  };

  // Preview using Blob
  const handleUploadSyllabus = (e) => {
    if (e?.target?.files) {
      setSyllabusPdfUpload(false);
      setBlobUrl(null); // Clear previous blob URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setSyllabusPdf(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // Handle preview
  const handlePreview = () => {
    if (syllabusPdf) {
      const byteCharacters = atob(syllabusPdf.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setBlobUrl(url);

      const newTab = window.open();
      newTab.document.write(
        '<iframe width="100%" height="100%" src="' + url + '"></iframe>'
      );
    }
  };
  console.log(syllabusPdf, "syllabusPdfsyllabusPdfsyllabusPdf");

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader
        headTitle="Edit Course"
        url={`/university/addUniversity/ViewUniversity/courses/${universityId}`}
      />

      <Box>
        <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
          Edit Course
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <InputField
                label="Course Title"
                handleChange={(e) => setCourseTitle(e.target.value)}
                widthSize="99%"
                value={courseTitle}
                // required
                paramses
                InputType="text"
                dispcriptionHeight
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Course Description"
                handleChange={(e) => setCourseDescription(e.target.value)}
                widthSize="99%"
                value={courseDescription}
                // required
                paramses
                InputType="discription"
                dispcriptionHeight="100px"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Syllabus"
                handleChange={(e) => setSyllabus(e.target.value)}
                widthSize="99%"
                value={syllabus}
                // required
                paramses
                InputType="discription"
                dispcriptionHeight="100px"
              />
            </Grid>

            <Grid item xs={12}>
              {syllabusPdfUpload ? (
                <label
                  htmlFor="syllabusUpload"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    border: "solid 1px #ECECEC",
                    width: "250px",
                    fontWeight: 700,
                  }}
                >
                  <span>
                    <IoMdCloudUpload
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        marginLeft: "12px",
                      }}
                    />
                  </span>
                  Upload Course Syllabus
                </label>
              ) : (
                <>
                  <Box sx={{ display: "flex" }}>
                    <img
                      src="/menuIcons/pdf.png"
                      style={{
                        width: "57px",
                        height: "auto",
                      }}
                      onClick={handlePreview}
                    ></img>

                    <label
                      htmlFor="syllabusUpload"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        border: "solid 1px #ECECEC",
                        width: "350px",
                        fontWeight: 700,
                      }}
                    >
                      <span>
                        <IoMdCloudUpload
                          style={{
                            fontSize: "30px",
                            cursor: "pointer",
                            marginLeft: "12px",
                          }}
                        />
                      </span>
                      Reupload Course Syllabus
                    </label>
                  </Box>
                </>
              )}
              <input
                type="file"
                id="syllabusUpload"
                accept=".pdf,.doc,.docx,.xlsx"
                style={{ display: "none" }}
                onChange={handleUploadSyllabus}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <SelectInputField
                label="Course Level"
                handleSelectChange={(e) => setCourseLevel(e.target.value)}
                widthSize="87px"
                value={courseLevel}
                mapValue={courseLevelFindSuccess?.data}
                mapMethod="courseLevel"
                // required
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <SelectInputField
                label="Subject"
                handleSelectChange={(e) => setCategory(e.target.value)}
                widthSize="50px"
                value={category}
                mapValue={subjectMajorFindSuccess?.data}
                mapMethod="courseSubject"
                // required
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <SelectInputField
                label="Course major"
                handleSelectChange={(e) => setCourseMajor(e.target.value)}
                widthSize="50px"
                value={courseMajor}
                mapValue={courseMajorList}
                mapMethod="courseMajor"
                // required
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <SelectInputField
                label=" Total Duration"
                handleSelectChange={(e) => setCategoryDuration(e.target.value)}
                widthSize="90px"
                value={categoryDuration}
                mapValue={duration}
                mapMethod="extracurricular"
                // required
              />
            </Grid>
            <Grid item xs={12} sm={12} mt={1}>
              {/* <SubmitButton
                title="Split Fee"
                // submit=
                type="click"
                handleSubmit={() => setOpen(true)}
              /> */}
              <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                Total fees
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12}> */}
            {/* <InputField
                label="Total Fees"
                // handleChange={(e) => setTotalFee(e.target.value)}
                widthSize="99%"
                value={totalFee}
                required
                paramses
                InputType="number"
                dispcriptionHeight
              /> */}
            <Grid item xs={12} sm={4}>
              <SelectInputField
                label="SelectCurrency"
                handleSelectChange={(e) => setCurrencySymbol(e.target.value)}
                widthSize="90px"
                value={currencySymbol}
                mapValue={symbolArray}
                mapMethod="currencyList"
                // required
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputField
                label="Mininum fee"
                handleChange={(e) => setMinimumFee(e.target.value)}
                widthSize="99%"
                value={minimumFee}
                // required
                paramses
                InputType="text"
                dispcriptionHeight
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputField
                label="Maximum fee"
                handleChange={(e) => setMaximumFee(e.target.value)}
                widthSize="99%"
                value={maximumFee}
                // required
                paramses
                InputType="text"
                dispcriptionHeight
              />
            </Grid>
            {/* </Grid> */}

            <Grid item xs={12} sm={12}>
              <div style={{ display: "flex" }}>
                <Checkbox
                  {...label}
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                    // mt: 1,
                    cursor: "pointer",
                  }}
                  checked={placeMent}
                  onClick={(e) => setPlaceMent(!placeMent)}
                />
                <Typography variant="h6" sx={{ mt: 0.5, fontWeight: "bold" }}>
                  Placement Availability
                </Typography>
              </div>
            </Grid>

            {placeMent && (
              <Grid item xs={12} sm={12}>
                <InputField
                  label="Add Description"
                  handleChange={(e) => setPlaceMentDescription(e.target.value)}
                  widthSize="99%"
                  value={placeMentDescription}
                  // required
                  paramses
                  InputType="text"
                  dispcriptionHeight
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{ mt: 0.5, fontWeight: "bold" }}>
                Mode Of Study
              </Typography>
              <div>
                Full Time
                <Checkbox
                  {...label}
                  checked={
                    !isPartTime && !isOnline && isFullTime ? true : false
                  }
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                    // mt: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setIsFullTime(true);
                    setIsOnline(false);
                    setIsPartTime(false);
                  }}
                />
                Part time
                <Checkbox
                  {...label}
                  checked={
                    !isFullTime && !isOnline && isPartTime ? true : false
                  }
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                    // mt: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setIsFullTime(false);
                    setIsOnline(false);
                    setIsPartTime(true);
                  }}
                />{" "}
                Online
                <Checkbox
                  {...label}
                  checked={
                    !isFullTime && !isPartTime && isOnline ? true : false
                  }
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                    // mt: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setIsFullTime(false);
                    setIsPartTime(false);
                    setIsOnline(true);
                  }}
                />{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{ mt: 0.5, fontWeight: "bold" }}>
                Prefer course without mathematics
              </Typography>
              <div>
                Yes
                <Checkbox
                  {...label}
                  checked={isMathamatic}
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                    // mt: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => setIsMathamatic(true)}
                />{" "}
                No
                <Checkbox
                  {...label}
                  checked={!isMathamatic}
                  sx={{
                    color: pink[800],
                    "&.Mui-checked": {
                      color: pink[600],
                    },
                    // mt: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => setIsMathamatic(false)}
                />
              </div>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Typography variant="h6" sx={{ mt: 0.5, fontWeight: "bold" }}>
                Top Course
              </Typography>
              <Box sx={{ ml: -9, mt: 2 }}>
                <SwitchButton
                  // label="Video"
                  handleChange={() => {
                    setIsTopCourse(!isTopCourse);
                  }}
                  checked={isTopCourse}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                Intake
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#efedf0",
                }}
              >
                <Box sx={{ width: "100%", m: 2 }}>
                  <TopInputFieldBorderLabel
                    label="Choose Intake Month and Year"
                    handleChange={(e) => setIntake(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={intake}
                    // required
                    paramses
                    type="month"
                  />
                </Box>

                <Box sx={{ mt: 2, width: "100%" }}>
                  {/* <TopInputFieldBorderLabel
                    label="Choose Month and Year"
                    handleChange={(e) => setIntakeDuration(e.target.value)}
                    widthSize="fullWidth"
                    heightSize="42px"
                    value={intakeDuration}
                    required
                    paramses
                    type="month"
                  /> */}
                  <HiOutlinePlus
                    style={{
                      fontSize: "25px",
                      margin: "10px",
                      cursor: "pointer",
                    }}
                    onClick={handleInTakeClick}
                  />
                </Box>
              </Box>
            </Grid>

            {intakeData?.map((data) => (
              <Grid item xs={6} sm={6} sx={{ mt: 1, fontWeight: "bold" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#efedf0",
                  }}
                >
                  <TextField
                    value={data.intakeDate}
                    fullWidth
                    sx={{ backgroundColor: "white", margin: 1 }}
                  />
                  {/* 
                  <TextField
                    value={data.intakeDurationDate}
                    fullWidth
                    sx={{ backgroundColor: "white", margin: 1 }}
                  /> */}

                  <img
                    src="/menuIcons/trash.png"
                    alt="gsl"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "25px",
                      paddingRight: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleInTakeDeleteClick(data.intakeDate)}
                  />
                </Box>
              </Grid>
            ))}

            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
                Education Eligibility
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#efedf0",
                }}
              >
                <Box sx={{ width: "100%", m: 2 }}>
                  <SelectInputField
                    label="Choose Board"
                    handleSelectChange={(e) => setBoard(e.target.value)}
                    widthSize="94px"
                    value={board}
                    mapValue={boardList}
                    mapMethod="courseBord"
                  />
                </Box>

                <Box sx={{ width: "100%", m: 2 }}>
                  <InputField
                    label="Mark Obtained"
                    handleChange={(e) => setBoardMarkObtained(e.target.value)}
                    widthSize="100%"
                    value={boardMarkObtained}
                    // required
                    paramses
                    InputType="number"
                    dispcriptionHeight
                  />
                </Box>
                <Box sx={{ width: "100%", m: 2 }}>
                  <InputField
                    label="Year"
                    handleChange={(e) => setEligibleYear(e.target.value)}
                    widthSize="100%"
                    value={eligibleYear}
                    // required
                    paramses
                    InputType="number"
                    dispcriptionHeight
                  />
                </Box>
                <HiOutlinePlus
                  style={{
                    fontSize: "60px",
                    margin: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleBoardClick}
                />
              </Box>
            </Grid>
          </Grid>

          {boardData?.map((data) => (
            <Grid item xs={12} sm={12} sx={{ mt: 2, fontWeight: "bold" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#efedf0",
                }}
              >
                <TextField
                  value={data.board}
                  fullWidth
                  sx={{ backgroundColor: "white", margin: 1 }}
                />

                <TextField
                  fullWidth
                  value={data.boardScore}
                  sx={{ backgroundColor: "white", margin: 1 }}
                />

                <TextField
                  fullWidth
                  value={data.eligibleYear}
                  sx={{ backgroundColor: "white", margin: 1 }}
                />

                <img
                  src="/menuIcons/trash.png"
                  alt="gsl"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginTop: "25px",
                    paddingRight: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleBoardDeleteClick(data.board)}
                />
              </Box>
            </Grid>
          ))}

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" sx={{ my: 2, fontWeight: "bold" }}>
              Undergraduate Diploma
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 1 }}>
              <InputField
                label="Mark in %"
                // handleChange={(e) => setUnderDeplomo(e.target.value)}
                handleChange={(e) => {
                  let value = e.target.value;
                  const phoneRegex = /^\d{0,3}$/;
                  if (phoneRegex.test(value) || e.target.value === "") {
                    if (value <= 100) {
                      setUnderDeplomo(e.target.value);
                    } else if (value > 100) {
                      setUnderDeplomo(100);
                    } else {
                      setUnderDeplomo(0);
                    }
                    setErrors((prev) => ({
                      ...prev,
                      ["diploma"]: "",
                    }));
                  } else {
                    setErrors((prev) => ({
                      ...prev,
                      ["diploma"]: "Invalid percentage",
                    }));
                  }
                }}
                widthSize="99%"
                value={underDeplomo}
                // required
                paramses
                InputType="text"
                dispcriptionHeight
                error={Boolean(errors.diploma)}
                helperText={errors.diploma}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              Language Test Qualification
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#efedf0",
              }}
            >
              <Box sx={{ m: 2, width: "100%" }}>
                <SelectInputField
                  label="Choose Test"
                  handleSelectChange={(e) => setTest(e.target.value)}
                  widthSize="80px"
                  value={test}
                  mapValue={englishTest}
                  mapMethod="courseBord"
                />
              </Box>

              <Box sx={{ width: "100%", m: 2 }}>
                <InputField
                  label="Score"
                  handleChange={(e) => setTestScore(e.target.value)}
                  widthSize="99%"
                  value={testScore}
                  // required
                  paramses
                  InputType="text"
                  dispcriptionHeight
                />
              </Box>

              <HiOutlinePlus
                style={{ fontSize: "60px", margin: "10px" }}
                onClick={handleTestClick}
              />
            </Box>
          </Grid>

          {testData?.map((data) => (
            <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "#efedf0",
                }}
              >
                <FormControl fullWidth sx={{ margin: 1 }}>
                  <TextField
                    value={data.test}
                    fullWidth
                    sx={{ backgroundColor: "white" }}
                  />
                </FormControl>

                <TextField
                  value={data.testScore}
                  fullWidth
                  sx={{ backgroundColor: "white", margin: 1 }}
                />
                <img
                  src="/menuIcons/trash.png"
                  alt="gsl"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginTop: "25px",
                    paddingRight: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleTestDeleteClick(data.test)}
                />
              </Box>
            </Grid>
          ))}

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              Internship Experience
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 1, width: "473px" }}>
              <SelectInputField
                label="Internship Experience"
                handleSelectChange={(e) =>
                  setInternshipExperience(e.target.value)
                }
                widthSize="140px"
                value={internshipExperience}
                mapValue={inteshipData}
                mapMethod="internship"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              Extracurricular activity
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 1, width: "473px" }}>
              <SelectInputField
                label="Extracurricular Activity"
                handleSelectChange={(e) => setExtracurricular(e.target.value)}
                widthSize="145px"
                value={extracurricular}
                mapValue={extracurricularList}
                mapMethod="extracurricular"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
              NGO/ Equivalent organization experience
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 1, width: "473px" }}>
              <SelectInputField
                label="NGO/ Equivalent organization experience"
                handleSelectChange={(e) => setNgoEqExperience(e.target.value)}
                widthSize="265px"
                value={ngoEqExperience}
                mapValue={inteshipData}
                mapMethod="ngoEq"
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              sx={{
                marginTop: 3,
                width: "90px",
                height: "46px",
                color: "white",
                backgroundColor: "#141E3C",
                fontWeight: 600,
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </Box>

      {open && (
        <div>
          <Modal
            keepMounted
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Split Fee
              </Typography>
              {feeFieldError && (
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h6"
                  component="h2"
                  color="error"
                >
                  {" "}
                  Please Add all the fields!
                </Typography>
              )}
              <DialogContentText>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={6} mt={2}>
                    <InputField
                      label="Application Fee"
                      handleChange={(event) =>
                        setFeeData({
                          ...freeData,
                          applicationFee: event.target.value,
                        })
                      }
                      value={freeData?.applicationFee}
                      widthSize="99%"
                      // required
                      InputType="number"
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <InputField
                      label="Tuition Fee"
                      handleChange={(event) =>
                        setFeeData({
                          ...freeData,
                          tuitionFee: event.target.value,
                        })
                      }
                      value={freeData?.tuitionFee}
                      widthSize="99%"
                      // required
                      InputType="number"
                    />
                  </Grid>

                  <Grid item xs={6} mt={2}>
                    <InputField
                      label="Living Expense"
                      handleChange={(event) =>
                        setFeeData({
                          ...freeData,
                          livingExpense: event.target.value,
                        })
                      }
                      value={freeData?.livingExpense}
                      widthSize="99%"
                      // required
                      InputType="number"
                    />
                  </Grid>

                  <Grid item xs={6} mt={2}>
                    <InputField
                      label="Visa Fee"
                      handleChange={(event) =>
                        setFeeData({
                          ...freeData,
                          visaFee: event.target.value,
                        })
                      }
                      value={freeData?.visaFee}
                      widthSize="99%"
                      // required
                      InputType="number"
                    />
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <InputField
                      label="Registration Fee"
                      handleChange={(event) =>
                        setFeeData({
                          ...freeData,
                          registrationFee: event.target.value,
                        })
                      }
                      value={freeData?.registrationFee}
                      widthSize="99%"
                      // required
                      InputType="number"
                    />
                  </Grid>
                </Grid>
              </DialogContentText>

              <Grid item xs={12} mt={2}>
                <Button
                  sx={{
                    // width: widthSize,
                    // height: heightSize,
                    color: "white",
                    backgroundColor: "rgba(20, 30, 60, 1)",
                    borderRadius: "8px",
                    fontWeight: 600,
                    fontSize: "12px",
                    "&:hover": {
                      backgroundColor: "rgba(20, 30, 60, 0.9)",
                    },
                  }}
                  onClick={handleFeeClick}
                >
                  Submit
                </Button>
              </Grid>
            </Box>
          </Modal>
        </div>
      )}
    </MainContainer>
  );
};

export default EditUniversityCourse;

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// export default function KeepMountedModal() {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       <Button onClick={handleOpen}>Open modal</Button>
//       <Modal
//         keepMounted
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="keep-mounted-modal-title"
//         aria-describedby="keep-mounted-modal-description"
//       >
//         <Box sx={style}>
//           <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
//             Text in a modal
//           </Typography>
//           <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
//             Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
//           </Typography>
//         </Box>
//       </Modal>
//     </div>
//   );
// }
