import {
  PAYMENTAPPROVAL_FIND_ERR,
  PAYMENTAPPROVAL_FIND_REQUEST,
  PAYMENTAPPROVAL_FIND_SUCCESS,
  PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_ERR,
  PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_REQUEST,
  PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_SUCCESS,
  TUTIONFEEPAYMENTAPPROVAL_APPROVE_ERR,
  TUTIONFEEPAYMENTAPPROVAL_APPROVE_REQUEST,
  TUTIONFEEPAYMENTAPPROVAL_APPROVE_SUCCESS,
  TUTIONFEEPAYMENTAPPROVAL_FIND_ERR,
  TUTIONFEEPAYMENTAPPROVAL_FIND_REQUEST,
  TUTIONFEEPAYMENTAPPROVAL_FIND_SUCCESS,
} from "../constants/paymentApprovalConstants";

// leadSourceCreateReducer
export const paymentApprovalFindReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENTAPPROVAL_FIND_REQUEST:
      return {
        ...state,
        paymentApprovalFindLoading: true,
      };
    case PAYMENTAPPROVAL_FIND_SUCCESS:
      return {
        ...state,
        paymentApprovalFindLoading: false,
        paymentApprovalFindSuccess: action.payload,
      };
    case PAYMENTAPPROVAL_FIND_ERR:
      return {
        ...state,
        paymentApprovalFindLoading: false,
        paymentApprovalFindErr: action.payload,
      };
    default:
      return state;
  }
};

// TutionFee approval find reducer

export const tutionFeePaymentApprovalFindReducer = (state = {}, action) => {
  switch (action.type) {
    case TUTIONFEEPAYMENTAPPROVAL_FIND_REQUEST:
      return {
        ...state,
        tutionFeePaymentApprovalFindLoading: true,
      };
    case TUTIONFEEPAYMENTAPPROVAL_FIND_SUCCESS:
      return {
        ...state,
        tutionFeePaymentApprovalFindLoading: false,
        tutionFeePaymentApprovalFindSuccess: action.payload,
      };
    case TUTIONFEEPAYMENTAPPROVAL_FIND_ERR:
      return {
        ...state,
        tutionFeePaymentApprovalFindLoading: false,
        tutionFeePaymentApprovalFindErr: action.payload,
      };
    default:
      return state;
  }
};


// lead payment approve Reducer
export const paymentApprovalApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_REQUEST:
      return {
        ...state,
        paymentApprovalRegistrationFeeApproveLoading: true,
      };
    case PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_SUCCESS:
      return {
        ...state,
        paymentApprovalRegistrationFeeApproveLoading: false,
        paymentApprovalRegistrationFeeApproveSuccess: action.payload,
      };
    case PAYMENTAPPROVAL_REGISTRATIONFEE_APPROVE_ERR:
      return {
        ...state,
        paymentApprovalRegistrationFeeApproveLoading: false,
        paymentApprovalRegistrationFeeApproveErr: action.payload,
      };
    default:
      return state;
  }
};



// TutionFee approval find reducer

export const tutionFeePaymentApprovalApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case TUTIONFEEPAYMENTAPPROVAL_APPROVE_REQUEST:
      return {
        ...state,
        tutionFeePaymentApprovalApproveLoading: true,
      };
    case TUTIONFEEPAYMENTAPPROVAL_APPROVE_SUCCESS:
      return {
        ...state,
        tutionFeePaymentApprovalApproveLoading: false,
        tutionFeePaymentApprovalApproveSuccess: action.payload,
      };
    case TUTIONFEEPAYMENTAPPROVAL_APPROVE_ERR:
      return {
        ...state,
        tutionFeePaymentApprovalApproveLoading: false,
        tutionFeePaymentApprovalApproveErr: action.payload,
      };
    default:
      return state;
  }
};
