import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import BasicTabs from "../Tabs/TabsMain";
import ReportsTab from "./ReportsTab";
import FinalChoiceVerificationTabs from "./FinalChoice/FinalChoiceVerificationTabs";
import UniversityCommessionTab from "./UniversityCommession/UniversityCommessionTab";
import { MainContainer } from "../content/content.element";

function Report(props) {
  const [whichPage, setWhichPage] = useState();

  useEffect(() => {
    setWhichPage(localStorage.getItem("selectedTab"));
  }, [localStorage]);

  const callback = (index) => {
    setWhichPage(index);
  };
  return (
    <MainContainer active={props.toggle}>
      <Box
        sx={{
          overflow: "hidden",
          transition: "0.5s ease",
          background: "#fff",
        }}
      >
        <Box
          sx={{
            pb: "24px",
          }}
        >
          <Box sx={{}}>
            <HeadBreadcrumbs
              first={"Reports"}
              head={"Home"}
              sechead={"Reports"}
              main={
                whichPage == 0
                  ? "Final Choices"
                  : whichPage == 1
                  ? "Final Choices verification"
                  : whichPage == 2
                  ? "University Commissions"
                  : null
              }
            />
            <Box>
              <BasicTabs
                tab1Name="Final Choices"
                tab2Name="Final Choices verification"
                tab3Name="University Commissions"
                tab1={<ReportsTab />}
                tab2={<FinalChoiceVerificationTabs />}
                tab3={<UniversityCommessionTab />}
                head="Home"
                sechead="lead"
                callback={callback}
                selected={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default Report;
