import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { pink } from "@mui/material/colors";
import { findBranchMasterAction } from "../../../actions/branchMasterActions";
import { allRoleFindAction } from "../../../actions/roleMangmentAction";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { TypographyText1 } from "../../customComponent/Typography";
import { SubmitButton } from "../../customComponent/Buttons";
import { employeeIncentiveCreateAction } from "../../../actions/IncentiveCategoryActions";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";

const incentiveCategoryMap = [
  "B2B",
  "B2B",
  "Indirect University",
  "GUS",
  "UK office direct university",
  "Indirect University",
];

function EmployeeIncentiveCreate(props) {
  const dispatch = useDispatch();
  const [role, setRole] = useState("");
  const [branch, setBranch] = useState("");
  const [enrolledStudentAmount, setEnrolledStudentAmount] = useState([]);
  const [enrolledApplicationFrom, setEnrolledApplicationFrom] = useState("");
  const [enrolledApplicationTo, setEnrolledApplicationTo] = useState("");
  const [applicationIncentiveAmmount, setApplicationIncentiveAmmount] =
    useState("");
  const [directUniversityNote, setDirectUniversityNote] = useState("");

  const [extraIncentive, setExtraIncentive] = useState("");
  const [extraIncentiveMethod, setExtraIncentiveMethod] = useState("");
  const [extraIncentiveData, setExtraIncentiveData] = useState({});
  const [extraIncentiveNote, setExtraIncentiveNote] = useState("");

  const [bonuStudentAmount, setBonuStudentAmount] = useState([]);
  const [bonusApplicationFrom, setBonusApplicationFrom] = useState("");
  const [bonusApplicationTo, setBonusApplicationTo] = useState("");
  const [bonusNote, setBonusNote] = useState("");
  const [
    applicationIncentiveBonusAmmount,
    setApplicationIncentiveBonusAmmount,
  ] = useState("");

  // other study abroad
  const [studyAbroadStudentAmount, setStudyAbroadStudentAmount] = useState([]);
  const [studyAbroadApplicationFrom, setStudyAbroadApplicationFrom] =
    useState("");
  const [studyAbroadApplicationTo, setStudyAbroadApplicationTo] = useState("");
  const [otherStudyAbroadNote, setOtherStudyAbroadNote] = useState("");

  const [
    applicationIncentiveStudyAbroadAmmount,
    setApplicationIncentiveStudyAbroadAmmount,
  ] = useState("");

  const [categoryValue, setCategoryValue] = useState([]);

  const { allRoleFindSuccess } = useSelector((state) => {
    return state.allRoleFind;
  });

  let { branchMasterFindSuccess } = useSelector((state) => {
    return state.findBranchMaster;
  });

  let { employeeIncentiveCreateSuccess } = useSelector((state) => {
    return state.employeeIncentiveCreate;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  //hook effect
  useEffect(() => {
    dispatch(allRoleFindAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [dispatch]);

  useEffect(() => {
    if (employeeIncentiveCreateSuccess && successAlertMsgSuccess) {
      setEnrolledStudentAmount([]);

      setEnrolledApplicationFrom("");

      setEnrolledApplicationTo("");

      setApplicationIncentiveAmmount("");

      setDirectUniversityNote("");

      setExtraIncentive("");

      setExtraIncentiveMethod("");

      setExtraIncentiveData({});

      setExtraIncentiveNote("");

      setBonuStudentAmount([]);

      setBonusApplicationFrom("");

      setBonusApplicationTo("");

      setBonusNote("");

      setApplicationIncentiveBonusAmmount("");
      setStudyAbroadStudentAmount([]);

      setStudyAbroadApplicationFrom("");

      setStudyAbroadApplicationTo("");

      setOtherStudyAbroadNote("");

      setApplicationIncentiveStudyAbroadAmmount("");
      Swal.fire("Added!", "Employee Incentive Added Successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [employeeIncentiveCreateSuccess]);

  // handleAddEnrolled
  const handleAddEnrolled = () => {
    if (
      enrolledApplicationFrom !== "" &&
      enrolledApplicationTo !== "" &&
      applicationIncentiveAmmount !== ""
    ) {
      const intakeExists = enrolledStudentAmount.some(
        (data) => data.applicationFrom === enrolledApplicationFrom
      );

      if (intakeExists) {
        alert("this test alredy you added");
      } else {
        setEnrolledStudentAmount([
          ...enrolledStudentAmount,
          {
            applicationFrom: enrolledApplicationFrom,
            applicationTo: enrolledApplicationTo,
            incentiveAmmount: applicationIncentiveAmmount,
          },
        ]);

        setEnrolledApplicationFrom("");
        setEnrolledApplicationTo("");
        setApplicationIncentiveAmmount("");
      }
    }
  };

  // handleAddBonus
  const handleAddBonus = () => {
    if (
      bonusApplicationFrom !== "" &&
      bonusApplicationTo !== "" &&
      applicationIncentiveBonusAmmount !== ""
    ) {
      const intakeExists = bonuStudentAmount.some(
        (data) => data.applicationFrom === enrolledApplicationFrom
      );

      if (intakeExists) {
        alert("this test alredy you added");
      } else {
        setBonuStudentAmount([
          ...bonuStudentAmount,
          {
            applicationFrom: bonusApplicationFrom,
            applicationTo: bonusApplicationTo,
            bonusAmmount: applicationIncentiveBonusAmmount,
          },
        ]);

        setBonusApplicationFrom("");
        setBonusApplicationTo("");
        setApplicationIncentiveBonusAmmount("");
      }
    }
  };

  // for study abroad
  const handleAddStudyAbroad = () => {
    if (
      studyAbroadApplicationFrom !== "" &&
      studyAbroadApplicationTo !== "" &&
      applicationIncentiveStudyAbroadAmmount !== ""
    ) {
      const intakeExists = studyAbroadStudentAmount.some(
        (data) => data.applicationFrom === studyAbroadApplicationFrom
      );

      if (intakeExists) {
        alert("this test alredy you added");
      } else {
        setStudyAbroadStudentAmount([
          ...studyAbroadStudentAmount,
          {
            applicationFrom: studyAbroadApplicationFrom,
            applicationTo: studyAbroadApplicationTo,
            studyAbroadAmmount: applicationIncentiveStudyAbroadAmmount,
          },
        ]);

        setStudyAbroadApplicationFrom("");
        setStudyAbroadApplicationTo("");
        setApplicationIncentiveStudyAbroadAmmount("");
      }
    }
  };

  // for delete
  const handleTestDeleteClick = (ammount) => {
    const updatedTestData = enrolledStudentAmount.filter(
      (data) => data.applicationFrom !== ammount
    );

    setEnrolledStudentAmount(updatedTestData);
  };

  // for Bonus Delete
  const handleBonusDeleteClick = (ammount) => {
    const updatedBonusData = bonuStudentAmount.filter(
      (data) => data.applicationFrom !== ammount
    );

    setBonuStudentAmount(updatedBonusData);
  };

  // for Bonus Delete
  const handleStudyDeleteClick = (ammount) => {
    const updatedStudyAbroadData = studyAbroadStudentAmount.filter(
      (data) => data.applicationFrom !== ammount
    );

    setStudyAbroadStudentAmount(updatedStudyAbroadData);
  };

  // handle submit
  const handleSubmit = () => {
    console.log({
      role: role,
      branch: branch,
      enrolledStudentAmount: enrolledStudentAmount,
      bonuStudentAmount: bonuStudentAmount,
      studyAbroadStudentAmount: studyAbroadStudentAmount,
      categoryValue: categoryValue,
      extraIncentiveData: extraIncentiveData,
      directUniversityNote: directUniversityNote,
      extraIncentiveNote: extraIncentiveNote,
      bonusNote: bonusNote,
      otherStudyAbroadNote: otherStudyAbroadNote,
    });

    dispatch(
      employeeIncentiveCreateAction(
        role,
        branch,
        enrolledStudentAmount,
        bonuStudentAmount,
        studyAbroadStudentAmount,
        categoryValue,
        extraIncentiveData,
        directUniversityNote,
        extraIncentiveNote,
        bonusNote,
        otherStudyAbroadNote,
        ""
      )
    );
  };


  console.log(branch,"branchbranchbranch")

  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="Create Employee Incentive" url="/Accounts" />

        <Grid container spacing={2} mt={2}>
          <Grid xs={12} lg={4} m={2}>
            <SelectInputField
              label="Choose Designation"
              handleSelectChange={(e) => setRole(e.target.value)}
              widthSize="132px"
              value={role}
              mapValue={allRoleFindSuccess}
              mapMethod="role"
            />
          </Grid>

          <Grid xs={12} lg={4} m={2}>
            <SelectInputField
              label="Branch"
              handleSelectChange={(e) => setBranch(e.target.value)}
              widthSize="49px"
              value={branch}
              mapValue={branchMasterFindSuccess}
              mapMethod="branch"
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 3 }}>
          <TypographyText1 title="Direct University" variant="h6" />
          <Box
            sx={{
              padding: 1,
              backgroundColor: "#efedf0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2} sx={{ m: 1 }}>
                <Grid xs={7}>
                  <Grid container spacing={2} sx={{ m: 1 }}>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 146, 166, 1)",
                        }}
                      >
                        Enrolled Applications Count From
                      </Typography>
                      <Paper
                        sx={{ height: "35px", mt: 1, pb: 1 }}
                        elevation={0}
                      >
                        <TextField
                          className="textField"
                          // label="helllo"
                          value={enrolledApplicationFrom}
                          onChange={(e) =>
                            setEnrolledApplicationFrom(e.target.value)
                          }
                          fullWidth
                          sx={{
                            textAlign: "center",
                            // backgroundColor: "white",
                            border: "none",
                            "& fieldset": {
                              border: "none",
                              borderRadius: "4px",
                            },
                            // height: "auto",
                            "& .MuiFormLabel-root": {
                              height: "32px",
                              pt: 1,
                              // pb:2,

                              "&.Mui-focused": {
                                color: "#7d7d7d",
                                pt: 0.9,
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 146, 166, 1)",
                        }}
                      >
                        Enrolled Applications Count To
                      </Typography>
                      <Paper
                        sx={{ height: "35px", mt: 1, pb: 1 }}
                        elevation={0}
                      >
                        <TextField
                          className="textField"
                          // label="helllo"
                          value={enrolledApplicationTo}
                          onChange={(e) =>
                            setEnrolledApplicationTo(e.target.value)
                          }
                          fullWidth
                          sx={{
                            textAlign: "center",
                            // backgroundColor: "white",
                            border: "none",
                            "& fieldset": {
                              border: "none",
                              borderRadius: "4px",
                            },
                            // height: "auto",
                            "& .MuiFormLabel-root": {
                              height: "32px",
                              pt: 1,
                              // pb:2,

                              "&.Mui-focused": {
                                color: "#7d7d7d",
                                pt: 0.9,
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={5}>
                  <Grid container spacing={2} sx={{ m: 1 }}>
                    <Grid item lg={9}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 146, 166, 1)",
                        }}
                      >
                        Incentive Amount
                      </Typography>
                      <Paper
                        sx={{ height: "35px", mt: 1, pb: 1 }}
                        elevation={0}
                      >
                        <TextField
                          className="textField"
                          // label="helllo"
                          value={applicationIncentiveAmmount}
                          onChange={(e) =>
                            setApplicationIncentiveAmmount(e.target.value)
                          }
                          fullWidth
                          sx={{
                            textAlign: "center",
                            // backgroundColor: "white",
                            border: "none",
                            "& fieldset": {
                              border: "none",
                              borderRadius: "4px",
                            },
                            // height: "auto",
                            "& .MuiFormLabel-root": {
                              height: "32px",
                              pt: 1,
                              // pb:2,

                              "&.Mui-focused": {
                                color: "#7d7d7d",
                                pt: 0.9,
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <HiOutlinePlus
                        style={{
                          fontSize: "60px",
                          padding: "25px",
                          cursor: "pointer",
                          width: "auto",
                          height: "40px",
                        }}
                        onClick={handleAddEnrolled}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {enrolledStudentAmount.map((ammount, index) => (
                  <>
                    <Grid xs={7}>
                      <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item xs={12} lg={4}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(138, 146, 166, 1)",
                            }}
                          >
                            Enrolled Applications Count From
                          </Typography>
                          <Paper
                            sx={{ height: "35px", mt: 1, pb: 1 }}
                            elevation={0}
                          >
                            <TextField
                              className="textField"
                              // label="helllo"
                              value={ammount.applicationFrom}
                              fullWidth
                              sx={{
                                textAlign: "center",
                                // backgroundColor: "white",
                                border: "none",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "4px",
                                },
                                // height: "auto",
                                "& .MuiFormLabel-root": {
                                  height: "32px",
                                  pt: 1,
                                  // pb:2,

                                  "&.Mui-focused": {
                                    color: "#7d7d7d",
                                    pt: 0.9,
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(138, 146, 166, 1)",
                            }}
                          >
                            Enrolled Applications Count To
                          </Typography>
                          <Paper
                            sx={{ height: "35px", mt: 1, pb: 1 }}
                            elevation={0}
                          >
                            <TextField
                              className="textField"
                              // label="helllo"
                              value={ammount.applicationTo}
                              fullWidth
                              sx={{
                                textAlign: "center",
                                // backgroundColor: "white",
                                border: "none",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "4px",
                                },
                                // height: "auto",
                                "& .MuiFormLabel-root": {
                                  height: "32px",
                                  pt: 1,
                                  // pb:2,

                                  "&.Mui-focused": {
                                    color: "#7d7d7d",
                                    pt: 0.9,
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid xs={5}>
                      <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item lg={9}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(138, 146, 166, 1)",
                            }}
                          >
                            Incentive Amount
                          </Typography>
                          <Paper
                            sx={{ height: "35px", mt: 1, pb: 1 }}
                            elevation={0}
                          >
                            <TextField
                              className="textField"
                              // label="helllo"
                              value={ammount.incentiveAmmount}
                              fullWidth
                              sx={{
                                textAlign: "center",
                                // backgroundColor: "white",
                                border: "none",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "4px",
                                },
                                // height: "auto",
                                "& .MuiFormLabel-root": {
                                  height: "32px",
                                  pt: 1,
                                  // pb:2,

                                  "&.Mui-focused": {
                                    color: "#7d7d7d",
                                    pt: 0.9,
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={6} md={3} lg={3} mt={0.5}>
                          <img
                            src="/menuIcons/trash.png"
                            alt="gsl"
                            style={{
                              marginTop: "19px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleTestDeleteClick(ammount.applicationFrom)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
            <Box sx={{ p: 3 }}>
              <textarea
                placeholder="Type here"
                rows={5}
                value={directUniversityNote}
                name="note"
                onChange={(e) => setDirectUniversityNote(e.target.value)}
                style={{
                  height: "auto",
                  width: "100%",
                  resize: "none",
                  outline: "none",
                  border: "1px solid rgba(238, 238, 238, 1)",
                  borderRadius: "4px",
                  maxWidth: "auto",
                }}
              />
            </Box>
          </Box>
          {/* <IncentiveCriteria /> */}
        </Box>

        <Box m={1.5}>
          <Grid container spacing={2} mt={2}>
            {incentiveCategoryMap.map((category) => (
              <Grid xs={6} lg={4} mt={3}>
                <Box
                  sx={{
                    boxShadow: 1,
                    backgroundColor: "rgba(245, 245, 245, 1)",
                    width: "auto",
                    height: "200px",
                    m: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: 400, fontSize: "18px", p: 1 }}>
                    {category}
                  </Typography>

                  <Box
                    sx={{
                      boxShadow: 1,
                      backgroundColor: "white",
                      width: "100%",
                      height: "150px",
                    }}
                  >
                    <Box m={2}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="ammount"
                            control={
                              <Radio
                                sx={{
                                  color: pink[800],
                                  "&.Mui-checked": {
                                    color: pink[600],
                                  },
                                }}
                                onChange={(e) =>
                                  setCategoryValue([
                                    ...categoryValue,
                                    {
                                      category: category,
                                      status: e.target.checked,
                                      method: "ammount",
                                    },
                                  ])
                                }
                              />
                            }
                            label="Amount"
                          />
                          <FormControlLabel
                            value="percentage"
                            control={
                              <Radio
                                sx={{
                                  color: pink[800],
                                  "&.Mui-checked": {
                                    color: pink[600],
                                  },
                                }}
                              />
                            }
                            label="Percentage"
                          />
                        </RadioGroup>
                      </FormControl>
                      <InputField
                        label=""
                        handleChange={(e) => {
                          // Create a copy of the categoryValue array
                          const updatedCategoryValue = [...categoryValue];

                          // Find the index of the object with the matching category
                          const index = updatedCategoryValue.findIndex(
                            (item) => item.category === category
                          );

                          if (index !== -1) {
                            // Add the inputValue to the object in the array
                            updatedCategoryValue[index].inputValue =
                              e.target.value;
                          }

                          // Update the state with the modified categoryValue array
                          setCategoryValue(updatedCategoryValue);
                        }}
                        widthSize="97%"
                        // value={"dfs0"}
                        paramses
                        InputType="number"
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* bonus */}
        <Box sx={{ mt: 3 }}>
          <TypographyText1 title="Bonus" variant="h6" />
          <Box
            sx={{
              backgroundColor: "#efedf0",
              mt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2} sx={{ m: 1 }}>
                <Grid xs={7}>
                  <Grid container spacing={2} sx={{ m: 1 }}>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 146, 166, 1)",
                        }}
                      >
                        Enrolled Applications Count From
                      </Typography>
                      <Paper
                        sx={{ height: "35px", mt: 1, pb: 1 }}
                        elevation={0}
                      >
                        <TextField
                          className="textField"
                          // label="helllo"
                          value={bonusApplicationFrom}
                          onChange={(e) =>
                            setBonusApplicationFrom(e.target.value)
                          }
                          fullWidth
                          sx={{
                            textAlign: "center",
                            // backgroundColor: "white",
                            border: "none",
                            "& fieldset": {
                              border: "none",
                              borderRadius: "4px",
                            },
                            // height: "auto",
                            "& .MuiFormLabel-root": {
                              height: "32px",
                              pt: 1,
                              // pb:2,

                              "&.Mui-focused": {
                                color: "#7d7d7d",
                                pt: 0.9,
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 146, 166, 1)",
                        }}
                      >
                        Enrolled Applications Count To
                      </Typography>
                      <Paper
                        sx={{ height: "35px", mt: 1, pb: 1 }}
                        elevation={0}
                      >
                        <TextField
                          className="textField"
                          // label="helllo"
                          value={bonusApplicationTo}
                          onChange={(e) =>
                            setBonusApplicationTo(e.target.value)
                          }
                          fullWidth
                          sx={{
                            textAlign: "center",
                            // backgroundColor: "white",
                            border: "none",
                            "& fieldset": {
                              border: "none",
                              borderRadius: "4px",
                            },
                            // height: "auto",
                            "& .MuiFormLabel-root": {
                              height: "32px",
                              pt: 1,
                              // pb:2,

                              "&.Mui-focused": {
                                color: "#7d7d7d",
                                pt: 0.9,
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={5}>
                  <Grid container spacing={2} sx={{ m: 1 }}>
                    <Grid item lg={9}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 146, 166, 1)",
                        }}
                      >
                        Bonus Amount
                      </Typography>
                      <Paper
                        sx={{ height: "35px", mt: 1, pb: 1 }}
                        elevation={0}
                      >
                        <TextField
                          className="textField"
                          // label="helllo"
                          value={applicationIncentiveBonusAmmount}
                          onChange={(e) =>
                            setApplicationIncentiveBonusAmmount(e.target.value)
                          }
                          fullWidth
                          sx={{
                            textAlign: "center",
                            // backgroundColor: "white",
                            border: "none",
                            "& fieldset": {
                              border: "none",
                              borderRadius: "4px",
                            },
                            // height: "auto",
                            "& .MuiFormLabel-root": {
                              height: "32px",
                              pt: 1,
                              // pb:2,

                              "&.Mui-focused": {
                                color: "#7d7d7d",
                                pt: 0.9,
                              },
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <HiOutlinePlus
                        style={{
                          fontSize: "60px",
                          padding: "25px",
                          cursor: "pointer",
                          width: "auto",
                          height: "40px",
                        }}
                        onClick={handleAddBonus}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {bonuStudentAmount.map((ammount, index) => (
                  <>
                    <Grid xs={7}>
                      <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item xs={12} lg={4}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(138, 146, 166, 1)",
                            }}
                          >
                            Enrolled Applications Count From
                          </Typography>
                          <Paper
                            sx={{ height: "35px", mt: 1, pb: 1 }}
                            elevation={0}
                          >
                            <TextField
                              className="textField"
                              // label="helllo"
                              value={ammount.applicationFrom}
                              fullWidth
                              sx={{
                                textAlign: "center",
                                // backgroundColor: "white",
                                border: "none",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "4px",
                                },
                                // height: "auto",
                                "& .MuiFormLabel-root": {
                                  height: "32px",
                                  pt: 1,
                                  // pb:2,

                                  "&.Mui-focused": {
                                    color: "#7d7d7d",
                                    pt: 0.9,
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(138, 146, 166, 1)",
                            }}
                          >
                            Enrolled Applications Count To
                          </Typography>
                          <Paper
                            sx={{ height: "35px", mt: 1, pb: 1 }}
                            elevation={0}
                          >
                            <TextField
                              className="textField"
                              // label="helllo"
                              value={ammount.applicationTo}
                              fullWidth
                              sx={{
                                textAlign: "center",
                                // backgroundColor: "white",
                                border: "none",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "4px",
                                },
                                // height: "auto",
                                "& .MuiFormLabel-root": {
                                  height: "32px",
                                  pt: 1,
                                  // pb:2,

                                  "&.Mui-focused": {
                                    color: "#7d7d7d",
                                    pt: 0.9,
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid xs={5}>
                      <Grid container spacing={2} sx={{ m: 1 }}>
                        <Grid item lg={9}>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(138, 146, 166, 1)",
                            }}
                          >
                            Bonus Amount
                          </Typography>
                          <Paper
                            sx={{ height: "35px", mt: 1, pb: 1 }}
                            elevation={0}
                          >
                            <TextField
                              className="textField"
                              // label="helllo"
                              value={ammount.bonusAmmount}
                              fullWidth
                              sx={{
                                textAlign: "center",
                                // backgroundColor: "white",
                                border: "none",
                                "& fieldset": {
                                  border: "none",
                                  borderRadius: "4px",
                                },
                                // height: "auto",
                                "& .MuiFormLabel-root": {
                                  height: "32px",
                                  pt: 1,
                                  // pb:2,

                                  "&.Mui-focused": {
                                    color: "#7d7d7d",
                                    pt: 0.9,
                                  },
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={6} md={3} lg={3} mt={0.5}>
                          <img
                            src="/menuIcons/trash.png"
                            alt="gsl"
                            style={{
                              marginTop: "19px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleBonusDeleteClick(ammount.applicationFrom)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
            <Box sx={{ p: 3 }}>
              <textarea
                placeholder="Type here"
                rows={5}
                value={bonusNote}
                name="note"
                onChange={(e) => setBonusNote(e.target.value)}
                style={{
                  height: "auto",
                  width: "100%",
                  resize: "none",
                  outline: "none",
                  border: "1px solid rgba(238, 238, 238, 1)",
                  borderRadius: "4px",
                  maxWidth: "auto",
                }}
              />
            </Box>
          </Box>
        </Box>

        <Grid xs={6} lg={4} mt={3}>
          <Box
            sx={{
              boxShadow: 1,
              backgroundColor: "rgba(245, 245, 245, 1)",
              width: "450px",
              height: "200px",
              m: 2,
            }}
          >
            <Typography sx={{ fontWeight: 400, fontSize: "18px", p: 1 }}>
              Extra Incentive per Enrollment
            </Typography>

            <Box
              sx={{
                boxShadow: 1,
                backgroundColor: "white",
                width: "100%",
                height: "auto",
              }}
            >
              <Box m={2}>
                <FormControlLabel
                  value="ammount"
                  control={
                    <Radio
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      onChange={(e) => {
                        setExtraIncentive("");
                        setExtraIncentiveMethod("ammount");
                      }}
                    />
                  }
                  label="Amount"
                />
                <FormControlLabel
                  value="percentage"
                  control={
                    <Radio
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      onChange={(e) => {
                        setExtraIncentive("");
                        setExtraIncentiveMethod("percentage");
                      }}
                    />
                  }
                  label="Percentage"
                />

                <InputField
                  label=""
                  handleChange={(e) => {
                    setExtraIncentive(e.target.value);
                    setExtraIncentiveData({
                      extraIncentiveMethod: extraIncentiveMethod,
                      extraIncentiveCount: e.target.value,
                    });
                  }}
                  widthSize="97%"
                  value={extraIncentive}
                  paramses
                  InputType="number"
                />

                <Box sx={{ p: 0.5 }}>
                  <textarea
                    placeholder="Type here"
                    rows={3}
                    value={extraIncentiveNote}
                    name="note"
                    onChange={(e) => setExtraIncentiveNote(e.target.value)}
                    style={{
                      height: "auto",
                      width: "100%",
                      resize: "none",
                      outline: "none",
                      border: "1px solid rgba(238, 238, 238, 1)",
                      borderRadius: "4px",
                      maxWidth: "auto",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Other Study Abroad */}
        <Box sx={{ mt: 8 }}>
          <TypographyText1 title="Other Study Abroad" variant="h6" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#efedf0",
              mt: 1,
            }}
          >
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid xs={7}>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item xs={12} lg={4}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(138, 146, 166, 1)",
                      }}
                    >
                      Enrolled Applications Count From
                    </Typography>
                    <Paper sx={{ height: "35px", mt: 1, pb: 1 }} elevation={0}>
                      <TextField
                        className="textField"
                        // label="helllo"
                        value={studyAbroadApplicationFrom}
                        onChange={(e) =>
                          setStudyAbroadApplicationFrom(e.target.value)
                        }
                        fullWidth
                        sx={{
                          textAlign: "center",
                          // backgroundColor: "white",
                          border: "none",
                          "& fieldset": { border: "none", borderRadius: "4px" },
                          // height: "auto",
                          "& .MuiFormLabel-root": {
                            height: "32px",
                            pt: 1,
                            // pb:2,

                            "&.Mui-focused": {
                              color: "#7d7d7d",
                              pt: 0.9,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(138, 146, 166, 1)",
                      }}
                    >
                      Enrolled Applications Count To
                    </Typography>
                    <Paper sx={{ height: "35px", mt: 1, pb: 1 }} elevation={0}>
                      <TextField
                        className="textField"
                        // label="helllo"
                        value={studyAbroadApplicationTo}
                        onChange={(e) =>
                          setStudyAbroadApplicationTo(e.target.value)
                        }
                        fullWidth
                        sx={{
                          textAlign: "center",
                          // backgroundColor: "white",
                          border: "none",
                          "& fieldset": { border: "none", borderRadius: "4px" },
                          // height: "auto",
                          "& .MuiFormLabel-root": {
                            height: "32px",
                            pt: 1,
                            // pb:2,

                            "&.Mui-focused": {
                              color: "#7d7d7d",
                              pt: 0.9,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={5}>
                <Grid container spacing={2} sx={{ m: 1 }}>
                  <Grid item lg={9}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "rgba(138, 146, 166, 1)",
                      }}
                    >
                      Commission Amount
                    </Typography>
                    <Paper sx={{ height: "35px", mt: 1, pb: 1 }} elevation={0}>
                      <TextField
                        className="textField"
                        // label="helllo"
                        value={applicationIncentiveStudyAbroadAmmount}
                        onChange={(e) =>
                          setApplicationIncentiveStudyAbroadAmmount(
                            e.target.value
                          )
                        }
                        fullWidth
                        sx={{
                          textAlign: "center",
                          // backgroundColor: "white",
                          border: "none",
                          "& fieldset": { border: "none", borderRadius: "4px" },
                          // height: "auto",
                          "& .MuiFormLabel-root": {
                            height: "32px",
                            pt: 1,
                            // pb:2,

                            "&.Mui-focused": {
                              color: "#7d7d7d",
                              pt: 0.9,
                            },
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <HiOutlinePlus
                      style={{
                        fontSize: "60px",
                        padding: "25px",
                        cursor: "pointer",
                        width: "auto",
                        height: "40px",
                      }}
                      onClick={handleAddStudyAbroad}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {studyAbroadStudentAmount.map((ammount, index) => (
                <>
                  <Grid xs={7}>
                    <Grid container spacing={2} sx={{ m: 1 }}>
                      <Grid item xs={12} lg={4}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "rgba(138, 146, 166, 1)",
                          }}
                        >
                          Enrolled Applications Count From
                        </Typography>
                        <Paper
                          sx={{ height: "35px", mt: 1, pb: 1 }}
                          elevation={0}
                        >
                          <TextField
                            className="textField"
                            // label="helllo"
                            value={ammount.applicationFrom}
                            fullWidth
                            sx={{
                              textAlign: "center",
                              // backgroundColor: "white",
                              border: "none",
                              "& fieldset": {
                                border: "none",
                                borderRadius: "4px",
                              },
                              // height: "auto",
                              "& .MuiFormLabel-root": {
                                height: "32px",
                                pt: 1,
                                // pb:2,

                                "&.Mui-focused": {
                                  color: "#7d7d7d",
                                  pt: 0.9,
                                },
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "rgba(138, 146, 166, 1)",
                          }}
                        >
                          Enrolled Applications Count To
                        </Typography>
                        <Paper
                          sx={{ height: "35px", mt: 1, pb: 1 }}
                          elevation={0}
                        >
                          <TextField
                            className="textField"
                            // label="helllo"
                            value={ammount.applicationTo}
                            fullWidth
                            sx={{
                              textAlign: "center",
                              // backgroundColor: "white",
                              border: "none",
                              "& fieldset": {
                                border: "none",
                                borderRadius: "4px",
                              },
                              // height: "auto",
                              "& .MuiFormLabel-root": {
                                height: "32px",
                                pt: 1,
                                // pb:2,

                                "&.Mui-focused": {
                                  color: "#7d7d7d",
                                  pt: 0.9,
                                },
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid xs={5}>
                    <Grid container spacing={2} sx={{ m: 1 }}>
                      <Grid item lg={9}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "rgba(138, 146, 166, 1)",
                          }}
                        >
                          Commission Amount
                        </Typography>
                        <Paper
                          sx={{ height: "35px", mt: 1, pb: 1 }}
                          elevation={0}
                        >
                          <TextField
                            className="textField"
                            // label="helllo"
                            value={ammount.studyAbroadAmmount}
                            fullWidth
                            sx={{
                              textAlign: "center",
                              // backgroundColor: "white",
                              border: "none",
                              "& fieldset": {
                                border: "none",
                                borderRadius: "4px",
                              },
                              // height: "auto",
                              "& .MuiFormLabel-root": {
                                height: "32px",
                                pt: 1,
                                // pb:2,

                                "&.Mui-focused": {
                                  color: "#7d7d7d",
                                  pt: 0.9,
                                },
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={6} md={3} lg={3} mt={0.5}>
                        <img
                          src="/menuIcons/trash.png"
                          alt="gsl"
                          style={{
                            marginTop: "19px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleStudyDeleteClick(ammount.applicationFrom)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ))}

              <Grid item lg={12}>
                <Box sx={{ p: 1 }}>
                  <textarea
                    placeholder="Type here"
                    rows={5}
                    value={otherStudyAbroadNote}
                    name="note"
                    onChange={(e) => setOtherStudyAbroadNote(e.target.value)}
                    style={{
                      height: "auto",
                      width: "100%",
                      resize: "none",
                      outline: "none",
                      border: "1px solid rgba(238, 238, 238, 1)",
                      borderRadius: "4px",
                      maxWidth: "auto",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={2} mb={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <SubmitButton
            title="Submit"
            submit="click"
            widthSize="75px"
            heightSize="40px"
            type="click"
            handleSubmit={handleSubmit}
          />
        </Box>
      </MainContainer>
    </>
  );
}

export default EmployeeIncentiveCreate;
