import React, { useEffect, useState } from "react";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { MainContainer } from "../../content/content.element";
import InputField, {
  CustomDateField,
  PhoneSelectInputFieldVariableLabel,
  RadioButtonField,
  SelectInputField,
} from "../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../customComponent/Buttons";
import TopicUploadComponent from "../../customComponent/TopicUploadComponent";
import CourseModal from "./course/CourseModal";
import { VideoData } from "../IeltsCourseManagement/courses/courseCategory/Topics/modalDataEntries";
import OfflineClassSelection from "./course/OfflineClassSelection";
import { ieltsCourseManagmentCourseFindAction } from "../../../actions/IeltsActions/courseManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import { ieltsStudentManagmentCreateAction } from "../../../actions/IeltsActions/studentManagmentAction";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";

function StudentRegisterForm({ toggle }) {
  const dispatch = useDispatch();

  const genderOption = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

  const courseOption = [
    {
      label: "Online Class",
      value: "onlineClass",
    },
    {
      label: "Offline Class",
      value: "offlineClass",
    },
  ];

  const paymentOption = [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Not Paid",
      value: "notPaid",
    },
  ];

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { ieltsStudentManagmentCreateSuccess, ieltsStudentManagmentCreateerror } =
    useSelector((state) => {
      return state.ieltsStudentManagmentCreate;
    });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsStudentManagmentCreateSuccess?.status == true
    ) {
      Swal.fire(
        "Created",
        ieltsStudentManagmentCreateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));

      setFormData({
        date: new Date(),
        gender: "male",
        type: "onlineClass",
        isPaid: "paid",
        username: "",
        email: "",
        phoneNumber: "",
        whatsappNumber: "",
        slots: "",
        teachers: "",
      });
    }
    
    if (
      successAlertMsgSuccess &&
      ieltsStudentManagmentCreateerror?.status == false
    ) {
      Swal.fire("Error", ieltsStudentManagmentCreateerror.message, "warning");
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsStudentManagmentCreateSuccess, successAlertMsgSuccess]);

  const [formData, setFormData] = useState({
    // date: new Date(),
    date: null,
    gender: "male",
    type: "onlineClass",
    isPaid: "paid",
    username: "",
    course: "",
    email: "",
    phoneNumber: "",
    whatsappNumber: "",
    centerId: "",
    slotId: "",
    teacherId: "",
    phoneNumberCountryCode: "+91",
    whatsAppNumberCountryCode: "+91",
  });

  const [err, setErr] = useState({
    name: "",
    date: "",
    email: "",
    gender: "",
    phoneNumber: "",
    whatsappNumber: "",
    type: "",
    isPaid: "",
  });

  const [teacherList, setTeacherList] = useState([]);

  // console.log(err, "errerrerrerr");

  const validateForm = () => {
    let isValid = true;
    const newErr = { ...err };

    if (formData.username.trim() === "") {
      newErr.username = "Username cannot be empty";
      isValid = false;
    } else {
      newErr.username = "";
    }

    if (formData.date === null) {
      newErr.date = "date of birth cannot be empty";
      isValid = false;
    } else {
      newErr.date = "";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email.trim() === "") {
      newErr.email = "Email cannot be empty";
      isValid = false;
    } else if (!emailRegex.test(formData.email.trim())) {
      newErr.email = "Invalid email format";
      isValid = false;
    } else {
      newErr.email = "";
    }

    if (formData.phoneNumber.trim() === "") {
      newErr.phoneNumber = "phone number cannot be empty";
      isValid = false;
    } else {
      newErr.phoneNumber = "";
    }

    if (!formData.course) {
      newErr.course = "Course cannot be empty";
      isValid = false;
    } else {
      newErr.course = "";
    }

    if (formData.whatsappNumber.trim() === "") {
      newErr.whatsappNumber = "whatsapp number cannot be empty";
      isValid = false;
    } else {
      newErr.whatsappNumber = "";
    }

    // if (
    //   // formData?.online?.selectedCourse?.length === 0 &&
    //   formData?.offline?.slots?.length === 0
    // ) {
    //   newErr.selectedCourse = "class cannot be empty";
    //   isValid = false;
    // } else {
    //   newErr.selectedCourse = "";
    // }

    // if (formData?.teachers?.length === 0) {
    //   newErr.selectedTeacher = "please select a teacher";
    //   isValid = false;
    // } else {
    //   newErr.selectedTeacher = "";
    // }

    setErr(newErr);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (
      name === "hostVideo" ||
      name === "participantVideo" ||
      name === "waitingRoom"
    ) {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
      setErr((prev) => ({
        ...prev,
        ["Button"]: "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErr((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleDate = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ["date"]: value,
    }));
    setErr((prev) => ({
      ...prev,
      ["date"]: "",
    }));
  };

  const handleAddNewButton = () => {
    console.log(validateForm());
    if (validateForm()) {
      console.log(formData, "formDataaaaaaaaaaaaaaaaaaaaaaaa");
      dispatch(ieltsStudentManagmentCreateAction(formData));
      dispatch(successTrueMsgAction(true));
    } else {
      alert("Form is invalid, please check errors");
      console.log(err);
    }
  };

  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState(VideoData);
  const [teacherModalData, setTeacherModalData] = useState(VideoData);
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [course, setCourse] = useState([]);

  const handleClickOpen = (title) => {
    setModal(title);
  };
  const handleClickClose = (title) => {
    setModal("");
  };

  const handleCourse = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErr((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleTecher = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      ["teacherId"]: value,
    }));
  };

  let {
    ieltsCourseManagmentCourseFindSuccess,
    ieltsCourseManagmentCourseFinderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseFind;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseFindAction());
  }, []);

  useEffect(() => {
    if (ieltsCourseManagmentCourseFindSuccess) {
      setCourse(ieltsCourseManagmentCourseFindSuccess.data);
    }
  }, [ieltsCourseManagmentCourseFindSuccess]);

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Register Student" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <div style={{ maxWidth: "1194px" }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "25px",
              "& .input-group": {
                mb: "0px",
                mt: "0",
              },
            }}
          >
            <Box>
              <InputField
                label="Full Name"
                handleChange={handleChange}
                value={formData?.username}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"username"}
                dispcriptionHeight
              />
              {err.username ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.username}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <CustomDateField
                name="date"
                value={formData.date}
                placeholder={"Date Of Birth"}
                // value={formData.date === new Date() ? formData.date : "Date Of Birth"}
                handleChange={handleDate}
                maxDate={new Date()}
              />
              {err?.date && (
                <Typography sx={{ color: "red", fontSize: "12px" }}>
                  {err?.date}
                </Typography>
              )}
            </Box>
            <Box>
              <InputField
                label="Email"
                handleChange={handleChange}
                value={formData?.email}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"email"}
                dispcriptionHeight
              />
              {err.email ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.email}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <Box>
              <RadioButtonField
                label={"Gender"}
                name="gender"
                handleChange={handleChange}
                radioOptions={genderOption}
                value={formData.gender}
              />
            </Box>

            <Box>
              <PhoneSelectInputFieldVariableLabel
                getCountryCode={(value) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    ["phoneNumberCountryCode"]: value,
                  }))
                }
                placeholder={"Add Phone Number"}
                name={"phoneNumber"}
                value={formData.phoneNumber}
                mt={"0"}
                label="none"
                handleChange={handleChange}
              />
              {err.phoneNumber ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.phoneNumber}
                </Typography>
              ) : (
                ""
              )}
            </Box>

            <Box>
              <PhoneSelectInputFieldVariableLabel
                getCountryCode={(value) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    ["whatsAppNumberCountryCode"]: value,
                  }))
                }
                placeholder={"Add Whatsapp Number"}
                name={"whatsappNumber"}
                value={formData.whatsappNumber}
                mt={"0"}
                label="none"
                handleChange={handleChange}
              />
              {err.whatsappNumber ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.whatsappNumber}
                </Typography>
              ) : (
                ""
              )}
            </Box>
          </Box>

          <Box
            sx={{
              my: "32px",
            }}
          >
            <RadioButtonField
              name="type"
              handleChange={handleChange}
              radioOptions={courseOption}
              value={formData.type}
            />
          </Box>

          <Box
            sx={{
              mb: "32px",
            }}
          >
            <Box
              sx={{
                padding: "20px",
                backgroundColor: "rgba(245, 245, 245, 1)",
                display: "grid",
                // gridTemplateColumns: {
                //   sm: "repeat(2,1fr)",
                //   md: "repeat(3,1fr)",
                //   xl: "repeat(6,13%)",
                // },
                gap: "25px",
              }}
            >
              {/* {formData?.online?.selectedCourse?.length > 0 ? (
                  <>
                    {formData?.online?.selectedCourse?.map((item) => (
                      <Box
                        sx={{
                          padding: "10px",
                          display: "grid",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: "4px",
                          //   gridTemplateColumns: "150px 1fr",
                        }}
                      >
                        <Box
                          sx={{
                            width: "70px",
                            height: "70px",
                          }}
                        >
                          <img src={item?.thumbnail_url} />
                        </Box>
                        <Box>
                          <Typography>{item.description}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box>
                    <TopicUploadComponent
                      type="add"
                      title={"Select Course"}
                      handleClick={() => handleClickOpen("course")}
                    />
                    {err.selectedCourse ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {err.selectedCourse}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                )} */}

              <Box sx={{ maxWidth: "500px" }}>
                <SelectInputField
                  handleSelectChange={handleCourse}
                  name={"course"}
                  value={formData.course}
                  label="Select Course"
                  mapValue={course}
                  mapMethod={"ieltsLiveClass"}
                />
                {/* {errorData?.course && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errorData?.course}
                    </Typography>
                  )} */}
              </Box>
            </Box>
          </Box>
          {formData.type === "offlineClass" && (
            <OfflineClassSelection
              parentData={formData}
              setParentData={setFormData}
              teacherList={setTeacherList}
            />
          )}

          <Box
            sx={{
              mt: "32px",
            }}
          >
            <RadioButtonField
              name="isPaid"
              handleChange={handleChange}
              radioOptions={paymentOption}
              value={formData.isPaid}
            />
          </Box>

          {formData.type == "offlineClass" ? (
            <Box
              sx={{
                mb: "32px",
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                  backgroundColor: "rgba(245, 245, 245, 1)",
                  display: "grid",
                  // gridTemplateColumns: {
                  //   sm: "repeat(2,1fr)",
                  //   md: "repeat(3,1fr)",
                  //   xl: "repeat(6,13%)",
                  // },
                  gap: "25px",
                }}
              >
                {/* {formData?.teachers?.length > 0 ? (
                  <>
                    {formData?.teachers?.map((item) => (
                      <Box
                        sx={{
                          padding: "10px",
                          display: "grid",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: "4px",
                          //   gridTemplateColumns: "150px 1fr",
                        }}
                      >
                        <Box
                          sx={{
                            width: "70px",
                            height: "70px",
                          }}
                        >
                          <img src={item?.thumbnail_url} />
                        </Box>
                        <Box>
                          <Typography>{item.description}</Typography>
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box>
                    <TopicUploadComponent
                      type="add"
                      title={"Assign Teacher"}
                      handleClick={() => handleClickOpen("teacher")}
                    />
                    {err.selectedTeacher ? (
                      <Typography
                        sx={{
                          color: "red",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {err.selectedTeacher}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                )} */}

                <Box>
                  <SelectInputField
                    handleSelectChange={handleTecher}
                    name={"teachers"}
                    value={formData.teacherId}
                    label="Assigned Teacher"
                    mapValue={teacherList}
                    mapMethod={"ieltsLiveClassTeacher"}
                  />
                  {/* {errorData?.course && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errorData?.course}
                    </Typography>
                  )} */}
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </div>

        <Box
          sx={{
            mt: "44px",
            mb: "30px",
          }}
        >
          <SubmitButton
            title="Save"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={handleAddNewButton}
          />
        </Box>
      </div>
    </MainContainer>
  );
}

export default StudentRegisterForm;
