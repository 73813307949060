import axios from "axios";
import {
  ADD_NEW_GALLERY_ITEMS_ERR,
  ADD_NEW_GALLERY_ITEMS_REQUEST,
  ADD_NEW_GALLERY_ITEMS_SUCCESS,
  ADMISSIONDOCUMENTS_FIND_ERR,
  ADMISSIONDOCUMENTS_FIND_REQUEST,
  ADMISSIONDOCUMENTS_FIND_SUCCESS,
  BLOGS_CREATE_REQUEST,
  BLOGS_CREATE_SUCCESS,
  BLOGS_DELETE_ERR,
  BLOGS_DELETE_REQUEST,
  BLOGS_DELETE_SUCCESS,
  BLOGS_EDIT_ERR,
  BLOGS_EDIT_GET_ERR,
  BLOGS_EDIT_GET_REQUEST,
  BLOGS_EDIT_GET_SUCCESS,
  BLOGS_EDIT_REQUEST,
  BLOGS_EDIT_SUCCESS,
  BLOGS_FIND_ERR,
  BLOGS_FIND_REQUEST,
  BLOGS_FIND_SUCCESS,
  CREATE_OFFICE_ERR,
  CREATE_OFFICE_REQUEST,
  CREATE_OFFICE_SUCCESS,
  DELETE_GALLERY_ITEMS_BY_ID_ERR,
  DELETE_GALLERY_ITEMS_BY_ID_REQUEST,
  DELETE_GALLERY_ITEMS_BY_ID_SUCCESS,
  DELETE_OFFICE_BY_ID_ERR,
  DELETE_OFFICE_BY_ID_REQUEST,
  DELETE_OFFICE_BY_ID_SUCCESS,
  DESTINATION_CREATE_ERR,
  DESTINATION_CREATE_REQUEST,
  DESTINATION_CREATE_SUCCESS,
  DESTINATION_DELETE_ERR,
  DESTINATION_DELETE_REQUEST,
  DESTINATION_DELETE_SUCCESS,
  DESTINATION_EDIT_ERR,
  DESTINATION_EDIT_REQUEST,
  DESTINATION_EDIT_SUCCESS,
  DESTINATION_FIND_BY_ID_ERR,
  DESTINATION_FIND_BY_ID_REQUEST,
  DESTINATION_FIND_BY_ID_SUCCESS,
  DESTINATION_FIND_ERR,
  DESTINATION_FIND_REQUEST,
  DESTINATION_FIND_SUCCESS,
  EDIT_OFFICE_ERR,
  EDIT_OFFICE_REQUEST,
  EDIT_OFFICE_SUCCESS,
  EVENTS_CREATE_ERR,
  EVENTS_CREATE_REQUEST,
  EVENTS_CREATE_SUCCESS,
  EVENTS_DELETE_ERR,
  EVENTS_DELETE_REQUEST,
  EVENTS_DELETE_SUCCESS,
  EVENTS_EDIT_ERR,
  EVENTS_EDIT_REQUEST,
  EVENTS_EDIT_SUCCESS,
  EVENTS_FIND_BY_ID_ERR,
  EVENTS_FIND_BY_ID_REQUEST,
  EVENTS_FIND_BY_ID_SUCCESS,
  EVENTS_FIND_ERR,
  EVENTS_FIND_REQUEST,
  EVENTS_FIND_SUCCESS,
  FEATUREDADS_CREATE_ERR,
  FEATUREDADS_CREATE_REQUEST,
  FEATUREDADS_CREATE_SUCCESS,
  FEATUREDADS_DELETE_ERR,
  FEATUREDADS_DELETE_REQUEST,
  FEATUREDADS_DELETE_SUCCESS,
  FEATUREDADS_FIND_ERR,
  FEATUREDADS_FIND_REQUEST,
  FEATUREDADS_FIND_SUCCESS,
  FEATURED_ADS_EDIT_ERR,
  FEATURED_ADS_EDIT_REQUEST,
  FEATURED_ADS_EDIT_SUCCESS,
  FEATURED_ADS_FIND_BY_ID_ERR,
  FEATURED_ADS_FIND_BY_ID_REQUEST,
  FEATURED_ADS_FIND_BY_ID_SUCCESS,
  FIND_GALLERY_ITEMS_BY_ID_ERR,
  FIND_GALLERY_ITEMS_BY_ID_REQUEST,
  FIND_GALLERY_ITEMS_BY_ID_SUCCESS,
  FIND_GALLERY_ITEMS_ERR,
  FIND_GALLERY_ITEMS_REQUEST,
  FIND_GALLERY_ITEMS_SUCCESS,
  GET_OFFICE_BY_ID_ERR,
  GET_OFFICE_BY_ID_REQUEST,
  GET_OFFICE_BY_ID_SUCCESS,
  GET_OFFICE_ERR,
  GET_OFFICE_REQUEST,
  GET_OFFICE_SUCCESS,
  NEWS_CREATE_ERR,
  NEWS_CREATE_REQUEST,
  NEWS_CREATE_SUCCESS,
  NEWS_DELETE_ERR,
  NEWS_DELETE_REQUEST,
  NEWS_DELETE_SUCCESS,
  NEWS_EDIT_ERR,
  NEWS_EDIT_REQUEST,
  NEWS_EDIT_SUCCESS,
  NEWS_FIND_BY_ID_ERR,
  NEWS_FIND_BY_ID_REQUEST,
  NEWS_FIND_BY_ID_SUCCESS,
  NEWS_FIND_ERR,
  NEWS_FIND_REQUEST,
  NEWS_FIND_SUCCESS,
  SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_ERR,
  SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_REQUEST,
  SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_SUCCESS,
  SCHOLARSHIP_APPLICATION_CREATE_ERR,
  SCHOLARSHIP_APPLICATION_CREATE_REQUEST,
  SCHOLARSHIP_APPLICATION_CREATE_SUCCESS,
  SERVICE_CREATE_ERR,
  SERVICE_CREATE_REQUEST,
  SERVICE_CREATE_SUCCESS,
  SERVICE_EDIT_ERR,
  SERVICE_EDIT_REQUEST,
  SERVICE_EDIT_SUCCESS,
  SERVICE_FIND_BY_ID_ERR,
  SERVICE_FIND_BY_ID_REQUEST,
  SERVICE_FIND_BY_ID_SUCCESS,
  SERVICE_FIND_ERR,
  SERVICE_FIND_REQUEST,
  SERVICE_FIND_SUCCESS,
  TALKTOEXPERT_CONVERT_TO_LEAD_ERR,
  TALKTOEXPERT_CONVERT_TO_LEAD_REQUEST,
  TALKTOEXPERT_CONVERT_TO_LEAD_SUCCESS,
  TALK_TO_EXPERT_CHANGE_STATUS_ERR,
  TALK_TO_EXPERT_CHANGE_STATUS_REQUEST,
  TALK_TO_EXPERT_CHANGE_STATUS_SUCCESS,
  TALK_TO_EXPERT_DELETE_STATUS_ERR,
  TALK_TO_EXPERT_DELETE_STATUS_REQUEST,
  TALK_TO_EXPERT_DELETE_STATUS_SUCCESS,
  TALK_TO_EXPERT_FIND_ERR,
  TALK_TO_EXPERT_FIND_REQUEST,
  TALK_TO_EXPERT_FIND_SUCCESS,
  TESTIMONIAL_CREATE_ERR,
  TESTIMONIAL_CREATE_REQUEST,
  TESTIMONIAL_CREATE_SUCCESS,
  TESTIMONIAL_DELETE_ERR,
  TESTIMONIAL_DELETE_REQUEST,
  TESTIMONIAL_DELETE_SUCCESS,
  TESTIMONIAL_EDIT_ERR,
  TESTIMONIAL_EDIT_REQUEST,
  TESTIMONIAL_EDIT_SUCCESS,
  TESTIMONIAL_FIND_BY_ID_ERR,
  TESTIMONIAL_FIND_BY_ID_REQUEST,
  TESTIMONIAL_FIND_BY_ID_SUCCESS,
  TESTIMONIAL_FIND_ERR,
  TESTIMONIAL_FIND_REQUEST,
  TESTIMONIAL_FIND_SUCCESS,
  TOPCOURSE_FIND_ERR,
  TOPCOURSE_FIND_REQUEST,
  TOPCOURSE_FIND_SUCCESS,
  TOPNAVIGATION_CREATE_ERR,
  TOPNAVIGATION_CREATE_REQUEST,
  TOPNAVIGATION_CREATE_SUCCESS,
  TOPNAVIGATION_DELETE_ERR,
  TOPNAVIGATION_DELETE_REQUEST,
  TOPNAVIGATION_DELETE_SUCCESS,
  TOPNAVIGATION_FINDEDIT_ERR,
  TOPNAVIGATION_FINDEDIT_REQUEST,
  TOPNAVIGATION_FINDEDIT_SUCCESS,
  TOPNAVIGATION_FIND_ERR,
  TOPNAVIGATION_FIND_REQUEST,
  TOPNAVIGATION_FIND_SUCCESS,
  TOPNAVIGATION_UPDATE_ERR,
  TOPNAVIGATION_UPDATE_REQUEST,
  TOPNAVIGATION_UPDATE_SUCCESS,
  UPDATE_GALLERY_ITEMS_BY_ID_ERR,
  UPDATE_GALLERY_ITEMS_BY_ID_REQUEST,
  UPDATE_GALLERY_ITEMS_BY_ID_SUCCESS,
} from "../constants/LandingPage/LandingPageConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../constants/roleManagmentConstant";

// TopNavigation_Find_Request

export const TopNavigationFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPNAVIGATION_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/topNavigationFind",
      config
    );
    dispatch({ type: TOPNAVIGATION_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: TOPNAVIGATION_FIND_ERR, payload: error.response.data });
  }
};

export const TopNavigationCreateAction = (
  TopNavContent,
  CallToAction,
  CallToActionLink,
  ExpireDate
) => async (dispatch, getState) => {
  try {
    console.log(ExpireDate, "topnavigation action");
    dispatch({ type: TOPNAVIGATION_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/topNavigationCreate",
      {
        TopNavContent,
        CallToAction,
        CallToActionLink,
        ExpireDate,
      },
      config
    );
    dispatch({ type: TOPNAVIGATION_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: TOPNAVIGATION_CREATE_ERR,
      payload: error.response.data,
    });
  }
};

// Delete

export const TopNavigationDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPNAVIGATION_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/topNavigationDelete?id=${id}`,
      config
    );
    dispatch({ type: TOPNAVIGATION_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: TOPNAVIGATION_DELETE_ERR, payload: error.response.data });
  }
};

// Edit

export const TopNavigationEditAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TOPNAVIGATION_FINDEDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/topNavigationEdit?id=${id}`,
      config
    );
    dispatch({ type: TOPNAVIGATION_FINDEDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: TOPNAVIGATION_FINDEDIT_ERR,
      payload: error.response.data,
    });
  }
};

// Update

export const TopNavigationUpdateAction = (
  id,
  TopNavContent,
  CallToAction,
  CallToActionLink,
  ExpireDate
) => async (dispatch, getState) => {
  try {
    console.log(ExpireDate, "topnavigation action");
    dispatch({ type: TOPNAVIGATION_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/topNavigationUpdate?id=${id}`,
      {
        TopNavContent,
        CallToAction,
        CallToActionLink,
        ExpireDate,
      },
      config
    );
    dispatch({ type: TOPNAVIGATION_UPDATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: TOPNAVIGATION_UPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

//   // Destination_Form

//  Create

export const DestinationFormCreateAction = (
  DestinationName,
  DestinationImage,
  Guide,
  Description,
  TopUniversities,
  TopCourses,
  AdmissionDocuments,
  admissionProcedure,
  costEstimate,
  Faq,
  currencySymbol
) => async (dispatch, getState) => {
  try {
    console.log(DestinationName, "DestinationNameDestinationName");
    dispatch({ type: DESTINATION_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/destinationCreate",
      {
        DestinationName,
        DestinationImage,
        Guide,
        Description,
        TopUniversities,
        TopCourses,
        AdmissionDocuments,
        admissionProcedure,
        costEstimate,
        Faq,
        currencySymbol,
      },
      config
    );
    dispatch({ type: DESTINATION_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: DESTINATION_CREATE_ERR, payload: error.response.data });
  }
};

//  EDIT

// DestinationFormEditAction
export const DestinationFormEditAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DESTINATION_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.put(
      "/api/admin/landingPage/destinationEdit",
      formData,
      config
    );
    dispatch({ type: DESTINATION_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: DESTINATION_EDIT_ERR, payload: error.response.data });
  }
};

// Find_request

export const DestinationFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DESTINATION_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/destinationFind",
      config
    );
    dispatch({ type: DESTINATION_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: DESTINATION_FIND_ERR, payload: error.response.data });
  }
};

// destinationFindByIDAction
export const destinationFindByIDAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DESTINATION_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/destinationFindByID?id=${id}`,
      config
    );
    dispatch({ type: DESTINATION_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: DESTINATION_FIND_BY_ID_ERR,
      payload: error.response.data,
    });
  }
};

// Destination delete actioin

export const DestinationDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DESTINATION_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/destinationDelete?id=${id}`,
      config
    );
    dispatch({ type: DESTINATION_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: DESTINATION_DELETE_ERR, payload: error.response.data });
  }
};

// SERVICE START
// LandingServiceAddAction
export const LandingServiceAddAction = (
  serviceName,
  description,
  description1,
  description2,
  feature
) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/serviceCreate",
      {
        serviceName,
        description,
        description1,
        description2,
        feature,
      },
      config
    );
    dispatch({ type: SERVICE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: SERVICE_CREATE_ERR, payload: error.response.data });
  }
};

// LandingServiceEditAction
export const LandingServiceEditAction = (
  id,
  serviceName,
  description,
  description1,
  description2,
  feature
) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/serviceEdit?id=${id}`,
      {
        serviceName,
        description,
        description1,
        description2,
        feature,
      },
      config
    );
    dispatch({ type: SERVICE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: SERVICE_EDIT_ERR, payload: error.response.data });
  }
};

// LandingServiceFindAction
export const LandingServiceFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/serviceFind",
      config
    );
    dispatch({ type: SERVICE_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: SERVICE_FIND_ERR, payload: error.response.data });
  }
};

// serviceFindByIdAction
export const serviceFindByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/serviceFindById?id=${id}`,
      config
    );

    console.log(
      data,
      "datadatadatadatadatadatadatadatadatadatadatadatadatadatadatadatadatadatadatadata"
    );
    dispatch({ type: SERVICE_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: SERVICE_FIND_BY_ID_ERR, payload: error.response.data });
  }
};

// LANDING NEWS PAGE
// newsCreateAction
export const newsCreateAction = (
  DestinationimageValue,
  category,
  title,
  htmlContent,
  tags
) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEWS_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/createLandingNews",
      { DestinationimageValue, category, title, htmlContent, tags },
      config
    );
    dispatch({ type: NEWS_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: NEWS_CREATE_ERR, payload: error.response.data });
  }
};

// newsCreateAction
export const findLandingNewsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NEWS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/findLandingNews",
      config
    );
    dispatch({ type: NEWS_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: NEWS_FIND_ERR, payload: error.response.data });
  }
};

// newsFindByIdAction
export const newsFindByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEWS_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/newsFindById?id=${id}`,
      config
    );
    dispatch({ type: NEWS_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: NEWS_FIND_BY_ID_ERR, payload: error.response.data });
  }
};

// blogDeleteAction
export const newsDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEWS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/newsDelete?id=${id}`,
      config
    );
    dispatch({ type: NEWS_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: NEWS_DELETE_ERR, payload: error.response.data });
  }
};

// newsEditAction
export const newsEditAction = (
  id,
  DestinationimageValue,
  category,
  title,
  htmlContent,
  tags
) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEWS_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/newsEdit?id=${id}`,
      { DestinationimageValue, category, title, htmlContent, tags },
      config
    );
    dispatch({ type: NEWS_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: NEWS_EDIT_ERR, payload: error.response.data });
  }
};

// BLOGS Action
// blogCreateAction
export const blogCreateAction = (
  DestinationimageValue,
  category,
  title,
  htmlContent,
  tags,
  blogSlug,
  seoKeyword
) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOGS_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/createLandingBlogs",
      {
        DestinationimageValue,
        category,
        title,
        htmlContent,
        tags,
        blogSlug,
        seoKeyword,
      },
      config
    );
    dispatch({ type: BLOGS_CREATE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: BLOGS_CREATE_SUCCESS, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
// blogGetEditDataAction
export const blogGetEditDataAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOGS_EDIT_GET_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/getEditLandingBlogs?id=${id}`,
      config
    );
    dispatch({ type: BLOGS_EDIT_GET_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: BLOGS_EDIT_GET_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// blogGetEditDataAction
export const blogEditDataAction = (
  id,
  DestinationimageValue,
  category,
  title,
  htmlContent,
  tags,
  blogSlug,
  seoKeyword
) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOGS_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/EditLandingBlogs?id=${id}`,
      {
        DestinationimageValue,
        category,
        title,
        htmlContent,
        tags,
        blogSlug,
        seoKeyword,
      },
      config
    );
    dispatch({ type: BLOGS_EDIT_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: BLOGS_EDIT_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findLandingBlogsAction
export const findLandingBlogsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOGS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/findLandingBlogs",
      config
    );
    dispatch({ type: BLOGS_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: BLOGS_FIND_ERR, payload: error.response.data });
  }
};

// blogDeleteAction
export const blogDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOGS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/blogDelete?id=${id}`,
      config
    );
    dispatch({ type: BLOGS_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: BLOGS_DELETE_ERR, payload: error.response.data });
  }
};

// TESTIMONIAL ACTION

// testimonialAction
export const testimonialAction = (
  newsImage,
  name,
  universityValue,
  content
) => async (dispatch, getState) => {
  try {
    dispatch({ type: TESTIMONIAL_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/createLandingTestimonial",
      {
        newsImage,
        name,
        universityValue,
        content,
      },
      config
    );
    dispatch({ type: TESTIMONIAL_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: TESTIMONIAL_CREATE_ERR, payload: error.response.data });
  }
};

// findLandingTestimonialAction
export const findLandingTestimonialAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: TESTIMONIAL_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/findLandingTestimonial",
      config
    );
    dispatch({ type: TESTIMONIAL_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: TESTIMONIAL_FIND_ERR, payload: error.response.data });
  }
};

// findTestimonialByIdAction
export const findTestimonialByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TESTIMONIAL_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/findTestimonialById?id=${id}`,
      config
    );
    console.log(data, "datadatadatadatadatadatadatadatadatadata");
    dispatch({ type: TESTIMONIAL_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: TESTIMONIAL_FIND_BY_ID_ERR,
      payload: error.response.data,
    });
  }
};

// testimonialsDeleteAction
export const testimonialsDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TESTIMONIAL_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/testimonialsDelete?id=${id}`,
      config
    );
    dispatch({ type: TESTIMONIAL_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: TESTIMONIAL_DELETE_ERR, payload: error.response.data });
  }
};

// testimonialEditAction
export const testimonialEditAction = (
  id,
  newsImage,
  name,
  universityValue,
  content
) => async (dispatch, getState) => {
  try {
    dispatch({ type: TESTIMONIAL_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/editLandingTestimonial?id=${id}`,
      {
        newsImage,
        name,
        universityValue,
        content,
      },
      config
    );
    dispatch({ type: TESTIMONIAL_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: TESTIMONIAL_EDIT_ERR, payload: error.response.data });
  }
};

// FEATUREDADS

// createFeaturedAdsAction
export const createFeaturedAdsAction = (
  newsImage,
  name,
  videoIframe,
  content
) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEATUREDADS_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/createFeaturedAds",
      {
        newsImage,
        name,
        videoIframe,
        content,
      },
      config
    );
    dispatch({ type: FEATUREDADS_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: FEATUREDADS_CREATE_ERR, payload: error.response.data });
  }
};

// findLandingFeaturedAdsAction
export const findLandingFeaturedAdsAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FEATUREDADS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/findLandingFeaturedAds",
      config
    );
    dispatch({ type: FEATUREDADS_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: FEATUREDADS_FIND_ERR, payload: error.response.data });
  }
};

// featuredAdsDeleteAction
export const featuredAdsDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEATUREDADS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/featuredAdsDelete?id=${id}`,
      config
    );
    dispatch({ type: FEATUREDADS_DELETE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: FEATUREDADS_DELETE_ERR, payload: error.response.data });
  }
};

// featuredAdsEditAction
export const featuredAdsEditAction = (
  id,
  newsImage,
  name,
  addIframe,
  content
) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEATURED_ADS_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/editFeaturedAds?id=${id}`,
      {
        newsImage,
        name,
        addIframe,
        content,
      },
      config
    );
    dispatch({ type: FEATURED_ADS_EDIT_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: FEATURED_ADS_EDIT_ERR, payload: error.response.data });
  }
};

// findfeaturedAdsByIdAction
export const findFeaturedAdsByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: FEATURED_ADS_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/findFeaturedAds?id=${id}`,
      config
    );
    console.log(data, "datadatadatadatadatadatadatadatadatadata");
    dispatch({ type: FEATURED_ADS_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: FEATURED_ADS_FIND_BY_ID_ERR,
      payload: error.response.data,
    });
  }
};

// events create action
export const eventsCreateAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENTS_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/createEvent",
      formData,
      config
    );
    dispatch({ type: EVENTS_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EVENTS_CREATE_ERR, payload: error.response.data });
  }
};

// events find action

export const eventsFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENTS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.get("/api/admin/landingPage/findEvents", config);
    dispatch({ type: EVENTS_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EVENTS_FIND_ERR, payload: error.response.data });
  }
};

// findEventByIdAction
export const findEventByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENTS_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/findById?id=${id}`,
      config
    );
    dispatch({ type: EVENTS_FIND_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EVENTS_FIND_BY_ID_ERR, payload: error.response.data });
  }
};

// eventsEditAction
export const eventsEditAction = (id, formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: EVENTS_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/editEvent?id=${id}`,
      formData,
      config
    );
    dispatch({ type: EVENTS_EDIT_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EVENTS_EDIT_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// eventDeleteAction
export const eventDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EVENTS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/deleteEvent?id=${id}`,
      config
    );
    dispatch({ type: EVENTS_DELETE_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EVENTS_DELETE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// Talk To Expert

export const TalkToExpertAction = () => async (dispatch) => {
  try {
    dispatch({ type: TALK_TO_EXPERT_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/talkToExpert",
      config
    );

    dispatch({
      type: TALK_TO_EXPERT_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error, "error.response");
    dispatch({
      type: TALK_TO_EXPERT_FIND_ERR,
      payload: error,
    });
  }
};

// talk to Expert Change Status

export const TalkToExpertChangeStatusAction = (id, status) => async (
  dispatch
) => {
  try {
    dispatch({ type: TALK_TO_EXPERT_CHANGE_STATUS_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/talkToExpert/changeStatus`,
      {
        id,
        status,
      },
      config
    );

    dispatch({
      type: TALK_TO_EXPERT_CHANGE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error, "error.response");
    dispatch({
      type: TALK_TO_EXPERT_CHANGE_STATUS_ERR,
      payload: error,
    });
  }
};

// talk to expert Delete

export const TalkToExpertDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: TALK_TO_EXPERT_DELETE_STATUS_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/talkToExpert/delete?id=${id}`,
      config
    );

    dispatch({
      type: TALK_TO_EXPERT_DELETE_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error, "error.response");
    dispatch({
      type: TALK_TO_EXPERT_DELETE_STATUS_ERR,
      payload: error,
    });
  }
};

// events find action

export const scholarshipApplicationAction = () => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SCHOLARSHIP_APPLICATION_CREATE_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/scholarshipList",
      config
    );
    dispatch({ type: SCHOLARSHIP_APPLICATION_CREATE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: SCHOLARSHIP_APPLICATION_CREATE_ERR,
      payload: error.response.data,
    });
  }
};

export const scholarshipApplicationConvertToLeadAction = (
  name,
  email,
  phonenumber,
  countryCode
) => async (dispatch, getState) => {
  try {
    dispatch({ type: SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/convertScholarshipToLeads",
      { name, email, phonenumber, countryCode },
      config
    );
    dispatch({
      type: SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: SCHOLARSHIP_APPLICATION_CONVERT_TO_LEAD_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

export const talkToexpertConvertToLeadAction = (
  name,
  email,
  phonenumber,
  countryCode
) => async (dispatch, getState) => {
  try {
    dispatch({ type: TALKTOEXPERT_CONVERT_TO_LEAD_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/talktoExpertTolead",
      { name, email, phonenumber, countryCode },
      config
    );
    dispatch({
      type: TALKTOEXPERT_CONVERT_TO_LEAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: TALKTOEXPERT_CONVERT_TO_LEAD_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// office

export const createOfficeAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_OFFICE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/landingPage/createAddress",
      formData,
      config
    );
    dispatch({ type: CREATE_OFFICE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: CREATE_OFFICE_ERR, payload: error.response.data });
  }
};

export const updateOfficeAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_OFFICE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      "/api/admin/landingPage/updateOfficeAddress",
      formData,
      config
    );
    dispatch({ type: EDIT_OFFICE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: EDIT_OFFICE_ERR, payload: error.response.data });
  }
};

export const getAllOfficeAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_OFFICE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/landingPage/findOfficeAddress",
      config
    );
    dispatch({ type: GET_OFFICE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: GET_OFFICE_ERR, payload: error.response.data });
  }
};

export const getOfficeByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_OFFICE_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/findOfficeById?id=${id}`,
      config
    );
    dispatch({ type: GET_OFFICE_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: GET_OFFICE_BY_ID_ERR, payload: error.response.data });
  }
};

export const deleteOfficeByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_OFFICE_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/deleteOfficeAddress?id=${id}`,
      config
    );
    dispatch({ type: DELETE_OFFICE_BY_ID_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: DELETE_OFFICE_BY_ID_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// add new gallery items
export const addNewGalleryItemsAction = (formdata) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ADD_NEW_GALLERY_ITEMS_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      `/api/admin/landingPage/galleryCreate`,
      formdata,
      config
    );
    dispatch({ type: ADD_NEW_GALLERY_ITEMS_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({ type: ADD_NEW_GALLERY_ITEMS_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// find new gallery items
export const findNewGalleryItemsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FIND_GALLERY_ITEMS_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/galleryFind`,
      config
    );
    dispatch({ type: FIND_GALLERY_ITEMS_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: FIND_GALLERY_ITEMS_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// find gallery items by id
export const findGalleryItemsByIdAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FIND_GALLERY_ITEMS_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/landingPage/galleryFindOne?id=${id}`,
      config
    );
    dispatch({ type: FIND_GALLERY_ITEMS_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: FIND_GALLERY_ITEMS_BY_ID_ERR,
      payload: error.response.data,
    });
  }
};

// update gallery items by id
export const updateGalleryItemsByIdAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: UPDATE_GALLERY_ITEMS_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.put(
      `/api/admin/landingPage/galleryUpdate`,
      formData,
      config
    );
    dispatch({ type: UPDATE_GALLERY_ITEMS_BY_ID_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: UPDATE_GALLERY_ITEMS_BY_ID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// delete gallery items by id
export const deleteGalleryItemsByIdAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: DELETE_GALLERY_ITEMS_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/landingPage/deletegallery?id=${id}`,
      config
    );
    dispatch({ type: DELETE_GALLERY_ITEMS_BY_ID_SUCCESS, payload: data });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: DELETE_GALLERY_ITEMS_BY_ID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
