import axios from "axios";
import {
  COURSELEVELMASTER_CREATE_ERR,
  COURSELEVELMASTER_CREATE_REQUEST,
  COURSELEVELMASTER_CREATE_SUCCESS,
  COURSELEVELMASTER_DELETE_ERR,
  COURSELEVELMASTER_DELETE_REQUEST,
  COURSELEVELMASTER_DELETE_SUCCESS,
  COURSELEVELMASTER_FIND_ERR,
  COURSELEVELMASTER_FIND_REQUEST,
  COURSELEVELMASTER_FIND_SUCCESS,
  COURSELEVELMASTER_UPDATE_ERR,
  COURSELEVELMASTER_UPDATE_REQUEST,
  COURSELEVELMASTER_UPDATE_SUCCESS,
  COURSEMASTER_CREATE_ERR,
  COURSEMASTER_CREATE_REQUEST,
  COURSEMASTER_CREATE_SUCCESS,
  COURSEMASTER_DELETE_ERR,
  COURSEMASTER_DELETE_REQUEST,
  COURSEMASTER_DELETE_SUCCESS,
  COURSEMASTER_FINDONE_ERR,
  COURSEMASTER_FINDONE_REQUEST,
  COURSEMASTER_FINDONE_SUCCESS,
  COURSEMASTER_FIND_ERR,
  COURSEMASTER_FIND_REQUEST,
  COURSEMASTER_FIND_SUCCESS,
  COURSEMASTER_UPDATE_ERR,
  COURSEMASTER_UPDATE_REQUEST,
  COURSEMASTER_UPDATE_SUCCESS,
  SUBJECTMASTER_BULKUPLOAD_ERR,
  SUBJECTMASTER_BULKUPLOAD_REQUEST,
  SUBJECTMASTER_BULKUPLOAD_SUCCESS,
  SUBJECTMASTER_CREATE_ERR,
  SUBJECTMASTER_CREATE_REQUEST,
  SUBJECTMASTER_CREATE_SUCCESS,
  SUBJECTMASTER_DELETE_ERR,
  SUBJECTMASTER_DELETE_REQUEST,
  SUBJECTMASTER_DELETE_SUCCESS,
  SUBJECTMASTER_FINDONE_ERR,
  SUBJECTMASTER_FINDONE_REQUEST,
  SUBJECTMASTER_FINDONE_SUCCESS,
  SUBJECTMASTER_FIND_ERR,
  SUBJECTMASTER_FIND_REQUEST,
  SUBJECTMASTER_FIND_SUCCESS,
  SUBJECTMASTER_UPDATE_ERR,
  SUBJECTMASTER_UPDATE_REQUEST,
  SUBJECTMASTER_UPDATE_SUCCESS,
} from "../constants/courseMajorMasterConstants";

//  courseMaster create action

export const courseMasterCreateAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COURSEMASTER_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/courseMajor/createCourseMajor",
      formData,
      config
    );

    dispatch({ type: COURSEMASTER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSEMASTER_CREATE_ERR, payload: error });
  }
};

//  courseMaster find action

export const courseMasterFindAction = (pageCount) => async (
  dispatch,
  getState
) => {
  try {
    // alert(pageCount);
    dispatch({ type: COURSEMASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/courseMajor/findCourseMajor",
      config
    );

    dispatch({ type: COURSEMASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSEMASTER_FIND_ERR, payload: error });
  }
};

//  courseMaster findById action

export const courseMasterFindByIdAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COURSEMASTER_FINDONE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/courseMajor/findByidCourseMajor?id=${id}`,
      config
    );

    dispatch({ type: COURSEMASTER_FINDONE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSEMASTER_FINDONE_ERR, payload: error });
  }
};

//  courseMaster update action

export const courseMasterUpdateAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COURSEMASTER_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.put(
      "/api/admin/courseMajor/updateCourseMajor",
      formData,
      config
    );

    dispatch({ type: COURSEMASTER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSEMASTER_UPDATE_ERR, payload: error });
  }
};

//  courseMaster Delete action

export const courseMasterDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COURSEMASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/courseMajor/deleteCourseMajor?id=${id}`,
      config
    );

    dispatch({ type: COURSEMASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSEMASTER_DELETE_ERR, payload: error });
  }
};

//  subjectMaster create action

export const subjectMasterCreateAction = (name) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SUBJECTMASTER_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "application/json",
      },
    };

    // Log to ensure the correct value is being sent
    console.log("Request body:", { name });

    let { data } = await axios.post(
      "/api/admin/courseMajor/createsubjectMajor",
      { name }, // Sending as an object with a single property
      config
    );

    dispatch({ type: SUBJECTMASTER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBJECTMASTER_CREATE_ERR, payload: error });
  }
};

//  SUBJECTMAJOR find action

export const subjectMasterFindAction = () => async (dispatch, getState) => {
  try {
    // alert(pageCount);
    dispatch({ type: SUBJECTMASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/courseMajor/findsubjectMajor",
      config
    );

    dispatch({ type: SUBJECTMASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBJECTMASTER_FIND_ERR, payload: error });
  }
};

//  SUBJECTMASTER findById action

export const subjectMasterFindByIdAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SUBJECTMASTER_FINDONE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/courseMajor/findOnesubjectMajor?id=${id}`,
      config
    );

    dispatch({ type: SUBJECTMASTER_FINDONE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBJECTMASTER_FINDONE_ERR, payload: error });
  }
};

//  SUBJECTMASTER update action

export const subjectMasterUpdateAction = (name, id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SUBJECTMASTER_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "application/json",
      },
    };

    let { data } = await axios.put(
      "/api/admin/courseMajor/updatesubjectMajor",
      { name, id },
      config
    );

    dispatch({ type: SUBJECTMASTER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBJECTMASTER_UPDATE_ERR, payload: error });
  }
};

//  SUBJECTMAJOR Delete action

export const subjectMasterDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUBJECTMASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/courseMajor/deletesubjectMajor?id=${id}`,
      config
    );

    dispatch({ type: SUBJECTMASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBJECTMASTER_DELETE_ERR, payload: error });
  }
};

//  SUBJECTMAJOR BulkUpload action

export const subjectBulkUploadAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SUBJECTMASTER_BULKUPLOAD_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/courseMajor/bulkUploadCourseSubject",
      formData,
      config
    );

    dispatch({ type: SUBJECTMASTER_BULKUPLOAD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBJECTMASTER_BULKUPLOAD_ERR, payload: error });
  }
};

//  courseLevel create action

export const courseLevelMasterCreateAction = (name) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COURSELEVELMASTER_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "application/json",
      },
    };

    let { data } = await axios.post(
      "/api/admin/courseMajor/courseLevelCreate",
      { name },
      config
    );

    dispatch({ type: COURSELEVELMASTER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSELEVELMASTER_CREATE_ERR, payload: error });
  }
};

//  course level find action

export const courseLevelMasterFindAction = () => async (dispatch, getState) => {
  try {
    // alert(pageCount);
    dispatch({ type: COURSELEVELMASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/courseMajor/courseLevelFind",
      config
    );

    dispatch({ type: COURSELEVELMASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSELEVELMASTER_FIND_ERR, payload: error });
  }
};

//  course level update action

export const courseLevelMasterUpdateAction = (name, id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COURSELEVELMASTER_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "application/json",
      },
    };

    let { data } = await axios.put(
      "/api/admin/courseMajor/courseLevelUpdate",
      { name, id },
      config
    );

    dispatch({ type: COURSELEVELMASTER_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSELEVELMASTER_UPDATE_ERR, payload: error });
  }
};

//  course level Delete action

export const courseLevelMasterDeleteAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: COURSELEVELMASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/courseMajor/courseLevelDelete?id=${id}`,
      config
    );

    dispatch({ type: COURSELEVELMASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COURSELEVELMASTER_DELETE_ERR, payload: error });
  }
};
