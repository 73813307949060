import React, { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { countries } from "countries-list";

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { RiSearch2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { entrolledStudentsFindAction } from "../../../actions/IeltsActions/entrolledStudentsAction";
// import { findcountryMasterAddAction } from "../../actions/countryMasterAction";

function IeltsTeacherFilterSearch(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [secListopen, setSecListOpen] = useState(false);
  const [thirdListopen, setThirdListOpen] = useState(false);
  const [fourthListopen, setFourthListOpen] = useState(false);
  const [fifthListopen, setFifthListOpen] = useState(false);
  const [sixthListopen, setSixthListOpen] = useState(false);
  const [sevenListopen, seventhListOpen] = useState(false);
  //   const [cityFind, setCityFind] = useState("");
  const [centerList, setCenterList] = useState([""]);
  const [specializationList, setSpecializationList] = useState([
    "Reading",
    "Writing",
    "Listening",
    "Speaking",
    "Grammer",
  ]);
  const [statusList, setStatusList] = useState(["Active", "Inactive"]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };
  const handleClick3 = () => {
    setThirdListOpen(!thirdListopen);
  };
  const handleClick4 = () => {
    setFourthListOpen(!fourthListopen);
  };
  const handleClick5 = () => {
    setFifthListOpen(!fifthListopen);
  };
  const handleClick6 = () => {
    setSixthListOpen(!sixthListopen);
  };
  const handleClick7 = () => {
    seventhListOpen(!sevenListopen);
  };

  const handleClearClick = () => {
    props.handleResetData(true);
    setSelectedCountry("");
    setSecListOpen(false);
    setThirdListOpen(false);
    setFourthListOpen(false);
  };

  //   const handleChange = (e) => {
  //     const searchText = e.target.value.toLowerCase();

  //     if (searchText === "") {
  //       setCountryList(countryFindSuccess); // Reset to the original data when search text is empty
  //     } else {
  //       setCountryList((chips) =>
  //         chips.filter((data) =>
  //           data.countryName.toLowerCase().includes(searchText)
  //         )
  //       );
  //     }
  //   };

  const handleCheck = (event, specialization, CheckedValue) => {
    if (CheckedValue !== "status") {
      setSelectedCountry(
        specialization === selectedCountry ? null : specialization
      );
      props.LeadFilterFunction(
        specialization,
        "Sepcilization",
        event.target.checked
      );
    } else {
      setSelectedCountry(
        specialization === selectedCountry ? null : specialization
      );
      props.LeadFilterFunction(specialization, "status", event.target.checked);
    }
  };

  // country

  return (
    <>
      <List
        sx={{
          // width:"fit-content",
          maxWidth: "316px",
          // height: "620px",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          // overflow: "scroll",
          // overflowX: "hidden",
          border: "1px solid #ECECEC",
          mt: 2.5,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ backgroundColor: "#F2F2F2" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filter</div>
              <div
                style={{
                  display: "flex",
                  marginTop: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClearClick}
              >
                <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                <Typography>Clear All</Typography>
              </div>
            </div>
          </ListSubheader>
        }
      >
        {props.statusComponent === "EntrolledTeachers" && (
          <>
            {/* first List */}
            {/* <ListItemButton onClick={handleClick}>
              <ListItemText
                primary="Country"
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  fontFamily: "Open Sans",
                }}
              />
              {open ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            {/* collapse */}
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <form>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        id="city"
                        placeholder="Search Here"
                        // value={cityFind}
                        // onChange={(e) => setCityFind(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            borderRadius: "20px",
                            height: "1px",
                          },
                          "& .MuiFormLabel-root": {
                            lineHeight: "5px",
                          },

                          "& .MuiInputBase-root": {
                            borderRadius: "3px",
                            borderColor: "#fafafa",
                          },
                        }}
                      />
                      <>
                        <Box
                          component="button"
                          sx={{
                            width: "40px",
                            height: "34px",
                            textAlign: "center",
                            border: "1px solid #9d9fa1",
                            borderBottomRightRadius: "5px",
                            WebkitBorderTopRightRadius: "5px",
                            fontSize: "24px",
                          }}
                          type="submit"
                        >
                          <InputAdornment position="end">
                            <RiSearch2Line />
                          </InputAdornment>
                        </Box>
                      </>
                    </div>
                  </form>
                </ListItemButton>
              </List>
            </Collapse>  */}
            {/* second List */}
            <ListItemButton onClick={handleClick2}>
              <ListItemText primary="Center" />
              {secListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={secListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <TextField
                    placeholder="Search Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <AiOutlineSearch />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      margin: 1,
                      width: "auto",
                      "& .MuiInputBase-input": {
                        borderRadius: "20px",
                        height: "1px",
                      },
                      "& .MuiFormLabel-root": {
                        lineHeight: "5px",
                      },

                      "& .MuiInputBase-root": {
                        borderRadius: "3px",
                        borderColor: "#fafafa",
                      },
                    }}
                  />
                </ListItemButton>

                <ListItemButton>
                  <FormGroup>
                    {centerList &&
                      centerList?.map((country) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              // checked={country === selectedCountry}
                              onChange={(e) => {
                                // handleCheckCountry(e, country, "Country");
                              }}
                            />
                          }
                          label={country ? country : "No Countries to list"}
                        />
                      ))}
                    <Typography
                      sx={{ fontWeight: 400, fontSize: "12px", color: "red" }}
                    >
                      viewAll
                    </Typography>
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick3}>
              <ListItemText primary="Specialization" />
              {thirdListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={thirdListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {specializationList &&
                      specializationList?.map((specialization) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={specialization === selectedCountry}
                              onChange={(e) => {
                                handleCheck(
                                  e,
                                  specialization,
                                  "specialization"
                                );
                              }}
                            />
                          }
                          label={
                            specialization
                              ? specialization
                              : "No States to list"
                          }
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={handleClick4}>
              <ListItemText primary="Status" />
              {fourthListopen ? (
                <ExpandLess sx={{ fontSize: "17px", fontWeight: "thin" }} />
              ) : (
                <ExpandMore sx={{ fontSize: "17px", fontWeight: "thin" }} />
              )}
            </ListItemButton>
            <Collapse in={fourthListopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <FormGroup>
                    {statusList &&
                      statusList?.map((status) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "#F8469A",
                                "&.Mui-checked": {
                                  color: "#F8469A",
                                },
                              }}
                              checked={status === selectedCountry}
                              onChange={(e) => {
                                handleCheck(e, status, "status");
                              }}
                            />
                          }
                          label={status ? status : "No Cities to Show"}
                        />
                      ))}
                  </FormGroup>
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
      </List>
    </>
  );
}

export default IeltsTeacherFilterSearch;
