import React, { useEffect, useState } from "react";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { MainContainer } from "../../../content/content.element";
import InputField from "../../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../../customComponent/Buttons";
import { ieltsSettingsZoomConfigCreateAction } from "../../../../actions/IeltsActions/SettingsAction";
import { useDispatch, useSelector } from "react-redux";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";

function IeltsZoomConfigurationForm({ toggle }) {
  
  const [formData, setFormData] = useState({
    topicName:"",
    // hostKey: "",
    email: "",
    password: "",
    // meetingId: "",
    // meetingLink: "",
    hostVideo: false,
    participantVideo: false,
    waitingRoom: false,
  });

  const [err, setErr] = useState({
    topicName: "",
    // hostKey: "",
    email: "",
    password: "",
    // meetingId: "",
    // meetingLink: "",
    Button: "",
  });

  const dispatch = useDispatch();

  // Message pop up

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsSettingsZoomConfigCreateSuccess,
    ieltsSettingsZoomConfigCreateerror,
  } = useSelector((state) => {
    return state.ieltsSettingsZoomConfigCreate;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsSettingsZoomConfigCreateSuccess?.status == true
    ) {
      setFormData({
        topicName:"",
        hostKey: "",
        email: "",
        password: "",
        meetingId: "",
        meetingLink: "",
        hostVideo: false,
        participantVideo: false,
        waitingRoom: false,
      });
      Swal.fire(
        "Created",
        ieltsSettingsZoomConfigCreateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsSettingsZoomConfigCreateSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsSettingsZoomConfigCreateSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsSettingsZoomConfigCreateSuccess, successAlertMsgSuccess]);

  const validateForm = () => {
    let isValid = true;
    const newErr = { ...err };

    if (formData.topicName.trim() === "") {
      newErr.topicName = "topic cannot be empty";
      isValid = false;
    } else {
      newErr.topicName = "";
    }

    // if (formData.hostKey.trim() === "") {
    //   newErr.hostKey = "Host Key cannot be empty";
    //   isValid = false;
    // } else {
    //   newErr.hostKey = "";
    // }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email.trim() === "") {
      newErr.email = "Email cannot be empty";
      isValid = false;
    } else if (!emailRegex.test(formData.email.trim())) {
      newErr.email = "Invalid email format";
      isValid = false;
    } else {
      newErr.email = "";
    }

    if (formData.password.trim() === "") {
      newErr.password = "Password cannot be empty";
      isValid = false;
    } else {
      newErr.password = "";
    }

    // if (formData.meetingId.trim() === "") {
    //   newErr.meetingId = "Meeting ID cannot be empty";
    //   isValid = false;
    // } else {
    //   newErr.meetingId = "";
    // }

    // if (formData.meetingLink.trim() === "") {
    //   newErr.meetingLink = "Meeting Link cannot be empty";
    //   isValid = false;
    // } else {
    //   newErr.meetingLink = "";
    // }

    if (
      formData.hostVideo ||
      formData.participantVideo ||
      formData.waitingRoom
    ) {
      newErr.Button = "";
    } else {
      newErr.Button = "please choose a type";
      isValid = false;
    }

    setErr(newErr);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (
      name === "hostVideo" ||
      name === "participantVideo" ||
      name === "waitingRoom"
    ) {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
      setErr((prev) => ({
        ...prev,
        ["Button"]: "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErr((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleAddNewButton = () => {
    if (validateForm()) {
      console.log(formData, "ddddddddddddddd");
      dispatch(ieltsSettingsZoomConfigCreateAction(formData));
      dispatch(successTrueMsgAction(true));
    } else {
      alert("Form is invalid, please check errors");
    }
  };

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Add Zoom Configuration" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <div style={{ maxWidth: "1194px" }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "25px",
              "& .input-group": {
                mb: "0px",
                mt: "0",
              },
            }}
          >
            <Box>
              <InputField
                label="Topic Name"
                handleChange={handleChange}
                value={formData?.topicName}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"topicName"}
                dispcriptionHeight
              />
              {err.topicName ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.topicName}
                </Typography>
              ) : (
                ""
              )}
            </Box>

            {/* <Box>
              <InputField
                label="Host Key"
                handleChange={handleChange}
                value={formData?.hostKey}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"hostKey"}
                dispcriptionHeight
              />
              {err.hostKey ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.hostKey}
                </Typography>
              ) : (
                ""
              )}
            </Box> */}

            <Box>
              <InputField
                label="Email"
                handleChange={handleChange}
                value={formData?.email}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"email"}
                dispcriptionHeight
              />
              {err.email ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.email}
                </Typography>
              ) : (
                ""
              )}
            </Box>

            <Box>
              <InputField
                label="Password"
                handleChange={handleChange}
                value={formData?.password}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"password"}
                dispcriptionHeight
              />
              {err.password ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.password}
                </Typography>
              ) : (
                ""
              )}
            </Box>

            {/* <Box>
              <InputField
                label="Meeting ID"
                handleChange={handleChange}
                value={formData?.meetingId}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"meetingId"}
                dispcriptionHeight
              />
              {err.meetingId ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.meetingId}
                </Typography>
              ) : (
                ""
              )}
            </Box>

            <Box>
              <InputField
                label="Meeting Link"
                handleChange={handleChange}
                value={formData?.meetingLink}
                widthSize="100%"
                required
                paramses
                InputType="text"
                name={"meetingLink"}
                dispcriptionHeight
              />
              {err.meetingLink ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  {err.meetingLink}
                </Typography>
              ) : (
                ""
              )}
            </Box> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              // gridTemplateColumns:"1fr 1fr 1fr",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "32px",
              maxWidth: "800px",
              "& .MuiFormControlLabel-root": {
                width: "100% !important",
                justifyContent: "start !important",
                alignItems: "center !important",
              },
            }}
          >
            <SwitchButton
              label={"Host Video"}
              checked={formData?.hostVideo}
              handleChange={handleChange}
              name={"hostVideo"}
            />
            <SwitchButton
              label={"Participant Video"}
              checked={formData?.participantVideo}
              handleChange={handleChange}
              name={"participantVideo"}
            />
            <SwitchButton
              label={"Waiting Room"}
              checked={formData?.waitingRoom}
              handleChange={handleChange}
              name={"waitingRoom"}
            />
          </Box>

          {err.Button ? (
            <Typography
              sx={{
                color: "red",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.Button}
            </Typography>
          ) : (
            ""
          )}
        </div>

        <Box
          sx={{
            mt: "44px",
            mb: "30px",
          }}
        >
          <SubmitButton
            title="Save"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={handleAddNewButton}
          />
        </Box>
      </div>
    </MainContainer>
  );
}

export default IeltsZoomConfigurationForm;
