import axios from "axios";
import {
  ENQUIRY_ENQUIRYSEARCH_ERR,
  ENQUIRY_ENQUIRYSEARCH_REQUEST,
  ENQUIRY_ENQUIRYSEARCH_SUCCESS,
  ENQUIRY_STUDENT_APPLICATION_FIND_ERR,
  ENQUIRY_STUDENT_APPLICATION_FIND_REQUEST,
  ENQUIRY_STUDENT_APPLICATION_FIND_SUCCESS,
  ENQUIRY_STUDENT_DETAILS_ERR,
  ENQUIRY_STUDENT_DETAILS_REQUEST,
  ENQUIRY_STUDENT_DETAILS_SUCCESS,
} from "../constants/enquiryConstant";

export const enquiryStudentDetailsFindAction = (paginationPageNumber) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ENQUIRY_STUDENT_DETAILS_REQUEST });

    let { data } = await axios.get(
      `/api/admin/enquiry/get-student-details?pageNumber=${paginationPageNumber}`,
      config
    );

    dispatch({ type: ENQUIRY_STUDENT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ENQUIRY_STUDENT_DETAILS_ERR,
      payload: error.response.data,
    });
  }
};

export const enquirySearchFindAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ENQUIRY_ENQUIRYSEARCH_REQUEST });

    let { data } = await axios.get("/api/admin/enquiry/searchEnqiry",config);

    dispatch({ type: ENQUIRY_ENQUIRYSEARCH_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ENQUIRY_ENQUIRYSEARCH_ERR,
      payload: error.response.data,
    });
  }
};

export const EnquiryStudentApplicationFindByIdAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ENQUIRY_STUDENT_APPLICATION_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/enquiry/find-student-applications?id=${id}`,
      config
    );
    dispatch({ type: ENQUIRY_STUDENT_APPLICATION_FIND_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: ENQUIRY_STUDENT_APPLICATION_FIND_ERR,
      payload: error.response.data,
    });
  }
};
