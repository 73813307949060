import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import PartnerSearch from "./PartnerSearch";
import ApplicationsTable from "./ApplicationsTable";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import PartnerSearch from "../../Partner/PartnerSearch";
import { findAllLeadApplicationAction } from "../../../actions/aplicationActions/applicationsAction";
import {
  Alert,
  AlertTitle,
  Grid,
  Pagination,
  TablePagination
} from "@mui/material";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import LeadsFilterSearch from "../../Leads/LeadsFilterSearch";

// material Table
const columns = [
  {
    title: "Sl no",
    field: "sl"
  },
  {
    title: "Student name",
    field: "studentName",
    hidden: false
  },
  {
    title: "Passport Number",
    field: "passportNumber",
    hidden: false
  },
  {
    title: "Email",
    field: "Email",
    hidden: false
  },
  {
    title: "Phone Number",
    field: "Phone Number",
    hidden: false
  },
  {
    title: "Country",
    field: "country",
    hidden: false
  },
  {
    title: "Course",
    field: "course",
    hidden: false
  },
  {
    title: "University",
    field: "University",
    hidden: false
  },

  {
    title: "Branch Manager",
    field: "universityHandler",
    hidden: false
  },
  {
    title: "Lead owner",
    field: "leadOwner",
    hidden: false
  },
  {
    title: "Counsilor",
    field: "counsilor",
    hidden: false
  },
  {
    title: "Telecaller",
    field: "Telecaller",
    hidden: false
  },
  {
    title: "Intake",
    field: "intake",
    hidden: false
  },
  {
    title: "Application Started Date",
    field: "Application Started Date",
    hidden: false
  },
  {
    title: "status",
    field: "status",
    hidden: false
  },
  {
    title: "Deferal intake month",
    field: "deferralMonth",
    hidden: false
  },
  {
    title: "Deferal intake year",
    field: "deferalYear",
    hidden: false
  },

  {
    title: "Payment status",
    field: "Paymentstatus",
    hidden: false
  },
  {
    title: "Action",
    field: "action",
    filtering: false
  }
];

function AllLeadApplications() {
  const dispatch = useDispatch();
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const [data, setData] = useState([]);
  // const [applicationData, setApplicationData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [filterPassData, setFilterPassData] = useState();
  const [columnName, setColumnName] = useState("");
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [leadId, setLeadId] = useState([]);
  const [ids, setIds] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pagiantionEntry, setPagiantionEntry] = useState(25);

  // / Employee column Visibility
  const [showNameColumn, setShowNameColumn] = useState(false);
  const [showEmailColumn, setShowEmailColumn] = useState(false);
  const [showPhoneColumn, setShowPhoneColumn] = useState(false);
  const [showWhatsAppNumColumn, setShowWhatsAppNumColumn] = useState(false);
  const [showPrefferedColumn, setShowPrefferedColumn] = useState(false);
  const [showLeadSourceColumn, setShowLeadSourceColumn] = useState(false);
  const [showLeadTypeColumn, setShowLeadTypeColumn] = useState(false);
  const [showStatusColumn, setShowStatusColumn] = useState(false);
  const [showEducationColumn, setShowEducationColumn] = useState(false);
  const [showUniversityPlaceColumn, setShowUniversityPlaceColumn] =
    useState(false);

  let { applicationAllFindSuccess } = useSelector((state) => {
    return state.findAllLeadApplication;
  });

  useEffect(() => {
    if (applicationAllFindSuccess) {
      setData(applicationAllFindSuccess?.data);
      setApplicationData(
        applicationAllFindSuccess?.data?.map((item) => ({
          id: item.id,
          column1: item.lead?.firstName,
          passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
          email: item.lead?.email,
          mobile: item.lead?.mobileNumber,
          column2: item?.universityCountry,
          column3: item?.courseName,
          column4: item?.universityName,
          column5: item?.user !== null ? item?.user?.firstName : "Not Assigned",
          column6: item?.lead?.leadOwnerName,
          column7: item?.lead?.Counselor?.firstName,
          column8: item?.lead?.Telecaller?.firstName !== undefined ? item?.lead?.Telecaller?.firstName : " " ,
          column9: item?.intakeDate === null ? " " : item?.intakeDate,
          column10: item?.methodStatus === null ? " " : item?.methodStatus,
          column11: item?.lead?.deferalIntakeMonth === null ? " " : item?.lead?.deferalIntakeMonth,
          column12: item?.lead?.deferalIntakeYear === null ? " " : item?.lead?.deferalIntakeYear,
          column13: item?.paymentStatus === null ? " " : item?.paymentStatus,
          column14: item?.createdAt === null || undefined ? " " : item?.createdAt?.split("T")[0],
          leadId: item.leadId
        }))
      );
    }
  }, [applicationAllFindSuccess, page]);

  useEffect(() => {
    dispatch(findAllLeadApplicationAction(page, "allApplication"));
  }, [dispatch, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    alert(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset page number when changing rows per page
  };

  const toggleNameColumn = (status, column) => {
    if (column === "name") {
      setColumnName(column);
      setShowNameColumn(status);
    }

    if (column === "email") {
      setColumnName(column);
      setShowEmailColumn(status);
    }

    if (column === "mobile") {
      setColumnName(column);
      setShowPhoneColumn(status);
    }

    if (column === "whatsAppNumber") {
      setColumnName(column);
      setShowWhatsAppNumColumn(status);
    }

    if (column === "prefferedCountry") {
      setColumnName(column);
      setShowPrefferedColumn(status);
    }
    if (column === "leadSource") {
      setColumnName(column);
      setShowLeadSourceColumn(status);
    }

    if (column === "leadType") {
      setColumnName(column);
      setShowLeadTypeColumn(status);
    }

    if (column === "status") {
      setColumnName(column);
      setShowStatusColumn(status);
    }

    if (column === "education") {
      setColumnName(column);
      setShowEducationColumn(status);
    }

    if (column === "universityPlace") {
      setColumnName(column);
      setShowUniversityPlaceColumn(status);
    }
  };

  // excel download
  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Partner_Managment");
  };

  // searching
  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setApplicationData(
        applicationData?.filter((item) => {
          const name = item.passport ? item.passport.toLowerCase() : "";
          const passport = item.column1 ? item.column1.toLowerCase() : "";
          const email = item.email ? item.email.toLowerCase() : "";
          const phone = item.mobile ? item.mobile.toLowerCase() : "";
          const manager = item.column6 ? item.column6.toLowerCase() : "";

          return (
            name.includes(e.target.value.toLowerCase()) ||
            phone.includes(
              e.target.value.toLowerCase().startsWith("+91")
                ? e.target.value.slice(3)
                : e.target.value
            ) ||
            manager.includes(e.target.value.toLowerCase()) ||
            email.includes(e.target.value.toLowerCase()) ||
            passport.includes(e.target.value.toLowerCase())
            // leadOwner.includes(e.target.value.toLowerCase()) ||
            // status.includes(e.target.value.toLowerCase()) ||
            // education.includes(e.target.value.toLowerCase()) ||
            // leadCity.includes(e.target.value.toLowerCase()) ||
            // leadBranch.includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setApplicationData(
        data.map((item) => ({
          id: item.id,
          column1: item.lead?.firstName,
          passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
          email: item.lead?.email,
          mobile: item.lead?.mobileNumber,
          column2: item?.universityCountry,
          column3: item?.courseName,
          column4: item?.universityName,
          column5: item?.user !== null ? item?.user?.firstName : "Not Assigned",
          column6: item?.lead?.leadOwnerName,
          column7: item?.lead?.Counselor?.firstName,
          column8: item?.lead?.Telecaller?.firstName,
          column9: item.intakeDate,
          column10: item.methodStatus,
          column11: item?.lead?.deferalIntakeMonth,
          column12: item?.lead?.deferalIntakeYear,
          column13: item?.paymentStatus,
          column14: item?.createdAt?.split("T")[0],
          leadId: item.leadId
        }))
      );
    }
  };

  // cleare filter
  const handleResetData = (status) => {
    setwhichFilter(null);
    setFilterPassData(null);
    setReset(!reset);
    setwhichFilter(null);
    setFilterPassData(null);
    setPaginationPageNumber(1);
    setApplicationData(
      applicationAllFindSuccess?.data?.map((item) => ({
        id: item.id,
        column1: item.lead?.firstName,
        passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
        email: item.lead?.email,
        mobile: item.lead?.mobileNumber,
        column2: item?.universityCountry,
        column3: item?.courseName,
        column4: item?.universityName,
        column5: item?.user !== null ? item?.user?.firstName : "Not Assigned",
        column6: item?.lead?.leadOwnerName,
        column7: item?.lead?.Counselor?.firstName,
        column8: item?.lead?.Telecaller?.firstName,
        column9: item.intakeDate,
        column10: item.methodStatus,
        column11: item?.lead?.deferalIntakeMonth,
        column12: item?.lead?.deferalIntakeYear,
        column13: item?.paymentStatus,
        column14: item?.createdAt?.split("T")[0],
        leadId: item.leadId
      }))
    );
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch(deleteLeadAction(leadId));
      }
    });
  };

  if (!hasPrivilege("all application") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    applicationAllFindSuccess,
    "applicationAllFindSuccessapplicationAllFindSuccessapplicationAllFindSuccess"
  );

  const handlePaginationEntry = (e) => {
    let value = e.target.value;
    if (value <= 100) {
      setPagiantionEntry(value);
    }
  };

  const LeadFilterFunction = (data, filterOption, checkedStatus) => {
    if (filterOption === "preffered country") {
      if (checkedStatus === true) {
        setApplicationData(
          applicationData?.filter((item) => {
            return (
              item.column2 !== null &&
              item.column2
                .toLowerCase()
                .split("(")[0]
                .includes(data.toLowerCase())
              // data.includes(item.leadSource)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setApplicationData(
          applicationAllFindSuccess?.data?.map((item) => ({
            id: item.id,
            column1: item.lead?.firstName,
            column2: item?.universityCountry,
            column3: item?.courseName,
            column4: item?.universityName,
            column5:
              item?.user !== null ? item?.user?.firstName : "Not Assigned",
            column6: item?.lead?.leadOwnerName,
            column7: item?.lead?.Counselor?.firstName,
            column8: item?.lead?.Telecaller?.firstName,
            column9: item.intakeDate,
            column10: item.methodStatus,
            column11: item?.lead?.deferalIntakeMonth,
            column12: item?.lead?.deferalIntakeYear,
            column13: item?.paymentStatus,
            leadId: item.leadId
          }))
        );
      }
    }

    if (filterOption === "leadStatus") {
      if (checkedStatus == true) {
        setApplicationData(
          applicationData?.filter((item) => {
            return (
              item.column6 !== null &&
              item.column6.toLowerCase().includes(data.toLowerCase())
              // data.includes(item.status)
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(checkedStatus);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setApplicationData(
          applicationAllFindSuccess?.data?.map((item) => ({
            id: item.id,
            column1: item.lead?.firstName,
            column2: item?.universityCountry,
            column3: item?.courseName,
            column4: item?.universityName,
            column5:
              item?.user !== null ? item?.user?.firstName : "Not Assigned",
            column6: item?.lead?.leadOwnerName,
            column7: item?.lead?.Counselor?.firstName,
            column8: item?.lead?.Telecaller?.firstName,
            column9: item.intakeDate,
            column10: item.methodStatus,
            column11: item?.lead?.deferalIntakeMonth,
            column12: item?.lead?.deferalIntakeYear,
            column13: item?.paymentStatus,
            leadId: item.leadId
          }))
        );
      }
    }

    if (filterOption === "leadUniversity") {
      if (checkedStatus === true) {
        setApplicationData(
          applicationData?.filter((item) => {
            return (
              item.column4 !== null &&
              item.column4.toLowerCase().includes(data.toLowerCase())
            );
          })
        );
        setwhichFilter(filterOption);
        setFilterPassData(data);
      } else {
        setwhichFilter(null);
        setFilterPassData(null);
        setApplicationData(
          applicationAllFindSuccess?.data?.map((item) => ({
            id: item.id,
            column1: item.lead?.firstName,
            passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
            email: item.lead?.email,
            mobile: item.lead?.mobileNumber,
            column2: item?.universityCountry,
            column3: item?.courseName,
            column4: item?.universityName,
            column5:
              item?.user !== null ? item?.user?.firstName : "Not Assigned",
            column6: item?.lead?.leadOwnerName,
            column7: item?.lead?.Counselor?.firstName,
            column8: item?.lead?.Telecaller?.firstName,
            column9: item.intakeDate,
            column10: item.methodStatus,
            column11: item?.lead?.deferalIntakeMonth,
            column12: item?.lead?.deferalIntakeYear,
            column13: item?.paymentStatus,
            column14: item?.createdAt?.split("T")[0],
            leadId: item.leadId
          }))
        );
      }
    }

    if (filterOption === "month") {
      let month;
      // let year;
      // let changedStatus;
      const date = new Date(data);
      month = date.toLocaleString("default", { month: "long" });
      // const convertyear = new Date(intakeYear);
      // year = convertyear.getFullYear();
      console.log(month, "monthmonth");
      setApplicationData(
        applicationAllFindSuccess?.data
          ?.filter((item) => {
            return (
              item?.lead?.deferalIntakeMonth !== null &&
              item?.lead?.deferalIntakeMonth
                .toLowerCase()
                .includes(month.toLowerCase())
            );
          })
          ?.map((item) => {
            return {
              id: item.id,
              column1: item.lead?.firstName,
              passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
              email: item.lead?.email,
              mobile: item.lead?.mobileNumber,
              column2: item?.universityCountry,
              column3: item?.courseName,
              column4: item?.universityName,
              column5:
                item?.user !== null ? item?.user?.firstName : "Not Assigned",
              column6: item?.lead?.leadOwnerName,
              column7: item?.lead?.Counselor?.firstName,
              column8: item?.lead?.Telecaller?.firstName,
              column9: item.intakeDate,
              column10: item.methodStatus,
              column11: item?.lead?.deferalIntakeMonth,
              column12: item?.lead?.deferalIntakeYear,
              column13: item?.paymentStatus,
              column14: item?.createdAt?.split("T")[0],
              leadId: item.leadId
            };
          })
      );
    }

    if (filterOption === "year") {
      // let month;
      let year;
      // let changedStatus;
      // const date = new Date(data);
      // month = date.toLocaleString("default", { month: "long" });
      const convertyear = new Date(data);
      year = convertyear.getFullYear();

      setApplicationData(
        applicationAllFindSuccess?.data
          ?.filter((item) => {
            return (
              item?.lead?.deferalIntakeYear !== null &&
              item?.lead?.deferalIntakeYear.includes(year)
            );
          })
          ?.map((item) => {
            return {
              id: item.id,
              column1: item.lead?.firstName,
              passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
              email: item.lead?.email,
              mobile: item.lead?.mobileNumber,
              column2: item?.universityCountry,
              column3: item?.courseName,
              column4: item?.universityName,
              column5:
                item?.user !== null ? item?.user?.firstName : "Not Assigned",
              column6: item?.lead?.leadOwnerName,
              column7: item?.lead?.Counselor?.firstName,
              column8: item?.lead?.Telecaller?.firstName,
              column9: item.intakeDate,
              column10: item.methodStatus,
              column11: item?.lead?.deferalIntakeMonth,
              column12: item?.lead?.deferalIntakeYear,
              column13: item?.paymentStatus,
              column14: item?.createdAt?.split("T")[0],
              leadId: item.leadId
            };
          })
      );
    }

    if (filterOption === "paymentStatus") {
      if (checkedStatus === true) {
        setApplicationData(
          applicationAllFindSuccess?.data
            ?.filter((item) => {
              return item?.paymentStatus
                ?.toLowerCase()
                .includes(data?.toLowerCase());
            })
            ?.map((item) => {
              return {
                id: item.id,
                column1: item.lead?.firstName,
                passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
                email: item.lead?.email,
                mobile: item.lead?.mobileNumber,
                column2: item?.universityCountry,
                column3: item?.courseName,
                column4: item?.universityName,
                column5:
                  item?.user !== null ? item?.user?.firstName : "Not Assigned",
                column6: item?.lead?.leadOwnerName,
                column7: item?.lead?.Counselor?.firstName,
                column8: item?.lead?.Telecaller?.firstName,
                column9: item.intakeDate,
                column10: item.methodStatus,
                column11: item?.lead?.deferalIntakeMonth,
                column12: item?.lead?.deferalIntakeYear,
                column13: item?.paymentStatus,
                column14: item?.createdAt?.split("T")[0],
                leadId: item.leadId
              };
            })
        );
      } else {
        setApplicationData(
          applicationAllFindSuccess?.data?.map((item) => ({
            id: item.id,
            column1: item.lead?.firstName,
            passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
            email: item.lead?.email,
            mobile: item.lead?.mobileNumber,
            column2: item?.universityCountry,
            column3: item?.courseName,
            column4: item?.universityName,
            column5:
              item?.user !== null ? item?.user?.firstName : "Not Assigned",
            column6: item?.lead?.leadOwnerName,
            column7: item?.lead?.Counselor?.firstName,
            column8: item?.lead?.Telecaller?.firstName,
            column9: item.intakeDate,
            column10: item.methodStatus,
            column11: item?.lead?.deferalIntakeMonth,
            column12: item?.lead?.deferalIntakeYear,
            column13: item?.paymentStatus,
            column14: item?.createdAt?.split("T")[0],
            leadId: item.leadId
          }))
        );
      }
    }

    if (filterOption === "refund") {
      if (checkedStatus === true) {
        console.log(data, "evalueevalueevalue");
        setApplicationData(
          applicationAllFindSuccess?.data
            ?.filter((item) => {
              return (
                item?.methodStatus !== null &&
                item?.methodStatus.toLowerCase().includes(data.toLowerCase())
              );
            })
            ?.map((item) => {
              return {
                id: item.id,
                column1: item.lead?.firstName,
                passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
                email: item.lead?.email,
                mobile: item.lead?.mobileNumber,
                column2: item?.universityCountry,
                column3: item?.courseName,
                column4: item?.universityName,
                column5:
                  item?.user !== null ? item?.user?.firstName : "Not Assigned",
                column6: item?.lead?.leadOwnerName,
                column7: item?.lead?.Counselor?.firstName,
                column8: item?.lead?.Telecaller?.firstName,
                column9: item.intakeDate,
                column10: item.methodStatus,
                column11: item?.lead?.deferalIntakeMonth,
                column12: item?.lead?.deferalIntakeYear,
                column13: item?.paymentStatus,
                column14: item?.createdAt?.split("T")[0],
                leadId: item.leadId
              };
            })
        );
      } else {
        setApplicationData(
          applicationAllFindSuccess?.data?.map((item) => ({
            id: item.id,
            column1: item.lead?.firstName,
            passport: item.lead?.lead_personal_details?.[0]?.passportNumber,
            email: item.lead?.email,
            mobile: item.lead?.mobileNumber,
            column2: item?.universityCountry,
            column3: item?.courseName,
            column4: item?.universityName,
            column5:
              item?.user !== null ? item?.user?.firstName : "Not Assigned",
            column6: item?.lead?.leadOwnerName,
            column7: item?.lead?.Counselor?.firstName,
            column8: item?.lead?.Telecaller?.firstName,
            column9: item.intakeDate,
            column10: item.methodStatus,
            column11: item?.lead?.deferalIntakeMonth,
            column12: item?.lead?.deferalIntakeYear,
            column13: item?.paymentStatus,
            column14: item?.createdAt?.split("T")[0],
            leadId: item.leadId
          }))
        );
      }
    }
  };

  return (
    <>
      <PartnerSearch
        columnVisibleHandler={toggleNameColumn}
        excelDownloadClick={excelDownloadClick}
        showNameColumn={showNameColumn}
        showEmailColumn={showEmailColumn}
        showPrefferedColumn={showPrefferedColumn}
        showPhoneColumn={showPhoneColumn}
        showWhatsAppNumColumn={showWhatsAppNumColumn}
        showLeadSourceColumn={showLeadSourceColumn}
        showLeadTypeColumn={showLeadTypeColumn}
        showStatusColumn={showStatusColumn}
        showEducationColumn={showEducationColumn}
        showUniversityPlaceColumn={showUniversityPlaceColumn}
        filterPassData={filterPassData ? filterPassData : null}
        whichFilter={whichFilter ? whichFilter : null}
        handleResetData={handleResetData}
        handleDeleteClick={handleDeleteClick}
        searchHandleChange={searchHandleChange}
        ids={ids}
        leadId={leadId}
        component="application"
        noAction={true}
      />
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <LeadsFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
            statusComponent="leadApplication"
            component="application"
            data={data}
            pagiantionEntry={pagiantionEntry}
            handlePaginationEntry={handlePaginationEntry}
          />
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <ApplicationsTable
            columns={columns}
            data={applicationData}
            component="application"
            subMenu="all application"
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
          />
        </Grid>
      </Grid>

      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]} // Customize the rows per page options as needed
        component="div"
        count={applicationData.length} // Total number of rows
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      <Pagination
        sx={{
          mt: "28px",
          "& .Mui-selected": {
            backgroundColor: "#f81b82 !important",
            color: "#fff"
          },
          "& .MuiPagination-ul": {
            justifyContent: "end"
          }
        }}
        rowsPerPageOptions={[10, 25, 50, 100]} // Customize the rows per page options as needed
        component="div"
        count={applicationAllFindSuccess?.totalPages} // Total number of rows
        rowsPerPage={applicationAllFindSuccess?.currentPage}
        page={page}
        onChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default AllLeadApplications;
