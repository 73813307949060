import axios from "axios";
import {
  CHAT_WITH_COUNSELOR_ERR,
  CHAT_WITH_COUNSELOR_NOTIFICATION_ERR,
  CHAT_WITH_COUNSELOR_NOTIFICATION_REQUEST,
  CHAT_WITH_COUNSELOR_NOTIFICATION_SUCCESS,
  CHAT_WITH_COUNSELOR_REQUEST,
  CHAT_WITH_COUNSELOR_STATUS_UPDATE_ERR,
  CHAT_WITH_COUNSELOR_STATUS_UPDATE_REQUEST,
  CHAT_WITH_COUNSELOR_STATUS_UPDATE_SUCCESS,
  CHAT_WITH_COUNSELOR_SUCCESS,
  COUNSELOR_NOTIFICATION_UPDATE_ERR,
  COUNSELOR_NOTIFICATION_UPDATE_REQUEST,
  COUNSELOR_NOTIFICATION_UPDATE_SUCCESS,
  GET_CHAT_WITH_COUNSELOR_ERR,
  GET_CHAT_WITH_COUNSELOR_REQUEST,
  GET_CHAT_WITH_COUNSELOR_SUCCESS,
} from "../constants/leadChatConstants";

// send chat
export const chatWithCounsilorAction =
  (formData) => async (dispatch, getState) => {
    console.log(formData, "formDataformDataformData");
    try {
      dispatch({ type: CHAT_WITH_COUNSELOR_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      let { data } = await axios.post(
        `/api/admin/leadchat/chat`,
        formData,
        config
      );
      dispatch({ type: CHAT_WITH_COUNSELOR_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CHAT_WITH_COUNSELOR_ERR,
        payload: error.response,
      });
    }
  };

// get chat

export const getchatWithCounsilorAction =
  (senderId, receiverId) => async (dispatch, getState) => {
    console.log(senderId, receiverId, "senderId, receiverId");
    try {
      dispatch({ type: GET_CHAT_WITH_COUNSELOR_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/leadchat/getchat?senderId=${senderId}&receiverId=${receiverId}`,
        config
      );
      dispatch({ type: GET_CHAT_WITH_COUNSELOR_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CHAT_WITH_COUNSELOR_ERR,
        payload: error,
      });
    }
  };

//  chat with counsilor notification Action

export const chatWithCounsilorNotificationAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: CHAT_WITH_COUNSELOR_NOTIFICATION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      let id = isUserExist.UserId;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/leadchat/notification?userId=${id}`,
        config
      );
      dispatch({
        type: CHAT_WITH_COUNSELOR_NOTIFICATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHAT_WITH_COUNSELOR_NOTIFICATION_ERR,
        payload: error,
      });
    }
  };

//  chat with counsilor notification Action

export const chatWithCounsilorStatusUpdateAction =
  (messageId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHAT_WITH_COUNSELOR_STATUS_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      let id = isUserExist.UserId;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/leadchat/messages/${messageId}/viewed`,
        { userId: id },
        config
      );
      dispatch({
        type: CHAT_WITH_COUNSELOR_STATUS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHAT_WITH_COUNSELOR_STATUS_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };

// counsilorNotificationClearAction
export const counsilorNotificationClearAction =
  (userId, counsellorId,messageIdToUpdate) => async (dispatch, getState) => {
    try {
      dispatch({ type: COUNSELOR_NOTIFICATION_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      let id = isUserExist.UserId;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/leadchat/counsilorNotificationClear?id=${id}`,
        { counsellorId, userId },
        config
      );
      dispatch({
        type: COUNSELOR_NOTIFICATION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COUNSELOR_NOTIFICATION_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };
