import axios from "axios";
import {
  ADD_UNIVERSITY_COMMISSION_ERR,
  ADD_UNIVERSITY_COMMISSION_REQUEST,
  ADD_UNIVERSITY_COMMISSION_SUCCESS,
  DELETE_UNIVERSITY_COMMISSION_ERR,
  DELETE_UNIVERSITY_COMMISSION_REQUEST,
  DELETE_UNIVERSITY_COMMISSION_SUCCESS,
  GET_ALL_FINAL_SUMMARY_ERR,
  GET_ALL_FINAL_SUMMARY_REQUEST,
  GET_ALL_FINAL_SUMMARY_SUCCESS,
  LIST_UNIVERSITY_COMMISSION_ERR,
  LIST_UNIVERSITY_COMMISSION_REQUEST,
  LIST_UNIVERSITY_COMMISSION_SUCCESS,
  PAID_PAYMENT_STATUS_UPDATE_ERR,
  PAID_PAYMENT_STATUS_UPDATE_REQUEST,
  PAID_PAYMENT_STATUS_UPDATE_SUCCESS,
  UNIVERSITY_COMMISSION_ERR_BY_ID,
  UNIVERSITY_COMMISSION_REQUEST_BY_ID,
  UNIVERSITY_COMMISSION_SUCCESS_BY_ID,
  UPDATE_UNIVERSITY_COMMISSION_ERR,
  UPDATE_UNIVERSITY_COMMISSION_REQUEST,
  UPDATE_UNIVERSITY_COMMISSION_SUCCESS,
} from "../../constants/reportsConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../constants/roleManagmentConstant";

// getAllFinalSummaryAction
export const getAllFinalSummaryAction =
  (email, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ALL_FINAL_SUMMARY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        "/api/admin/report/getAllFinalSummary",
        config
      );

      dispatch({ type: GET_ALL_FINAL_SUMMARY_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: GET_ALL_FINAL_SUMMARY_ERR,
        payload: error.response.data,
      });
    }
  };

// getFinalSummaryByFilterAction
export const getFinalSummaryByFilterAction =
  (year, month) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ALL_FINAL_SUMMARY_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let userId = isUserExist.id;
      let { data } = await axios.post(
        "/api/admin/report/getFinalSummaryByFilter",
        { year, month },
        config
      );
      console.log(data, "datadatadatadatadatadatadatadatadata");

      dispatch({ type: GET_ALL_FINAL_SUMMARY_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: GET_ALL_FINAL_SUMMARY_ERR,
        payload: error.response.data,
      });
    }
  };

// paidPaymentUpdationAction
export const paidPaymentUpdationAction =
  (status, section, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PAID_PAYMENT_STATUS_UPDATE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/report/paidPaymentUpdation?id=${id}`,
        { status, section },
        config
      );

      dispatch({ type: PAID_PAYMENT_STATUS_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: PAID_PAYMENT_STATUS_UPDATE_ERR,
        payload: error.response.data,
      });
    }
  };

// add university commission
export const addUniversityCommissionAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_UNIVERSITY_COMMISSION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        `/api/admin/report/addUniversityCommission`,
        { formData },
        config
      );

      dispatch({ type: ADD_UNIVERSITY_COMMISSION_SUCCESS, payload: data });
      dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: ADD_UNIVERSITY_COMMISSION_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// list university commission
export const listUniversityCommissionAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: LIST_UNIVERSITY_COMMISSION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/report/listUniversityCommission`,
        config
      );

      dispatch({ type: LIST_UNIVERSITY_COMMISSION_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: LIST_UNIVERSITY_COMMISSION_ERR,
        payload: error.response.data,
      });
    }
  };

// delete university commission
export const deleteUniversityCommissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_UNIVERSITY_COMMISSION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/report/deleteUniversityCommission?id=${id}`,
        config
      );

      dispatch({ type: DELETE_UNIVERSITY_COMMISSION_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: DELETE_UNIVERSITY_COMMISSION_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// university commission find by id
export const universityCommissionFindByIdAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UNIVERSITY_COMMISSION_REQUEST_BY_ID });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/report/universityCommissionFindById?id=${id}`,
        config
      );

      dispatch({ type: UNIVERSITY_COMMISSION_SUCCESS_BY_ID, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: UNIVERSITY_COMMISSION_ERR_BY_ID,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };

// university commission find by id
export const updateUniversityCommissionByIdAction =
  (formData,id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_UNIVERSITY_COMMISSION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/report/updateUniversityCommission?id=${id}`,
        {formData},
        config
      );

      dispatch({ type: UPDATE_UNIVERSITY_COMMISSION_SUCCESS, payload: data });
    } catch (error) {
      console.log(error.response, "error.response");
      dispatch({
        type: UPDATE_UNIVERSITY_COMMISSION_ERR,
        payload: error.response.data,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
    }
  };
