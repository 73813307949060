import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleTable from "./ScheduleTable";
import ScheduledFilterSearch from "./ScheduledFilterSearch";
import { Alert, AlertTitle, Box, Grid, Typography } from "@mui/material";
import DownloadExcel from "../../extraComponents/DownloadExcel";
import DownloadPdf from "../../extraComponents/DownloadPdf";

import { useDispatch, useSelector } from "react-redux";
import { sceduleMeetingFindAction } from "../../../actions/ticket/sceduleMeeting";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function SchedulesMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [whichFilter, setwhichFilter] = useState();
  const [reset, setReset] = useState(false);
  const [filterPassData, setFilterPassData] = useState();
  const { privilege, hasPrivilege } = usePrivilegeCheck();


  // Response

  let { sceduleMeetingFindSuccess, sceduleMeetingFinderror } = useSelector(
    (state) => {
      return state.sceduleMeetingFind;
    }
  );

  let { sceduleMeetingStatusChangeSuccess, sceduleMeetingStatusChangeerror } =
    useSelector((state) => {
      return state.sceduleMeetingStatusChange;
    });

  useEffect(() => {
    dispatch(sceduleMeetingFindAction());
  }, [dispatch, sceduleMeetingStatusChangeSuccess]);

  useEffect(() => {
    if (sceduleMeetingFindSuccess) {
      setData(sceduleMeetingFindSuccess?.data);
    }
  }, [sceduleMeetingFindSuccess]);

  console.log(sceduleMeetingFindSuccess, "sceduleMeetingFindSuccess");

  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "NAME",
      field: "NAME",
    },
    {
      title: "Counsellor",
      field: "Counsellor",
    },
    {
      title: "Scheduled Date",
      field: "Scheduled",
    },
    {
      title: "Time",
      field: "Time",
    },
    // {
    //   title: "Message",
    //   field: "Message",
    // },
    {
      title: "Status",
      field: "Status",
    },
  ];

  // Handle filter

  // filter
  const LeadFilterFunction = (date, filterOption) => {
    console.log(date, "date", filterOption, "option");
    if (filterOption == "date") {
      setData(
        sceduleMeetingFindSuccess?.data?.filter(
          (item) => item.sceduledDate == date
        )
      );
    }
    if (filterOption == "status") {
      setData(
        sceduleMeetingFindSuccess?.data?.filter((item) => item.status == date)
      );
    }
    setwhichFilter(filterOption);
    setFilterPassData(data);
  };

  // Handle resetData

  const handleResetData = (status) => {
    setData(sceduleMeetingFindSuccess?.data);
  };

  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "sceduledMeeting");
  };

  const pdfDownloadClick = () => {
    DownloadPdf(columns, data, "sceduledMeeting", "sceduledMeeting");
  };

  // Handle search

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          console.log(item);
          return (
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.counsilorName
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(sceduleMeetingFindSuccess?.data);
    }
  };

  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");
  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };

  if (!hasPrivilege("scedules") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(hasPrivilege("scedules"), "hasPrivilege", privilege, "hasPrivilegehasPrivilegehasPrivilege");
  
  return (
    <div>
      <ScheduleHeader
        pdfDownloadClick={pdfDownloadClick}
        excelDownloadClick={excelDownloadClick}
        searchHandleChange={searchHandleChange}
      />
      <Grid container spacing={-3} sx={{ mt: "-31px" }}>
        <Grid item xs={12} md={2} lg={2.2}>
          <ScheduledFilterSearch
            LeadFilterFunction={LeadFilterFunction}
            handleResetData={handleResetData}
          />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              mt: "20px",
              "& input": {
                width: "30px",
                outline: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              Rows Per Page :{" "}
            </Typography>
            <input type="number" value={page} onChange={handlePageSearch} />
          </Box>
          {pageError && (
            <Typography
              sx={{
                fontSize: 10,
                color: "red",
              }}
            >
              {pageError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={10} lg={9.8}>
          <ScheduleTable columns={columns} data={data} pageNumber={page} subMenu="scedules" />
        </Grid>
      </Grid>
    </div>
  );
}

export default SchedulesMain;
