import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import CancelIcon from "@mui/icons-material/Cancel";

import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";

import { useDispatch, useSelector } from "react-redux";

import InputField, { SelectInputField } from "../../customComponent/InputField";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { SubmitButton } from "../../customComponent/Buttons";
import { Editor, EditorState, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { blogCreateAction } from "../../../actions/LandingPageAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";

function CreateBlogs(props) {
  const dispatch = useDispatch();
  const [selectedStyle, setSelectedStyle] = useState("NORMAL");
  const [bgColor, setBgColor] = useState("transparent");
  const [textColor, setTextColor] = useState("black");
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);

  const [DestinationimageValue, setDestinationimageValue] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [addedCategory, setAddedCategory] = useState([]);
  const [tags, setTags] = useState("");
  const [blogSlug, setBlogSlug] = useState("");
  const [seoKeyword, setSeoKeyword] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [enteredTags, setEnteredTags] = useState([]);
  const [error, setError] = useState({});
  const newsimage = useRef();

  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );

  let { blogCreateSuccess } = useSelector((state) => {
    return state.blogCreate;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (blogCreateSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `Blog added successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [blogCreateSuccess, dispatch, successAlertMsgSuccess]);

  //   DESCRIPTION STYLE
  const handleBoldClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "BOLD"));
  };

  const handleItalicClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "ITALIC"));
  };

  const handleUnderlineClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "UNDERLINE"));
  };

  const handleStrikethroughClick = () => {
    setDescription(RichUtils.toggleInlineStyle(description, "STRIKETHROUGH"));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
    setDescription(
      RichUtils.toggleInlineStyle(
        description,
        `BG_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setDescription(
      RichUtils.toggleInlineStyle(
        description,
        `TEXT_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextAlignment = (alignment) => {
    setDescription(
      RichUtils.toggleBlockType(description, `ALIGN_${alignment.toUpperCase()}`)
    );
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  // text editor section
  const handleStyleChange = (e) => {
    const newStyle = e.target.value;

    // Toggle the new style immediately
    const newEditorState = RichUtils.toggleInlineStyle(description, newStyle);

    setSelectedStyle(newStyle);
    setDescription(newEditorState);
  };

  const handleDestinationImage = (event) => {
    const file = event.target.files[0];
    setDestinationimageValue(file.name);
    const reader = new FileReader();

    // Read the image file as a data URL
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const UploadDestinationimage = reader.result;
      setNewsImage(UploadDestinationimage);
    };
  };

  // handle Delete in Multiple input Field

  const handleDelete = (deletedTag) => {
    setEnteredTags(enteredTags.filter((tag) => tag !== deletedTag));
  };

  // Handle Add multiple tags

  const handleAddTags = () => {
    if (tags.trim() !== "") {
      setEnteredTags((prevEnteredTags) => [...prevEnteredTags, tags.trim()]);
      setTags(""); // Clear the input after adding a tag
    }
  };

  const handleAddCategory = () => {
    if (category.trim() !== "") {
      setAddedCategory((prevEnteredCategory) => [
        ...prevEnteredCategory,
        category.trim(),
      ]);
      setCategory(""); // Clear the input after adding a tag
    }
  };

  // HandleCreate

  const validate = () => {
    let errors = {};

    if (newsImage === "") {
      errors.newsImage = "Please select a image";
    }
    if (addedCategory === "") {
      errors.addedCategory = "Please choose a category";
    }
    if (title === "") {
      errors.title = "Please choose a title";
    }
    if (!description) {
      errors.description = "Please enter a description";
    }
    if (enteredTags.length === 0) {
      errors.enteredTags = "Please choose tags";
    }
    if (blogSlug === "") {
      errors.blogSlug = "Please enter slug name";
    }
    if (seoKeyword.length === 0) {
      errors.seoKeyword = "Please enter seo keyword";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };
  const HandleCreate = () => {
    const contentState = description.getCurrentContent();
    function getInlineStyle(entity) {
      const entityKey = entity.getEntity();
      const entityInstance = contentState.getEntity(entityKey);
      const entityType = entityInstance.getType();
      if (entityType === "STYLE") {
        const entityData = entityInstance.getData();
        return entityData.style;
      }
      return null;
    }
    const htmlContent = stateToHTML(contentState, {
      inlineStyles: {
        STYLE: {
          element: "span",
          attributes: (style, entity) => ({
            style: getInlineStyle(entity),
          }),
        },
      },
    });

    if (validate()) {
      dispatch(
        blogCreateAction(
          newsImage,
          addedCategory,
          title,
          htmlContent,
          enteredTags,
          blogSlug,
          seoKeyword
        )
      );

      setNewsImage("");
      setCategory("");
      setTitle("");
      setTags("");
      setBlogSlug("");
      setSeoKeyword("");

      setError({});
    }
  };

  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Blog" url={"/landingPage"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={6} style={{ position: "relative" }}>
          <Grid>
            <InputField
              label="Cover Image"
              widthSize="92%"
              value={DestinationimageValue}
              required
              paramses
            ></InputField>
          </Grid>
          <Grid style={{ position: "absolute", top: 5, right: 50 }}>
            <button
              onClick={() => newsimage.current.click()}
              style={{
                width: "100px",
                height: "40px",
                fontSize: "13px",
                fontFamily: "monospace",
                border: "none",
              }}
            >
              Browse
            </button>
            <input
              ref={newsimage}
              type="file"
              accept="image/*"
              onChange={(event) => handleDestinationImage(event)}
              style={{ display: "none" }}
            ></input>
          </Grid>
          {Boolean(error.newsImage) && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
              }}
            >
              {error.newsImage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={6} style={{ position: "relative" }}>
          <Box>
            <InputField
              label="Category"
              widthSize="98%"
              value={category}
              handleChange={(e) => setCategory(e.target.value)}
              required
              paramses
              paddingRight={"120px"}
              maxLength={"50"}
              error={Boolean(error.addedCategory)}
              helperText={error.addedCategory}
            />
            <Box sx={{ position: "absolute", top: 5, right: 5 }}>
              <button
                style={{
                  width: "100px",
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "monospace",
                  border: "none",
                }}
                onClick={handleAddCategory}
              >
                Add
              </button>
            </Box>
          </Box>

          <Stack direction="row" spacing={1}>
            {addedCategory
              ? addedCategory.map((categories, index) => (
                  <Chip
                    label={categories}
                    key={index}
                    onDelete={() =>
                      setAddedCategory(
                        addedCategory.filter((value) => value !== categories)
                      )
                    }
                    deleteIcon={<CancelIcon />}
                  />
                ))
              : ""}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ mt: 3 }}>
          <InputField
            label="Title"
            handleChange={(e) => setTitle(e.target.value)}
            widthSize="99.2%"
            value={title}
            InputType="discription"
            // dispcriptionHeight=""
            required
            paramses
            error={Boolean(error.title)}
            helperText={error.title}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <div>
            <ToggleButtonGroup
              value={formats}
              onChange={handleFormat}
              aria-label="text formatting"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <select value={selectedStyle} onChange={handleStyleChange}>
                <option value="NORMAL">Normal</option>
                <option value="BOLD">Bold</option>
                <option value="ITALIC">Italic</option>
                <option value="UNDERLINE">Underline</option>
              </select>
              <FormatBoldIcon
                onClick={handleBoldClick}
                value="bold"
                aria-label="bold"
              />

              <FormatItalicIcon
                onClick={handleItalicClick}
                value="italic"
                aria-label="italic"
              />

              <FormatUnderlinedIcon
                onClick={handleUnderlineClick}
                value="underlined"
                aria-label="underlined"
              />

              <StrikethroughSIcon
                onClick={handleStrikethroughClick}
                value="strikethrough"
                aria-label="strikethrough"
              />
              <FormatColorTextIcon
                onClick={() => handleBgColorChange("yellow")}
                value="backgroundcolor"
                aria-label="backgroundcolor"
              />

              <InvertColorsIcon
                onClick={() => handleTextColorChange("blue")}
                value="textcolor"
                aria-label="textcolor"
              />

              <FormatAlignLeftIcon
                onClick={() => handleTextAlignment("left")}
                value="textcolor"
                aria-label="textcolor"
              />
            </ToggleButtonGroup>
          </div>
          <div
            style={{
              border: "1px solid #ececec",
              padding: "10px",
              minHeight: "200px",
              marginTop: "10px",
            }}
          >
            <Editor
              editorState={description}
              onChange={setDescription}
              customStyleMap={{
                // Define custom inline styles for text and background colors
                TEXT_COLOR_RED: { color: "red" },
                TEXT_COLOR_BLUE: { color: "blue" },
                BG_COLOR_YELLOW: { backgroundColor: "yellow" },
                BG_COLOR_GREEN: { backgroundColor: "green" },
                ALIGN_LEFT: { textAlign: "left" },
              }}
            />
          </div>
          {Boolean(error.htmlContent) && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
              }}
            >
              {error.htmlContent}
            </Typography>
          )}
        </Grid>
        {/* List universities  */}
        <Grid item xs={12} lg={12} sx={{ mt: 4, position: "relative" }}>
          <>
            <InputField
              label="Tags"
              widthSize="99.2%"
              value={tags}
              handleChange={(e) => setTags(e.target.value)}
              required
              paramses
              multiple={true}
              maxLength={"50"}
              error={Boolean(error.enteredTags)}
              helperText={error.enteredTags}
            />

            <Box sx={{ position: "absolute", top: 5, right: 0 }}>
              <button
                style={{
                  width: "100px",
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "monospace",
                  border: "none",
                }}
                onClick={handleAddTags}
              >
                Add
              </button>
            </Box>
            <Stack direction="row" spacing={1}>
              {enteredTags
                ? enteredTags.map((tags, index) => (
                    <Chip
                      label={tags}
                      key={index}
                      onDelete={() =>
                        setEnteredTags(
                          enteredTags.filter((value) => value !== tags)
                        )
                      }
                      deleteIcon={<CancelIcon />}
                    />
                  ))
                : ""}
            </Stack>
          </>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ mt: 4 }}>
          <InputField
            label="Blog Slug"
            widthSize="97%"
            value={blogSlug}
            handleChange={(e) => setBlogSlug(e.target.value)}
            required
            paramses
            error={Boolean(error.blogSlug)}
            helperText={error.blogSlug}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ mt: 4 }}>
          <InputField
            label="SEO Keywords"
            widthSize="97.5%"
            value={seoKeyword}
            handleChange={(e) => setSeoKeyword(e.target.value)}
            required
            paramses
            error={Boolean(error.seoKeyword)}
            helperText={error.seoKeyword}
          />
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default CreateBlogs;
