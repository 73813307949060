import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Reorder from "./Reorder";
import CloseIcon from "@mui/icons-material/Close";
import InputField, {
  SelectinputFieldCustom,
} from "../../customComponent/InputField";
import { KeyboardArrowDown } from "@mui/icons-material";

function EstimatedEnrollmentTable() {
  function createData(
    name,
    estimatedEnrolments,
    estimatedCommission,
    averageCommission
  ) {
    return {
      name,
      estimatedEnrolments,
      estimatedCommission,
      averageCommission,
    };
  }
  const rows = [
    createData("2023 Enrolments & Commission", 143, 15663, 15663),
    createData("2023 Enrolments & Commission", 143, 15663, 15663),
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "850px",
            mt: "0",
          },
        }}
      >
        <Box
          sx={{
            p: "32px",
            display: "flex",
            flexDirection: "column",
            gap: "22px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "21px",
                fontWeight: "700",
              }}
            >
              Estimated enrolments & commission
            </Typography>
            <CloseIcon
              sx={{
                cursor: "pointer",
                fontSize: "33px",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box sx={{}}>
            <FormControl sx={{ minWidth: 120, width: "100%" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{
                  color: "rgba(5, 5, 15, 1)",
                  background: "#fff",
                  pr: "8px",
                }}
              >
                Choose Year
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Age"
                // onChange={handleChange}
                sx={{
                  width: "100%",
                  paddingRight: "20px",
                }}
                IconComponent={() => <KeyboardArrowDown />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {/* <FormHelperText></FormHelperText> */}
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography sx={{ fontSize: "18px" }}>
              Enrolments & Commission
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "30px",
                "& input": {
                  boxSizing: "border-box",
                  height: "56px",
                },
                "& input::placeholder": {
                  color: "rgba(5, 5, 15, 1)",
                },
                "& .input-group": {
                  mb: "0",
                  mt: "0",
                },
                "& .input-group input": {
                  paddingLeft: "15px !important",
                },
              }}
            >
              <InputField placeholder="Estimated Enrolments" />
              <InputField placeholder="Estimated Commissions" />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography sx={{ fontSize: "18px" }}>
              Enrolments & Commission
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "30px",
                "& input": {
                  boxSizing: "border-box",
                  height: "56px",
                },
                "& input::placeholder": {
                  color: "rgba(5, 5, 15, 1)",
                },
                "& .input-group": {
                  mb: "0",
                  mt: "0",
                },
                "& .input-group input": {
                  paddingLeft: "15px !important",
                },
              }}
            >
              <InputField placeholder="Estimated Enrolments" />
              <InputField placeholder="Estimated Commissions" />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              sx={{
                backgroundColor: "rgba(20, 30, 60, 1)",
                fontWeight: "600",
                color: "#fff",
                textTransform: "capitalize",
                border: "1px solid rgba(20, 30, 60, 1)",
                "&:hover": {
                  color: "rgba(20, 30, 60, 1)",
                },
              }}
              onClick={handleClickOpen}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                backgroundColor: "",
                padding: "20px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "700",
                // fontFamily: "Open-Sans",
                pb: "16px",
              }}
            >
              Estimated enrolments & commission report
            </Typography>
            <Typography
              sx={{
                padding: "20px",
                pt: 0,
                borderRadius: "10px 10px 0 0",
                width: "100%",
                fontSize: "12px",
                lineHeight: "24px",
                fontWeight: "400",
                // fontFamily: "Open-Sans",
                color: "rgba(5, 5, 15, 0.75)",
              }}
            >
              2023 Income Projection
            </Typography>
          </Box>
          <Button
            sx={{
              backgroundColor: "rgba(20, 30, 60, 1)",
              fontWeight: "600",
              color: "#fff",
              textTransform: "capitalize",
              border: "1px solid rgba(20, 30, 60, 1)",
              "&:hover": {
                color: "rgba(20, 30, 60, 1)",
              },
            }}
            onClick={handleClickOpen}
          >
            Edit
          </Button>
        </Box>
        <Box
          sx={{
            overflowX: "scroll",
            maxWidth: "100%",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "& table": {
              minWidth: "1200px",
            },
          }}
        >
          <Table>
            <TableHead
              sx={{
                background: "#F9F9FB",
                width: "100%",
                "& th": {
                  textTransform: "uppercase",
                  fontWeight: "600",
                  // fontFamily: "Inter",
                  color: "#666E7D",
                },
              }}
            >
              <TableRow
                sx={{
                  "& th": {
                    borderBottom: "0",
                  },
                }}
              >
                <TableCell>
                  sl no <Reorder />
                </TableCell>
                {/* estimatedEnrolments, estimatedCommission, averageCommission, */}
                <TableCell></TableCell>
                <TableCell>
                  Estimated Enrolments <Reorder />
                </TableCell>
                <TableCell>
                  Estimated commission <Reorder />
                </TableCell>
                <TableCell>
                  average commission <Reorder />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                background: "#fff",
                border: "1px solid rgba(220, 223, 227, 0.50)",
                borderTop: "0",
              }}
            >
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{
                    borderTop: "0",
                    "& .MuiTableCell-root": {
                      borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
                    },
                  }}
                >
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.estimatedEnrolments}</TableCell>
                  <TableCell>{row.estimatedCommission}</TableCell>
                  <TableCell>{row.averageCommission}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
}

export default EstimatedEnrollmentTable;
