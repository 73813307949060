import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Reorder from "./Reorder";

function PreveousYearIncom() {
  function createData(
    year,
    application,
    student,
    enrolment,
    commissions,
    paid
  ) {
    return {
      year,
      application,
      student,
      enrolment,
      commissions,
      paid,
    };
  }
  const rows = [
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
    createData("2022", "1060", "647", "22", "15663", "06 Oct 2022"),
  ];

  return (
    <Box>
      <Box
        sx={{
          overflowX: "scroll",
          maxWidth: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "& table": {
            minWidth: "1200px",
          },
        }}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
            },
          }}
        >
          <TableHead
            sx={{
              background: "#F9F9FB",
              "& th": {
                textTransform: "uppercase",
                fontWeight: "600",
                // fontFamily: "Inter",
                color: "#666E7D",
              },
            }}
          >
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell>
                Applications <Reorder />
              </TableCell>
              <TableCell>
                Students <Reorder />
              </TableCell>
              <TableCell>
                Enrolments <Reorder />
              </TableCell>
              <TableCell>
                commissions <Reorder />
              </TableCell>
              <TableCell>
                Paid <Reorder />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              background: "#fff",
              border: "1px solid rgba(220, 223, 227, 0.50)",
            }}
          >
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.year}</TableCell>
                <TableCell>{row.application}</TableCell>
                <TableCell>{row.student}</TableCell>
                <TableCell>{row.enrolment}</TableCell>
                <TableCell>₹ {row.commissions}</TableCell>
                <TableCell>{row.paid}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}
export default PreveousYearIncom;
