import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";

import Select from "react-select";
import { countries } from "countries-list";
import { useDispatch } from "react-redux";

import {
  countryMasterAddAction,
  findcountryMasterAddAction
} from "../../../../actions/countryMasterAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import { TypographyText } from "../../../customComponent/Typography";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { useSelector } from "react-redux";
import { locationCreateAction } from "../../../../actions/locationAction";
import { SelectInputField } from "../../../customComponent/InputField";

// const allCountries = Object.entries(countries).map(([code, country]) => ({
//   value: code,
//   label: `${country.name} (+${country.phone})`,
//   phone: `+${country.phone}`,
// }));

function AddLocation({ status, addMasterCategryHandler, handleClosepopUp }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [location, setLocation] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryPhone, setCountryPhone] = useState("");

  const [errors, setErrors] = useState();
  const [selectedCountryDetails, setSelectedCountryDetails] = useState({});

  let { countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  const allCountries =
    countryFindSuccess?.map((item) => ({
      value: item?.countryCode || "",
      label: item?.countryName || "",
      phone: item?.countryPhoneCode || ""
    })) || [];

  console.log(allCountries, "allCountriesallCountries");

  const handleClose = () => {
    setOpen(false);
    addMasterCategryHandler(status, false);
    handleClosepopUp();
  };

  const handleSubmit = () => {
    const validate = () => {
      const newErrors = {};
      if (selectedCountry === "") {
        newErrors.selectedCountry = "Country is required";
      }

      if (location === "") {
        newErrors.location = "Location is required";
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    if (validate()) {
      dispatch(locationCreateAction(selectedCountryDetails, location));
      handleClose();
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch]);

  //   form

  const handleCountryChange = (selectedOption) => {
    setErrorMsg(false);
    setSelectedCountry(selectedOption);
  };

  console.log(selectedCountryDetails, "selectedCountryDetailsselectedCountryDetails");

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <TypographyText title="Add New Location" variant="h5" />
        </DialogTitle>

        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose Country
                </Typography>
              )}
              {/* <Select
                options={allCountries}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select a country"
                style={{ width: "100%", height: "50px", marginBottom: "16px" }}
                required
              />
              <Typography
                sx={{
                  color: "#FF2400",
                  fontSize: "12px"
                }}
              >
                {errors?.selectedCountry}
              </Typography> */}

              <SelectInputField
                label="Country Name"
                handleSelectChange={(e) => {
                  console.log(
                    e.target.value,
                    "selectedCountryDetailsselectedCountryDetails"
                  );
                  setErrorMsg(false);
                  setSelectedCountry(e.target.value);
                  const findCountryDetails =  allCountries.find(
                    (country) => country.label === e.target.value
                  )
                  setSelectedCountryDetails(
                    findCountryDetails
                  );
                  setCountryCode(findCountryDetails.value)
                  setCountryPhone(findCountryDetails.phone)

                }}
                widthSize="99px"
                value={selectedCountry}
                mapValue={allCountries}
                mapMethod="locatioCountry"
                required={true}
              />
              <Typography
                sx={{
                  color: "#FF2400",
                  fontSize: "12px"
                }}
              >
                {errors?.selectedCountry}
              </Typography>

              {selectedCountryDetails && (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      label="Country Code"
                      value={countryCode}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone Code"
                      value={countryPhone}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextField
                  label="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  fullWidth
                  required
                />
                <Typography
                  sx={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {errors?.location}
                </Typography>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddLocation;
