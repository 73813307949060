import React, { useEffect, useState } from "react";
import { MainContainer } from "../content/content.element";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../customComponent/InfoHeader";
import { Divider, Grid } from "@mui/material";
import { Editor, EditorState, RichUtils } from "draft-js";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { stateToHTML } from "draft-js-export-html";
import { SubmitButton } from "../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  hrEmployeeJobEditAction,
  hrEmployeeJobUpdateAction,
} from "../../actions/HrActions/hrEmployeeManagmentAction";
import { ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import InputField from "../customComponent/InputField";

function EmployeeJobEditForm(props) {
  let { id } = useParams();
  console.log(id, "kkkiiittttyyyyy");
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [selectedStyle, setSelectedStyle] = useState("NORMAL");
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);
  const [bgColor, setBgColor] = useState("transparent");
  const [textColor, setTextColor] = useState("black");
  const [JobTitle, setJobtitle] = useState("");
  const [JobLocation, setJoblocation] = useState("");
  const [JobType, setJobType] = useState("");
  const [Experience, setExperience] = useState("");
  const [Vacancies, setVacancies] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  // text editor section

  const handleStyleChange = (e) => {
    const newStyle = e.target.value;

    // Toggle the new style immediately

    const newEditorState = RichUtils.toggleInlineStyle(editorState, newStyle);

    setSelectedStyle(newStyle);
    setEditorState(newEditorState);
  };

  // Handle_createJob_Button

  useEffect(() => {
    dispatch(hrEmployeeJobEditAction(id));
  }, [dispatch]);

  let { hrEmployeeJobEditSuccess } = useSelector((state) => {
    return state.hrEmployeeJobEdit;
  });

  console.log(hrEmployeeJobEditSuccess, "@@@@@@@@@@@@@@@@@@22222");

  //   Convert_HTml_to_Plaintext

  function convertHTMLToPlainText(html) {
    // Convert HTML to Draft.js ContentState
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    // Convert Draft.js ContentState to plain text
    const plainText = contentState.getPlainText();

    return plainText;
  }

  useEffect(() => {
    if (hrEmployeeJobEditSuccess) {
      setJobtitle(
        hrEmployeeJobEditSuccess ? hrEmployeeJobEditSuccess.jobtitle : "-"
      );
      setJoblocation(
        hrEmployeeJobEditSuccess ? hrEmployeeJobEditSuccess.jobLocation : "-"
      );
      setJobType(
        hrEmployeeJobEditSuccess ? hrEmployeeJobEditSuccess.jobType : "-"
      );
      setExperience(
        hrEmployeeJobEditSuccess ? hrEmployeeJobEditSuccess.Experience : "-"
      );
      setVacancies(
        hrEmployeeJobEditSuccess ? hrEmployeeJobEditSuccess.Vacanices : "-"
      );

      setDescription(
        convertHTMLToPlainText(
          hrEmployeeJobEditSuccess ? hrEmployeeJobEditSuccess.Description : "-"
        )
      );

      let text = convertFromHTML(hrEmployeeJobEditSuccess?.Description);
      const contentState = ContentState.createFromBlockArray(
        text.contentBlocks
      );
      console.log(contentState, "texttexttexttexttexttexttexttext");
      setEditorState(
        hrEmployeeJobEditSuccess?.Description
          ? EditorState.createWithContent(contentState)
          : EditorState.createEmpty()
      );
    }
  }, [hrEmployeeJobEditSuccess]);

  const HandleUpdate = () => {
    const contentState = editorState.getCurrentContent();
    const htmlContent = stateToHTML(contentState);
    setDescription(htmlContent);

    dispatch(
      hrEmployeeJobUpdateAction(
        id,
        JobTitle,
        JobLocation,
        JobType,
        Experience,
        Vacancies,
        description
      )
    );
    setJobtitle("");
    setJoblocation("");
    setJobType("");
    setExperience("");
    setVacancies("");
    setEditorState( EditorState.createEmpty())
  };

  const applyStyle = () => {
    switch (selectedStyle) {
      case "BOLD":
        return "BOLD";
      case "ITALIC":
        return "ITALIC";
      case "UNDERLINE":
        return "UNDERLINE";
      default:
        return "";
    }
  };

  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const handleStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        `BG_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        `TEXT_COLOR_${color.toUpperCase()}`
      )
    );
  };
  const handleTextAlignment = (alignment) => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, `ALIGN_${alignment.toUpperCase()}`)
    );
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  console.log(JobTitle, editorState, "***********************************");
  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Edit Job" url={"/hrmanagment"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={6}>
          <InputField
            label="Job Title"
            handleChange={(e) => setJobtitle(e.target.value)}
            widthSize="97%"
            value={JobTitle}
            required
            paramses
          />
        </Grid>
        <Grid item xs={12} lg={6} style={{}}>
          <InputField
            label="Job Location"
            handleChange={(e) => setJoblocation(e.target.value)}
            widthSize="97%"
            value={JobLocation}
            required
            paramses
          />
        </Grid>

        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Job Type"
              handleChange={(e) => setJobType(e.target.value)}
              widthSize="95%"
              value={JobType}
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Experience"
              handleChange={(e) => setExperience(e.target.value)}
              widthSize="95%"
              value={Experience}
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Vacancies"
              handleChange={(e) => setVacancies(e.target.value)}
              widthSize="95%"
              value={Vacancies}
              required
              paramses
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <div>
              <ToggleButtonGroup
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <select value={selectedStyle} onChange={handleStyleChange}>
                  <option value="NORMAL">Normal</option>
                  <option value="BOLD">Bold</option>
                  <option value="ITALIC">Italic</option>
                  <option value="UNDERLINE">Underline</option>
                </select>
                <FormatBoldIcon
                  onClick={handleBoldClick}
                  value="bold"
                  aria-label="bold"
                />

                <FormatItalicIcon
                  onClick={handleItalicClick}
                  value="italic"
                  aria-label="italic"
                />

                <FormatUnderlinedIcon
                  onClick={handleUnderlineClick}
                  value="underlined"
                  aria-label="underlined"
                />

                <StrikethroughSIcon
                  onClick={handleStrikethroughClick}
                  value="strikethrough"
                  aria-label="strikethrough"
                />
                <FormatColorTextIcon
                  onClick={() => handleBgColorChange("yellow")}
                  value="backgroundcolor"
                  aria-label="backgroundcolor"
                />

                <InvertColorsIcon
                  onClick={() => handleTextColorChange("blue")}
                  value="textcolor"
                  aria-label="textcolor"
                />

                <FormatAlignLeftIcon
                  onClick={() => handleTextAlignment("left")}
                  value="textcolor"
                  aria-label="textcolor"
                />
              </ToggleButtonGroup>
            </div>
            <div
              style={{
                border: "1px solid #ececec",
                padding: "10px",
                minHeight: "200px",
                marginTop: "10px",
              }}
            >
              <Editor
                editorState={editorState}
                onChange={setEditorState}
                placeholder="Description"
                customStyleMap={{
                  // Define custom inline styles for text and background colors
                  TEXT_COLOR_RED: { color: "red" },
                  TEXT_COLOR_BLUE: { color: "blue" },
                  BG_COLOR_YELLOW: { backgroundColor: "yellow" },
                  BG_COLOR_GREEN: { backgroundColor: "green" },
                  ALIGN_LEFT: { textAlign: "left" },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Update"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleUpdate}
        />
      </Grid>
    </MainContainer>
  );
}

export default EmployeeJobEditForm;
