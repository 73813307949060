import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";

function DetailedViewListComponent({
  type,
  handleClick,
  isPlaying,
  topicName,
  duration,
  url,
}) {
  console.log(url);

  return (
    <Box sx={{ minWidth: "350px" }}>
      {type === "video" ? (
        <Box
          sx={{
            boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "16px 16px 0 0",
              overflow: "hidden",
              objectPosition: "center",
              "& video": {
                width: "100%",
                maxHeight: "130px",
                height: "100%",
                objectFit: "cover",
              },
            }}
          >
            {/* <img src="https://images.pexels.com/photos/5427659/pexels-photo-5427659.jpeg?auto=compress&cs=tinysrgb&w=600" /> */}
            <video src={url}/>
          </Box>
          <Box
            sx={{
              border: "1px solid rgba(239, 239, 239, 1)",
              background: "rgba(255, 255, 255, 1)",
              padding: "8px",
              pb: "16px",
              borderTop: "0",
            }}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
            >
              {topicName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  "& svg": {},
                }}
                onClick={() => handleClick("video", isPlaying ? false : true)}
              >
                {isPlaying ? (
                  <svg
                    // onClick={() => handleClick("video", false)}
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 4.8C0 2.5368 -7.15256e-08 1.4064 0.7032 0.7032C1.4064 -7.15256e-08 2.5368 0 4.8 0C7.0632 0 8.1936 -7.15256e-08 8.8968 0.7032C9.6 1.4064 9.6 2.5368 9.6 4.8V19.2C9.6 21.4632 9.6 22.5936 8.8968 23.2968C8.1936 24 7.0632 24 4.8 24C2.5368 24 1.4064 24 0.7032 23.2968C-7.15256e-08 22.5936 0 21.4632 0 19.2V4.8ZM14.4 4.8C14.4 2.5368 14.4 1.4064 15.1032 0.7032C15.8064 -7.15256e-08 16.9368 0 19.2 0C21.4632 0 22.5936 -7.15256e-08 23.2968 0.7032C24 1.4064 24 2.5368 24 4.8V19.2C24 21.4632 24 22.5936 23.2968 23.2968C22.5936 24 21.4632 24 19.2 24C16.9368 24 15.8064 24 15.1032 23.2968C14.4 22.5936 14.4 21.4632 14.4 19.2V4.8Z"
                      fill="#141E3C"
                    />
                  </svg>
                ) : (
                  <svg
                    // onClick={() => handleClick("video", true)}
                    width="18"
                    height="14"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.26117 0.440984C4.46015 -0.593306 3 0.253997 3 2.33197V17.6665C3 19.7466 4.46015 20.5928 6.26117 19.5595L19.6488 11.8728C21.4504 10.8382 21.4504 9.1619 19.6488 8.12748L6.26117 0.440984Z"
                      fill="#141E3C"
                    />
                  </svg>
                )}

                <Typography
                  sx={{
                    color: "rgba(20, 30, 60, 1)",
                    fontWeight: "700",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Play Video
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: "12px",
                  color: "rgba(255, 27, 111, 1)",
                }}
              >
                Duration :{duration}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : type === "audio" ? (
        <>
          <Box
            sx={{
              border: "1px solid rgba(239, 239, 239, 1)",
              background: "rgba(255, 255, 255, 1)",
              padding: "16px",
              pb: "20px",
              borderRadius: "8px",
              boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                fontSize: "14px",
              }}
            >
              {topicName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                  "& svg": {},
                }}
                onClick={() => handleClick("audio", isPlaying ? false : true)}
              >
                {isPlaying ? (
                  <svg
                    // onClick={() => handleClick("audio", false)}
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 4.8C0 2.5368 -7.15256e-08 1.4064 0.7032 0.7032C1.4064 -7.15256e-08 2.5368 0 4.8 0C7.0632 0 8.1936 -7.15256e-08 8.8968 0.7032C9.6 1.4064 9.6 2.5368 9.6 4.8V19.2C9.6 21.4632 9.6 22.5936 8.8968 23.2968C8.1936 24 7.0632 24 4.8 24C2.5368 24 1.4064 24 0.7032 23.2968C-7.15256e-08 22.5936 0 21.4632 0 19.2V4.8ZM14.4 4.8C14.4 2.5368 14.4 1.4064 15.1032 0.7032C15.8064 -7.15256e-08 16.9368 0 19.2 0C21.4632 0 22.5936 -7.15256e-08 23.2968 0.7032C24 1.4064 24 2.5368 24 4.8V19.2C24 21.4632 24 22.5936 23.2968 23.2968C22.5936 24 21.4632 24 19.2 24C16.9368 24 15.8064 24 15.1032 23.2968C14.4 22.5936 14.4 21.4632 14.4 19.2V4.8Z"
                      fill="#141E3C"
                    />
                  </svg>
                ) : (
                  <svg
                    // onClick={() => handleClick("audio", true)}
                    width="18"
                    height="14"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.26117 0.440984C4.46015 -0.593306 3 0.253997 3 2.33197V17.6665C3 19.7466 4.46015 20.5928 6.26117 19.5595L19.6488 11.8728C21.4504 10.8382 21.4504 9.1619 19.6488 8.12748L6.26117 0.440984Z"
                      fill="#141E3C"
                    />
                  </svg>
                )}

                <Typography
                  sx={{
                    color: "rgba(20, 30, 60, 1)",
                    fontWeight: "700",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                  }}
                >
                  {" "}
                  Listen Audio
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "rgba(255, 27, 111, 1)",
                }}
              >
                Duration :{duration}
              </Typography>
            </Box>
          </Box>
        </>
      ) : type === "notes" ? (
        <>
          <Box
            sx={{
              border: "1px solid rgba(239, 239, 239, 1)",
              background: "rgba(255, 255, 255, 1)",
              padding: "16px",
              pb: "20px",
              borderRadius: "8px",
              boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                fontSize: "14px",
              }}
            >
              {topicName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.26653 14.6816C8.08253 14.6816 7.95853 14.6996 7.89453 14.7176V15.8956C7.97053 15.9136 8.06553 15.9186 8.19653 15.9186C8.67553 15.9186 8.97053 15.6766 8.97053 15.2676C8.97053 14.9016 8.71653 14.6816 8.26653 14.6816ZM11.7535 14.6936C11.5535 14.6936 11.4235 14.7116 11.3465 14.7296V17.3396C11.4235 17.3576 11.5475 17.3576 11.6595 17.3576C12.4765 17.3636 13.0085 16.9136 13.0085 15.9616C13.0145 15.1316 12.5295 14.6936 11.7535 14.6936Z"
                    fill="#141E3C"
                  />
                  <path
                    d="M14 2.00195H6C5.46957 2.00195 4.96086 2.21266 4.58579 2.58774C4.21071 2.96281 4 3.47152 4 4.00195V20.002C4 20.5324 4.21071 21.0411 4.58579 21.4162C4.96086 21.7913 5.46957 22.002 6 22.002H18C18.5304 22.002 19.0391 21.7913 19.4142 21.4162C19.7893 21.0411 20 20.5324 20 20.002V8.00195L14 2.00195ZM9.498 16.192C9.189 16.482 8.733 16.612 8.202 16.612C8.09904 16.6131 7.99613 16.6071 7.894 16.594V18.02H7V14.084C7.40345 14.0238 7.81112 13.9964 8.219 14.002C8.776 14.002 9.172 14.108 9.439 14.321C9.693 14.523 9.865 14.854 9.865 15.244C9.864 15.636 9.734 15.967 9.498 16.192ZM13.305 17.547C12.885 17.896 12.246 18.062 11.465 18.062C10.997 18.062 10.666 18.032 10.441 18.002V14.085C10.8446 14.0261 11.2521 13.9983 11.66 14.002C12.417 14.002 12.909 14.138 13.293 14.428C13.708 14.736 13.968 15.227 13.968 15.932C13.968 16.695 13.689 17.222 13.305 17.547ZM17 14.772H15.468V15.683H16.9V16.417H15.468V18.021H14.562V14.032H17V14.772ZM14 9.00195H13V4.00195L18 9.00195H14Z"
                    fill="#141E3C"
                  />
                </svg>
                <a href={url} download={url} target="blank">
                  <Typography
                    sx={{
                      color: "rgba(20, 30, 60, 1)",
                      fontWeight: "700",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                    }}
                  >
                    {" "}
                    View Note
                  </Typography>
                </a>
              </Box>
              <Typography
                component={"a"}
                href={url}
                download
                sx={{
                  textDecoration: "none",
                  fontSize: "12px",
                  color: "rgba(255, 27, 111, 1)",
                }}
              >
                Download
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default DetailedViewListComponent;
