import React, { forwardRef, useEffect, useState } from "react";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { Box, TextField, Typography, Grid, Checkbox } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

import { MainContainer } from "../../content/content.element";
import {
  editRollAction,
  roleEditFindAction,
} from "../../../actions/roleMangmentAction";
import { InfoHeader } from "../../customComponent/InfoHeader";
import InputField from "../../customComponent/InputField";
import { SubmitButton } from "../../customComponent/Buttons";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function EditRole(props) {
  const initialCheckboxState = {
    isLeadUpdate: false,
    isLeadView: false,
    isLeadDelete: false,
    isLeadAll: false,
    // isadministrationUpdate: false,
    // isadministrationView: false,
    // isadministrationDelete: false,
    // isadministrationAll: false,
    // isApplicationUpdate: false,
    // isApplicationView: false,
    // isApplicationDelete: false,
    // isApplicationAll: false,
    // isUniversityUpdate: false,
    // isUniversityView: false,
    // isUniversityDelete: false,
    // isUniversityAll: false,
    // isCoursUpdate: false,
    // isCoursView: false,
    // isCoursDelete: false,
    // isCoursAll: false,
    // isTicketUpdate: false,
    // isTicketView: false,
    // isTicketDelete: false,
    // isTicketAll: false,
    // isEnquiryUpdate: false,
    // isEnquiryView: false,
    // isEnquiryDelete: false,
    // isEnquiryAll: false,
    // isWebsitUpdate: false,
    // isWebsitView: false,
    // isWebsitDelete: false,
    // isWebsitAll: false,
    // isIeltsUpdate: false,
    // isIeltsView: false,
    // isIeltsDelete: false,
    // isIeltsAll: false,
    // isHRmanagmentUpdate: false,
    // isHRmanagmentView: false,
    // isHRmanagmentDelete: false,
    // isHRmanagmentAll: false,
    // isAccountsUpdate: false,
    // isAccountsView: false,
    // isAccountsDelete: false,
    // isAccountsAll: false,
    // isSettingsUpdate: false,
    // isSettingsView: false,
    // isSettingsDelete: false,
    // isSettingsAll: false,
  };

  const { roleId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editData, setEditData] = useState({});
  const [error, setError] = useState({});

  const { rollEditFindSuccess, rollEditFindLoading, rollEditFindErr } =
    useSelector((state) => {
      return state.roleEditFind;
    });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  const { rollEditLoading, rollEditSuccess, rollEditErr } = useSelector(
    (state) => {
      return state.editRoll;
    }
  );

  useEffect(() => {
    if (rollEditSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `${rollEditSuccess.message}`, "success");
      dispatch(successTrueMsgAction(false));
      navigate("/Administration");
    }
  }, [rollEditSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    dispatch(roleEditFindAction(roleId));
  }, [dispatch, roleId]);

  const [checkboxState, setCheckboxState] = useState(initialCheckboxState);
  const [roleName, setRoleName] = useState(editData.roleName);
  const [roleDescription, setRoleDescription] = useState(
    editData.roleDescription
  );

  const [isLeadUpdate, setIsLeadUpdate] = useState(editData.isLeadUpdate);
  const [isLeadView, setIsLeadView] = useState(editData.isLeadView);
  const [isLeadDelete, setIsLeadDelete] = useState(editData.isLeadDelete);
  const [isLeadAll, setIsLeadAll] = useState(editData.isLeadAll);

  const [isadministrationUpdate, setIsadministrationUpdate] = useState(
    editData.isadministrationUpdate
  );
  const [isadministrationView, setIsadministrationView] = useState(
    editData.isadministrationView
  );
  const [isadministrationDelete, setIsadministrationDelete] = useState(
    editData.isadministrationDelete
  );
  const [isadministrationAll, setIsadministrationAll] = useState(
    editData.isadministrationAll
  );

  const [isApplicationUpdate, setIsApplicationUpdate] = useState(
    editData.isApplicationUpdate
  );
  const [isApplicationView, setIsApplicationView] = useState(
    editData.isApplicationView
  );
  const [isApplicationDelete, setIsApplicationDelete] = useState(
    editData.isApplicationDelete
  );
  const [isApplicationAll, setIsApplicationAll] = useState(
    editData.isApplicationAll
  );

  const [isUniversityUpdate, setIsUniversityUpdate] = useState(
    editData.isUniversityUpdate
  );
  const [isUniversityView, setIsUniversityView] = useState(
    editData.isUniversityView
  );
  const [isUniversityDelete, setIsUniversityDelete] = useState(
    editData.isUniversityDelete
  );
  const [isUniversityAll, setIsUniversityAll] = useState(
    editData.isUniversityAll
  );

  const [isCoursUpdate, setIsCoursUpdate] = useState(editData.isCoursUpdate);
  const [isCoursView, setIsCoursView] = useState(editData.isCoursView);
  const [isCoursDelete, setIsCoursDelete] = useState(editData.isCoursDelete);
  const [isCoursAll, setIsCoursAll] = useState(editData.isCoursAll);

  const [isTicketUpdate, setIsTicketUpdate] = useState(editData.isTicketUpdate);
  const [isTicketView, setIsTicketView] = useState(editData.isTicketView);
  const [isTicketDelete, setIsTicketDelete] = useState(editData.isTicketDelete);
  const [isTicketAll, setIsTicketAll] = useState(editData.isTicketAll);

  const [isEnquiryUpdate, setIsEnquiryUpdate] = useState(
    editData.isEnquiryUpdate
  );
  const [isEnquiryView, setIsEnquiryView] = useState(editData.isEnquiryView);
  const [isEnquiryDelete, setIsEnquiryDelete] = useState(
    editData.isEnquiryDelete
  );
  const [isEnquiryAll, setIsEnquiryAll] = useState(editData.isEnquiryAll);

  const [isWebsitUpdate, setIsWebsitUpdate] = useState(editData.isWebsitUpdate);
  const [isWebsitView, setIsWebsitView] = useState(editData.isWebsitView);
  const [isWebsitDelete, setIsWebsitDelete] = useState(editData.isWebsitDelete);
  const [isWebsitAll, setIsWebsitAll] = useState(editData.isWebsitAll);

  const [isIeltsUpdate, setIsIeltsUpdate] = useState(editData.isIeltsUpdate);
  const [isIeltsView, setIsIeltsView] = useState(editData.isIeltsView);
  const [isIeltsDelete, setIsIeltsDelete] = useState(editData.isIeltsDelete);
  const [isIeltsAll, setIsIeltsAll] = useState(editData.isIeltsAll);

  const [isHRmanagmentUpdate, setIsHRmanagmentUpdate] = useState(
    editData.isHRmanagmentUpdate
  );
  const [isHRmanagmentView, setIsHRmanagmentView] = useState(
    editData.isHRmanagmentView
  );
  const [isHRmanagmentDelete, setIsHRmanagmentDelete] = useState(
    editData.isHRmanagmentDelete
  );
  const [isHRmanagmentAll, setIsIsHRmanagmentAll] = useState(
    editData.isHRmanagmentAll
  );

  const [isAccountsUpdate, setIsAccountsUpdate] = useState(
    editData.isAccountsUpdate
  );
  const [isAccountsView, setIsAccountsView] = useState(editData.isAccountsView);
  const [isAccountsDelete, setIsAccountsDelete] = useState(
    editData.isAccountsDelete
  );
  const [isAccountsAll, setIsAccountsAll] = useState(editData.isAccountsAll);

  const [isSettingsUpdate, setIsSettingsUpdate] = useState(
    editData.isSettingsUpdate
  );
  const [isSettingsView, setIsSettingsView] = useState(editData.isSettingsView);
  const [isSettingsDelete, setIsSettingsDelete] = useState(
    editData.isSettingsDelete
  );
  const [isSettingsAll, setIsSettingsAll] = useState(editData.isSettingsAll);

  useEffect(() => {
    if (rollEditFindSuccess) {
      setEditData(rollEditFindSuccess);
      setCheckboxState(rollEditFindSuccess);
      setRoleName(rollEditFindSuccess.roleName);
      setRoleDescription(rollEditFindSuccess.roleDescription);
      setIsLeadUpdate(rollEditFindSuccess.isLeadUpdate);
      setIsLeadView(rollEditFindSuccess.isLeadView);
      setIsLeadDelete(rollEditFindSuccess.isLeadDelete);
      setIsLeadAll(rollEditFindSuccess.isLeadAll);
      setIsadministrationUpdate(rollEditFindSuccess.isadministrationUpdate);
      setIsadministrationView(rollEditFindSuccess.isadministrationView);
      setIsadministrationDelete(rollEditFindSuccess.isadministrationDelete);
      setIsadministrationAll(rollEditFindSuccess.isadministrationAll);
      setIsApplicationUpdate(rollEditFindSuccess.isApplicationUpdate);
      setIsApplicationView(rollEditFindSuccess.isApplicationView);
      setIsApplicationDelete(rollEditFindSuccess.isApplicationDelete);
      setIsApplicationAll(rollEditFindSuccess.isApplicationAll);
      setIsUniversityUpdate(rollEditFindSuccess.isUniversityUpdate);
      setIsUniversityView(rollEditFindSuccess.isUniversityView);
      setIsUniversityDelete(rollEditFindSuccess.isUniversityDelete);
      setIsUniversityAll(rollEditFindSuccess.isUniversityAll);
      setIsCoursUpdate(rollEditFindSuccess.isCoursUpdate);
      setIsCoursView(rollEditFindSuccess.isCoursView);
      setIsCoursDelete(rollEditFindSuccess.isCoursDelete);
      setIsCoursAll(rollEditFindSuccess.isCoursAll);
      setIsTicketUpdate(rollEditFindSuccess.isTicketUpdate);
      setIsTicketView(rollEditFindSuccess.isTicketView);
      setIsTicketDelete(rollEditFindSuccess.isTicketDelete);
      setIsTicketAll(rollEditFindSuccess.isTicketAll);
      setIsEnquiryUpdate(rollEditFindSuccess.isEnquiryUpdate);
      setIsEnquiryView(rollEditFindSuccess.isEnquiryView);
      setIsEnquiryDelete(rollEditFindSuccess.isEnquiryDelete);
      setIsEnquiryAll(rollEditFindSuccess.isEnquiryAll);
      setIsWebsitUpdate(rollEditFindSuccess.isWebsitUpdate);
      setIsWebsitView(rollEditFindSuccess.isWebsitView);
      setIsWebsitDelete(rollEditFindSuccess.isWebsitDelete);
      setIsWebsitAll(rollEditFindSuccess.isWebsitAll);
      setIsIeltsUpdate(rollEditFindSuccess.isIeltsUpdate);
      setIsIeltsView(rollEditFindSuccess.isIeltsView);
      setIsIeltsDelete(rollEditFindSuccess.isIeltsDelete);
      setIsIeltsAll(rollEditFindSuccess.isIeltsAll);
      setIsHRmanagmentUpdate(rollEditFindSuccess.isHRmanagmentUpdate);
      setIsHRmanagmentView(rollEditFindSuccess.isHRmanagmentView);
      setIsHRmanagmentDelete(rollEditFindSuccess.isHRmanagmentDelete);
      setIsIsHRmanagmentAll(rollEditFindSuccess.isHRmanagmentAll);
      setIsAccountsUpdate(rollEditFindSuccess.isAccountsUpdate);
      setIsAccountsView(rollEditFindSuccess.isAccountsView);
      setIsAccountsDelete(rollEditFindSuccess.isAccountsDelete);
      setIsAccountsAll(rollEditFindSuccess.isAccountsAll);
      setIsSettingsUpdate(rollEditFindSuccess.isSettingsUpdate);
      setIsSettingsView(rollEditFindSuccess.isSettingsView);
      setIsSettingsDelete(rollEditFindSuccess.isSettingsDelete);
      setIsSettingsAll(rollEditFindSuccess.isSettingsAll);
    }
  }, [
    rollEditFindSuccess,
    setEditData,
    setRoleDescription,
    setIsLeadUpdate,
    setIsLeadView,
    setIsLeadDelete,
    setIsLeadAll,
    setIsadministrationUpdate,
    setIsadministrationView,
    setIsadministrationDelete,
    setIsadministrationAll,
    setIsApplicationUpdate,
    setIsApplicationView,
    setIsApplicationDelete,
    setIsApplicationAll,
    setIsUniversityUpdate,
    setIsUniversityView,
    setIsUniversityDelete,
    setIsUniversityAll,
    setIsCoursUpdate,
    setIsCoursView,
    setIsCoursDelete,
    setIsCoursAll,
    setIsTicketUpdate,
    setIsTicketView,
    setIsTicketDelete,
    setIsTicketAll,
    setIsEnquiryUpdate,
    setIsEnquiryView,
    setIsEnquiryDelete,
    setIsEnquiryAll,
    setIsWebsitUpdate,
    setIsWebsitView,
    setIsWebsitDelete,
    setIsWebsitAll,
    setIsIeltsUpdate,
    setIsIeltsView,
    setIsIeltsDelete,
    setIsIeltsAll,
    setIsHRmanagmentUpdate,
    setIsHRmanagmentView,
    setIsHRmanagmentDelete,
    setIsIsHRmanagmentAll,
    setIsAccountsUpdate,
    setIsAccountsView,
    setIsAccountsDelete,
    setIsAccountsAll,
    setIsSettingsUpdate,
    setIsSettingsView,
    setIsSettingsDelete,
    setIsSettingsAll,
  ]);

  const handleCheckboxChange = (checkboxName, checked, all) => {
    if (all && all === "all") {
      setCheckboxState((prevState) => ({
        ...prevState,
        [`${checkboxName}all`]: checked,
        [`${checkboxName}Update`]: checked,
        [`${checkboxName}View`]: checked,
        [`${checkboxName}Delete`]: checked,
        [`${checkboxName}All`]: checked,
      }));
    } else {
      setCheckboxState((prevState) => ({
        ...prevState,
        [`${checkboxName}${all}`]: checked,
      }));
    }
  };

  useEffect(() => {
    setCheckboxState((prevState) => ({
      ...prevState,
      isLeadAll:
        prevState.isLeadUpdate &&
        prevState.isLeadView &&
        prevState.isLeadDelete,
      isadministrationAll:
        prevState.isadministrationUpdate &&
        prevState.isadministrationView &&
        prevState.isadministrationDelete,
      isApplicationAll:
        prevState.isApplicationUpdate &&
        prevState.isApplicationView &&
        prevState.isApplicationDelete,
      isUniversityAll:
        prevState.isUniversityUpdate &&
        prevState.isUniversityView &&
        prevState.isUniversityDelete,
      isCourseAll:
        prevState.isCourseUpdate &&
        prevState.isCourseView &&
        prevState.isCourseDelete,
      isTicketAll:
        prevState.isTicketUpdate &&
        prevState.isTicketView &&
        prevState.isTicketDelete,

      isEnquiryAll:
        prevState.isEnquiryUpdate &&
        prevState.isEnquiryView &&
        prevState.isEnquiryDelete,

      isWebsiteAll:
        prevState.isWebsiteUpdate &&
        prevState.isWebsiteView &&
        prevState.isWebsiteDelete,

      isIELTSAll:
        prevState.isIELTSUpdate &&
        prevState.isIELTSView &&
        prevState.isIELTSDelete,

      isHRmanagmentAll:
        prevState.isHRmanagmentUpdate &&
        prevState.isHRmanagmentView &&
        prevState.isHRmanagmentDelete,

      isAccountsAll:
        prevState.isAccountsUpdate &&
        prevState.isAccountsView &&
        prevState.isAccountsDelete,

      isSettingsAll:
        prevState.isSettingsUpdate &&
        prevState.isSettingsView &&
        prevState.isSettingsDelete,

      // Repeat the pattern for other checkboxes
    }));
  }, [
    checkboxState.isLeadUpdate,
    checkboxState.isLeadView,
    checkboxState.isLeadDelete,
    checkboxState.isadministrationUpdate,
    checkboxState.isadministrationView,
    checkboxState.isadministrationDelete,
    checkboxState.isApplicationUpdate,
    checkboxState.isApplicationView,
    checkboxState.isApplicationDelete,

    checkboxState.isUniversityUpdate,
    checkboxState.isUniversityView,
    checkboxState.isUniversityDelete,

    checkboxState.isCourseUpdate,
    checkboxState.isCourseView,
    checkboxState.isCourseDelete,

    checkboxState.isTicketUpdate,
    checkboxState.isTicketView,
    checkboxState.isTicketDelete,

    checkboxState.isEnquiryUpdate,
    checkboxState.isEnquiryView,
    checkboxState.isEnquiryDelete,

    checkboxState.isWebsiteUpdate,
    checkboxState.isWebsiteView,
    checkboxState.isWebsiteDelete,

    checkboxState.isIELTSUpdate,
    checkboxState.isIELTSView,
    checkboxState.isIELTSDelete,

    checkboxState.isHRmanagmentUpdate,
    checkboxState.isHRmanagmentView,
    checkboxState.isHRmanagmentDelete,

    checkboxState.isAccountsUpdate,
    checkboxState.isAccountsView,
    checkboxState.isAccountsDelete,

    checkboxState.isSettingsUpdate,
    checkboxState.isSettingsView,
    checkboxState.isSettingsDelete,
  ]);

  // material Table
  let columns = [
    {
      title: "SL NO",
      field: "sl",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Role Name",
      field: "name",
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Update",
      field: "update",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "View",
      field: "view",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Delete",
      field: "delete",
      filtering: false,
      //   hidden: true
      // lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "All",
      field: "all",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  let data = [
    {
      sl: 1,
      name: "Lead Managment",
      update: (
        <>
          <Checkbox
            sx={{
              color: "#f81b82",
              "&.Mui-checked": {
                color: "#f81b82",
              },
            }}
            onChange={(e) =>
              handleCheckboxChange("isLead", e.target.checked, "Update")
            }
            checked={checkboxState.isLeadUpdate}
          />
        </>
      ),
      view: (
        <>
          <Checkbox
            sx={{
              color: "#f81b82",
              "&.Mui-checked": {
                color: "#f81b82",
              },
            }}
            onChange={(e) =>
              handleCheckboxChange("isLead", e.target.checked, "View")
            }
            checked={checkboxState.isLeadView}
          />
        </>
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          onChange={(e) =>
            handleCheckboxChange("isLead", e.target.checked, "Delete")
          }
          checked={checkboxState.isLeadDelete}
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          onChange={(e) =>
            handleCheckboxChange("isLead", e.target.checked, "all")
          }
          checked={checkboxState.isLeadAll}
        />
      ),
    },
    {
      sl: 2,
      name: "Administration",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isadministrationUpdate}
          onChange={(e) =>
            handleCheckboxChange("isadministration", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isadministrationView}
          onChange={(e) =>
            handleCheckboxChange("isadministration", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isadministrationDelete}
          onChange={(e) =>
            handleCheckboxChange("isadministration", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isadministrationAll}
          onChange={(e) =>
            handleCheckboxChange("isadministration", e.target.checked, "all")
          }
        />
      ),
    },

    {
      sl: 3,
      name: "Application",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isApplicationUpdate}
          onChange={(e) =>
            handleCheckboxChange("isApplication", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isApplicationView}
          onChange={(e) =>
            handleCheckboxChange("isApplication", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isApplicationDelete}
          onChange={(e) =>
            handleCheckboxChange("isApplication", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isApplicationAll}
          onChange={(e) =>
            handleCheckboxChange("isApplication", e.target.checked, "all")
          }
        />
      ),
    },
    {
      sl: 4,
      name: "University",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isUniversityUpdate}
          onChange={(e) =>
            handleCheckboxChange("isUniversity", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isUniversityView}
          onChange={(e) =>
            handleCheckboxChange("isUniversity", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isUniversityDelete}
          onChange={(e) =>
            handleCheckboxChange("isUniversity", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isUniversityAll}
          onChange={(e) =>
            handleCheckboxChange("isUniversity", e.target.checked, "all")
          }
        />
      ),
    },
    {
      sl: 5,
      name: "Course",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isCourseUpdate}
          onChange={(e) =>
            handleCheckboxChange("isCourse", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isCourseView}
          onChange={(e) =>
            handleCheckboxChange("isCourse", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isCourseDelete}
          onChange={(e) =>
            handleCheckboxChange("isCourse", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isCourseAll}
          onChange={(e) =>
            handleCheckboxChange("isCourse", e.target.checked, "all")
          }
        />
      ),
    },
    {
      sl: 6,
      name: "ticket",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isTicketUpdate}
          onChange={(e) =>
            handleCheckboxChange("isTicket", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isTicketView}
          onChange={(e) =>
            handleCheckboxChange("isTicket", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isTicketDelete}
          onChange={(e) =>
            handleCheckboxChange("isTicket", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isTicketAll}
          onChange={(e) =>
            handleCheckboxChange("isTicket", e.target.checked, "all")
          }
        />
      ),
    },

    {
      sl: 7,
      name: "enquiry",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isEnquiryUpdate}
          onChange={(e) =>
            handleCheckboxChange("isEnquiry", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isEnquiryView}
          onChange={(e) =>
            handleCheckboxChange("isEnquiry", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isEnquiryDelete}
          onChange={(e) =>
            handleCheckboxChange("isEnquiry", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isEnquiryAll}
          onChange={(e) =>
            handleCheckboxChange("isEnquiry", e.target.checked, "all")
          }
        />
      ),
    },

    {
      sl: 8,
      name: "Website",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isWebsiteUpdate}
          onChange={(e) =>
            handleCheckboxChange("isWebsite", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isWebsiteView}
          onChange={(e) =>
            handleCheckboxChange("isWebsite", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isWebsiteDelete}
          onChange={(e) =>
            handleCheckboxChange("isWebsite", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isWebsiteAll}
          onChange={(e) =>
            handleCheckboxChange("isWebsite", e.target.checked, "all")
          }
        />
      ),
    },
    {
      sl: 9,
      name: "IELTS",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isIELTSUpdate}
          onChange={(e) =>
            handleCheckboxChange("isIELTS", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isIELTSView}
          onChange={(e) =>
            handleCheckboxChange("isIELTS", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isIELTSDelete}
          onChange={(e) =>
            handleCheckboxChange("isIELTS", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isIELTSAll}
          onChange={(e) =>
            handleCheckboxChange("isIELTS", e.target.checked, "all")
          }
        />
      ),
    },
    {
      sl: 10,
      name: "HR",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isHRmanagmentUpdate}
          onChange={(e) =>
            handleCheckboxChange("isHRmanagment", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isHRmanagmentView}
          onChange={(e) =>
            handleCheckboxChange("isHRmanagment", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isHRmanagmentDelete}
          onChange={(e) =>
            handleCheckboxChange("isHRmanagment", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isHRmanagmentAll}
          onChange={(e) =>
            handleCheckboxChange("isHRmanagment", e.target.checked, "all")
          }
        />
      ),
    },
    {
      sl: 11,
      name: "Account",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isAccountsUpdate}
          onChange={(e) =>
            handleCheckboxChange("isAccounts", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isAccountsView}
          onChange={(e) =>
            handleCheckboxChange("isAccounts", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isAccountsDelete}
          onChange={(e) =>
            handleCheckboxChange("isAccounts", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isAccountsAll}
          onChange={(e) =>
            handleCheckboxChange("isAccounts", e.target.checked, "all")
          }
        />
      ),
    },

    {
      sl: 12,
      name: "Setting",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isSettingsUpdate}
          onChange={(e) =>
            handleCheckboxChange("isSettings", e.target.checked, "Update")
          }
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isSettingsView}
          onChange={(e) =>
            handleCheckboxChange("isSettings", e.target.checked, "View")
          }
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isSettingsDelete}
          onChange={(e) =>
            handleCheckboxChange("isSettings", e.target.checked, "Delete")
          }
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          checked={checkboxState.isSettingsAll}
          onChange={(e) =>
            handleCheckboxChange("isSettings", e.target.checked, "all")
          }
        />
      ),
    },
  ];

  const validate = () => {
    const newErrors = {};
    if (roleName === "") {
      newErrors.roleName = "Role name is required";
    }
    if (roleDescription === "") {
      newErrors.roleDescription = "Role discription is required";
    }
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const privilageSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      // successTrueMsgAction(true);
      dispatch(
        editRollAction(
          editData.id,
          roleName,
          roleDescription
          // checkboxState.isLeadUpdate,
          // checkboxState.isLeadView,
          // checkboxState.isLeadDelete,
          // checkboxState.isLeadAll,
          // checkboxState.isadministrationUpdate,
          // checkboxState.isadministrationView,
          // checkboxState.isadministrationDelete,
          // checkboxState.isadministrationAll,
          // checkboxState.isApplicationUpdate,
          // checkboxState.isApplicationView,
          // checkboxState.isApplicationDelete,
          // checkboxState.isApplicationAll,
          // checkboxState.isUniversityUpdate,
          // checkboxState.isUniversityView,
          // checkboxState.isUniversityDelete,
          // checkboxState.isUniversityAll,
          // checkboxState.isCoursUpdate,
          // checkboxState.isCoursView,
          // checkboxState.isCoursDelete,
          // checkboxState.isCoursAll,
          // checkboxState.isTicketUpdate,
          // checkboxState.isTicketView,
          // checkboxState.isTicketDelete,
          // checkboxState.isTicketAll,
          // checkboxState.isEnquiryUpdate,
          // checkboxState.isEnquiryView,
          // checkboxState.isEnquiryDelete,
          // checkboxState.isEnquiryAll,
          // checkboxState.isWebsitUpdate,
          // checkboxState.isWebsitView,
          // checkboxState.isWebsitDelete,
          // checkboxState.isWebsitAll,
          // checkboxState.isIeltsUpdate,
          // checkboxState.isIeltsView,
          // checkboxState.isIeltsDelete,
          // checkboxState.isIeltsAll,
          // checkboxState.isHRmanagmentUpdate,
          // checkboxState.isHRmanagmentView,
          // checkboxState.isHRmanagmentDelete,
          // checkboxState.isHRmanagmentAll,
          // checkboxState.isAccountsUpdate,
          // checkboxState.isAccountsView,
          // checkboxState.isAccountsDelete,
          // checkboxState.isAccountsAll,
          // checkboxState.isSettingsUpdate,
          // checkboxState.isSettingsView,
          // checkboxState.isSettingsDelete,
          // checkboxState.isSettingsAll
        )
      );
    }
  };

  return (
    <MainContainer active={props.toggle}>
      <form onSubmit={privilageSubmit}>
        <InfoHeader headTitle="Edit Role" url="/Administration" />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Box
            sx={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <Typography variant="h6">Edit Role Details</Typography>
          </Box>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Role Name"
              handleChange={(e) => {
                setRoleName(e.target.value);
              }}
              widthSize="99%"
              value={roleName}
              required=""
              paramses=""
              error={Boolean(error?.roleName)}
              helperText={error?.roleName}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              label="Role Description"
              handleChange={(e) => {
                setRoleDescription(e.target.value);
              }}
              widthSize="99%"
              value={roleDescription}
              required=""
              paramses=""
              error={Boolean(error?.roleName)}
              helperText={error?.roleName}
            />
          </Grid>
        </Grid>

        {/* tables */}

        {/* <Grid container spacing={2} sx={{ mt: 2 }}>
          <Box
            sx={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <Typography variant="h6">Select Priviliges</Typography>
          </Box>

          <Grid item lg={12} xs={12}>
            <MaterialTable
              icons={tableIcons}
              title="Role Edit"
              data={data}
              columns={columns}
              options={{
                search: false,
                exportButton: false,
                showTitle: false,
                toolbar: false,
                showFirstLastPageButtons: false,
                showSelectAllCheckbox: false,
                showRowSelection: false,
                pageSize: 10,
                paginationType: "stepped",
                headerStyle: {
                  background: "none",
                },
              }}
              components={{
                Toolbar: () => null,
              }}
              direction="ltr"
            />
          </Grid>
        </Grid> */}
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <SubmitButton
            title="save"
            submit="submit"
            widthSize="80px"
            heightSize="40px"
            type="submit"
            handleSubmit=""
          />
        </Box>
      </form>
    </MainContainer>
  );
}

export default EditRole;
