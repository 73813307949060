import React, { useState } from "react";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { newPasswordSetAction } from "../../../actions/adminAuthAction";

function ConfirmPassword() {
  const dispatch = useDispatch();

  const { newPasswordLoading, newPasswordSuccess, newPasserror } = useSelector(
    (state) => {
      return state.newPasswordSet;
    }
  );

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [isNotSame, setIsNotSame] = useState(false);

  // form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword === "") {
      setNewPasswordError(true);
    }
    if (confirmPassword === "") {
      setConfirmPasswordError(true);
    }

    if (newPassword !== confirmPassword) {
      setIsNotSame(true);
    }

    if (newPassword !== "" && confirmPassword !== "") {
      event.preventDefault();
      dispatch(newPasswordSetAction(newPassword, confirmPassword));
    }
  };
  // onchange newPassword handle
  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
    setNewPasswordError(false);
  };

  // onchange confirm password handle
  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(false);
    setIsNotSame(false);
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {newPasserror && (
          <Typography
            style={{
              fontWeight: 90,
              fontSize: "20px",
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: "52px",
              color: "red",
            }}
          >
            {newPasserror}
          </Typography>
        )}
        <Typography
          component="h2"
          variant="h2"
          style={{
            fontWeight: 700,
            fontSize: "40px",
            fontFamily: "'Open Sans', sans-serif",
            lineHeight: "52px",
            color: "#141E3C",
          }}
        >
          Set New Password
        </Typography>

        <Typography
          component="h6"
          variant="h6"
          style={{
            width: "425px",
            height: "24px",
            left: "40px",
            top: "101px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",

            color: "#777AAE",
          }}
        >
          Set a New Password For Login GSL Account
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            type="password"
            label="New Password Address"
            name="password"
            autoComplete="newPassword"
            onChange={handleNewPassword}
            error={newPasswordError}
            helperText={newPasswordError ? "Please enter your newPassword" : ""}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="cpassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            onChange={handleConfirmPassword}
            error={confirmPasswordError}
            helperText={
              confirmPasswordError ? "Please enter your password" : ""
            }
            autoComplete="current-password"
          />
          <p style={{ color: "red", margin: "10px" }}>
            {isNotSame ? `Confirm Password is Not Same` : ""}
          </p>

          <Grid container mt="10px">
            <Grid item xs>
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                style={{ color: "#777AAE" }}
              /> */}
            </Grid>
            <Grid item>
              {/* <Link
                href="#"
                variant="body2"
                style={{
                  color: "#FF1B6F",
                  textDecoration: "none",
                  fontFamily: "Poppins",
                }}
                onClick={forgotPageHandler}
              >
                Forgot password?
              </Link> */}
            </Grid>
          </Grid>
          <Button
            className="button"
            type="submit"
            fullWidth
            size="large"
            sx={{
              mt: 3,
              mb: 2,
              fontWeight: "700",
              backgroundColor: "#141E3C",
              color: "#fff",
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

export default ConfirmPassword;
