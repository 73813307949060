import { Box, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectTopicTab from "../Topics/SelectTopicTab";
import TopicUploadComponent from "../../../../../customComponent/TopicUploadComponent";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCreateCourseAction } from "../../../../../../actions/IeltsActions/courseManagmentAction";

function OnlineTabItem({ type }) {
  const dispatch = useDispatch();

  let { ieltsCourseManagmentCourseCreateSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });

  let {
    ieltsCourseManagmentCourseEditFindSuccess,
    ieltsCourseManagmentCourseEditFinderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseEditFind;
  });

  const [itemData, setItemData] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    setItemData(ieltsCourseManagmentCourseCreateSuccess);
  }, [ieltsCourseManagmentCourseCreateSuccess]);

  const handleFileChange = (e, fileType) => {
    const files = e.target.files;

    const updateItemData = (prevItemData) => ({
      ...prevItemData,
      online: {
        ...prevItemData.online,
        [type]: {
          ...prevItemData.online[type],
          [fileType]: files[0],
        },
      },
    });

    setItemData((prevItemData) => updateItemData(prevItemData));
    dispatch(ieltsCourseManagmentCreateCourseAction(updateItemData(itemData)));
  };

  const handleDelete = (fileType) => {
    setItemData((prevItemData) => {
      const updatedData = {
        ...prevItemData,
        online: {
          ...prevItemData.online,
          [type]: {
            ...prevItemData.online[type],
            [fileType]: "",
          },
        },
      };
      dispatch(ieltsCourseManagmentCreateCourseAction(updatedData));

      return updatedData;
    });
  };

  console.log(itemData?.online[type], "itemDataitemData");

  return (
    <>
      <Box sx={{ marginLeft: "-24px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <Box sx={{}}>
            <Typography
              sx={{
                mb: "6px",
                fontSize: "12px",
              }}
            >
              Demo Video
            </Typography>
            {itemData?.online[type]?.DemoVideo?.length == 0 && (
              <FormLabel
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: "558px",
                  height: "46px",
                  border: "1px solid #ECECEC",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  pr: "8px",
                }}
                //   onChange={handleChange}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#ACB1C6",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {"Choose File"}
                </Typography>
                <input
                  type="file"
                  hidden
                  name="logoFile"
                  multiple
                  onChange={(e) => handleFileChange(e, "DemoVideo")}
                  accept=".mp4"
                />
                <Box
                  sx={{
                    cursor: "pointer",
                    padding: "4px 18px",
                    background: "#EBF2FD",
                    color: "#1E88E5",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Browse
                </Box>
              </FormLabel>
            )}
            {itemData?.online[type]?.DemoVideo && (
              <Box
                sx={{
                  padding: "20px",
                  background: "rgba(245, 245, 245, 1)",
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  // gridTemplateColumns: "repeat(6,1fr)",
                  "& > div": {
                    maxWidth: "180px",
                    width: "100%",
                  },
                }}
              >
                {/* {itemData?.online[type].DemoVideo.map((item, i) => ( */}
                <TopicUploadComponent
                  handleClick={() => handleDelete("DemoVideo")}
                  title={
                    itemData?.online[type]?.DemoVideo.name
                      ? itemData?.online[type]?.DemoVideo.name
                      : "Demo video"
                  }
                  type="video"
                />
                {/* ))} */}
              </Box>
            )}
          </Box>

          <Box sx={{}}>
            <Typography
              sx={{
                mb: "6px",
                fontSize: "12px",
              }}
            >
              Demo Audio
            </Typography>
            {itemData?.online[type]?.DemoAudio?.length == 0 && (
              <FormLabel
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: "558px",
                  height: "46px",
                  border: "1px solid #ECECEC",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  pr: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#ACB1C6",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {"Choose File"}
                </Typography>
                <input
                  type="file"
                  hidden
                  name="logoFile"
                  multiple
                  onChange={(e) => handleFileChange(e, "DemoAudio")}
                  accept=".mp3"
                />
                <Box
                  sx={{
                    cursor: "pointer",
                    padding: "4px 18px",
                    background: "#EBF2FD",
                    color: "#1E88E5",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Browse
                </Box>
              </FormLabel>
            )}

            {itemData?.online[type]?.DemoAudio && (
              <Box
                sx={{
                  padding: "20px",
                  background: "rgba(245, 245, 245, 1)",
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  // gridTemplateColumns: "repeat(6,1fr)",
                  "& > div": {
                    maxWidth: "180px",
                    width: "100%",
                  },
                }}
              >
                {/* {itemData?.online[type].DemoAudio.map((item, i) => ( */}
                <TopicUploadComponent
                  handleClick={() => handleDelete("DemoAudio")}
                  title={
                    itemData?.online[type]?.DemoAudio.name
                      ? itemData?.online[type]?.DemoAudio.name
                      : "Demo audio"
                  }
                  type="audio"
                />
                {/* ))} */}
              </Box>
            )}
          </Box>

          <Box sx={{}}>
            <Typography
              sx={{
                mb: "6px",
                fontSize: "12px",
              }}
            >
              Demo Note
            </Typography>
            {itemData?.online[type]?.DemoNote?.length == 0 && (
              <FormLabel
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: "558px",
                  height: "46px",
                  border: "1px solid #ECECEC",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  pr: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#ACB1C6",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {"Choose File"}
                </Typography>
                <input
                  type="file"
                  hidden
                  name="logoFile"
                  multiple
                  onChange={(e) => handleFileChange(e, "DemoNote")}
                  accept=".pdf"
                />
                <Box
                  sx={{
                    cursor: "pointer",
                    padding: "4px 18px",
                    background: "#EBF2FD",
                    color: "#1E88E5",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Browse
                </Box>
              </FormLabel>
            )}
            {itemData?.online[type]?.DemoNote && (
              <Box
                sx={{
                  padding: "20px",
                  background: "rgba(245, 245, 245, 1)",
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  // gridTemplateColumns: "repeat(6,1fr)",
                  "& > div": {
                    maxWidth: "180px",
                    width: "100%",
                  },
                }}
              >
                {/* {itemData?.online[type].DemoNote.map((item, i) => ( */}
                <TopicUploadComponent
                  handleClick={() => handleDelete("DemoNote")}
                  title={
                    itemData?.online[type]?.DemoNote.name
                      ? itemData?.online[type]?.DemoNote.name
                      : "Demo note"
                  }
                  type="pdf"
                />
                {/* ))} */}
              </Box>
            )}
          </Box>

          {itemData?.online[type]?.DemoAudio?.length != 0 &&
            itemData?.online[type]?.DemoVideo?.length != 0 &&
            itemData?.online[type]?.DemoNote?.length != 0 && (
              <Box>
                <SelectTopicTab type={type} />
              </Box>
            )}
        </Box>
      </Box>
    </>
  );
}

export default OnlineTabItem;
