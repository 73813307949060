import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Button from "@mui/material/Button";

import Swal from "sweetalert2";

import { HiOutlineChevronRight } from "react-icons/hi";

import { Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { MainContainer } from "../../../content/content.element";
import {
  universityAddSubmitAction,
  universityEditAction,
  universityViewAction,
} from "../../../../actions/universityActions/universityManagmentAction";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import EditfileUpload from "./EditfileUpload";
import EditeligibleCerteria from "./EditeligibleCerteria";
import EditaboutUniversity from "./EditaboutUniversity";
import EditbasicInformation from "./EditbasicInformation";

function EditUniversityMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    universityEditLoading,
    universityEditSuccess,
    universityEditErr,
  } = useSelector((state) => {
    return state.universityEdit;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (universityEditSuccess && successAlertMsgSuccess) {
      localStorage.removeItem("edituniversityName");
      localStorage.removeItem("editcountry");
      localStorage.removeItem("editcity");
      localStorage.removeItem("editstate");
      localStorage.removeItem("location");

      localStorage.removeItem("editranking");
      localStorage.removeItem("editrank");
      localStorage.removeItem("editaffiliation");
      localStorage.removeItem("editfacilities");
      localStorage.removeItem("edittypes");
      localStorage.removeItem("editestablishedYear");
      localStorage.removeItem("edituniversityAbout");
      localStorage.removeItem("editselectedFiles");
      localStorage.removeItem("editinternationalFee");
      localStorage.removeItem("editinternationalStudent");
      localStorage.removeItem("editqualification");
      localStorage.removeItem("edittest");
      localStorage.removeItem("editworkExperience");
      localStorage.removeItem("editremark");
      localStorage.removeItem("editdocumentRequired");
      localStorage.removeItem("editisTopUniversity");
      localStorage.removeItem("editwebsite");
      localStorage.removeItem("editapplicationFee");
      Swal.fire("Added!", "University Updated Successfully.", "success");
      dispatch(successTrueMsgAction(false));
      navigate("/university");
    }
  }, [universityEditSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (universityEditErr && successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${universityEditErr}`,
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [universityEditErr, successAlertMsgSuccess]);

  const steps = [
    { label: "1", details: "Basic Information" },
    { label: "2", details: "About University" },
    { label: "3", details: "Eligibility Criteria" },
    { label: "4", details: "File upload" },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = (status) => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    status === "completed" && handleNext();
  };

  const handleUploadSubmit = (
    selectedVideoFiles,
    selectedFiles,
    brochurFile,
    logoFile,
    universityCoverImage
  ) => {
    let universityName = JSON.parse(localStorage.getItem("edituniversityName"));
    let country = JSON.parse(localStorage.getItem("editcountry"));
    let state = JSON.parse(localStorage.getItem("editstate"));
    console.log(localStorage.getItem("editcity"));
    let city =
      localStorage.getItem("editcity") !== "undefined"
        ? JSON.parse(localStorage.getItem("editcity"))
        : "";
        let location =
      localStorage.getItem("location") !== "undefined"
        ? JSON.parse(localStorage.getItem("location"))
        : "";
    let ranking = JSON.parse(localStorage.getItem("editranking"));
    let rank = JSON.parse(localStorage.getItem("editrank"));
    let websiteLink = JSON.parse(localStorage.getItem("editwebsite"));
    let affiliation = JSON.parse(localStorage.getItem("editaffiliation"));
    let facilities = JSON.parse(localStorage.getItem("editfacilities"));
    let types = JSON.parse(localStorage.getItem("edittypes"));
    let establishedYear = JSON.parse(
      localStorage.getItem("editestablishedYear")
    );
    let universityAbout = JSON.parse(
      localStorage.getItem("edituniversityAbout")
    );
    let internationalFee = JSON.parse(
      localStorage.getItem("editinternationalFee")
    );
    let eligibilityQualification = JSON.parse(
      localStorage.getItem("editqualification")
    );
    let eligibilityTest = JSON.parse(localStorage.getItem("edittest"));
    let eligibilityWorkExperience = JSON.parse(
      localStorage.getItem("editworkExperience")
    );
    let eligibilityRemark = JSON.parse(localStorage.getItem("editremark"));

    const documentRequired = localStorage.getItem("editdocumentRequired");
    const isTopUniversity = localStorage.getItem("editisTopUniversity");
    const applicationFee = localStorage.getItem("editapplicationFee");
    const universityHandler = localStorage.getItem("universityHandler");


    if (logoFile) {
      dispatch(
        universityEditAction({
          universityName: universityName,
          country: country,
          state: state,
          city: city,
          location,
          ranking: ranking,
          rank: rank,
          websiteLink,
          affiliation: affiliation,
          facilities: facilities,
          types: types,
          establishedYear: new Date(establishedYear).getFullYear(),
          universityAbout: universityAbout,
          filesVideo: selectedVideoFiles,
          internationalFee,
          eligibilityQualification,
          eligibilityTest,
          eligibilityWorkExperience,
          eligibilityRemark,
          documentRequired,
          brochurFile,
          logoFile,
          isTopUniversity,
          applicationFee,
          universityCoverImage,
          universityId: id,
          universityHandler
        })
      );
      localStorage.removeItem("edituniversityName");
      localStorage.removeItem("editcountry");
      localStorage.removeItem("editcity");
      localStorage.removeItem("editstate");
      localStorage.removeItem("location");

      localStorage.removeItem("editranking");
      localStorage.removeItem("editrank");
      localStorage.removeItem("editaffiliation");
      localStorage.removeItem("editfacilities");
      localStorage.removeItem("edittypes");
      localStorage.removeItem("editestablishedYear");
      localStorage.removeItem("edituniversityAbout");
      localStorage.removeItem("editselectedFiles");
      localStorage.removeItem("editinternationalFee");
      localStorage.removeItem("editinternationalStudent");
      localStorage.removeItem("editqualification");
      localStorage.removeItem("edittest");
      localStorage.removeItem("editworkExperience");
      localStorage.removeItem("editremark");
      localStorage.removeItem("editdocumentRequired");
      localStorage.removeItem("editisTopUniversity");
      localStorage.removeItem("editwebsite");
      localStorage.removeItem("editapplicationFee");
      localStorage.removeItem("universityHandler");


    } else {
      Swal.fire("Add Logo!", `Please Upload Logo`, "error");
    }
  };

  return (
    <MainContainer active={props.toggle}>
      <span
        onClick={() => {
          localStorage.removeItem("edituniversityName");
          localStorage.removeItem("editcountry");
          localStorage.removeItem("editcity");
          localStorage.removeItem("editstate");
          localStorage.removeItem("editranking");
          localStorage.removeItem("editrank");
          localStorage.removeItem("editaffiliation");
          localStorage.removeItem("editfacilities");
          localStorage.removeItem("edittypes");
          localStorage.removeItem("editestablishedYear");
          localStorage.removeItem("edituniversityAbout");
          localStorage.removeItem("editselectedFiles");
          localStorage.removeItem("editinternationalFee");
          localStorage.removeItem("editinternationalStudent");
          localStorage.removeItem("editqualification");
          localStorage.removeItem("edittest");
          localStorage.removeItem("editworkExperience");
          localStorage.removeItem("editremark");
          localStorage.removeItem("editdocumentRequired");
          localStorage.removeItem("editisTopUniversity");
          localStorage.removeItem("editwebsite");
          localStorage.removeItem("editapplicationFee");
          localStorage.removeItem("universityHandler");


        }}
      >
        <InfoHeader headTitle="Edit University" url="/university" />
      </span>
      <Box sx={{ mt: 3 }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <>
              <Button
                key={label.label}
                sx={{
                  color: completed[index] ? "#fff" : "#141E3C",
                  backgroundColor: completed[index] ? "#141E3C" : "#fff",
                  marginRight: "8px",
                  borderRadius: "50%",
                  minWidth: "40px",
                  height: "40px",
                  border: "1px solid #141E3C",
                  fontWeight: 700,
                }}
                color="inherit"
                onClick={handleStep(index)}
              >
                {label.label}
              </Button>

              <Typography
                variant="h6"
                sx={{
                  fontFamily: "'Montserrat',sans-serif",
                  fontWeight: 600,
                  fontSize: "18px",
                  ml: 1,
                }}
              >
                {label.details}
              </Typography>
              {index !== steps.length - 1 && (
                <HiOutlineChevronRight
                  style={{
                    marginLeft: "20px",
                    fontSize: "25px",
                    marginRight: "15px",
                    fontWeight: 1,
                    color: "rgba(172, 177, 198, 1)",
                  }}
                />
              )}
            </>
          ))}
        </Stepper>
        <>
          {activeStep === 0 && (
            <EditbasicInformation handleComplete={handleComplete} />
          )}

          {activeStep === 1 && (
            <EditaboutUniversity
              handleBack={handleBack}
              handleComplete={handleComplete}
            />
          )}

          {activeStep === 2 && (
            <EditeligibleCerteria
              handleBack={handleBack}
              handleComplete={handleComplete}
            />
          )}

          {activeStep === 3 && (
            <EditfileUpload
              activeStep={activeStep}
              steps={steps}
              isLastStep={isLastStep}
              handleBack={handleBack}
              handleComplete={handleComplete}
              handleUploadSubmit={handleUploadSubmit}
            />
          )}
        </>
      </Box>
    </MainContainer>
  );
}

export default EditUniversityMain;
