import axios from "axios";
import {
  ADD_UNIVERSITY_COVER_IMAGE_ERR,
  ADD_UNIVERSITY_COVER_IMAGE_REQUEST,
  ADD_UNIVERSITY_COVER_IMAGE_SUCCESS,
  DELETE_UNIVERSITY_COVER_IMAGE_ERR,
  DELETE_UNIVERSITY_COVER_IMAGE_REQUEST,
  DELETE_UNIVERSITY_COVER_IMAGE_SUCCESS,
  GET_UNIVERSITY_COVER_IMAGE_ERR,
  GET_UNIVERSITY_COVER_IMAGE_REQUEST,
  GET_UNIVERSITY_COVER_IMAGE_SUCCESS,
} from "../../constants/settings/settingsConstants";

export const addUniversityCoverImageAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_UNIVERSITY_COVER_IMAGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let id = isUserExist.UserId;

      let { data } = await axios.post(
        `/api/admin/default/addDefaultUniversityCoverImage`,
        formData,
        config
      );

      dispatch({ type: ADD_UNIVERSITY_COVER_IMAGE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ADD_UNIVERSITY_COVER_IMAGE_ERR,
        payload: error.response.data,
      });
    }
  };

export const getUniversityCoverImageAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_UNIVERSITY_COVER_IMAGE_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.get(
        `/api/admin/default/getDefaultUniversityCoverImage`,
        config
      );

      dispatch({ type: GET_UNIVERSITY_COVER_IMAGE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_UNIVERSITY_COVER_IMAGE_ERR,
        payload: error.response.data,
      });
    }
  };

export const deleteUniversityCoverImageAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_UNIVERSITY_COVER_IMAGE_REQUEST });
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.delete(
        `/api/admin/default/deleteDefaultUniversityCoverImage?id=${id}`,
        config
      );

      dispatch({ type: DELETE_UNIVERSITY_COVER_IMAGE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: DELETE_UNIVERSITY_COVER_IMAGE_ERR,
        payload: error.response.data,
      });
    }
  };
