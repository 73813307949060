import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Typography } from "@mui/material";
import { MainContainer } from "../../content/content.element";
import DetailedViewListComponent from "../../customComponent/DetailedViewListComponent";
import MusicPlayerSlider from "../../customComponent/MusicPlayer";
import VideoPlayer from "../../customComponent/VideoPlayer";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import TopicUploadComponent from "../../customComponent/TopicUploadComponent";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ieltsLearningManagmentFindTopicByIdAction } from "../../../actions/IeltsActions/learningManadmentAction";
import { InfoHeader } from "../../customComponent/InfoHeader";

function TopicDetailedView({ toggle }) {
  const dispatch = useDispatch();
  const [playVideo, setPlayVideo] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);
  const [topicDetails, setTopicDetails] = useState("");
  const { id } = useParams();

  const handleClick = (type, value) => {
    
    if (type === "audio") {
      setPlayVideo(false);
      setPlayAudio(value);
    }
    if (type === "video") {
      setPlayAudio(false);
      setPlayVideo(value);
    }
  };

  let {
    ieltsLearningManagmentFindTopicByIdSuccess,
    ieltsLearningManagmentFindTopicByIderror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentFindTopiById;
  });

  useEffect(() => {
    dispatch(ieltsLearningManagmentFindTopicByIdAction(id));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsLearningManagmentFindTopicByIdSuccess) {
      setTopicDetails(ieltsLearningManagmentFindTopicByIdSuccess.data);
    }
  }, [ieltsLearningManagmentFindTopicByIdSuccess]);

  const capitalizedTopicName = topicDetails?.TopicName
    ? topicDetails.TopicName.charAt(0).toUpperCase() +
      topicDetails.TopicName.slice(1)
    : "";
  return (
    <MainContainer active={toggle}>
      <InfoHeader
        headTitle={capitalizedTopicName + `(${topicDetails?.Category})`}
        url="/IELTS"
      />
      <Box
        sx={{
          pb: "50px",
          display: "flex",
          alignItems: {
            xs: "start",
            lg: "center",
          },
          gap: {
            xs: "20px",
            lg: "40px",
          },
          flexDirection: {
            xs: "column-reverse",
            lg: "row",
          },
          mt: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            maxWidth: "433px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#141E3C",
                fontSize: "18px",
                mb: "16px",
              }}
            >
              Video
            </Typography>

            <DetailedViewListComponent
              type={"video"}
              isPlaying={playVideo}
              handleClick={handleClick}
              topicName={topicDetails?.TopicName}
              duration={topicDetails?.VideoDuration}
              url={topicDetails?.VideoUrl}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#141E3C",
                fontSize: "18px",
                mb: "16px",
              }}
            >
              Audio
            </Typography>
            <DetailedViewListComponent
              type={"audio"}
              isPlaying={playAudio}
              handleClick={handleClick}
              topicName={topicDetails?.TopicName}
              duration={topicDetails?.AudioDuration}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#141E3C",
                fontSize: "18px",
                mb: "16px",
              }}
            >
              Notes
            </Typography>
            <DetailedViewListComponent
              type={"notes"}
              topicName={topicDetails?.TopicName}
              url={topicDetails?.NoteUrl}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {playAudio && <MusicPlayerSlider url={topicDetails?.AudioUrl} />}
          {playVideo && <VideoPlayer url={topicDetails?.VideoUrl} />}
          {/* <TopicUploadComponent type='audio' title={"bdsvbdsvbdsdsabdjabbbbbbbbbbbbb dsajdskbaaaaaaaaaaaaa dajsbddddddddddddddd"}/> */}
        </Box>
      </Box>
    </MainContainer>
  );
}

export default TopicDetailedView;
