import { Alert, AlertTitle, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportIncentiveMenu from "./ReportIncentiveMenu";
import { ReportIncentiveTable } from "./ReportIncentiveTable";
import { findAllLeadApplicationAction } from "../../../actions/aplicationActions/applicationsAction";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function ReportIncentiveMain() {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { privilege, hasPrivilege } = usePrivilegeCheck();


  let { applicationAllFindSuccess } = useSelector((state) => {
    return state.findAllLeadApplication;
  });

  let columns = [
    {
      title: "SL NO",
      field: "SL NO",
    },
    {
      title: "University Name",
      field: "universityName",
    },
    {
      title: "Student name",
      field: "Student name",
    },
    {
      title: "country",
      field: "UNIVERSITY",
    },
    {
      title: "COURSE",
      field: "COURSE",
    },
    {
      title: "DATE ",
      field: "DATE ",
    },
    {
      title: "status",
      field: "status",
    },

    {
      title: "",
      field: "action",
      filtering: false,
    },
  ];

  // function createData(id, studentName, university, course, date, status) {
  //   return {
  //     id,
  //     studentName,
  //     university,
  //     course,
  //     date,
  //     status,
  //   };
  // }

  useEffect(() => {
    if (applicationAllFindSuccess) {
      let result = applicationAllFindSuccess?.data?.map((item) => ({
        id: item.id,
        universityName: item?.universityName,
        studentName: item.lead?.firstName,
        university: item?.universityCountry,
        course: item?.courseName,
        date: item.intakeDate,
        status: item.methodStatus,
      }))
      setData(result);
      setResultData(result);
      // setApplicationData(
      //   applicationAllFindSuccess?.data?.map((item) => ({
      //     id: item.id,
      //     column1: item.lead?.firstName,
      //     column2: item?.universityCountry,
      //     column3: item?.courseName,
      //     column4: item?.user !== null ? item?.user?.firstName : "Not Assigned",
      //     column5: item.intakeDate,
      //     column6: item.methodStatus,
      //     leadId: item.leadId,
      //   }))
      // );
    }
  }, [applicationAllFindSuccess, page]);

 

  useEffect(() => {
    dispatch(findAllLeadApplicationAction(page, "Enrolled"));
  }, [dispatch, page]);

  const handleSearch = (e) => {
    let filteredValues = data?.filter((row) =>
      row.studentName?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setResultData(filteredValues);
  };

  if (!hasPrivilege("incentive") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  console.log(hasPrivilege("incentive"),"incentiveincentiveincentive",privilege,"privilegeprivilege")


  return (
    <Box>
      <ReportIncentiveMenu handleSearch={handleSearch} />
      <Box
        sx={{
          mt: "26px",
          overflow: "hidden",
        }}
      >
        <ReportIncentiveTable columns={columns} data={resultData} />
      </Box>
    </Box>
  );
}

export default ReportIncentiveMain;
