import axios from "axios";

import {
  ALL_COURSE_FIND_ERR,
  ALL_COURSE_FIND_REQUEST,
  ALL_COURSE_FIND_SUCCESS,
  UNIVERSITY_COURSE_ADD_ERR,
  UNIVERSITY_COURSE_ADD_REQUEST,
  UNIVERSITY_COURSE_ADD_SUCCESS,
  UNIVERSITY_COURSE_BULKUPLOAD_ERR,
  UNIVERSITY_COURSE_BULKUPLOAD_REQUEST,
  UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS,
  UNIVERSITY_COURSE_DELETE_ERR,
  UNIVERSITY_COURSE_DELETE_REQUEST,
  UNIVERSITY_COURSE_DELETE_SUCCESS,
  UNIVERSITY_COURSE_EDITFIND_ERR,
  UNIVERSITY_COURSE_EDITFIND_REQUEST,
  UNIVERSITY_COURSE_EDITFIND_SUCCESS,
  UNIVERSITY_COURSE_EDIT_ERR,
  UNIVERSITY_COURSE_EDIT_REQUEST,
  UNIVERSITY_COURSE_EDIT_SUCCESS,
  UNIVERSITY_COURSE_FIND_ERR,
  UNIVERSITY_COURSE_FIND_REQUEST,
  UNIVERSITY_COURSE_FIND_SUCCESS,
  UNIVERSITY_COURSE_MAJOR_FIND_ERR,
  UNIVERSITY_COURSE_MAJOR_FIND_REQUEST,
  UNIVERSITY_COURSE_MAJOR_FIND_SUCCESS,
  UNIVERSITY_COURSE_TABLE_FILTER_ERR,
  UNIVERSITY_COURSE_TABLE_FILTER_REQUEST,
  UNIVERSITY_COURSE_TABLE_FILTER_SUCCESS,
} from "../../../constants/university/courseConstant/universityCourseConstant";
import {
  SUCCESS_TRUE_MSG_ERR,
  SUCCESS_TRUE_MSG_SUCCESS,
} from "../../../constants/roleManagmentConstant";

export const universityCourseAddAction = ({
  id,
  courseTitle,
  courseDescription,
  syllabus,
  syllabusPdf,
  courseLevel,
  category,
  categoryDuration,
  intake,
  totalFee,
  splitData,
  placeMentDescription,
  board,
  test,
  internshipExperience,
  underDeplomo,
  ngoEqExperience,
  extracurricular,
  modeOfStudy,
  WithoutMathamatics,
  isTopCourse,
  courseMajor,
}) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_ADD_REQUEST });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

    let { data } = await axios.post(
      "/api/admin/universityCourse/addCourse",
      {
        id,
        courseTitle,
        courseDescription,
        syllabus,
        syllabusPdf,
        courseLevel,
        category,
        categoryDuration,
        intake,
        totalFee,
        splitData,
        placeMentDescription,
        board,
        test,
        internshipExperience,
        underDeplomo,
        ngoEqExperience,
        extracurricular,
        modeOfStudy,
        WithoutMathamatics,
        isTopCourse,
        courseMajor,
      },
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_COURSE_ADD_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// findCourseAction
export const universityFindCourseAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_FIND_REQUEST });

    let { data } = await axios.get(
      `/api/admin/universityCourse/findCourse?id=${id}`,
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_COURSE_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// allFindCourses
export const allFindCoursesAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: ALL_COURSE_FIND_REQUEST });

    let { data } = await axios.get(
      "/api/admin/universityCourse/allFindCourses",
      config
    );
    dispatch({ type: ALL_COURSE_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_COURSE_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// deleteCourseAction
export const deleteCourseAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_DELETE_REQUEST });

    let { data } = await axios.delete(
      `/api/admin/universityCourse/deleteCourse?id=${id}`,
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_COURSE_DELETE_ERR,
      payload: error.response.data,
    });
  }
};

// courseTableFilterAction
export const courseTableFilterAction = (
  searchData,
  universityId,
  searchStatus
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_TABLE_FILTER_REQUEST });

    let { data } = await axios.post(
      "/api/admin/universityCourse/searchCourseFind",
      { searchData, universityId, searchStatus },
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_TABLE_FILTER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_COURSE_TABLE_FILTER_ERR,
      payload: error.response.data,
    });
  }
};

// findUniversityCourseAction

export const universityCourseFindAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_EDITFIND_REQUEST });

    let { data } = await axios.get(
      `/api/admin/universityCourse/courseFindOne?id=${id}`,
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_EDITFIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_COURSE_EDITFIND_ERR,
      payload: error.response.data,
    });
  }
};

// findUniversityCourseEditAction

export const universityCourseEditAction = ({
  universityId,
  courseTitle,
  courseDescription,
  syllabus,
  syllabusPdf,
  courseLevel,
  category,
  categoryDuration,
  intake,
  totalFee,
  splitData,
  placeMentDescription,
  board,
  test,
  internshipExperience,
  underDeplomo,
  ngoEqExperience,
  extracurricular,
  modeOfStudy,
  WithoutMathamatics,
  isTopCourse,
  courseMajor,
  courseId,
}) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_EDIT_REQUEST });
    dispatch({ type: SUCCESS_TRUE_MSG_SUCCESS, payload: true });

    let { data } = await axios.post(
      "/api/admin/universityCourse/editCourse",
      {
        universityId,
        courseTitle,
        courseDescription,
        syllabus,
        syllabusPdf,
        courseLevel,
        category,
        categoryDuration,
        intake,
        totalFee,
        splitData,
        placeMentDescription,
        board,
        test,
        internshipExperience,
        underDeplomo,
        ngoEqExperience,
        extracurricular,
        modeOfStudy,
        WithoutMathamatics,
        isTopCourse,
        courseMajor,
        courseId,
      },
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_COURSE_EDIT_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseBulkUpload action :::::::::::::::::::::::

export const CourseBulkUploadAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch({ type: UNIVERSITY_COURSE_BULKUPLOAD_REQUEST });

    let { data } = await axios.post(
      "/api/admin/universityCourse/bulkUploadCourse",
      formData,
      config
    );

    dispatch({ type: UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS, payload: data });
  } catch (error) {

    dispatch({
      type: UNIVERSITY_COURSE_BULKUPLOAD_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });

  }
};
