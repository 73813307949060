import axios from "axios";

import {
  IELTS_SETTINGS_CONTACT_DELETE_ERR,
  IELTS_SETTINGS_CONTACT_DELETE_REQUEST,
  IELTS_SETTINGS_CONTACT_DELETE_SUCCESS,
  IELTS_SETTINGS_CONTACT_FIND_BY_ID_ERR,
  IELTS_SETTINGS_CONTACT_FIND_BY_ID_REQUEST,
  IELTS_SETTINGS_CONTACT_FIND_BY_ID_SUCCESS,
  IELTS_SETTINGS_CONTACT_FIND_ERR,
  IELTS_SETTINGS_CONTACT_FIND_REQUEST,
  IELTS_SETTINGS_CONTACT_FIND_SUCCESS,
  IELTS_SETTINGS_FAQ_CREATE_ERR,
  IELTS_SETTINGS_FAQ_CREATE_REQUEST,
  IELTS_SETTINGS_FAQ_CREATE_SUCCESS,
  IELTS_SETTINGS_FAQ_DELETE_ERR,
  IELTS_SETTINGS_FAQ_DELETE_REQUEST,
  IELTS_SETTINGS_FAQ_DELETE_SUCCESS,
  IELTS_SETTINGS_FAQ_FIND_BY_ID_ERR,
  IELTS_SETTINGS_FAQ_FIND_BY_ID_REQUEST,
  IELTS_SETTINGS_FAQ_FIND_BY_ID_SUCCESS,
  IELTS_SETTINGS_FAQ_FIND_ERR,
  IELTS_SETTINGS_FAQ_FIND_REQUEST,
  IELTS_SETTINGS_FAQ_FIND_SUCCESS,
  IELTS_SETTINGS_FAQ_STATUS_CHANGE_ERR,
  IELTS_SETTINGS_FAQ_STATUS_CHANGE_REQUEST,
  IELTS_SETTINGS_FAQ_STATUS_CHANGE_SUCCESS,
  IELTS_SETTINGS_FAQ_UPDATE_ERR,
  IELTS_SETTINGS_FAQ_UPDATE_REQUEST,
  IELTS_SETTINGS_FAQ_UPDATE_SUCCESS,
  IELTS_SETTINGS_ZOOMCONFIG_CREATE_ERR,
  IELTS_SETTINGS_ZOOMCONFIG_CREATE_REQUEST,
  IELTS_SETTINGS_ZOOMCONFIG_CREATE_SUCCESS,
  IELTS_SETTINGS_ZOOMCONFIG_FINDONE_ERR,
  IELTS_SETTINGS_ZOOMCONFIG_FINDONE_REQUEST,
  IELTS_SETTINGS_ZOOMCONFIG_FINDONE_SUCCESS,
  IELTS_SETTINGS_ZOOMCONFIG_FIND_ERR,
  IELTS_SETTINGS_ZOOMCONFIG_FIND_REQUEST,
  IELTS_SETTINGS_ZOOMCONFIG_FIND_SUCCESS,
} from "../../constants/Ielts/ieltsSettingsConstants";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

// des:settings faq create Action
export const ieltsSettingsFaqCreateAction = (formData) => async (dispatch) => {
  console.log(formData, "first");

  try {
    dispatch({ type: IELTS_SETTINGS_FAQ_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/settings/faq/createFaq",
      formData,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_FAQ_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_FAQ_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq find Action
export const ieltsSettingsFaqFindAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_FAQ_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/settings/faq/findFaq",
      config
    );

    dispatch({
      type: IELTS_SETTINGS_FAQ_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_FAQ_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq findById Action
export const ieltsSettingsFaqFindbyIdAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_FAQ_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/settings/faq/findById?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_FAQ_FIND_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_FAQ_FIND_BY_ID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq update Action
export const ieltsSettingsFaqUpdateAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_FAQ_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/settings/faq/faqEdit",
      formData,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_FAQ_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_FAQ_UPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq statusChange Action
export const ieltsSettingsFaqStatusChangeAction = (status, id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_SETTINGS_FAQ_STATUS_CHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/settings/faq/faqStatusChange",
      {
        status,
        id,
      },
      config
    );
    dispatch({
      type: IELTS_SETTINGS_FAQ_STATUS_CHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_FAQ_STATUS_CHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq delete Action
export const ieltsSettingsFaqDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_FAQ_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/settings/faq/faqDelete?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_FAQ_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_FAQ_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// Contact Tab

// des:settings contact find Action
export const ieltsSettingsContactFindAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_CONTACT_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/settings/contact/findContact",
      config
    );

    dispatch({
      type: IELTS_SETTINGS_CONTACT_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_CONTACT_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq findById Action
export const ieltsSettingsContactFindbyIdAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_CONTACT_FIND_BY_ID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/settings/contact/findById?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_CONTACT_FIND_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_CONTACT_FIND_BY_ID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings faq delete Action
export const ieltsSettingsContactDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_CONTACT_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/settings/contact/delete?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_CONTACT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_CONTACT_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings zoomConfig create Action

export const ieltsSettingsZoomConfigCreateAction = (formData) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_SETTINGS_ZOOMCONFIG_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/settings/zoom/zoomConfigCreate",
      formData,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_ZOOMCONFIG_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_ZOOMCONFIG_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings zoomConfig find Action

export const ieltsSettingsZoomConfigFindAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_SETTINGS_ZOOMCONFIG_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/settings/zoom/zoomConfigFind",
      config
    );

    dispatch({
      type: IELTS_SETTINGS_ZOOMCONFIG_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_ZOOMCONFIG_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:settings zoomConfig findOne Action

export const ieltsSettingsZoomConfigFindOneAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_SETTINGS_ZOOMCONFIG_FINDONE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/settings/zoom/zoomConfigFindOne?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_SETTINGS_ZOOMCONFIG_FINDONE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_SETTINGS_ZOOMCONFIG_FINDONE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
