import React, { useEffect, useState } from "react";
import { Grid, Checkbox, Typography, Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { MainContainer } from "../../../content/content.element";
import {
  employeedetailsShowAction,
  findAllEmployeeAction,
  leaveApplyAction,
} from "../../../../actions/employeeManagmentAction";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import InputField, {
  SelectInputField,
} from "../../../customComponent/InputField";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../../actions/globalAction";

const type = ["vacation", "Sick", "Personal"];
export default function EmployeeLeaveApplyForm(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [employeeId, setEmployeeId] = useState("");
  const [mailId, setMailId] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [isFullDay, setIsFullDay] = useState(false);
  const [dateFrom, setDateFrom] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [toDateError, setToDateError] = useState("");
  const [leaveReason, setLeaveReason] = useState("");
  const [error, setErrors] = useState();

  // selectors

  let { findEmployeeLoading, findEmployeeSuccess, findEmployeeErr } =
    useSelector((state) => {
      return state.findAllEmployee;
    });

  let {
    showDetailsEmployeeLoading,
    showDetailsEmployeeSuccess,
    showDetailsEmployeeErr,
  } = useSelector((state) => {
    return state.employeedetailsShow;
  });

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (showDetailsEmployeeSuccess) {
      setEmployeeId(showDetailsEmployeeSuccess?.users?.employeeId);
      setMailId(showDetailsEmployeeSuccess?.users?.email);
    }
  }, [showDetailsEmployeeSuccess]);

  useEffect(() => {
    if (successAlertMsgSuccess) {
      Swal.fire("Good job!", `leave applied`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess, dispatch]);

  // useEffect(() => {
  //   dispatch(findAllEmployeeAction());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(employeedetailsShowAction(id));
  }, [dispatch, id]);

  const validate = () => {
    const newErrors = {};
    if (dateFrom == currentDate) {
      newErrors.dateFrom = "From date is required";
    }
    if (dateTo == currentDate) {
      newErrors.dateTo = "Date to is required";
    } else if (dateTo < dateFrom) {
      newErrors.dateTo = "select date greater than From date";
    }
    if (leaveReason === "") {
      newErrors.leaveReason = "Leave Reason is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here

    if (validate()) {
      dispatch(
        leaveApplyAction({
          id,
          employeeId,
          mailId,
          leaveType,
          isFullDay: isFullDay,
          dateFrom,
          dateTo,
          leaveReason,
        })
      );
      let newDate = new Date();
      setCurrentDate(newDate);
      setLeaveType("");
      setIsFullDay(false);
      setDateFrom(newDate);
      setDateTo(newDate);
      setLeaveReason("");
    }
  };

  return (
    <MainContainer active={props.toggle}>
      <form onSubmit={handleSubmit}>
        <InfoHeader
          headTitle="Apply Leave"
          url={`/Administration/employee/details/${id}`}
        />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* First Column */}
          <Grid item xs={12} sm={4}>
            <InputField
              label="Employee ID"
              // handleChange={(e) => setEmployeeId(e.target.value)}
              value={showDetailsEmployeeSuccess?.users?.employeeId}
              widthSize="99%"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputField
              label="Employee Mail ID"
              // handleChange={(e) => setMailId(e.target.value)}
              value={showDetailsEmployeeSuccess?.users?.email}
              widthSize="99%"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInputField
              label="Leave Type"
              handleSelectChange={(e) => setLeaveType(e.target.value)}
              widthSize="75px"
              value={leaveType}
              mapValue={type}
              mapMethod="leaveType"
            />
          </Grid>

          {/* Second Column */}
          <Grid item lg={12} xs={12} sm={12}>
            <div style={{ display: "flex" }}>
              <Checkbox
                sx={{
                  color: "#F8469A",
                  "&.Mui-checked": {
                    color: "#F8469A",
                  },
                  fontSize: "12px",
                  fontWeight: 400,
                }}
                checked={isFullDay}
                onChange={(e) => setIsFullDay(e.target.checked)}
              />
              <Typography sx={{ mt: 1 }}>Full Day</Typography>
            </div>
          </Grid>

          {/* Third Column */}
          <Grid item xs={12} sm={12} lg={6}>
            <InputField
              label="Date From"
              handleChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const today = new Date();

                // Check if the selected date is before today
                if (selectedDate < today) {
                  // If it's before today, set the input value to today
                  setDateFrom(today.toISOString().split("T")[0]);
                } else {
                  setDateFrom(e.target.value);
                }
                setErrors((prev) => ({
                  ...prev,
                  ["dateFrom"]: "",
                }));
              }}
              value={dateFrom}
              widthSize="99%"
              InputType="date"
              min={new Date().toISOString().split("T")[0]}
              error={Boolean(error?.dateFrom)}
              helperText={error?.dateFrom}
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <InputField
              label="Date To"
              handleChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const today = new Date();
                // Check if the selected date is before today
                if (selectedDate < today || selectedDate < new Date(dateFrom)) {
                  // If it's before today, set the input value to today
                  setToDateError("select date greater than From date");
                  // setErrors((prev) => ({
                  //   ...prev,
                  //   ["dateTo"]: "select date greater than From date",
                  // }));
                  // setDateTo(today.toISOString().split("T")[0]);
                } else {
                  setToDateError("");
                  setDateTo(e.target.value);
                  setErrors((prev) => ({
                    ...prev,
                    ["dateTo"]: "",
                  }));
                }
              }}
              value={dateTo}
              widthSize="99%"
              InputType="date"
              error={Boolean(error?.dateTo)}
              helperText={error?.dateTo}
            />
            {/* <span style={{ color: "red", fontSize: "13px", fontWeight: 350 }}>
              {toDateError}
            </span> */}
          </Grid>

          {/* Fourth Column */}
          <Grid item xs={12} sm={12} lg={12}>
            <InputField
              label="Leave Reason"
              handleChange={(e) => {
                setLeaveReason(e.target.value);
                setErrors((prev) => ({
                  ...prev,
                  ["leaveReason"]: "",
                }));
              }}
              value={leaveReason}
              widthSize="99%"
              InputType="discription"
              dispcriptionHeight="150px"
              error={Boolean(error?.leaveReason)}
              helperText={error?.leaveReason}
            />
          </Grid>
        </Grid>

        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            id="demo-positioned-button"
            style={{
              backgroundColor: "#141E3C",
              size: "10px",
              padding: 1,
              color: "#fff",
              width: "80px",
              height: "45px",
              borderRadius: "5px",
              margin: "8px",
              fontWeight: 600,
              fontSize: "13px",
            }}
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </form>
    </MainContainer>
  );
}
