import axios from "axios";
import {
  CREATE_NEW_MENU_ERR,
  CREATE_NEW_MENU_REQUEST,
  CREATE_NEW_MENU_SUCCESS,
  CREATE_NEW_SUBMENU_ERR,
  CREATE_NEW_SUBMENU_REQUEST,
  CREATE_NEW_SUBMENU_SUCCESS,
  CREATE_PERMISSION_ERR,
  CREATE_PERMISSION_REQUEST,
  CREATE_PERMISSION_SUCCESS,
  DELETE_MENU_ERR,
  DELETE_MENU_REQUEST,
  DELETE_MENU_SUCCESS,
  DELETE_PERMISSION_ERR,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  EDIT_MENU_ERR,
  EDIT_MENU_REQUEST,
  EDIT_MENU_SUCCESS,
  FIND_ALL_PERMISSION_DETAILS_ERR,
  FIND_ALL_PERMISSION_DETAILS_REQUEST,
  FIND_ALL_PERMISSION_DETAILS_SUCCESS,
  FIND_MENU_ERR,
  FIND_MENU_REQUEST,
  FIND_MENU_SUCCESS,
  FIND_PERMISSION_DETAILS_ERR,
  FIND_PERMISSION_DETAILS_REQUEST,
  FIND_PERMISSION_DETAILS_SUCCESS,
  FIND_SUBMENU_DETAILS_ERR,
  FIND_SUBMENU_DETAILS_REQUEST,
  FIND_SUBMENU_DETAILS_SUCCESS,
  FIND_SUB_MENU_ERR,
  FIND_SUB_MENU_REQUEST,
  FIND_SUB_MENU_SUCCESS,
  UPDATE_PERMISSION_ERR,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_SUBMENU_ERR,
  UPDATE_SUBMENU_REQUEST,
  UPDATE_SUBMENU_SUCCESS,
} from "../constants/developersConstant";

// create new menu
export const developerCreateMenuAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_NEW_MENU_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/developer/addMenu",
        formData,
        config
      );
      // body : {
      //   "name" : "Lead",
      //   "path" : "/lead"
      // }

      dispatch({ type: CREATE_NEW_MENU_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: CREATE_NEW_MENU_ERR, payload: error });
    }
  };

// update new menu
export const developerUpdateMenuAction =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: EDIT_MENU_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/developer/editSideMenu?id=${id}`,
        formData,
        config
      );

      // body : {
      //   "name" : "Lead",
      //   "path" : "/lead"
      // }

      dispatch({ type: EDIT_MENU_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: EDIT_MENU_ERR, payload: error });
    }
  };

// delete new menu
export const developerDeleteMenu = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_MENU_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.delete(
      `/api/admin/developer/deleteMenu?id=${id}`,
      config
    );
    dispatch({ type: DELETE_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_MENU_ERR, payload: error });
  }
};

// find all menu
export const developerFindAllMenu = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FIND_MENU_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.get("/api/admin/developer/findSideMenu", config);
    console.log(data, "datadatadatadatadatadatadatadata");
    dispatch({ type: FIND_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FIND_MENU_ERR, payload: error });
  }
};

// ******* submenu **********

// create new submenu
export const developerCreateSubMenuAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_NEW_SUBMENU_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/developer/addSubMenu",
        formData,
        config
      );
      // body : {
      //   "name" : "Lead",
      //   "path" : "/lead"
      // }

      dispatch({ type: CREATE_NEW_SUBMENU_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: CREATE_NEW_SUBMENU_ERR, payload: error });
    }
  };

// update new submenu
export const developerUpdateSubMenuAction =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_SUBMENU_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/developer/editSubMenu?id=${id}`,
        formData,
        config
      );

      // body : {
      //   "name" : "Lead",
      //   "path" : "/lead"
      // }

      dispatch({ type: UPDATE_SUBMENU_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: UPDATE_SUBMENU_ERR, payload: error });
    }
  };

// delete new submenu
export const developerDeleteSubMenu = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_MENU_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.delete(
      `/api/admin/developer/deleteMenu?id=${id}`,
      config
    );
    dispatch({ type: DELETE_MENU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_MENU_ERR, payload: error });
  }
};

// find all submenu
export const developerFindAllSubMenuDetailsAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_SUB_MENU_REQUEST });
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let { data } = await axios.get(
        "/api/admin/developer/findSubMenu",
        config
      );
      dispatch({ type: FIND_SUB_MENU_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FIND_SUB_MENU_ERR, payload: error });
    }
  };

export const developerFindSubMenuDetails =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_SUBMENU_DETAILS_REQUEST });
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let { data } = await axios.get(
        `/api/admin/developer/editFindSubMenu?id=${id}`,
        config
      );
      dispatch({ type: FIND_SUBMENU_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FIND_SUBMENU_DETAILS_ERR, payload: error });
    }
  };

// ******* permission **********

// create new Permission
export const developerCreatePermissionAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_PERMISSION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.post(
        "/api/admin/developer/addPermission",
        formData,
        config
      );

      dispatch({ type: CREATE_PERMISSION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: CREATE_PERMISSION_ERR, payload: error });
    }
  };

// update new Permission
export const developerUpdatePermissionAction =
  (formData, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_PERMISSION_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/developer/editPermission?id=${id}`,
        formData,
        config
      );

      dispatch({ type: UPDATE_PERMISSION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: UPDATE_PERMISSION_ERR, payload: error });
    }
  };

// delete new Permission
export const developerDeletePermission = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_PERMISSION_REQUEST });
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };
    let { data } = await axios.delete(
      `/api/admin/developer/deletePermission?id=${id}`,
      config
    );
    dispatch({ type: DELETE_PERMISSION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_PERMISSION_ERR, payload: error });
  }
};

// find Permission by id
export const developerFindPermissionByIdDetails =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_PERMISSION_DETAILS_REQUEST });
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let { data } = await axios.get(
        `/api/admin/developer/editPermissionFind?id=${id}`,
        config
      );
      dispatch({ type: FIND_PERMISSION_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FIND_PERMISSION_DETAILS_ERR, payload: error });
    }
  };

// find all Permission
export const developerFindAllPermissionDetails =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: FIND_ALL_PERMISSION_DETAILS_REQUEST });
      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      let { data } = await axios.get(
        `/api/admin/developer/findAllPermission`,
        config
      );
      dispatch({ type: FIND_ALL_PERMISSION_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FIND_ALL_PERMISSION_DETAILS_ERR, payload: error });
    }
  };
