import React, { useEffect, useState } from "react";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { MainContainer } from "../../../content/content.element";
import InputField, {
  SelectInputField,
} from "../../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../../customComponent/Buttons";
import { useNavigate } from "react-router-dom";
import TopicUploadComponent from "../../../customComponent/TopicUploadComponent";
import { ieltsCategoryFindAction } from "../../../../actions/IeltsActions/learningManadmentAction";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentExamtipsCreateAction } from "../../../../actions/IeltsActions/courseManagmentAction";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";

function ExamTipsAddNewForm({ toggle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [uploadedExamTips, setUploadedExamTips] = useState("");
  const [err, setErr] = useState({
    title: "",
    category: "",
    uploadedExamTips: "",
    all: "",
  });


  // Category find response

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("topicsAdd"));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  /////////////////////////////////////////////////////////////////////

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsCourseManagmentExamtipsCreateSuccess,
    ieltsCourseManagmentExamtipsCreateerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentExamTipsCreate;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentExamtipsCreateSuccess?.status == true
    ) {
      Swal.fire("Created", "Examtip created successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentExamtipsCreateSuccess?.status == false
    ) {
      Swal.fire("Error", "Examtip not created.", "warning");
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsCourseManagmentExamtipsCreateSuccess, successAlertMsgSuccess]);

  // Handle function for adding examTips

  const handleAddNewButton = () => {
    let isValid = true;
    const currentErr = { ...err };

    if (!category) {
      currentErr.category = "Enter category name";
      isValid = false;
    }
    if (!title) {
      currentErr.title = "Enter title";
      isValid = false;
    }
    if (!uploadedExamTips) {
      currentErr.uploadedExamTips = "Upload examTip pdf";
      isValid = false;
    }
    setErr(currentErr);
    if (!isValid) {
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("category", category);
    formData.append("uploadExamTips", uploadedExamTips);
    dispatch(ieltsCourseManagmentExamtipsCreateAction(formData));
    dispatch(successTrueMsgAction(true));
    setTitle("");
    setCategory("");
    setUploadedExamTips("");
  };

  // console.log(err?.category, "err.category err.category err.category ");

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Exam Tips" url="/IELTS" />

      <div style={{ marginTop: "30px" }}>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            "& .input-group": {
              mb: "44px",
              mt: "0",
            },
          }}
        >
          <InputField
            label="Title"
            handleChange={(e) => {
              {
                setTitle(e.target.value);
                setErr({ title: "" });
              }
            }}
            value={title}
            widthSize="546px"
            required
            paramses
            InputType="text"
            dispcriptionHeight
          />
          {err.title ? (
            <Typography
              sx={{
                mb: "44px",
                mt: "-34px",
                color: "red",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.title}
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            "& .MuiFormControl-root": {
              maxWidth: "558px",
            },
          }}
        >
          <SelectInputField
            mapValue={categoryList}
            label="Select Category"
            handleSelectChange={(e) => {
              setCategory(e.target.value);
              setErr({ category: "" });
            }}
            widthSize="558px"
            value={category}
            mapMethod="ieltsCategory"
          />
          {err.category ? (
            <Typography
              sx={{
                mb: "44px",
                // mt: "-34px",
                color: "red",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.category}
            </Typography>
          ) : (
            ""
          )}
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            mt: "32px",
          }}
        >
          {!uploadedExamTips ? (
            <Box>
              <Typography
                sx={{
                  color: "#05050F",
                  fontSize: "16px",
                  lineHeight: "20px",
                  mb: "23px",
                }}
              >
                Upload File
              </Typography>
              <FormLabel
                sx={{
                  boxSizing: "border-box",
                  width: "100%",
                  maxWidth: "558px",
                  height: "46px",
                  border: "1px solid #C4C4C4",
                  display: "flex",
                  alignItems: "center",
                  pl: "14px",
                  pr: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: formData.logoFile ? "#05050F" : "#ACB1C6",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  Choose file
                </Typography>
                <input
                  type="file"
                  hidden
                  name="logoFile"
                  multiple
                  onChange={(e) => {
                    setUploadedExamTips(e.target.files[0]);
                    setErr({ uploadedExamTips: "" });
                  }}
                  accept=".pdf"
                />
                <Box
                  sx={{
                    cursor: "pointer",
                    padding: "4px 18px",
                    background: "#EBF2FD",
                    color: "#1E88E5",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  Browse
                </Box>
              </FormLabel>
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4,234px)",
                padding: "30px 20px",
                background: "#F5F5F5",
                gap: "20px",
              }}
            >
              <Box>
                <TopicUploadComponent
                  type="pdf"
                  title={uploadedExamTips?.name}
                  handleClick={()=>setUploadedExamTips('')}
                />
              </Box>
            </Box>
          )}
        </Grid>
        {err.uploadedExamTips ? (
          <Typography
            sx={{
              mb: "44px",
              // mt: "-34px",
              color: "red",
              fontWeight: 400,
              fontSize: "12px",
            }}
          >
            {err.uploadedExamTips}
          </Typography>
        ) : (
          ""
        )}
        <Box
          sx={{
            mt: "44px",
          }}
        >
          <SubmitButton
            title="Add New"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={handleAddNewButton}
          />
        </Box>
      </div>
    </MainContainer>
  );
}

export default ExamTipsAddNewForm;
