import React from "react";
import { MainContainer } from "../content/content.element";
import BasicTabs from "../Tabs/TabsMain";
import { Box, Typography } from "@mui/material";
import WithDrawalMain from "./withDrawal/WithDrawalMain";
import ReferalSettings from "./ReferalSettings/ReferalSettings";
import ReferralsMain from "./referrals/referralsMain";

function ReferAndEarnMain(props) {
  return (
    <>
      <MainContainer active={props.toggle}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            mt: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "700",
              color: "#05050F",
            }}
          >
            Refer & Earn
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "24px",
              color: "#ACB1C6",
            }}
          >
            Home / Refer and Earn{" "}
          </Typography>
        </Box>

        <BasicTabs
          tab1={<ReferralsMain />}
          tab2={<WithDrawalMain />}
          tab3={<ReferalSettings />}
          tab1Name="Referrals"
          tab2Name="Withdrawal"
          tab3Name="Referral Settings"
        />
      </MainContainer>
    </>
  );
}

export default ReferAndEarnMain;
