import React, { useEffect, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { RiDeleteBin6Line } from "react-icons/ri";
import { Box } from "@mui/system";
import { SlOptionsVertical } from "react-icons/sl";
import AddCountry from "./AddCountry";
import { useDispatch, useSelector } from "react-redux";
import {
  CountryMasterDeleteAction,
  findcountryMasterAddAction,
} from "../../../../actions/countryMasterAction";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import EditCountry from "./EditCountry";
import { Skeleton, Typography } from "@mui/material";
import { MasterTable } from "../MasterTable";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";
import {
  COUNTRY_MASTER_ADD_ERR,
  COUNTRY_MASTER_ADD_SUCCESS,
} from "../../../../constants/countryMasterConstant";

const ITEM_HEIGHT = 48;
function CountryMaster({
  addCountryFormOpen,
  formStatusHandler,
  subMenu,
  searchHandle,
}) {
  const [open, setOpen] = useState(addCountryFormOpen);

  const dispatch = useDispatch();

  const [countryData, setCountryData] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [tableId, setTableId] = useState("");

  // selectors
  let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let { countryEditedSuccess } = useSelector((state) => {
    return state.countryMasterEdit;
  });

  let { countryAddSuccess, countryAddErr } = useSelector((state) => {
    return state.countryMasterAdd;
  });

  let { countryDeleteSuccess } = useSelector((state) => {
    return state.CountryMasterDelete;
  });

  let { countryEditFindSuccess } = useSelector((state) => {
    return state.editCountryForm;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (addCountryFormOpen) {
      setOpen(addCountryFormOpen);
    }
  }, [addCountryFormOpen]);

  useEffect(() => {
    if (searchHandle !== "" && searchHandle !== " ") {
      console.log(searchHandle,"searchHandlesearchHandle")
        setCountryData(
          countryFindSuccess?.filter((item) => {
            const CountryName = item.countryName
              ? item.countryName.split("(")[0].toLowerCase()
              : "";
            return CountryName.includes(searchHandle.toLowerCase());
          })
        );
    } else {
      if (countryFindSuccess) {
        let reversedData = countryFindSuccess?.reverse();
        setCountryData(reversedData);
      }
    }
  }, [searchHandle]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [
    dispatch,
    countryAddSuccess,
    countryEditedSuccess,
    countryEditFindSuccess,
  ]);

  useEffect(() => {
    if (countryFindSuccess) {
      let reversedData = countryFindSuccess.reverse();
      setCountryData(reversedData);
    }
  }, [countryFindSuccess, countryDeleteSuccess, countryEditedSuccess]);

  useEffect(() => {
    if (countryAddSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `created a new country`, "success").then((value) =>
        dispatch({ type: COUNTRY_MASTER_ADD_SUCCESS, payload: false })
      );
      dispatch(successTrueMsgAction(false));
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgSuccess, countryAddSuccess, dispatch]);

  useEffect(() => {
    if (countryAddErr && successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `<p>${countryAddErr}</p>`,
      }).then((value) =>
        dispatch({ type: COUNTRY_MASTER_ADD_ERR, payload: false })
      );

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, countryAddErr, dispatch]);

  const addMasterCategryHandler = (category, status) => {
    setOpen(status);
    formStatusHandler(status);
  };

  // edit form
  const editMasterCategryHandler = (category, status) => {
    setEditForm(status);
  };

  // navigate to Edit page
  const rollEditHandler = (id) => {
    setTableId(id);
    setEditForm(true);
  };

  // delete action
  const RoledeleteHandler = (id) => {
    setAnchorEl(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(CountryMasterDeleteAction(tableId));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  // action button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTableId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "CountryId",
      field: "CountryId",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Code",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Country",
      field: "country",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Phone Code",
      field: "phoneCode",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Updated By",
      field: "updatedby",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Updated Date",
      field: "updatedDate",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const data = countryData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    countryID: data.countryUniqueId,
    column1: data.countryCode,
    column2: data.countryName,
    column3: data.countryPhoneCode,
    column4: data.updatedBy,
    column5: data.createdAt,
    action: (
      <>
        <SlOptionsVertical
          aria-label="more"
          id="long-button"
          aria-controls={openAction ? "long-menu" : undefined}
          aria-expanded={openAction ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => handleClick(e, data.id)}
          style={{ marginLeft: "10px", color: "#F80B7A" }}
        >
          <MoreVertIcon />
        </SlOptionsVertical>
      </>
    ),
  }));

  return (
    <>
      {/*TABLE ACTION MENU START*/}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openAction}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            borderColor: "black",
            marginLeft: "81px",
            backgroundColor: "white",
          },
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <TbEdit
              onClick={() => rollEditHandler(tableId)}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => RoledeleteHandler(tableId)}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>
        </Box>
      </Menu>
      {/*TABLE ACTION MENU END*/}

      {/* edit component */}
      {editForm && (
        <EditCountry
          status={editForm}
          editMasterCategryHandler={editMasterCategryHandler}
          id={tableId}
        />
      )}
      {/* popup */}
      {open && (
        <AddCountry
          status={open}
          addMasterCategryHandler={addMasterCategryHandler}
        />
      )}

      <Box sx={{ mt: 1 }}>
        {countryFindLoading ? (
          <>
            <Typography variant="h1">
              <Skeleton animation={false} />
            </Typography>

            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
          </>
        ) : (
          <MasterTable
            data={data}
            columns={columns}
            component="countryMaster"
            rollEditHandler={rollEditHandler}
            subMenu={subMenu}
          />
        )}
      </Box>
    </>
  );
}

export default CountryMaster;
