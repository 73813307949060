import React, { useEffect, useState } from "react";
import BasicTabs from "../../../../Tabs/TabsMain";
import OnlineTab from "./online/OnlineTab";
import OfflineTab from "./offline/OfflineTab";
import { useSelector } from "react-redux";

function CourseCategoryMain({ switches }) {
  let { ieltsCourseManagmentCourseCreateSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });

  const [formData, setFormData] = useState();

  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateSuccess) {
      setFormData(ieltsCourseManagmentCourseCreateSuccess);
    }
  }, [ieltsCourseManagmentCourseCreateSuccess]);


  return (
    <div>
      {(formData?.isOnline || formData?.isOffline) && (
        <BasicTabs
          tab1Name={formData ? formData.isOnline && "Online" : null}
          tab2Name={formData ? formData.isOffline && "Offline" : null}
          // selected={true}
          tab1={
            formData?.isOnline ? (
              <OnlineTab selectedTab={switches} />
            ) : formData?.isOffline ? (
              <OfflineTab />
            ) : null
          }
          tab2={formData?.isOffline && <OfflineTab />}
        />
      )}
    </div>
  );
}

export default CourseCategoryMain;
