import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Typography } from "@mui/material";

import Select from "react-select";
import { countries } from "countries-list";

import { City, State } from "country-state-city";

import { useDispatch, useSelector } from "react-redux";
import {
  cityMasterEditAction,
  findEditCityFormAction,
} from "../../../../actions/cityManagmentAction";
import { findEditStateFormAction } from "../../../../actions/StateMasterAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";

function EditCity({ status, editMasterCategryHandler, id }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cityId, setCityId] = useState("");
  const [stateId, setStateId] = useState("");

  const [countryError, setCountryError] = useState(false);
  const [selectedCity, setSelectedCity] = useState({});
  const [city, setCity] = useState([]);

  let { stateFindEditLoading, stateFindEditSuccess, stateFindEditErr } =
    useSelector((state) => {
      return state.findEditStateForm;
    });

  let { cityFindEditSuccess } = useSelector((state) => {
    return state.findEditCityForm;
  });

  // console.log(cityFindEditSuccess, "cityFindEditSuccesscityFindEditSuccess");
  // console.log(stateFindEditSuccess, "stateFindEditSuccessstateFindEditSuccess");

  useEffect(() => {
    dispatch(findEditCityFormAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (cityFindEditSuccess) {
      setCityId(cityFindEditSuccess.id);
      setStateId(cityFindEditSuccess.stateId);

      dispatch(findEditStateFormAction(cityFindEditSuccess.stateId));

      //   const statesCity = City.getCitiesOfState(cityFindEditSuccess.countryCode,
      //     selectedOption.value.isoCode);

      //   setCity(statesCity);

      setSelectedCountry(cityFindEditSuccess);
    }
  }, [cityFindEditSuccess, dispatch]);

  useEffect(() => {
    if (cityFindEditSuccess && stateFindEditSuccess) {
      setCityId(cityFindEditSuccess.id);

      const statesCity = City.getCitiesOfState(
        stateFindEditSuccess.countryCode,
        stateFindEditSuccess.isoCode
      );
      let selectedState = statesCity.filter(
        (c) => c.name === cityFindEditSuccess.cityName
      );
      let filteredCity = selectedState.map((data) => ({
        value: data.name,
        label: data.name,
      }));

      setCity(statesCity);
      setSelectedCity(filteredCity);

      setSelectedCountry(cityFindEditSuccess);
    }
  }, [cityFindEditSuccess, stateFindEditSuccess]);

  const handleClose = () => {
    setOpen(false);
    editMasterCategryHandler(status, false);
  };

  const handleSubmit = () => {
    if (selectedCity || selectedCity !== null) {
      dispatch(cityMasterEditAction(selectedCity, cityId, stateId));
      editMasterCategryHandler(status, false);
      setSelectedCountry(null);
      setErrorMsg(false);
    } else {
      setErrorMsg(true);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit City</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose Country
                </Typography>
              )}
              {countryError && (
                <Typography align="center" color="error">
                  Please Choose Country You Added
                </Typography>
              )}
              <TextField fullWidth value={selectedCountry?.stateName} />

              <h3>Select City:</h3>
              <Select
                options={city.map((data) => ({
                  value: data.name,
                  label: data.name,
                }))}
                value={selectedCity}
                onChange={(selectedOption) => {
                  console.log(selectedOption.value, "selectedOption");
                  setSelectedCity(selectedOption);
                }}
                isDisabled={!selectedCountry}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button> */}
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditCity;
