export const STATE_MASTER_ADD_REQUEST = "STATE_MASTER_ADD_REQUEST";
export const STATE_MASTER_ADD_SUCCESS = "STATE_MASTER_ADD_SUCCESS";
export const STATE_MASTER_ADD_ERR = "STATE_MASTER_ADD_ERR";


export const STATE_MASTER_FIND_REQUEST = "STATE_MASTER_FIND_REQUEST";
export const STATE_MASTER_FIND_SUCCESS = "STATE_MASTER_FIND_SUCCESS";
export const STATE_MASTER_FIND_ERR = "STATE_MASTER_FIND_ERR";

export const STATE_MASTER_DELETE_REQUEST = "STATE_MASTER_DELETE_REQUEST";
export const STATE_MASTER_DELETE_SUCCESS = "STATE_MASTER_DELETE_SUCCESS";
export const STATE_MASTER_DELETE_ERR = "STATE_MASTER_DELETE_ERR";

export const STATE_MASTER_EDIT_FIND_REQUEST = "STATE_MASTER_EDIT_FIND_REQUEST";
export const STATE_MASTER_EDIT_FIND_SUCCESS = "STATE_MASTER_EDIT_FIND_SUCCESS";
export const STATE_MASTER_EDIT_FIND_ERR = "STATE_MASTER_EDIT_FIND_ERR";

export const STATE_MASTER_EDIT_REQUEST = "STATE_MASTER_EDIT_REQUEST";
export const STATE_MASTER_EDIT_SUCCESS = "STATE_MASTER_EDIT_SUCCESS";
export const STATE_MASTER_EDIT_ERR = "STATE_MASTER_EDIT_ERR";
