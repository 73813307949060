// referal settings constants

// create referal settings
export const CREATE_REFERAL_SETTINGS_REQUEST =
  "CREATE_REFERAL_SETTINGS_REQUEST";
export const CREATE_REFERAL_SETTINGS_SUCCESS =
  "CREATE_REFERAL_SETTINGS_SUCCESS";
export const CREATE_REFERAL_SETTINGS_ERR = "CREATE_REFERAL_SETTINGS_ERR";

// list referal settings

export const GET_REFERAL_SETTINGS_REQUEST = "GET_REFERAL_SETTINGS_REQUEST";
export const GET_REFERAL_SETTINGS_SUCCESS = "GET_REFERAL_SETTINGS_SUCCESS";
export const GET_REFERAL_SETTINGS_ERR = "GET_REFERAL_SETTINGS_ERR";

// referal list constants

export const REFERRALS_FIND_REQUEST = "REFERRALS_FIND_REQUEST";
export const REFERRALS_FIND_SUCCESS = "REFERRALS_FIND_SUCCESS";
export const REFERRALS_FIND_ERR = "REFERRALS_FIND_ERR";

// referal statuschange constants

export const REFERRALS_STATUSCHANGE_REQUEST = "REFERRALS_STATUSCHANGE_REQUEST";
export const REFERRALS_STATUSCHANGE_SUCCESS = "REFERRALS_STATUSCHANGE_SUCCESS";
export const REFERRALS_STATUSCHANGE_ERR = "REFERRALS_STATUSCHANGE_ERR";


// create faq 
export const CREATE_REFERAL_FAQ_REQUEST = "CREATE_REFERAL_FAQ_REQUEST";
export const CREATE_REFERAL_FAQ_SUCCESS = "CREATE_REFERAL_FAQ_SUCCESS";
export const CREATE_REFERAL_FAQ_ERR = "CREATE_REFERAL_FAQ_ERR";

// get faq 
export const GET_REFERAL_FAQ_REQUEST = "GET_REFERAL_FAQ_REQUEST";
export const GET_REFERAL_FAQ_SUCCESS = "GET_REFERAL_FAQ_SUCCESS";
export const GET_REFERAL_FAQ_ERR = "GET_REFERAL_FAQ_ERR";

// update referal faq
export const UPDATE_REFERAL_FAQ_REQUEST = "UPDATE_REFERAL_FAQ_REQUEST";
export const UPDATE_REFERAL_FAQ_SUCCESS = "UPDATE_REFERAL_FAQ_SUCCESS";
export const UPDATE_REFERAL_FAQ_ERR = "UPDATE_REFERAL_FAQ_ERR";

// delete referal faq
export const DELETE_REFERAL_FAQ_REQUEST = "DELETE_REFERAL_FAQ_REQUEST";
export const DELETE_REFERAL_FAQ_SUCCESS = "DELETE_REFERAL_FAQ_SUCCESS";
export const DELETE_REFERAL_FAQ_ERR = "DELETE_REFERAL_FAQ_ERR";


// referal withdraw
export const WITHDRAW_REFERAL_REQUEST = "WITHDRAW_REFERAL_REQUEST";
export const WITHDRAW_REFERAL_SUCCESS = "WITHDRAW_REFERAL_SUCCESS";
export const WITHDRAW_REFERAL_ERR = "WITHDRAW_REFERAL_ERR";

// referal withdraw status change
export const WITHDRAW_STATUS_CHANGE_REFERAL_REQUEST =
  "WITHDRAW_STATUS_CHANGE_REFERAL_REQUEST";
export const WITHDRAW_STATUS_CHANGE_REFERAL_SUCCESS =
  "WITHDRAW_STATUS_CHANGE_REFERAL_SUCCESS";
export const WITHDRAW_STATUS_CHANGE_REFERAL_ERR =
  "WITHDRAW_STATUS_CHANGE_REFERAL_ERR";

// referal withdraw details
export const REFERAL_WITHDRAW_DETAILS_REQUEST =
  "REFERAL_WITHDRAW_DETAILS_REQUEST";
export const REFERAL_WITHDRAW_DETAILS_SUCCESS =
  "REFERAL_WITHDRAW_DETAILS_SUCCESS";
export const REFERAL_WITHDRAW_DETAILS_ERR =
  "REFERAL_WITHDRAW_DETAILS_ERR";
