import { Box } from '@mui/material'
import React from 'react'
import BasicTabs from '../Tabs/TabsMain'
import { MainContainer } from '../content/content.element';
import MenuTable from './menu/MenuTable';
import SubMenuTable from './submenu/SubMenuTable';
import PermissionTable from './permissions/PermissionTable';

function DeveloerRoleMenuMain(props) {
  return (
    <MainContainer active={props.toggle}>
      <BasicTabs
        tab1Name="Menu"
        tab2Name="Submenu"
        tab3Name="Permissions"
        tab1={<MenuTable />}
        tab2={<SubMenuTable/>}
        tab3={<PermissionTable />}
      />
    </MainContainer>
  );
}

export default DeveloerRoleMenuMain