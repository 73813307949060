// Location Create Reducer

import { LOCATION_CREATE_ERR, LOCATION_CREATE_REQUEST, LOCATION_CREATE_SUCCESS, LOCATION_DELETE_ERR, LOCATION_DELETE_REQUEST, LOCATION_DELETE_SUCCESS, LOCATION_FIND_ERR, LOCATION_FIND_REQUEST, LOCATION_FIND_SUCCESS, LOCATION_FINDONE_ERR, LOCATION_FINDONE_REQUEST, LOCATION_FINDONE_SUCCESS, LOCATION_UPDATE_ERR, LOCATION_UPDATE_REQUEST, LOCATION_UPDATE_SUCCESS } from "../constants/locationConstants";


export const LocationCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_CREATE_REQUEST:
        return {
          ...state,
          locationCreateLoading: true,
        };
      case LOCATION_CREATE_SUCCESS:
        return {
          ...state,
          locationCreateLoading: false,
          locationCreateSuccess: action.payload,
        };
      case LOCATION_CREATE_ERR:
        return {
          ...state,
          locationCreateLoading: false,
          locationCreateErr: action.payload,
        };
      default:
        return state;
    }
  };


  export const LocationFindReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_FIND_REQUEST:
        return {
          ...state,
          locationFindLoading: true,
        };
      case LOCATION_FIND_SUCCESS:
        return {
          ...state,
          locationFindLoading: false,
          locationFindSuccess: action.payload,
        };
      case LOCATION_FIND_ERR:
        return {
          ...state,
          locationFindLoading: false,
          locationFindErr: action.payload,
        };
      default:
        return state;
    }
  };

  export const LocationFindOneReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_FINDONE_REQUEST:
        return {
          ...state,
          locationFindOneLoading: true,
        };
      case LOCATION_FINDONE_SUCCESS:
        return {
          ...state,
          locationFindOneLoading: false,
          locationFindOneSuccess: action.payload,
        };
      case LOCATION_FINDONE_ERR:
        return {
          ...state,
          locationFindOneLoading: false,
          locationFindOneErr: action.payload,
        };
      default:
        return state;
    }
  };


  export const LocationUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_UPDATE_REQUEST:
        return {
          ...state,
          locationUpdateLoading: true,
        };
      case LOCATION_UPDATE_SUCCESS:
        return {
          ...state,
          locationUpdateLoading: false,
          locationUpdateSuccess: action.payload,
        };
      case LOCATION_UPDATE_ERR:
        return {
          ...state,
          locationUpdateLoading: false,
          locationUpdateErr: action.payload,
        };
      default:
        return state;
    }
  };


  export const LocationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LOCATION_DELETE_REQUEST:
        return {
          ...state,
          locationDeleteLoading: true,
        };
      case LOCATION_DELETE_SUCCESS:
        return {
          ...state,
          locationDeleteLoading: false,
          locationDeleteSuccess: action.payload,
        };
      case LOCATION_DELETE_ERR:
        return {
          ...state,
          locationDeleteLoading: false,
          locationDeleteErr: action.payload,
        };
      default:
        return state;
    }
  };