import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Checkbox,
  Box,
  MenuItem,
  Pagination,
  Typography,
  Rating,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { serviceRatingAction } from "../../../actions/ratingActions";
import EditNotification from "./EditNotification";
import { Navigate, useNavigate } from "react-router-dom";
import {
  deleteNotificationAction,
  partnerNotificationFindAllAction,
  partnerNotificationFindOneAction,
} from "../../../actions/partnerAction/partnerAction";
import { parseISO } from "date-fns";
import { format } from "date-fns";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

function NotificationTable({ subMenu }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { partnerNotificationFindAllSuccess } = useSelector(
    (state) => state.partnerNotificationFindAll
  );

  let { partnerNotificationCreateSuccess } = useSelector((state) => {
    return state.partnerNotificationCreate;
  });

  let { partnerNotificationFindOneSuccess } = useSelector(
    (state) => state.partnerNotificationFindOne
  );

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { partnerNotificationUpdateSuccess } = useSelector((state) => {
    return state.partnerNotificationUpdate;
  });

  let { partnerNotificationDeleteSuccess, partnerNotificationDeleteError } =
    useSelector((state) => state.partnerNotificationDelete);

  useEffect(() => {
    dispatch(partnerNotificationFindAllAction());
  }, [
    dispatch,
    partnerNotificationDeleteSuccess,
    partnerNotificationCreateSuccess,
    partnerNotificationUpdateSuccess,
  ]);

  useEffect(() => {
    if (partnerNotificationFindAllSuccess) {
      let rows = partnerNotificationFindAllSuccess.data;
      let reversedRow = [...rows].reverse();
      setData(reversedRow);
    }
  }, [partnerNotificationFindAllSuccess]);

  useEffect(() => {
    if (partnerNotificationDeleteSuccess) {
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerNotificationDeleteSuccess]);

  useEffect(() => {
    if (partnerNotificationDeleteSuccess && successAlertMsgSuccess) {
      Swal.fire("Added!", "Notification deleted successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerNotificationDeleteSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (partnerNotificationDeleteError && successAlertMsgErr) {
      Swal.fire("Something Went Wrong!", "", "error");
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerNotificationDeleteError, successAlertMsgErr]);

  useEffect(() => {
    if (partnerNotificationUpdateSuccess && successAlertMsgSuccess) {
      Swal.fire("Updated!", "Notification updated successfully.", "success");
      setOpen(false);
      setActionButtonShow(null);
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerNotificationUpdateSuccess, successAlertMsgSuccess]);

  const columns = ["Sl No", "Subject", "sent to", "Date"];

  const [data, setData] = useState([]);
  const [tableId, setTableId] = useState(null);
  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);

  const { isUpdatePrevilage, isDeletePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const openActionButton = Boolean(actionButtonShow);

  useEffect(() => {
    if (partnerNotificationFindOneSuccess && tableId) {
      setRowData(partnerNotificationFindOneSuccess.data);
      setOpen(true);
    }
  }, [partnerNotificationFindOneSuccess]);

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  const EditHandler = (id) => {
    if (isUpdatePrevilage(subMenu)) {
      dispatch(partnerNotificationFindOneAction(id));
    } else {
      setOpenAlert(true);
    }
  };

  const DeleteHandler = (id) => {
    if (isDeletePrevilage(subMenu)) {
      setActionButtonShow(null);
      dispatch(deleteNotificationAction(id));
      dispatch(successTrueMsgAction(true));
    } else {
      setOpenAlert(true);
    }
  };

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [rowData, setRowData] = useState({});

  return (
    <>
      <Box
        sx={{
          mt: "10px",
        }}
      >
        {/* <Typography
        sx={{
          backgroundColor: "#fff",
          padding: "5px",
          paddingRight: "0px",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          fontSize: "16px",
          fontWeight: "400",
          mb: "10px",
        }}
      >
        Showing{" "}
        <Typography
          component={"span"}
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingLeft: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            // lineHeight: "24px",
            fontWeight: "700",
          }}
        >
          {data.length} Result
        </Typography>
      </Typography> */}
        {data?.length > 0 ? (
          <>
            <Box
              sx={{
                overflowX: "scroll",
                mt: "20px",
              }}
            >
              <Table
                className="masterTable"
                sx={{
                  minWidth: "1000px",
                }}
              >
                <TableHead>
                  <TableRow className="msterTableCell">
                    <TableCell>
                      <Checkbox
                        //   checked={selectedRows.length === sortedData.length}
                        //   onChange={handleSelectAllRows}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    {columns?.map((column) => {
                      return (
                        <TableCell key={column}>
                          <TableSortLabel
                            sx={{
                              fontWeight: "700",
                              textTransform: "uppercase",
                              fontSize: "13px",
                            }}
                          >
                            {column}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    ?.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => (
                      <TableRow className="tableRow" key={item.id}>
                        <TableCell>
                          <Checkbox
                            // checked={selectedRows.includes(item.id)}
                            // onChange={() => handleSelectRow(item.id)}
                            style={{ color: "#f81b82" }}
                          />
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.subject}</TableCell>
                        <TableCell>{item.targetedUser}</TableCell>
                        <TableCell>
                          {" "}
                          {item.createdAt
                            ? format(parseISO(item.createdAt), "dd-MM-yyyy")
                            : null}
                        </TableCell>
                        <TableCell>
                          <SlOptionsVertical
                            id="demo-positioned-button"
                            aria-controls={
                              openActionButton
                                ? "demo-positioned-menu"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              openActionButton ? "true" : undefined
                            }
                            onClick={(e) => {
                              handleClickAction(e, item.id);
                            }}
                            variant="outlined"
                            style={{
                              marginLeft: "10px",
                              color: "#F80B7A",
                              cursor: "pointer",
                            }}
                          />
                        </TableCell>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={actionButtonShow}
                          open={openActionButton}
                          onClose={handleCloseAction}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            borderColor: "black",
                            borderRadius: "24px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <MenuItem>
                              <TbEdit
                                onClick={() => EditHandler(tableId)}
                                style={{
                                  fontSize: "lg",
                                  width: "50px",
                                  height: "23px",
                                }}
                              />
                            </MenuItem>

                            <MenuItem>
                              <AiOutlineEye
                                style={{
                                  fontSize: "lg",
                                  width: "20px",
                                  height: "19px",
                                }}
                                onClick={() =>
                                  navigate(`/settings/notification/${tableId}`)
                                }
                              />
                            </MenuItem>

                            <MenuItem>
                              <RiDeleteBin6Line
                                onClick={() => DeleteHandler(tableId)}
                                style={{
                                  fontSize: "lg",
                                  width: "50px",
                                  height: "23px",
                                }}
                              />
                            </MenuItem>
                          </Box>
                        </Menu>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter />
              </Table>
            </Box>
            <Pagination
              sx={{
                mt: "28px",
                "& .Mui-selected": {
                  backgroundColor: "#f81b82 !important",
                  color: "#fff",
                },
                "& .MuiPagination-ul": {
                  justifyContent: "end",
                },
              }}
              count={Math.ceil(data?.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </>
        ) : (
          <TableNoItemComponent />
        )}
      </Box>
      {open && (
        <EditNotification
          open={open}
          handleClose={() => setOpen(false)}
          data={rowData}
        />
      )}
      {/* {/*TABLE ACTION MENU END */}
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default NotificationTable;
