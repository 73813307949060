import { useEffect, useState } from "react";

import Swal from "sweetalert2";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import { allRoleFindAction } from "../../../actions/roleMangmentAction";
import { Typography } from "@material-ui/core";
import { Alert, AlertTitle, Skeleton } from "@mui/material";
import ColumnVisible from "../../extraComponents/ColumnVisible";
import { TableData } from "../../Table/Table";
import DownloadPdf from "../../extraComponents/DownloadPdf";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import DownloadExcel from "../../extraComponents/DownloadExcel.js";
import { successTrueMsgAction } from "../../../actions/globalAction.js";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant.js";

function RoleMangment() {
  const dispatch = useDispatch();

  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);

  // selectors

  const {
    allRoleFindLoading,
    allRoleFindSuccess,
    allRoleFindErr,
  } = useSelector((state) => {
    return state.allRoleFind;
  });

  // Selector for BulkUpload

  // const { rollBulkUploadSuccess, rollBulkUploadErr } = useSelector((state) => {
  //   return state.rollBulkUpload;
  // });

  const { rollDeleteSuccess } = useSelector((state) => {
    return state.roleDelete;
  });
  // theme Provider
  let theme = createTheme({
    palette: {
      primary: {
        main: "#0052cc",
      },
      secondary: {
        main: "#edf2ff",
      },
    },
  });

  theme = createTheme(theme, {
    palette: {
      info: {
        main: theme.palette.secondary.main,
      },
    },
  });

  const [roleData, setRoleData] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(allRoleFindAction());
  }, [dispatch, rollDeleteSuccess]);

  // useEffect(() => {
  //   if (allRoleFindErr) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Something went wrong!",
  //       footer: `<p>${allRoleFindErr}</p>`,
  //     });
  //   }
  // }, [allRoleFindErr]);

  // SuccessPopUp for BulkUpload

  // const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
  //   (state) => {
  //     return state.successTrueMsg;
  //   }
  // );

  // useEffect(() => {
  //   if (rollBulkUploadSuccess && successAlertMsgSuccess) {
  //     Swal.fire("Uploaded!", "Role uploaded successFully.", "success");
  //     dispatch(successTrueMsgAction(false));
  //   }
  //   if (rollBulkUploadErr && successAlertMsgErr) {
  //     Swal.fire("Failed!", "Role uploaded Failed.", "error");
  //     dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
  //   }
  // }, [rollBulkUploadSuccess]);

  // custom Hooks
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  // columnVisibility
  const [showRollNameColumn, setShowRollNameColumn] = useState(false);
  const [showDescriptionNameColumn, setShowDescriptionNameColumn] = useState(
    false
  );
  const [showUpdatebyNameColumn, setShowUpdatebyNameColumn] = useState(false);
  const [showLastUpdatebyNameColumn, setShowLastUpdatebyNameColumn] = useState(
    false
  );

  const [columnName, setColumnName] = useState("");

  const toggleNameColumn = (status, column) => {
    if (column === "roleName") {
      setColumnName(column);
      setShowRollNameColumn(status);
    }
    if (column === "Description") {
      setColumnName(column);
      setShowDescriptionNameColumn(status);
    }
    if (column === "updateby") {
      setColumnName(column);
      setShowUpdatebyNameColumn(status);
    }
    if (column === "lastupdateby") {
      setColumnName(column);
      setShowLastUpdatebyNameColumn(status);
    }
  };

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedRollNameColumn = localStorage.getItem("roleName");
    const storedDescriptionNameColumn = localStorage.getItem("Description");
    const storedUpdatedbyColumn = localStorage.getItem("updateby");
    const storedLastUpdatebyColumn = localStorage.getItem("lastupdateby");

    if (storedRollNameColumn !== null) {
      setColumnName("roleName");
      setShowRollNameColumn(JSON.parse(storedRollNameColumn));
    }
    if (storedDescriptionNameColumn !== null) {
      setColumnName("Description");
      setShowDescriptionNameColumn(JSON.parse(storedDescriptionNameColumn));
    }
    if (storedUpdatedbyColumn !== null) {
      setColumnName("updateby");
      setShowUpdatebyNameColumn(JSON.parse(storedUpdatedbyColumn));
    }
    if (storedLastUpdatebyColumn !== null) {
      setColumnName("lastupdateby");
      setShowLastUpdatebyNameColumn(JSON.parse(storedLastUpdatebyColumn));
    }
  }, []);

  useEffect(() => {
    // Save column visibility state to localStorage
    localStorage.setItem("roleName", JSON.stringify(showRollNameColumn));
    localStorage.setItem(
      "Description",
      JSON.stringify(showDescriptionNameColumn)
    );
    localStorage.setItem("updateby", JSON.stringify(showUpdatebyNameColumn));
    localStorage.setItem(
      "lastupdateby",
      JSON.stringify(showLastUpdatebyNameColumn)
    );
  }, [
    showRollNameColumn,
    showDescriptionNameColumn,
    showUpdatebyNameColumn,
    showLastUpdatebyNameColumn,
    columnName,
  ]);

  useEffect(() => {
    if (allRoleFindSuccess) {
      setRoleData(allRoleFindSuccess);
    }
  }, [allRoleFindSuccess, rollDeleteSuccess]);

  const columns = [
    {
      field: "slno",
      title: "SL NO",
    },
    {
      field: "roleName",
      title: "Role Name",
      hidden: showRollNameColumn,
    },
    {
      field: "description",
      title: "Description",
      hidden: showDescriptionNameColumn,
    },
    {
      field: "RoleID",
      title: "RoleID",
      hidden: showDescriptionNameColumn,
    },
    {
      field: "updateby",
      title: "Updated By",
      hidden: showUpdatebyNameColumn,
    },
    {
      field: "lastupdateby",
      title: "Last Updated By",
      hidden: showLastUpdatebyNameColumn,
    },
    {
      field: "action",
      title: "",
    },
  ];

  useEffect(() => {
    if (roleData) {
      let array = roleData.reverse();
      setData(
        array.map((data, index) => ({
          id: data.id,
          sl: index + 1,
          name: data.roleName,
          description: data.roleDescription,
          bulkID: data?.bulkUploadId,
          updatedby: "admin",
          lastupdateby: data.createdAt,
        }))
      );
    }
  }, [roleData]);

  console.log(roleData, "roleDataroleData");

  const searchHandleChange = (e) => {
    if (e.target.value !== "" && e.target.value !== " ") {
      setData(
        data?.filter((item) => {
          return (
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.updatedby
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.lastupdateby
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        })
      );
    } else {
      setData(
        roleData.map((data, index) => ({
          id: data.id,
          sl: index + 1,
          name: data.roleName,
          description: data.roleDescription,
          updatedby: "admin",
          lastupdateby: data.createdAt,
        }))
      );
    }
    // console.log(obj, "objobjobjobjobj");
  };

  const pdfDownloadClick = () => {
    DownloadPdf(columns, data, "Role_Managment", "Role Managment");
  };

  const excelDownloadClick = () => {
    DownloadExcel(columns, data, "Role_Managment");
  };

  if (!hasPrivilege("role managment") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("payslip"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <ColumnVisible
          columnVisibleHandler={toggleNameColumn}
          data={data}
          component="role"
          searchHandleChange={searchHandleChange}
          pdfDownloadClick={pdfDownloadClick}
          excelDownloadClick={excelDownloadClick}
          roleRow1={showRollNameColumn}
          roleRow2={showDescriptionNameColumn}
          roleRow3={showUpdatebyNameColumn}
          roleRow4={showLastUpdatebyNameColumn}
          subMenu="role managment"
        />

        {allRoleFindLoading ? (
          <>
            {/* <Skeleton /> */}
            <Typography variant="h1">
              <Skeleton animation={false} />
            </Typography>

            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
          </>
        ) : (
          <TableData
            data={data}
            columns={columns}
            allRoleFindSuccess={allRoleFindSuccess}
            showRollNameColumn={showRollNameColumn}
            showDescriptionNameColumn={showDescriptionNameColumn}
            showUpdatebyNameColumn={showUpdatebyNameColumn}
            showLastUpdatebyNameColumn={showLastUpdatebyNameColumn}
            component="role"
            showUrl=""
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
            subMenu="role managment"
          />
        )}
      </ThemeProvider>
    </>
  );
}

export default RoleMangment;
