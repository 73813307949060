import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Reorder from "./Reorder";

function TaskTable({ rows }) {
  return (
    <Box
      sx={{
        overflowX: "scroll",
        maxWidth: "100%",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "& table": {
          minWidth: "1200px",
        },
      }}
    >
      <Table>
        <TableHead
          sx={{
            background: "#fff",
            "& th": {
              textTransform: "uppercase",
              fontWeight: "600",
              // fontFamily: "Inter",
              color: "#666E7D",
            },
          }}
        >
          <TableRow>
            <TableCell>
              SUBJECT <Reorder />
            </TableCell>
            <TableCell>
              DUE DATE <Reorder />
            </TableCell>
            <TableCell>
              STATUS <Reorder />
            </TableCell>
            <TableCell>
              PRIORITY <Reorder />
            </TableCell>
            <TableCell>
              RELATED TO <Reorder />
            </TableCell>
            <TableCell>
              COUNSELLOR <Reorder />
            </TableCell>
            <TableCell>
              TASK OWNER <Reorder />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            background: "#fff",
            border: "1px solid rgba(220, 223, 227, 0.50)",
          }}
        >
          {rows.map((row, i) => (
            <TableRow
              key={i}
              sx={{
                "& .MuiTableCell-root": {
                  borderBottom: "1px solid rgba(220, 223, 227, 0.50)",
                },
              }}
            >
              <TableCell sx={{ color: "blue" }}>{row.subject}</TableCell>
              <TableCell>{row.dueDate}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.priority}</TableCell>
              <TableCell sx={{ color: "blue" }}>{row.related}</TableCell>
              <TableCell>{row.counsellor}</TableCell>
              <TableCell>{row.taskOwner}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default TaskTable;
