import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ieltsPurchasedStudentCourseFindByIdAction } from "../../../actions/IeltsActions/purchasedStudentActions";
import { useParams } from "react-router-dom";

function IeltsPurchasedCourseView() {
  let { ieltsPurchasedStudentCourseFindByIdSuccess } = useSelector((state) => {
    return state.ieltsPurchasedStudentCourseFindById;
  });

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (ieltsPurchasedStudentCourseFindByIdSuccess){
      console.log(ieltsPurchasedStudentCourseFindByIdSuccess);
      setData(ieltsPurchasedStudentCourseFindByIdSuccess.data);
    };
  }, [ieltsPurchasedStudentCourseFindByIdSuccess]);

  useEffect(() => {
    dispatch(ieltsPurchasedStudentCourseFindByIdAction(id));
  }, [dispatch]);

  const [data,setData] = useState();

  let arr = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          sm: "1fr 1fr",
          md: "1fr 1fr 1fr",
          xl: "1fr 1fr 1fr 1fr",
        },
        gap: {
          sm: "12px",
          md: "16px",
          lg: "24px",
        },
      }}
    >
      {data?.map((item) => (
        <Box
          sx={{
            border: "1px solid rgba(236, 236, 236, 1)",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid rgba(236, 236, 236, 1)",
              padding: "12px",
              paddingBottom: "16px",
              "& img": {
                objectFit: "cover",
                height: "127px",
                width: "100%",
                borderRadius: "6px",
              },
            }}
          >
            <img src={item?.ielts_course?.CourseCoverImgUrl} />
          </Box>
          <Box
            sx={{
              padding: "12px",
              paddingTop: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  size: "16px",
                }}
              >
                {item?.ielts_course?.CourseName}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  size: "12px",
                }}
              >
                {item?.courseMethod == "onlineClass"
                  ? "Online"
                  : item?.courseMethod == "offlineClass"
                  ? "Offline"
                  : ""}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "9px",
              }}
            >
              {item?.ielts_course?.CourseDuration}{" - "}
              {item?.ielts_course?.ielts_course_topics?.length} Topics
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default IeltsPurchasedCourseView;
