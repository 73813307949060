// universityCourseAddReducer

import {
  ALL_COURSE_FIND_ERR,
  ALL_COURSE_FIND_REQUEST,
  ALL_COURSE_FIND_SUCCESS,
  UNIVERSITY_COURSE_ADD_ERR,
  UNIVERSITY_COURSE_ADD_REQUEST,
  UNIVERSITY_COURSE_ADD_SUCCESS,
  UNIVERSITY_COURSE_BULKUPLOAD_ERR,
  UNIVERSITY_COURSE_BULKUPLOAD_REQUEST,
  UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS,
  UNIVERSITY_COURSE_DELETE_ERR,
  UNIVERSITY_COURSE_DELETE_REQUEST,
  UNIVERSITY_COURSE_DELETE_SUCCESS,
  UNIVERSITY_COURSE_EDITFIND_ERR,
  UNIVERSITY_COURSE_EDITFIND_REQUEST,
  UNIVERSITY_COURSE_EDITFIND_SUCCESS,
  UNIVERSITY_COURSE_EDIT_ERR,
  UNIVERSITY_COURSE_EDIT_REQUEST,
  UNIVERSITY_COURSE_EDIT_SUCCESS,
  UNIVERSITY_COURSE_FIND_ERR,
  UNIVERSITY_COURSE_FIND_REQUEST,
  UNIVERSITY_COURSE_FIND_SUCCESS,
  UNIVERSITY_COURSE_MAJOR_FIND_ERR,
  UNIVERSITY_COURSE_MAJOR_FIND_REQUEST,
  UNIVERSITY_COURSE_MAJOR_FIND_SUCCESS,
  UNIVERSITY_COURSE_TABLE_FILTER_ERR,
  UNIVERSITY_COURSE_TABLE_FILTER_REQUEST,
  UNIVERSITY_COURSE_TABLE_FILTER_SUCCESS,
} from "../../../constants/university/courseConstant/universityCourseConstant";

export const universityCourseAddReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_ADD_REQUEST:
      return {
        ...state,
        universityCourseAddLoading: true,
      };
    case UNIVERSITY_COURSE_ADD_SUCCESS:
      return {
        ...state,
        universityCourseAddLoading: false,
        universityCourseAddSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_ADD_ERR:
      return {
        ...state,
        universityCourseAddLoading: false,
        universityCourseAddErr: action.payload,
      };
    default:
      return state;
  }
};

// universityFindCourseReducer
export const universityFindCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_FIND_REQUEST:
      return {
        ...state,
        universityCourseFindLoading: true,
      };
    case UNIVERSITY_COURSE_FIND_SUCCESS:
      return {
        ...state,
        universityCourseFindLoading: false,
        universityCourseFindSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_FIND_ERR:
      return {
        ...state,
        universityCourseFindLoading: false,
        universityCourseFindErr: action.payload,
      };
    default:
      return state;
  }
};

// allFindCoursesReducer
export const allFindCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_COURSE_FIND_REQUEST:
      return {
        ...state,
        allCourseFindLoading: true,
      };
    case ALL_COURSE_FIND_SUCCESS:
      return {
        ...state,
        allCourseFindLoading: false,
        allCourseFindSuccess: action.payload,
      };
    case ALL_COURSE_FIND_ERR:
      return {
        ...state,
        allCourseFindLoading: false,
        allCourseFindErr: action.payload,
      };
    default:
      return state;
  }
};

// deleteCourseReducer
export const deleteCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_DELETE_REQUEST:
      return {
        ...state,
        universityCourseDeletingLoading: true,
      };
    case UNIVERSITY_COURSE_DELETE_SUCCESS:
      return {
        ...state,
        universityCourseDeletingLoading: false,
        universityCourseDeletingSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_DELETE_ERR:
      return {
        ...state,
        universityCourseDeletingLoading: false,
        universityCourseDeletingErr: action.payload,
      };
    default:
      return state;
  }
};

export const courseTableFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_TABLE_FILTER_REQUEST:
      return {
        ...state,
        universityCourseFilterDataLoading: true,
      };
    case UNIVERSITY_COURSE_TABLE_FILTER_SUCCESS:
      return {
        ...state,
        universityCourseFilterDataLoading: false,
        universityCourseFilterDataSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_TABLE_FILTER_ERR:
      return {
        ...state,
        universityCourseFilterDataLoading: false,
        universityCourseFilterDataErr: action.payload,
      };
    default:
      return state;
  }
};

export const courseEditFindReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_EDITFIND_REQUEST:
      return {
        ...state,
        universityCourseEditFindLoading: true,
      };
    case UNIVERSITY_COURSE_EDITFIND_SUCCESS:
      return {
        ...state,
        universityCourseEditFindLoading: false,
        universityCourseEditFindSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_EDITFIND_ERR:
      return {
        ...state,
        universityCourseEditFindLoading: false,
        universityCourseEditFindErr: action.payload,
      };
    default:
      return state;
  }
};

export const courseEditReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_EDIT_REQUEST:
      return {
        ...state,
        universityCourseEditLoading: true,
      };
    case UNIVERSITY_COURSE_EDIT_SUCCESS:
      return {
        ...state,
        universityCourseEditLoading: false,
        universityCourseEditSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_EDIT_ERR:
      return {
        ...state,
        universityCourseEditLoading: false,
        universityCourseEditErr: action.payload,
      };
    default:
      return state;
  }
};

// courseBulkupload reducer

export const courseBulkUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_COURSE_BULKUPLOAD_REQUEST:
      return {
        ...state,
        universityCourseBulkUploadLoading: true,
      };
    case UNIVERSITY_COURSE_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        universityCourseBulkUploadLoading: false,
        universityCourseBulkUploadSuccess: action.payload,
      };
    case UNIVERSITY_COURSE_BULKUPLOAD_ERR:
      return {
        ...state,
        universityCourseBulkUploadLoading: false,
        universityCourseBulkUploadErr: action.payload,
      };
    default:
      return state;
  }
};
