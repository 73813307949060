import {
  CREATE_NEW_MENU_ERR,
  CREATE_NEW_MENU_REQUEST,
  CREATE_NEW_MENU_SUCCESS,
  CREATE_NEW_SUBMENU_ERR,
  CREATE_NEW_SUBMENU_REQUEST,
  CREATE_NEW_SUBMENU_SUCCESS,
  CREATE_PERMISSION_ERR,
  CREATE_PERMISSION_REQUEST,
  CREATE_PERMISSION_SUCCESS,
  DELETE_MENU_ERR,
  DELETE_MENU_REQUEST,
  DELETE_MENU_SUCCESS,
  DELETE_PERMISSION_ERR,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  DELETE_SUBMENU_ERR,
  DELETE_SUBMENU_REQUEST,
  DELETE_SUBMENU_SUCCESS,
  EDIT_MENU_ERR,
  EDIT_MENU_REQUEST,
  EDIT_MENU_SUCCESS,
  FIND_ALL_PERMISSION_DETAILS_ERR,
  FIND_ALL_PERMISSION_DETAILS_REQUEST,
  FIND_ALL_PERMISSION_DETAILS_SUCCESS,
  FIND_MENU_ERR,
  FIND_MENU_REQUEST,
  FIND_MENU_SUCCESS,
  FIND_PERMISSION_DETAILS_ERR,
  FIND_PERMISSION_DETAILS_REQUEST,
  FIND_PERMISSION_DETAILS_SUCCESS,
  FIND_SUBMENU_DETAILS_ERR,
  FIND_SUBMENU_DETAILS_REQUEST,
  FIND_SUBMENU_DETAILS_SUCCESS,
  FIND_SUB_MENU_ERR,
  FIND_SUB_MENU_REQUEST,
  FIND_SUB_MENU_SUCCESS,
  UPDATE_PERMISSION_ERR,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
  UPDATE_SUBMENU_ERR,
  UPDATE_SUBMENU_REQUEST,
  UPDATE_SUBMENU_SUCCESS,
} from "../constants/developersConstant";

// *********** menu **************
// create a new menu in developer
export const createMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_MENU_REQUEST:
      return {
        ...state,
        createNewMenuLoading: true,
      };
    case CREATE_NEW_MENU_SUCCESS:
      return {
        ...state,
        createNewMenuLoading: false,
        createNewMenuSuccess: action.payload,
      };
    case CREATE_NEW_MENU_ERR:
      return {
        ...state,
        createNewMenuLoading: false,
        createNewMenuErr: action.payload,
      };
    default:
      return state;
  }
};

// edit a new menu in developer
export const editMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_MENU_REQUEST:
      return {
        ...state,
        editNewMenuLoading: true,
      };
    case EDIT_MENU_SUCCESS:
      return {
        ...state,
        editNewMenuLoading: false,
        editNewMenuSuccess: action.payload,
      };
    case EDIT_MENU_ERR:
      return {
        ...state,
        editNewMenuLoading: false,
        editNewMenuErr: action.payload,
      };
    default:
      return state;
  }
};

// delete a new menu in developer
export const deleteMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MENU_REQUEST:
      return {
        ...state,
        deleteMenuLoading: true,
      };
    case DELETE_MENU_SUCCESS:
      return {
        ...state,
        deleteMenuLoading: false,
        deleteMenuSuccess: action.payload,
      };
    case DELETE_MENU_ERR:
      return {
        ...state,
        deleteMenuLoading: false,
        deleteMenuErr: action.payload,
      };
    default:
      return state;
  }
};

// developerFindAllSubMenuDetailsReducer
export const developerFindAllSubMenuDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_SUB_MENU_REQUEST:
      return {
        ...state,
        subMenuFindAllLoading: true,
      };
    case FIND_SUB_MENU_SUCCESS:
      return {
        ...state,
        subMenuFindAllLoading: false,
        subMenuFindAllSuccess: action.payload,
      };
    case FIND_SUB_MENU_ERR:
      return {
        ...state,
        subMenuFindAllLoading: false,
        subMenuFindAllErr: action.payload,
      };
    default:
      return state;
  }
};

// find all menu in developer
export const findAllMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_MENU_REQUEST:
      return {
        ...state,
        findAllMenuLoading: true,
      };
    case FIND_MENU_SUCCESS:
      return {
        ...state,
        findAllMenuLoading: false,
        findAllMenuSuccess: action.payload,
      };
    case FIND_MENU_ERR:
      return {
        ...state,
        findAllMenuLoading: false,
        findAllMenuErr: action.payload,
      };
    default:
      return state;
  }
};

// *********** submenu **************
// create a new menu in developer
export const createSubMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_NEW_SUBMENU_REQUEST:
      return {
        ...state,
        createSubMenuInDeveloperTabLoading: true,
      };
    case CREATE_NEW_SUBMENU_SUCCESS:
      return {
        ...state,
        createSubMenuInDeveloperTabLoading: false,
        createSubMenuInDeveloperTabSuccess: action.payload,
      };
    case CREATE_NEW_SUBMENU_ERR:
      return {
        ...state,
        createSubMenuInDeveloperTabLoading: false,
        createSubMenuInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// edit a new menu in developer
export const editSubMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SUBMENU_REQUEST:
      return {
        ...state,
        editSubMenuInDeveloperTabLoading: true,
      };
    case UPDATE_SUBMENU_SUCCESS:
      return {
        ...state,
        editSubMenuInDeveloperTabLoading: false,
        editSubMenuInDeveloperTabSuccess: action.payload,
      };
    case UPDATE_SUBMENU_ERR:
      return {
        ...state,
        editSubMenuInDeveloperTabLoading: false,
        editSubMenuInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// delete a new menu in developer
export const deleteSubMenuInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SUBMENU_REQUEST:
      return {
        ...state,
        deleteSubMenuInDeveloperTabLoading: true,
      };
    case DELETE_SUBMENU_SUCCESS:
      return {
        ...state,
        deleteSubMenuInDeveloperTabLoading: false,
        deleteSubMenuInDeveloperTabSuccess: action.payload,
      };
    case DELETE_SUBMENU_ERR:
      return {
        ...state,
        deleteSubMenuInDeveloperTabLoading: false,
        deleteSubMenuInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// find all menu in developer
export const findSubMenuDetailsInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_SUBMENU_DETAILS_REQUEST:
      return {
        ...state,
        findSubMenuDetailsInDeveloperTabLoading: true,
      };
    case FIND_SUBMENU_DETAILS_SUCCESS:
      return {
        ...state,
        findSubMenuDetailsInDeveloperTabLoading: false,
        findSubMenuDetailsInDeveloperTabSuccess: action.payload,
      };
    case FIND_SUBMENU_DETAILS_ERR:
      return {
        ...state,
        findSubMenuDetailsInDeveloperTabLoading: false,
        findSubMenuDetailsInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// *********** permissions **************
// create a permissions in developer
export const createPermissionsInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PERMISSION_REQUEST:
      return {
        ...state,
        createPermissionsInDeveloperTabLoading: true,
      };
    case CREATE_PERMISSION_SUCCESS:
      return {
        ...state,
        createPermissionsInDeveloperTabLoading: false,
        createPermissionsInDeveloperTabSuccess: action.payload,
      };
    case CREATE_PERMISSION_ERR:
      return {
        ...state,
        createPermissionsInDeveloperTabLoading: false,
        createPermissionsInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// edit a permissions in developer
export const editPermissionsInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        editPermissionsInDeveloperTabLoading: true,
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        editPermissionsInDeveloperTabLoading: false,
        editPermissionsInDeveloperTabSuccess: action.payload,
      };
    case UPDATE_PERMISSION_ERR:
      return {
        ...state,
        editPermissionsInDeveloperTabLoading: false,
        editPermissionsInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// delete a permissions in developer
export const deletePermissionsInDeveloperTabReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PERMISSION_REQUEST:
      return {
        ...state,
        deletePermissionsInDeveloperTabLoading: true,
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        deletePermissionsInDeveloperTabLoading: false,
        deletePermissionsInDeveloperTabSuccess: action.payload,
      };
    case DELETE_PERMISSION_ERR:
      return {
        ...state,
        deletePermissionsInDeveloperTabLoading: false,
        deletePermissionsInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// find all permissions in developer
export const findPermissionsDetailsInDeveloperTabReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_PERMISSION_DETAILS_REQUEST:
      return {
        ...state,
        findPermissionsDetailsInDeveloperTabLoading: true,
      };
    case FIND_PERMISSION_DETAILS_SUCCESS:
      return {
        ...state,
        findPermissionsDetailsInDeveloperTabLoading: false,
        findPermissionsDetailsInDeveloperTabSuccess: action.payload,
      };
    case FIND_PERMISSION_DETAILS_ERR:
      return {
        ...state,
        findPermissionsDetailsInDeveloperTabLoading: false,
        findPermissionsDetailsInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};

// find all permissions in developer
export const findAllPermissionsDetailsInDeveloperTabReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_ALL_PERMISSION_DETAILS_REQUEST:
      return {
        ...state,
        findAllPermissionsDetailsInDeveloperTabLoading: true,
      };
    case FIND_ALL_PERMISSION_DETAILS_SUCCESS:
      return {
        ...state,
        findAllPermissionsDetailsInDeveloperTabLoading: false,
        findAllPermissionsDetailsInDeveloperTabSuccess: action.payload,
      };
    case FIND_ALL_PERMISSION_DETAILS_ERR:
      return {
        ...state,
        findAllPermissionsDetailsInDeveloperTabLoading: false,
        findAllPermissionsDetailsInDeveloperTabErr: action.payload,
      };
    default:
      return state;
  }
};
