import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Alert,
  AlertTitle,
  Typography,
  Pagination,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, MenuItem } from "@mui/material";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { MdNotInterested } from "react-icons/md";
import { BiSortAlt2 } from "react-icons/bi";

import { AiOutlineEye } from "react-icons/ai";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

export const BonusTable = ({
  columns,
  data,
  component,
  subMenu,
  //   isPresentMap,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [actionButtonShow, setActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const openActionButton = Boolean(actionButtonShow);

  const handleClickAction = (event, id) => {
    setActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setActionButtonShow(null);
  };

  // Delete Table

  const handleDeleteNavigation = (id) => {};

  // navigate to Edit page

  const topNavigationEditHandler = (id) => {
    // navigate(`/landingPage/blogPageEdit/${id}`);
  };

  const handleSingleItem = (id) => {
    navigate(`bonus/${id}`);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="administratCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });
      return sortedItems;
    }
    return data;
  }, [data, sortBy, sortOrder]);

  const [pageError, setPageError] = useState("");
  const [inputNumber, setInputNumber] = useState(10);

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setInputNumber(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };

  useEffect(() => {
    if (inputNumber > 10) {
      setRowsPerPage(inputNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [inputNumber]);

  return sortedData.length > 0 ? (
    <>
      <Typography sx={{ mb: "20px" }}>
        Showing
        <Typography
          component={"span"}
          sx={{
            fontWeight: "600",
          }}
        >
          {" "}
          {
            sortedData?.slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage
            )?.length
          }{" "}
          Result
        </Typography>
      </Typography>

      <Box
        sx={{
          overflowX: "scroll",
          width: "100%",
        }}
      >
        <Table className="landingTable" sx={{ minWidth: "1200px" }}>
          <TableHead sx={{ backgroundColor: "#F9F9FB" }}>
            <TableRow className="landingTableCell">
              <TableCell
                sx={{
                  borderBottom: "0",
                  py: "5px",
                }}
              >
                <Checkbox
                  // checked={selectedRows.length === sortedData.length}
                  // onChange={handleSelectAllRows}
                  style={{ color: "#f81b82" }}
                />
              </TableCell>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }
                return (
                  <TableCell
                    key={column.field}
                    className="landingTableCell"
                    sx={{
                      textTransform: "uppercase",
                      color: "#666E7D",
                      fontWeight: "600",
                      borderBottom: "0",
                      py: "5px",
                    }}
                  >
                    <TableSortLabel
                      className="landingHeaderText"
                      active={column === sortBy}
                      direction={sortOrder}
                      onClick={() => requestSort(column)}
                      IconComponent={
                        column.field !== "action" && CustomSortIcon
                      }
                    >
                      {column.title}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
              {/* <TableCell className="landingTableCell"></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, indx) => (
                <TableRow
                  className="landingTable"
                  key={indx}
                  sx={{
                    "& .MuiTableCell-root ": {
                      borderBottom: "0",
                      py: "5px",
                    },
                  }}
                >
                  <TableCell>
                    <Checkbox
                      // checked={selectedRows.includes(item.id)}
                      // onChange={() => handleSelectRow(item.id)}
                      style={{ color: "#f81b82" }}
                    />
                  </TableCell>
                  <TableCell>{indx + 1}</TableCell>
                  {item.employeeId && <TableCell>{item.employeeId}</TableCell>}
                  {item.name && <TableCell>{item.name}</TableCell>}
                  {item.role && <TableCell>{item.role}</TableCell>}
                  {item.enrollmentCount && (
                    <TableCell>{item.enrollmentCount}</TableCell>
                  )}
                  {item.date && <TableCell>{item.date}</TableCell>}

                  <TableCell>
                    <SlOptionsVertical
                      id="demo-positioned-button"
                      aria-controls={
                        openActionButton ? "demo-positioned-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openActionButton ? "true" : undefined}
                      onClick={(e) => {
                        handleClickAction(e, item.id);
                      }}
                      variant="outlined"
                      style={{ marginLeft: "10px", color: "#F80B7A" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter />
        </Table>
      </Box>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={actionButtonShow}
        open={openActionButton}
        onClose={handleCloseAction}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top", // Change from "top" to "bottom"
          horizontal: "left",
        }}
        sx={{
          borderColor: "black",
          borderRadius: "24px",
          mt: 1.5,
          width: "auto",
          height: "auto",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <AiOutlineEye
              onClick={() => handleSingleItem(tableId)}
              className="icons"
              style={{
                fontSize: "lg",
                width: "20px",
                height: "19px",
              }}
            />
          </MenuItem>
        </Box>
      </Menu>

      {/* {Math.ceil(data.length / rowsPerPage) !== 1 && ( */}
      <>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                mt: "20px",
                "& input": {
                  width: "30px",
                  outline: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                Rows Per Page :{" "}
              </Typography>
              <input
                type="number"
                value={inputNumber}
                onChange={handlePageSearch}
              />
            </Box>
            {pageError && (
              <Typography
                sx={{
                  fontSize: 10,
                  color: "red",
                }}
              >
                {pageError}
              </Typography>
            )}
          </Box>
          <Pagination
            sx={{
              mt: "28px",
              "& .Mui-selected": {
                background: "#FE0B7A !important",
              },
              "& .MuiPagination-ul": {
                justifyContent: "end",
              },
            }}
            count={Math.ceil(data.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      </>
      {/* )} */}
    </>
  ) : (
    <TableNoItemComponent />
  );
};
