import {
  ADD_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
  ADD_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
  ADD_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST,
  ADD_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
  ADD_COST_ESTIMATE_OF_COUNTRY_ERR,
  ADD_COST_ESTIMATE_OF_COUNTRY_REQUEST,
  ADD_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
  ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_DOCUMENT_OF_COUNTRY_ERR,
  ADD_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR,
  ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST,
  ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS,
  ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
  ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
  ADD_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
  ADD_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST,
  ADD_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
  COUNTRY_DOC_ADD_ERR,
  COUNTRY_DOC_ADD_REQUEST,
  COUNTRY_DOC_ADD_SUCCESS,
  COUNTRY_DOC_DELETE_ERR,
  COUNTRY_DOC_DELETE_REQUEST,
  COUNTRY_DOC_DELETE_SUCCESS,
  COUNTRY_DOC_FIND_ERR,
  COUNTRY_DOC_FIND_REQUEST,
  COUNTRY_DOC_FIND_SUCCESS,
  DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
  DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST,
  DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
  DELETE_COST_ESTIMATE_OF_COUNTRY_ERR,
  DELETE_COST_ESTIMATE_OF_COUNTRY_REQUEST,
  DELETE_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
  DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_SOP_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_SOP_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_SOP_DOCUMENT_OF_COUNTRY_SUCCESS,
  DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
  DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST,
  DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_ADMISSION_DOCUMENT_OF_COUNTRY_ERR,
  FIND_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_ADMISSION_PROCEDURE_OF_COUNTRY_ERR,
  FIND_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST,
  FIND_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS,
  FIND_COST_ESTIMATE_OF_COUNTRY_ERR,
  FIND_COST_ESTIMATE_OF_COUNTRY_REQUEST,
  FIND_COST_ESTIMATE_OF_COUNTRY_SUCCESS,
  FIND_CRIME_DOCUMENT_OF_COUNTRY_ERR,
  FIND_CRIME_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_CRIME_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_DOCUMENT_OF_COUNTRY_ERR,
  FIND_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR,
  FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST,
  FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS,
  FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR,
  FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_MEDICAL_DOCUMENT_OF_COUNTRY_ERR,
  FIND_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS,
  FIND_TRAVEL_DOCUMENT_OF_COUNTRY_ERR,
  FIND_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST,
  FIND_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS,
  PAYMENT_GETWAY_ADD_OF_COUNTRY_ERR,
  PAYMENT_GETWAY_ADD_OF_COUNTRY_REQUEST,
  PAYMENT_GETWAY_ADD_OF_COUNTRY_SUCCESS,
} from "../../constants/knowledgeBankConstant/countryDocsConstant";

// submitCountryDocsAddReducer
export const submitCountryDocsAddReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_DOC_ADD_REQUEST:
      return {
        ...state,
        countryAddLoading: true,
      };
    case COUNTRY_DOC_ADD_SUCCESS:
      return {
        ...state,
        countryAddLoading: false,
        countryAddSuccess: action.payload,
      };
    case COUNTRY_DOC_ADD_ERR:
      return {
        ...state,
        countryAddLoading: false,
        countryAddError: action.payload,
      };
    default:
      return state;
  }
};

// CountryDocFindReducer
export const CountryDocFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_DOC_FIND_REQUEST:
      return {
        ...state,
        countryDocFindLoading: true,
      };
    case COUNTRY_DOC_FIND_SUCCESS:
      return {
        ...state,
        countryFindLoading: false,
        countryDocFindSuccess: action.payload,
      };
    case COUNTRY_DOC_FIND_ERR:
      return {
        ...state,
        countryFindLoading: false,
        countryDocFindError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocDeleteReducer
export const countryDocDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRY_DOC_DELETE_REQUEST:
      return {
        ...state,
        countryDocDeleteLoading: true,
      };
    case COUNTRY_DOC_DELETE_SUCCESS:
      return {
        ...state,
        countryDeleteLoading: false,
        countryDocDeleteSuccess: action.payload,
      };
    case COUNTRY_DOC_DELETE_ERR:
      return {
        ...state,
        countryDeleteLoading: false,
        countryDocDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentSOPApplicationAddReducer
export const countryDocumentSOPApplicationAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocSopAddLoading: true,
      };
    case ADD_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocSopAddLoading: false,
        countryDocSopAddSuccess: action.payload,
      };
    case ADD_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocSopAddLoading: false,
        countryDocSopAddError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentSOPApplicationFindReducer
export const countryDocumentSOPApplicationFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocSopFindLoading: true,
      };
    case FIND_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocSopFindLoading: false,
        countryDocSopFindSuccess: action.payload,
      };
    case FIND_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocSopFindLoading: false,
        countryDocSopFindError: action.payload,
      };
    default:
      return state;
  }
};

// SopDocDeleteReducer
export const SopDocDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SOP_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocSopDeleteLoading: true,
      };
    case DELETE_SOP_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocSopDeleteLoading: false,
        countryDocSopDeleteSuccess: action.payload,
      };
    case DELETE_SOP_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocSopDeleteLoading: false,
        countryDocSopDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// travel

// countryDocumentTravelApplicationAddReducer
export const countryDocumentTravelApplicationAddReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADD_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocTravelAddLoading: true,
      };
    case ADD_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocTravelAddLoading: false,
        countryDocTravelAddSuccess: action.payload,
      };
    case ADD_TRAVEL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocTravelAddLoading: false,
        countryDocTravelAddError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentTravelApplicationFindReducer
export const countryDocumentTravelApplicationFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocTravelFindLoading: true,
      };
    case FIND_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocTravelFindLoading: false,
        countryDocTravelFindSuccess: action.payload,
      };
    case FIND_TRAVEL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocTravelFindLoading: false,
        countryDocTravelFindError: action.payload,
      };
    default:
      return state;
  }
};

// TravelApplicationDeleteReducer
export const TravelApplicationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocTravelDeleteLoading: true,
      };
    case DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocTravelDeleteLoading: false,
        countryDocTravelDeleteSuccess: action.payload,
      };
    case DELETE_TRAVEL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocTravelDeleteLoading: false,
        countryDocTravelDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// Guidance
// countryDocumentGuidanceApplicationFindReducer
export const countryDocumentGuidanceApplicationFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocGuidanceFindLoading: true,
      };
    case FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocGuidanceFindLoading: false,
        countryDocGuidanceFindSuccess: action.payload,
      };
    case FIND_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocGuidanceFindLoading: false,
        countryDocGuidanceFindError: action.payload,
      };
    default:
      return state;
  }
};

// documentForCountryGuidanceApplicationFindReducer
export const documentForCountryGuidanceApplicationFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST:
      return {
        ...state,
        docGuidanceForCountryFindLoading: true,
      };
    case FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS:
      return {
        ...state,
        docGuidanceForCountryFindLoading: false,
        docGuidanceForCountryFindSuccess: action.payload,
      };
    case FIND_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR:
      return {
        ...state,
        docGuidanceForCountryFindLoading: false,
        docGuidanceForCountryFindError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentGuidanceApplicationAddReducer
export const countryDocumentGuidanceApplicationAddReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocGuidanceAddLoading: true,
      };
    case ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocGuidanceAddLoading: false,
        countryDocGuidanceAddSuccess: action.payload,
      };
    case ADD_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocGuidanceAddLoading: false,
        countryDocGuidanceAddError: action.payload,
      };
    default:
      return state;
  }
};

// documentForCountryGuidanceApplicationAddReducer
export const documentForCountryGuidanceApplicationAddReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_REQUEST:
      return {
        ...state,
        docGuidanceForCountryAddLoading: true,
      };
    case ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_SUCCESS:
      return {
        ...state,
        docGuidanceForCountryAddLoading: false,
        docGuidanceForCountryAddSuccess: action.payload,
      };
    case ADD_GUIDANCE_DOCUMENT_FOR_COUNTRY_ERR:
      return {
        ...state,
        docGuidanceForCountryAddLoading: false,
        docGuidanceForCountryAddError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentGuidanceApplicationDeleteReducer
export const countryDocumentGuidanceApplicationDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocGuidanceDeleteLoading: true,
      };
    case DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocGuidanceDeleteLoading: false,
        countryDocGuidanceDeleteSuccess: action.payload,
      };
    case DELETE_GUIDANCE_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocGuidanceDeleteLoading: false,
        countryDocGuidanceDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// Admission

// countryDocumentAdmissionApplicationAddReducer
export const countryDocumentAdmissionApplicationAddReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADD_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocAdmissionAddLoading: true,
      };
    case ADD_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocAdmissionAddLoading: false,
        countryDocAdmissionAddSuccess: action.payload,
      };
    case ADD_ADMISSION_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocAdmissionAddLoading: false,
        countryDocAdmissionAddError: action.payload,
      };
    default:
      return state;
  }
};

// countryAdmissionProcedureAddReducer
export const countryAdmissionProcedureAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocAdmissionProcedureAddLoading: true,
      };
    case ADD_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocAdmissionProcedureAddLoading: false,
        countryDocAdmissionProcedureAddSuccess: action.payload,
      };
    case ADD_ADMISSION_PROCEDURE_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocAdmissionProcedureAddLoading: false,
        countryDocAdmissionProcedureAddError: action.payload,
      };
    default:
      return state;
  }
};

// countryCostEstimateAddReducer
export const countryCostEstimateAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_COST_ESTIMATE_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryCostEstimateAddLoading: true,
      };
    case ADD_COST_ESTIMATE_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryCostEstimateAddLoading: false,
        countryCostEstimateAddSuccess: action.payload,
      };
    case ADD_COST_ESTIMATE_OF_COUNTRY_ERR:
      return {
        ...state,
        countryCostEstimateAddLoading: false,
        countryCostEstimateAddError: action.payload,
      };
    default:
      return state;
  }
};

// countryAdmissionProcedureFindReducer
export const countryAdmissionProcedureFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocAdmissionProcedureFindLoading: true,
      };
    case FIND_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocAdmissionProcedureFindLoading: false,
        countryDocAdmissionProcedureFindSuccess: action.payload,
      };
    case FIND_ADMISSION_PROCEDURE_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocAdmissionFindLoading: false,
        countryDocAdmissionProcedureFindError: action.payload,
      };
    default:
      return state;
  }
};

// countryCostEstimateFindReducer
export const countryCostEstimateFindReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_COST_ESTIMATE_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryCostEstimateFindLoading: true,
      };
    case FIND_COST_ESTIMATE_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryCostEstimateFindLoading: false,
        countryCostEstimateFindSuccess: action.payload,
      };
    case FIND_COST_ESTIMATE_OF_COUNTRY_ERR:
      return {
        ...state,
        countryCostEstimateFindLoading: false,
        countryCostEstimateFindError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentAdmissionApplicationFindReducer
export const countryDocumentAdmissionApplicationFindReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case FIND_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocAdmissionFindLoading: true,
      };
    case FIND_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocAdmissionFindLoading: false,
        countryDocAdmissionFindSuccess: action.payload,
      };
    case FIND_ADMISSION_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocAdmissionFindLoading: false,
        countryDocAdmissionFindError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentAdmissionApplicationDeleteReducer
export const countryDocumentAdmissionApplicationDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocAdmissionDeleteLoading: true,
      };
    case DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocAdmissionDeleteLoading: false,
        countryDocAdmissionDeleteSuccess: action.payload,
      };
    case DELETE_ADMISSION_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocAdmissionDeleteLoading: false,
        countryDocAdmissionDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// countryDocumentAdmissionProcedureDeleteReducer
export const countryDocumentAdmissionProcedureDeleteReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryDocAdmissionPeocedureDeleteLoading: true,
      };
    case DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryDocAdmissionPeocedureDeleteLoading: false,
        countryDocAdmissionPeocedureDeleteSuccess: action.payload,
      };
    case DELETE_ADMISSION_PROCEDURE_OF_COUNTRY_ERR:
      return {
        ...state,
        countryDocAdmissionPeocedureDeleteLoading: false,
        countryDocAdmissionPeocedureDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// countryCostEstimateDeleteReduer
export const countryCostEstimateDeleteReduer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COST_ESTIMATE_OF_COUNTRY_REQUEST:
      return {
        ...state,
        costEstimateDeleteLoading: true,
      };
    case DELETE_COST_ESTIMATE_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        costEstimateDeleteLoading: false,
        costEstimateDeleteSuccess: action.payload,
      };
    case DELETE_COST_ESTIMATE_OF_COUNTRY_ERR:
      return {
        ...state,
        costEstimateDeleteLoading: false,
        costEstimateDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// countryCrimeAndMedicalApplicationAddReducer
export const countryCrimeAndMedicalApplicationAddReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryCrimeMediDocLoading: true,
      };
    case ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryCrimeMediDocLoading: false,
        countryCrimeMediDocSuccess: action.payload,
      };
    case ADD_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryCrimeMediDocLoading: false,
        countryCrimeMediDocError: action.payload,
      };
    default:
      return state;
  }
};

// countryCrimeApplicationGetReducer
export const countryCrimeApplicationGetReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_CRIME_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryCrimeDocFindLoading: true,
      };
    case FIND_CRIME_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryCrimeDocFindLoading: false,
        countryCrimeDocFindSuccess: action.payload,
      };
    case FIND_CRIME_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryCrimeDocFindLoading: false,
        countryCrimeDocFindError: action.payload,
      };
    default:
      return state;
  }
};

// criminalDocDeleteReducer
export const criminalDocDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryCrimeDocDeleteLoading: true,
      };
    case DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryCrimeDocDeleteLoading: false,
        countryCrimeDocDeleteSuccess: action.payload,
      };
    case DELETE_CRIME_MEDICAL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryCrimeDocDeleteLoading: false,
        countryCrimeDocDeleteError: action.payload,
      };
    default:
      return state;
  }
};

// medicalDocDeleteReducer
export const medicalDocDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryMedicalDocDeleteLoading: true,
      };
    case DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryMedicalDocDeleteLoading: false,
        countryMedicalDocDeleteSuccess: action.payload,
      };
    case DELETE_MEDICAL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryMedicalDocDeleteLoading: false,
        countryMedicalDocDeleteError: action.payload,
      };
    default:
      return state;
  }
};



// countryMedicalApplicationGetReducer
export const countryMedicalApplicationGetReducer = (state = {}, action) => {
  switch (action.type) {
    case FIND_MEDICAL_DOCUMENT_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryMedicalDocFindLoading: true,
      };
    case FIND_MEDICAL_DOCUMENT_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryMedicalDocFindLoading: false,
        countryMedicalDocFindSuccess: action.payload,
      };
    case FIND_MEDICAL_DOCUMENT_OF_COUNTRY_ERR:
      return {
        ...state,
        countryMedicalDocFindLoading: false,
        countryMedicalDocFindError: action.payload,
      };
    default:
      return state;
  }
};

// countryPaymentGetwayUpdateReducer
export const countryPaymentGetwayUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_GETWAY_ADD_OF_COUNTRY_REQUEST:
      return {
        ...state,
        countryPaymentGetWayLoading: true,
      };
    case PAYMENT_GETWAY_ADD_OF_COUNTRY_SUCCESS:
      return {
        ...state,
        countryPaymentGetWayLoading: false,
        countryPaymentGetWaySuccess: action.payload,
      };
    case PAYMENT_GETWAY_ADD_OF_COUNTRY_ERR:
      return {
        ...state,
        countryPaymentGetWayLoading: false,
        countryPaymentGetWayError: action.payload,
      };
    default:
      return state;
  }
};
