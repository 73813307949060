import {
  CITY_MASTER_ADD_ERR,
  CITY_MASTER_ADD_REQUEST,
  CITY_MASTER_ADD_SUCCESS,
  CITY_MASTER_DELETE_ERR,
  CITY_MASTER_DELETE_REQUEST,
  CITY_MASTER_DELETE_SUCCESS,
  CITY_MASTER_EDIT_ERR,
  CITY_MASTER_EDIT_FIND_ERR,
  CITY_MASTER_EDIT_FIND_REQUEST,
  CITY_MASTER_EDIT_FIND_SUCCESS,
  CITY_MASTER_EDIT_REQUEST,
  CITY_MASTER_EDIT_SUCCESS,
  CITY_MASTER_FIND_ERR,
  CITY_MASTER_FIND_REQUEST,
  CITY_MASTER_FIND_SUCCESS,
} from "../constants/cityMasterConstant";

// cityMasterAddReducer
export const cityMasterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CITY_MASTER_ADD_REQUEST:
      return {
        ...state,
        cityAddLoading: true,
      };
    case CITY_MASTER_ADD_SUCCESS:
      return {
        ...state,
        cityAddLoading: false,
        cityAddSuccess: action.payload,
      };
    case CITY_MASTER_ADD_ERR:
      return {
        ...state,
        cityAddLoading: false,
        cityAddErr: action.payload,
      };
    default:
      return state;
  }
};

// findCityMasterReducer
export const findCityMasterReducer = (state = {}, action) => {
  switch (action.type) {
    case CITY_MASTER_FIND_REQUEST:
      return {
        ...state,
        cityFindLoading: true,
      };
    case CITY_MASTER_FIND_SUCCESS:
      return {
        ...state,
        cityFindLoading: false,
        cityFindSuccess: action.payload,
      };
    case CITY_MASTER_FIND_ERR:
      return {
        ...state,
        cityFindLoading: false,
        cityFindErr: action.payload,
      };
    default:
      return state;
  }
};

// cityMasterDeleteReducer
export const cityMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CITY_MASTER_DELETE_REQUEST:
      return {
        ...state,
        cityDeleteLoading: true,
      };
    case CITY_MASTER_DELETE_SUCCESS:
      return {
        ...state,
        cityDeleteLoading: false,
        cityDeleteSuccess: action.payload,
      };
    case CITY_MASTER_DELETE_ERR:
      return {
        ...state,
        cityDeleteLoading: false,
        cityDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

// findEditCityFormReducer
export const findEditCityFormReducer = (state = {}, action) => {
  switch (action.type) {
    case CITY_MASTER_EDIT_FIND_REQUEST:
      return {
        ...state,
        cityFindEditLoading: true,
      };
    case CITY_MASTER_EDIT_FIND_SUCCESS:
      return {
        ...state,
        cityFindEditLoading: false,
        cityFindEditSuccess: action.payload,
      };
    case CITY_MASTER_EDIT_FIND_ERR:
      return {
        ...state,
        cityFindEditLoading: false,
        cityFindEditErr: action.payload,
      };
    default:
      return state;
  }
};

// cityMasterEditReducer
export const cityMasterEditReducer = (state = {}, action) => {
  switch (action.type) {
    case CITY_MASTER_EDIT_REQUEST:
      return {
        ...state,
        cityEditLoading: true,
      };
    case CITY_MASTER_EDIT_SUCCESS:
      return {
        ...state,
        cityEditLoading: false,
        cityEditSuccess: action.payload,
      };
    case CITY_MASTER_EDIT_ERR:
      return {
        ...state,
        cityEditLoading: false,
        cityEditErr: action.payload,
      };
    default:
      return state;
  }
};
