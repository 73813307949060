import {
  USER_CONFIRM_OTP_ERR,
  USER_CONFIRM_OTP_REQUEST,
  USER_CONFIRM_OTP_SUCCESS,
  USER_LOGIN_ERR,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SET_NEW_PASSWORD_ERR,
  USER_SET_NEW_PASSWORD_REQUEST,
  USER_SET_NEW_PASSWORD_SUCCESS,
  USER_OTP_REQUEST,
  USER_OTP_SUCCESS,
  USER_OTP_ERR,
  USER_FIND_REQUEST,
  USER_FIND_SUCCESS,
  USER_FIND_ERR,
} from "../constants/adminAuthConstant";

// Login submit Reducer
export const loginSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loginSubmitLoading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loginSubmitLoading: false,
        loginSuccess: action.payload,
      };
    case USER_LOGIN_ERR:
      return {
        ...state,
        loginSubmitLoading: false,
        loginSubmiterror: action.payload,
      };
    default:
      return state;
  }
};

// findUserReducer

export const findUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FIND_REQUEST:
      return {
        ...state,
        loginFindLoading: true,
      };
    case USER_FIND_SUCCESS:
      return {
        loginFindLoading: false,
        loginFindSuccess: action.payload,
      };
    case USER_FIND_ERR:
      return {
        ...state,
        loginFindLoading: false,
        loginFinderror: action.payload,
      };
    default:
      return state;
  }
};

// forgotPassSentOTPReducer
export const forgotPassSentOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_OTP_REQUEST:
      return {
        ...state,
        forgotPassSentOTPLoading: true,
      };
    case USER_OTP_SUCCESS:
      return {
        ...state,
        forgotPassSentOTPLoading: false,
        forgotPassSentOTPSuccess: action.payload,
      };
    case USER_OTP_ERR:
      return {
        ...state,
        forgotPassSentOTPLoading: false,
        forgotPassSentOTPerror: action.payload,
      };
    default:
      return state;
  }
};

// confirmOTPReducer
export const confirmOTPReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_OTP_REQUEST:
      return {
        ...state,
        confirmOTPLoading: true,
      };
    case USER_CONFIRM_OTP_SUCCESS:
      return {
        ...state,
        confirmOTPLoading: false,
        confirmOTPSuccess: action.payload,
      };
    case USER_CONFIRM_OTP_ERR:
      return {
        ...state,
        confirmOTPLoading: false,
        confirmOTPerror: action.payload,
      };
    default:
      return state;
  }
};

// new set password reducer
export const newPasswordSetReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        newPasswordLoading: true,
      };
    case USER_SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordLoading: false,
        newPasswordSuccess: action.payload,
      };
    case USER_SET_NEW_PASSWORD_ERR:
      return {
        ...state,
        newPasswordLoading: false,
        newPasserror: action.payload,
      };
    default:
      return state;
  }
};
