import React, { useState } from "react";
import { MainContainer } from "../content/content.element";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import BasicTabs from "../Tabs/TabsMain";
import { Box } from "@mui/material";

function HrMain(props) {
  const [wichTab, setWichTab] = useState(0);

  const callback = (tab) => {
    setWichTab(tab);
  };
  return (
    <MainContainer active={props.toggle}>
      <Box>
        <HeadBreadcrumbs
          head="Home"
          sechead="Human resources"
          first="Human Resources"
          main={
            wichTab === 0
              ? "Attendance"
              : wichTab === 1
              ? "Holiday List"
              : wichTab === 2
              ? "Leave Application"
              : wichTab === 3
              ? "Payslip"
              : wichTab === 4
              ? "Jobs"
              : null
          }
        />
      </Box>
      <Box>
        <BasicTabs
          tab1Name="Attendance"
          tab2Name="Holiday List"
          tab3Name="Leave Application"
          tab4Name="Payslip"
          tab5Name="Jobs"
          tab1={props.tab1}
          tab2={props.tab2}
          tab3={props.tab3}
          tab4={props.tab4}
          tab5={props.tab5}
          callback={callback}
        />
      </Box>
    </MainContainer>
  );
}

export default HrMain;
