import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import InputField, {
  SelectInputField,
} from "../../../customComponent/InputField";
import { TypographyText } from "../../../customComponent/Typography";
import { useDispatch, useSelector } from "react-redux";
import { universityViewAction } from "../../../../actions/universityActions/universityManagmentAction";
import { useParams } from "react-router-dom";

function EditeligibleCerteria({ handleComplete }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [qualification, setQualification] = useState("");
  const [test, setTest] = useState([]);
  const [testData, setTestData] = useState(["IELTS", "GMAT", "TOEFL"]);
  const [workExperience, setWorkExperience] = useState("");
  const [remark, setRemark] = useState("");
  const [documentRequired, setDocumentRequired] = useState("");
  const [qualificationError, setQualificationError] = useState(false);
  const [testError, setTestError] = useState(false);
  const [workExperienceError, setWorkExperienceError] = useState(false);
  const [remarkError, setRemarkError] = useState(false);

  const qualificationdData = ["SSLC", "HIGHER SECONDARY", "UG", "PG"];

  const {
    universityViewByIdLoading,
    universityViewByIdSuccess,
    universityViewByIdErr,
  } = useSelector((state) => {
    return state.universityView;
  });

  useEffect(() => {
    dispatch(universityViewAction(id));
  }, [dispatch]);

  // Edit details
  console.log(
    universityViewByIdSuccess?.basicInfo?.documentRequired,
    "universityViewByIdSuccessuniversityViewByIdSuccess"
  );

  useEffect(() => {
    if (universityViewByIdSuccess) {
      const eligibilityInfo =
        universityViewByIdSuccess?.universityEligibilityCriteria;
      if (eligibilityInfo) {
        setQualification(eligibilityInfo?.eligibilityQualification);
        setWorkExperience(eligibilityInfo?.eligibilityWorkExperience);
        setRemark(eligibilityInfo?.eligibilityRemark);
        console.log(eligibilityInfo?.eligibile_certeria_entrance_requireds.map(
          (value) => value.entranceRequired
        ),"cdcdscsdcsdcsd")
        setTest(
          eligibilityInfo?.eligibile_certeria_entrance_requireds.map(
            (value) => value.entranceRequired
          )
        );
      }
      setDocumentRequired(
        universityViewByIdSuccess?.basicInfo?.documentRequired
      );
    }
  }, [universityViewByIdSuccess]);

  useEffect(() => {
    // Retrieve column visibility state from localStorage
    const storedQualificationColumn = localStorage.getItem("editqualification");
    const storedTestTestColumn = localStorage.getItem("edittest");
    const storedWorkExperianceColumn = localStorage.getItem(
      "editworkExperience"
    );
    const storedRemarkColumn = localStorage.getItem("editremark");
    const storedDocumentRequiredColumn = localStorage.getItem(
      "editdocumentRequired"
    );

    console.log(storedDocumentRequiredColumn, "dcdcsdcsdcs");

    if (storedQualificationColumn !== null) {
      setQualification(JSON.parse(storedQualificationColumn));
    }
    if (storedTestTestColumn !== null) {
      setTest(JSON.parse(storedTestTestColumn));
    }
    if (storedWorkExperianceColumn !== null) {
      setWorkExperience(JSON.parse(storedWorkExperianceColumn));
    }
    if (storedRemarkColumn !== null) {
      setRemark(JSON.parse(storedRemarkColumn));
    }

    if (storedDocumentRequiredColumn !== null) {
      setDocumentRequired(JSON.parse(storedDocumentRequiredColumn));
    }
  }, []);

  const handleQualificationChange = (event) => {
    setQualification(event.target.value);
    setQualificationError(false);
  };

  const handleTestChange = (event) => {
    // const {
    //   target: { value },
    // } = event;

    // const filteredArray = value.filter((obj) => obj);

    // setTest((prevRanking) => {
    //   const updatedRanking = [...prevRanking]; // Create a copy of the current ranking state

    //   filteredArray.forEach((selectedItem) => {
    //     // console.log(selectedItem,"selectedItemselectedItem");
    //     const existingIndex = updatedRanking.findIndex(
    //       (item) => item === selectedItem
    //     );
    //     // console.log(existingIndex,"existingIndexexistingIndexexistingIndex");

    //     if (existingIndex !== -1) {
    //       // Item already exists in the ranking, remove it
    //       updatedRanking.splice(existingIndex, 1);
    //     } else {
    //       // Item doesn't exist in the ranking, add it
    //       updatedRanking.push(selectedItem);
    //       console.log(updatedRanking, "selectedItemselectedItemselectedItem");
    //     }
    //   });

    //   return updatedRanking;
    // });

    // setTypesError(false);
    // setTest(event.target.value);
    setTest(event);
    setTestError(false);
  };

  const handleWorkExperienceChange = (event) => {
    setWorkExperience(event.target.value);
    setWorkExperienceError(false);
  };

  const handleRemarkChange = (event) => {
    setRemark(event.target.value);
    setRemarkError(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = true;

    // Validate form fields
    if (qualification.trim() === "") {
      setQualificationError(true);
      isValid = false;
    }

    if (test === "") {
      setTestError(true);
      isValid = false;
    }

    // if (workExperience.trim() === "") {
    //   setWorkExperienceError(true);
    //   isValid = false;
    // }

    if (remark.trim() === "") {
      setRemarkError(true);
      isValid = false;
    }

    if (isValid) {
      // Perform form submission or any desired action

      localStorage.setItem("editqualification", JSON.stringify(qualification));

      localStorage.setItem("edittest", JSON.stringify(test));
      localStorage.setItem(
        "editworkExperience",
        JSON.stringify(workExperience)
      );
      localStorage.setItem("editremark", JSON.stringify(remark));
      localStorage.setItem(
        "editdocumentRequired",
        JSON.stringify(documentRequired)
      );

      handleComplete("completed");
      console.log("Form submitted successfully");
    }
  };

  console.log(
    documentRequired,
    "documentRequireddocumentRequireddocumentRequireddocumentRequired"
  );

  return (
    <>
      {/* <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }}>
        Eligibility Criteria
      </Typography> */}
      <TypographyText title="Eligibility Criteria" />

      <Box sx={{ mt: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={12} md={4} lg={4}>
              {/* <TextField
                label="Qualification"
                value={qualification}
                onChange={handleQualificationChange}
                fullWidth
                required
                error={qualificationError}
                helperText={
                  qualificationError ? "Qualification is required" : ""
                }
              /> */}
              <SelectInputField
                label="Qualification"
                handleSelectChange={handleQualificationChange}
                widthSize="123px"
                value={qualification}
                mapValue={qualificationdData}
                mapMethod="qualificationData"
              />

              {/* <InputField
                label="Qualification"
                handleChange={handleQualificationChange}
                widthSize="97%"
                value={qualification}
                isRequired={true}
              /> */}
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              {/* <TextField
                label="Test"
                select
                value={test}
                onChange={handleTestChange}
                fullWidth
                required
                error={testError}
                helperText={testError ? "Test is required" : ""}
              >
                <MenuItem value="ILTES">ILTES</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
              </TextField> */}

              <SelectInputField
                label="Entrance Required"
                handleSelectChange={handleTestChange}
                widthSize="123px"
                value={test}
                mapValue={testData}
                mapMethod="entrance"
                multiple="true"
              />
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={4} lg={4}>
              {/* <TextField
                label="Work Experience"
                type="date"
                value={workExperience}
                onChange={handleWorkExperienceChange}
                fullWidth
                required
                error={workExperienceError}
                helperText={
                  workExperienceError ? "Work Experience is required" : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}

              <InputField
                label="Work Experience"
                handleChange={handleWorkExperienceChange}
                widthSize="97%"
                value={workExperience}
                InputType="text"
              />
            </Grid>

            {/* Third Column */}
            <Grid item xs={12} md={12}>
              {/* <TextField
                label="Remark"
                value={remark}
                onChange={handleRemarkChange}
                fullWidth
                required
                error={remarkError}
                helperText={remarkError ? "Remark is required" : ""}
              /> */}

              <InputField
                label="Remark"
                handleChange={handleRemarkChange}
                widthSize="99%"
                value={remark}
                InputType="discription"
                dispcriptionHeight="130px"
                isRequired={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyText title="Documents required while admission" />
            </Grid>

            <Grid
              item
              lg={12}
              sx={{
                "& .floating_label_field": {
                  top: "-17px !important",
                },
              }}
            >
              <InputField
                label="Documents Required"
                handleChange={(e) => setDocumentRequired(e.target.value)}
                widthSize="99%"
                value={documentRequired}
                InputType="discription"
                dispcriptionHeight="80px"
                maxLength={250}
                placeholder={
                  " This is Not Description Field. This is the Required Document Field. Please Add Only Required Text Here eg : sslc,adhar,plus two like this."
                }
                rows={"10"}
                isRequired={true}
              />
            </Grid>
            {/* <Grid item lg={6}>
              <Typography>
                This is Not Description Field. This is the Required Document
                Field. Please Add Only Required Text Here eg : sslc,adhar,plus
                two like this.
              </Typography>
            </Grid> */}
          </Grid>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <OutLinedButton
              title="Previous"
              submit=""
              widthSize="105px"
              heightSize="45px"
              type="click"
              // handleClick={handleBack}
            />

            <SubmitButton
              title="Next"
              submit="submit"
              widthSize="105px"
              heightSize="45px"
              type=""
              handleBack=""
            />
          </div>
        </form>
      </Box>
    </>
  );
}

export default EditeligibleCerteria;
