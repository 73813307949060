import React, { useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { universityCourseFindAction } from "../../../actions/universityActions/courseActions/universityCourseAction";

function UniversityCourseDetails(props) {
  const { id, universityId } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();

  let { universityCourseEditFindSuccess } = useSelector((state) => {
    return state.courseEditFind;
  });

  useEffect(() => {
    if (universityCourseEditFindSuccess) {
      setData(universityCourseEditFindSuccess.data);
    }
  }, [universityCourseEditFindSuccess]);

  useEffect(() => {
    dispatch(universityCourseFindAction(id));
  }, [id]);

  console.log(
    universityCourseEditFindSuccess,
    "universityCourseEditFindSuccessuniversityCourseEditFindSuccess"
  );

  return (
    <MainContainer active={props.toggle}>
      <InfoHeader
        headTitle={data?.courseTitle}
        url={`/university/addUniversity/ViewUniversity/courses/${universityId}`}
      />
      <Box
        sx={{
          my: "30px",
        }}
      >
        <Box
          sx={{
            border: "1px solid #ECECEC",
            borderRadius: "4px",
            padding: "25px",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))",
              gap: "20px",
              columnGap: "40px",
            }}
          >
            <Box
              sx={{
                maxWidth: "680px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000000",
                  fontWeight: "600",
                  mb: "8px",
                }}
              >
                Course Description
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#00000099",
                }}
              >
                {data?.courseDescription ? data?.courseDescription : "-"}
              </Typography>
            </Box>

            <Box
              sx={{
                maxWidth: "680px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000000",
                  fontWeight: "600",
                  mb: "8px",
                }}
              >
                Syllabus
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#00000099",
                  mb: "8px",
                }}
              >
                {data?.syllabus ? data?.syllabus : "-"}
              </Typography>
              <Button
                component="a"
                href={data?.syllabusPdf}
                target="_blank"
                sx={{
                  color: "#141E3C",
                  border: "1px solid #141E3C",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  fontSize: "14px",
                  lineHeight: "24px",
                  boxSizing: "border-box",
                  textTransform: "capitalize",
                  textDecoration: "none",
                }}
              >
                Download Syllabus Pdf
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))",
              gap: "20px",
              columnGap: "40px",
              mt: "40px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit,minmax(200px,1fr))",
              }}
            >
              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Course Level
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.courseLevel ? data?.courseLevel : "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Subject
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.subject ? data?.subject : "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Total Duration
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.CourseDuration ? data?.CourseDuration : "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Mode of Study
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.modeOfStudy ? data?.modeOfStudy : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Placement Availability
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.placeMentDescription
                      ? data?.placeMentDescription
                      : "No"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Prefer Course without Mathematics
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.WithoutMathamatics ? "Yes" : "No"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Top Course
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.isTopCourse ? "Yes" : "No"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Internship Experience
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.internshipExperience
                      ? data?.internshipExperience
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit,minmax(200px,400px))",
              }}
            >
              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Total Fee
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.totalFee ? data?.totalFee : "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    Extracurricular Activity
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.extracurricular ? data?.extracurricular : "-"}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#000000",
                      fontWeight: "600",
                      mb: "8px",
                    }}
                  >
                    NGO/Equivalent Organization Experience
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#00000099",
                      mb: "8px",
                    }}
                  >
                    {data?.ngoEqExperience ? data?.ngoEqExperience : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(300px,1fr))",
            gap: "20px",
            columnGap: "40px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000000",
                fontWeight: "600",
                my: "12px",
              }}
            >
              Education Eligibility
            </Typography>

            <Box
              sx={{
                overflowX: "scroll",
                maxWidth: "100%",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <TableContainer sx={{ minWidth: "600px" }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#F9F9FB",
                      "& th": {
                        fontWeight: "600",
                        textTransform: "uppercase",
                        color: "#666E7D",
                        borderBottom: "1px solid #F9F9FB",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell>sl no</TableCell>
                      <TableCell>Board</TableCell>
                      <TableCell>Mark obtained</TableCell>
                      <TableCell>year</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {data?.Course_Boards.map((value, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{value.board}</TableCell>
                        <TableCell>{value.boardScore}</TableCell>
                        <TableCell>{value.eligibleYear}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000000",
                fontWeight: "600",
                my: "12px",
              }}
            >
              Language Test Qualification
            </Typography>
            <Box
              sx={{
                overflowX: "scroll",
                maxWidth: "100%",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <TableContainer sx={{ minWidth: "600px" }}>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#F9F9FB",
                      "& th": {
                        fontWeight: "600",
                        textTransform: "uppercase",
                        color: "#666E7D",
                        borderBottom: "1px solid #F9F9FB",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell>sl no</TableCell>
                      <TableCell>Test</TableCell>
                      <TableCell>Mark obtained</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {data?.Course_Tests.map((value, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{value.test}</TableCell>
                        <TableCell>{value.testScore}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "30px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "600",
              mb: "8px",
            }}
          >
            Undergraduate Diploma Mark in percentage
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#00000099",
              mb: "8px",
            }}
          >
            {data?.underDeplomo ? data?.underDeplomo : "-"}%
          </Typography>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default UniversityCourseDetails;
