import React, { useEffect, useState } from "react";
import { SubmitButton } from "../../customComponent/Buttons";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  MultiSelect,
  NormalInputField,
  NormalSelect,
} from "../../customComponent/InputField";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  findAllPartnerAction,
  partnerNotificationAddAction,
} from "../../../actions/partnerAction/partnerAction";
import { useDispatch, useSelector } from "react-redux";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import { hrEmployeeFindAction } from "../../../actions/HrActions/hrEmployeeManagmentAction";

function AddNotification({ open, handleClose }) {
  const dispatch = useDispatch();

  let { partnerNotificationCreateSuccess, partnerNotificationCreateError } =
    useSelector((state) => {
      return state.partnerNotificationCreate;
    });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { partnerFindSuccess } = useSelector((state) => {
    return state.findAllPartner;
  });

  let { hrEmployeeFindSuccess } = useSelector((state) => {
    return state.hrEmployeeFind;
  });

  useEffect(() => {
    if (partnerFindSuccess) {
      let data = partnerFindSuccess.map((item) => ({
        label: item.name,
        id: item.id,
      }));
      setPartnerOptions(data);
    }
  }, [partnerFindSuccess]);

  useEffect(() => {
    if (hrEmployeeFindSuccess) {
      let data = hrEmployeeFindSuccess.map((item) => ({
        label: item.firstName,
        id: item.id,
      }));
      setEmployeeOptions(data);
    }
  }, [hrEmployeeFindSuccess]);

  useEffect(() => {
    dispatch(findAllPartnerAction());
    dispatch(hrEmployeeFindAction());
  }, []);

  useEffect(() => {
    if (partnerNotificationCreateSuccess && successAlertMsgSuccess) {
      Swal.fire("Added!", "Notification Send Successfully.", "success");
      dispatch(successTrueMsgAction(false));
      handleModalClose();
    }
  }, [partnerNotificationCreateSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (partnerNotificationCreateError && successAlertMsgErr) {
      Swal.fire("Something Went Wrong!", "", "error");
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerNotificationCreateError, successAlertMsgErr]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleModalClose = () => {
    setFormData({
      subject: null,
      user: null,
      selectedPartner: [],
      content: null,
      content: null,
    });
    setEditorState(() => EditorState.createEmpty());
    handleClose();
  };

  const [formData, setFormData] = useState({});
  const [partnerOptions, setPartnerOptions] = useState([]);
  const partnerOptionMap = Object.fromEntries(
    partnerOptions.map((e) => [e.id, e.label])
  );
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const employeeOptionMap = Object.fromEntries(
    employeeOptions.map((e) => [e.id, e.label])
  );

  const [error, setError] = useState();

  const toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "embedded",
      "emoji",
      "image",
      "remove",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
    },
    fontSize: {
      options: [10, 12, 14, 16, 18, 24, 30, 36],
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    textAlign: {
      options: ["left", "center", "right"],
    },
    colorPicker: {
      colors: [
        "rgba(0,0,0,0.65)",
        "rgba(255,0,0,0.65)",
        "rgba(255,255,0,0.65)",
        "rgba(0,0,255,0.65)",
      ],
    },
  };

  const validate = () => {
    let error = {};
    if (!formData?.subject) {
      error.subject = "Subject is required";
    }
    if (!formData?.user) {
      error.user = "Please select a user";
    }
    if (
      formData?.user === "Partners" &&
      (!formData?.selectedPartner || formData?.selectedPartner.length === 0)
    ) {
      error.selectedPartner = "Please select partners";
    }
    if (
      formData?.user === "Employee" &&
      (!formData?.selectedEmployee || formData?.selectedEmployee.length === 0)
    ) {
      error.selectedEmployee = "Please select Employees";
    }
    if (!formData?.content || formData?.content === "<p><br></p>") {
      error.content = "Please enter some contents";
    }
    if (!formData?.file) {
      error.file = "Please choose a file";
    }

    setError(error);
    return Object.keys(error).length === 0;
  };

  let options = [
    "All Users",
    "Students",
    "Partners",
    "Employee",
    "Accommodation",
  ];

  const handleSubmit = () => {
    if (validate()) {
      const newFormData = new FormData();
      newFormData.append("subject", formData.subject);
      newFormData.append("user", formData.user);
      newFormData.append("selectedPartner", formData.selectedPartner);
      newFormData.append("selectedEmployee", formData.selectedEmployee);
      newFormData.append("content", formData.content);
      newFormData.append("uploadedFile", formData.file);
      dispatch(partnerNotificationAddAction(newFormData));
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleEditorChange = (state) => {
    const contentState = state.getCurrentContent();
    const html = stateToHTML(contentState);
    setEditorState(state);
    setFormData((prevSlots) => ({
      ...prevSlots,
      content: html,
    }));

    setError((prev) => ({
      ...prev,
      ["content"]: "",
    }));
  };

  const handleFileChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      ["file"]: event.target.files[0],
    }));
    setError((prev) => ({
      ...prev,
      ["file"]: "",
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "850px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "18px",
            fontFamily: "Montserrat', sans-serif",
          }}
        >
          New Notification
        </Typography>
        <CloseIcon
          onClick={handleModalClose}
          sx={{
            cursor: "pointer",
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            pt: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            "& .MuiTextField-root": {
              width: "100%",
            },
            "& .public-DraftEditorPlaceholder-root": {
              color: Boolean(error?.content) ? "#d32f2f" : "#05050F",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px !important",
            },
            "& .MuiInputLabel-root": {
              color: "#05050F",
            },
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              border: "1px solid #ECECEC !important",
            },
            "&  .MuiTextField-root .MuiOutlinedInput-root  fieldset.MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #ECECEC !important",
              },
            "&  .MuiTextField-root .MuiOutlinedInput-root.Mui-error  fieldset.MuiOutlinedInput-notchedOutline":
              {
                border: "1px solid #d32f2f !important",
              },
          }}
        >
          <NormalInputField
            label="Subject"
            value={formData?.subject}
            error={Boolean(error?.subject)}
            helperText={error?.subject}
            handleChange={handleInput}
            name={"subject"}
          />

          <NormalSelect
            label={"Targeted users"}
            value={formData?.user}
            error={Boolean(error?.user)}
            helperText={error?.user}
            name={"user"}
            options={options}
            mapMethod={"targetedUsers"}
            handleChange={handleInput}
          />

          {formData?.user === "Partners" && (
            <Box
              sx={{
                "& .MuiPaper-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                  padding: "0",
                },
                "& .MuiFormControl-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                },
                "& .MuiInputLabel-root.Mui-error ": {
                  color: "#d32f2f !important",
                },
              }}
            >
              <Autocomplete
                id="using-simple-state"
                multiple
                value={formData?.selectedPartner}
                onChange={(_event, val) => {
                  setFormData((prev) => ({
                    ...prev,
                    ["selectedPartner"]: val,
                    ["selectedEmployee"]: [],
                  }));
                  setError((prev) => ({
                    ...prev,
                    ["selectedPartner"]: "",
                  }));
                }}
                sx={{
                  "& .MuiInputLabel-root ": {
                    color: "#05050F !important",
                  },
                  "& legend ": {
                    width: "95px",
                  },
                }}
                popupIcon={
                  <KeyboardArrowDown
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  />
                }
                options={partnerOptions.map((c) => c.id)}
                getOptionLabel={(opt) => partnerOptionMap[opt]}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Select Partners"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(error?.selectedPartner)}
                      helperText={error?.selectedPartner}
                    />
                  );
                }}
              />
            </Box>
          )}

          {formData?.user === "Employee" && (
            <Box
              sx={{
                "& .MuiPaper-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                  padding: "0",
                },
                "& .MuiFormControl-root": {
                  height: "100%",
                  width: "100%",
                  margin: "0",
                },
                "& .MuiInputLabel-root.Mui-error ": {
                  color: "#d32f2f !important",
                },
              }}
            >
              <Autocomplete
                id="using-simple-state"
                multiple
                value={formData?.selectedEmployee}
                onChange={(_event, val) => {
                  setFormData((prev) => ({
                    ...prev,
                    ["selectedEmployee"]: val,
                    ["selectedPartner"]: [],
                  }));
                  setError((prev) => ({
                    ...prev,
                    ["selectedEmployee"]: "",
                  }));
                }}
                sx={{
                  "& .MuiInputLabel-root ": {
                    color: "#05050F !important",
                  },
                  "& legend ": {
                    width: "95px",
                  },
                }}
                popupIcon={
                  <KeyboardArrowDown
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  />
                }
                options={employeeOptions.map((c) => c.id)}
                getOptionLabel={(opt) => employeeOptionMap[opt]}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Select Employees"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      error={Boolean(error?.selectedEmployee)}
                      helperText={error?.selectedEmployee}
                    />
                  );
                }}
              />
            </Box>
          )}

          <Box
            sx={{
              //   overflow: "hidden",
              "& .rdw-editor-main": {
                paddingX: "20px",
                border: `1px solid ${
                  Boolean(error?.content) ? "#d32f2f" : "#ECECEC"
                }`,
                minHeight: "200px",
                cursor: "text",
              },
            }}
          >
            <Editor
              toolbar={toolbarOptions}
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              placeholder="Content"
              wrapperClassName="rdw-editor-wrapper"
              editorClassName="rdw-editor-main"
            />
            {Boolean(error?.content) && (
              <FormHelperText
                sx={{
                  color: "#d32f2f",
                  ml: "14px",
                }}
              >
                {error.content}
              </FormHelperText>
            )}
          </Box>

          <Box
            sx={{
              border: !Boolean(error?.file)
                ? "1px solid #ECECEC"
                : "1px solid #d32f2f",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 10px",
              color: !Boolean(error?.file) ? "#05050F" : "#d32f2f",
              fontSize: "16px",
              "& label": {
                display: "flex",
                alignItems: "center",
                gap: "10px",
                cursor: "pointer",
              },
            }}
          >
            <label for="upload">
              {formData?.file ? (
                <Typography>{formData?.file?.name}</Typography>
              ) : (
                <>
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.6501 2.40898C15.223 0.0824223 11.2737 0.0824223 8.84647 2.40898L1.30006 9.64264C-0.433462 11.3046 -0.433348 14.0087 1.30037 15.6705C2.16728 16.5015 3.30564 16.917 4.44446 16.9169C5.58298 16.9168 6.72188 16.5014 7.58868 15.6705L14.506 9.03972C15.5463 8.04273 15.5464 6.42025 14.5061 5.4229C13.4658 4.42579 11.7731 4.42583 10.733 5.42308L6.2094 9.75912C5.86211 10.092 5.86211 10.6318 6.20933 10.9647C6.55669 11.2976 7.11968 11.2976 7.46704 10.9647L11.9907 6.62854C12.3374 6.29615 12.9017 6.29615 13.2484 6.62843C13.5951 6.9609 13.5951 7.50165 13.2483 7.83404L6.33108 14.4649C5.29076 15.462 3.59824 15.4621 2.55801 14.465C1.51781 13.4678 1.51769 11.8453 2.55778 10.8482L10.1041 3.61456C11.8379 1.95282 14.6588 1.95274 16.3925 3.61463C17.2324 4.41963 17.695 5.49002 17.6949 6.6285C17.6949 7.76703 17.2324 8.83738 16.3924 9.64245L8.84628 16.8762C8.49899 17.2091 8.49899 17.7488 8.84635 18.0818C9.02002 18.2483 9.24761 18.3315 9.47517 18.3315C9.70273 18.3315 9.93037 18.2482 10.104 18.0818L17.6501 10.8481C18.8259 9.72106 19.4735 8.22254 19.4735 6.62858C19.4736 5.03454 18.826 3.53602 17.6501 2.40898Z"
                      fill={!Boolean(error?.file) ? "#141E3C" : "#d32f2f"}
                    />
                  </svg>
                  Attach File
                </>
              )}
            </label>
            <input
              accept=".pdf"
              id="upload"
              type="file"
              onChange={(e) => handleFileChange(e)}
              style={{ display: "none" }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "20px 24px",
          pt: "0",
        }}
      >
        <SubmitButton
          title="send"
          submit=""
          widthSize="81px"
          heightSize="35px"
          handleSubmit={handleSubmit}
          type="click"
        />
      </DialogActions>
    </Dialog>
  );
}

export default AddNotification;
