import React, { useEffect, useState } from "react";
import { EmployeeLeaveApplicationTable } from "./EmployeeLeaveApplicationTable";
import { Alert, AlertTitle, Grid } from "@mui/material";
import { SubmitButton } from "../customComponent/Buttons";
import { hrEmployeeLeaveApplicationFindAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialogue from "../customComponent/Dialogue";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../customComponent/premissionDialog";

function AllEmployeeLeaveTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [LeaveApplicationlist, SetLeaveApplicationList] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (isCreatePrevilage("leave application")) {
      setOpen(true);
    } else {
      setOpenAlert(true);
    }
  };
  const handleClose = () => setOpen(false);
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  let { hrEmployeeLeaveApplicationFindSuccess } = useSelector((state) => {
    return state.hrEmployeeLeaveApplicationFind;
  });

  let { leaveApplicationStatusChangeSuccess } = useSelector((state) => {
    return state.leaveApplicationStatusChange;
  });

  let { hrEmployeeLeaveApplicationApplySuccess } = useSelector((state) => {
    return state.hrEmployeeLeaveApplicationApply;
  });
  // Fetch_Data //

  useEffect(() => {
    dispatch(hrEmployeeLeaveApplicationFindAction());
  }, [
    dispatch,
    leaveApplicationStatusChangeSuccess,
    hrEmployeeLeaveApplicationApplySuccess,
  ]);

  //set_data_to_state

  useEffect(() => {
    if (hrEmployeeLeaveApplicationFindSuccess) {
      SetLeaveApplicationList(hrEmployeeLeaveApplicationFindSuccess);
    }
  }, [hrEmployeeLeaveApplicationFindSuccess]);

  const data = LeaveApplicationlist.map((Leaves, index) => ({
    sl: index + 1,
    id: Leaves.id,
    leaveId: Leaves.leaves[0].id,
    coloum1: Leaves.leaves[0].leaveEmployeeName,
    coloum2: Leaves.employeeId,
    coloum3: Leaves.leaves[0].leaveType,
    coloum4: Leaves.leaves[0].leaveStartDate,
    coloum5: Leaves.leaves[0].leaveEndDate,
    coloum6: Leaves.leaves[0].leaveDuration,
    coloum7: Leaves.leaves[0].leaveStatus,
  }));

  if (!hasPrivilege("leave application") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      {/* {hasPrivilege("leave application") && privilege == null ? ( */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Create"
            submit=""
            widthSize="136px"
            heightSize="38px"
            type="click"
            handleSubmit={handleOpen}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <EmployeeLeaveApplicationTable
            columns={[
              {
                title: "SL NO",
                field: "SL NO",
              },
              {
                title: "EMPLOYEE NAME",
                field: "EMPLOYEE NAME",
              },
              {
                title: "ID",
                field: "ID",
              },
              {
                title: "LEAVE TYPE",
                field: "LEAVE TYPE",
              },
              {
                title: "START DATE",
                field: "START DATE",
              },
              {
                title: "END DATE",
                field: "END DATE",
              },
              {
                title: "DURATION",
                field: "DURATION",
              },
              {
                title: "STATUS",
                field: "STATUS",
              },
            ]}
            data={data}
            component="LeaveApplications"
            subMenu="leave application"
            //   isPresentMap={isPresent}
          />
        </Grid>
      </Grid>
      {/* ) : (
        <Alert severity="error">
          <AlertTitle>warning</AlertTitle>
          You don't have permission to access — <strong>this page.!</strong>
        </Alert>
      )}  */}

      {open && (
        <Dialogue
          openTrue={open}
          handleClose={handleClose}
          headerName="Leave Application Form"
          Dailogcomponent="LeaveApplicationForm"
          width="100%"
          margintop="5%"
          height="75%"
        />
      )}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default AllEmployeeLeaveTable;
