import { Box } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import IeltsSettingsZoomMeetingTable from './IeltsSettingsZoomMeetingTable';
import { useDispatch, useSelector } from 'react-redux';
import { ieltsSettingsZoomConfigFindAction } from '../../../../actions/IeltsActions/SettingsAction';

function IeltsZoomConfigurationMain() {
  const dispatch = useDispatch()
  const [zoomConfigList,setZoomConfigList] = useState([])
  let {
    ieltsSettingsZoomConfigFindSuccess,
    ieltsSettingsZoomConfigFinderror,
  } = useSelector((state) => {
    return state.ieltsSettingsZoomConfigFind;
  });

  useEffect(()=>{
    dispatch(ieltsSettingsZoomConfigFindAction())
  },[dispatch])

  useEffect(()=>{
    if(ieltsSettingsZoomConfigFindSuccess){
      setZoomConfigList(ieltsSettingsZoomConfigFindSuccess?.data)
    }
  },[ieltsSettingsZoomConfigFindSuccess])


  console.log(zoomConfigList,"zoomConfigListzoomConfigList")

  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "Topic Name", field: "topicname" },
    { title: "Host Key", field: "hostkey" },
    { title: "Email", field: "email" },
    // { title: "Meeting ID", field: "meetingId" },
  ];

  


  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };


  return (
    <Box
      sx={{
        mt: 2,
        gap: "25px",
      }}
    >
      <IeltsSettingsZoomMeetingTable
        data={zoomConfigList}
        columns={columns}
        component="ieltsCategory"
        selectBoxIdPassHandler={selectBoxIdPassHandler}
      />
    </Box>
  );
}

export default IeltsZoomConfigurationMain
