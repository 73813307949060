
import axios from "axios";
import { COUNSELOR_RATING_FIND_ERR, COUNSELOR_RATING_FIND_REQUEST, COUNSELOR_RATING_FIND_SUCCESS, SERVICE_RATING_FIND_ERR, SERVICE_RATING_FIND_REQUEST, SERVICE_RATING_FIND_SUCCESS } from "../constants/ratingConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

export const counsilorRatingAction = (skipValue) => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNSELOR_RATING_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/rating/counsilorRatingFind?skipValue=${skipValue}`,
      config
    );

    dispatch({ type: COUNSELOR_RATING_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNSELOR_RATING_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

export const serviceRatingAction = (skipValue) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_RATING_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/rating/serviceRatingFind?skipValue=${skipValue}`,
      config
    );

    dispatch({ type: SERVICE_RATING_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SERVICE_RATING_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
