import {
  LEAD_SOURCE_CREATE_REQUEST,
  LEAD_SOURCE_CREATE_SUCCESS,
  LEAD_SOURCE_DELETE_ERR,
  LEAD_SOURCE_DELETE_REQUEST,
  LEAD_SOURCE_DELETE_SUCCESS,
  LEAD_SOURCE_FIND_DETAILS_ERR,
  LEAD_SOURCE_FIND_DETAILS_REQUEST,
  LEAD_SOURCE_FIND_DETAILS_SUCCESS,
  LEAD_SOURCE_FIND_ERR,
  LEAD_SOURCE_FIND_REQUEST,
  LEAD_SOURCE_FIND_SUCCESS,
} from "../constants/leadSourceConstant";

// leadSourceCreateReducer
export const leadSourceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOURCE_CREATE_REQUEST:
      return {
        ...state,
        createLeadSourceLoading: true,
      };
    case LEAD_SOURCE_CREATE_SUCCESS:
      return {
        ...state,
        createLeadSourceLoading: false,
        createLeadSourceSuccess: action.payload,
      };
    case LEAD_SOURCE_CREATE_SUCCESS:
      return {
        ...state,
        createLeadSourceLoading: false,
        createLeadSourceErr: action.payload,
      };
    default:
      return state;
  }
};

// leadSourceFindReducer
export const leadSourceFindReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOURCE_FIND_REQUEST:
      return {
        ...state,
        findLeadSourceLoading: true,
      };
    case LEAD_SOURCE_FIND_SUCCESS:
      return {
        ...state,
        findLeadSourceLoading: false,
        findLeadSourceSuccess: action.payload,
      };
    case LEAD_SOURCE_FIND_ERR:
      return {
        ...state,
        findLeadSourceLoading: false,
        findLeadSourceErr: action.payload,
      };
    default:
      return state;
  }
};

// leadSourceFindDetailReducer
export const leadSourceFindDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOURCE_FIND_DETAILS_REQUEST:
      return {
        ...state,
        findDetailsLeadSourceLoading: true,
      };
    case LEAD_SOURCE_FIND_DETAILS_SUCCESS:
      return {
        ...state,
        findDetailsLeadSourceLoading: false,
        findDetailsLeadSourceSuccess: action.payload,
      };
    case LEAD_SOURCE_FIND_DETAILS_ERR:
      return {
        ...state,
        findDetailsLeadSourceLoading: false,
        findDetailsLeadSourceErr: action.payload,
      };
    default:
      return state;
  }
};

// deleteLeadSourceReducer
export const deleteLeadSourceReducer = (state = {}, action) => {
  switch (action.type) {
    case LEAD_SOURCE_DELETE_REQUEST:
      return {
        ...state,
        deleteLeadSourceLoading: true,
      };
    case LEAD_SOURCE_DELETE_SUCCESS:
      return {
        ...state,
        deleteLeadSourceLoading: false,
        deleteLeadSourceSuccess: action.payload,
      };
    case LEAD_SOURCE_DELETE_ERR:
      return {
        ...state,
        deleteLeadSourceLoading: false,
        deleteLeadSourceErr: action.payload,
      };
    default:
      return state;
  }
};
