import React, { forwardRef, useRef, useState } from "react";

import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Chip } from "@material-ui/core";

import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

import "./inputField.css";
import { RiSearch2Line } from "react-icons/ri";
import { format, getMonth, getYear } from "date-fns";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { countries } from "countries-list";
import CountryFlag from "./CountryFlag";
import ReactDatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect } from "react";
import { CiEdit } from "react-icons/ci";

function InputField({
  label,
  handleChange,
  widthSize,
  value,
  required,
  paramses,
  InputType,
  dispcriptionHeight,
  multiple,
  paddingRight,
  maxLength,
  name,
  placeholder,
  autocomplete,
  rows,
  isRequired,
  readonly,
  error,
  helperText,
  requiredSymbol
}) {
  return (
    <Box
      sx={{
        "& input + .floating_label_field": {
          top: value === "" ? "9.3px !important" : undefined,
          fontSize: value === "" ? "14px !important" : "11px !important"
        },
        "& input:focus + .floating_label_field": {
          top: "-11px !important"
        }
      }}
    >
      <div className={error ? "input-group input-error" : "input-group"}>
        {InputType !== "discription" ? (
          <>
            <input
              {...paramses}
              readOnly={readonly ? readonly : false}
              disabled={readonly ? readonly : false}
              type={
                InputType === "date"
                  ? "date"
                  : InputType === "month"
                  ? "month"
                  : InputType === "password"
                  ? "password"
                  : InputType === "email"
                  ? "email"
                  : InputType === "number"
                  ? "number"
                  : InputType === "text"
                  ? "text"
                  : InputType === "time"
                  ? "time"
                  : ""
              }
              name={name ? name : "payee_name"}
              className="input_style floating_input_field"
              autoComplete={autocomplete}
              id="payee_name"
              required={isRequired ? isRequired : required ? required : false}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              style={{
                width: "100%",
                maxWidth: widthSize,
                paddingRight: paddingRight ? paddingRight : "",
                paddingLeft: "15px !important",
                paddingRight: "15px !important",
                color: readonly ? "gray" : "initial", // Change text color if readonly
                backgroundColor: readonly ? "#f0f0f0" : "white", // Change background color if readonly
                cursor: readonly ? "not-allowed" : "auto" // Show not-allowed cursor if readonly
              }}
              multiple={multiple === true ? multiple : false}
              maxLength={maxLength ? maxLength : ""}
              inputMode="none"
            />
          </>
        ) : (
          <textarea
            name={name}
            className="input_style floating_input_field"
            autoComplete="off"
            id="payee_name"
            placeholder={placeholder ? placeholder : ""}
            maxLength={maxLength ? maxLength : ""}
            required={isRequired ? isRequired : required ? required : false}
            value={value}
            onChange={handleChange}
            style={{
              height: dispcriptionHeight,
              width: widthSize
            }}
            rows={rows ? rows : "150"}
          />
          // </Box>
        )}
        <label className="floating_label_field">
          {label}{" "}
          <span
            className="text-danger"
            style={{ color: "rgba(255, 45, 46, 1)" }}
          >{`${required || isRequired || requiredSymbol ? "*" : ""}`}</span>
        </label>
      </div>
      {error && (
        <Box
          sx={{
            mt: "-15px",
            ml: "4px",
            textAlign: "left"
          }}
        >
          <Typography
            sx={{
              color: "#FF2400",
              fontSize: "12px"
            }}
          >
            {helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default InputField;

// top label input

export const TopInputField = ({
  label,
  handleChange,
  widthSize,
  heightSize,
  value,
  required,
  paramses,
  name
}) => {
  return (
    // <Paper sx={{ height: "30px", pt: 1, pb: 1,border:"1px solid" }} elevation={0}>
    <TextField
      className="textField"
      name={name}
      label={label}
      value={value}
      onChange={handleChange}
      fullWidth
      sx={{
        height: heightSize,
        textAlign: "center",
        // backgroundColor: "white",
        border: "1.2px solid #ececec",
        "& fieldset": { border: "none", borderRadius: "5px", mt: 1 },
        // height: "auto",

        "& .MuiFormLabel-root": {
          height: "30px",
          pt: 1.5,

          "&.Mui-focused": {
            color: "#7d7d7d",
            pt: 1.5
          }
        }
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
    // </Paper>
  );
};

export const NormalInputField = ({
  value,
  handleChange,
  label,
  name,
  error,
  helperText
}) => {
  return (
    <TextField
      error={error}
      helperText={helperText}
      label={label}
      onChange={handleChange}
      value={value}
      name={name}
      sx={{
        "& .MuiInputLabel-root.Mui-focused": { color: "rgba(5, 5, 15, 1)" },
        "& .MuiInputLabel-root.Mui-error": { color: "#d32f2f" },
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#d32f2f !important"
        },
        "& .Mui-error input": {
          color: "#d32f2f"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.23) !important"
        },
        "& fieldset legend span": { fontSize: "0.7rem" }
      }}
    />
  );
};

// top of the label border
export const TopInputFieldBorderLabel = ({
  disabled,
  label,
  name,
  handleChange,
  widthSize,
  heightSize,
  value,
  required,
  paramses,
  type,
  error,
  helperText,
  requiredSymbol,
  handleEditRequest,
  verified
}) => {
  const formatDate = (dateString) => {
    if (dateString) {
      // console.log(dateString, "sdjfhksjdfhksjdhjksdjh");
      const date = new Date(dateString);
      return format(date, "yyyy-MM-dd");
    }
    return;
  };
  return (
    <Paper sx={{ height: "35px", pt: 1, pb: 1 }} elevation={0}>
      <TextField
        disabled={disabled}
        className="textField"
        name={name}
        type={type === "date" ? "date" : type === "month" ? "month" : ""}
        label={
          <>
            {verified && (
              <img
                src="/verified.png"
                className="img-fluid ms-2"
                alt=""
                style={{
                  width: "23px",
                  height: "20px"
                }}
              />
            )}
            {label}
            {requiredSymbol && <span style={{ color: "red" }}> *</span>}
          </>
        }
        value={type === "date" ? formatDate(value) : value}
        onChange={handleChange}
        error={error}
        helperText={helperText}
        fullWidth
        required={required}
        autoComplete="off"
        sx={{
          textAlign: "center",
          // backgroundColor: "white",
          border: "none",
          "& fieldset": {
            border:
              name !== "deferal" ? "none" : "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px"
          },
          // height: "auto",
          "& .MuiFormLabel-root": {
            height: "32px",
            pt: 1,
            // pb:2,

            "&.Mui-focused": {
              color: "#7d7d7d",
              pt: 0.9
            }
          },
          "& .MuiInputLabel-asterisk": {
            color: "red",
            fontSize: "1.2em"
          }
        }}
        InputProps={
          type === "edit"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleEditRequest}
                      edge="end"
                      disabled={false} // Ensures the button is always clickable
                    >
                      <CiEdit size={35} />
                    </IconButton>
                  </InputAdornment>
                )
              }
            : undefined
        }
        InputLabelProps={{
          shrink: true,
          startAdornment:
            type === "month" ? (
              <InputAdornment position="start">
                <Typography variant="body1">
                  {value ? value : "MM/YYYY"}
                </Typography>
              </InputAdornment>
            ) : null
        }}
      />
    </Paper>
  );
};

export const TopMonthOnlyInput = ({
  disabled,
  label,
  name,
  handleChange,
  widthSize,
  heightSize,
  value,
  required,
  paramses,
  type
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        pt: "10px",
        borderRadius: "4px",
        backgroundColor: "#fff",
        "& .react-datepicker-wrapper": {
          width: "100%"
        },
        "& input": {
          width: "100%",
          outline: "none",
          border: "none",
          boxSizing: "border-box",
          p: "0",
          pr: "35px",
          background: "transparent",
          position: "relative",
          zIndex: "2",
          pb: "5px"
        },
        pl: "14px",
        pt: "5px",
        maxWidth: "calc(100% - 14px)"
      }}
    >
      <Typography
        sx={{
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "13px"
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          position: "relative",
          mt: "4px"
        }}
      >
        <ReactDatePicker
          selected={value}
          onChange={handleChange}
          placeholderText="--/--"
          dateFormat="MM/yyyy"
          name={name}
          showMonthYearPicker
        />
        <CalendarMonthIcon
          sx={{
            color: "#000",
            position: "absolute",
            right: "10px",
            top: "0px",
            fontSize: "20px"
          }}
        />
      </Box>
    </Box>
  );
};

// select input field

export function SelectInputField({
  label,
  handleSelectChange,
  widthSize,
  value,
  mapValue,
  mapMethod,
  multiple,
  widthSelect,
  name,
  convertTo12HourFormat,
  disabled,
  required,
  height,
  requiredSymbol
}) {
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const chips = () => {
    let data = mapValue?.filter((slot) => value.includes(slot?.id));
    return data?.map((item) => (
      <Chip key={item.id} label={item.timeFrom + " " + item.timeTo} />
    ));
  };
  const [selectedValue, setSelectedValue] = useState(value);

  console.log(selectedValue, "selectedValueselectedValueselectedValue");

  return (
    <>
      <FormControl
        disabled={disabled ? disabled : false}
        label={label}
        className="selectFormLabelTobeControlled"
        sx={{
          width: "100%"
        }}
        fullWidth
      >
        <InputLabel
          id="lead-source-label"
          className="formControlLabelRole "
          sx={{
            fontWeight: 400,
            px: "5px",
            ml: "-5px",
            color:
              mapMethod === "gender"
                ? ""
                : mapMethod === "leadSource"
                ? ""
                : mapMethod === "allCountry"
                ? ""
                : "black",
            fontSize: "14px"
          }}
        >
          {required || requiredSymbol ? (
            <>
              {label}
              <span
                className="text-danger"
                style={{ color: "rgba(255, 45, 46, 1)" }}
              >
                {" *"}
              </span>
            </>
          ) : (
            label
          )}
        </InputLabel>
        <Select
          value={selectedValue}
          multiple={multiple === "true" ? multiple : null}
          input={
            multiple === "truSelectInputField2e" ? (
              <OutlinedInput id="select-multiple-chip" label="Chip" />
            ) : null
          }
          renderValue={
            multiple === "true"
              ? (selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: mapMethod === "ieltsSlot" ? "nowrap" : "wrap",
                      overflowX: mapMethod === "ieltsSlot" ? "scroll" : "",
                      "&::-webkit-scrollbar": {
                        display: "none"
                      },
                      "& .MuiOutlinedInput-input": {
                        boxSizing: "border-box",
                        py: "0px",
                        display: "flex",
                        alignItems: "center"
                      },
                      gap: 0.3,
                      width: "auto",
                      height: "auto",
                      mt: 1
                    }}
                  >
                    {mapMethod === "ieltsSlot"
                      ? // value.map((item) => <Chip key={item} label={item} />
                        chips()
                      : selected?.map((value) => (
                          <Chip
                            sx={{
                              "& .MuiChip-label": {
                                fontSize: "10px"
                              }
                            }}
                            key={
                              mapMethod === "ieltsSlot"
                                ? value
                                : mapMethod === "followUp"
                                ? value
                                : mapMethod === "branchMaster"
                                ? value
                                : mapMethod === "entrance"
                                ? value
                                : value.id
                            }
                            label={
                              mapMethod === "ieltsSlot"
                                ? value
                                : mapMethod === "branchMaster"
                                ? value.branchName
                                : mapMethod === "followUp"
                                ? value
                                : mapMethod === "entrance"
                                ? value
                                : mapMethod === "universityRanking"
                                ? value.universityMasterName
                                : mapMethod === "universityAffiliation"
                                ? value.universityMasterName
                                : mapMethod === "universityFacilities"
                                ? value.universityMasterName
                                : mapMethod === "universityType"
                                ? value.universityMasterName
                                : mapMethod === "alluniversities"
                                ? value.universityname
                                : mapMethod === "allCourses"
                                ? value.topCourses
                                : mapMethod === "allAdmissionDoc"
                                ? value.admissionDoc
                                : value.name
                            }
                            onDelete={() => {
                              console.log("Deleting chip...");
                              if (mapMethod !== "entrance") {
                                setSelectedValue(
                                  selectedValue.filter(
                                    (item) => item.id !== value.id
                                  )
                                );
                                handleSelectChange(
                                  selectedValue.filter(
                                    (chip) => chip.id !== value.id
                                  )
                                );
                              } else {
                                setSelectedValue(
                                  selectedValue.filter((item) => item !== value)
                                );
                                handleSelectChange(
                                  selectedValue.filter((chip) => chip !== value)
                                );
                              }
                            }}
                            deleteIcon={
                              <Typography
                                style={{ color: "#313131", fontSize: "16px" }}
                                onMouseDown={(event) => event.stopPropagation()}
                              >
                                X{" "}
                              </Typography>
                            }
                          />
                        ))}
                  </Box>
                )
              : null
          }
          onChange={(event) => {
            let updatedValue;

            if (mapMethod === "allCountry") {
              const selectedItems = event.target.value; // This is an array of selected items
              updatedValue = [...selectedValue]; // Copy of current selected values

              selectedItems.forEach((item) => {
                // Check if the item is already selected
                const isAlreadySelected = updatedValue.some(
                  (selected) => selected.id === item.id
                );

                // If not already selected, add it to updatedValue
                if (!isAlreadySelected) {
                  updatedValue.push(item);
                }
              });
              // Update state and call handleSelectChange with unique values
              setSelectedValue(updatedValue);
            }

            setSelectedValue(event.target.value);
            mapMethod === "branchMaster" ||
            mapMethod === "allCountry" ||
            mapMethod === "entrance" ||
            mapMethod === "universityAffiliation" ||
            mapMethod === "universityFacilities" ||
            mapMethod === "universityType" ||
            mapMethod === "alluniversities" ||
            mapMethod === "allCourses" ||
            mapMethod === "allAdmissionDoc"
              ? handleSelectChange(
                  mapMethod === "allCountry" ? updatedValue : event.target.value
                )
              : handleSelectChange(event);
          }}
          labelId="lead-source-label"
          id="lead-source-select"
          className="formControlSelectRole"
          name={name}
          sx={{
            width: widthSelect ? widthSelect : null,
            backgroundColor: "#fff",
            "& .MuiOutlinedInput-input": {
              boxSizing: "border-box",
              py: "0px",
              display: "flex",
              alignItems: "center"
            },
            // border:"1px solid #c4c5d6",
            height: height ? height : "46px",
            "& .MuiInputBase-input": {
              borderRadius: "20px",
              height: "100%"
            },
            "& .MuiInputBase-root": {
              borderWidth: "1px",
              borderRadius: "4px",
              borderColor: "#c4c5d6",
              // border: "1px solid #c4c5d6",
              border: "none",

              "&:hover": {
                // borderColor: "#ECECEC", // Set the desired border color for hover state
                border: "none"
              },
              "&.Mui-focused": {
                // borderColor: "#ECECEC", // Set the desired border color for focused state
                border: "none"
              }
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 150,
                padding: mapMethod === "ieltsSlot" && "11px",
                "& .MuiMenu-list": {
                  display: mapMethod === "ieltsSlot" && "grid",
                  gridTemplateColumns:
                    mapMethod === "ieltsSlot" && "114px 114px",
                  gap: mapMethod === "ieltsSlot" && "10px"
                },
                "& .MuiMenuItem-root": {
                  justifyContent: mapMethod === "ieltsSlot" && "center",
                  border:
                    mapMethod === "ieltsSlot" &&
                    "1px solid rgba(236, 236, 236, 1)",
                  borderRadius: mapMethod === "ieltsSlot" && "8px",
                  fontSize: mapMethod === "ieltsSlot" && "14px"
                },
                "& .MuiMenuItem-root.Mui-selected ": {
                  background:
                    mapMethod === "ieltsSlot" &&
                    "rgba(59, 71, 143, 1) !important",
                  padding: mapMethod === "ieltsSlot" ? "3px 6px" : "",
                  textAlign: mapMethod === "ieltsSlot" && "center",
                  color: mapMethod === "ieltsSlot" && "#fff"
                }
              }
            }
          }}
          required={required}
        >
          {mapValue &&
            mapMethod === "ieltsSlot" &&
            mapValue.length !== 0 &&
            mapValue?.map((data) => (
              <MenuItem
                key={data?.id}
                value={data?.id}
                style={{ minHeight: "20px" }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "20px",
                  flexWrap: "wrap"
                }}
              >
                {data?.timeFrom + " " + data?.timeTo}
              </MenuItem>
            ))}
          {/* </Box> */}

          {mapValue &&
            mapMethod != "ieltsSlot" &&
            mapValue.length !== 0 &&
            mapValue?.map((data) => (
              <MenuItem
                key={data.id}
                value={
                  mapMethod === "universityRank"
                    ? data
                    : mapMethod === "branchMaster"
                    ? data
                    : mapMethod === "office"
                    ? data
                    : mapMethod === "gender"
                    ? data
                    : mapMethod === "leadSource"
                    ? data
                    : mapMethod === "allCountry"
                    ? {
                        name: data.countryName.split("(")[0],
                        id: data.id
                      }
                    : mapMethod === "countryName"
                    ? data
                    : mapMethod === "specialization"
                    ? data
                    : mapMethod === "universityState"
                    ? data
                    : mapMethod === "universityCity"
                    ? data
                    : mapMethod === "universityRanking"
                    ? {
                        id: data.id,
                        universityMasterName: data.universityMasterName
                      }
                    : mapMethod === "universityAffiliation"
                    ? {
                        id: data.id,
                        universityMasterName: data.universityMasterName
                      }
                    : mapMethod === "universityFacilities"
                    ? {
                        id: data.id,
                        universityMasterName: data.universityMasterName
                      }
                    : mapMethod === "universityType"
                    ? {
                        id: data.id,
                        universityMasterName: data.universityMasterName
                      }
                    : mapMethod === "courseLevel"
                    ? data?.courseLevel
                    : mapMethod == "tempRole"
                    ? data.roleName
                    : mapMethod === "courseSubject"
                    ? data.majorName
                    : mapMethod === "courseBord"
                    ? data
                    : mapMethod === "internship"
                    ? data
                    : mapMethod === "extracurricular"
                    ? data
                    : mapMethod === "ngoEq"
                    ? data
                    : mapMethod === "leaveType"
                    ? data
                    : mapMethod === "branchManager"
                    ? data
                    : mapMethod === "statusLead"
                    ? data
                    : mapMethod === "priorityLead"
                    ? data
                    : mapMethod === "countryCode"
                    ? data.phone
                    : mapMethod === "leadStatus"
                    ? data
                    : mapMethod === "hrBranch"
                    ? data
                    : mapMethod === "followUp"
                    ? data
                    : mapMethod === "holiday"
                    ? data
                    : mapMethod === "followUp"
                    ? data
                    : mapMethod === "hrBranch"
                    ? data
                    : mapMethod == "branch"
                    ? data.id
                    : mapMethod == "permission"
                    ? {
                        id: data.id,
                        permission: data.name
                      }
                    : mapMethod === "DestinationName"
                    ? data
                    : mapMethod === "ieltsCategory"
                    ? data.CategoryName
                    : mapMethod === "alluniversities"
                    ? data
                    : // : // {
                    // //     id: data.id,
                    // //     universityname: data.universityname,
                    // //   }
                    mapMethod === "alluniversitiesEdit"
                    ? {
                        data
                      }
                    : mapMethod === "allCourses"
                    ? {
                        id: data.id,
                        topCourses: data.name,
                        tableID: data.tableID
                      }
                    : mapMethod === "allAdmissionDoc"
                    ? {
                        id: data.id,
                        admissionDoc: data.docName,
                        tableID: data.tableID
                      }
                    : mapMethod === "entrance"
                    ? data
                    : mapMethod === "employeeId"
                    ? // {
                      data
                    : // id: data.id,
                    // employeeId: data.employeeId,
                    // }
                    mapMethod === "selectFromTime"
                    ? data
                    : mapMethod === "slots"
                    ? data
                    : mapMethod == "ieltsoffline"
                    ? data.id
                    : mapMethod == "ieltsLiveClass"
                    ? data.id
                    : mapMethod == "ieltsLiveClassTeacher"
                    ? data.id
                    : mapMethod == "ieltsLiveClassCategory"
                    ? data.id
                    : mapMethod == "channel"
                    ? data.id
                    : mapMethod === "nationality"
                    ? data.label
                    : mapMethod === "State"
                    ? data.label
                    : mapMethod === "permanantCity"
                    ? data.name
                    : mapMethod == ""
                    ? data
                    : mapMethod == "ielts"
                    ? data
                    : mapMethod == "courseMajor"
                    ? data.majorName
                    : mapMethod == "ApplicationHanlder"
                    ? data.id
                    : mapMethod == "ApplicationMethod"
                    ? data
                    : mapMethod == "qualificationData"
                    ? data
                    : mapMethod == "currencyList"
                    ? data?.currency_symbol
                    : mapMethod == "UniversityHandler"
                    ? data?.id
                    : mapMethod == "universityLocation"
                    ? data?.location
                    : mapMethod == "locatioCountry"
                    ? data.label
                    : mapMethod == "universitieType"
                    ? data
                    : mapMethod == "levelofstudy"
                    ? data
                    : mapMethod == "selectBranch"
                    ? data?.branch_master?.id
                    : data.id
                }
                style={{ minHeight: "20px" }}
              >
                {mapMethod === "role" && data.roleName}
                {mapMethod === "branchMaster" && data.branchName}
                {mapMethod === "branch" && data.branchName}
                {mapMethod === "teams" && data.firstName}
                {mapMethod === "countryList" && data.countryName}
                {mapMethod === "universityCountry" && data.countryName}
                {mapMethod === "universityState" && data.stateName}
                {mapMethod === "universityCity" && data.cityName}
                {mapMethod === "universityRank" && data}
                {mapMethod === "gender" && data}
                {mapMethod === "leadSource" && data}
                {mapMethod === "allCountry" && data.countryName.split("(")[0]}
                {mapMethod === "countryName" && data.countryName}
                {mapMethod === "universityRanking" && data.universityMasterName}
                {mapMethod === "universityAffiliation" &&
                  data.universityMasterName}
                {mapMethod === "universityFacilities" &&
                  data.universityMasterName}
                {mapMethod === "universityType" && data.universityMasterName}
                {mapMethod === "entrance" && data}
                {mapMethod === "courseLevel" && data?.courseLevel}
                {mapMethod === "courseSubject" && data.majorName}
                {mapMethod === "courseBord" && data}
                {mapMethod === "internship" && data}
                {mapMethod === "extracurricular" && data}
                {mapMethod === "ngoEq" && data}
                {mapMethod === "tempRole" && data.roleName}
                {mapMethod === "leaveType" && data}
                {mapMethod === "branchOwner" && data.branchName}
                {mapMethod === "branchManager" &&
                  data.firstName +
                    `${
                      data.isManager
                        ? `(Manager)`
                        : data.isTelecallerManager
                        ? `(Telecaller Manager)`
                        : data.isTeleCallerTeamLead
                        ? `(Telecaller Team lead)`
                        : ""
                    }`}
                {mapMethod === "statusLead" && data}
                {mapMethod === "priorityLead" && data}
                {mapMethod === "countryCode" && data.phone}
                {mapMethod === "leadStatus" && data}
                {mapMethod === "hrBranch" && data}
                {mapMethod === "followUp" && data}
                {mapMethod === "holiday" && data}
                {mapMethod === "followUp" && data}
                {mapMethod === "hrBranch" && data}
                {mapMethod === "DestinationName" && data.DocName.split("(")[0]}
                {mapMethod === "alluniversities" && data.universityname}
                {mapMethod === "allCourses" && data.name}
                {mapMethod === "allAdmissionDoc" && data.docName}
                {/* {mapMethod === "entrance" && data} */}
                {mapMethod === "employeeId" && data.firstName}
                {mapMethod === "ieltsCategory" && data.CategoryName}
                {mapMethod === "ieltsoffline" && data.CenterName}
                {mapMethod === "specialization" && data}
                {mapMethod == "ieltsLiveClass" && data.CourseName}
                {mapMethod == "ieltsLiveClassTeacher" && data.teacherName}
                {mapMethod == "ieltsLiveClassCategory" && data.CategoryName}
                {mapMethod === "slots" && data}
                {mapMethod === "selectFromTime" && convertTo12HourFormat(data)}
                {mapMethod === "channel" && data.topicName}
                {mapMethod === "" && data}
                {mapMethod === "nationality" ? data.label && data.label : ""}
                {mapMethod === "State" ? data?.label && data?.label : ""}
                {mapMethod === "permanantCity" ? data?.name && data?.name : ""}
                {mapMethod === "ielts" ? data : ""}
                {mapMethod === "courseMajor" ? data.majorName : ""}
                {mapMethod === "office" ? data : ""}
                {mapMethod === "alluniversitiesEdit" ? data.universityname : ""}
                {mapMethod === "permission" ? data.name : ""}
                {mapMethod == "ApplicationHanlder" ? data.firstName : ""}
                {mapMethod == "ApplicationMethod" ? data : ""}
                {mapMethod == "qualificationData" ? data : ""}
                {mapMethod == "currencyList"
                  ? data?.currency_symbol + " " + data?.currency_name
                  : ""}
                {mapMethod == "UniversityHandler" ? data?.firstName : ""}
                {mapMethod == "universityLocation" ? data?.location : ""}
                {mapMethod == "locatioCountry" ? data?.label : ""}
                {mapMethod == "universitieType" ? data : ""}
                {mapMethod == "levelofstudy" ? data : ""}
                {mapMethod == "selectBranch" ? data?.branch_master?.branchName : ""}

              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
}

export function SelectInputFieldForBranch({
  label,
  handleSelectChange,
  value,
  mapValue,
  multiple,
  widthSelect,
  name,
  disabled,
  required,
  height,
  mapMethod
}) {
  let filteredObjects = [];
  filteredObjects = mapValue?.filter((item1) =>
    value.some((item2) => item2.id === item1.id)
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const [selectedValue, setSelectedValue] = useState(value);

  return (
    <>
      <FormControl
        disabled={disabled ? disabled : false}
        label={label}
        className="selectFormLabelTobeControlled"
        sx={{
          width: "100%"
        }}
        fullWidth
      >
        <InputLabel
          id="lead-source-label"
          className="formControlLabelRole "
          sx={{
            fontWeight: 400,
            px: "5px",
            ml: "-5px",
            color: "black",
            fontSize: "14px"
          }}
        >
          {required ? (
            <>
              {label}
              <span
                className="text-danger"
                style={{ color: "rgba(255, 45, 46, 1)" }}
              >
                {" *"}
              </span>
            </>
          ) : (
            label
          )}
        </InputLabel>
        <Select
          value={filteredObjects || []}
          multiple={multiple}
          input={
            multiple === "truSelectInputField2e" ? (
              <OutlinedInput id="select-multiple-chip" label="Chip" />
            ) : null
          }
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                overflowX: "",
                "&::-webkit-scrollbar": {
                  display: "none"
                },
                "& .MuiOutlinedInput-input": {
                  boxSizing: "border-box",
                  py: "0px",
                  display: "flex",
                  alignItems: "center"
                },
                gap: 0.3,
                width: "auto",
                height: "auto",
                mt: 1
              }}
            >
              {selected?.map((item) => (
                <Chip
                  sx={{
                    "& .MuiChip-label": {
                      fontSize: "10px"
                    }
                  }}
                  key={item}
                  label={item.branchName}
                  onDelete={() => {
                    console.log("Deleting chip...");
                    setSelectedValue(
                      selectedValue.filter((value) => value.id !== item.id)
                    );
                    handleSelectChange(
                      selectedValue.filter((chip) => chip.id !== item.id)
                    );
                  }}
                  deleteIcon={
                    <Typography
                      style={{ color: "#313131", fontSize: "16px" }}
                      onMouseDown={(event) => event.stopPropagation()}
                    >
                      X{" "}
                    </Typography>
                  }
                />
              ))}
            </Box>
          )}
          onChange={(event) => {
            setSelectedValue(event.target.value);
            mapMethod === "branchMaster"
              ? handleSelectChange(event.target.value)
              : handleSelectChange(event);
          }}
          labelId="lead-source-label"
          id="lead-source-select"
          className="formControlSelectRole"
          name={name}
          sx={{
            width: widthSelect ? widthSelect : null,
            backgroundColor: "#fff",
            "& .MuiOutlinedInput-input": {
              boxSizing: "border-box",
              py: "0px",
              display: "flex",
              alignItems: "center"
            },
            height: height ? height : "46px",
            "& .MuiInputBase-input": {
              borderRadius: "20px",
              height: "100%"
            },
            "& .MuiInputBase-root": {
              borderWidth: "1px",
              borderRadius: "4px",
              borderColor: "#c4c5d6",
              border: "none",

              "&:hover": {
                border: "none"
              },
              "&.Mui-focused": {
                border: "none"
              }
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 150,
                padding: "11px"
                // "& .MuiMenu-list": {
                //   display: "grid",
                //   gridTemplateColumns: "114px 114px",
                //   gap: "10px",
                // },
                // "& .MuiMenuItem-root": {
                //   justifyContent: "center",
                //   border: "1px solid rgba(236, 236, 236, 1)",
                //   borderRadius: "8px",
                //   fontSize: "14px",
                // },
                // "& .MuiMenuItem-root.Mui-selected ": {
                //   background: "rgba(59, 71, 143, 1) !important",
                //   textAlign: "center",
                //   color: "#fff",
                // },
              }
            }
          }}
          required={required}
        >
          {mapValue?.map((data) => (
            <MenuItem
              key={data.id}
              value={data}
              style={{ minHeight: "20px" }}
              selected={true}
            >
              {data.branchName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export const MultiSelect = ({ widthSize, mapMethod, label }) => {
  const [selectedNames, setSelectedNames] = useState([]);
  const names = [
    "Humaira Sims",
    "Santiago Solis",
    "Dawid Floyd",
    "Mateo Barlow",
    "Samia Navarro",
    "Kaden Fields",
    "Genevieve Watkins",
    "Mariah Hickman",
    "Rocco Richardson",
    "Harris Glenn"
  ];
  return (
    <Paper
      sx={{ height: "35px", pt: 1, pb: 1, border: "px solid #ececec" }}
      elevation={0}
    >
      <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel
          id="lead-source-label"
          className="formControlLabelRole"
          sx={{
            width: widthSize,
            fontWeight: 400,
            color:
              mapMethod === "gender"
                ? ""
                : mapMethod === "leadSource"
                ? ""
                : mapMethod === "allCountry"
                ? ""
                : "black",
            fontSize: "14px"
          }}
        >
          {label}
        </InputLabel>
        <Select
          multiple
          value={selectedNames}
          labelId="lead-source-label"
          id="lead-source-select"
          // sx={{
          //   textAlign: "center",
          //   // backgroundColor: "white",
          //   border: "none",
          //   "& fieldset": { border: "none", borderRadius: "4px" },
          //   // height: "auto",
          //   "& .MuiFormLabel-root": {
          //     height: "32px",
          //     pt: 1,
          //     // pb:2,

          //     "&.Mui-focused": {
          //       color: "#7d7d7d",
          //       pt: 0.9,
          //     },
          //   },
          // }}

          sx={{
            textAlign: "left",

            width: "100%",
            "& .MuiInputLabel-asterisk": {
              color: "rgba(255, 27, 111, 1)"
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              textAlign: "left"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0.5px solid #ECECEC ",
              borderRadius: "4px",
              borderColor: "#ECECEC !important"
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline ": {
              border: "1px solid #d32f2f !important"
            },
            "& .MuiFormControl-root": {
              borderRadius: "16px !important"
            },

            "& .MuiInputLabel-root": {
              color: "#000",
              fontSize: "16px",
              transform: "translate(14px, -6px) scale(1)"
            },
            "& .MuiSelect-root": {
              paddingTop: "12px" // Adjust the top padding for better alignment
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(20, 30, 60, 1)", // Customize the placeholder text color here
              fontWeight: 500,
              fontSize: "14px",
              fontFamily: "'Lato', sans-serif"
            }
          }}
          onChange={(e) => setSelectedNames(e.target.value)}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected?.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() =>
                    setSelectedNames(
                      selectedNames.filter((item) => item !== value)
                    )
                  }
                  deleteIcon={
                    // <CancelIcon
                    //   onMouseDown={(event) => event.stopPropagation()}
                    // />
                    <Typography
                      style={{ color: "#313131", fontSize: "16px" }}
                      onMouseDown={(event) => event.stopPropagation()}
                    >
                      X{" "}
                    </Typography>
                  }
                />
              ))}
            </Stack>
          )}
        >
          {names?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              sx={{ justifyContent: "space-between" }}
            >
              {name}
              {selectedNames.includes(name) ? <CheckIcon color="info" /> : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

// Select inputfield 2

export function SelectInputField2({
  options,
  defaultValue,
  handleSelectChange,
  value
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  return (
    <select
      value={value}
      style={{
        width: "179px",
        height: "40px",
        border: "1px solid #ECECEC",
        background: "white",
        color: value ? "#141E3C" : "#ACB1C6",
        borderRadius: "5px"
      }}
      onChange={handleSelectChange}
    >
      <option value="defaultOption">All Branches</option>
      {options?.map((option) => (
        <option
          key={option.id}
          value={option.branchName}
          style={{ color: "#ACB1C6" }}
        >
          {option.branchName}
        </option>
      ))}
    </select>
  );
}

export function NormalSelect({
  options,
  handleChange,
  value,
  label,
  name,
  error,
  helperText,
  mapMethod
}) {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel
        id="demo-simple-select-label"
        sx={{
          background: "#fff",
          pr: "10px",
          pl: "5px",
          "&.MuiInputLabel-root.Mui-focused": { color: "rgba(5, 5, 15, 1)" },
          "&.MuiInputLabel-root.Mui-error": { color: "#d32f2f" }
        }}
      >
        {label}
      </InputLabel>
      <Select
        name={name}
        sx={{
          textAlign: "left",
          paddingRight: "10px",
          width: "100%",
          "& .MuiInputLabel-asterisk": {
            color: "rgba(255, 27, 111, 1)"
          },
          "&.Mui-error > svg": {
            color: "#d32f2f"
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            textAlign: "left"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            // border: "0.5px solid #ECECEC ",
            borderRadius: "4px",
            // borderColor: "#ECECEC !important",
            border: "1px solid #C4C4C4 !important"
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline ": {
            border: "1px solid #d32f2f !important"
          },
          "& .MuiFormControl-root": {
            borderRadius: "16px !important"
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(20, 30, 60, 1)", // Customize the placeholder text color here
            fontWeight: 500,
            fontSize: "14px",
            color: "black"
            // fontFamily: "'Lato', sans-serif",
          }
        }}
        IconComponent={() => <KeyboardArrowDown />}
        value={value}
        onChange={handleChange}
      >
        {options?.map((item) =>
          mapMethod === "developer" ? (
            <MenuItem value={item.id}>{item.value}</MenuItem>
          ) : mapMethod === "targetedUsers" ? (
            <MenuItem value={item}>{item}</MenuItem>
          ) : (
            <MenuItem value={item.universityName}>
              {item.universityName}
            </MenuItem>
          )
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

// Search inputfield

export function SearcInutfield({ handleOnchange }) {
  return (
    <div style={{ display: "flex", marginRight: "10px", borderRadius: "5px" }}>
      <input
        placeholder="Search Employees"
        className="placeholder-style"
        style={{
          border: "1px solid #ECECEC",
          borderRadius: "5px",
          height: "35px",
          width: "350px"
        }}
        onChange={(e) => {
          handleOnchange(e.target.value);
        }}
      ></input>
      <div
        style={{
          paddingTop: "7px",
          border: "1px solid #ECECEC",
          borderRadius: "5px",
          right: "0",
          paddingInline: "15px"
        }}
      >
        <RiSearch2Line
          style={{ width: "20px", height: "24px", fill: "rgb(149 149 149)" }}
        />
      </div>
    </div>
  );
}

// Date inputField
export function Dateinputfield({ handleDateChange, dateValue, name }) {
  return (
    <div style={{ display: "flex", borderRadius: "5px" }}>
      <input
        type="date"
        style={{
          border: "1px solid #ECECEC",
          borderRadius: "5px",
          height: "37px",
          width: "179px",
          color: "#ACB1C6"
        }}
        name={name}
        value={dateValue}
        onChange={handleDateChange}
      ></input>
    </div>
  );
}

// SELECT BOX
export function DropDownSelect({
  handleChange,
  data,
  value,
  label,
  component
}) {
  return (
    <Box sx={{ background: "white", p: 0.5, borderRadius: "5px" }}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="demo"
        onChange={handleChange}
        IconComponent={() => <KeyboardArrowDown />}
        displayEmpty
        renderValue={value !== "" ? undefined : () => " "}
        sx={{
          width: "100%",

          "& fieldset": { border: "0 !important", outline: "0 !important" },
          "& .MuiOutlinedInput-input": {
            p: 0
          },
          "& .MuiOutlinedInput-input": {
            boxSizing: "border-box",
            py: "0px",
            display: "flex",
            alignItems: "center"
          }
        }}
      >
        {component === "ShortlistCourse"
          ? data?.map((item) => (
              <MenuItem key={item.majorName} value={item.majorName}>
                {item.majorName}
              </MenuItem>
            ))
          : label === "Level"
          ? data?.map((item) => (
              <MenuItem key={item.courseLevel} value={item.courseLevel}>
                {item.courseLevel}
              </MenuItem>
            ))
          : label === "location"
          ? data?.map((item) => (
              <MenuItem key={item.location} value={item.location}>
                {item.location}
              </MenuItem>
            ))
          : data?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
      </Select>
    </Box>
  );
}

// for partner
export function SelectinputFieldCustom({
  handleChange,
  value,
  array,
  label,
  name,
  backgroundColor,
  error,
  varientLabel,
  labelColor,
  required,
  mapMethod
}) {
  return (
    <>
      <FormControl
        error={error}
        helperText={""}
        fullWidth
        disabled={false}
        sx={{
          backgroundColor: backgroundColor,
          "& .MuiInputLabel-root.Mui-error": {
            color: "#d32f2f !important"
          },
          "& .MuiFormLabel-root.Mui-focused ": {
            paddingTop: "0px",
            paddingRight: "5px"
          },
          "& .MuiFormLabel-root.MuiFormLabel-filled ~ .MuiInputBase-root > .MuiSelect-select":
            {
              paddingTop: "24px",
              paddingBottom: "8px"
            },
          "& .MuiFormLabel-root.MuiFormLabel-filled ": {
            paddingTop: "0px",
            paddingRight: "5px"
          }
        }}
      >
        {label && (
          <InputLabel
            htmlFor="demo-simple-select-label"
            // shrink={true}
            sx={{
              position: "absolute",
              paddingLeft: "5px",
              paddingRight: "5px",
              backgroundColor: "#fff",
              // transition: "top 0.2s ease",
              top: "-6px",
              fontSize: "16px",
              color: "black",
              "&[data-shrink='true']": {
                color: "black",
                top: "0"
              }
            }}
          >
            {label}
            {required && <span style={{ color: "#FF1B6F" }}> *</span>}
          </InputLabel>
        )}
        {varientLabel && (
          <InputLabel
            variant="standard"
            htmlFor="demo-simple-select-label"
            // shrink={true}
            style={{
              position: "absolute",
              backgroundColor: "white",
              color: labelColor ? labelColor : "black",
              paddingLeft: "15px",
              paddingRight: "5px"
              // color: "black",
              // fontFamily: "'Poppins', sans-serif",
            }}
          >
            {varientLabel}
            {required && <span style={{ color: "#FF1B6F" }}> *</span>}
          </InputLabel>
        )}
        <Select
          name={name}
          onChange={handleChange}
          sx={{
            textAlign: "left",
            // border: "1px solid #C4C4C4",
            width: "100%",
            "& .MuiInputLabel-asterisk": {
              color: "rgba(255, 27, 111, 1)"
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "4px",
              textAlign: "left"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              // border: "0.5px solid #ECECEC ",
              borderRadius: "4px",
              // borderColor: "#ECECEC !important",
              border: "1px solid #C4C4C4"
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline ": {
              border: "1px solid #d32f2f !important"
            },
            "& .MuiFormControl-root": {
              borderRadius: "16px !important"
            },

            "& .MuiInputLabel-root": {
              color: "#000",
              fontSize: "16px",
              transform: "translate(14px, -6px) scale(1)"
            },
            "& .MuiSelect-root": {
              // paddingTop: "12px", // Adjust the top padding for better alignment
            },
            "& .MuiSelect-select": {
              paddingY: "10px !important"
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(20, 30, 60, 1)", // Customize the placeholder text color here
              fontWeight: 500,
              fontSize: "14px",
              color: "black"
              // fontFamily: "'Lato', sans-serif",
            },
            "& .MuiOutlinedInput-input": {
              boxSizing: "border-box",
              py: "0px",
              display: "flex",
              alignItems: "center"
            }
          }}
          // IconComponent={() => (
          //   <Box
          //     sx={{
          //       position: "absolute",
          //       right: "20px",
          //       color: "rgba(172, 177, 198, 1)",
          //       cursor: "pointer",
          //       // top: "15px",
          //       zIndex: "0",
          //     }}
          //   >
          //     <KeyboardArrowDownIcon />
          //   </Box>
          // )}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          native={false}
          value={value}
          IconComponent={() => <KeyboardArrowDown />}
        >
          {array?.map((item, i) =>
            mapMethod === "centerCategory" ? (
              <MenuItem key={i} value={item?.CategoryName}>
                {item?.CategoryName}
              </MenuItem>
            ) : (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            )
          )}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
}

// Date Field
export function CustomDateField({
  handleChange,
  value,
  name,
  placeholder,
  maxDate,
  minDate
}) {
  const [startDate, setStartDate] = useState(new Date());
  const range = (start, end, step) => {
    const result = [];
    for (let i = start; i < end; i += step) {
      result.push(i);
    }
    return result;
  };
  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Box
      className="example-custom-input"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "30px",
        alignItems: "center",
        padding: "12px 16px",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "4px",
        cursor: "pointer"
      }}
      onClick={onClick}
      ref={ref}
    >
      <Typography>{value ? value : placeholder}</Typography>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 22H6C4.3 22 3 20.7 3 19V7C3 5.3 4.3 4 6 4H18C19.7 4 21 5.3 21 7V19C21 20.7 19.7 22 18 22ZM6 6C5.4 6 5 6.4 5 7V19C5 19.6 5.4 20 6 20H18C18.6 20 19 19.6 19 19V7C19 6.4 18.6 6 18 6H6Z"
          fill="#ACB1C6"
        />
        <path
          d="M16 8C15.4 8 15 7.6 15 7V3C15 2.4 15.4 2 16 2C16.6 2 17 2.4 17 3V7C17 7.6 16.6 8 16 8Z"
          fill="#ACB1C6"
        />
        <path
          d="M8 8C7.4 8 7 7.6 7 7V3C7 2.4 7.4 2 8 2C8.6 2 9 2.4 9 3V7C9 7.6 8.6 8 8 8Z"
          fill="#ACB1C6"
        />
        <path
          d="M20 12H4C3.4 12 3 11.6 3 11C3 10.4 3.4 10 4 10H20C20.6 10 21 10.4 21 11C21 11.6 20.6 12 20 12Z"
          fill="#ACB1C6"
        />
        <path
          d="M10 18H8C7.4 18 7 17.6 7 17V15C7 14.4 7.4 14 8 14H10C10.6 14 11 14.4 11 15V17C11 17.6 10.6 18 10 18Z"
          fill="#ACB1C6"
        />
      </svg>
    </Box>
  ));

  return (
    <Box
      sx={{
        width: "100%",
        "& .react-datepicker-wrapper": {
          width: "100%"
        },
        "& .react-datepicker-popper": {
          zIndex: "1000 !important"
        }
      }}
    >
      <DatePicker
        // selected={value ? value : startDate}
        // onChange={handleChange}
        selected={value}
        onChange={(value) => handleChange(value)}
        placeholderText={placeholder}
        customInput={<ExampleCustomInput />}
        maxDate={maxDate}
        minDate={minDate}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
      />
    </Box>
  );
}

// Radio Button
export function RadioButtonField({
  value,
  handleChange,
  label,
  radioOptions,
  name
}) {
  return (
    <FormControl>
      {label && (
        <FormLabel
          id="demo-radio-buttons-group-label"
          sx={{
            color: "rgba(5, 5, 15, 1) !important"
          }}
        >
          {label}
        </FormLabel>
      )}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={handleChange}
      >
        {radioOptions?.map((items) => (
          <FormControlLabel
            value={items.value}
            control={
              <Radio
                sx={{
                  color: "rgba(20, 30, 60, 1)",
                  "&.Mui-checked": {
                    color: "rgba(20, 30, 60, 1)"
                  }
                }}
              />
            }
            label={items.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

// PhoneNumber SelectInput Field

export function PhoneSelectInputFieldVariableLabel({
  value,
  handleChange,
  getCountryCode,
  widthSize,
  mt,
  error,
  helperText,
  name,
  labelMarginLeft,
  label,
  color,
  placeholder
}) {
  const allCountries = Object.entries(countries)?.map(([code, country]) => ({
    phone: `+${country.phone}`,
    countryCode: code
  }));

  // console.log(allCountries, "allciuntriesss");
  const [countryCode, setCountryCode] = useState("+91");

  // console.log(countryCode, "countryCode");

  return (
    <TextField
      sx={{
        margin: "auto",
        mt: mt ? mt : 3,
        width: widthSize ? widthSize : "100%",
        "@media (max-width:653px)": { width: widthSize ? widthSize : "100%" },
        "& .MuiInputLabel-asterisk": {
          color: "rgba(255, 27, 111, 1)"
        },
        "& .MuiInputBase-root legend": {
          marginLeft: labelMarginLeft ? labelMarginLeft : "0px"
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "4px"
        },
        "& .MuiInputBase-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline":
          {
            border: "0px solid rgba(218, 219, 228, 1) !important"
          },
        "& .MuiFormControl-root": {
          borderRadius: "16px !important",
          marginTop: "0"
        },
        "& .MuiInputLabel-root": {
          color: "#000",
          fontSize: "16px",
          fontFamily: "'Poppins',sans-serif",
          marginLeft: labelMarginLeft ? labelMarginLeft : "0px"
        },
        "& .MuiInputLabel-root.Mui-error ": {
          color: color ? color : "#d32f2f"
        },
        "& .MuiInputBase-input": {
          paddingLeft: "10px"
        },
        "& .MuiSelect-outlined ": {
          paddingRight: "5px !important",
          paddingLeft: "0px !important"
        },
        "& .MuiSelect-select img ": {
          width: "27px !important",
          height: "auto !important",
          borderRadius: "0 !important"
          // objectFit: "contain !important",
        },
        "& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline ": {
          border: "1px solid rgba(0, 0, 0, 0.23) !important",
          color: "#d32f2f"
        },
        "& .MuiInputBase-input::placeholder": {
          color: "rgba(20, 30, 60, 1)", // Customize the placeholder text color here
          fontWeight: 400,
          fontSize: "16px",
          fontFamily: "'Poppins', sans-serif",
          paddingLeft: "0px",
          opacity: "1"
        }
      }}
      margin="normal"
      error={error}
      helperText={error && helperText}
      required
      fullWidth
      name={name}
      id="phone"
      label={label === "none" ? "" : label ? label : "Phone number"}
      type="number"
      value={value}
      onChange={(e) => handleChange(e)}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        style: { color: "black", fontWeight: 400 },
        startAdornment: (
          <InputAdornment position="end">
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  sx={{
                    color: "rgba(5, 5, 15, 1)"
                  }}
                />
              )}
              sx={{
                border: "none",
                outline: "none",
                // backgroundColor: "red",
                color: "rgba(5, 5, 15, 1)",
                width: "auto",
                borderRadius: "0 !important",
                padding: "0",

                "& .MuiOutlinedInput-notchedOutline": {
                  outline: "0px !important",
                  border: "0 !important"
                },
                "& .MuiSelect-outlined ": {
                  padding: "0 "
                },
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  gap: "15px"
                },
                "& .MuiSelect-select img": {
                  margin: "0 !important",
                  width: "37px !important"
                }
              }}
              onChange={(e) => {
                getCountryCode(e.target.value);
                setCountryCode(e.target.value);
              }}
              value={countryCode}
            >
              {allCountries
                ? allCountries?.map((value, i) => {
                    return (
                      <MenuItem value={value.phone} key={i}>
                        <CountryFlag countryCode={value.countryCode} />
                        {value.phone}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </InputAdornment>
        )
      }}
      placeholder={placeholder ? placeholder : "Add Phone Number"}
    />
  );
}

//

export function TextFieldInput({
  placeholder,
  value,
  color,
  name,
  handleChange,
  error,
  label,
  borderColor
}) {
  return (
    <>
      <TextField
        autoComplete={"off"}
        sx={{
          width: "100%",
          "& input": {
            outline: "none !important",
            color: color ? color : "#ACB1C6"
          },
          "& input::placeholder": {
            color: color ? color : "#ACB1C6",
            opacity: "1"
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: error ? "red" : borderColor || "#ECECEC",
            borderWidth: "1px",
            borderStyle: "solid"
          }
        }}
        placeholder={placeholder}
        value={value}
        id="outlined-basic"
        variant="outlined"
        label={label}
        name={name}
        error={error}
        helperText={error}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
      />
    </>
  );
}

// Select field with searchBox

export function SelectSearchBox({ mappedValue, handleChange }) {
  <Autocomplete
    freeSolo
    id="free-solo-2-demo"
    disableClearable
    options={mappedValue.map((option) => option)}
    onChange={handleChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Search input"
        slotProps={{
          input: {
            ...params.InputProps,
            type: "search"
          }
        }}
      />
    )}
  />;
}
