import React, { useState, startTransition } from "react";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import { MainContainer } from "../content/content.element";
import BasicTabs from "../Tabs/TabsMain";
import { Skeleton, Typography } from "@mui/material";
import LeadPassportApprovals from "./LeadApprovals/leadPassportApproval";


const LeadApprovals = React.lazy(() => import("./LeadApprovals/leadApprovals"));

function ApprovalMainMenu(props) {
  const [whichPage, setWhichPage] = useState(0);
  const [isPending, setIsPending] = useState(false);

  const fetchData = async () => {
    setIsPending(true);
    await new Promise((resolve) => setTimeout(resolve, 0)); // Simulate async fetch
    setIsPending(false);
  };

  const callback = (index) => {
    startTransition(() => {
      fetchData().then(() => {
        setWhichPage(index);
      });
    });
  };

  return (
    <>
      <MainContainer active={props.toggle}>
        <>
          <HeadBreadcrumbs
            head="home"
            sechead="Leads"
            first="Leads"
            main={whichPage === 0 ? "Lead Status Approving" : null}
          />
          <BasicTabs
            tab1Name="Approval requests"
            tab2Name="Passport Approvals"
            tab1={
              <React.Suspense
                fallback={
                  <div
                    style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
                  >
                    <>
                      {/* <Skeleton /> */}
                      <Typography variant="h1">
                        <Skeleton animation={false} />
                      </Typography>

                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                    </>
                  </div>
                }
              >
                <LeadApprovals toggle={props.toggle} />
              </React.Suspense>
            }
            tab2={
              <React.Suspense
                fallback={
                  <div
                    style={{ textAlign: "center", padding: "1px,1px,1px,1px" }}
                  >
                    <>
                      {/* <Skeleton /> */}
                      <Typography variant="h1">
                        <Skeleton animation={false} />
                      </Typography>

                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                      <Typography variant="h3">
                        <Skeleton animation="wave" />
                      </Typography>

                      <Skeleton animation={false} />
                    </>
                  </div>
                }
              >
                <LeadPassportApprovals toggle={props.toggle} />
              </React.Suspense>
            }
            head="Home"
            sechead="lead"
            callback={callback}
          />
        </>
      </MainContainer>
    </>
  );
}

export default React.memo(ApprovalMainMenu);
