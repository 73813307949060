import React, { useEffect, useState } from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Sidebar from "./component/SideNavBar/SideNav";
import HOME from "./component/HOME/Home.js";
import Header from "./component/Header/Header";
import Sample from "./component/HOME/Sample";
import LeadsMain from "./component/Leads/LeadsMain";
import All_leadListTable from "./component/Leads/All_leadListTable";
import Login from "./component/auth/login/Login";
import RoleMangment from "./component/administration/role/RoleMangment";
import CreateRoles from "./component/administration/role/CreateRoles";
import EditRole from "./component/administration/role/EditRole";
import { Box } from "@mui/material";
import AdministrationMain from "./component/administration/AdministrationMain";
import EmployeeMain from "./component/administration/employee/EmployeeMain";
import AddEmployee from "./component/administration/employee/AddEmployee";
import EmployeeDetails from "./component/administration/employee/EmployeeDetails";
import EditEmployeeDetails from "./component/administration/employee/EditEmployeeDetails";
import EmployeeLeaveApplyForm from "./component/administration/employee/employeeLeaveApply/EmployeeLeaveApplyForm";
import EmployeeTrackPage from "./component/administration/employee/EmployeeTrackPage";
import UniversityMain from "./component/university/UniversityMain";
import Universities from "./component/university/universities/Universities";
import AddUniversity from "./component/university/universities/addUniversity/AddUniversity";
import UniversityMasterMain from "./component/university/universityMaster/UniversityMasterMain";
import ViewUniversity from "./component/university/universities/ViewUniversity";
import SettingMain from "./component/setting/SettingMain";
import MasterChip from "./component/setting/master/MasterChip";
import UniversityCourseMain from "./component/university/universityCourse/UniversityCourseMain";
import CourseManagment from "./component/university/universityCourse/CourseManagment.js";
import AddUniversityCourse from "./component/university/universityCourse/AddUniversityCourse";
import KnowledgeBankMain from "./component/knowledgeBank/KnowledgeBankMain";
import CreateLeadDocs from "./component/Leads/createLeads/CreateLeadDocs.js";
import LeadsFilterSearch from "./component/Leads/LeadsFilterSearch";
import BranchMaster from "./component/setting/master/branch/BranchMaster";
import LeadsPending from "./component/Leads/LeadsPending";
import MainFollowUp from "./component/Leads/followUp/MainFollowUp";
import HrMain from "./component/HR/HrMain";
import AllHrAttendance from "./component/HR/AllHrAttendance";
import AllEmployeeHolidays from "./component/HR/AllEmployeeHolidays";
import AllEmployeeLeaveTable from "./component/HR/AllEmployeeLeaveTable";
import AllEmployeePaySlip from "./component/HR/AllEmployeePaySlip";
import NotificationMain from "./component/notification/NotificationMain";
import AllEmployeeJobs from "./component/HR/AllEmployeeJobs";
import EmployeeJobCreateForm from "./component/HR/EmployeeJobCreateForm";
import EmployeeJobEditForm from "./component/HR/EmployeeJobEditForm";
import LandingPageMain from "./component/LandingPage/LandingPageMain";
import AllTopNavigation from "./component/LandingPage/AllTopNavigation";
import TopNavigationCreateForm from "./component/LandingPage/TopNavigationCreateForm";
import TopNavigationEdit from "./component/LandingPage/TopNavigationEditForm";
import DestinationCreateForm from "./component/LandingPage/Destination/DestinationCreateForm";
import AllDestination from "./component/LandingPage/Destination/AllDestination";
import Services from "./component/LandingPage/services/Services";
import CreateService from "./component/LandingPage/services/CreateService";
import NewsMain from "./component/LandingPage/news/NewsMain";
import CreateNews from "./component/LandingPage/news/CreateNews";
import BlogMain from "./component/LandingPage/blogs/BlogMain";
import CreateBlogs from "./component/LandingPage/blogs/CreateBlogs";
import TestimonialsMain from "./component/LandingPage/testimonials/TestimonialsMain";
import CreateTestimonials from "./component/LandingPage/testimonials/CreateTestimonials";
import FeaturedAdsMain from "./component/LandingPage/featuredAds/FeaturedAdsMain";
import FeaturedAdsCreateForm from "./component/LandingPage/featuredAds/FeaturedAdsCreateForm.js";
import AccountMain from "./component/Accounts/AccountMain";
import IncentiveCategory from "./component/Accounts/incentiveCategory/IncentiveCategory";
import EmployeeIncentiveCreate from "./component/Accounts/employeeIncentive/EmployeeIncentiveCreate";
import EmployeeIncentiveMain from "./component/Accounts/employeeIncentive/EmployeeIncentiveMain";
import LeadStatusApprove from "./component/Leads/LeadApprovals/LeadStatusApprove.js";
import LeadsDeferral from "./component/Leads/LeadsDeferral";
import EditBlogs from "./component/LandingPage/blogs/EditBlogs.js";
import Ielts from "./component/Ielts/IeltsEntrolled/ieltsMain.js";
import IeltsEntrolledMain from "./component/Ielts/IeltsEntrolled/ieltsEntrolledMain.js";
import IeltsEntrolledStudentsViewMain from "./component/Ielts/IeltsEntrolled/ieltsEntrolledStudentsViewMain.js";
import IeltsEntrolledStudentsView from "./component/Ielts/IeltsEntrolled/ieltsEntrolledStudentsView.js";
import PartnerMain from "./component/Partner/PartnerMain.js";
import AllPartners from "./component/Partner/AllPartners.js";
import PartnerApproval from "./component/Partner/PartnerApproval.js";
import IeltsLearningManagmentMain from "./component/Ielts/IeltsLearningManagment/ieltsLearningManagmentMain.js";
import PartnerKycApproval from "./component/Partner/PartnerKycApproval.js";
import PartnerKycMain from "./component/Partner/partnerKyc/PartnerKycMain.js";
import DealsMain from "./component/Leads/DealsMain.js";
import AddNewTopicsForm from "./component/Ielts/IeltsLearningManagment/addNewTopicsForm.js";
import TopicDetailedView from "./component/Ielts/IeltsLearningManagment/TopicDetailedView.js";
import IeltsCourseManagmentMain from "./component/Ielts/IeltsCourseManagement/ieltsCourseManagmentMain.js";
import QuestionBankAddNewForm from "./component/Ielts/IeltsCourseManagement/questionBank/QuestionBankAddNewForm.js";
import ExamTipsAddNewForm from "./component/Ielts/IeltsCourseManagement/examTips/ExamTipsAddNewForm.js";
import CourseAddNew from "./component/Ielts/IeltsCourseManagement/courses/CourseAddNew.js";
import IeltsTeacherManagmentMain from "./component/Ielts/IeltsTeacherManagment/ieltsTeacherManagmentMain.js";
import IeltsTeacherAddForm from "./component/Ielts/IeltsTeacherManagment/ieltsTeacherAddForm.js";
import IeltsTeacherDetailedViewMain from "./component/Ielts/IeltsTeacherManagment/ieltsTeacherDetailedViewMain.js";
import IeltsTeacherDetailedView from "./component/Ielts/IeltsTeacherManagment/ieltsTeacherDetailedView.js";
import TopicEditForm from "./component/Ielts/IeltsLearningManagment/topicEditForm.js";
import IeltsTeacherEditForm from "./component/Ielts/IeltsTeacherManagment/ieltsTeacherEditForm.js";
import IeltsCenterMain from "./component/Ielts/IeltsCenter/IeltsCenterMain.js";
import CenterTableAddNewForm from "./component/Ielts/IeltsCenter/CenterAddNewForm.js";
import IeltsCeterDetailedView from "./component/Ielts/IeltsCenter/IeltsCeterDetailedView.js";
import IeltsLiveClassAddNewForm from "./component/Ielts/IeltsCourseManagement/liveClass/IeltsLiveClassAddNewForm.js";
import IeltsLiveClassSinglePage from "./component/Ielts/IeltsCourseManagement/liveClass/IeltsLiveClassSinglePage.js";
import IeltsSettingsMain from "./component/Ielts/IeltsSettings/IeltsSettingsMain.js";
import IeltsZoomConfigurationForm from "./component/Ielts/IeltsSettings/zoomConfiguration/IeltsZoomConfigurationForm.js";
import StudentRegisterForm from "./component/Ielts/studentRegister/StudentRegisterForm.js";
import IeltsFaqForm from "./component/Ielts/IeltsSettings/Faq/IeltsFaqForm.js";
import FaqSingleItem from "./component/Ielts/IeltsSettings/Faq/FaqSingleItem.js";
import CourseDetailedView from "./component/Ielts/IeltsCourseManagement/courses/detailedView/CourseDetailedViewTab.js";
import IeltsPurchasedMain from "./component/Ielts/IeltsPurchased/IeltsPurchasedMain.js";
import PurchasedDetailedView from "./component/Ielts/IeltsPurchased/PurchasedDetailedView.js";
import IeltsTeacherDashBoardHome from "./component/Ielts/IeltsTeacherDashboard/IeltsteacherDashBoardHome.js";
import IeltsTeacherDashBoardMain from "./component/Ielts/IeltsTeacherDashboard/IeltsTeacherDashBoardMain.js";
import IeltsTeacherDashboardDetailedView from "./component/Ielts/IeltsTeacherDashboard/IeltsTeacherDashBoardDetailedView.js";
import IeltsLiveClassEditForm from "./component/Ielts/IeltsCourseManagement/liveClass/IeltsLiveClasseEditForm.js";
import IeltsEditFaq from "./component/Ielts/IeltsSettings/Faq/IeltsEditFaq.js";
import ExamTipsEditForm from "./component/Ielts/IeltsCourseManagement/examTips/ExamTipsEditForm.js";
import QuestionBankEditNewForm from "./component/Ielts/IeltsCourseManagement/questionBank/QuestionBankEditNewForm .js";
import ApplicationMain from "./component/Application/ApplicationMain.js";
// import PartnerLeads from "./component/Application/partnerLeads/PartnerLeads.js";
import AssignedStudentsMain from "./component/Ielts/IeltsTeacherManagment/AssignedStudents/AssignedStudentMain.js";
import CreateEvents from "./component/LandingPage/events/CreateEvents.js";
import EventMain from "./component/LandingPage/events/EventMain.js";
import PartnerDetailsMain from "./component/Partner/partnerDetails/PartnerDetailsMain.js";
import CourseEditForm from "./component/Ielts/IeltsCourseManagement/courses/CourseEditForm.js";
import AllLeadApplications from "./component/Application/allLeadApplications/AllLeadApplications.js";
import EndrolledApplications from "./component/Application/allLeadApplications/EndrolledApplications.js";
import BannerMaster from "./component/setting/Banner/BannerMaster.js";
import AddBannerForm from "./component/setting/Banner/AddBannerForm.js";
import ReportIncentiveMain from "./component/Accounts/incentive/ReportIncentiveMain.jsx";
import InsentiveDetailedPage from "./component/Accounts/incentive/InsentiveDetailedPage.jsx";
import BonusMain from "./component/Accounts/Bonus/BonusMain.jsx";
import BonusDetailspage from "./component/Accounts/Bonus/BonusDetailspage.jsx";
import EditService from "./component/LandingPage/services/EditService.js";
import DestinationEditForm from "./component/LandingPage/Destination/DestinationEditForm.js";
import TalkToExpertMain from "./component/LandingPage/talkToExpert/TalkToExpertMain.js";
import IeltsLiveClassTeacherManagmentMain from "./component/Ielts/IeltsTeacherDashboard/IeltsLiveClassTeacherManagmentMain.js";
import EditNews from "./component/LandingPage/news/EditNews.js";
import EditEvents from "./component/LandingPage/events/EditEvents.js";
import EditTestimonials from "./component/LandingPage/testimonials/EditTestimonials.js";

import Report from "./component/report/Report.js";

import EditFeaturedAds from "./component/LandingPage/featuredAds/EditFeaturedAds.js";
import ScholarshipMain from "./component/LandingPage/ScholarshipList/ScholarshipMain.js";
import TicketsMain from "./component/Tickets/TicketsMain.js";
import ManageSlot from "./component/Tickets/ManageSlot.js";
import ScheduleDetailPage from "./component/Tickets/ScheduleDetailPage.js";
import TicketDetailPage from "./component/Tickets/TicketDetailPage.js";
import LeadSourceMain from "./component/setting/leadSource/LeadSourceMain.js";
import AddLeadSource from "./component/setting/leadSource/AddLeadSource.js";
import EditLeadSource from "./component/setting/leadSource/EditLeadSource.js";
import PartnersLeadApplication from "./component/Partner/PartnersLeadApplication.js";
import { ChatScreen } from "./component/Leads/createLeads/ChatScreen.js";
import ApplicationDetails from "./component/Application/allLeadApplications/ApplicationDetails.js";
import ReferAndEarnMain from "./component/ReferAndEarn/ReferAndEarnMain.js";
import WithDrawalDetailsMain from "./component/ReferAndEarn/withDrawal/WithdrawalDetails.js/WithDrawalDetailsMain.js";
import DeveloerRoleMenuMain from "./component/developers/DeveloerRoleMenuMain.js";
import RoleDetailsMain from "./component/administration/role/roleDetails/RoleDetailsMain.js";
import RoleMenuShows from "./component/administration/role/roleDetails/RoleMenuShows.js";
import RatingsMain from "./component/setting/Rating/RatingsMain.js";
import JobDetailedPage from "./component/HR/JobsDetails/JobDetailedPage.js";
import DefaultDocuments from "./component/setting/DefaultDocuments/DefaultDocuments.js";
import OpenedTasks from "./component/Leads/followUp/activities/OpenedTasks.js";
import OpenedMeetings from "./component/Leads/followUp/activities/OpenedMeetings.js";
import OpenedCalls from "./component/Leads/followUp/activities/OpenedCalls.js";
import OfficeListMain from "./component/LandingPage/officeList/OfficeListMain.js";
import CreateOfficeList from "./component/LandingPage/officeList/CreateOfficeList.js";
import EditOfficeList from "./component/LandingPage/officeList/EditOfficeList.js";
import NotificationMasterMain from "./component/setting/Notification/NotificationMain.js";
import NotificationSingle from "./component/setting/Notification/NotificationSingle.js";
import EditUniversityMain from "./component/university/universities/editUniversity/EditUniversityMain.js";
import EnquiryMain from "./component/Enquiry/EnquiryMain.js";
import EditUniversityCourse from "./component/university/universityCourse/EditUniversityCourse.js";
import UniversityCourseDetails from "./component/university/universityCourse/UniversityCourseDetails.js";
import Declaration from "./component/website/Declaration.js";
import { Applications } from "./component/Enquiry/MyApplications.js";
import EnquiryApplicationList from "./component/Enquiry/EnquiryApplicationList.js";
import DeclarationApproval from "./component/Application/allLeadApplications/declarationApproval.js";
import CreateGalleryForm from "./component/LandingPage/gallery/CreateGallery.js";
import GalleryMain from "./component/LandingPage/gallery/GalleryMain.js";
import EditGallery from "./component/LandingPage/gallery/EditGallery.js";
import PermissionDetailsWithAccordian from "./component/administration/role/roleDetails/PermissionDetailsWithAccordian.js";
import PaymentHistory from "./component/Application/allLeadApplications/paymentHistory.js";
import RegistrationApprovalMain from "./component/Accounts/Approval/registrationApprovalMain.js";
import TutionFeeApprovalMain from "./component/Accounts/Approval/tutionFeeApproval.js";
import CourseMasterMain from "./component/university/courseMaster/courseMasterMain.js";

import DealsMenuMain from "./component/Leads/DealsMenuMain.js";
import EntrolledMenuMain from "./component/Leads/EntrolledMenuMain.js";
import PartnerEntrolledAppliction from "./component/Partner/partnerEntrolledApplccationMenu.js";
import PartnerLeadApplicationMenu from "./component/Partner/partnerLeadApplccationMenu.js";
import ApprovalMainMenu from "./component/Leads/ApprovalMainMenu.js";
import EmployeeProfile from "./component/Profile/EmployeeProfile.js";
import PaymentDetails from "./component/Application/allLeadApplications/paymentDetails.js";
import RefundApprovalMain from "./component/Accounts/Approval/refundApproval.js";
// import Teststepper from "./component/Leads/createLeads/testStepper.js";
// import { MainCounselor } from "./component/Leads/createLeads/ChatScreen.js";

function App() {
  const [isHover, setIsHover] = useState(true);

  const mouseOnEnter = () => {
    setIsHover(false);
  };
  const mouseOnLeave = () => {
    setIsHover(true);
  };

  const tabLabels = {
    undefined: "top navigation",
    0: "top navigation",
    1: "destination",
    2: "services",
    3: "news",
    4: "events",
    5: "blogs",
    6: "gallery",
    7: "contact",
    8: "testmonials",
    9: "featured ads",
    10: "talk to expert",
    11: "scholarship list",
    12: "officelist"
  };

  return (
    <Box className="main">
      {/* <SuccessPopUp /> */}
      {/* <ErrorPopUp /> */}
      {/* <WaitForPartnerVerificcationScreen/> */}
      <BrowserRouter>
        <Header isHover={isHover} />
        <Sidebar
          mouseOnEnter={mouseOnEnter}
          mouseOnLeave={mouseOnLeave}
          isHover={isHover}
        />

        <Routes>
          <Route path="/" element={<HOME toggle={isHover} />} />

          <Route path="/Home" element={<HOME toggle={isHover} />} />

          <Route path="/login" element={<Login toggle={isHover} />} />

          <Route
            path="/sample"
            element={<LeadsFilterSearch toggle={isHover} />}
          />

          {/* Leads */}
          <Route path="/Leads" element={<LeadsMain toggle={isHover} />} />
          {/* Deals */}
          <Route path="/deals" element={<DealsMenuMain toggle={isHover} />} />

          {/* Entrolled */}
          <Route
            path="/enrolled"
            element={<EntrolledMenuMain toggle={isHover} />}
          />

          <Route
            path="/approval"
            element={<ApprovalMainMenu toggle={isHover} />}
          />

          <Route
            path="/Leads/details/:id/:subMenu/:mainMenu/:tab"
            element={<CreateLeadDocs toggle={isHover} />}
          />

          <Route
            path="/Leads/details/:id/:subMenu/:mainMenu"
            element={<CreateLeadDocs toggle={isHover} />}
          />
          <Route
            path="/Leads/details/chat/:id"
            element={<ChatScreen toggle={isHover} />}
          />
          <Route
            path="/Leads/details/leadFollowUp/:id"
            element={<MainFollowUp toggle={isHover} />}
          />
          <Route
            path="/Leads/details/leadFollowUp/:id/open-tasks"
            element={<OpenedTasks toggle={isHover} />}
          />
          <Route
            path="/Leads/details/leadFollowUp/:id/open-meetings"
            element={<OpenedMeetings toggle={isHover} />}
          />
          <Route
            path="/Leads/details/leadFollowUp/:id/open-calls"
            element={<OpenedCalls toggle={isHover} />}
          />
          {/* Adminisration */}
          <Route
            path="/Administration"
            element={
              <AdministrationMain
                toggle={isHover}
                tab1={<RoleMangment toggle={isHover} />}
                tab2={<EmployeeMain toggle={isHover} />}
              />
            }
          />
          <Route
            path="/Administration/role/details/:id"
            element={
              <RoleDetailsMain
                toggle={isHover}
                tab1={<RoleMenuShows toggle={isHover} />}
                tab2={<PermissionDetailsWithAccordian toggle={isHover} />}
                // tab3={<PermissionDetails toggle={isHover} />}
              />
            }
          />
          {/* Role Managment */}
          <Route
            path="/Administration/createRole"
            element={<CreateRoles toggle={isHover} />}
          />
          <Route
            path="/Administration/editRole/:roleId"
            element={<EditRole toggle={isHover} />}
          />
          {/* Employee Managment */}
          <Route
            path="/Administration/employee/addEmployee"
            element={<AddEmployee toggle={isHover} />}
          />
          <Route
            path="/Administration/employee/details/:id"
            element={<EmployeeDetails toggle={isHover} />}
          />
          <Route
            path="/Administration/employee/details/edit/:id"
            element={<EditEmployeeDetails toggle={isHover} />}
          />
          <Route
            path="/Administration/employee/details/applyLeave/:id"
            element={<EmployeeLeaveApplyForm toggle={isHover} />}
          />
          <Route
            path="/Administration/employee/details/track/:id"
            element={<EmployeeTrackPage toggle={isHover} />}
          />
          {/* partner managment */}
          <Route
            path="/Partner"
            element={
              <PartnerMain
                toggle={isHover}
                tab1={<AllPartners />}
                tab2={<PartnerApproval />}
                tab3={<PartnerKycApproval />}
                // tab4={<PartnersLeadApplication />}
                // tab5={<PartnerEntrolledLeads />}
              />
            }
          />

          <Route
            path="/partnerLeadApplication"
            element={<PartnerLeadApplicationMenu toggle={isHover} />}
          />
          <Route
            path="/partnerEntrolledApplication"
            element={<PartnerEntrolledAppliction toggle={isHover} />}
          />
          <Route
            path="/Partner/viewDetails/:id"
            element={<PartnerKycMain toggle={isHover} />}
          />
          <Route
            path="/Partner/details/:id"
            element={<PartnerDetailsMain toggle={isHover} />}
          />
          {/* University Managment */}
          <Route
            path="/university"
            element={
              <UniversityMain
                toggle={isHover}
                tab1={<Universities />}
                tab2={<UniversityMasterMain />}
                tab3={<CourseMasterMain />}
              />
            }
          />
          <Route
            path="/university/addUniversity"
            element={<AddUniversity toggle={isHover} />}
          />
          <Route
            path="/university/editUniversity/:id"
            element={<EditUniversityMain toggle={isHover} />}
          />
          <Route
            path="/university/addUniversity/ViewUniversity/:id"
            element={<ViewUniversity toggle={isHover} />}
          />
          {/* GSL Setting*/}
          <Route
            path="/Settings"
            element={
              <SettingMain
                toggle={isHover}
                tab1={<MasterChip toggle={isHover} />}
                tab2={<NotificationMasterMain />}
                tab3={<BranchMaster toggle={isHover} />}
                tab4={<BannerMaster toggle={isHover} />}
                tab5={<LeadSourceMain toggle={isHover} />}
                tab6={<RatingsMain />}
                tab7={<DefaultDocuments />}
              />
            }
          />
          <Route
            path="/settings/notification/:id"
            element={<NotificationSingle toggle={isHover} />}
          />
          <Route
            path="/Settings/addBanner"
            element={<AddBannerForm toggle={isHover} />}
          />
          <Route
            path="/Settings/addleadSource"
            element={<AddLeadSource toggle={isHover} />}
          />
          <Route
            path="/Settings/editLeadSource/:id"
            element={<EditLeadSource toggle={isHover} />}
          />
          {/* UNIVERSITY COURSES */}

          <Route
            path="/university/addUniversity/ViewUniversity/courses/:id"
            element={<UniversityCourseMain toggle={isHover} />}
          />
          <Route
            path="/university/courses/addCourse/:id"
            element={<AddUniversityCourse toggle={isHover} />}
          />
          <Route
            path="/university/courses/editCourse/:id/:universityId"
            element={<EditUniversityCourse toggle={isHover} />}
          />
          <Route
            path="/university/courses/courseDetails/:id/:universityId"
            element={<UniversityCourseDetails toggle={isHover} />}
          />
          <Route
            path="/DocumentUpload"
            element={<KnowledgeBankMain toggle={isHover} />}
          />
          {/* Enquiry */}
          <Route path="/Enquiry" element={<EnquiryMain toggle={isHover} />} />
          <Route
            path="enquiry/application/:id"
            element={<Applications toggle={isHover} />}
          />
          <Route
            path="enquiry/applicationDetails/:id/:applicationId"
            element={<EnquiryApplicationList toggle={isHover} />}
          />
          {/* Ielts */}
          <Route
            path="/ielts"
            element={
              <Ielts
                toggle={isHover}
                tab1={<IeltsCenterMain toggle={isHover} />}
                tab2={<IeltsLearningManagmentMain toggle={isHover} />}
                tab3={<IeltsCourseManagmentMain toggle={isHover} />}
                tab4={<IeltsTeacherManagmentMain toggle={isHover} />}
                tab5={<IeltsEntrolledMain toggle={isHover} />}
                tab6={<IeltsPurchasedMain toggle={isHover} />}
                tab7={<IeltsSettingsMain toggle={isHover} />}
              />
            }
          ></Route>
          <Route
            path="/ielts/purchased/online/:id"
            element={<PurchasedDetailedView toggle={isHover} />}
          />
          <Route
            path="/ielts/purchased/offline/:id"
            element={<PurchasedDetailedView toggle={isHover} />}
          />
          <Route
            path="/ielts/topicdetailedview/:id"
            element={<TopicDetailedView toggle={isHover} />}
          />
          <Route
            path="/ielts/learningManagment/editTopics/:id"
            element={<TopicEditForm toggle={isHover} />}
          ></Route>
          {/* <Route
            path="/ielts/learningManagment/editTopics/:id"
            element={<TopicEditForm toggle={isHover} />}
          ></Route> */}
          <Route
            path="/ielts/student/register"
            element={<StudentRegisterForm toggle={isHover} />}
          />
          <Route
            path="/ielts/center/addnew/"
            element={<CenterTableAddNewForm toggle={isHover} />}
          />
          <Route
            path="/ielts/center/detailedview/:id"
            element={<IeltsCeterDetailedView toggle={isHover} />}
          />
          <Route
            path="/ielts/examtips/addnew/"
            element={<ExamTipsAddNewForm toggle={isHover} />}
          />
          <Route
            path="/ielts/examtips/editExamTip/:id"
            element={<ExamTipsEditForm toggle={isHover} />}
          />
          <Route
            path="/ielts/questionbank/addnew/"
            element={<QuestionBankAddNewForm toggle={isHover} />}
          />
          <Route
            path="/ielts/questionbank/edit/:id"
            element={<QuestionBankEditNewForm toggle={isHover} />}
          />
          <Route
            path="/ielts/courses/addnew/"
            element={<CourseAddNew toggle={isHover} />}
          />
          <Route
            path="/ielts/courses/edit/:id"
            element={<CourseEditForm toggle={isHover} />}
          />
          <Route
            path="/ielts/courses/detailedview/:type/:id"
            element={<CourseDetailedView toggle={isHover} />}
          />
          {/* Live Classs */}
          <Route
            path="/ielts/liveclass/addnew/"
            element={<IeltsLiveClassAddNewForm toggle={isHover} />}
          />
          <Route
            path="/ielts/liveclass/detailedView/:id"
            element={<IeltsLiveClassSinglePage toggle={isHover} />}
          />
          <Route
            path="/ielts/liveclass/editForm/:id"
            element={<IeltsLiveClassEditForm toggle={isHover} />}
          />
          <Route
            path="/ielts/view/:id"
            element={
              <IeltsEntrolledStudentsViewMain
                toggle={isHover}
                tab1={<IeltsEntrolledStudentsView toggle={isHover} />}
              />
            }
          ></Route>
          <Route
            path="/ielts/settings/zoom/addnew"
            element={<IeltsZoomConfigurationForm toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/settings/faq/addnew"
            element={<IeltsFaqForm toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/settings/faq/:id"
            element={<FaqSingleItem toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/settings/faq/edit/:id"
            element={<IeltsEditFaq toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/learningManagment/addTopics"
            element={<AddNewTopicsForm toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/teacherManagment/addTeacher"
            element={<IeltsTeacherAddForm toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/teacherManagment/editTeacher/:id"
            element={<IeltsTeacherEditForm toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/teacherManagment/view/:id"
            element={
              <IeltsTeacherDetailedViewMain
                toggle={isHover}
                tab1={<IeltsTeacherDetailedView toggle={isHover} />}
                tab2={<AssignedStudentsMain toggle={isHover} />}
              />
            }
          ></Route>
          {/* TeacherDashboard */}
          <Route
            path="/ielts/teacherdashboard/assignedstudents/"
            element={<IeltsTeacherDashBoardMain toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/teacherdashboard/assignedstudents/:id"
            element={<IeltsTeacherDashboardDetailedView toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/teacherdashboard/"
            element={<IeltsTeacherDashBoardHome toggle={isHover} />}
          ></Route>
          <Route
            path="/ielts/teacherdashboard/assignedLiveClass"
            element={<IeltsLiveClassTeacherManagmentMain toggle={isHover} />}
          ></Route>
          {/* Hr managment */}
          <Route
            path="/hrManagment"
            element={
              <HrMain
                toggle={isHover}
                tab1={<AllHrAttendance toggle={isHover} />}
                tab2={<AllEmployeeHolidays toggle={isHover} />}
                tab3={<AllEmployeeLeaveTable toggle={isHover} />}
                tab4={<AllEmployeePaySlip toggle={isHover} />}
                tab5={<AllEmployeeJobs toggle={isHover} />}
              />
            }
          />
          <Route
            path="/hrmanagment/Createjobs"
            element={<EmployeeJobCreateForm toggle={isHover} />}
          />
          <Route
            path="/hrmanagment/Editjobs/:id"
            element={<EmployeeJobEditForm toggle={isHover} />}
          />
          <Route
            path="/hrmanagment/jobs/:id"
            element={<JobDetailedPage toggle={isHover} />}
          />
          {/* notification section */}
          <Route
            path="/notifications"
            element={<NotificationMain toggle={isHover} />}
          />
          {/* Landing Page */}
          <Route
            path="/landingPage"
            element={
              <LandingPageMain
                toggle={isHover}
                tab1={<AllTopNavigation toggle={isHover} />}
                tab2={<AllDestination toggle={isHover} />}
                tab3={<Services toggle={isHover} />}
                tab4={<NewsMain toggle={isHover} />}
                tab5={<EventMain toggle={isHover} />}
                tab6={<BlogMain toggle={isHover} />}
                tab7={<TestimonialsMain toggle={isHover} />}
                tab8={<FeaturedAdsMain toggle={isHover} />}
                tab9={<TalkToExpertMain toggle={isHover} hideButton={true} />}
                tab10={<ScholarshipMain toggle={isHover} hideButton={true} />}
                tab11={<OfficeListMain toggle={isHover} hideButton={true} />}
                tab12={<GalleryMain toggle={isHover} hideButton={true} />}
                tab13={"contact"}
                url1="/topNavigationcreateForm"
                url2="/destinationCreateForm"
                url3="/serviceCreateForm"
                url4="/newsCreateForm"
                url5="/eventsCreateForm"
                url6="/blogCreateForm"
                url7="/testmonialsCreateForm"
                url8="/featuredAdsCreateForm"
                url9=""
                url10=""
                url11="/Create-office-list"
                url12="/gallery"
                // url13="/blogCreateForm"
                tabLabels={tabLabels}
              />
            }
          />
          <Route
            path="/landingPage/topNavigationcreateForm"
            element={<TopNavigationCreateForm toggle={isHover} />}
          />
          <Route
            path="/landingPage/topNavigationEditForm/:id"
            element={<TopNavigationEdit toggle={isHover} />}
          />
          <Route
            path="/landingPage/destinationCreateForm"
            element={<DestinationCreateForm toggle={isHover} />}
          />
          <Route
            path="/landingPage/destinationEditForm/:id"
            element={<DestinationEditForm toggle={isHover} />}
          />
          <Route
            path="/landingPage/serviceCreateForm"
            element={<CreateService toggle={isHover} />}
          />
          <Route
            path="/landingPage/Create-office-list"
            element={<CreateOfficeList toggle={isHover} />}
          />
          <Route
            path="/landingPage/serviceEditForm/:id"
            element={<EditService toggle={isHover} />}
          />
          <Route
            path="/landingPage/newsCreateForm"
            element={<CreateNews toggle={isHover} />}
          />
          <Route
            path="/landingPage/newsEditForm/:id"
            element={<EditNews toggle={isHover} />}
          />
          <Route
            path="/landingPage/eventsCreateForm"
            element={<CreateEvents toggle={isHover} />}
          />
          <Route
            path="/landingPage/eventsEditForm/:id"
            element={<EditEvents toggle={isHover} />}
          />
          <Route
            path="/landingPage/blogCreateForm"
            element={<CreateBlogs toggle={isHover} />}
          />
          <Route
            path="/landingPage/blogPageEdit/:id"
            element={<EditBlogs toggle={isHover} />}
          />
          <Route
            path="/landingPage/testmonialsCreateForm"
            element={<CreateTestimonials toggle={isHover} />}
          />
          <Route
            path="/landingPage/testmonialsEditForm/:id"
            element={<EditTestimonials toggle={isHover} />}
          />
          <Route
            path="/landingPage/featuredAdsCreateForm"
            element={<FeaturedAdsCreateForm toggle={isHover} />}
          />
          <Route
            path="/landingPage/featuredAdsEditForm/:id"
            element={<EditFeaturedAds toggle={isHover} />}
          />
          <Route
            path="/landingPage/officeEditForm/:id"
            element={<EditOfficeList toggle={isHover} />}
          />
          <Route
            path="/landingPage/gallery"
            element={<CreateGalleryForm toggle={isHover} />}
          />
          <Route
            path="/landingPage/gallery/:id"
            element={<EditGallery toggle={isHover} />}
          />
          {/* Account Page */}
          <Route
            path="/accounts"
            element={
              <AccountMain
                toggle={isHover}
                tab1={<IncentiveCategory />}
                tab2={<EmployeeIncentiveMain />}
                tab3={<ReportIncentiveMain />}
                tab4={<BonusMain />}
                tab5={<RegistrationApprovalMain />}
                tab6={<TutionFeeApprovalMain />}
                tab7={<RefundApprovalMain />}
              />
            }
          />
          <Route
            path="/accounts/incentive/:id"
            element={<InsentiveDetailedPage toggle={isHover} />}
          />
          <Route
            path="/accounts/bonus/:id"
            element={<BonusDetailspage toggle={isHover} />}
          />
          <Route
            path="/accounts/createEmployeeIncentive"
            element={<EmployeeIncentiveCreate toggle={isHover} />}
          />
          {/* partner */}
          {/* Leads */}
          <Route
            path="/Application"
            element={
              <ApplicationMain
                toggle={isHover}
                tab1={<AllLeadApplications toggle={isHover} />}
                // tab2={<PartnerLeads toggle={isHover} />}
                tab2={<EndrolledApplications toggle={isHover} />}
                // tab3={<DeclarationApproval toggle={isHover} />}

                // tab4={<LeadStatusApprove toggle={isHover} />}
                // tab5={<LeadsDeferral toggle={isHover} />}
              />
            }
          />
          <Route
            path="Application/applicationDetails/:id/:leadId"
            element={<ApplicationDetails toggle={isHover} />}
          />
          <Route
            path="/paymentHistory/:applicationID/:leadID"
            element={<PaymentHistory toggle={isHover} />}
          />

          <Route
            path="/paymentHistory/:leadID"
            element={<PaymentDetails toggle={isHover} />}
          />

          {/* Report */}
          <Route path="/reports" element={<Report toggle={isHover} />} />
          <Route path="/tickets" element={<TicketsMain toggle={isHover} />} />
          <Route
            path="/tickets/details/:id"
            element={<TicketDetailPage toggle={isHover} />}
          />
          <Route
            path="/tickets/schedule/details/:id"
            element={<ScheduleDetailPage toggle={isHover} />}
            cliq
          />
          <Route
            path="/tickets/manage-slot"
            element={<ManageSlot toggle={isHover} />}
          />
          <Route
            path="/ReferAndEarn"
            element={<ReferAndEarnMain toggle={isHover} />}
          />
          <Route
            path="/ReferAndEarn/:id"
            element={<WithDrawalDetailsMain toggle={isHover} />}
          />
          <Route
            path="/developer/rolemenu"
            element={<DeveloerRoleMenuMain toggle={isHover} />}
          />
          <Route path="/website" element={<Declaration toggle={isHover} />} />
          <Route
            path="/profile"
            element={<EmployeeProfile toggle={isHover} />}
          />

          {/* <Route path="/test" element={<Teststepper toggle={isHover} />} /> */}
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
