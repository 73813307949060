import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  FormControl,
  InputLabel,
  Select,
  Pagination,
  Dialog,
} from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { Box, Checkbox, Grid, MenuItem, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@mui/material";

import { BiSortAlt2 } from "react-icons/bi";

// import "./ieltsEntrolledStudentTable.css";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { ContentState, convertFromHTML } from "draft-js";
import { TbEdit } from "react-icons/tb";
import { ieltsTeacherManagmentStatusChangeAction } from "../../../actions/IeltsActions/teacherManagmentAction";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import { sceduleMeetingStatusChangeAction } from "../../../actions/ticket/sceduleMeeting";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../../Leads/createLeads/AccessDeniedIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400",
  height: "400",
  //   overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 20,
  p: 4,
};

function ScheduleTable({
  setIdsFunction,
  data,
  leadFindLoading,
  selectBoxIdPassHandler,
  columns,
  pageNumber,
  subMenu,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    privilege,
    hasPrivilege,
    isStatusChangePrevilage,
  } = usePrivilegeCheck();

  useEffect(() => {
    if (pageNumber > 10) {
      setRowsPerPage(pageNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [pageNumber]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    setPage(1);
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // HandleStatusChange

  const handleStatus = (status, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      dispatch(sceduleMeetingStatusChangeAction(id, status));
    } else {
      setOpenAlert(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(data?.length, page, rowsPerPage);
  };

  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              backgroundColor: "#fff",
              padding: "5px",
              paddingRight: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Showing{" "}
            <Typography
              component={"span"}
              sx={{
                backgroundColor: "#fff",
                padding: "5px",
                paddingLeft: "0px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                fontSize: "16px",
                // lineHeight: "24px",
                fontWeight: "700",
              }}
            >
              {
                sortedData?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                ).length
              }{" "}
              Result
            </Typography>
          </Typography>
          {leadFindLoading ? (
            <>
              {/* <Skeleton /> */}
              <Typography variant="h1">
                <Skeleton animation={false} />
              </Typography>

              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
              <Typography variant="h3">
                <Skeleton animation="wave" />
              </Typography>

              <Skeleton animation={false} />
            </>
          ) : (
            <div
              style={{
                overflowX: "scroll",
                border: "0px solid #ffff",
              }}
              className="scrollBar"
            >
              <Table className="EmployeeJobTable">
                <TableHead>
                  <TableRow className="EmployeeJobTableCell">
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1, // To ensure it's above other cells
                        padding: "1px",
                        border: "1px solid #ddd",
                        // borderLeft: "1px solid #ddd",
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <Checkbox
                        checked={selectedRows?.length === sortedData?.length}
                        onChange={handleSelectAllRows}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    {columns?.map((column) => {
                      if (column.hidden) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={column.field}
                          className="EmployeeJobTableCell LeadCommon"
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <TableSortLabel
                            className="LeadHeaderText"
                            active={column === sortBy}
                            direction={sortOrder}
                            onClick={() => requestSort(column)}
                            IconComponent={
                              column.field !== "action" && CustomSortIcon
                            }
                          >
                            {column.title}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData
                    ?.slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, indx) => (
                      <TableRow className="EmployeeJobTable" key={indx}>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1, // To ensure it's above other cells
                            backgroundColor: "white", // Adjust as needed
                            padding: "1px",
                            border: "1px solid #ddd",
                          }}
                        >
                          <Checkbox
                            checked={selectedRows.includes(item.id)}
                            onChange={() => handleSelectRow(item.id)}
                            style={{ color: "#f81b82" }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {indx + 1}
                        </TableCell>

                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.name}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.counsilorName}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.sceduledDate}
                        </TableCell>
                        <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          {item.sceduledTime}
                        </TableCell>
                        {/* <TableCell
                        style={{
                          padding: "0 16px",
                          whiteSpace: "nowrap",
                          //   display: "flex",
                          //   alignItems: "center",
                          //   justifyContent: "center",
                          //   height:"100%",
                          textAlign: "center",
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3277_54765)">
                            <path
                              d="M8.75 17.6123V22.2503C8.75 22.5743 8.958 22.8613 9.266 22.9633C9.343 22.9883 9.422 23.0003 9.5 23.0003C9.734 23.0003 9.96 22.8903 10.104 22.6943L12.817 19.0023L8.75 17.6123Z"
                              fill="#ACB1C6"
                            />
                            <path
                              d="M23.685 0.139089C23.455 -0.0239114 23.153 -0.0459114 22.903 0.0850886L0.403045 11.8351C0.137044 11.9741 -0.0199555 12.2581 0.00204448 12.5571C0.0250445 12.8571 0.224045 13.1131 0.507045 13.2101L6.76205 15.3481L20.083 3.95809L9.77505 16.3771L20.258 19.9601C20.336 19.9861 20.418 20.0001 20.5 20.0001C20.636 20.0001 20.771 19.9631 20.89 19.8911C21.08 19.7751 21.209 19.5801 21.242 19.3611L23.992 0.861089C24.033 0.581089 23.915 0.303089 23.685 0.139089Z"
                              fill="#ACB1C6"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_3277_54765">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </TableCell> */}

                        {item.status == "cancelled" ? (
                          <TableCell
                            style={{
                              padding: "0 16px",
                              whiteSpace: "nowrap",
                              fontWeight: 700,
                              color: "red",
                            }}
                          >
                            {item.status}
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{ padding: "0 8px", whiteSpace: "nowrap" }}
                          >
                            <FormControl
                              sx={{
                                mt: 1,
                                bgcolor:
                                  item.status == "Approved"
                                    ? "#228200"
                                    : "#E52900",
                                borderRadius: "8px",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "fit-content",
                                "& .MuiSelect-icon": {
                                  top: "3px",
                                },
                              }}
                            >
                              <InputLabel
                                htmlFor="demo-simple-select-placeholder"
                                style={{
                                  transform: "translate(10px, 10px)",
                                  pointerEvents: "none",
                                  zIndex: 1,
                                  transition:
                                    "transform 0.25s, font-size 0.25s",
                                  margin: "-6px",
                                  color: "white",
                                  fontWeight: 400,
                                  paddingLeft: "10px",
                                  border: "none !important",
                                }}
                              >
                                {item.status ? item.status : "placeholder"}
                              </InputLabel>
                              <Select
                                value={status}
                                onChange={(e) =>
                                  handleStatus(e.target.value, item.id)
                                }
                                inputProps={{
                                  // name: {item.coloum7},
                                  id: "demo-simple-select-placeholder",
                                }}
                                sx={{
                                  position: "relative",
                                  paddingRight: "10px",
                                  width: "112px",
                                  // maxHeight: "30px",
                                  // outline:"none !important",border:"4px solid #000",MuiSelect-select
                                  top: "-4px",
                                  ".MuiSelect-select": {
                                    padding: "0",
                                  },
                                  ".MuiOutlinedInput-notchedOutline": {
                                    outline: "none !important",
                                    border: "none !important",
                                  },
                                }}
                              >
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        )}

                        {/* <TableCell
                          style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                        >
                          <SlOptionsVertical
                            id="demo-positioned-button"
                            aria-controls={
                              leadOpenActionButton
                                ? "demo-positioned-menu"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              leadOpenActionButton ? "true" : undefined
                            }
                            onClick={(e) => {
                              handleClickAction(e, indx + 1);
                            }}
                            variant="outlined"
                            style={{ marginLeft: "10px", color: "#F80B7A" }}
                            sx={{
                              backgroundColor: "#141E3C",
                              size: "10px",
                              padding: 1,
                              margin: 3,
                              color: "#fff",
                              width: "75px",
                              height: "40px",
                            }}
                          /> */}
                        {/* TABLE ACTION MENU START*/}
                        {/* <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            open={leadActionButtonShow}
                            onClose={handleCloseAction}
                            anchorEl={leadActionButtonShow}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorOrigin={{
                              vertical: "bottom", // Change from "top" to "bottom"
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top", // Change from "top" to "bottom"
                              horizontal: "left",
                            }}
                            sx={{
                              borderColor: "black",
                              borderRadius: "24px",
                              mt: 1.5,
                              width: "auto",
                              height: "auto",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <MenuItem>
                                <AiOutlineEye
                                  style={{
                                    fontSize: "lg",
                                    width: "20px",
                                    height: "19px",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/tickets/schedule/details/${tableId}`
                                    )
                                  }
                                />
                              </MenuItem>
                            </Box>
                          </Menu>{" "}
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter />
              </Table>
            </div>
          )}
          <Pagination
            sx={{
              mt: "28px",
              "& .Mui-selected": {
                backgroundColor: "#f81b82 !important",
                color: "#fff",
              },
              "& .MuiPagination-ul": {
                justifyContent: "end",
              },
            }}
            count={Math.ceil(data?.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

      {/* permission component */}
      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
}

export default ScheduleTable;
