import axios from "axios";
import {
  CITY_MASTER_ADD_ERR,
  CITY_MASTER_ADD_REQUEST,
  CITY_MASTER_ADD_SUCCESS,
  CITY_MASTER_DELETE_ERR,
  CITY_MASTER_DELETE_REQUEST,
  CITY_MASTER_DELETE_SUCCESS,
  CITY_MASTER_EDIT_ERR,
  CITY_MASTER_EDIT_FIND_ERR,
  CITY_MASTER_EDIT_FIND_REQUEST,
  CITY_MASTER_EDIT_FIND_SUCCESS,
  CITY_MASTER_EDIT_REQUEST,
  CITY_MASTER_EDIT_SUCCESS,
  CITY_MASTER_FIND_ERR,
  CITY_MASTER_FIND_REQUEST,
  CITY_MASTER_FIND_SUCCESS,
} from "../constants/cityMasterConstant";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";

// cityMasterAddAction
export const cityMasterAddAction =
  (selectedState, selectedCity, stateId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CITY_MASTER_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      selectedState.updatedby = isUserExist.name;

      let { data } = await axios.post(
        "/api/admin/city/addCityMaster",
        { selectedState, selectedCity, stateId },
        config
      );

      dispatch({ type: CITY_MASTER_ADD_SUCCESS, payload: data });
    } catch (error) {
      console.log(error,"errorororororororororrooorororor******");
      dispatch({ type: CITY_MASTER_ADD_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true});
    }
  };

//   findCityMasterAction
export const findCityMasterAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CITY_MASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get("/api/admin/city/findCityMaster", config);

    dispatch({ type: CITY_MASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CITY_MASTER_FIND_ERR, payload: error });
  }
};

// cityMasterDeleteAction
export const cityMasterDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CITY_MASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/city/deleteCityMaster?id=${id}`,
      config
    );

    dispatch({ type: CITY_MASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CITY_MASTER_DELETE_ERR, payload: error });
  }
};

// findEditCityFormAction
export const findEditCityFormAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CITY_MASTER_EDIT_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/city/findEditCityMaster?id=${id}`,
      config
    );

    dispatch({ type: CITY_MASTER_EDIT_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CITY_MASTER_EDIT_FIND_ERR, payload: error });
  }
};

// cityMasterEditAction
export const cityMasterEditAction =
  (cityData, cityid, stateId) => async (dispatch, getState) => {
    try {
      dispatch({ type: CITY_MASTER_EDIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      let { data } = await axios.put(
        `/api/admin/city/editCityMaster?id=${cityid}`,
        { cityData, stateId },
        config
      );

      dispatch({ type: CITY_MASTER_EDIT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: CITY_MASTER_EDIT_ERR, payload: error });
    }
  };
