import { Box, Chip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VideoTabItem from "./tabItems/VideoTabItem";
import AudioTabItem from "./tabItems/AudioTabItem";
import NotesTabItem from "./tabItems/NotesTabItem";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCourseFindByIdAction } from "../../../../../actions/IeltsActions/courseManagmentAction";
import { useParams } from "react-router-dom";
import { InfoHeader } from "../../../../customComponent/InfoHeader";

function CourseDetailedOfflineViewTabItem({ tabName }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [offlineArray, setOfflineArray] = useState([]);

  // Find Course Details

  let {
    ieltsCourseManagmentCourseFindByIdSuccess,
    ieltsCourseManagmentCourseFindByIderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseFindById;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseFindByIdAction(id, tabName));
  }, [dispatch]);

  console.log(
    ieltsCourseManagmentCourseFindByIdSuccess,
    "ieltsCourseManagmentCourseFindByIdSuccess"
  );

  // function removeDuplicatesByCourseId(dataArray) {
  //   const uniqueCourseIds = new Set();
  //   return dataArray.filter((obj) => {
  //     if (!uniqueCourseIds.has(obj.courseId)) {
  //       uniqueCourseIds.add(obj.courseId);
  //       return true;
  //     }
  //     return false;
  //   });
  // }

  useEffect(() => {
    if (ieltsCourseManagmentCourseFindByIdSuccess) {
      setOfflineArray(
        ieltsCourseManagmentCourseFindByIdSuccess.data?.offlineData
      );
    }
  }, [ieltsCourseManagmentCourseFindByIdSuccess]);

  console.log(offlineArray, "offlineArrayaaaaaaaaaaaaaaa");
  console.log(
    ieltsCourseManagmentCourseFindByIdSuccess?.data?.offlineData,
    "ieltsCourseManagmentCourseFindByIdSuccess.data?.offlineData"
  );

  return (
    <>
      {offlineArray.length > 0 ? (
        <Box
          sx={{
            mb: "50px",
            display: "grid",
            gap: "20px",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {offlineArray?.map((item, i) => (
            <Box
              key={i}
              sx={{
                border: "1px solid rgba(236, 236, 236, 1)",
                borderRadius: "4px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Center Name
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {item?.ielts_center?.CenterName}
                </Typography>
              </Box>

              {}
              <Box>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Slot
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {item?.ielts_center_slot?.timeFrom +
                    " - " +
                    item?.ielts_center_slot?.timeTo}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Seat Availability
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {item?.SeatAvaliability}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "500px",
            }}
          >
            <Box
              sx={{
                "& img": {
                  width: "200px",
                },
              }}
            >
              <img
                src="/course-no-item-to-display.png"
                alt="no item to list image"
              />
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                }}
              >
                No items to display
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default CourseDetailedOfflineViewTabItem;
