import { Alert, AlertTitle, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { findBranchMasterAction } from "../../../../actions/branchMasterActions";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import { MasterTable } from "../MasterTable";
import AddBranchForm from "./AddBranchForm";
import EditBranchForm from "./EditBranchForm";
import PremissionDialog from "../../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../../constants/customHooks/UsePrivilegeCheck";

function BranchMaster() {
  const dispatch = useDispatch();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [branchData, setBranchData] = useState([]);
  const [editData, setEdiData] = useState([]);
  const [editForm, setEditFormOpen] = useState(false);

  let {
    branchMasterFindLoading,
    branchMasterFindSuccess,
    branchMasterFindErr,
  } = useSelector((state) => {
    return state.findBranchMaster;
  });

  let {
    branchMasterAddLoading,
    branchMasterAddSuccess,
    branchMasterAddErr,
  } = useSelector((state) => {
    return state.branchMasterAdd;
  });

  let { branchMasterDeleteSuccess } = useSelector((state) => {
    return state.branchMasterDelete;
  });

  let { branchMasterEditSuccess } = useSelector((state) => {
    return state.branchMasterEdit;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  const [addForm, setAddFormOpen] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // useEffect(() => {
  //   if (branchMasterEditSuccess && successAlertMsgSuccess) {
  //     Swal.fire("Updated", `Branch updated successfully`, "success");
  //     dispatch(successTrueMsgAction(false));
  //     dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
  //   }
  // }, [branchMasterEditSuccess, successAlertMsgSuccess]);

  useEffect(() => {
    if (branchMasterAddSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `Created a new branch`, "success");
      dispatch(successTrueMsgAction(false));

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [branchMasterAddSuccess]);

  useEffect(() => {
    if (successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: branchMasterAddErr,
      });

      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, branchMasterAddErr, dispatch]);

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [
    branchMasterAddSuccess,
    branchMasterDeleteSuccess,
    branchMasterEditSuccess,
    dispatch,
  ]);

  useEffect(() => {
    if (branchMasterFindSuccess) {
      let data = branchMasterFindSuccess?.reverse();
      setBranchData(data);
    }
  }, [branchMasterFindSuccess]);

  const data = branchData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.branchName || " -  ",
    column2: data.location || " -  ",
    column3: data.phoneNumber || " -  ",
    column4: data?.branchMasterId || " -  ",
    email: data?.branchEmail || " -  ",
    countryCode: data.countryCode || " -  ",
    branchEmail: data.branchEmail || " -  ",
  }));

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Branch Name",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Location",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Phone Number",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Email ID",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "BranchMasterID",
      field: "code",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const handleModalClick = () => {
    if (isCreatePrevilage("branch")) {
      setAddFormOpen(true);
    } else {
      setOpenAlert(true);
    }
  };

  const addMasterCategryHandler = () => {
    setAddFormOpen(false);
    setEditFormOpen(false);
  };

  const rollEditHandler = (id) => {
    setEditFormOpen(true);
    let filterEditData = data.filter((branch) => branch.id === id);
    setEdiData(filterEditData);
  };

  if (!hasPrivilege("branch") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  return (
    <>
      <Button
        sx={{
          color: "white",
          backgroundColor: "#141E3C",
          height: "38px",
          width: "143px",
          fontWeight: 700,
          fontSize: "12px",
          display: "flex",
          marginLeft: "auto",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: "rgba(20, 30, 60, 0.9)",
          },
        }}
        onClick={handleModalClick}
      >
        Add New Branch
      </Button>

      <div style={{ marginTop: "15px" }}></div>

      <MasterTable
        data={data}
        columns={columns}
        component="branchMaster"
        rollEditHandler={rollEditHandler}
        subMenu="branch"
      />

      {/* add master component */}
      {addForm && (
        <AddBranchForm
          status={addForm}
          addMasterCategryHandler={addMasterCategryHandler}
        />
      )}

      {editForm && (
        <EditBranchForm
          status={editForm}
          addMasterCategryHandler={addMasterCategryHandler}
          data={editData}
        />
      )}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default BranchMaster;
