import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import IeltsSettingsFaqTable from "./IeltsSettingsFaqTable";
import {
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import { MdClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ieltsSettingsFaqFindAction } from "../../../../actions/IeltsActions/SettingsAction";

function IeltsFaqMain() {
  const dispatch = useDispatch();

  // all faq
  let { ieltsSettingsFaqFindSuccess } = useSelector((state) => {
    return state.ieltsSettingsFaqFind;
  });

  // status change
  let { ieltsSettingsFaqChangeStatusSuccess } = useSelector((state) => {
    return state.ieltsSettingsFaqChangeStatus;
  });

  // delete
  let { ieltsSettingsFaqDeleteSuccess } = useSelector((state) => {
    return state.ieltsSettingsFaqDelete;
  });

// fetch all data once once more when deleted or changed its status
  useEffect(() => {
    dispatch(ieltsSettingsFaqFindAction());
  }, [
    dispatch,
    ieltsSettingsFaqChangeStatusSuccess,
    ieltsSettingsFaqDeleteSuccess,
  ]);



  const [tableData, setTabledata] = useState();

  useEffect(() => {
    if (ieltsSettingsFaqFindSuccess) {
      setTabledata(ieltsSettingsFaqFindSuccess.data);
      setSortedFaq(ieltsSettingsFaqFindSuccess.data);
    }
  }, [ieltsSettingsFaqFindSuccess]);





  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "QUESTION", field: "question" },
    { title: "STATUS", field: "status" },
  ];

  const [sortedFaq, setSortedFaq] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [secListopen, setSecListOpen] = useState(true);
  const [categoryList, setCategoyList] = useState([
    "Active",
    "Inactive",
  ]);

  // handleFilter function

  const handleFilterFunction = (filterValue, checkedStatus) => {
    setSelectedCategory(filterValue === selectedCategory ? null : filterValue);
    if (checkedStatus) {
      setSortedFaq(
        tableData?.filter((topics) => {
          return filterValue == topics.status;
        })
      );
    } else {
      setSortedFaq(tableData);
    }
  };

  // Handle ClearAll function

  const handleClearAllFunction = () => {
    setSortedFaq(tableData);
    setSelectedCategory(null);
  };

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };



  const [page, setPage] = useState(10);
  const [pageError, setPageError] = useState("");

  const handlePageSearch = (e) => {
    const { value } = e.target;
    if (value >= 0) {
      setPage(value);
    }
    if (value >= 10) {
      setPageError("");
    } else {
      setPageError("please choose a value greater than 10");
    }
  };


  return (
    <Box
      sx={{
        mt: 2,
        gap: "25px",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          lg: "330px 1fr",
        },
      }}
    >
      <Box
        sx={{
          " & .MuiListSubheader-root": {
            backgroundColor: "#F2F2F2",
          },
        }}
      >
        {selectedCategory && (
          <Chip
            sx={{
              mb: "20px",
              "&.MuiChip-root": {
                pl: "5px",
                pr: "5px",
              },
            }}
            label={
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "700",
                  color: "rgba(88, 94, 106, 1)",
                }}
              >
                Category:{" "}
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "400",
                  }}
                  component={"span"}
                >
                  {selectedCategory}
                </Typography>
              </Typography>
            }
            onDelete={handleClearAllFunction}
          />
        )}

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>Filter</div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    cursor: "pointer",
                  }}
                  //   onClick={handleClearClick}
                >
                  <MdClear style={{ fontSize: "22px", height: "20px" }} />{" "}
                  <Typography onClick={handleClearAllFunction}>
                    Clear All
                  </Typography>
                </div>
              </div>
            </ListSubheader>
          }
        >
          <Collapse
            in={secListopen}
            timeout="auto"
            unmountOnExit
            sx={{
              mt: "20px",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton>
                <FormGroup
                  sx={{
                    "& .MuiFormControlLabel-root": {
                      width: "100%",
                    },
                    width: "100%",
                  }}
                >
                  {categoryList &&
                    categoryList?.map((category, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            sx={{
                              color: "#F8469A",
                              "&.Mui-checked": {
                                color: "#F8469A",
                              },
                            }}
                            checked={category === selectedCategory}
                            onChange={(e) => {
                              handleFilterFunction(category, e.target.checked);
                            }}
                          />
                        }
                        label={category}
                      />
                    ))}
                </FormGroup>
              </ListItemButton>
            </List>
          </Collapse>

          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              mt: "20px",
              "& input": {
                width: "30px",
                outline: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
              }}
            >
              Rows Per Page :{" "}
            </Typography>
            <input type="number" value={page} onChange={handlePageSearch} />
          </Box>
          {pageError && (
            <Typography
              sx={{
                fontSize: 10,
                color: "red",
              }}
            >
              {pageError}
            </Typography>
          )}
        </List>
      </Box>
      <IeltsSettingsFaqTable
        data={sortedFaq}
        columns={columns}
        component="ieltsCategory"
        selectBoxIdPassHandler={selectBoxIdPassHandler}
        pageNumber={page}
      />
    </Box>
  );
}

export default IeltsFaqMain;
