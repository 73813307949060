import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUniversityCoverImageAction,
  deleteUniversityCoverImageAction,
  getUniversityCoverImageAction,
} from "../../../actions/settings/settingsActions";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function DefaultDocuments() {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();
  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  let { getUniversityCoverImageSuccess } = useSelector(
    (state) => state.getUniversityCoverImage
  );

  let { deleteUniversityCoverImageSuccess } = useSelector(
    (state) => state.deleteUniversityCoverImage
  );

  let { addUniversityCoverImageSuccess } = useSelector(
    (state) => state.addUniversityCoverImage
  );

  useEffect(() => {
    if (getUniversityCoverImageSuccess) {
      let url = getUniversityCoverImageSuccess;
      setFormData((prev) => ({
        ...prev,
        data: url,
      }));
    }
  }, [getUniversityCoverImageSuccess]);

  useEffect(() => {
    dispatch(getUniversityCoverImageAction());
  }, [deleteUniversityCoverImageSuccess, addUniversityCoverImageSuccess]);

  const [formData, setFormData] = useState();

  const handleFileInput = () => {
    if (isCreatePrevilage("default documents")) {
      inputRef.current.click();
    } else {
      setOpenAlert(true);
    }
  };

  const handleFileInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      coverImageField: e.target.files[0],
    }));
  };

  const submitCoverImage = () => {
    let data = new FormData();
    data.append("coverImageField", formData.coverImageField);
    data.append("type", "university cover image");
    dispatch(addUniversityCoverImageAction(data));
    setFormData((prev) => ({
      ...prev,
      coverImageField: false,
    }));
  };

  if (!hasPrivilege("default documents") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(
    hasPrivilege("default documents"),
    "hasPrivilege",
    privilege,
    "hasPrivilegehasPrivilegehasPrivilege"
  );

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(0, 0, 0, 0.87)",
            mt: "10px",
          }}
        >
          Cover Image Field :{" "}
        </Typography>
        <Box>
          <Box
            sx={{
              "& input::placeholder": {
                color: "#DADBE4",
              },
              "& input": {
                p: "10px 20px",
                outline: "none",
                border: "1px solid #DADBE4",
                boxSizing: "border-box",
                borderRadius: "10px",
                width: "100%",
              },
              "& label": {
                width: "fit-content",
              },
            }}
          >
            {formData?.coverImageField ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "200px",
                    height: "200px",
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      objectPosition: "left",
                    },
                  }}
                >
                  <img
                    src={
                      formData?.coverImageField &&
                      URL.createObjectURL(formData?.coverImageField)
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleFileInput}
                    sx={{
                      textTransform: "capitalize",
                      border: "1px solid #141E3C !important",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "12px",
                      minWidth: "fit-content",
                      backgroundColor: "#141E3C !important",
                    }}
                  >
                    change Image
                  </Button>
                  <Button
                    onClick={submitCoverImage}
                    sx={{
                      textTransform: "capitalize",
                      border: "1px solid #141E3C !important",
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "12px",
                      minWidth: "fit-content",
                      backgroundColor: "#141E3C !important",
                    }}
                  >
                    submit
                  </Button>
                </Box>
                <input
                  ref={inputRef}
                  type="file"
                  id="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  name="coverImageField"
                  onChange={handleFileInputChange}
                />
              </Box>
            ) : (
              <>
                <input
                  ref={inputRef}
                  type="file"
                  id="file"
                  hidden
                  accept="image/png, image/gif, image/jpeg"
                  name="coverImageField"
                  onChange={handleFileInputChange}
                />
                <Button
                  onClick={handleFileInput}
                  sx={{
                    textTransform: "capitalize",
                    border: "1px solid #141E3C !important",
                    color: "#fff",
                    fontWeight: "700",
                    backgroundColor: "#141E3C !important",
                  }}
                >
                  Add Image
                </Button>
              </>
            )}

            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "22px",
                color: "#595959",
                my: "5px",
              }}
            >
              Discription : This is the field to list a image as a cover image
              on university page.The current selection will list below.
            </Typography>
          </Box>

          {formData?.data[0]?.image && (
            <Box
              sx={
                {
                  // display: "flex",
                  // gap: "10px",
                  // width: "100%",
                }
              }
            >
              <Box
                sx={{
                  width: "200px",
                  height: "200px",
                  "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "left",
                  },
                }}
              >
                <img src={formData?.data[0]?.image} />
              </Box>
              <Typography
                sx={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#595959",
                  width: "100%",
                  maxWidth: "200px",
                  mt: "5px",
                  cursor: "pointer",
                }}
              >
                click{" "}
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "10px",
                    lineHeight: "12px",
                    color: "#595959",
                    textAlign: "right",
                    color: "red",
                    textDecoration: "underline",
                    width: "100%",
                    maxWidth: "200px",
                    mt: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (isDeletePrevilage("default documents")) {
                      dispatch(
                        deleteUniversityCoverImageAction(formData?.data[0]?.id)
                      );
                    } else {
                      setOpenAlert(true);
                    }
                  }}
                >
                  Delete
                </Typography>{" "}
                to delete the cover image
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </div>
  );
}

export default DefaultDocuments;
