import React, { forwardRef, useEffect, useState } from "react";

import MaterialTable from "@material-table/core";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { Box, Grid, Checkbox } from "@mui/material";

import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { MainContainer } from "../../content/content.element";
import { createRollAction } from "../../../actions/roleMangmentAction";
import { InfoHeader } from "../../customComponent/InfoHeader";
import { TypographyText1 } from "../../customComponent/Typography";
import InputField from "../../customComponent/InputField";
import { SubmitButton } from "../../customComponent/Buttons";
import { successTrueMsgAction } from "../../../actions/globalAction";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function CreateRoles(props) {
  const [errorDisplayed, setErrorDisplayed] = useState(false);
  const [successDisplayed, setSuccessDisplayed] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const { createRollLoading, createRollSuccess, createRollErr } = useSelector(
    (state) => {
      return state.createRoll;
    }
  );

  const { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (successAlertMsgSuccess) {
      setSuccessDisplayed(true);
      Swal.fire("Good job!", `${createRollSuccess}`, "success");
      dispatch(successTrueMsgAction(false));
      navigate("/Administration");
    }
    if (createRollErr && !errorDisplayed) {
      setErrorDisplayed(true);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `<p>${createRollErr}</p>`,
      });
    }
  }, [successAlertMsgSuccess, createRollErr, errorDisplayed]);

  const dispatch = useDispatch();

  const initialCheckboxState = {
    isLeadUpdate: false,
    isLeadView: false,
    isLeadDelete: false,
    isLeadAll: false,
    // isadministrationUpdate: false,
    // isadministrationView: false,
    // isadministrationDelete: false,
    // isadministrationAll: false,
    // isApplicationUpdate: false,
    // isApplicationView: false,
    // isApplicationDelete: false,
    // isApplicationAll: false,
    // isUniversityUpdate: false,
    // isUniversityView: false,
    // isUniversityDelete: false,
    // isUniversityAll: false,
    // isCourseUpdate: false,
    // isCourseView: false,
    // isCourseDelete: false,
    // isCourseAll: false,
    // isTicketUpdate: false,
    // isTicketView: false,
    // isTicketDelete: false,
    // isTicketAll: false,
    // isEnquiryUpdate: false,
    // isEnquiryView: false,
    // isEnquiryDelete: false,
    // isEnquiryAll: false,
    // isWebsiteUpdate: false,
    // isWebsiteView: false,
    // isWebsiteDelete: false,
    // isWebsiteAll: false,
    // isIeltsUpdate: false,
    // isIeltsView: false,
    // isIeltsDelete: false,
    // isIeltsAll: false,
    // isHRmanagmentUpdate: false,
    // isHRmanagmentView: false,
    // isHRmanagmentDelete: false,
    // isHRmanagmentAll: false,
    // isAccountsUpdate: false,
    // isAccountsView: false,
    // isAccountsDelete: false,
    // isAccountsAll: false,
    // isSettingsUpdate: false,
    // isSettingsView: false,
    // isSettingsDelete: false,
    // isSettingsAll: false,
  };

  const [checkboxState, setCheckboxState] = useState(initialCheckboxState);

  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");

  // const [isLeadUpdate, setIsLeadUpdate] = useState();
  // const [isLeadView, setIsLeadView] = useState();
  // const [isLeadDelete, setIsLeadDelete] = useState();
  // const [isLeadAll, setIsLeadAll] = useState();

  // const [isadministrationUpdate, setIsadministrationUpdate] = useState();
  // const [isadministrationView, setIsadministrationView] = useState();
  // const [isadministrationDelete, setIsadministrationDelete] = useState();
  // const [isadministrationAll, setIsadministrationAll] = useState();

  // const [isApplicationUpdate, setIsApplicationUpdate] = useState();
  // const [isApplicationView, setIsApplicationView] = useState();
  // const [isApplicationDelete, setIsApplicationDelete] = useState();
  // const [isApplicationAll, setIsApplicationAll] = useState();

  // const [isUniversityUpdate, setIsUniversityUpdate] = useState();
  // const [isUniversityView, setIsUniversityView] = useState();
  // const [isUniversityDelete, setIsUniversityDelete] = useState();
  // const [isUniversityAll, setIsUniversityAll] = useState();

  // const [isCoursUpdate, setIsCoursUpdate] = useState();
  // const [isCoursView, setIsCoursView] = useState();
  // const [isCoursDelete, setIsCoursDelete] = useState();
  // const [isCoursAll, setIsCoursAll] = useState();

  // const [isTicketUpdate, setIsTicketUpdate] = useState();
  // const [isTicketView, setIsTicketView] = useState();
  // const [isTicketDelete, setIsTicketDelete] = useState();
  // const [isTicketAll, setIsTicketAll] = useState();

  // const [isEnquiryUpdate, setIsEnquiryUpdate] = useState();
  // const [isEnquiryView, setIsEnquiryView] = useState();
  // const [isEnquiryDelete, setIsEnquiryDelete] = useState();
  // const [isEnquiryAll, setIsEnquiryAll] = useState();

  // const [isWebsitUpdate, setIsWebsitUpdate] = useState();
  // const [isWebsitView, setIsWebsitView] = useState();
  // const [isWebsitDelete, setIsWebsitDelete] = useState();
  // const [isWebsitAll, setIsWebsitAll] = useState();

  // const [isIeltsUpdate, setIsIeltsUpdate] = useState();
  // const [isIeltsView, setIsIeltsView] = useState();
  // const [isIeltsDelete, setIsIeltsDelete] = useState();
  // const [isIeltsAll, setIsIeltsAll] = useState();

  // const [isHRmanagmentUpdate, setIsHRmanagmentUpdate] = useState();
  // const [isHRmanagmentView, setIsHRmanagmentView] = useState();
  // const [isHRmanagmentDelete, setIsHRmanagmentDelete] = useState();
  // const [isHRmanagmentAll, setIsIsHRmanagmentAll] = useState();

  // const [isAccountsUpdate, setIsAccountsUpdate] = useState();
  // const [isAccountsView, setIsAccountsView] = useState();
  // const [isAccountsDelete, setIsAccountsDelete] = useState();
  // const [isAccountsAll, setIsAccountsAll] = useState();

  // const [isSettingsUpdate, setIsSettingsUpdate] = useState();
  // const [isSettingsView, setIsSettingsView] = useState();
  // const [isSettingsDelete, setIsSettingsDelete] = useState();
  // const [isSettingsAll, setIsSettingsAll] = useState();

  const handleCheckboxChange = (checkboxName, checked, all) => {
    if (all && all === "all") {
      setCheckboxState((prevState) => ({
        ...prevState,
        [`${checkboxName}all`]: checked,
        [`${checkboxName}Update`]: checked,
        [`${checkboxName}View`]: checked,
        [`${checkboxName}Delete`]: checked,
        [`${checkboxName}All`]: checked,
      }));
    } else {
      console.log(
        checkboxState.isLeadUpdate,
        checkboxState.isLeadView,
        checkboxState.isLeadDelete
      );

      setCheckboxState((prevState) => ({
        ...prevState,
        [`${checkboxName}${all}`]: checked,
      }));
    }
  };

  useEffect(() => {
    setCheckboxState((prevState) => ({
      ...prevState,
      isLeadAll:
        prevState.isLeadUpdate &&
        prevState.isLeadView &&
        prevState.isLeadDelete,
      // isadministrationAll:
      //   prevState.isadministrationUpdate &&
      //   prevState.isadministrationView &&
      //   prevState.isadministrationDelete,
      // isApplicationAll:
      //   prevState.isApplicationUpdate &&
      //   prevState.isApplicationView &&
      //   prevState.isApplicationDelete,
      // isUniversityAll:
      //   prevState.isUniversityUpdate &&
      //   prevState.isUniversityView &&
      //   prevState.isUniversityDelete,
      // isCourseAll:
      //   prevState.isCourseUpdate &&
      //   prevState.isCourseView &&
      //   prevState.isCourseDelete,
      // isTicketAll:
      //   prevState.isTicketUpdate &&
      //   prevState.isTicketView &&
      //   prevState.isTicketDelete,

      // isEnquiryAll:
      //   prevState.isEnquiryUpdate &&
      //   prevState.isEnquiryView &&
      //   prevState.isEnquiryDelete,

      // isWebsiteAll:
      //   prevState.isWebsiteUpdate &&
      //   prevState.isWebsiteView &&
      //   prevState.isWebsiteDelete,

      // isIeltsAll:
      //   prevState.isIeltsUpdate &&
      //   prevState.isIeltsView &&
      //   prevState.isIeltsDelete,

      // isHRmanagmentAll:
      //   prevState.isHRmanagmentUpdate &&
      //   prevState.isHRmanagmentView &&
      //   prevState.isHRmanagmentDelete,

      // isAccountsAll:
      //   prevState.isAccountsUpdate &&
      //   prevState.isAccountsView &&
      //   prevState.isAccountsDelete,

      // isSettingsAll:
      //   prevState.isSettingsUpdate &&
      //   prevState.isSettingsView &&
      //   prevState.isSettingsDelete,

      // Repeat the pattern for other checkboxes
    }));
  }, [
    checkboxState.isLeadUpdate,
    checkboxState.isLeadView,
    checkboxState.isLeadDelete,
    // checkboxState.isadministrationUpdate,
    // checkboxState.isadministrationView,
    // checkboxState.isadministrationDelete,
    // checkboxState.isApplicationUpdate,
    // checkboxState.isApplicationView,
    // checkboxState.isApplicationDelete,

    // checkboxState.isUniversityUpdate,
    // checkboxState.isUniversityView,
    // checkboxState.isUniversityDelete,

    // checkboxState.isCourseUpdate,
    // checkboxState.isCourseView,
    // checkboxState.isCourseDelete,

    // checkboxState.isTicketUpdate,
    // checkboxState.isTicketView,
    // checkboxState.isTicketDelete,

    // checkboxState.isEnquiryUpdate,
    // checkboxState.isEnquiryView,
    // checkboxState.isEnquiryDelete,

    // checkboxState.isWebsiteUpdate,
    // checkboxState.isWebsiteView,
    // checkboxState.isWebsiteDelete,

    // checkboxState.isIeltsUpdate,
    // checkboxState.isIeltsView,
    // checkboxState.isIeltsDelete,

    // checkboxState.isHRmanagmentUpdate,
    // checkboxState.isHRmanagmentView,
    // checkboxState.isHRmanagmentDelete,

    // checkboxState.isAccountsUpdate,
    // checkboxState.isAccountsView,
    // checkboxState.isAccountsDelete,

    // checkboxState.isSettingsUpdate,
    // checkboxState.isSettingsView,
    // checkboxState.isSettingsDelete,
  ]);

  // material Table
  let columns = [
    {
      title: "id",
      field: "id",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
      hidden: true,
    },
    {
      title: "SL NO",
      field: "sl",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Role Name",
      field: "name",
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Update",
      field: "update",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "View",
      field: "view",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Delete",
      field: "delete",
      filtering: false,
      //   hidden: true
      // lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "All",
      field: "all",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  let data = [
    {
      sl: 1,
      name: "Menus",
      update: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          onChange={(e) =>
            handleCheckboxChange("isLead", e.target.checked, "Update")
          }
          checked={checkboxState.isLeadUpdate}
        />
      ),
      view: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          onChange={(e) =>
            handleCheckboxChange("isLead", e.target.checked, "View")
          }
          checked={checkboxState.isLeadView}
        />
      ),
      delete: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          onChange={(e) =>
            handleCheckboxChange("isLead", e.target.checked, "Delete")
          }
          checked={checkboxState.isLeadDelete}
        />
      ),
      all: (
        <Checkbox
          sx={{
            color: "#f81b82",
            "&.Mui-checked": {
              color: "#f81b82",
            },
          }}
          onChange={(e) =>
            handleCheckboxChange("isLead", e.target.checked, "all")
          }
          checked={checkboxState.isLeadAll}
        />
      ),
    },
    // {
    //   sl: 2,
    //   name: "Administration",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isadministrationUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isadministration", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isadministrationView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isadministration", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isadministrationDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isadministration", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isadministrationAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isadministration", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },

    // {
    //   sl: 3,
    //   name: "Application",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isApplicationUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isApplication", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isApplicationView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isApplication", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isApplicationDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isApplication", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isApplicationAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isApplication", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
    // {
    //   sl: 4,
    //   name: "University",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isUniversityUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isUniversity", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isUniversityView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isUniversity", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isUniversityDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isUniversity", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isUniversityAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isUniversity", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
    // {
    //   sl: 5,
    //   name: "Course",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isCourseUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isCourse", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isCourseView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isCourse", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isCourseDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isCourse", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isCourseAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isCourse", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
    // {
    //   sl: 6,
    //   name: "ticket",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isTicketUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isTicket", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isTicketView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isTicket", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isTicketDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isTicket", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isTicketAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isTicket", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },

    // {
    //   sl: 7,
    //   name: "enquiry",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isEnquiryUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isEnquiry", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isEnquiryView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isEnquiry", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isEnquiryDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isEnquiry", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isEnquiryAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isEnquiry", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },

    // {
    //   sl: 8,
    //   name: "Website",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isWebsiteUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isWebsite", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isWebsiteView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isWebsite", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isWebsiteDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isWebsite", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isWebsiteAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isWebsite", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
    // {
    //   sl: 9,
    //   name: "IELTS",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isIeltsUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isIelts", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isIeltsView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isIelts", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isIeltsDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isIelts", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isIeltsAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isIelts", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
    // {
    //   sl: 10,
    //   name: "HR",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isHRmanagmentUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isHRmanagment", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isHRmanagmentView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isHRmanagment", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isHRmanagmentDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isHRmanagment", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isHRmanagmentAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isHRmanagment", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
    // {
    //   sl: 11,
    //   name: "Account",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isAccountsUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isAccounts", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isAccountsView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isAccounts", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isAccountsDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isAccounts", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isAccountsAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isAccounts", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },

    // {
    //   sl: 12,
    //   name: "Setting",
    //   update: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isSettingsUpdate}
    //       onChange={(e) =>
    //         handleCheckboxChange("isSettings", e.target.checked, "Update")
    //       }
    //     />
    //   ),
    //   view: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isSettingsView}
    //       onChange={(e) =>
    //         handleCheckboxChange("isSettings", e.target.checked, "View")
    //       }
    //     />
    //   ),
    //   delete: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isSettingsDelete}
    //       onChange={(e) =>
    //         handleCheckboxChange("isSettings", e.target.checked, "Delete")
    //       }
    //     />
    //   ),
    //   all: (
    //     <Checkbox
    //       sx={{
    //         color: "#f81b82",
    //         "&.Mui-checked": {
    //           color: "#f81b82",
    //         },
    //       }}
    //       checked={checkboxState.isSettingsAll}
    //       onChange={(e) =>
    //         handleCheckboxChange("isSettings", e.target.checked, "all")
    //       }
    //     />
    //   ),
    // },
  ];

  const validate = () => {
    const newErrors = {};
    if (roleName === "") {
      newErrors.roleName = "Role name is required";
    }
    if (roleDescription === "") {
      newErrors.roleDescription = "Role discription is required";
    }
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const privilageSubmit = (e) => {
    e.preventDefault();
    // if (roleName === "") {
    //   alert("Role Name Field is Required");
    // } else {
    //   dispatch(
    //     createRollAction(
    //       roleName,
    //       roleDescription,
    //       checkboxState.isLeadUpdate,
    //       checkboxState.isLeadView,
    //       checkboxState.isLeadDelete,
    //       checkboxState.isLeadAll
    //     )
    //   );

    //   setRoleName("");
    //   setRoleDescription("");
    //   setCheckboxState({});
    // }
    
      if (validate()) {
         dispatch(
           createRollAction(
             roleName,
             roleDescription,
             checkboxState.isLeadUpdate,
             checkboxState.isLeadView,
             checkboxState.isLeadDelete,
             checkboxState.isLeadAll
           )
         );
    
         setRoleName("");
         setRoleDescription("");
         setCheckboxState({});
      }
  };

  return (
    <MainContainer active={props.toggle}>
      <form onSubmit={privilageSubmit}>
        <InfoHeader headTitle="Create Role" url="/Administration" />

        <Grid container spacing={2} sx={{ mt: 2,"& input":{
          width:"calc(100% - 10px) !important",
        } }}>
          <Box
            sx={{
              width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <TypographyText1 variant="Title" title="Enter Role Details" />
          </Box>
          <Grid item xs={12} lg={5}>
            <InputField
              label="Role Name"
              handleChange={(e) => {
                setRoleName(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["roleName"]: "",
                }));
              }}
              // widthSize = "546px"
              value={roleName}
              error={Boolean(error?.roleName)}
              helperText={error?.roleName}
            />
          </Grid>
          <Grid item xs={12} lg={7}>
            <InputField
              label="Role Description"
              handleChange={(e) => {
                setRoleDescription(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["roleDescription"]: "",
                }));
              }}
              widthSize="fullWidth"
              value={roleDescription}
              error={Boolean(error?.roleDescription)}
              helperText={error?.roleDescription}
            />
          </Grid>
        </Grid>

        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            paddingBottom: "10px",
          }}
        >
          <SubmitButton
            title="save"
            submit="submit"
            widthSize="80px"
            heightSize="40px"
            type="submit"
            handleSubmit=""
          />
        </Box>
      </form>
    </MainContainer>
  );
}
export default CreateRoles;
