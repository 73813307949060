import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MainContainer } from "../content/content.element";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import EnquiryTable from "./EnquiryTable";
import { RiSearch2Line } from "react-icons/ri";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  enquirySearchFindAction,
  enquiryStudentDetailsFindAction
} from "../../actions/enquiryAction";
import { useNavigate } from "react-router-dom";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));
function EnquiryMain({ toggle }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(8);
  const [counsilorNote, setCounsilorNote] = useState({});
  const [teleCallerNote, setTeleCallerNote] = useState({});

  let { enquiryStudentDetailsSuccess, enquiryStudentDetailsLoading } =
    useSelector((state) => state.enquiryStudentDetails);
  let { enquirySearchSuccess } = useSelector((state) => state.enquirySearch);

  useEffect(() => {
    dispatch(enquirySearchFindAction());
  }, []);

  useEffect(() => {
    dispatch(enquiryStudentDetailsFindAction(paginationPageNumber));
  }, [dispatch, paginationPageNumber]);

  useEffect(() => {
    if (enquiryStudentDetailsSuccess) {
      setData(enquiryStudentDetailsSuccess?.data);

      const latestTeleCallerNote = enquiryStudentDetailsSuccess?.data?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_telecaller_notes &&
            current.lead_telecaller_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_telecaller_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_telecaller_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date

      const latestCounsilorNote = enquiryStudentDetailsSuccess?.data?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_counsilor_notes &&
            current.lead_counsilor_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_counsilor_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_counsilor_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date

      setCounsilorNote(latestCounsilorNote);
      setTeleCallerNote(latestTeleCallerNote);
    }
  }, [enquiryStudentDetailsSuccess, paginationPageNumber]);

  // pagination

  const handleChangePage = (event, newPage) => {
    setPaginationPageNumber(newPage);
  };

  const searchHandleChange = (e) => {
    let value = e.target.value;
    if (value) {
      let result = enquirySearchSuccess?.data?.filter(
        (item) =>
          item.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.lastName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.whatsappNumber?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.mobileNumber?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.Counselor?.firstName
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          item?.Telecaller?.firstName
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          item?.leadOwnerName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.livingCountry?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.lead_personal_details[0]?.passportNumber
            ?.toLowerCase()
            ?.includes(value?.toLowerCase())
      );
      setData(result);
      const latestTeleCallerNote = enquirySearchSuccess?.data?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_telecaller_notes &&
            current.lead_telecaller_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_telecaller_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_telecaller_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date

      const latestCounsilorNote = enquirySearchSuccess?.data?.reduce(
        (latest, current) => {
          // Check if the current object has telecaller notes
          if (
            current.lead_counsilor_notes &&
            current.lead_counsilor_notes.length > 0
          ) {
            // Find the latest note in the current object's telecaller notes
            const latestInCurrent = current.lead_counsilor_notes.reduce(
              (latestNote, currentNote) =>
                new Date(currentNote.createdAt) > new Date(latestNote.createdAt)
                  ? currentNote
                  : latestNote,
              current.lead_counsilor_notes[0]
            );

            // Compare with the overall latest note
            return new Date(latestInCurrent.createdAt) >
              new Date(latest.createdAt)
              ? latestInCurrent
              : latest;
          }

          return latest;
        },
        { createdAt: "1970-01-01T00:00:00.000Z" }
      ); // Start with a very old date

      setCounsilorNote(latestCounsilorNote);
      setTeleCallerNote(latestTeleCallerNote);
    } else {
      setData(enquiryStudentDetailsSuccess?.data);
    }
  };

  console.log(
    enquirySearchSuccess,
    "enquirySearchSuccessenquirySearchSuccessenquirySearchSuccess"
  );

  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name"
    },
    {
      title: "Email",
      field: "email"
    },
    {
      title: "Phone",
      field: "phone"
    },

    {
      title: "Whatsapp Number",
      field: "whatsappNumber"
    },
    {
      title: "Passport number",
      field: "passportNumber"
    },
    {
      title: "Country",
      field: "preferredCountry"
    },
    {
      title: "Lead Source",
      field: "leadSource"
    },
    {
      title: "Lead Owner",
      field: "leadOwner"
    },
    {
      title: "Counsilor",
      field: "counsilor"
    },
    {
      title: "Counsilor note",
      field: "counsilorNote"
    },
    {
      title: "TeleCaller",
      field: "TeleCaller"
    },
    {
      title: "Telecaller note",
      field: "telecallerNote"
    },
    {
      title: "Lead Branch",
      field: "leadBranch"
    },
    {
      title: "Status",
      field: "status"
    },
    // {
    //   title: "Application status",
    //   field: "applicationstatus",
    // },
    {
      title: "Action",
      field: "action"
    }
  ];

  console.log(
    enquiryStudentDetailsSuccess,
    "enquiryStudentDetailsSuccessenquiryStudentDetailsSuccess"
  );

  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px"
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer" }}
        />
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "22px",
            fontWeight: "700"
          }}
        >
          Enquiry
        </Typography>
      </Box>

      <Box
        sx={{
          mt: "30px"
        }}
      >
        <Box
          sx={{
            mt: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "end"
          }}
        >
          <div className={classes.searchContainer}>
            <TextField
              className={classes.searchInput}
              variant="outlined"
              placeholder="Search Here"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                searchHandleChange(e);
              }}
              sx={{
                "& .MuiInputBase-input": {
                  borderRadius: "20px",
                  height: "1px",
                  paddingRight: "48px !important"
                },
                "& .MuiFormLabel-root": {
                  lineHeight: "5px"
                },
                "& .MuiInputBase-root": {
                  borderRadius: "3px",
                  borderColor: "#fafafa"
                },
                "& .MuiOutlinedInput-notchedOutline ": {
                  borderRadius: "3px",
                  borderColor: "#ECECEC !important",
                  borderWidth: "1px !important"
                }
              }}
            />
            <>
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "8px",
                  width: "40px",
                  // top:"0",
                  // bottom:"0",
                  border: "1px solid #ECECEC",
                  borderRadius: "3px",
                  height: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <RiSearch2Line />
              </InputAdornment>
            </>
          </div>
        </Box>

        <Box>
          {data.length > 0 ? (
            <EnquiryTable
              columns={columns}
              data={data}
              paginationPageNumber={paginationPageNumber}
              pagiantionEntry={pagiantionEntry}
              handlePageNation={handleChangePage}
              totalEntry={enquiryStudentDetailsSuccess?.totalItems}
              teleCallerNote={teleCallerNote}
              counsilorNote={counsilorNote}
            />
          ) : (
            <TableNoItemComponent />
          )}
        </Box>
      </Box>
    </MainContainer>
  );
}

export default EnquiryMain;
