import { COUNSELOR_RATING_FIND_ERR, COUNSELOR_RATING_FIND_REQUEST, COUNSELOR_RATING_FIND_SUCCESS, SERVICE_RATING_FIND_ERR, SERVICE_RATING_FIND_REQUEST, SERVICE_RATING_FIND_SUCCESS } from "../constants/ratingConstant";

// counselor rating 
export const counselorRatingsFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNSELOR_RATING_FIND_REQUEST:
      return {
        ...state,
        counselorRatingFindLoading: true,
      };
    case COUNSELOR_RATING_FIND_SUCCESS:
      return {
        ...state,
        counselorRatingFindLoading: false,
        counselorRatingFindSuccess: action.payload,
      };
    case COUNSELOR_RATING_FIND_ERR:
      return {
        ...state,
        counselorRatingFindLoading: false,
        counselorRatingFindErr: action.payload,
      };
    default:
      return state;
  }
};

// service rating 
export const serviceRatingFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_RATING_FIND_REQUEST:
      return {
        ...state,
        serviceRatingFindLoading: true,
      };
    case SERVICE_RATING_FIND_SUCCESS:
      return {
        ...state,
        serviceRatingFindLoading: false,
        serviceRatingFindSuccess: action.payload,
      };
    case SERVICE_RATING_FIND_ERR:
      return {
        ...state,
        serviceRatingFindLoading: false,
        serviceRatingFindErr: action.payload,
      };
    default:
      return state;
  }
};
