
export const ROLE_BULKUPLOAD_REQUEST = "ROLE_BULKUPLOAD_REQUEST";
export const ROLE_BULKUPLOAD_SUCCESS = "ROLE_BULKUPLOAD_SUCCESS";
export const ROLE_BULKUPLOAD_ERR = "ROLE_BULKUPLOAD_ERR";

export const ROLE_CREATE_REQUEST = "ROLE_CREATE_REQUEST";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_ERR = "ROLE_CREATE_ERR";

export const ROLE_ALL_FIND_REQUEST = "ROLE_ALL_FIND_REQUEST";
export const ROLE_ALL_FIND_SUCCESS = "ROLE_ALL_FIND_SUCCESS";
export const ROLE_ALL_FIND_ERR = "ROLE_ALL_FIND_ERR";

export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_ERR = "ROLE_DELETE_ERR";

export const ROLE_EDIT_FIND_REQUEST = "ROLE_EDIT_FIND_REQUEST";
export const ROLE_EDIT_FIND_SUCCESS = "ROLE_EDIT_FIND_SUCCESS";
export const ROLE_EDIT_FIND_ERR = "ROLE_EDIT_FIND_ERR";

export const ROLE_EDIT_REQUEST = "ROLE_EDIT_REQUEST";
export const ROLE_EDIT_SUCCESS = "ROLE_EDIT_SUCCESS";
export const ROLE_EDIT_ERR = "ROLE_EDIT_ERR";

// ROLE MAIN MENU AND SUBMENU

export const GET_ALL_MAIN_AND_SUB_REQUEST = "GET_ALL_MAIN_AND_SUB_REQUEST";
export const GET_ALL_MAIN_AND_SUB_SUCCESS = "GET_ALL_MAIN_AND_SUB_SUCCESS";
export const GET_ALL_MAIN_AND_SUB_ERR = "GET_ALL_MAIN_AND_SUB_ERR";

export const CREATE_ROLE_SUB_MENU_PERMISSION_REQUEST =
  "CREATE_ROLE_SUB_MENU_PERMISSION_REQUEST";
export const CREATE_ROLE_SUB_MENU_PERMISSION_SUCCESS =
  "CREATE_ROLE_SUB_MENU_PERMISSION_SUCCESS";
export const CREATE_ROLE_SUB_MENU_PERMISSION_ERR =
  "CREATE_ROLE_SUB_MENU_PERMISSION_ERR";

export const FIND_ROLE_SUB_MENU_PERMISSION_REQUEST =
  "FIND_ROLE_SUB_MENU_PERMISSION_REQUEST";
export const FIND_ROLE_SUB_MENU_PERMISSION_SUCCESS =
  "FIND_ROLE_SUB_MENU_PERMISSION_SUCCESS";
export const FIND_ROLE_SUB_MENU_PERMISSION_ERR =
  "FIND_ROLE_SUB_MENU_PERMISSION_ERR";

export const CREATE_PRIVILAGE_REQUEST = "CREATE_PRIVILAGE_REQUEST";
export const CREATE_PRIVILAGE_SUCCESS = "CREATE_PRIVILAGE_SUCCESS";
export const CREATE_PRIVILAGE_ERR = "CREATE_PRIVILAGE_ERR";

export const FIND_PRIVILAGE_REQUEST = "FIND_PRIVILAGE_REQUEST";
export const FIND_PRIVILAGE_SUCCESS = "FIND_PRIVILAGE_SUCCESS";
export const FIND_PRIVILAGE_ERR = "FIND_PRIVILAGE_ERR";


export const CREATE_SELECTALL_PREVILAGE_REQUEST = "CREATE_SELECTALL_PREVILAGE_REQUEST";
export const CREATE_SELECTALL_PREVILAGE_SUCCESS = "CREATE_SELECTALL_PREVILAGE_SUCCESS";
export const CREATE_SELECTALL_PREVILAGE_ERR = "CREATE_SELECTALL_PREVILAGE_ERR";


export const CREATE_SELECTALL_PERMISSION_REQUEST = "CREATE_SELECTALL_PERMISSION_REQUEST";
export const CREATE_SELECTALL_PERMISSION_SUCCESS = "CREATE_SELECTALL_PERMISSION_SUCCESS";
export const CREATE_SELECTALL_PERMISSION_ERR = "CREATE_SELECTALL_PERMISSION_ERR";



// sucess noted

export const SUCCESS_TRUE_MSG_REQUEST = "SUCCESS_TRUE_MSG_REQUEST";
export const SUCCESS_TRUE_MSG_SUCCESS = "SUCCESS_TRUE_MSG_SUCCESS";
export const SUCCESS_TRUE_MSG_ERR = "SUCCESS_TRUE_MSG_ERR";
