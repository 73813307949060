import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { chatWithCounsilorStatusUpdateAction } from "../../actions/leadChatAction";
import { useDispatch } from "react-redux";

function ChatMessage({ name, image, time, status, senderId, messageId }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleClick = (messageId) => {
    dispatch(chatWithCounsilorStatusUpdateAction(messageId));
    navigate(`/Leads/details/chat/${senderId}`);
  };

  const getTime = (time) => {
    const dateTime = new Date(time);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const displayHours = (hours % 12 || 12).toString().padStart(2, "0");
    const displayMinutes = minutes.toString().padStart(2, "0");

    return `${displayHours}:${displayMinutes} ${amOrPm}`;
  };

  const getDaysCount = (data) => {
    const currentDate = new Date();
    const inputDate = new Date(data);
    if (
      inputDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
      inputDate.getUTCMonth() === currentDate.getUTCMonth() &&
      inputDate.getUTCDate() === currentDate.getUTCDate()
    ) {
      return "today";
    }
    const timeDifference = currentDate - inputDate;
    const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference + " day ago";
  };

  return (
    <Box
      sx={{
        filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
        padding: "11px 12px",
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "8px",
        cursor: "pointer",
      }}
      onClick={() => handleClick(messageId)}
    >
      <Box
        sx={{
          gap: "20px",
          display: "grid",
          gridTemplateColumns: "41px 1fr",
        }}
      >
        <Box
          sx={{
            width: "41px",
            height: "41px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
            "& img": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          }}
        >
          <img src={image ? image : "/avatar.png"} />
        </Box>
        <Box
          sx={{
            maxWidth: "calc(100% - 41px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "600",
                color: "#141E3C",
              }}
            >
              {name}
            </Typography>
            <Box
              sx={{
                width: "9px",
                height: "9px",
                background: status
                  ? "rgba(218, 219, 228, 1)"
                  : "rgba(255, 27, 111, 1)",
                borderRadius: "50%",
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                lineHeight: "24px",
                color: "#777AAE",
              }}
            >
              {getTime(time)}
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                lineHeight: "24px",
                color: "#777AAE",
              }}
            >
              {getDaysCount(time)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChatMessage;
