import { Box, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

function ApplicationDetails() {
  const Data = [
    {
      name: "Applications awaiting decisions",
      count: "334",
    },
    {
      name: "Visa Received",
      count: "334",
    },
    {
      name: "Conditional offers",
      count: "229",
    },
    {
      name: "Visa Awaited",
      count: "334",
    },
    {
      name: "Unconditional offers",
      count: "48",
    },
    {
      name: "Deferral",
      count: "13",
    },
    {
      name: "Students Enrolled",
      count: "95",
    },
    {
      name: "Applications rejected",
      count: "334",
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#000",
            lineHeight: "24px",
          }}
        >
          Application Details
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            "& .MuiOutlinedInput-notchedOutline": {
              outline: "none !important",
              borderColor: "rgba(236, 236, 236, 1) !important",
            },
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={10}
            sx={{
              borderRadius: "30px",
              "& .MuiOutlinedInput-input": {
                p: "5px",
                pl: "15px",
                pr: "45px !important",
              },
            }}
          >
            <MenuItem value={10}>2023</MenuItem>
          </Select>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={10}
            sx={{
              borderRadius: "30px",
              "& .MuiOutlinedInput-input": {
                p: "5px",
                pl: "15px",
                pr: "45px !important",
              },
            }}
          >
            <MenuItem value={10}>UK</MenuItem>
          </Select>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={10}
            sx={{
              borderRadius: "30px",
              "& .MuiOutlinedInput-input": {
                p: "5px",
                pl: "15px",
                pr: "45px !important",
              },
            }}
          >
            <MenuItem value={10}>All Intake</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "30px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "32px",
          rowGap: "16px",
          maxWidth: "680px",
        }}
      >
        {Data.map((item, i) => (
          <Box
            key={i}
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#ACB1C6",
                lineHeight: "24px",
              }}
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#141E3C",
                lineHeight: "24px",
              }}
            >
              {item.count}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ApplicationDetails;
