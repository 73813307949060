import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { referralsStatusChangeAction } from "../../../actions/referAndEarnAction";

function ReferalStatusChange({ open, Close, selectedvalue, id }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    status: selectedvalue ? selectedvalue : "",
    note: "",
    id: id ? id : "",
  });

  const handleClose = () => {
    Close();
    setFormData({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  console.log(formData);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(referralsStatusChangeAction(formData));
    Close();
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            margin: "0",
            width: "calc(100% - 30px)",
            maxWidth: "850px",
            borderRadius: "4px",
          },
        }}
        PaperProps={{
          style: {
            alignSelf: "flex-start",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "22px",
                color: "#05050F",
              }}
            >
              Change Status
            </Typography>
            <CloseIcon
              sx={{
                fontSize: "30px",
                color: "#000",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent
            sx={{
              "& input": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                borderRadius: "4px",
              },
              "& input::placeholder": {
                color: "#ACB1C6",
              },
              "& textarea": {
                border: "1px solid #ECECEC !important",
                outline: "none",
                padding: "16px",
                width: "100%",
                boxSizing: "border-box",
                resize: "none",
                borderRadius: "4px",
                // mt: "24px",
              },
              "& textarea::placeholder": {
                color: "#ACB1C6",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                mt: "13px",
              }}
            >
              <select
                style={{
                  fontFamily: "inherit",
                  fontSize: "16px",
                  padding: "8px",
                  border: "1px solid #ECECEC",
                  background: "white",
                  borderRadius: "4px",
                  width: "100%",
                  height: "56px",
                  boxSizing: "border-box",
                  resize: "none",
                }}
                placeholder="Status"
                onChange={handleChange}
                name="status"
                value={formData?.status}
              >
                <option value="Contacted">Contacted</option>
                <option value="Pending">Pending</option>
                <option value="Closed">Closed</option>
              </select>

              <textarea
                placeholder="Notes"
                name={"note"}
                value={formData?.note}
                onChange={handleChange}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              mb: "20px",
              mr: "15px",
            }}
          >
            <Button
              type={"text"}
              sx={{
                padding: "16px 24px",
                color: "white",
                backgroundColor: "rgba(20, 30, 60, 1)",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "rgba(20, 30, 60, 0.9)",
                },
              }}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ReferalStatusChange;
