import React, { useEffect, useState } from "react";
import PartnerTable from "./PartnerTable/PartnerTable";
import { useDispatch, useSelector } from "react-redux";
import { findAllPartnerAction } from "../../actions/partnerAction/partnerAction";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { Alert, AlertTitle } from "@mui/material";

// material Table
const columns = [
  {
    title: "Sl no",
    field: "sl",
  },
  {
    title: "Partner Name",
    field: "partnerName",
    hidden: false,
  },
  {
    title: "Email",
    field: "email",
    hidden: false,
  },
  {
    title: "Phone",
    field: "phone",
    hidden: false,
  },
  {
    title: "Manager",
    field: "manager",
    hidden: false,
  },
  {
    title: "",
    field: "action",
    filtering: false,
  },
];

function PartnerKycApproval() {
  const dispatch = useDispatch();

  const [partnerdata, setPartnerData] = useState([]);

  const { privilege, hasPrivilege } = usePrivilegeCheck();


  let { partnerFindSuccess } = useSelector((state) => {
    return state.findAllPartner;
  });

  useEffect(() => {
    if (partnerFindSuccess) {
      setPartnerData(
        partnerFindSuccess
          ?.filter((data) => data.KYCverification !== true)
          .map((item) => ({
            id: item.id,
            column1: item.name,
            column2: item.email,
            column3: item.phone,
            column4: item.user?.firstName,
          }))
      );
    }
  }, [partnerFindSuccess]);

  useEffect(() => {
    dispatch(findAllPartnerAction());
  }, [dispatch]);


  if (!hasPrivilege("Partner KYC approvel") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }


  console.log(partnerdata,"partnerdatapartnerdatapartnerdata")

  return (
    <>
      <PartnerTable
        columns={columns}
        data={partnerdata}
        component="partnerKyc"
        subMenu="Partner KYC approvel"
      />
    </>
  );
}

export default PartnerKycApproval;
