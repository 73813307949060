import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { kycDetailsAddAction } from "../../redux/action/AuthAction";
import { useDispatch } from "react-redux";
import InputField, {
  SelectinputFieldCustom,
} from "../../customComponent/InputField";
import { kycDetailsEditAction } from "../../../actions/partnerAction/partnerAction";
import { useParams } from "react-router-dom";

function BankAndKYCDetailsForm({ pageNumber, partnerData }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [storedAddressData, setStoredAddressData] = useState();
  const [companyInformationData, setCompanyInformationData] = useState();
  const [formData, setFormData] = useState({
    id: partnerData?.partnerId ? partnerData?.partnerId : id,
    accountholder: partnerData?.accountholder,
    accountNumber: partnerData?.accountNumber,
    bankCode: partnerData?.bankCode,
    bankName: partnerData?.bankName,
    PANNumber: partnerData?.PANNumber,
    branchName: partnerData?.branchName,
    employeesCount: partnerData?.employeesCount,
    section: "bankKyc",
  });

  // useEffect(() => {
  //   const storedCompanyData = localStorage.getItem("companyInformationData");
  //   const storedAddressData = localStorage.getItem("AddressData");
  //   if (storedCompanyData) {
  //     setCompanyInformationData(JSON.parse(storedCompanyData));
  //   }
  //   if (storedAddressData) {
  //     setStoredAddressData(JSON.parse(storedAddressData));
  //   }
  // }, []);

  useEffect(() => {
    if (partnerData) {
      setFormData({
        ...formData,
        id: partnerData?.partnerId,
        accountholder: partnerData?.accountholder,
        accountNumber: partnerData?.accountNumber,
        bankCode: partnerData?.bankCode,
        bankName: partnerData?.bankName,
        PANNumber: partnerData?.PANNumber,
        branchName: partnerData?.branchName,
        employeesCount: partnerData?.employeesCount,
        section: "bankKyc",
      });
    }
  }, [partnerData]);

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    // if (!formData.accountholder) {
    //   errors.accountholder = "Account holder name is required";
    // }

    // if (!formData.accountNumber) {
    //   errors.accountNumber = "AccountNumber is required";
    // }

    // if (!formData.bankCode) {
    //   errors.bankCode = "Bank code is required";
    // }

    // if (!formData.bankName) {
    //   errors.bankName = "Bank name is required";
    // }

    // if (!formData.PANNumber) {
    //   errors.PANNumber = "Pan number is required";
    // }

    // if (!formData.branchName) {
    //   errors.branchName = "Branch name is required";
    // }

    // if (!formData.employeesCount) {
    //   errors.employeesCount = "Employees count is required";
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      // alert("form submitted successfully");
      //   console.log("Form submitted:", formData);
      //   localStorage.setItem("BankAndKycDetails", JSON.stringify(formData));
      // console.log(companyInformationData, "step 1");
      // console.log(storedAddressData, "step 2");
      console.log(formData, "step 3");
      // dispatch(kycDetailsAddAction(formData));
      let formDetail = new FormData();
      formDetail.append("formDetail", JSON.stringify(formData));

      dispatch(kycDetailsEditAction(formData.id, formDetail));
      pageNumber(1);
    } else {
      alert("please fill the fields");
      console.log("Form validation failed");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };
  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "32px",
          rowGap: "24px",
          mt: "50px",
          "& input":{
            maxWidth:"calc(100% - 10px)",
          }
        }}
      >
        <InputField
          error={formErrors.accountholder}
          // required
          handleChange={handleChange}
          name={"accountholder"}
          label={"Account Holder Name"}
          color="#05050F"
          value={formData.accountholder}
        />

        <InputField
          error={formErrors.accountNumber}
          handleChange={handleChange}
          name="accountNumber"
          label={"Account Number"}
          color="#05050F"
          value={formData.accountNumber}
        />

        <InputField
          error={formErrors.bankCode}
          name="bankCode"
          label={"SWIFT/IFC Number/IBAN/Sort Code"}
          color="#05050F"
          handleChange={handleChange}
          value={formData.bankCode}
        />

        <InputField
          error={formErrors.bankName}
          label={"Bank Name"}
          name="bankName"
          color="#05050F"
          handleChange={handleChange}
          value={formData.bankName}
        />

        <InputField
          error={formErrors.PANNumber}
          handleChange={handleChange}
          name={"PANNumber"}
          label={"PAN Card Number"}
          color="#05050F"
          value={formData.PANNumber}
        />

        <InputField
          error={formErrors.branchName}
          label={"Branch Name"}
          name="branchName"
          color="#05050F"
          handleChange={handleChange}
          shrink
          value={formData.branchName}
        />

        <InputField
          error={formErrors.employeesCount}
          name="employeesCount"
          label={"Number of Employees"}
          value={formData.employeesCount}
          color="#05050F"
          handleChange={handleChange}
          shrink
        />

        {/* <SelectinputFieldCustom
          handleChange={handleChange}
          name="employeesCount"
          label={"Number of Employees"}
          value={formData.employeesCount}
          color="#05050F"
          array={[
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
          ]}
        /> */}
      </Box>
      <Box
        sx={{
          textAlign: "right",
        }}
      >
        <Button
          type="subbmit"
          sx={{
            padding: "16px 24px",
            borderRadius: "8px",
            fontWeight: "700",
            textTransform: "capitalize",
            fontFamily: "Open Sans  !important",
            border: "1px solid #141E3C !important",
            background: "#141E3C !important",
            color: "#fff !important",
            mt: "82px",
          }}
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
}

export default BankAndKYCDetailsForm;
