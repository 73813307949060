import { Box, Button, Chip, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import CompanyInformationForm from "./CompanyInformationForm";
import PartnerKycHead from "./PartnerKycHead.js";
import { successTrueMsgAction } from "../../../actions/globalAction.js";
import {
  partnerKycApprovalAction,
  partnerKycDetailFindAction,
} from "../../../actions/partnerAction/partnerAction.js";
import { MainContainer } from "../../content/content.element.js";
import AddressForm from "./AddressForm.js";
import BankAndKYCDetailsForm from "./BankAndKYCDetailsForm.js";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck.js";
import AccessDeniedIcon from "../../Leads/createLeads/AccessDeniedIcon.jsx";
import { Close } from "@mui/icons-material";

function PartnerKycMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    privilege,
    hasPrivilege,
    isCreateNewPartnerPrevilage,
    isChangeLeaderPrevilage,
    isApprovePrevilage,
    isUpdatePrevilage,
    isDeletePrevilage,
    isCommisionCategoryChangePrevilage,
  } = usePrivilegeCheck();

  const [partnerData, setPartnerData] = useState();
  const [open, setOpenAlert] = useState(false);

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let { partnerDetailFindSuccess } = useSelector((state) => {
    return state.partnerKycDetailFind;
  });

  let { partnerKycApprovalSuccess } = useSelector((state) => {
    return state.partnerKycApproval;
  });

  let { partnerKycEditSuccess } = useSelector((state) => {
    return state.kycDetailsEdit;
  });

  useEffect(() => {
    dispatch(partnerKycDetailFindAction(id));
  }, [id, partnerKycEditSuccess, partnerKycApprovalSuccess]);

  useEffect(() => {
    if (partnerDetailFindSuccess) {
      console.log(
        partnerDetailFindSuccess,
        "partnerDetailFindSuccesspartnerDetailFindSuccesspartnerDetailFindSuccess"
      );
      setPartnerData(partnerDetailFindSuccess);
    }
  }, [
    partnerDetailFindSuccess,
    partnerKycApprovalSuccess,
    partnerKycEditSuccess,
  ]);

  // useEffect(() => {
  //   if (
  //     partnerData &&
  //     !partnerData?.KYCverification &&
  //     partnerData?.partnerAddress &&
  //     partnerData?.partnerBankKyc
  //   ) {
  //     setFillDetails(true);
  //   }
  // }, [partnerData]);

  useEffect(() => {
    if (successAlertMsgSuccess && partnerKycEditSuccess) {
      Swal.fire("Updated!", "Partner KYC has been updated.", "success");

      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess]);

  useEffect(() => {
    if (successAlertMsgSuccess && partnerKycApprovalSuccess) {
      Swal.fire("Approved!", "Partner KYC has been Approved.", "success");

      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess]);

  const [formPageCount, setFormPageCount] = useState(1);

  const kycApprovalHandle = () => {
    if (partnerData?.partnerAddress && partnerData?.partnerBankKyc) {
      dispatch(partnerKycApprovalAction(id));
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Fill All Required Fields",
      });
    }
  };

  return (
    <>
      <MainContainer active={props.toggle}>
        <Box
          sx={{
            backgroundColor: "#fff",
            // padding: "0 15px",
            // width: "fit-content",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              width: "auto",
              padding: {
                xs: "40px 0px",
                // lg: "40px",
              },
              maxWidth: "auto",
              margin: "auto",
            }}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "40px",
                    lineHeight: "24px",
                    color: "#000",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Partner KYC verification
                </Typography>
                <Box
                  sx={{
                    width: "auto",
                  }}
                >
                  {/* <img src="/images/gsl.png" alt="" /> */}

                  {partnerData?.KYCverification ? (
                    <Chip
                      style={{
                        backgroundColor: "green",
                        color: "white",
                      }}
                      label="Approved"
                      component="a"
                      clickable
                      sx={{
                        m: 1,
                        padding: "10px 19px",
                        width: "auto",
                        fontSize: "8px !important",
                      }}
                    />
                  ) : // <Button
                  //   sx={{ color: "white", backgroundColor: "#abf7b1" }}
                  //   onClick={kycApprovalHandle}
                  // >
                  //   Approve
                  // </Button>
                  isApprovePrevilage("Partner KYC approvel") ? (
                    <Button
                      onClick={kycApprovalHandle}
                      sx={{
                        padding: "10px 19px",
                        borderRadius: "8px",
                        fontWeight: "700",
                        textTransform: "capitalize",
                        fontFamily: "Open Sans  !important",
                        border: "1px solid #141E3C !important",
                        background: "#141E3C !important",
                        color: "#fff !important",
                      }}
                    >
                      Approve
                    </Button>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  mt: "73px",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridGap: "20px",
                }}
              >
                <PartnerKycHead
                  active={formPageCount == 1}
                  pageNumber={setFormPageCount}
                  order={1}
                  title={"Company Information"}
                />
                <PartnerKycHead
                  active={formPageCount == 2}
                  pageNumber={setFormPageCount}
                  order={2}
                  title={"Address"}
                />
                <PartnerKycHead
                  active={formPageCount == 3}
                  pageNumber={setFormPageCount}
                  order={3}
                  title={"Bank & KYC Information"}
                />
              </Box>

              <Box sx={{ position: "relative" }}>
                {!isUpdatePrevilage("Partner KYC approvel") && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: "0",
                      top: "0",
                      left: "0",
                      bottom: "0",
                      // background:"red",
                      zIndex: "9998",
                    }}
                    onClick={() => setOpenAlert(true)}
                  ></Box>
                )}
                {formPageCount == 1 && (
                  <CompanyInformationForm
                    pageNumber={setFormPageCount}
                    partnerData={partnerData}
                  />
                )}
                {formPageCount == 2 && (
                  <AddressForm
                    pageNumber={setFormPageCount}
                    partnerData={partnerData && partnerData?.partnerAddress}
                  />
                )}
                {formPageCount == 3 && (
                  <BankAndKYCDetailsForm
                    pageNumber={setFormPageCount}
                    partnerData={partnerData && partnerData?.partnerBankKyc}
                  />
                )}{" "}
              </Box>
            </>
          </Box>
        </Box>
      </MainContainer>

      <Dialog
        open={open}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
}

export default PartnerKycMain;
