import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { MasterTable } from "../MasterTable";
import TableNoItemComponent from "../../../customComponent/TableNoItemComponent";
import { GetCountries } from "react-country-state-city";
import { CurrencyFindAction } from "../../../../actions/countryMasterAction";
import { useDispatch, useSelector } from "react-redux";

const ITEM_HEIGHT = 48;

function CurrencyMaster({ searchHandle }) {
  const dispatch = useDispatch();

  const [symbolArray, setSymbolArray] = useState([]);

  let { countryCurrencyFindSuccess, countryCurrencyFindErr } = useSelector(
    (state) => {
      return state.countryCurrencyFind;
    }
  );

  useEffect(() => {
    if (searchHandle !== "" && searchHandle !== " ") {
      console.log(searchHandle, "searchHandlesearchHandle");
      setSymbolArray(
        countryCurrencyFindSuccess?.filter((item) => {
          const CountryName = item.countryName
            ? item.countryName.split("(")[0].toLowerCase()
            : "";

          return CountryName.includes(searchHandle.toLowerCase());
        })
      );
    } else {
      if (countryCurrencyFindSuccess) {
        let reversedData = countryCurrencyFindSuccess?.reverse();
        setSymbolArray(reversedData);
      }
    }
  }, [searchHandle]);

  useEffect(() => {
    dispatch(CurrencyFindAction());
  }, []);

  useEffect(() => {
    setSymbolArray(countryCurrencyFindSuccess);
  }, [countryCurrencyFindSuccess]);

  console.log(symbolArray, "symbolArraysymbolArraysymbolArray");

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Country Name",
      field: "Country Name",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Currency",
      field: "Currency",
      //   hidden: showEmailColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "CountryId",
      field: "CountryId",
      //   hidden: showMobileColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const data = symbolArray?.map((data, index) => {
    const code = Math.floor(100 + Math.random() * 900);
    return {
      sl: index + 1,
      column1: data.countryName,
      column2: data.currency,
      column3: data.currencyId,
    };
  });

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <>
          {data?.length > 0 ? (
            <MasterTable
              data={data}
              columns={columns}
              component="currency"
              // rollEditHandler={rollEditHandler}
            />
          ) : (
            <TableNoItemComponent />
          )}
        </>
      </Box>
    </>
  );
}

export default CurrencyMaster;
