import axios from "axios";
import {
  IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_CREATE_CHECK_ERROR_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_CREATING_ERR,
  IELTS_COURSEMANAGMENT_COURSE_CREATING_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_CREATING_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_EDITFIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_EDITFIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_EDITFIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_EDIT_ERR,
  IELTS_COURSEMANAGMENT_COURSE_EDIT_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_EDIT_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_COURSE_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_SLOT_ERR,
  IELTS_COURSEMANAGMENT_COURSE_SLOT_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_SLOT_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_ERR,
  IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_REQUEST,
  IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_SUCCESS,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_ERR,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_REQUEST,
  IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_SUCCESS,
} from "../../constants/Ielts/ieltsCoursemanagmentConstnts";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

// des:CourseManagment examTips create Action

export const ieltsCourseManagmentExamtipsCreateAction = (formData) => async (
  dispatch
) => {
  try {
    // alert("llllll");
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/createExamtips",
      formData,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment examTips find Action

export const ieltsCourseManagmentExamtipsFindAction = () => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/courseManagment/findExamtips",
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment examTips findById Action

export const ieltsCourseManagmentExamtipsFindbyIdAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/findByIdExamtips?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_FINDBYID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment examTips update Action

export const ieltsCourseManagmentExamtipUpdateAction = (formData) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/examtipsUpdate",
      formData,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_UPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment examTips statusChange Action

export const ieltsCourseManagmentExamTipsStatusChangeAction = (
  status,
  id
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/examTipStatusChange",
      {
        status,
        id,
      },
      config
    );
    console.log(data, "datatatata");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment examTips delete Action

export const ieltsCourseManagmentExamTipsDeleteAction = (id) => async (
  dispatch
) => {
  try {
    // alert(id);
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/courseManagment/examtipsDelete?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPS_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment examTipsPdf delete Action

export const ieltsCourseManagmentExamTipsPdfDeleteAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/courseManagment/examTipPdfDelete?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_EXAMTIPSPDF_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// QUESTION BANK ACTIONS ///////////////////////////////////////////////////////////////

// des:CourseManagment questionBank create Action

export const ieltsCourseManagmentQuestionBankCreateAction = (
  formData
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/createQuestionBank",
      formData,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment questionBank find Action

export const ieltsCourseManagmentQuestionBankFindAction = () => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/courseManagment/findQuestionBank",
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment questionBank findById Action

export const ieltsCourseManagmentQuestionBankFindbyIdAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/findByIdQuestionBank?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_FINDBYID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment questionBank update Action

export const ieltsCourseManagmentQuestionBankUpdateAction = (
  formData
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/QuestionBankUpdate",
      formData,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_UPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment questionBank statusChange Action

export const ieltsCourseManagmentQuestionBankStatusChangeAction = (
  status,
  id
) => async (dispatch) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/questionBankStatusChange",
      {
        status,
        id,
      },
      config
    );
    console.log(data, "datatatata");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment questionBank delete Action

export const ieltsCourseManagmentQuestionBankDeleteAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/courseManagment/questionbankDelete?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANK_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// des:CourseManagment questionBankPdf delete Action

export const ieltsCourseManagmentQuestionBankPdfDeleteAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/courseManagment/questionBankPdfDelete?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_QUESTIONBANKPDF_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

//  COURSE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// CourseManagment Course Creation Action

export const ieltsCourseManagmentCreateCourseAction = (data) => async (
  dispatch
) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
  }
};

export const ieltsCourseManagmentCreateCourseErrorAction = (data) => async (
  dispatch
) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CREATE_CHECK_ERROR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
  }
};

export const ieltsCourseManagmentCreateCourseNullfyAction = (data) => async (
  dispatch
) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
  }
};

// CourseManagment Course Topic find Action

export const ieltsCourseManagmentCourseTopicFindAction = (
  categoryType
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/courseTopicFind?categoryType=${categoryType}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_TOPICFIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course Creating Action

export const ieltsCourseManagmentCourseCreatingAction = (formDatas) => async (
  dispatch
) => {
  try {
    // alert("jkdj");
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_CREATING_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/CreateCourse",
      formDatas,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CREATING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CREATING_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course Find Action

export const ieltsCourseManagmentCourseFindAction = () => async (dispatch) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/courseManagment/findCourse",
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course StatusChange Action

export const ieltsCourseManagmentCourseStatusChangeAction = (
  status,
  id
) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/courseStatusChange",
      {
        status,
        id,
      },
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course FindById Action

export const ieltsCourseManagmentCourseFindByIdAction = (id, type) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_FINDBYID_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/findCourseById?id=${id}&&type=${type}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_FINDBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_FINDBYID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course Slot Action

export const ieltsCourseManagmentCourseSlotAction = (id) => async (
  dispatch
) => {
  try {
    // alert(id);
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_SLOT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/courseSlotFind?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_SLOT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_SLOT_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course Center Action

export const ieltsCourseManagmentCourseCenterAction = () => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/courseManagment/courseCenter",
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_CENTER_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// courseManagment course edit find action

export const ieltsCourseManagmentCourseEditFindAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_EDITFIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;
    
    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/editCourseFind?courseid=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_EDITFIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_EDITFIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// courseManagment course edit action

export const ieltsCourseManagmentCourseEditAction = (formData) => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_EDIT_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/editCourse",
      formData,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_EDIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_EDIT_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course LiveClass create Action

export const ieltsCourseManagmentCourseLiveClassCreateAction = (
  formData
) => async (dispatch) => {
  try {
    // alert("uiuihihih");
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/liveClassCreate",
      formData,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_CREATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course LiveClass find Action

export const ieltsCourseManagmentCourseLiveClassFindAction = () => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      "/api/admin/ielts/courseManagment/liveClassFind",
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course LiveClass findById Action

export const ieltsCourseManagmentCourseLiveClassFindByIdAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/courseManagment/liveClassFindById?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FINDBYID_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course LiveClass statusChange Action

export const ieltsCourseManagmentCourseLiveClassStatusChangeAction = (
  status,
  id
) => async (dispatch) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.put(
      `/api/admin/ielts/courseManagment/liveClassStatusChange?id=${id}&&status=${status}`,
      config
    );

    console.log(data, "dataaaaaaaaaaaaaaaaaaaaaa");

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course LiveClass Update Action

export const ieltsCourseManagmentCourseLiveClassUpdateAction = (
  formData
) => async (dispatch) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    let { data } = await axios.post(
      "/api/admin/ielts/courseManagment/liveClassUpdate",
      formData,
      config
    );

    console.log(data, "dataaaaaaaaaaaaaaaaaaaaaa");

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_UPDATE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// CourseManagment Course LiveClass Delete Action

export const ieltsCourseManagmentCourseLiveClassDeleteAction = (id) => async (
  dispatch
) => {
  try {
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/ielts/courseManagment/liveClassDelete?id=${id}`,
      config
    );

    console.log(data, "dataaaaaaaaaaaaaaaaaaaaaa");

    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_DELETE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
