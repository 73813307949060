import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";

import Swal from "sweetalert2";

import Select from "react-select";
import { State, Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";

import { findcountryMasterAddAction } from "../../../../actions/countryMasterAction";
import { StateMasterAddAction } from "../../../../actions/StateMasterAction";
import { OutLinedButton, SubmitButton } from "../../../customComponent/Buttons";
import { TypographyText } from "../../../customComponent/Typography";
import { successTrueMsgAction } from "../../../../actions/globalAction";

const allStates = State.getStatesOfCountry("IN").map((state) => ({
  value: state.isoCode,
  label: state.name,
}));

const countries = Country.getAllCountries();

// console.log(State.getStatesOfCountry("IN"), "State.getStatesOfCountry(US)");
function AddState({ status, addMasterCategryHandler }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState([]);
  const [error, setError] = useState({});

  const [countryName, setCountryName] = useState([]);
  const [countryId, setCountryId] = useState("");

  // selectors
  let { countryFindLoading, countryFindSuccess, countryFindErr } = useSelector(
    (state) => {
      return state.findcountryMasterAdd;
    }
  );
  let { stateAddLoading, stateAddSuccess, stateAddErr } = useSelector(
    (state) => {
      return state.StateMasterAdd;
    }
  );

  useEffect(() => {
    setCountryName(countryFindSuccess);
  }, [countryFindSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction());
  }, [dispatch, stateAddSuccess]);

  const handleCountryChange = (selectedOption) => {
    setErrorMsg(false);
    let checkCountry = countryName.find(
      (value) => value.countryName === selectedOption.label
    );
  
    if (checkCountry) {
      setCountryId(checkCountry.id);
      setSelectedCountry(selectedOption);
      const countryStates = State.getStatesOfCountry(selectedOption.value);
      console.log(countryStates,"countryStatescountryStatescountryStates")
      setStates(countryStates);
      setSelectedState(null);
      // setCountryError(false);
       setError((prev) => ({
         ...prev,
         ["country"]: "",
       }));
    } else {
      setSelectedCountry(null);
      // setCountryError(true);
      setError((prev) => ({
        ...prev,
        ["country"]: "Please Choose Country You Added",
      }));
      return;
    }
  };

  const handleClose = () => {
    addMasterCategryHandler(status, false);
    setOpen(false);
  };

  const validate = () => {
    let error = {}

    if(!selectedCountry){
      error.country = "please select a country"
    }

    if(!selectedState){
      error.state = "please select a state"
    }

    setError(error);
    return Object.keys(error).length === 0;

  }

  const handleSubmit = () => {
    if (validate()){

      if (selectedCountry || selectedCountry !== null) {
        dispatch(
          StateMasterAddAction(selectedCountry, selectedState, countryId)
        );
        dispatch(successTrueMsgAction(true));
        setSelectedCountry(null);
        setErrorMsg(false);
        addMasterCategryHandler(status, false);
        setOpen(false);
      } else {
        setErrorMsg(true);
      }
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //   form

  // const handleCountryChange = (selectedOption) => {
  //   setErrorMsg(false);
  //   setSelectedCountry(selectedOption);
  // };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <TypographyText title="Add New State" variant="h5" />
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose Country
                </Typography>
              )}
              {countryError && (
                <Typography align="center" color="error">
                  Please Choose Country You Added
                </Typography>
              )}
              <Select
                options={countries.map((country) => ({
                  value: country.isoCode,
                  label: `${country.name} (+${country.phonecode})`,
                }))}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select a country"
              />
              {Boolean(error?.country) && (
                <Typography sx={{
                  fontSize:"12px",
                }} color="error">
                  {error?.country}
                </Typography>
              )}

              <h3>Select State:</h3>
              <Select
                options={states.map((state) => ({
                  value: state.isoCode,
                  label: state.name,
                }))}
                value={selectedState}
                onChange={(selectedOption) => {
                  setSelectedState(selectedOption)
                   setError((prev) => ({
                     ...prev,
                     ["state"]: "",
                   }));
                }}
                isDisabled={!selectedCountry}
                placeholder="Select a state"
              />
              {Boolean(error?.state) && (
                <Typography sx={{
                  fontSize:"12px",
                }} color="error">
                  {error?.state}
                </Typography>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OutLinedButton
            title="Cancel"
            handleClick={handleClose}
            widthSize="81px"
            heightSize="35px"
          />
          <SubmitButton
            title="Add"
            submit=""
            widthSize="81px"
            heightSize="35px"
            handleSubmit={handleSubmit}
            type="click"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AddState;
