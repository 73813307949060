import { Alert, AlertTitle, Box, Chip } from "@mui/material";
import React, { useState } from "react";
import CounsilorRatingTable from "./CounsilorRatingTable";
import ServicesRatingTable from "./ServicesRatingTable";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function RatingsMain() {
  const [clicked, setClicked] = useState("Services");
  const handleClick = (title) => {
    setClicked(title);
  };
  const { privilege, hasPrivilege } = usePrivilegeCheck();


  if (!hasPrivilege("rating") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  console.log(hasPrivilege("rating"), "hasPrivilege", privilege, "hasPrivilegehasPrivilegehasPrivilege");


  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {["Services", "Counselor"].map((item) => (
          <Chip
            label={item}
            clickable
            sx={{
              "&:hover": {
                backgroundColor: "#FE0B7A",
                color: "white",
              },
              color: clicked === item ? "white" : "rgba(0, 0, 0, 0.87)",
              backgroundColor:
                clicked === item ? "#FE0B7A" : "rgba(0, 0, 0, 0.12)",
              transition: "0.5s ease",
            }}
            onClick={() => handleClick(item)}
          />
        ))}
      </Box>

      <Box>
        {clicked === "Counselor" && <CounsilorRatingTable />}
        {clicked === "Services" && <ServicesRatingTable />}
      </Box>
    </div>
  );
}

export default RatingsMain;
