import axios from "axios";
import { SUCCESS_TRUE_MSG_ERR } from "../constants/roleManagmentConstant";
import {
  STATE_MASTER_ADD_ERR,
  STATE_MASTER_ADD_REQUEST,
  STATE_MASTER_ADD_SUCCESS,
  STATE_MASTER_DELETE_ERR,
  STATE_MASTER_DELETE_REQUEST,
  STATE_MASTER_DELETE_SUCCESS,
  STATE_MASTER_EDIT_ERR,
  STATE_MASTER_EDIT_FIND_ERR,
  STATE_MASTER_EDIT_FIND_REQUEST,
  STATE_MASTER_EDIT_FIND_SUCCESS,
  STATE_MASTER_EDIT_REQUEST,
  STATE_MASTER_EDIT_SUCCESS,
  STATE_MASTER_FIND_ERR,
  STATE_MASTER_FIND_REQUEST,
  STATE_MASTER_FIND_SUCCESS,
} from "../constants/StateMasterConstant";

// StateMasterAddAction
export const StateMasterAddAction =
  (selectedCountry, selectedState, countryId) => async (dispatch, getState) => {
    try {
      dispatch({ type: STATE_MASTER_ADD_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };

      selectedState.updatedby = isUserExist.name;

      let { data } = await axios.post(
        "/api/admin/state/addStateMaster",
        { selectedCountry, selectedState, countryId },
        config
      );

      dispatch({ type: STATE_MASTER_ADD_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: STATE_MASTER_ADD_ERR, payload: error.response.data });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true});
    }
  };

//   findStateMasterAction
export const findStateMasterAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STATE_MASTER_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get("/api/admin/state/findStateMaster", config);

    dispatch({ type: STATE_MASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: STATE_MASTER_FIND_ERR, payload: error });
  }
};

// stateMasterDeleteAction
export const stateMasterDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STATE_MASTER_DELETE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.delete(
      `/api/admin/state/deleteStateMaster?id=${id}`,
      config
    );

    dispatch({ type: STATE_MASTER_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: STATE_MASTER_DELETE_ERR, payload: error });
  }
};

// editStateFormAction
export const findEditStateFormAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STATE_MASTER_EDIT_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/state/findEditStateMaster?id=${id}`,
      config
    );

    dispatch({ type: STATE_MASTER_EDIT_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: STATE_MASTER_EDIT_FIND_ERR, payload: error });
  }
};

// stateMasterEditAction
export const stateMasterEditAction =
  (editData, stateId) => async (dispatch, getState) => {
    try {
      dispatch({ type: STATE_MASTER_EDIT_REQUEST });

      let isUserExist = localStorage.getItem("loginInfo")
        ? JSON.parse(localStorage.getItem("loginInfo"))
        : null;

      const config = {
        headers: {
          Authorization: `Bearer ${isUserExist?.token}`,
        },
      };
      editData.updatedby = isUserExist.name;
      let { data } = await axios.put(
        `/api/admin/state/editStateMaster?id=${stateId}`,
        editData,
        config
      );

      dispatch({ type: STATE_MASTER_EDIT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: STATE_MASTER_EDIT_ERR, payload: error });
    }
  };
