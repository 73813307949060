import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Chip,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import { SlOptionsVertical } from "react-icons/sl";
import SendIcon from "@mui/icons-material/Send";
import { Box, Checkbox, Grid, MenuItem, Typography } from "@mui/material";

import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdNotInterested } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@mui/material";

import { BiSortAlt2 } from "react-icons/bi";

import "./PartnerTable.css";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { partnerApprovalStatusUpdateAction } from "../../../actions/partnerAction/partnerEnquiryAction";
import {
  deletePartnerAction,
  partnerOtpVerifyAction,
} from "../../../actions/partnerAction/partnerAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import InputField from "../../customComponent/InputField";
import { SubmitButton } from "../../customComponent/Buttons";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

function PartnerTable({
  showNameColumn,
  showPrefferedColumn,
  showPhoneColumn,
  setIdsFunction,
  data,
  columns,
  leadFindLoading,
  selectBoxIdPassHandler,
  component,
  isExistCounsilor,
  subMenu,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    privilege,
    hasPrivilege,
    isCreateNewPartnerPrevilage,
    isChangeLeaderPrevilage,
    isApprovePrevilage,
    isDeletePrevilage,
    isCommisionCategoryChangePrevilage,
  } = usePrivilegeCheck();

  const [selectedRows, setSelectedRows] = useState([]);
  const [pendingData, setPendingData] = useState([]);

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  let { partnerDeleteSuccess, partnerDeleteError } = useSelector((state) => {
    return state.deletePartner;
  });

  let {
    partnerOtpVerifyLoading,
    partnerOtpVerifySuccess,
    partnerOtpVerifyError,
  } = useSelector((state) => {
    return state.partnerOtpVerify;
  });

  useEffect(() => {
    if (partnerDeleteSuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `deleted successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerDeleteSuccess]);

  useEffect(() => {
    if (partnerOtpVerifySuccess && successAlertMsgSuccess) {
      Swal.fire("Good job!", `OTP verified successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [partnerOtpVerifySuccess]);

  useEffect(() => {
    if (partnerOtpVerifyError && successAlertMsgErr) {
      Swal.fire(
        "something went wrong",
        `Error ${partnerOtpVerifyError}`,
        "error"
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [partnerOtpVerifyError]);

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    // if (selectedRows.length === sortedData.length) {
    //   setSelectedRows([]);
    // } else {
    //   let Ids = sortedData.map((item) => item.id);
    //   setSelectedRows(Ids);
    //   selectBoxIdPassHandler(Ids);
    // }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [otps, setOTPs] = useState({});

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // otp submit handler

  // Handle OTP submission for a specific index
  const handleOTPSubmit = (index) => {
    // Handle OTP submission logic here
    console.log("OTP submitted:", otps[index]);
    if (otps) {
      dispatch(partnerOtpVerifyAction(otps));
    }
  };

  // Handle OTP change for a specific index
  const handleChange = (event, id) => {
    // Update OTP value for the corresponding index
    const newOTPs = { partnerId: id, otp: event.target.value };
    console.log(newOTPs, "newOTPsnewOTPsnewOTPsnewOTPsnewOTPs");
    setOTPs(newOTPs);
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  return (
    <Grid container spacing={2} sx={{ mt: 1, ml: 1 }}>
      <Grid item xs={12} lg={12}>
        {leadFindLoading ? (
          <>
            {/* <Skeleton /> */}
            <Typography variant="h1">
              <Skeleton animation={false} />
            </Typography>

            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
          </>
        ) : (
          <>
            {sortedData.length > 0 ? (
              <div
                style={{
                  overflowX: "scroll",
                  border: "0px solid #ffff",
                }}
                className="scrollBar"
              >
                {sortedData?.length > 0 ? (
                  <Table
                    className="Partner"
                    sx={{
                      width: "100% !important",
                    }}
                  >
                    <TableHead>
                      <TableRow className="PartnerCell">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            padding: "1px",
                            border: "1px solid #ddd",
                            backgroundColor: "#fafafa",
                          }}
                        >
                          <Checkbox
                            //   checked={selectedRows.length === sortedData.length}
                            onChange={handleSelectAllRows}
                            style={{ color: "#f81b82" }}
                          />
                        </TableCell>
                        {columns?.map((column) => {
                          if (column.hidden) {
                            return null;
                          }
                          return (
                            <TableCell
                              key={column.field}
                              className="PartnerCell PartnerCommon"
                              style={{
                                padding: "0 16px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <TableSortLabel
                                className="PartnerHeaderText"
                                active={column === sortBy}
                                direction={sortOrder}
                                onClick={() => requestSort(column)}
                                IconComponent={
                                  column.field !== "action" && CustomSortIcon
                                }
                              >
                                {column.title}
                              </TableSortLabel>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData?.map((item, indx) => (
                        <TableRow className="Partner" key={indx}>
                          <TableCell
                            style={{
                              position: "sticky",
                              left: 0,
                              zIndex: 1, // To ensure it's above other cells
                              backgroundColor: "white", // Adjust as needed
                              padding: "1px",
                              border: "1px solid #ddd",
                            }}
                          >
                            <Checkbox
                              checked={selectedRows.includes(item.id)}
                              onChange={() => handleSelectRow(item.id)}
                              style={{ color: "#f81b82" }}
                            />
                          </TableCell>
                          <TableCell
                            style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                          >
                            {indx + 1}
                          </TableCell>
                          {!showNameColumn && (
                            <TableCell
                              style={{
                                padding: "0 16px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.column1}
                            </TableCell>
                          )}

                          {!showPhoneColumn && (
                            <TableCell
                              style={{
                                padding: "0 16px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.column2}
                            </TableCell>
                          )}

                          <TableCell
                            style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                          >
                            {item.column3}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </TableCell>

                          <TableCell
                            style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                          >
                            {item.column4}
                          </TableCell>
                        

                          {component !== "partnerKyc" ? (
                            <TableCell
                              style={{
                                padding: "0 16px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.status === "pending" &&
                              isApprovePrevilage(subMenu) ? (
                                <>
                                  <Button
                                    sx={{
                                      backgroundColor: "rgba(34, 130, 0, 1)",
                                      color: "white",
                                      width: "76px",
                                      height: "25px",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      mr: 2,
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(34, 110, 0, 0.5)",
                                      },
                                    }}
                                    onClick={() =>
                                      // alert(item.id)
                                      dispatch(
                                        component === "partnerEnquiry"
                                          ? partnerApprovalStatusUpdateAction(
                                              item.id,
                                              "approved"
                                            )
                                          : null
                                      )
                                    }
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    sx={{
                                      backgroundColor: "rgba(229, 41, 0, 1)",
                                      color: "white",
                                      width: "76px",
                                      height: "25px",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      ml: 1,
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(229, 21, 30, 0.5)",
                                      },
                                    }}
                                    onClick={() =>
                                      dispatch(
                                        component === "partnerEnquiry"
                                          ? partnerApprovalStatusUpdateAction(
                                              item.id,
                                              "rejected"
                                            )
                                          : null
                                      )
                                    }
                                  >
                                    Reject
                                  </Button>
                                </>
                              ) : item.status === "rejected" ||
                                item.status === "approved" ? (
                                <>
                                  <Chip
                                    sx={{
                                      height: "auto",
                                      borderRadius: "3px",
                                      backgroundColor:
                                        item.status === "approved"
                                          ? "rgb(220, 250, 195)"
                                          : "rgb(255,194,194)",
                                      color:
                                        item.status === "approved"
                                          ? "rgb(105,162,66)"
                                          : "rgba(255,69,69)",
                                      "& .MuiChip-label": {
                                        display: "block",
                                        whiteSpace: "normal",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        width: "max-content",
                                      },
                                    }}
                                    label={
                                      item.status?.[0].toUpperCase() +
                                      item.status?.slice(1)
                                    }
                                  />
                                </>
                              ) : (
                                // item.column5
                                <>
                                  <TableCell
                                    style={{
                                      // padding: "5px 16px",
                                      mt: "15px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {!item.column6 ? (
                                      <Box style={{ display: "flex" }}>
                                        {/* <InputField
                                  placeholder="OTP"
                                  handleChange={(event) =>
                                    alert(event.target.value)
                                  }
                                  // value={"email"}
                                  // widthSize="99%"
                                  // required
                                  InputType="email"
                                /> */}

                                        <div key={indx}>
                                          <TextField
                                            sx={{
                                              "& input": {
                                                width: "85px",
                                                outline: "none !important",
                                                // color: color ? color : "#ACB1C6",
                                              },
                                              // "& .MuiFormLabel-root": {
                                              //   // color: color ? color : "#ACB1C6",
                                              // },
                                              // "& .MuiFormLabel-root.Mui-focused": {
                                              //   // color: color ? color : "#ACB1C6",
                                              // },
                                              // "& .MuiFormLabel-root.Mui-error": {
                                              //   color: "#d32f2f",
                                              // },
                                              // "& .MuiFormLabel-root.Mui-focused.Mui-error":
                                              //   {
                                              //     color: "#d32f2f",
                                              //   },
                                              // "& input::placeholder": {
                                              //   // color: color ? color : "#ACB1C6",
                                              //   opacity: "1",
                                              // },
                                              // "& .MuiOutlinedInput-notchedOutline ": {
                                              //   // border: error
                                              //   //   ? "1px solid red !important"
                                              //   //   : "1px solid #ECECEC !important",
                                              // },
                                            }}
                                            autoComplete={"off"}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            placeholder="OTP"
                                            id={`outlined-basic-${indx}`}
                                            variant="outlined"
                                            // value={otp}
                                            onChange={(event) =>
                                              handleChange(event, item.id)
                                            }
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={<SendIcon />}
                                                    onClick={() =>
                                                      handleOTPSubmit(indx)
                                                    }
                                                  >
                                                    Submit
                                                  </Button>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </div>
                                      </Box>
                                    ) : (
                                      <Chip
                                        sx={{
                                          height: "auto",
                                          borderRadius: "3px",
                                          padding: "10px",
                                          backgroundColor: item.column6
                                            ? "rgb(220, 250, 195)"
                                            : "rgb(255,194,194)",
                                          color: item.column6
                                            ? "rgb(105,162,66)"
                                            : "rgba(255,69,69)",
                                          "& .MuiChip-label": {
                                            display: "block",
                                            whiteSpace: "normal",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            width: "max-content",
                                          },
                                        }}
                                        label={
                                          item.column6
                                            ? "Verified"
                                            : "Not Verified"
                                        }
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <SlOptionsVertical
                                      id="demo-positioned-button"
                                      aria-controls={
                                        leadOpenActionButton
                                          ? "demo-positioned-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        leadOpenActionButton
                                          ? "true"
                                          : undefined
                                      }
                                      onClick={(e) => {
                                        handleClickAction(e, item.id);
                                      }}
                                      variant="outlined"
                                      style={{
                                        marginLeft: "10px",
                                        color: "#F80B7A",
                                        cursor: "pointer",
                                      }}
                                      sx={{
                                        backgroundColor: "#141E3C",
                                        size: "10px",
                                        padding: 1,
                                        margin: 3,
                                        color: "#fff",
                                        width: "75px",
                                        height: "40px",
                                      }}
                                    />
                                    {/* TABLE ACTION MENU START*/}
                                    <Menu
                                      id="demo-positioned-menu"
                                      aria-labelledby="demo-positioned-button"
                                      open={leadActionButtonShow}
                                      onClose={handleCloseAction}
                                      anchorEl={leadActionButtonShow}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                      anchorOrigin={{
                                        vertical: "bottom", // Change from "top" to "bottom"
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top", // Change from "top" to "bottom"
                                        horizontal: "left",
                                      }}
                                      sx={{
                                        borderColor: "black",
                                        borderRadius: "24px",
                                        mt: 1.5,
                                        width: "auto",
                                        height: "auto",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <MenuItem>
                                          <AiOutlineEye
                                            style={{
                                              fontSize: "lg",
                                              width: "20px",
                                              height: "19px",
                                            }}
                                            onClick={() =>
                                              navigate(
                                                `/Partner/details/${tableId}`
                                              )
                                            }
                                          />
                                        </MenuItem>

                                        {isDeletePrevilage(subMenu) ? (
                                          <MenuItem>
                                            <RiDeleteBin6Line
                                              style={{
                                                fontSize: "lg",
                                                width: "20px",
                                                height: "19px",
                                              }}
                                              onClick={() => {
                                                Swal.fire({
                                                  title: "Are you sure?",
                                                  text: "You won't be able to revert this!",
                                                  icon: "warning",
                                                  showCancelButton: true,
                                                  confirmButtonColor: "#3085d6",
                                                  cancelButtonColor: "#d33",
                                                  confirmButtonText:
                                                    "Yes, delete it!",
                                                }).then((result) => {
                                                  if (result.isConfirmed) {
                                                    dispatch(
                                                      deletePartnerAction(
                                                        tableId
                                                      )
                                                    );
                                                  }
                                                });
                                              }}
                                            />
                                          </MenuItem>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    </Menu>{" "}
                                  </TableCell>
                                </>
                              )}
                            </TableCell>
                          ) : (
                            <TableCell>
                              <AiOutlineEye
                                style={{
                                  fontSize: "lg",
                                  width: "20px",
                                  height: "19px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(`/Partner/viewDetails/${item.id}`)
                                }
                              />
                            </TableCell>
                          )}
                          {/* {/*TABLE ACTION MENU END */}
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter />
                  </Table>
                ) : (
                  <TableNoItemComponent />
                )}
              </div>
            ) : (
              <TableNoItemComponent />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default PartnerTable;
