import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Menu,
  Pagination,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";
import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { ieltsPurchasedStudentCourseStatusChangeAction } from "../../../actions/IeltsActions/purchasedStudentActions";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";
import PremissionDialog from "../../customComponent/premissionDialog";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

function IeltsOnlinePurchaseTable({
  data,
  columns,
  component,
  selectBoxIdPassHandler,
  columnVisibility,
  pageNumber,
  subMenu,
}) {
  useEffect(() => {
    if (pageNumber > 10) {
      setRowsPerPage(pageNumber);
    } else {
      setRowsPerPage(10);
    }
  }, [pageNumber]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { isStatusChangePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // Sort icon

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="customIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="customIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState(""); // Current sort column
  const [sortOrder, setSortOrder] = useState("asc"); // Current sort order

  const requestSort = (column) => {
    let newSortOrder = "asc";

    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    setPage(1);
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  // Handle status change
  const handleStatusChange = (status, id) => {
    if (isStatusChangePrevilage(subMenu)) {
      dispatch(ieltsPurchasedStudentCourseStatusChangeAction(status, id));
    } else {
      setOpenAlert(true);
    }
    // setStatus(status);
  };

  const handleSelectAllRows = () => {
    if (selectedRows?.length === sortedData?.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData?.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    //  setIdsFunction(updatedSelectedRows);
  };

  //  Table actions

  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return sortedData?.length === 0 ? (
    <TableNoItemComponent />
  ) : (
    <>
      <Box sx={{ overflow: "hidden" }}>
        <Typography
          sx={{
            backgroundColor: "#fff",
            padding: "5px",
            paddingRight: "0px",
            borderRadius: "10px 10px 0 0",
            width: "100%",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Showing{" "}
          <Typography
            component={"span"}
            sx={{
              backgroundColor: "#fff",
              padding: "5px",
              paddingLeft: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              // lineHeight: "24px",
              fontWeight: "700",
            }}
          >
            {
              sortedData?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )?.length
            }{" "}
            Result
          </Typography>
        </Typography>

        <Box
          sx={{
            overflowX: "scroll",
            maxWidth: "100%",
          }}
        >
          <Table className="masterTable" sx={{ minWidth: "1500px" }}>
            <TableHead
              sx={{
                background: "#F9F9FB",
              }}
            >
              <TableRow className="msterTableCell">
                <TableCell>
                  <Checkbox
                    checked={selectedRows?.length === sortedData?.length}
                    onChange={handleSelectAllRows}
                    style={{ color: "#f81b82" }}
                  />
                </TableCell>
                {columns?.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  let visibility = columnVisibility.find(
                    (visible) => visible.title === column.title
                  );
                  return (
                    visibility?.value && (
                      <TableCell key={column.field}>
                        <TableSortLabel
                          className="headerText"
                          active={column === sortBy}
                          direction={sortOrder}
                          onClick={() => requestSort(column)}
                          IconComponent={
                            column.field !== "action" && CustomSortIcon
                          }
                        >
                          {column.title}
                        </TableSortLabel>
                      </TableCell>
                    )
                  );
                })}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                ?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((item, indx) => (
                  <TableRow className="tableRow" key={indx}>
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleSelectRow(item.id)}
                        style={{ color: "#f81b82" }}
                      />
                    </TableCell>
                    {columnVisibility[0]?.value && (
                      <TableCell>{indx + 1}</TableCell>
                    )}
                    {columnVisibility[1]?.value && (
                      <TableCell>
                        {item?.ielts_entrolled_student?.fullName}
                      </TableCell>
                    )}
                    {columnVisibility[2]?.value && (
                      <TableCell>
                        {item?.ielts_entrolled_student?.dob}
                      </TableCell>
                    )}
                    {columnVisibility[3]?.value && (
                      <TableCell>
                        {item?.ielts_entrolled_student?.gender}
                      </TableCell>
                    )}
                    {columnVisibility[4]?.value && (
                      <TableCell>
                        {item?.ielts_entrolled_student?.email}
                      </TableCell>
                    )}
                    {columnVisibility[5]?.value && (
                      <TableCell>
                        {item?.ielts_entrolled_student?.phoneNumber}
                      </TableCell>
                    )}
                    {columnVisibility[6]?.value && (
                      <TableCell>
                        {item?.ielts_entrolled_student?.whatsAppNumber}
                      </TableCell>
                    )}
                    {columnVisibility[7]?.value && (
                      <TableCell>{item?.ielts_course?.CourseName}</TableCell>
                    )}
                    {columnVisibility[11]?.value && (
                      <TableCell>
                        <FormControl
                          fullWidth
                          sx={{
                            mt: 1,
                            bgcolor:
                              item?.ielts_entrolled_student?.status === "Active"
                                ? "#228200"
                                : "#E52900",
                            borderRadius: "8px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            "& .MuiSelect-icon": {
                              top: "3px",
                            },
                            "&.MuiFormControl-root": {
                              width: "100px",
                            },
                          }}
                        >
                          <InputLabel
                            htmlFor="demo-simple-select-placeholder"
                            style={{
                              transform: "translate(10px, 10px)",
                              pointerEvents: "none",
                              zIndex: 1,
                              transition: "transform 0.25s, font-size 0.25s",
                              margin: "-6px",
                              color: "white",
                              fontWeight: 400,
                              paddingLeft: "10px",
                              border: "none !important",
                            }}
                          >
                            {item?.ielts_entrolled_student?.status
                              ? item?.ielts_entrolled_student?.status
                              : "placeholder"}
                          </InputLabel>
                          <Select
                            value={status}
                            onChange={(e) =>
                              handleStatusChange(
                                e.target.value,
                                item?.ielts_entrolled_student?.id
                              )
                            }
                            inputProps={{
                              // name: {item.coloum3},
                              id: "demo-simple-select-placeholder",
                            }}
                            sx={{
                              position: "absolute",
                              top: "0px",
                              right: "0",
                              "& .MuiSelect-select": {
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                outline: "none !important",
                                border: "none !important",
                              },
                            }}
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}

                    <TableCell
                      style={{ padding: "0 16px", whiteSpace: "nowrap" }}
                    >
                      <SlOptionsVertical
                        id="demo-positioned-button"
                        aria-controls={
                          leadOpenActionButton
                            ? "demo-positioned-menu"
                            : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          leadOpenActionButton ? "true" : undefined
                        }
                        onClick={(e) => {
                          handleClickAction(
                            e,
                            item?.ielts_entrolled_student?.id
                          );
                        }}
                        variant="outlined"
                        style={{
                          marginLeft: "10px",
                          color: "#F80B7A",
                          cursor: "pointer",
                        }}
                        sx={{
                          backgroundColor: "#141E3C",
                          size: "10px",
                          padding: 1,
                          margin: 3,
                          color: "#fff",
                          width: "75px",
                          height: "40px",
                        }}
                      />
                      {/* TABLE ACTION MENU START*/}
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        open={leadActionButtonShow}
                        onClose={handleCloseAction}
                        anchorEl={leadActionButtonShow}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom", // Change from "top" to "bottom"
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top", // Change from "top" to "bottom"
                          horizontal: "left",
                        }}
                        sx={{
                          borderColor: "black",
                          borderRadius: "24px",
                          mt: 1.5,
                          width: "auto",
                          height: "auto",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MenuItem>
                            <AiOutlineEye
                              style={{
                                fontSize: "lg",
                                width: "20px",
                                height: "19px",
                              }}
                              onClick={() =>
                                navigate(`/ielts/purchased/online/${tableId}`)
                              }
                            />
                          </MenuItem>
                        </Box>
                      </Menu>{" "}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter />
          </Table>
        </Box>
        <Pagination
          sx={{
            mt: "28px",
            "& .Mui-selected": {
              backgroundColor: "#f81b82 !important",
              color: "#fff",
            },
            "& .MuiPagination-ul": {
              justifyContent: "end",
            },
          }}
          count={Math.ceil(data?.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IeltsOnlinePurchaseTable;
