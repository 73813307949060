export const GET_ALL_FINAL_SUMMARY_REQUEST = "GET_ALL_FINAL_SUMMARY_REQUEST";
export const GET_ALL_FINAL_SUMMARY_SUCCESS = "GET_ALL_FINAL_SUMMARY_SUCCESS";
export const GET_ALL_FINAL_SUMMARY_ERR = "GET_ALL_FINAL_SUMMARY_ERR";

export const PAID_PAYMENT_STATUS_UPDATE_REQUEST =
  "PAID_PAYMENT_STATUS_UPDATE_REQUEST";
export const PAID_PAYMENT_STATUS_UPDATE_SUCCESS =
  "PAID_PAYMENT_STATUS_UPDATE_SUCCESS";
export const PAID_PAYMENT_STATUS_UPDATE_ERR = "PAID_PAYMENT_STATUS_UPDATE_ERR";

export const ADD_UNIVERSITY_COMMISSION_REQUEST =
  "ADD_UNIVERSITY_COMMISSION_REQUEST";
export const ADD_UNIVERSITY_COMMISSION_SUCCESS =
  "ADD_UNIVERSITY_COMMISSION_SUCCESS";
export const ADD_UNIVERSITY_COMMISSION_ERR = "ADD_UNIVERSITY_COMMISSION_ERR";

export const LIST_UNIVERSITY_COMMISSION_REQUEST =
  "LIST_UNIVERSITY_COMMISSION_REQUEST";
export const LIST_UNIVERSITY_COMMISSION_SUCCESS =
  "LIST_UNIVERSITY_COMMISSION_SUCCESS";
export const LIST_UNIVERSITY_COMMISSION_ERR = "LIST_UNIVERSITY_COMMISSION_ERR";

export const DELETE_UNIVERSITY_COMMISSION_REQUEST =
  "DELETE_UNIVERSITY_COMMISSION_REQUEST";
export const DELETE_UNIVERSITY_COMMISSION_SUCCESS =
  "DELETE_UNIVERSITY_COMMISSION_SUCCESS";
export const DELETE_UNIVERSITY_COMMISSION_ERR =
  "DELETE_UNIVERSITY_COMMISSION_ERR";

export const UNIVERSITY_COMMISSION_REQUEST_BY_ID =
  "UNIVERSITY_COMMISSION_REQUEST_BY_ID";
export const UNIVERSITY_COMMISSION_SUCCESS_BY_ID =
  "UNIVERSITY_COMMISSION_SUCCESS_BY_ID";
export const UNIVERSITY_COMMISSION_ERR_BY_ID =
  "UNIVERSITY_COMMISSION_ERR_BY_ID";

export const UPDATE_UNIVERSITY_COMMISSION_REQUEST =
  "UPDATE_UNIVERSITY_COMMISSION_REQUEST";
export const UPDATE_UNIVERSITY_COMMISSION_SUCCESS = "UPDATE_UNIVERSITY_COMMISSION_SUCCESS";
export const UPDATE_UNIVERSITY_COMMISSION_ERR = "UPDATE_UNIVERSITY_COMMISSION_ERR";
