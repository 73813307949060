import React, { useEffect, useState } from "react";
import PartnerDetailHeadTab from "./PartnerDetailHeadTab";
import { MainContainer } from "../../content/content.element";
import { InfoHeader } from "../../customComponent/InfoHeader";
import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { SubmitButton } from "../../customComponent/Buttons";
import { SelectInputField } from "../../customComponent/InputField";
import {
  allPartnerCommissionCategoryFindAction,
  partnerCommissionIdUpdateAction,
  partnerKycDetailFindAction,
} from "../../../actions/partnerAction/partnerAction";
import { useDispatch, useSelector } from "react-redux";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../../Leads/createLeads/AccessDeniedIcon";

function PartnerDetailsMain(props) {
  const {
    privilege,
    hasPrivilege,
    isCreateNewPartnerPrevilage,
    isChangeLeaderPrevilage,
    isDeletePrevilage,
    isCommisionCategoryChangePrevilage,
  } = usePrivilegeCheck();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [partnerData, setPartnerData] = useState({});
  const [partnerCommision, setPartnerCommision] = useState([]);

  const [previlageAlert, setPrevilageAlert] = useState(false);

  const [tabIsVisible, setTabIsVisible] = useState({
    companyInfo: false,
    addressInfo: false,
    bankInfo: false,
  });

  let { partnerDetailFindSuccess } = useSelector((state) => {
    return state.partnerKycDetailFind;
  });

  let {
    partnerCommissionFindLoading,
    partnerCommissionFindSuccess,
    partnerCommissionFindError,
  } = useSelector((state) => {
    return state.allPartnerCommissionCategoryFind;
  });

  let {
    partnerCommissionIdUpdateLoading,
    partnerCommissionIdUpdateSuccess,
    partnerCommissionIdUpdateError,
  } = useSelector((state) => {
    return state.partnerCommissionIdUpdate;
  });

  useEffect(() => {
    dispatch(partnerKycDetailFindAction(id));
  }, [id, partnerCommissionIdUpdateSuccess]);

  useEffect(() => {
    dispatch(allPartnerCommissionCategoryFindAction());
  }, []);

  useEffect(() => {
    if (partnerCommissionFindSuccess) {
      setPartnerCommision(partnerCommissionFindSuccess);
    }
  }, [partnerCommissionFindSuccess]);

  useEffect(() => {
    if (partnerDetailFindSuccess) {
      setPartnerData(partnerDetailFindSuccess);
    }
  }, [partnerDetailFindSuccess]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChooseCommission = (commissionId) => {
    if (isCommisionCategoryChangePrevilage("All Partners")) {
      dispatch(partnerCommissionIdUpdateAction(commissionId, id));
    } else {
      setPrevilageAlert(true);
    }
  };
  return (
    <>
      <MainContainer active={props.toggle}>
        <InfoHeader headTitle="Partner Details" url="/Partner" />
        <Box mt={1}>
          <PartnerDetailHeadTab
            title="Company Information"
            handleClick={() =>
              setTabIsVisible({
                ...tabIsVisible,
                companyInfo: !tabIsVisible.companyInfo,
              })
            }
          />
        </Box>

        {tabIsVisible.companyInfo && (
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12} lg={9}>
              <div style={{ display: "flex" }}>
                <div style={{ margin: "1px" }}>
                  <img
                    alt="university_image"
                    src="/Ellipse.png"
                    style={{
                      borderRadius: "50px",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </div>
              </div>
            </Grid>
            {/* </Grid>

        <Grid container spacing={2}> */}
            <Grid
              item
              xs={12}
              lg={2}
              sx={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "end" }}>
                  <Chip
                    label={
                      partnerData?.incentiveCategory?.category
                        ? `Category is ${partnerData?.incentiveCategory?.category}  `
                        : "Commission Category "
                    }
                    onClick={handleClick}
                    onDelete={() => console.log("have a nice day")}
                    variant="outlined"
                    deleteIcon={
                      <LiaExchangeAltSolid
                        style={{ color: "white", fontSize: "small" }}
                      />
                    }
                    sx={{
                      backgroundColor: "#141e3c",
                      color: "white",
                      padding: "15px",
                      "& .css-1jzq0dw-MuiChip-label": {
                        fontSize: "small",
                      },
                    }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {partnerCommision?.length !== 0 &&
                      partnerCommision?.map((item) => (
                        <MenuItem
                          key={item.id}
                          onClick={() => handleChooseCommission(item.id)}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center", // Center the content horizontally
                            alignItems: "center",
                            textAlign: "center", // Center the content vertically
                          }}
                        >
                          {item?.category} - {item?.percentage}%
                        </MenuItem>
                      ))}
                  </Menu>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <table
                  className="tableView"
                  style={{
                    width: "100%",
                    margin: "5px",
                  }}
                >
                  <tr>
                    <th className="tableHeader">Organization / Agent Name</th>
                    <td className="tabelTd"> {partnerData?.name}</td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Type of Organization</th>
                    <td className="tabelTd">
                      {partnerData?.typeOfOrganization}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Established Year</th>
                    <td className="tabelTd">{partnerData?.establishedYear}</td>
                  </tr>
                 

                  <tr>
                    <th className="tableHeader">Company Email Name</th>
                    <td className="tabelTd">{partnerData?.email}</td>
                  </tr>

                  <tr>
                    <th className="tableHeader">Company GST/VAT Number</th>
                    <td className="tabelTd">{partnerData?.GSTorVAT}</td>
                  </tr>
                </table>

                <table
                  className="tableView"
                  style={{ width: "100%", margin: "5px" }}
                >
                 
                  <tr>
                    <th className="tableHeader">Date of Incorporation</th>
                    <td className="tabelTd">
                      {partnerData?.dateOfIncorporation}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Phone</th>
                    <td className="tabelTd">{partnerData?.phone}</td>
                  </tr>

                  <tr>
                    <th className="tableHeader">Website</th>
                    <td className="tabelTd">{partnerData?.websiteUrl}</td>
                  </tr>

                  <tr>
                    <th className="tableHeader">Assosiate With Other</th>
                    <td className="tabelTd">
                      {partnerData?.assosiateWithOther}
                    </td>
                  </tr>
                </table>
              </div>
            </Grid>
          </Grid>
        )}

        <Box mt={1}>
          <PartnerDetailHeadTab
            title="Address Information"
            handleClick={(e) =>
              setTabIsVisible({
                ...tabIsVisible,
                addressInfo: !tabIsVisible.addressInfo,
              })
            }
          />
        </Box>

        {tabIsVisible?.addressInfo && (
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <table
                  className="tableView"
                  style={{
                    width: "100%",
                    margin: "5px",
                  }}
                >
                  <tr>
                    <th className="tableHeader">country</th>
                    <td className="tabelTd">
                      {" "}
                      {partnerData?.partnerAddress?.country}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">State</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.state}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">City</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.city}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Whats App Number</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.whatsappNumber}
                    </td>
                  </tr>
                </table>

                <table
                  className="tableView"
                  style={{ width: "100%", margin: "5px" }}
                >
                  <tr>
                    <th className="tableHeader">Address Line 1</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.addressLine1}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Address Line 2</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.addressLine2}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Post Code</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.postCode}
                    </td>
                  </tr>

                  <tr>
                    <th className="tableHeader">Skype ID</th>
                    <td className="tabelTd">
                      {partnerData?.partnerAddress?.skypeID}
                    </td>
                  </tr>
                </table>
              </div>
            </Grid>
          </Grid>
        )}

        <Box mt={1}>
          <PartnerDetailHeadTab
            title="Bank & KYC Information"
            handleClick={(e) =>
              setTabIsVisible({
                ...tabIsVisible,
                bankInfo: !tabIsVisible.bankInfo,
              })
            }
          />
        </Box>
        {tabIsVisible?.bankInfo && (
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid
              item
              xs={12}
              lg={12}
              sx={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <table
                  className="tableView"
                  style={{
                    width: "100%",
                    margin: "5px",
                  }}
                >
                  <tr>
                    <th className="tableHeader">Account Holder</th>
                    <td className="tabelTd">
                      {" "}
                      {partnerData?.partnerBankKyc?.accountholder}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Account Number</th>
                    <td className="tabelTd">
                      {partnerData?.partnerBankKyc?.accountNumber}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Bank Code</th>
                    <td className="tabelTd">
                      {partnerData?.partnerBankKyc?.bankCode}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Bank Name</th>
                    <td className="tabelTd">
                      {partnerData?.partnerBankKyc?.bankName}
                    </td>
                  </tr>
                </table>

                <table
                  className="tableView"
                  style={{ width: "100%", margin: "5px" }}
                >
                  <tr>
                    <th className="tableHeader">PAN Number</th>
                    <td className="tabelTd">
                      {partnerData?.partnerBankKyc?.PANNumber}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Branch Name</th>
                    <td className="tabelTd">
                      {partnerData?.partnerBankKyc?.branchName}
                    </td>
                  </tr>
                  <tr>
                    <th className="tableHeader">Employees Count</th>
                    <td className="tabelTd">
                      {partnerData?.partnerBankKyc?.employeesCount}
                    </td>
                  </tr>
                </table>
              </div>
            </Grid>
          </Grid>
        )}

        {/* <Box mt={1}>
        <PartnerDetailHeadTab
          title="Partner Staff Information"
          handleClick={(e) => alert("coming soon")}
        />
      </Box> */}

        <Grid
          item
          xs={12}
          lg={2}
          sx={{
            display: "flex",
          }}
        >
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            {/* <Grid item xs={2} lg={2}>
              <SelectInputField
                label="Choose no. of Staff"
                handleSelectChange={() => alert("coming soon")}
                mapValue={["1", "2", "3", "4", "5"]}
                mapMethod=""
              />
            </Grid> */}
            {/* <Grid item xs={1} lg={1}>
              <SubmitButton
                title="save"
                widthSize="auto"
                heightSize="auto"
                type="click"
                handleSubmit={() => alert("coming soon")}
              />
            </Grid> */}
          </Grid>
        </Grid>

        <Box mt={1}>
          <PartnerDetailHeadTab
            title="Partner Incentive"
            handleClick={(e) => alert("coming soon")}
          />
        </Box>
      </MainContainer>

      {/* Dialog for Previlage popUp */}

      <Dialog
        open={previlageAlert}
        onClose={() => setPrevilageAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setPrevilageAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission to create/delete
        </Typography>
      </Dialog>
    </>
  );
}

export default PartnerDetailsMain;
