import React from 'react'

function AccessDeniedIcon() {
  return (
    <svg
      width="226"
      height="159"
      viewBox="0 0 226 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M121.434 38.2344H98.5107C89.9153 38.2344 82.9473 45.2022 82.9473 53.7978V86.493H90.3822V54.7217C90.3822 49.7221 94.4352 45.6693 99.4347 45.6693H120.51C125.509 45.6693 129.562 49.7223 129.562 54.722V86.493H136.997V53.7977C136.997 45.2022 130.029 38.2344 121.434 38.2344Z"
        fill="url(#paint0_linear_9452_72068)"
      />
      <path
        d="M77.5082 63.5864C77.1467 63.5864 76.8535 63.2933 76.8535 62.9317V58.811C76.8535 58.4494 77.1467 58.1562 77.5082 58.1562C77.8698 58.1562 78.1629 58.4494 78.1629 58.811V62.9316C78.1629 63.2931 77.8698 63.5864 77.5082 63.5864Z"
        fill="#1F4380"
      />
      <path
        d="M77.5082 54.4581C77.1467 54.4581 76.8535 54.165 76.8535 53.8034C76.8535 41.8628 86.5679 32.1484 98.5085 32.1484C98.87 32.1484 99.1632 32.4416 99.1632 32.8032C99.1632 33.1647 98.87 33.4579 98.5085 33.4579C87.2899 33.4579 78.1629 42.5848 78.1629 53.8034C78.1629 54.1648 77.8698 54.4581 77.5082 54.4581Z"
        fill="#1F4380"
      />
      <path
        d="M137.652 87.1542H128.908V54.7285C128.908 50.0979 125.14 46.3306 120.51 46.3306H99.435C94.8044 46.3306 91.0373 50.0977 91.0373 54.7283V87.1542H82.293V53.8041C82.293 44.8613 89.5684 37.5859 98.5111 37.5859H121.434C130.377 37.5859 137.652 44.8613 137.652 53.8041V87.1542H137.652ZM130.217 85.8446H136.343V53.8041C136.343 45.5835 129.655 38.8954 121.434 38.8954H98.511C90.2903 38.8954 83.6022 45.5835 83.6022 53.8041V85.8446H89.7277V54.7281C89.7277 49.3756 94.0823 45.021 99.4348 45.021H120.51C125.862 45.021 130.217 49.3756 130.217 54.7283L130.217 85.8446Z"
        fill="#1F4380"
      />
      <path
        d="M66.0586 84.0635V96.0089C66.0586 120.263 85.7203 139.924 109.974 139.924C134.228 139.924 153.89 120.263 153.89 96.0087V84.0635C153.89 76.9264 148.104 71.1406 140.967 71.1406H78.9816C71.8445 71.1406 66.0586 76.9266 66.0586 84.0635Z"
        fill="url(#paint1_linear_9452_72068)"
      />
      <path
        d="M148.947 126.952C148.806 126.952 148.664 126.907 148.545 126.813C148.26 126.591 148.209 126.179 148.432 125.894C149.509 124.513 150.522 123.06 151.441 121.576C151.631 121.268 152.035 121.173 152.342 121.364C152.65 121.554 152.744 121.958 152.554 122.265C151.61 123.79 150.57 125.282 149.464 126.7C149.335 126.865 149.142 126.952 148.947 126.952Z"
        fill="#1F4380"
      />
      <path
        d="M115.209 145.742C114.878 145.742 114.594 145.492 114.558 145.155C114.521 144.796 114.782 144.474 115.141 144.436C126.302 143.266 136.422 138.427 144.407 130.442C144.663 130.186 145.078 130.186 145.333 130.442C145.589 130.697 145.589 131.112 145.333 131.368C137.133 139.568 126.74 144.537 115.278 145.738C115.255 145.741 115.232 145.742 115.209 145.742Z"
        fill="#1F4380"
      />
      <path
        d="M109.974 140.577C98.0694 140.577 86.8767 135.941 78.4585 127.523C70.0403 119.105 65.4043 107.912 65.4043 96.0074V84.062C65.4043 76.5752 71.4952 70.4844 78.9819 70.4844H140.967C148.454 70.4844 154.544 76.5752 154.544 84.062V96.0072C154.544 107.912 149.908 119.105 141.49 127.523C133.072 135.941 121.88 140.577 109.974 140.577ZM78.9819 71.7938C72.2171 71.7938 66.7137 77.2974 66.7137 84.062V96.0074C66.7137 119.861 86.1205 139.268 109.974 139.268C133.828 139.268 153.235 119.861 153.235 96.0072V84.062C153.235 77.2974 147.732 71.7938 140.967 71.7938H78.9819Z"
        fill="#1F4380"
      />
      <path
        d="M117.551 118.358C125.689 114.988 129.553 105.658 126.183 97.52C122.812 89.3822 113.482 85.5178 105.344 88.8887C97.2064 92.2596 93.342 101.589 96.7129 109.727C100.084 117.865 109.413 121.729 117.551 118.358Z"
        fill="white"
      />
      <path
        d="M111.443 120.23C102.288 120.23 94.8398 112.782 94.8398 103.627C94.8398 94.4716 102.288 87.0234 111.443 87.0234C120.598 87.0234 128.046 94.4716 128.046 103.627C128.046 112.782 120.598 120.23 111.443 120.23ZM111.443 88.3329C103.01 88.3329 96.1493 95.1935 96.1493 103.627C96.1493 112.06 103.01 118.921 111.443 118.921C119.876 118.921 126.737 112.06 126.737 103.627C126.737 95.1935 119.876 88.3329 111.443 88.3329Z"
        fill="#1F4380"
      />
      <path
        d="M115.106 99.9009C115.106 97.6759 113.244 95.8858 110.992 96.0057C108.988 96.1124 107.364 97.7804 107.306 99.7862C107.264 101.25 108.032 102.535 109.191 103.237L108.483 112.167C108.454 112.533 108.743 112.846 109.111 112.846H113.3C113.668 112.846 113.957 112.533 113.928 112.167L113.22 103.237C114.349 102.553 115.106 101.317 115.106 99.9009Z"
        fill="url(#paint2_linear_9452_72068)"
      />
      <path
        d="M113.539 113.492H109.349C108.992 113.492 108.648 113.341 108.406 113.079C108.163 112.816 108.04 112.462 108.069 112.106L108.747 103.557C107.55 102.676 106.847 101.255 106.891 99.758C106.958 97.4068 108.849 95.4673 111.196 95.3426C112.461 95.2755 113.662 95.7166 114.578 96.5854C115.495 97.4546 116 98.6288 116 99.8917C116 101.358 115.313 102.701 114.142 103.558L114.82 112.106C114.848 112.462 114.725 112.816 114.483 113.079C114.24 113.341 113.896 113.492 113.539 113.492ZM109.376 112.182H113.512L112.774 102.877L113.12 102.667C114.103 102.072 114.69 101.034 114.69 99.8917C114.69 98.9918 114.331 98.1551 113.677 97.5356C113.024 96.9167 112.167 96.5997 111.266 96.6504C109.594 96.7392 108.248 98.1208 108.199 99.7959C108.166 100.961 108.767 102.061 109.769 102.668L110.114 102.877L109.376 112.182Z"
        fill="#1F4380"
      />
      <path
        d="M158.042 63.9667C158.041 64.1384 157.971 64.303 157.843 64.4274C157.674 64.5916 157.438 64.6512 157.211 64.5868L155.023 63.9653C154.796 63.9009 154.627 63.726 154.569 63.4976C154.512 63.2692 154.578 63.0347 154.747 62.8707L156.379 61.2863C156.548 61.1222 156.785 61.0626 157.011 61.127C157.238 61.1914 157.408 61.3662 157.465 61.5947L158.021 63.8005C158.035 63.8558 158.042 63.9115 158.042 63.9667ZM155.063 63.336C155.063 63.351 155.065 63.3638 155.067 63.3719C155.073 63.3951 155.094 63.4512 155.163 63.4708L157.352 64.0922C157.421 64.1117 157.468 64.0751 157.485 64.0584C157.502 64.0418 157.54 63.9958 157.523 63.9259L156.967 61.7201C156.949 61.6504 156.894 61.6277 156.871 61.6212C156.848 61.6146 156.789 61.6048 156.737 61.6549L155.105 63.2392C155.072 63.2719 155.063 63.3081 155.063 63.336Z"
        fill="#1F4380"
      />
      <path
        d="M59.1986 59.9043C59.3001 60.0682 59.3326 60.266 59.2851 60.4606C59.2223 60.7175 59.0331 60.9151 58.7792 60.9893L56.3278 61.7043C56.0739 61.7783 55.8081 61.7134 55.617 61.5305C55.4262 61.3477 55.3493 61.085 55.4121 60.8281L56.0186 58.3477C56.0814 58.0907 56.2705 57.8931 56.5244 57.8191C56.7783 57.745 57.044 57.8099 57.2351 57.993L59.08 59.7584C59.1266 59.8027 59.1661 59.8518 59.1986 59.9043ZM55.9905 61.0822C55.9994 61.0966 56.0092 61.1074 56.016 61.1138C56.0353 61.1324 56.0888 61.1731 56.1663 61.1506L58.6176 60.4358C58.6949 60.4131 58.7183 60.35 58.7246 60.3238C58.7311 60.2978 58.7397 60.2312 58.6812 60.1752L56.8363 58.4098C56.778 58.354 56.7118 58.3654 56.6859 58.3731C56.6601 58.3807 56.5981 58.4065 56.5788 58.4849L55.9722 60.9653C55.9603 61.0159 55.9739 61.0555 55.9905 61.0822Z"
        fill="#1F4380"
      />
      <path
        d="M166.465 81.1068C165.454 81.1068 164.631 80.2838 164.631 79.2721C164.631 78.2605 165.454 77.4375 166.465 77.4375C167.477 77.4375 168.3 78.2605 168.3 79.2721C168.3 80.2838 167.477 81.1068 166.465 81.1068ZM166.465 78.0116C165.77 78.0116 165.205 78.577 165.205 79.2721C165.205 79.9673 165.771 80.5327 166.465 80.5327C167.16 80.5327 167.726 79.9673 167.726 79.2721C167.726 78.577 167.161 78.0116 166.465 78.0116Z"
        fill="#1F4380"
      />
      <path
        d="M55.9326 127.447C54.7971 127.447 53.873 126.523 53.873 125.388C53.873 124.252 54.7969 123.328 55.9326 123.328C57.0681 123.328 57.9921 124.252 57.9921 125.388C57.9921 126.523 57.0681 127.447 55.9326 127.447ZM55.9326 123.973C55.1523 123.973 54.5175 124.608 54.5175 125.388C54.5175 126.168 55.1523 126.803 55.9326 126.803C56.7127 126.803 57.3475 126.168 57.3475 125.388C57.3477 124.608 56.7129 123.973 55.9326 123.973Z"
        fill="#1F4380"
      />
      <path
        d="M171.325 67.0829L171.932 66.4766C172.069 66.339 172.069 66.1159 171.932 65.9782C171.794 65.8406 171.571 65.8406 171.433 65.9782L170.827 66.5846L170.221 65.9782C170.083 65.8406 169.86 65.8406 169.722 65.9782C169.585 66.1159 169.585 66.339 169.722 66.4766L170.329 67.0829L169.724 67.6881C169.586 67.8258 169.586 68.0489 169.724 68.1865C169.792 68.2552 169.883 68.2897 169.973 68.2897C170.063 68.2897 170.153 68.2552 170.222 68.1865L170.827 67.5813L171.432 68.1865C171.501 68.2552 171.591 68.2897 171.682 68.2897C171.772 68.2897 171.862 68.2552 171.931 68.1865C172.068 68.0489 172.068 67.8258 171.931 67.6881L171.325 67.0829Z"
        fill="#1F4380"
      />
      <path
        d="M67.7873 131.028L68.4447 130.371C68.5939 130.222 68.5939 129.98 68.4447 129.831C68.2956 129.681 68.0535 129.681 67.9044 129.831L67.2471 130.488L66.5897 129.831C66.4406 129.681 66.1985 129.681 66.0494 129.831C65.9002 129.98 65.9002 130.222 66.0494 130.371L66.7068 131.028L66.0507 131.684C65.9015 131.834 65.9015 132.076 66.0507 132.225C66.1253 132.299 66.2232 132.337 66.3209 132.337C66.4186 132.337 66.5165 132.299 66.591 132.225L67.2471 131.568L67.9031 132.225C67.9776 132.299 68.0755 132.337 68.1732 132.337C68.2709 132.337 68.3688 132.299 68.4434 132.225C68.5926 132.075 68.5926 131.834 68.4434 131.684L67.7873 131.028Z"
        fill="#1F4380"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9452_72068"
          x1="82.9473"
          y1="62.3636"
          x2="136.997"
          y2="62.3636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4E6FF" />
          <stop offset="1" stop-color="#73B2FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9452_72068"
          x1="92.958"
          y1="67.8928"
          x2="137.193"
          y2="137.222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FE0B7A" />
          <stop offset="1" stop-color="#FF8F5C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9452_72068"
          x1="107.305"
          y1="104.423"
          x2="115.106"
          y2="104.423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C4E6FF" />
          <stop offset="1" stop-color="#73B2FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default AccessDeniedIcon