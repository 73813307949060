import axios from "axios";
import {
  UNIVERSITY_MASTER_BY_TYPE_ID_FIND_ERR,
  UNIVERSITY_MASTER_BY_TYPE_ID_FIND_REQUEST,
  UNIVERSITY_MASTER_BY_TYPE_ID_FIND_SUCCESS,
  UNIVERSITY_MASTER_EDIT_ERR,
  UNIVERSITY_MASTER_EDIT_REQUEST,
  UNIVERSITY_MASTER_EDIT_SUCCESS,
  UNIVERSITY_MASTER_FIND_ERR,
  UNIVERSITY_MASTER_FIND_REQUEST,
  UNIVERSITY_MASTER_FIND_SUCCESS,
  UNIVERSITY_MASTER_SUBMIT_ERR,
  UNIVERSITY_MASTER_SUBMIT_REQUEST,
  UNIVERSITY_MASTER_SUBMIT_SUCCESS,
  UNIVERSITY_MASTER_SUB_DELETE_ERR,
  UNIVERSITY_MASTER_SUB_DELETE_REQUEST,
  UNIVERSITY_MASTER_SUB_DELETE_SUCCESS,
} from "../../constants/university/universityManagmentMaster";

// universityAddSumitAction
export const universityMasterAddAction = (university, name, file) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_MASTER_SUBMIT_REQUEST });

    let { data } = await axios.post(
      "/api/admin/university/addUniversityMaster",
      { university, name, file },
      config
    );

    dispatch({ type: UNIVERSITY_MASTER_SUBMIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_MASTER_SUBMIT_ERR,
      payload: error.response.data,
    });
  }
};

// universityEditAction

export const universityMasterEditAction = (
  university,
  name,
  file,
  id
) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_MASTER_EDIT_REQUEST });

    let { data } = await axios.post(
      "/api/admin/university/editUniversityMaster",
      { university, name, file, id },
      config
    );

    dispatch({ type: UNIVERSITY_MASTER_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_MASTER_EDIT_ERR,
      payload: error.response.data,
    });
  }
};

// universityAddSumitAction
export const universityMasterFindAction = () => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_MASTER_FIND_REQUEST });

    let { data } = await axios.get(
      "/api/admin/university/findUniversityMaster",
      config
    );

    dispatch({ type: UNIVERSITY_MASTER_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_MASTER_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// findMasterbyTypeIDAction
export const findMasterbyTypeIDAction = (id) => async (dispatch, getState) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_MASTER_BY_TYPE_ID_FIND_REQUEST });

    let { data } = await axios.get(
      `/api/admin/university/findMasterbyTypeId?id=${id}`,
      config
    );

    dispatch({
      type: UNIVERSITY_MASTER_BY_TYPE_ID_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_MASTER_BY_TYPE_ID_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// deleteUniversityMasterAction
export const deleteUniversityMasterAction = (id) => async (
  dispatch,
  getState
) => {
  try {
    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    dispatch({ type: UNIVERSITY_MASTER_SUB_DELETE_REQUEST });

    let { data } = await axios.delete(
      `/api/admin/university/deleteMasterbyTypeSubId?id=${id}`,
      config
    );

    dispatch({
      type: UNIVERSITY_MASTER_SUB_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UNIVERSITY_MASTER_SUB_DELETE_ERR,
      payload: error.response.data,
    });
  }
};
