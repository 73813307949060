import React from "react";
import BasicTabs from "../Tabs/TabsMain";
import { MainContainer } from "../content/content.element";
import SchedulesMain from "./Schedules/SchedulesMain";
import { Box } from "@mui/material";
import AllTicketsMain from "./AllTickets/AllTicketsMain";
import MySlotsMain from "./Myslots/slotMain";

function TicketsMain(props) {
  return (
    <MainContainer active={props.toggle}>
      <Box
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: "1px solid rgba(236, 236, 236, 1)",
          },
        }}
      >
        <BasicTabs
          // tab1Name="All Tickets"
          tab1Name="Schedules"
          tab2Name="Slots"
          // tab1={<AllTicketsMain />}
          tab1={<SchedulesMain />}
          tab2={<MySlotsMain />}
        />
      </Box>
    </MainContainer>
  );
}

export default TicketsMain;
