import React from 'react'
import { MainContainer } from '../../content/content.element';
import { InfoHeader } from '../../customComponent/InfoHeader';
import { Box } from '@mui/material';
import BasicTabs from '../../Tabs/TabsMain';
import IeltsTeacherDashboardSingleView from './IeltsTeacherDashboardSingleView';
import IeltsTeacherCourseView from './IeltsTeacherCourseView';

function IeltsTeacherDashboardDetailedView({ toggle }) {
  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle={"Details"} url="/ielts/teacherdashboard/assignedstudents/" />
      <Box sx={{}}>
        <BasicTabs
          tab1Name={"Student Details"}
          tab2Name={"Courses"}
          tab1={<IeltsTeacherDashboardSingleView />}
          tab2={<IeltsTeacherCourseView />}
        />
      </Box>
    </MainContainer>
  );
}

export default IeltsTeacherDashboardDetailedView;