import { Alert, AlertTitle, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { employeeIncentiveFindAction } from "../../../actions/IncentiveCategoryActions";
import { SubmitButton } from "../../customComponent/Buttons";
import { EmployeeIncentiveTable } from "./EmployeeIncentiveTable";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function EmployeeIncentiveMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allEmployeeIncentive, setAllEmployeeIncentive] = useState([]);
  const [data, setData] = useState([]);
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };
  let { employeeIncentiveCreateSuccess } = useSelector((state) => {
    return state.employeeIncentiveCreate;
  });

  let { employeeIncentiveFindSuccess } = useSelector((state) => {
    return state.employeeIncentiveFind;
  });

  let { partnerCommissionDeleteSuccess } = useSelector((state) => {
    return state.partnerCommissionDelete;
  });

  useEffect(() => {
    dispatch(employeeIncentiveFindAction());
  }, [dispatch, employeeIncentiveCreateSuccess]);

  useEffect(() => {
    if (employeeIncentiveFindSuccess) {
      setAllEmployeeIncentive(employeeIncentiveFindSuccess);
    }
  }, [employeeIncentiveFindSuccess]);

  useEffect(() => {
    // material table data & column
    setData(
      allEmployeeIncentive?.map((incentive, index) => ({
        sl: index + 1,
        id: incentive.id,
        column1: incentive?.role!== null?incentive?.role?.roleName:"-",
        column2: incentive?.branch_master?.branchName,
      }))
    );
  }, [allEmployeeIncentive]);

  // dialogue handle
  const handleClickOpen = () => {
    if (isCreatePrevilage("employee incentive")) {
      navigate("createEmployeeIncentive");
    } else {
      setOpenAlert(true);
    }
  };

  console.log(allEmployeeIncentive,"allEmployeeIncentiveallEmployeeIncentive")

  // table
  const columns = [
    {
      field: "slno",
      title: "SL NO",
    },
    {
      field: "Role",
      title: "Role Name",
    },
    {
      field: "Branch",
      title: "Branch",
    },
    {
      field: "action",
      title: "",
    },
  ];

  if (!hasPrivilege("employee incentive") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SubmitButton
              title="Create Category"
              submit="click"
              widthSize="170px"
              heightSize="40px"
              type="click"
              handleSubmit={handleClickOpen}
            />
          </div>
        </Grid>

        <Grid xs={12} mt={2}>
          <EmployeeIncentiveTable
            columns={columns}
            data={data}
            incentiveData={employeeIncentiveFindSuccess}
            component="employeeIncentive"
            subMenu="employee incentive"
          />
        </Grid>
      </Grid>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default EmployeeIncentiveMain;
