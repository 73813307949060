import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  followUpActivitiesLinkAddAction,
  followUpActivitiesLinkFindAction,
} from "../../../../actions/leadActions/leadFollowUpActions";
import { OutLinedButton } from "../../../customComponent/Buttons";
import InputField from "../../../customComponent/InputField";

function FollowLinks() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [link, setLink] = useState("");
  const [linkData, setLinkData] = useState([]);

  let {
    leadFollowUpLinkAddLoading,
    leadFollowUpLinkAddSuccess,
    leadFollowUpLinkAddError,
  } = useSelector((state) => {
    return state.followUpActivitiesLinkAdd;
  });

  let {
    leadFollowUpLinkFindLoading,
    leadFollowUpLinkFindSuccess,
    leadFollowUpLinkFindError,
  } = useSelector((state) => {
    return state.followUpActivitiesNormalLinkFind;
  });

  useEffect(() => {
    dispatch(followUpActivitiesLinkFindAction(id));
  }, [leadFollowUpLinkAddSuccess, id, dispatch]);

  useEffect(() => {
    if (leadFollowUpLinkFindSuccess) {
      setLinkData(leadFollowUpLinkFindSuccess);
    }
  }, [leadFollowUpLinkFindSuccess]);
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          marginLeft: "10px",
          height: "auto",
          border: "1px solid #ececec",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            fontFamily: "'Montserrat', sans-serif",
            m: 2,
          }}
        >
          Links
        </Typography>

        <Box m={3}>
          <InputField
            //   label="Visa applied date"
            widthSize="99%"
            handleChange={(e) => {
              setLink(e.target.value);
            }}
            value={link}
            InputType="text"
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <OutLinedButton
              title="Add link"
              handleClick={() => {
                dispatch(followUpActivitiesLinkAddAction(link, id, "note"));
                setLink("");
              }}
              widthSize="full"
              heightSize="auto"
            />
          </div>
        </Box>
        {linkData &&
          linkData.map((data) => (
            <Box sx={{ backgroundColor: "#f9f9f9", m: 2 }}>
              <Typography
                component="span"
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#bfc3d3",
                  m: 3,
                }}
              >
                {" "}
                {data.createdAt
                  ? format(
                      new Date(data?.createdAt.split("T")[0]),
                      "dd / MM / yyyy"
                    )
                  : null}
              </Typography>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Typography
                component="span"
                sx={{ fontSize: "12px", fontWeight: 500, color: "#bfc3d3" }}
              >
                {" "}
                {data.createdAt
                  ? format(parseISO(data.createdAt), "hh:mm a")
                  : null}
              </Typography>
              <Typography sx={{ fontSize: "15px", fontWeight: 500, m: 2.9 }}>
                <a href={data.link} target="blank">
                  {data.link}
                </a>
              </Typography>
            </Box>
          ))}
      </Paper>
    </>
  );
}

export default FollowLinks;
