import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export const InfoHeader = ({ headTitle, url }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "auto",
          height: "50px",
          display: "flex",
          alignItems: "center",
          // paddingLeft: "16px",
          backgroundColor: "white",
          borderRadius: "5px",
          m: 1,
          ml: 0,
        }}
      >
        <BiLeftArrowAlt
          style={{
            fontSize: "600px",
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(url);
            if (headTitle === "Add University" ||headTitle === "Edit University") {
              localStorage.removeItem("universityName");
              localStorage.removeItem("country");
              localStorage.removeItem("city");
              localStorage.removeItem("state");
              localStorage.removeItem("ranking");
              localStorage.removeItem("rank");
              localStorage.removeItem("affiliation");
              localStorage.removeItem("facilities");
              localStorage.removeItem("types");
              localStorage.removeItem("establishedYear");
              localStorage.removeItem("universityAbout");
              localStorage.removeItem("selectedFiles");
              localStorage.removeItem("internationalFee");
              localStorage.removeItem("internationalStudent");
              localStorage.removeItem("qualification");
              localStorage.removeItem("test");
              localStorage.removeItem("workExperience");
              localStorage.removeItem("remark");
              localStorage.removeItem("documentRequired");
              localStorage.removeItem("isTopUniversity");
              localStorage.removeItem("website");
            }
          }}
        />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            ml: 1,
            fontSize: "22px",
            textTransform: "capitalize",
          }}
        >
          {headTitle}
        </Typography>
      </Box>
      <Divider />
    </>
  );
};
