// SceduleMeeting find action

import axios from "axios";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";
import {
  SCEDULEMEETING_FIND_ERR,
  SCEDULEMEETING_FIND_REQUEST,
  SCEDULEMEETING_FIND_SUCCESS,
  SCEDULEMEETING_STATUSCHANGE_ERR,
  SCEDULEMEETING_STATUSCHANGE_REQUEST,
  SCEDULEMEETING_STATUSCHANGE_SUCCESS,
  SLOT_CREATE_ERR,
  SLOT_CREATE_REQUEST,
  SLOT_CREATE_SUCCESS,
  SLOT_FIND_ERR,
  SLOT_FIND_REQUEST,
  SLOT_FIND_SUCCESS,
  SLOT_STATUS_CHANGE_FIND_ERR,
  SLOT_STATUS_CHANGE_FIND_REQUEST,
  SLOT_STATUS_CHANGE_FIND_SUCCESS,
} from "../../constants/ticket/sceduleMeetingConstants";

export const sceduleMeetingFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SCEDULEMEETING_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let id = isUserExist.UserId;


    let { data } = await axios.get(
      `/api/admin/tickets/sceduledMeetings?id=${id}`,
      config
    );

    dispatch({ type: SCEDULEMEETING_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SCEDULEMEETING_FIND_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// Scedule a Meeting manage slot

export const sceduleMeetingManagSlotAction = (formData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SLOT_CREATE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    console.log(config, "configgggg");

    let { data } = await axios.post(
      "/api/admin/tickets/manageSlot",
      formData,
      config
    );

    dispatch({ type: SLOT_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SLOT_CREATE_ERR, payload: error.response.data });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// Scedule a Meeting statuChange

export const sceduleMeetingStatusChangeAction = (id, status) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SCEDULEMEETING_STATUSCHANGE_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    console.log(config, "configgggg");

    let { data } = await axios.post(
      "/api/admin/tickets/sceduledMeetingStatusChange",
      {
        id,
        status,
      },
      config
    );

    dispatch({ type: SCEDULEMEETING_STATUSCHANGE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SCEDULEMEETING_STATUSCHANGE_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};

// SLOT FIND ACTION

export const slotFindAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SLOT_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let id = isUserExist.UserId;

    // console.log(config, "configgggg");

    let { data } = await axios.get(
      `/api/admin/tickets/slotsFind?id=${id}`,
      config
    );

    dispatch({ type: SLOT_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SLOT_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};


// Scedule a Meeting statuChange

export const slotStatusChangeAction = (id, status) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: SLOT_STATUS_CHANGE_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    console.log(config, "configgggg");

    let { data } = await axios.post(
      "/api/admin/tickets/slotStatusChange",
      {
        id,
        status,
      },
      config
    );

    dispatch({ type: SLOT_STATUS_CHANGE_FIND_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SLOT_STATUS_CHANGE_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};