import React, { useState } from 'react'
import { OutLinedButton, SubmitButton } from "../../customComponent/Buttons";
import { AiOutlineDownload } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { RiSearch2Line } from "react-icons/ri";
import {
  Box,
  Checkbox,
  Grid,
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));


function ScheduleHeader({ searchHandleChange,pdfDownloadClick,excelDownloadClick }) {

  const classes = useStyles();
  const navigate = useNavigate();



//   const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   navigate("manage-slot");
  // };


  // button Export

  const [exportShow, setExportShow] = React.useState(null);
  const openExport = Boolean(exportShow);

  const handleClickExport = (event) => {
    setExportShow(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportShow(null);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "5px",
              width: "auto",
              fontSize: "8px",
            }}
          ></div>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid>
              <div className={classes.searchContainer} style={{marginBottom:"0"}}>
                <TextField
                  className={classes.searchInput}
                  variant="outlined"
                  placeholder="Search Here"
                  sx={{
                    "& .MuiInputBase-input": {
                      borderRadius: "20px",
                      height: "1px",
                    },
                    "& .MuiFormLabel-root": {
                      lineHeight: "5px",
                    },

                    "& .MuiInputBase-root": {
                      borderRadius: "3px",
                      borderColor: "#fafafa",
                    },
                  }}
                  onChange={(e) => searchHandleChange(e)}
                />
                <>
                  <Box
                    sx={{
                      width: "40px",
                      height: "16px",
                      pt: 1.9,
                      textAlign: "center",
                      border: "1px solid #9d9fa1",
                      borderBottomRightRadius: "5px",
                      WebkitBorderTopRightRadius: "5px",
                      fontSize: "24px",
                    }}
                  >
                    <InputAdornment position="end">
                      <RiSearch2Line />
                    </InputAdornment>
                  </Box>
                </>
              </div>
            </Grid>
            {/* <Grid
              sx={{
                margin: 1,
              }}
            >
              <OutLinedButton
                id="demo-positioned-button"
                // aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
                title="Manage Slot"
                handleClick={handleClick}
                // widthSize="182px"
                heightSize="30px"
              />
            </Grid> */}

            {/* export Button */}

            <Grid sx={{ margin: 1 }}>
              <OutLinedButton
                title="Export"
                handleClick={handleClickExport}
                widthSize="auto"
                heightSize="30px"
              />

              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={exportShow}
                open={openExport}
                onClose={handleCloseExport}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
              >
                <MenuItem onClick={pdfDownloadClick}>
                  <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                    <AiOutlineDownload
                      style={{ color: "#141E3C", fontSize: "24px" }}
                    />
                  </Icon>
                  Pdf
                </MenuItem>
                <MenuItem onClick={excelDownloadClick}>
                  <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                    <SiMicrosoftexcel
                      style={{ color: "#141E3C", fontSize: "24px" }}
                    />
                  </Icon>
                  Excel
                </MenuItem>
              </Menu>
            </Grid>
            {/* Submit Button */}

            {/* Submit Button */}
            <Grid sx={{ margin: 1 }}>
              <SubmitButton
                title="Add"
                submit=""
                widthSize="75px"
                heightSize="30px"
                type="click"
                handleSubmit={() =>
                  navigate("/ielts/teacherManagment/addTeacher")
                }
              />
            </Grid>

          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScheduleHeader