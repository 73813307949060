import React from 'react'
import EmployeeHolidayTable from './EmployeeHolidayTable'

function EmployeeHoliday() {
  return (
    <div>
        <EmployeeHolidayTable/>
    </div>
  )
}

export default EmployeeHoliday