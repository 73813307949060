import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Typography,
} from "@mui/material";


function ReferedStudentTable({ data }) {
  const columns = [
    {
      title: "SL NO",
      field: "sl",
    },
    {
      title: "student name",
      field: "name",
    },
    {
      title: "email",
      field: "email",
    },
    {
      title: "Phone number",
      field: "PhoneNumber",
    },
    {
      title: "Country of residence",
      field: "CountryOfResidence",
    },
    {
      title: "Application Status",
      field: "ApplicationStatus",
    },
    {
      title: "Date",
      field: "date",
    },
  ];

  // const data = [
  //   {
  //     name: "Shabeer Muhammed",
  //     email: "shabeer. muhammed@gmail.com",
  //     phone: "977584421",
  //     country: "Scotland",
  //     applicationStatus: "Enrolled",
  //     date: "15 / 05 / 2023",
  //   },
  //   {
  //     name: "Shabeer Muhammed",
  //     email: "shabeer. muhammed@gmail.com",
  //     phone: "977584421",
  //     country: "Scotland",
  //     applicationStatus: "Enrolled",
  //     date: "15 / 05 / 2023",
  //   },
  //   {
  //     name: "Shabeer Muhammed",
  //     email: "shabeer. muhammed@gmail.com",
  //     phone: "977584421",
  //     country: "Scotland",
  //     applicationStatus: "Enrolled",
  //     date: "15 / 05 / 2023",
  //   },
  //   {
  //     name: "Shabeer Muhammed",
  //     email: "shabeer. muhammed@gmail.com",
  //     phone: "977584421",
  //     country: "Scotland",
  //     applicationStatus: "Enrolled",
  //     date: "15 / 05 / 2023",
  //   },
  // ];

  const convertToDate = (date) => {
    let timestamp = new Date(date);
    return timestamp.toLocaleDateString();
  };

  return (
    <div
      style={{
        overflowX: "scroll",
        border: "0px solid #ffff",
      }}
      className="scrollBar"
    >
      <Table className="EmployeeJobTable">
        <TableHead>
          <TableRow className="EmployeeJobTableCell">
            {/* <TableCell
              style={{
                position: "sticky",
                left: 0,
                zIndex: 1,
                padding: "1px",
                border: "1px solid #ddd",
                backgroundColor: "#fafafa",
              }}
            >
              <Checkbox
                //   checked={selectedRows?.length === sortedData?.length}
                //   onChange={handleSelectAllRows}
                style={{ color: "#f81b82" }}
              /> 
            </TableCell>*/}
            {columns?.map((column) => {
              return (
                <TableCell
                  key={column.field}
                  className="EmployeeJobTableCell LeadCommon"
                  style={{ padding: "10px 16px", whiteSpace: "nowrap" }}
                >
                  <TableSortLabel
                    className="LeadHeaderText"
                    //   active={column === sortBy}
                    //   direction={sortOrder}
                    //   onClick={() => requestSort(column)}
                    //   IconComponent={column.field !== "action" && CustomSortIcon}
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "700",
                      color: "#666E7D",
                    }}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              );
            })}
            {/* <TableCell></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, indx) => (
            <TableRow className="EmployeeJobTable" key={indx}>
              {/* <TableCell
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 1, // To ensure it's above other cells
                  backgroundColor: "white", // Adjust as needed
                  padding: "1px",
                  border: "1px solid #ddd",
                }}
              >
                <Checkbox
                  // checked={selectedRows.includes(item.id)}
                  // onChange={() => handleSelectRow(item.id)}
                  style={{ color: "#f81b82" }}
                />
              </TableCell> */}
              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {indx + 1}
              </TableCell>
              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {item?.lead?.firstName}
              </TableCell>

              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {item?.lead?.email}
              </TableCell>

              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {item?.lead?.countryId + " " + item?.lead?.mobileNumber}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </TableCell>

              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {item?.lead?.livingCountry}
              </TableCell>

              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {item?.lead?.LeadAppliedShortList?.methodStatus ? (
                  item?.lead?.LeadAppliedShortList?.methodStatus
                ) : (
                  <Typography
                    sx={{
                      padding: "4px 10px",
                      bgcolor: "#F9F9FB",
                      color:"#FF2D2E",
                      borderRadius:"3px",
                      fontSize:"12px"
                    }}
                  >
                    Not Started
                  </Typography>
                )}
              </TableCell>

              <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {convertToDate(item.updatedAt)}
              </TableCell>

              {/* <TableCell style={{ padding: "10px 16px", whiteSpace: "nowrap" }}>
                {item.status}
              </TableCell> */}

              {/* <TableCell style={{ padding: "0 16px", whiteSpace: "nowrap" }}> */}
              {/* <SlOptionsVertical
                  id="demo-positioned-button"
                  // aria-controls={
                  //   leadOpenActionButton ? "demo-positioned-menu" : undefined
                  // }
                  aria-haspopup="true"
                  // aria-expanded={leadOpenActionButton ? "true" : undefined}
                  // onClick={(e) => {
                  //   handleClickAction(e, item.id);
                  // }}
                  variant="outlined"
                  style={{ marginLeft: "10px", color: "#F80B7A" }}
                  sx={{
                    backgroundColor: "#141E3C",
                    size: "10px",
                    padding: 1,
                    margin: 3,
                    color: "#fff",
                    width: "75px",
                    height: "40px",
                  }}
                /> */}
              {/* TABLE ACTION MENU START*/}
              {/* <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  // open={leadActionButtonShow}
                  // onClose={handleCloseAction}
                  // anchorEl={leadActionButtonShow}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom", // Change from "top" to "bottom"
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top", // Change from "top" to "bottom"
                    horizontal: "left",
                  }}
                  sx={{
                    borderColor: "black",
                    borderRadius: "24px",
                    mt: 1.5,
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MenuItem>
                      <AiOutlineEye
                        style={{
                          fontSize: "lg",
                          width: "20px",
                          height: "19px",
                        }}
                        //   onClick={() =>
                        //     navigate(`/ielts/teacherManagment/view/${tableId}`)
                        //   }
                      />
                    </MenuItem>
                    <MenuItem>
                      <TbEdit
                        style={{
                          fontSize: "lg",
                          width: "20px",
                          height: "19px",
                        }}
                        //   onClick={() =>
                        //     navigate(
                        //       `/ielts/teacherManagment/editTeacher/${tableId}`
                        //     )
                        //   }
                        className="icons"
                      />
                    </MenuItem>
                  </Box>
                </Menu>{" "} */}
              {/* </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter />
      </Table>
    </div>
  );
}

export default ReferedStudentTable;
