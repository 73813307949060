import React, { useEffect, useState } from "react";
import { MainContainer } from "../content/content.element";
import { useNavigate } from "react-router-dom";
import { InfoHeader } from "../customComponent/InfoHeader";
import { Divider, Grid } from "@mui/material";
import { Editor, EditorState, RichUtils } from "draft-js";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { stateToHTML } from "draft-js-export-html";
import { SubmitButton } from "../customComponent/Buttons";
import { hrEmployeeJobCreateAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../customComponent/InputField";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../actions/globalAction";

function EmployeeJobCreateForm(props) {
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const contentState = editorState.getCurrentContent();
  let htmlContent = stateToHTML(contentState);

  const [selectedStyle, setSelectedStyle] = useState("NORMAL");
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);
  const [bgColor, setBgColor] = useState("transparent");
  const [textColor, setTextColor] = useState("black");
  const [JobTitle, setJobtitle] = useState("");
  const [JobLocation, setJoblocation] = useState("");
  const [JobType, setJobType] = useState("");
  const [Experience, setExperience] = useState("");
  const [Vacancies, setVacancies] = useState("");
  const [error, setError] = useState({});

  let {
    hrEmployeeJobCreateLoading,
    hrEmployeeJobCreateSuccess,
    hrEmployeeJobCreateErr,
  } = useSelector((state) => {
    return state.hrEmployeeJobCreate;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    if (successAlertMsgSuccess) {
      Swal.fire("Good job!", `Job added Successfully`, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [hrEmployeeJobCreateSuccess, successAlertMsgSuccess, dispatch]);

  // text editor section

  const handleStyleChange = (e) => {
    const newStyle = e.target.value;

    // Toggle the new style immediately

    const newEditorState = RichUtils.toggleInlineStyle(editorState, newStyle);

    setSelectedStyle(newStyle);
    setEditorState(newEditorState);
  };

  // Handle_createJob_Button

  const validate = () => {
    let newError = {};

    if (JobTitle === "") {
      newError.JobTitle = "Please enter a title";
    }
    if (JobLocation === "") {
      newError.JobLocation = "Please enter a location";
    }
    if (JobType === "") {
      newError.JobType = "Please enter a type";
    }
    if (Experience === "") {
      newError.Experience = "Please enter a experience";
    }
    if (Vacancies === "") {
      newError.Vacancies = "Please enter a vacancies";
    }
    // if(htmlContent === ""){
    //   newError.htmlContent === "Please enter a "
    // }

    setError(newError);
    return Object.keys(newError).length === 0;
  };

  const HandleCreate = () => {
    if (validate()) {
      dispatch(
        hrEmployeeJobCreateAction(
          JobTitle,
          JobLocation,
          JobType,
          Experience,
          Vacancies,
          htmlContent
        )
      );
      setJobtitle("");
      setJoblocation("");
      setJobType("");
      setExperience("");
      setVacancies("");
      setEditorState(EditorState.createEmpty());
    }
  };

  const applyStyle = () => {
    switch (selectedStyle) {
      case "BOLD":
        return "BOLD";
      case "ITALIC":
        return "ITALIC";
      case "UNDERLINE":
        return "UNDERLINE";
      default:
        return "";
    }
  };

  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
  };

  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
  };

  const handleStrikethroughClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
  };

  const handleBgColorChange = (color) => {
    setBgColor(color);
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        `BG_COLOR_${color.toUpperCase()}`
      )
    );
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        `TEXT_COLOR_${color.toUpperCase()}`
      )
    );
  };
  const handleTextAlignment = (alignment) => {
    setEditorState(
      RichUtils.toggleBlockType(editorState, `ALIGN_${alignment.toUpperCase()}`)
    );
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  console.log(JobTitle, htmlContent, "***********************************");
  return (
    <MainContainer active={props.toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          backgroundColor: "white",
        }}
      >
        <InfoHeader headTitle="Create Job" url={"/hrmanagment"} />
      </div>
      <Divider />

      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} lg={6}>
          <InputField
            label="Job Title"
            handleChange={(e) => {
              setJobtitle(e.target.value);
              setError((prev) => ({
                ...prev,
                ["JobTitle"]: "",
              }));
            }}
            widthSize="97%"
            value={JobTitle}
            required
            paramses
            helperText={error.JobTitle}
            error={Boolean(error.JobTitle)}
          />
        </Grid>
        <Grid item xs={12} lg={6} style={{}}>
          <InputField
            label="Job Location"
            handleChange={(e) => {
              setJoblocation(e.target.value);
              setError((prev) => ({
                ...prev,
                ["JobLocation"]: "",
              }));
            }}
            widthSize="97%"
            value={JobLocation}
            required
            paramses
            helperText={error.JobLocation}
            error={Boolean(error.JobLocation)}
          />
        </Grid>

        <Grid container sx={{ mt: 5 }}>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Job Type"
              handleChange={(e) => {
                setJobType(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["JobType"]: "",
                }));
              }}
              widthSize="95%"
              value={JobType}
              required
              paramses
              helperText={error.JobType}
              error={Boolean(error.JobType)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Experience"
              handleChange={(e) => {
                setExperience(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["Experience"]: "",
                }));
              }}
              widthSize="95%"
              value={Experience}
              required
              paramses
              helperText={error.Experience}
              error={Boolean(error.Experience)}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <InputField
              label="Vacancies"
              handleChange={(e) => {
                setVacancies(e.target.value);
                setError((prev) => ({
                  ...prev,
                  ["Vacancies"]: "",
                }));
              }}
              widthSize="95%"
              value={Vacancies}
              required
              paramses
              helperText={error.Vacancies}
              error={Boolean(error.Vacancies)}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <div>
              <ToggleButtonGroup
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <select value={selectedStyle} onChange={handleStyleChange}>
                  <option value="NORMAL">Normal</option>
                  <option value="BOLD">Bold</option>
                  <option value="ITALIC">Italic</option>
                  <option value="UNDERLINE">Underline</option>
                </select>
                <FormatBoldIcon
                  onClick={handleBoldClick}
                  value="bold"
                  aria-label="bold"
                />

                <FormatItalicIcon
                  onClick={handleItalicClick}
                  value="italic"
                  aria-label="italic"
                />

                <FormatUnderlinedIcon
                  onClick={handleUnderlineClick}
                  value="underlined"
                  aria-label="underlined"
                />

                <StrikethroughSIcon
                  onClick={handleStrikethroughClick}
                  value="strikethrough"
                  aria-label="strikethrough"
                />
                <FormatColorTextIcon
                  onClick={() => handleBgColorChange("yellow")}
                  value="backgroundcolor"
                  aria-label="backgroundcolor"
                />

                <InvertColorsIcon
                  onClick={() => handleTextColorChange("blue")}
                  value="textcolor"
                  aria-label="textcolor"
                />

                <FormatAlignLeftIcon
                  onClick={() => handleTextAlignment("left")}
                  value="textcolor"
                  aria-label="textcolor"
                />
              </ToggleButtonGroup>
            </div>
            <div
              style={{
                border: "1px solid #ececec",
                padding: "10px",
                minHeight: "200px",
                marginTop: "10px",
              }}
            >
              <Editor
                editorState={editorState}
                onChange={setEditorState}
                placeholder="Description"
                customStyleMap={{
                  // Define custom inline styles for text and background colors
                  TEXT_COLOR_RED: { color: "red" },
                  TEXT_COLOR_BLUE: { color: "blue" },
                  BG_COLOR_YELLOW: { backgroundColor: "yellow" },
                  BG_COLOR_GREEN: { backgroundColor: "green" },
                  ALIGN_LEFT: { textAlign: "left" },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* Submit Button */}
      <Grid
        item
        xs={4}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <SubmitButton
          title="Save"
          submit=""
          widthSize="100px"
          heightSize="38px"
          type="click"
          handleSubmit={HandleCreate}
        />
      </Grid>
    </MainContainer>
  );
}

export default EmployeeJobCreateForm;
