import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Alert, AlertTitle, Button, Chip, Grid } from "@mui/material";
import { SubmitButton } from "../../customComponent/Buttons";
import { useNavigate } from "react-router-dom";
import ExamTipsMain from "./examTips/ExamTipsMain";
import QuestionBankMain from "./questionBank/QuestionBankMain";
import IeltsCoursesMain from "./courses/IeltsCoursesMain";
import IeltsLiveClassMain from "./liveClass/IeltsLiveClassMain";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white",
    },
  },
}));

function IeltsCourseManagmentMain({ masterPageHandler }) {
  const navigate = useNavigate();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const classes = useStyles();
  const [clicked, setClicked] = useState("Exam Tips");
  const [addFormCountry, setAddFormCountry] = useState(false);
  const [addFormState, setAddFormState] = useState(false);
  const [addFormCity, setAddFormCity] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClick = (master) => {
    setClicked(master);
  };

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleModalClick = () => {
    if (clicked === "Categories") {
      setAddFormCountry(true);
    }

    if (clicked === "Topics") {
      setAddFormState(true);
    }
  };

  if (!hasPrivilege("course managment") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Exam Tips" ? "clicked" : ""
            }`}
            label="Exam Tips"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Exam Tips")}
          />

          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Question Bank" ? "clicked" : ""
            }`}
            label="Question Bank"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Question Bank")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Course" ? "clicked" : ""
            }`}
            label="Course"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Course")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "Live Class" ? "clicked" : ""
            }`}
            label="Live Class"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("Live Class")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Add New"
            submit=""
            widthSize="123px"
            heightSize="38px"
            type="click"
            handleSubmit={() => {
              if (isCreatePrevilage("course managment")) {
                navigate(
                  clicked == "Exam Tips"
                    ? "/ielts/examtips/addnew/"
                    : clicked == "Question Bank"
                    ? "/ielts/questionbank/addnew/"
                    : clicked == "Course"
                    ? "/ielts/courses/addnew/"
                    : clicked == "Live Class"
                    ? "/ielts/liveclass/addnew/"
                    : ""
                );
              } else {
                setOpenAlert(true);
              }
            }}
          />
        </Grid>
      </Grid>

      {clicked === "Exam Tips" && <ExamTipsMain subMenu="course managment" />}
      {clicked === "Question Bank" && (
        <QuestionBankMain subMenu="course managment" />
      )}
      {clicked === "Course" && <IeltsCoursesMain subMenu="course managment" />}
      {clicked === "Live Class" && (
        <IeltsLiveClassMain subMenu="course managment" />
      )}

      {/* {clicked === "state" && (
        <StateMaster
          addFormState={addFormState}
          stateFormStatusHandler={stateFormStatusHandler}
        />
      )}

      {clicked === "city" && (
        <CityMaster
          addFormCity={addFormCity}
          cityFormStatusHandler={cityFormStatusHandler}
        />
      )}  */}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IeltsCourseManagmentMain;
