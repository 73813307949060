import {
  Divider,
  Paper,
  Typography,
  Button,
  TextField,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  countryDocumentGuidanceApplicationAddAction,
  countryDocumentGuidanceApplicationDeleteAction,
  countryDocumentGuidanceApplicationFindAction,
} from "../../actions/knowledgeBankActions/CountryDocsActions";
import { useDispatch, useSelector } from "react-redux";

import Swal from "sweetalert2";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";

import InputField from "../customComponent/InputField.js";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck.js";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function CountryDocumentsGuidance({ docId, country }) {
  const dispatch = useDispatch();

  // Custom hook for Previlage checking

  const {
    privilege,
    hasPrivilege,
    isCreatePrevilage,
    isDeletePrevilage,
  } = usePrivilegeCheck();

  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [docName, setDocName] = useState();
  const [guideData, setGuideData] = useState([]);
  const [error, setError] = useState("");
  const [previlageAlert, setPrevilageAlert] = useState(false);

  const { countryDocGuidanceFindSuccess } = useSelector((state) => {
    return state.countryDocumentGuidanceApplicationFind;
  });

  const { countryDocGuidanceAddSuccess } = useSelector((state) => {
    return state.countryDocumentGuidanceApplicationAdd;
  });

  const { countryDocGuidanceDeleteSuccess } = useSelector((state) => {
    return state.countryDocumentGuidanceApplicationDelete;
  });

  useEffect(() => {
    dispatch(countryDocumentGuidanceApplicationFindAction(docId));
  }, [
    dispatch,
    countryDocGuidanceAddSuccess,
    countryDocGuidanceDeleteSuccess,
    docId,
  ]);

  useEffect(() => {
    if (countryDocGuidanceFindSuccess) {
      setGuideData(countryDocGuidanceFindSuccess);
    }
  }, [countryDocGuidanceFindSuccess]);
  //   popup add common sop document
  const handleClickOpen = () => {
    if (isCreatePrevilage("document upload")) {
      setOpen(true);
    } else {
      setPrevilageAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError("");
      setSelectedFile(file);

      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (docName !== "" && selectedFile !== null) {
      dispatch(
        countryDocumentGuidanceApplicationAddAction(
          docName,
          previewImage,
          docId
        )
      );
      setOpen(false);
      setDocName("");
      setSelectedFile(null);
      setPreviewImage("");
      setError("");
    } else {
      setError("Please add document");
    }
  };

  // delete document
  const handleGuidanceDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(countryDocumentGuidanceApplicationDeleteAction(id));
        Swal.fire("Deleted!", "Your file has been  deleted.", "success");
      }
    });
  };
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "auto",
          height: "53px",
          alignItems: "center",
          paddingLeft: "16px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "19px" }}>
          Guidance documents for partners
        </Typography>

        <Box sx={{ m: 1 }}>
          <OutLinedButton
            title="Add"
            handleClick={handleClickOpen}
            widthSize="72px"
            heightSize="36px"
          />
        </Box>
      </Paper>

      {guideData.length !== 0 ? (
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#fdfdfd",
            width: "216px",
            height: "180px",
            textAlign: "center",
            mt: 2,
            border: "1px solid #ECECEC",
          }}
        >
          {guideData.map((guide) => (
            <>
              <Box
                sx={{
                  backgroundColor: "#f8f8f8",
                  width: "auto",
                  cursor: "pointer",
                  height: "110px",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <TiDelete
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      handleGuidanceDelete(guide.id);
                    }}
                  />
                </Box>
                <a
                  href={guide.guidanceDocUrl}
                  download={guide.guidanceDocUrl}
                  target="blank"
                >
                  <img
                    src="/menuIcons/pdf.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "57px",
                      height: "auto",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </Box>
              <Divider />
              <Box sx={{ m: 1, height: "55px", p: "10px" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                  Travel Document
                </Typography>
              </Box>
            </>
          ))}
        </Paper>
      ) : null}

      {/* dialog sop pupup */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: "10vh", // Adjust the value as needed
            alignSelf: "flex-start",
            width: "500px",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>{"Add Guidance Documents"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ pt: "7px" }}
            >
              <InputField
                label="Guidance Name"
                handleChange={(e) => setDocName(e.target.value)}
                value={docName}
                widthSize="97%"
              />

              <Paper
                variant="outlined"
                sx={{
                  backgroundColor: "#F6F6F6",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.xlsx"
                  id="file-upload"
                  // style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                {selectedFile?.name.split(".")[1] === "pdf" && (
                  <img
                    src="/menuIcons/pdf.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "100px",
                      height: "auto",
                    }}
                  />
                )}
                {selectedFile?.name.split(".")[1] === "xlsx" && (
                  <img
                    src="/menuIcons/xls.png"
                    alt="Uploaded"
                    style={{
                      marginTop: "10px",
                      width: "100px",
                      height: "auto",
                    }}
                  />
                )}
                {selectedFile && <p>Selected file: {selectedFile.name}</p>}
              </Paper>
              {error && (
                <Typography
                  sx={{
                    color: "#c51244",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {error}
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <OutLinedButton
              title="Cancel"
              handleClick={handleClose}
              widthSize="81px"
              heightSize="35px"
            />
            <SubmitButton
              title="Add"
              submit="submit"
              widthSize="81px"
              heightSize="35px"
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog for Previlage popUp */}

      <Dialog
        open={previlageAlert}
        onClose={() => setPrevilageAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setPrevilageAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700",
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px",
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px",
          }}
        >
          Sorry, you don’t have permission to create/delete
        </Typography>
      </Dialog>
    </>
  );
}
