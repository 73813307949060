import { Typography } from "@mui/material";
import React from "react";

export const TypographyText = ({ title,variant }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        mt: "18px",
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: "Montserrat', sans-serif",
      }}
    >
      {title}
    </Typography>
  );
};

export const TypographyText1 = ({ title,variant }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        mt: "5px",
        fontWeight: 700,
        fontSize: "19px",
        fontFamily: "Montserrat', sans-serif",
      }}
    >
      {title}
    </Typography>
  );
};
