import React from 'react'

function LeadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79844 11.1977C8.5856 11.1977 8.38148 11.1132 8.23098 10.9627C8.08049 10.8122 7.99594 10.6081 7.99594 10.3952V7.99522C7.98513 7.88383 7.99774 7.7714 8.03297 7.66517C8.06819 7.55894 8.12525 7.46125 8.20047 7.37837C8.27569 7.2955 8.36742 7.22928 8.46975 7.18396C8.57209 7.13864 8.68277 7.11523 8.79469 7.11523C8.90661 7.11523 9.01729 7.13864 9.11963 7.18396C9.22196 7.22928 9.31369 7.2955 9.38891 7.37837C9.46413 7.46125 9.52118 7.55894 9.55641 7.66517C9.59163 7.7714 9.60424 7.88383 9.59344 7.99522V10.3952C9.59345 10.6068 9.50993 10.8098 9.36105 10.96C9.21217 11.1103 9.00997 11.1957 8.79844 11.1977Z"
        fill="#ACB1C6"
      />
      <path
        d="M12 11.1971C11.7872 11.1971 11.5831 11.1126 11.4326 10.9621C11.2821 10.8116 11.1975 10.6075 11.1975 10.3946V7.19962C11.2168 7.00113 11.3092 6.81692 11.4569 6.68289C11.6046 6.54886 11.7968 6.47461 11.9963 6.47461C12.1957 6.47461 12.388 6.54886 12.5356 6.68289C12.6833 6.81692 12.7758 7.00113 12.795 7.19962V10.4021C12.7931 10.6124 12.7087 10.8134 12.56 10.9621C12.4113 11.1108 12.2103 11.1952 12 11.1971Z"
        fill="#ACB1C6"
      />
      <path
        d="M15.2025 11.1976C14.9897 11.1976 14.7855 11.1131 14.635 10.9626C14.4845 10.8121 14.4 10.608 14.4 10.3951V5.60265C14.3892 5.49125 14.4018 5.37882 14.437 5.27259C14.4722 5.16636 14.5293 5.06867 14.6045 4.9858C14.6797 4.90292 14.7715 4.8367 14.8738 4.79138C14.9761 4.74607 15.0868 4.72266 15.1987 4.72266C15.3107 4.72266 15.4213 4.74607 15.5237 4.79138C15.626 4.8367 15.7177 4.90292 15.793 4.9858C15.8682 5.06867 15.9252 5.16636 15.9605 5.27259C15.9957 5.37882 16.0083 5.49125 15.9975 5.60265V10.4026C15.9955 10.6129 15.9111 10.814 15.7625 10.9626C15.6138 11.1113 15.4127 11.1957 15.2025 11.1976Z"
        fill="#ACB1C6"
      />
      <path
        d="M18.3979 24H5.6029C4.75354 24 3.93897 23.6626 3.33839 23.062C2.7378 22.4614 2.4004 21.6469 2.4004 20.7975V6.3C2.39949 5.71309 2.52807 5.1332 2.77697 4.60168C3.02588 4.07016 3.38898 3.6001 3.8404 3.225L6.6004 0.93C7.31853 0.331549 8.22309 0.00261963 9.1579 0L18.3979 0C19.2473 0 20.0618 0.337405 20.6624 0.937991C21.263 1.53858 21.6004 2.35314 21.6004 3.2025V20.7975C21.6004 21.6469 21.263 22.4614 20.6624 23.062C20.0618 23.6626 19.2473 24 18.3979 24ZM9.1579 1.5975C8.60926 1.60616 8.08004 1.80197 7.6579 2.1525L4.8604 4.455C4.58974 4.68013 4.37211 4.96222 4.22302 5.28114C4.07393 5.60006 3.99706 5.94795 3.9979 6.3V20.7975C3.99987 21.2206 4.16881 21.6258 4.46797 21.9249C4.76713 22.2241 5.17232 22.393 5.5954 22.395H18.3979C18.821 22.393 19.2262 22.2241 19.5253 21.9249C19.8245 21.6258 19.9934 21.2206 19.9954 20.7975V3.2025C19.9934 2.77942 19.8245 2.37424 19.5253 2.07508C19.2262 1.77591 18.821 1.60697 18.3979 1.605L9.1579 1.5975Z"
        fill="#ACB1C6"
      />
      <path
        d="M16.8003 19.1999H7.2003C7.08891 19.2107 6.97648 19.1981 6.87025 19.1629C6.76401 19.1276 6.66632 19.0706 6.58345 18.9954C6.50058 18.9201 6.43436 18.8284 6.38904 18.7261C6.34372 18.6237 6.32031 18.5131 6.32031 18.4011C6.32031 18.2892 6.34372 18.1785 6.38904 18.0762C6.43436 17.9739 6.50058 17.8821 6.58345 17.8069C6.66632 17.7317 6.76401 17.6746 6.87025 17.6394C6.97648 17.6042 7.08891 17.5916 7.2003 17.6024H16.8003C16.9117 17.5916 17.0241 17.6042 17.1304 17.6394C17.2366 17.6746 17.3343 17.7317 17.4172 17.8069C17.5 17.8821 17.5662 17.9739 17.6116 18.0762C17.6569 18.1785 17.6803 18.2892 17.6803 18.4011C17.6803 18.5131 17.6569 18.6237 17.6116 18.7261C17.5662 18.8284 17.5 18.9201 17.4172 18.9954C17.3343 19.0706 17.2366 19.1276 17.1304 19.1629C17.0241 19.1981 16.9117 19.2107 16.8003 19.1999Z"
        fill="#ACB1C6"
      />
      <path
        d="M16.7996 15.2028H7.19962C7.00113 15.1836 6.81692 15.0911 6.68289 14.9434C6.54886 14.7958 6.47461 14.6035 6.47461 14.4041C6.47461 14.2046 6.54886 14.0124 6.68289 13.8647C6.81692 13.717 7.00113 13.6246 7.19962 13.6053H16.7996C16.911 13.5945 17.0234 13.6071 17.1297 13.6423C17.2359 13.6776 17.3336 13.7346 17.4165 13.8098C17.4993 13.8851 17.5656 13.9768 17.6109 14.0791C17.6562 14.1815 17.6796 14.2921 17.6796 14.4041C17.6796 14.516 17.6562 14.6267 17.6109 14.729C17.5656 14.8313 17.4993 14.9231 17.4165 14.9983C17.3336 15.0735 17.2359 15.1306 17.1297 15.1658C17.0234 15.201 16.911 15.2136 16.7996 15.2028Z"
        fill="#ACB1C6"
      />
    </svg>
  );
}

export default LeadIcon