// IELTS STUDENTS ENTROLLED CONSTANTS

export const IELTS_STUDENTS_ENTROLLED_FIND_REQUEST = "IELTS_STUDENTS_ENTROLLED_FIND_REQUEST";
export const IELTS_STUDENTS_ENTROLLED_FIND_SUCCESS = "IELTS_STUDENTS_ENTROLLED_FIND_SUCCESS";
export const IELTS_STUDENTS_ENTROLLED_FIND_ERR = "IELTS_STUDENTS_ENTROLLED_FIND_ERR";

// IELTS STUDENTS STATUS CHANGE CONSTANTS

export const IELTS_STUDENTS_STATUSCHANGE_REQUEST = "IELTS_STUDENTS_STATUSCHANGE_REQUEST";
export const IELTS_STUDENTS_STATUSCHANGE_SUCCESS = "IELTS_STUDENTS_STATUSCHANGE_SUCCESS";
export const IELTS_STUDENTS_STATUSCHANGE_ERR = "IELTS_STUDENTS_STATUSCHANGE_ERR";


// IELTS STUDENTS ENTROLLED DETAILS

export const IELTS_STUDENTS_DETAILS_FIND_REQUEST = "IELTS_STUDENTS_DETAILS_FIND_REQUEST";
export const IELTS_STUDENTS_DETAILS_FIND_SUCCESS = "IELTS_STUDENTS_DETAILS_FIND_SUCCESS";
export const IELTS_STUDENTS_DETAILS_FIND_ERR = "IELTS_STUDENTS_DETAILS_FIND_ERR";


// IELTS LEARNING MANAGMENT CONSTANTS

export const IELTS_CATEGORY_FIND_REQUEST = "IELTS_CATEGORY_FIND_REQUEST";
export const IELTS_CATEGORY_FIND_SUCCESS = "IELTS_CATEGORY_FIND_SUCCESS";
export const IELTS_CATEGORY_FIND_ERR = "IELTS_CATEGORY_FIND_ERR";


// IELTS CATEGORY STATUS CHANGE CONSTANTS

export const IELTS_CATEGORY_STATUSCHANGE_REQUEST = "IELTS_CATEGORY_STATUSCHANGE_REQUEST";
export const IELTS_CATEGORY_STATUSCHANGE_SUCCESS = "IELTS_CATEGORY_STATUSCHANGE_SUCCESS";
export const IELTS_CATEGORY_STATUSCHANGE_ERR = "IELTS_CATEGORY_STATUSCHANGE_ERR";


// IELTS LEARNING MANAGMENT CREATE TOPIC CONSTANTS


export const IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_REQUEST = "IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_REQUEST";
export const IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_SUCCESS = "IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_ERR = "IELTS_LEARNINGMANAGMENT_CREATE_TOPIC_ERR";


// IELTS LEARNING MANAGMENT FIND TOPIC CONSTANTS

export const IELTS_LEARNINGMANAGMENT_FIND_TOPIC_REQUEST = "IELTS_LEARNINGMANAGMENT_FIND_TOPIC_REQUEST";
export const IELTS_LEARNINGMANAGMENT_FIND_TOPIC_SUCCESS = "IELTS_LEARNINGMANAGMENT_FIND_TOPIC_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_FIND_TOPIC_ERR = "IELTS_LEARNINGMANAGMENT_FIND_TOPIC_ERR";


// IELTS LEARNING MANAGMENT FIND TOPIC BYID CONSTANTS


export const IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_REQUEST = "IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_REQUEST";
export const IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_SUCCESS = "IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_ERR = "IELTS_LEARNINGMANAGMENT_FIND_TOPIC_BYID_ERR";



// IELTS LEARNING MANAGMENT TOPIC STATUS CHANGE CONSTANTS


export const IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_REQUEST = "IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_REQUEST";
export const IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_SUCCESS = "IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_ERR = "IELTS_LEARNINGMANAGMENT_TOPIC_STATUSCHANGE_ERR";


// IELTS LEARNING MANAGMENT TOPIC UPDATE CONSTANTS


export const IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_REQUEST = "IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_REQUEST";
export const IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_SUCCESS = "IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_ERR = "IELTS_LEARNINGMANAGMENT_TOPIC_UPDATE_ERR";



// IELTS LEARNING MANAGMENT TOPIC DELETE CONSTANTS


export const IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_REQUEST = "IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_REQUEST";
export const IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_SUCCESS = "IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_ERR = "IELTS_LEARNINGMANAGMENT_TOPIC_DELETE_ERR";



// IELTS LEARNING MANAGMENT TOPIC DELETE CONSTANTS


export const IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_REQUEST = "IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_REQUEST";
export const IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_SUCCESS = "IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_SUCCESS";
export const IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_ERR = "IELTS_LEARNINGMANAGMENT_ENTIRE_TOPIC_DELETE_ERR";


