import { Button } from "@mui/base";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  partnerCommissionCreateAction,
  partnerCommissionFindAction,
} from "../../../actions/IncentiveCategoryActions";
import { SubmitButton } from "../../customComponent/Buttons";
import InputField from "../../customComponent/InputField";
import { TypographyText } from "../../customComponent/Typography";
import { IncentiveTable } from "./IncentiveTable";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";

function IncentiveCategory() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [percentage, setPercentage] = useState("");
  const [allPartnerCommission, setAllPartnerCommission] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  let { partnerCommissionCreateSuccess } = useSelector((state) => {
    return state.partnerCommissionCreate;
  });

  let { partnerCommissionFindSuccess } = useSelector((state) => {
    return state.partnerCommissionFind;
  });

  let { partnerCommissionDeleteSuccess } = useSelector((state) => {
    return state.partnerCommissionDelete;
  });

  useEffect(() => {
    dispatch(partnerCommissionFindAction());
  }, [
    partnerCommissionCreateSuccess,
    partnerCommissionDeleteSuccess,
    dispatch,
  ]);

  useEffect(() => {
    if (partnerCommissionFindSuccess) {
      setAllPartnerCommission(partnerCommissionFindSuccess);
    }
  }, [partnerCommissionFindSuccess]);

  useEffect(() => {
    // material table data & column
    setData(
      allPartnerCommission?.map((commission, index) => ({
        sl: index + 1,
        id: commission.id,
        column1: commission.category,
        column2: commission.percentage,
      }))
    );
  }, [allPartnerCommission]);

  // dialogue handle
  const handleClickOpen = () => {
    if (isCreatePrevilage("incentive category")) {
      setOpen(true);
    } else {
      setOpenAlert(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // table
  const columns = [
    {
      field: "slno",
      title: "SL NO",
    },
    {
      field: "roleName",
      title: "Role Name",
    },
    {
      field: "CommissionPercentage",
      title: "Commission Percentage",
    },
    {
      field: "action",
      title: "",
    },
  ];

  // handle submit
  const partnerCommissionhandleClick = () => {
    if (category && percentage) {
      dispatch(partnerCommissionCreateAction(category, percentage));
      setCategory("");
      setPercentage("");
      setOpen();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please fill the form",
      });
    }
  };

  const handlePercentage = (e) => {
    if (e.target.value < 100) {
      setPercentage(e.target.value);
      setError(false);
    } else {
      setError(true);
    }
  };

  if (!hasPrivilege("incentive category") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SubmitButton
              title="Create Category"
              submit="click"
              widthSize="170px"
              heightSize="40px"
              type="click"
              handleSubmit={handleClickOpen}
            />
          </div>
        </Grid>

        <Grid xs={12} mt={2}>
          <IncentiveTable
            columns={columns}
            data={data}
            component="incentive"
            subMenu="incentive category"
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            bottom: "33vh",
            width: "850px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <TypographyText title={"Create Partner Commission"} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box mt={1.5}>
              <InputField
                label="Category Name"
                handleChange={(e) => setCategory(e.target.value)}
                widthSize="100%"
                value={category}
                required
                InputType="text"
              />

              <InputField
                label="Commission Percentage"
                handleChange={handlePercentage}
                widthSize="100%"
                value={percentage}
                required
                InputType="number"
              />
              {error && (
                <Typography
                  sx={{ color: "red", fontSize: "12px", mt: "-15px" }}
                >
                  Please enter a value less than 100
                </Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            title="Create"
            submit="click"
            widthSize="90px"
            heightSize="40px"
            type="click"
            handleSubmit={partnerCommissionhandleClick}
          />
        </DialogActions>
      </Dialog>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default IncentiveCategory;
