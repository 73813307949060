import React, { useEffect, useState } from "react";

import { AiOutlineDownload, AiOutlinePlus } from "react-icons/ai";

import { SiMicrosoftexcel } from "react-icons/si";

import { RiSearch2Line } from "react-icons/ri";

import CheckIcon from "@mui/icons-material/Check";

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Typography
} from "@mui/material";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Icon,
  InputAdornment,
  ListItem,
  Menu,
  MenuItem,
  TextField
} from "@mui/material";

import { RiUserFollowLine } from "react-icons/ri";
import { BsArrowUpCircle, BsArrowRightCircle } from "react-icons/bs";
import { MdOutlineAssignmentReturned } from "react-icons/md";
import { TbArrowsExchange2 } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";

import { useNavigate } from "react-router-dom";

import { makeStyles, OutlinedInput, Paper } from "@material-ui/core";

import LeadCreatForm from "./createLeads/LeadCreatForm";
import { useDispatch, useSelector } from "react-redux";
import {
  leadBulkUploadAction,
  leadFindAction,
  leadOwnerChangeAction
} from "../../actions/leadActions/leadActions";
import { findBranchMasterAction } from "../../actions/branchMasterActions";
import {
  branchManagerFetchAction,
  findAllEmployeeAction
} from "../../actions/employeeManagmentAction";
import { OutLinedButton, SubmitButton } from "../customComponent/Buttons";
import Swal from "sweetalert2";
import { MultiSelect, SelectInputField } from "../customComponent/InputField";
import { findUserAction } from "../../actions/adminAuthAction";
import BulkUploadModal from "../university/universities/BulkUploadModal";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import { successTrueMsgAction } from "../../actions/globalAction";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function LeadersSearchHeader({
  columnVisibleHandler,
  excelDownloadClick,
  data,
  searchHandleChange,
  pdfDownloadClick,
  showNameColumn,
  showEmailColumn,
  showPrefferedColumn,
  showPhoneColumn,
  showWhatAppNumberColumn,
  showLeadSourceColumn,
  showLeadOwnerColumn,
  showLeadBranchColumn,
  showStatusColumn,
  showPriorityColumn,
  showLeadPlaceColumn,
  showReasonColumn,
  showTelecallerColumn,
  showCounsilorColumn,
  filterPassData,
  whichFilter,
  handleResetData,
  handleDeleteClick,
  ids,
  leadId,
  component,
  subMenu
}) {
  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage
  } = usePrivilegeCheck();

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [addForm, setAddForm] = React.useState(false);
  const [chipData, setChipData] = useState([]);
  const [openOwner, setOpenOwner] = useState(false);
  const [dialogModel, setDialogModel] = useState("");
  const [changeOwner, setChangeOwner] = useState("");
  const [branchManager, setBranchManager] = useState('');
  const [followUpActivities, setFollowUpActivities] = useState([]);
  const [selectionCategory, setSelectionCategory] = useState("Branch Manger");
  const [teleCallers, setTeleCallers] = useState([]);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [changeCounsilorBranch, setChangeCounsilorBranch] = useState("");
  const [error, setError] = useState({});

  let {
    branchMasterFindLoading,
    branchMasterFindSuccess,
    branchMasterFindErr
  } = useSelector((state) => {
    return state.findBranchMaster;
  });

  let { branchManagerLoading, branchManagerSuccess, branchManagerError } =
    useSelector((state) => {
      return state.branchManagerFetch;
    });

  let { findEmployeeLoading, findEmployeeSuccess, findEmployeeErr } =
    useSelector((state) => {
      return state.findAllEmployee;
    });

  let { leadFindLoading, leadFindSuccess } = useSelector((state) => {
    return state.leadFind;
  });

  useEffect(() => {
    if (findEmployeeSuccess) {
      if (loginFindSuccess?.isTelecallerManager == true) {
        setTeleCallers(
          findEmployeeSuccess?.filter(
            (item) => item.teleCaller_Manager_id == loginFindSuccess?.id
          )
        );
      } else if (loginFindSuccess?.isTeleCallerTeamLead == true) {
        setTeleCallers(
          findEmployeeSuccess?.filter(
            (item) => item.teleCallerTeamLead_ID == loginFindSuccess?.id
          )
        );
      } else {
        setTeleCallers(
          findEmployeeSuccess?.filter((item) => item.isTeleCaller)
        );
      }
    }
  }, [dispatch, findEmployeeSuccess]);

  console.log(findEmployeeSuccess, "findEmployeeSuccessfindEmployeeSuccess");

  useEffect(() => {
    dispatch(leadFindAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findBranchMasterAction());
  }, [dispatch, dialogModel]);

  useEffect(() => {
    if (whichFilter !== null && filterPassData !== null) {
      setChipData((prevChipData) => {
        const updatedChipData = prevChipData.map((chip) => {
          if (chip.label === whichFilter) {
            return {
              ...chip,
              result: filterPassData
            };
          }
          return chip;
        });

        if (!updatedChipData.some((chip) => chip.label === whichFilter)) {
          return [
            ...updatedChipData,
            { label: whichFilter, result: filterPassData }
          ];
        }

        return updatedChipData;
      });
    } else {
      setChipData([]);
    }
  }, [whichFilter, filterPassData]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStateChange = () => {
    setAddForm(false);
  };
  // button Export
  const [exportShow, setExportShow] = React.useState(null);
  const openExport = Boolean(exportShow);

  const handleClickExport = (event) => {
    setExportShow(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportShow(null);
  };

  const handleClickOpen = (dialogModelName) => {
    setDialogModel(dialogModelName);
    setOpenOwner(true);
    // setApplyData(data);
  };

  useEffect(() => {
    dispatch(findAllEmployeeAction());
  }, [dispatch]);

  const handleChange = (event) => {
    setChangeOwner(event.target.value);
    setError((prev) => ({
      ...prev,
      ["changeOwner"]: ""
    }));
    dispatch(branchManagerFetchAction(event.target.value, dialogModel));
  };

  const handleChangeCounsilorBranch = (event) => {
    setChangeCounsilorBranch(event.target.value);
    setError((prev) => ({
      ...prev,
      ["changeCounsilorBranch"]: ""
    }));
    dispatch(branchManagerFetchAction(event.target.value, dialogModel));
  };

  const handleOwnerChange = (event) => {
    setBranchManager(event.target.value);
    setError((prev) => ({
      ...prev,
      ["branchManager"]: ""
    }));
  };

  const handlePopClose = () => {
    setOpenOwner(false);
    setChangeOwner("");
    setChangeCounsilorBranch("");
  };
  // button Action
  const [actionShow, setActionShow] = React.useState(null);
  const openAction = Boolean(actionShow);

  const handleClickAction = (event) => {
    setActionShow(event.currentTarget);
  };
  const handleCloseAction = () => {
    setActionShow(null);
  };

  // chip
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete));
    handleResetData(true);
  };

  const validate = () => {
    let errors = {};

    if (changeOwner === "") {
      errors.changeOwner = "Status is required";
    }
    if (changeCounsilorBranch === "") {
      errors.changeCounsilorBranch = "Counsilor branch is required";
    }

    if (branchManager === "") {
      errors.branchManager = "Counsilor is required";
    }

    setError(errors);
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  const handleMethodApplyClick = () => {
    if (ids.length !== 0 || leadId.length !== 0) {
      if (dialogModel === "counsellor") {
        if (validate()) {
          dispatch(
            leadOwnerChangeAction(
              ids,
              leadId,
              changeOwner,
              branchManager,
              dialogModel,
              followUpActivities,
              "",
              "",
              "",
              "",
              component
            )
          );
          setOpenOwner(false);
        }
      } else {
        dispatch(
          leadOwnerChangeAction(
            ids,
            leadId,
            changeOwner,
            branchManager,
            dialogModel,
            followUpActivities,
            "",
            "",
            "",
            "",
            component
          )
        );
        setOpenOwner(false);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Please choose leads`
      });
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    setFollowUpActivities(newValue);
  };

  //  bulk upload popup

  const openBulkUploadModal = () => {
    setBulkUploadModalOpen(true);
  };

  // Bulk upload api call

  const handleBulkUploadSubmit = (bulkUploadValue) => {
    const formData = new FormData();
    formData.append("file", bulkUploadValue);
    dispatch(leadBulkUploadAction(formData));
    dispatch(successTrueMsgAction(true));
  };

  console.log(
    isCreatePrevilage(subMenu),
    "isCreatePrevilage(subMenu)isCreatePrevilage(subMenu)"
  );

  return (
    <>
      <Grid container spacing={2}>
        <BulkUploadModal
          open={bulkUploadModalOpen}
          handleClose={() => setBulkUploadModalOpen(false)}
          handleSubmit={handleBulkUploadSubmit}
          title="Bulk Upload leads"
          downloadurl="https://gsl-dev2.s3.ap-south-1.amazonaws.com/bulkUpload/bulkUpload752"
        />
        <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "5px",
              width: "auto",
              fontSize: "8px"
            }}
          >
            {chipData.map((data, index) => {
              return (
                <ListItem key={index} style={{ marginBottom: "2px" }}>
                  <Chip
                    size="small"
                    className="MuiChip-sizeSmall"
                    classes={{
                      iconSmall: "MuiChip-iconSmall",
                      labelSmall: "MuiChip-labelSmall"
                    }}
                    icon={`${data.label}:`}
                    onDelete={handleDelete(data.label)}
                    label={
                      <Typography variant="body2" style={{ fontSize: 12 }}>
                        <span style={{ fontWeight: 600, fontSize: 12 }}>
                          {whichFilter}:{" "}
                        </span>
                        {data.result}
                      </Typography>
                    }
                    style={{
                      fontWeight: 90,
                      fontSize: 10,
                      marginLeft: "-10px"
                    }}
                  />
                </ListItem>
              );
            })}
          </div>
        </Grid>

        <Grid item xs={9}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid>
              <div className={classes.searchContainer}>
                <TextField
                  className={classes.searchInput}
                  variant="outlined"
                  placeholder="Search Here"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    searchHandleChange(e);
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      borderRadius: "20px",
                      height: "1px",
                      paddingRight: "48px !important"
                    },
                    "& .MuiFormLabel-root": {
                      lineHeight: "5px"
                    },

                    "& .MuiInputBase-root": {
                      borderRadius: "3px",
                      borderColor: "#fafafa"
                    },
                    "& .MuiOutlinedInput-notchedOutline ": {
                      borderRadius: "3px",
                      borderColor: "#ECECEC !important",
                      borderWidth: "1px !important"
                    }
                  }}
                />
                <>
                  <InputAdornment
                    position="end"
                    sx={{
                      position: "absolute",
                      right: "8px",
                      width: "40px",
                      // top:"0",
                      // bottom:"0",
                      border: "1px solid #ECECEC",
                      borderRadius: "3px",
                      height: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer"
                    }}
                  >
                    <RiSearch2Line />
                  </InputAdornment>
                </>
              </div>
            </Grid>

            <Grid sx={{ margin: 1 }}>
              <OutLinedButton
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                title="Column visibility"
                handleClick={handleClick}
                widthSize="182px"
                heightSize="30px"
              />
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                sx={{
                  borderColor: "black",
                  borderRadius: "24px",
                  mt: 6,
                  "& ul": {
                    maxHeight: "250px",
                    overflow: "scroll"
                  },
                  "& ul::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
              >
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showNameColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "name")
                    }
                  />
                  Name
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showEmailColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "email")
                    }
                  />
                  Email
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showPhoneColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "mobile")
                    }
                  />
                  Phone
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showWhatAppNumberColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "whatappnumber")
                    }
                  />
                  WhatsApp number
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showPrefferedColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "prefferedCountry")
                    }
                  />
                  preffered country
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showLeadSourceColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "leadSource")
                    }
                  />
                  Lead source
                </MenuItem>
                {component !== "leadDeferral" && (
                  <MenuItem>
                    <Checkbox
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        }
                      }}
                      checked={showLeadOwnerColumn === true ? true : false}
                      onChange={(e) =>
                        columnVisibleHandler(e.target.checked, "leadowner")
                      }
                    />
                    Lead Owner
                  </MenuItem>
                )}
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showLeadBranchColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "leadbranch")
                    }
                  />
                  Lead Branch
                </MenuItem>
                {component !== "leadDeferral" && (
                  <MenuItem>
                    <Checkbox
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        }
                      }}
                      checked={showStatusColumn === true ? true : false}
                      onChange={(e) =>
                        columnVisibleHandler(e.target.checked, "status")
                      }
                    />
                    Status
                  </MenuItem>
                )}
                {component !== "leadDeferral" && (
                  <MenuItem>
                    <Checkbox
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        }
                      }}
                      checked={showPriorityColumn === true ? true : false}
                      onChange={(e) =>
                        columnVisibleHandler(e.target.checked, "priority")
                      }
                    />
                    Priority
                  </MenuItem>
                )}
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showLeadPlaceColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "place")
                    }
                  />
                  Lead place
                </MenuItem>
                {component === "leadDeferral" ||
                  (component == "dead" && (
                    <MenuItem>
                      <Checkbox
                        sx={{
                          color: "#F8469A",
                          "&.Mui-checked": {
                            color: "#F8469A"
                          }
                        }}
                        checked={showReasonColumn === true ? true : false}
                        onChange={(e) =>
                          columnVisibleHandler(e.target.checked, "reason")
                        }
                      />
                      Reason
                    </MenuItem>
                  ))}
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showTelecallerColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "telecaller")
                    }
                  />
                  TeleCaller
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    sx={{
                      color: "#F8469A",
                      "&.Mui-checked": {
                        color: "#F8469A"
                      }
                    }}
                    checked={showCounsilorColumn === true ? true : false}
                    onChange={(e) =>
                      columnVisibleHandler(e.target.checked, "counsilor")
                    }
                  />
                  Counsilor
                </MenuItem>
              </Menu>
            </Grid>

            {/* export Button */}

            <Grid sx={{ margin: 1 }}>
              <OutLinedButton
                title="Export"
                handleClick={handleClickExport}
                widthSize="auto"
                heightSize="30px"
              />

              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={exportShow}
                open={openExport}
                onClose={handleCloseExport}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                sx={{ borderColor: "black", borderRadius: "24px", mt: 6 }}
              >
                <MenuItem>
                  <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                    <AiOutlineDownload
                      style={{ color: "#141E3C", fontSize: "24px" }}
                      onClick={pdfDownloadClick}
                    />
                  </Icon>
                  Pdf
                </MenuItem>
                <MenuItem onClick={excelDownloadClick}>
                  <Icon fontSize="medium" color="secondary" sx={{ m: 1 }}>
                    <SiMicrosoftexcel
                      style={{ color: "#141E3C", fontSize: "24px" }}
                    />
                  </Icon>
                  Excel
                </MenuItem>
              </Menu>
            </Grid>
            {/* Action Button */}

            {/* Action Button */}
            <Grid sx={{ margin: 1 }}>
              {component == "AllLeads" && isCreatePrevilage(subMenu) ? (
                <SubmitButton
                  title="Create a New Lead"
                  submit=""
                  widthSize="160px"
                  heightSize="30px"
                  type="click"
                  handleSubmit={() => setAddForm(true)}
                />
              ) : (
                ""
              )}
            </Grid>
            {isChangeOwnerPrevilage(subMenu) ||
            isAssignCounsilorPrevilage(subMenu) ||
            isChangePriorityPrevilage(subMenu) ||
            isDeletePrevilage(subMenu) ? (
              <Grid sx={{ margin: 1 }}>
                <SubmitButton
                  title="Action"
                  submit=""
                  widthSize="75px"
                  heightSize="30px"
                  type="click"
                  handleSubmit={handleClickAction}
                />

                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={actionShow}
                  open={openAction}
                  onClose={handleCloseAction}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  sx={{
                    borderColor: "rgba(20, 30, 60, 1)",
                    borderRadius: "24px",
                    mt: 6,
                    width: "280px",
                    height: "503px"
                  }}
                >
                  {/* {component !== "dead" ? (
                  <MenuItem>
                    <Button
                      startIcon={<AiOutlinePlus />}
                      sx={{
                        backgroundColor: "#141E3C",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "rgba(20, 30, 60, 0.9)",
                        },
                      }}
                      onClick={() => setAddForm(true)}
                    >
                      Create a New Lead
                    </Button>
                  </MenuItem>
                ) : null} */}

                  {/* {component !== "dead" ? (
                  <MenuItem>
                    <Button
                      startIcon={<RiUserFollowLine />}
                      // sx={{ backgroundColor: "#141E3C" }}
                    >
                      Add Follow up
                    </Button>
                  </MenuItem>
                ) : null} */}

                  {isChangeOwnerPrevilage(subMenu) ? (
                    <MenuItem>
                      <Button
                        startIcon={<BsArrowRightCircle />}
                        onClick={() => handleClickOpen("branch manager")}
                      >
                        change owner
                      </Button>
                    </MenuItem>
                  ) : null}

                  {isAssignCounsilorPrevilage(subMenu) ? (
                    <MenuItem>
                      <Button
                        startIcon={<MdOutlineAssignmentReturned />}
                        onClick={() => handleClickOpen("counsellor")}
                      >
                        Assign Counsellor
                      </Button>
                    </MenuItem>
                  ) : null}
                  {isChangePriorityPrevilage(subMenu) ? (
                    <MenuItem>
                      <Button
                        startIcon={<BsArrowUpCircle />}
                        // sx={{ backgroundColor: "#141E3C" }}
                        onClick={() => handleClickOpen("priority")}
                      >
                        change priority
                      </Button>
                    </MenuItem>
                  ) : null}
                  {/* {isChangeStatusPrevilage(subMenu) ? (
                    <MenuItem>
                      <Button
                        startIcon={<TbArrowsExchange2 />}
                        // sx={{ backgroundColor: "#141E3C" }}
                      >
                        change status
                      </Button>
                    </MenuItem>
                  ) : null} */}

                  {isDeletePrevilage(subMenu) ? (
                    <MenuItem>
                      <Button
                        startIcon={<RiDeleteBinLine />}
                        // sx={{ backgroundColor: "#141E3C" }}
                        onClick={handleDeleteClick}
                      >
                        Delete
                      </Button>
                    </MenuItem>
                  ) : null}
                </Menu>
              </Grid>
            ) : (
              ""
            )}
            {component == "AllLeads" && isCreatePrevilage("All Leads") ? (
              <Grid sx={{ margin: 1 }}>
                <SubmitButton
                  title="Bulk Upload"
                  submit=""
                  widthSize="150px"
                  heightSize="30px"
                  type="click"
                  handleSubmit={openBulkUploadModal}
                />
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>

      {/* actions dialog */}

      {addForm && (
        <LeadCreatForm
          addForm={addForm}
          handleStateChange={handleStateChange}
        />
      )}
      <Dialog
        open={openOwner}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "start !important"
          },
          "& .MuiPaper-root": {
            bottom: "0 !important"
          }
        }}
        PaperProps={{
          sx: {
            bottom: dialogModel === "branch manager" ? "25vh" : "30vh",
            width: "850px"
          }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "22px", fontWeight: 700 }}
        >
          {dialogModel === "counsellor" && "select Counsellor"}

          {dialogModel === "branch manager" && "Change Owner"}
          {dialogModel === "priority" && "Change Priority"}
        </DialogTitle>
        {dialogModel === "branch manager" || dialogModel === "telecaller" ? (
          <>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                <SelectInputField
                  label="Choose Employee"
                  handleSelectChange={(e) => {
                    setSelectionCategory(e.target.value);
                    setDialogModel(
                      e.target.value === "Branch Manger"
                        ? "branch manager"
                        : "telecaller"
                    );
                  }}
                  widthSize="101px"
                  value={selectionCategory}
                  mapValue={["Telecaller", "Branch Manger"]}
                  mapMethod=""
                />
              </Box>

              {selectionCategory === "Branch Manger" ? (
                <>
                  <Box sx={{ mt: 2 }}>
                    <SelectInputField
                      label="Choose Branch"
                      handleSelectChange={handleChange}
                      widthSize="101px"
                      value={changeOwner}
                      mapValue={
                        branchMasterFindSuccess && branchMasterFindSuccess
                      }
                      mapMethod="branchOwner"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <SelectInputField
                      label="Choose Branch Manager"
                      handleSelectChange={handleOwnerChange}
                      widthSize="160px"
                      value={branchManager}
                      mapValue={branchManagerSuccess && branchManagerSuccess}
                      mapMethod="branchManager"
                    />
                  </Box>
                  <div style={{ display: "flex", marginTop: "5px" }}>
                    <Checkbox
                      id="full-day"
                      label="Send Email Notification To Branch Manager"
                      // checked={isFullDay}
                      // onChange={(e) => setIsFullDay(e.target.checked)}
                      sx={{
                        color: "#F8469A",
                        "&.Mui-checked": {
                          color: "#F8469A"
                        }
                      }}
                    />
                    <Typography
                      sx={{ mt: 1, fontSize: "14px", fontWeight: 400 }}
                    >
                      Send Email Notification To Branch Manager
                    </Typography>
                  </div>
                  <Box sx={{ mt: 2.5 }}>
                    <Paper
                      sx={{
                        height: "auto",
                        pt: 1,
                        pb: 1,
                        border: "2px solid #ececec"
                      }}
                      elevation={0}
                    >
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={["Open Calls", "Open Tasks", "Open Meetings"]}
                        sx={{
                          textAlign: "center",
                          // backgroundColor: "white",
                          // border: "none",
                          border: "0.1px solid #ececec",
                          borderColor: "#ececec",
                          height: "50px",
                          "&:focus": {
                            outline: "none"
                          },
                          "& fieldset": {
                            border: "none",
                            borderRadius: "4px",
                            height: "50px"
                          },
                          // height: "auto",
                          "& .MuiFormLabel-root": {
                            height: "42px",
                            mt: -2,
                            // pb:2,

                            "&.Mui-focused": {
                              color: "#7d7d7d",
                              pt: 4
                            }
                          }
                        }}
                        getOptionLabel={(option) => option}
                        value={followUpActivities}
                        onChange={handleAutocompleteChange}
                        disableCloseOnSelect
                        renderOption={(props, option, { selected }) => (
                          <MenuItem
                            key={option}
                            value={option}
                            sx={{ justifyContent: "space-between" }}
                            {...props}
                          >
                            {option}
                            {selected ? <CheckIcon color="info" /> : null}
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Transfer Activity"
                          />
                        )}
                        renderTags={(value, getTagProps) => (
                          <Stack gap={1} direction="row" flexWrap="wrap">
                            {value.map((option, index) => (
                              <Chip
                                key={index}
                                label={option}
                                {...getTagProps({ index })}
                                deleteIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer"
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      const newValues = [...followUpActivities];
                                      newValues.splice(index, 1);
                                      setFollowUpActivities(newValues);
                                    }}
                                  >
                                    X
                                  </div>
                                }
                              />
                            ))}
                          </Stack>
                        )}
                      />
                    </Paper>
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ mt: 2 }}>
                    <SelectInputField
                      label="Choose TeleCaller"
                      handleSelectChange={handleOwnerChange}
                      widthSize="160px"
                      value={branchManager}
                      mapValue={teleCallers && teleCallers}
                      mapMethod="branchManager"
                    />
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <OutLinedButton
                title="Cancel"
                handleClick={handlePopClose}
                widthSize="75px"
                heightSize="30px"
              />

              <SubmitButton
                title="Submit"
                submit=""
                widthSize="75px"
                heightSize="30px"
                type="click"
                handleSubmit={handleMethodApplyClick}
              />
            </DialogActions>{" "}
          </>
        ) : null}
        {dialogModel === "counsellor" && (
          <>
            <DialogContent>
              <Box sx={{ mt: 1 }}>
                <SelectInputField
                  label=" Lead Status"
                  handleSelectChange={handleChange}
                  widthSize="80px"
                  value={changeOwner}
                  mapValue={
                    component == "deals"
                      ? [
                          "new",
                          "warm",
                          "cold",
                          "dead",
                          "future",
                          "completed",
                          "Application Started"
                        ]
                      : ["new", "warm", "cold", "dead", "future", "completed"]
                  }
                  mapMethod="statusLead"
                  requiredSymbol={true}
                />
                <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {error?.changeOwner}
                </span>
              </Box>

              <Box sx={{ mt: 2 }}>
                <SelectInputField
                  label="Choose Branch"
                  handleSelectChange={handleChangeCounsilorBranch}
                  widthSize="101px"
                  value={changeCounsilorBranch}
                  mapValue={branchMasterFindSuccess && branchMasterFindSuccess}
                  mapMethod="branchOwner"
                  requiredSymbol={true}
                />
                <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {error?.changeCounsilorBranch}
                </span>
              </Box>

              <Box sx={{ mt: 2 }}>
                <SelectInputField
                  label="Choose Counsellor"
                  handleSelectChange={(e) => handleOwnerChange(e)}
                  widthSize="125px"
                  value={branchManager}
                  mapValue={branchManagerSuccess}
                  mapMethod="branchManager"
                  requiredSymbol={true}
                />
                <span
                  style={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {error?.branchManager}
                </span>
              </Box>
              <div style={{ display: "flex", marginTop: "5px" }}>
                <Checkbox
                  id="full-day"
                  label="Send Email Notification To Counsellor"
                  sx={{
                    color: "#F8469A",
                    "&.Mui-checked": {
                      color: "#F8469A"
                    }
                  }}
                />
                <Typography sx={{ mt: 1, fontSize: "14px", fontWeight: 400 }}>
                  Send Email Notification To Counsellor
                </Typography>
              </div>

              {/* <Box sx={{ mt: 2 }}>
                <Paper
                  sx={{
                    height: "auto",
                    pt: 1,
                    pb: 1,
                    border: "2px solid #ececec",
                  }}
                  elevation={0}
                >
                 
                </Paper>
              </Box> */}

              <Box sx={{ mt: 2.5 }}>
                <Paper
                  sx={{
                    height: "auto",
                    pt: 1,
                    pb: 1,
                    border: "2px solid #ececec"
                  }}
                  elevation={0}
                >
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={["Open Calls", "Open Tasks", "Open Meetings"]}
                    sx={{
                      textAlign: "center",
                      // backgroundColor: "white",
                      // border: "none",
                      border: "0.1px solid #ececec",
                      borderColor: "#ececec",
                      height: "50px",
                      "&:focus": {
                        outline: "none"
                      },
                      "& fieldset": {
                        border: "none",
                        borderRadius: "4px",
                        height: "50px"
                      },
                      // height: "auto",
                      "& .MuiFormLabel-root": {
                        height: "42px",
                        mt: -2,
                        // pb:2,

                        "&.Mui-focused": {
                          color: "#7d7d7d",
                          pt: 4
                        }
                      }
                    }}
                    getOptionLabel={(option) => option}
                    value={followUpActivities}
                    onChange={handleAutocompleteChange}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ justifyContent: "space-between" }}
                        {...props}
                      >
                        {option}
                        {selected ? <CheckIcon color="info" /> : null}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Transfer Activity"
                      />
                    )}
                    renderTags={(value, getTagProps) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {value.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                            deleteIcon={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer"
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const newValues = [...followUpActivities];
                                  newValues.splice(index, 1);
                                  setFollowUpActivities(newValues);
                                }}
                              >
                                X
                              </div>
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  />
                </Paper>
              </Box>
            </DialogContent>
            <DialogActions>
              <OutLinedButton
                title="Cancel"
                handleClick={handlePopClose}
                widthSize="75px"
                heightSize="30px"
              />

              <SubmitButton
                title="Submit"
                submit=""
                widthSize="75px"
                heightSize="30px"
                type="click"
                handleSubmit={handleMethodApplyClick}
              />
            </DialogActions>
          </>
        )}

        {dialogModel === "priority" && (
          <>
            <DialogContent>
              <Box sx={{ mt: 1 }}>
                <SelectInputField
                  label=" Lead Priority"
                  handleSelectChange={(e) => {
                    setChangeOwner(e.target.value);
                    let manager = localStorage.getItem("loginInfo")
                      ? JSON.parse(localStorage.getItem("loginInfo"))
                      : null;
                    setBranchManager({
                      id: manager.UserId,
                      name: manager.name
                    });
                  }}
                  widthSize="85px"
                  value={changeOwner}
                  mapValue={["High", "Normal", "Low"]}
                  mapMethod="priorityLead"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <OutLinedButton
                title="Cancel"
                handleClick={handlePopClose}
                widthSize="75px"
                heightSize="30px"
              />

              <SubmitButton
                title="Submit"
                submit=""
                widthSize="75px"
                heightSize="30px"
                type="click"
                handleSubmit={handleMethodApplyClick}
              />
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default LeadersSearchHeader;
