import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField
} from "@mui/material";
import CountryMaster from "./country/CountryMaster";
import StateMaster from "./state/StateMaster";
import CityMaster from "./city/CityMaster";
import { SubmitButton } from "../../customComponent/Buttons";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import PremissionDialog from "../../customComponent/premissionDialog";
import CurrencyMaster from "./currency/CurrencyMaster";
import { RiSearch2Line } from "react-icons/ri";
import LocationMaster from "./locatioin/LocationMaster";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px"
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A" // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white"
    }
  }
}));

const useStylesForSearch = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    // alignItems: ""
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function MasterChip({ masterPageHandler }) {
  const classes = useStyles();
  const searchClasses = useStylesForSearch();

  const [clicked, setClicked] = useState("country");
  const [addFormCountry, setAddFormCountry] = useState(false);
  const [addFormState, setAddFormState] = useState(false);
  const [addFormCity, setAddFormCity] = useState(false);
  const [addFormLocation, setAddFormLocation] = useState(false);

  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();
  const [openAlert, setOpenAlert] = useState(false);
  const [search, setSearch] = useState("");

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClick = (master) => {
    setClicked(master);
  };

  const handleClose = ()=>{
    setAddFormLocation(false)
  }

  const handleModalClick = () => {
    if (isCreatePrevilage("master")) {
      if (clicked === "country") {
        setAddFormCountry(true);
      }

      if (clicked === "state") {
        setAddFormState(true);
      }

      if (clicked === "city") {
        setAddFormCity(true);
      }
      if (clicked === "location") {
        setAddFormLocation(true);
      }
    } else {
      setOpenAlert(true);
    }
  };

  const countryFormStatusHandler = () => {
    setAddFormCountry(false);
  };

  const stateFormStatusHandler = () => {
    setAddFormState(false);
  };
  const cityFormStatusHandler = () => {
    setAddFormCity(false);
  };

  if (!hasPrivilege("master") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "country" ? "clicked" : ""
            }`}
            label="Country Master"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("country")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "state" ? "clicked" : ""
            }`}
            label="State Master"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("state")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "city" ? "clicked" : ""
            }`}
            label="City Master"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("city")}
          />

          <Chip
            className={`${classes.hoverElement} ${
              clicked === "location" ? "clicked" : ""
            }`}
            label="Location Master"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("location")}
          />

          <Chip
            className={`${classes.hoverElement} ${
              clicked === "currency" ? "clicked" : ""
            }`}
            label="Currency Master"
            component="a"
            clickable
            sx={{ m: 1 }}
            onClick={() => handleClick("currency")}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <div
              className={searchClasses.searchContainer}
              style={{ position: "relative" }}
            >
              <TextField
                className={searchClasses.searchInput}
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                placeholder="Search Here"
                sx={{
                  "& .MuiInputBase-input": {
                    borderRadius: "20px",
                    height: "1px",
                    paddingRight: "48px !important"
                  },
                  "& .MuiFormLabel-root": {
                    lineHeight: "5px"
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "3px",
                    borderColor: "#fafafa"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "3px",
                    borderColor: "#ECECEC !important",
                    borderWidth: "1px !important"
                  }
                }}
              />
              <InputAdornment
                position="end"
                sx={{
                  position: "absolute",
                  right: "8px",
                  width: "40px",
                  border: "1px solid #ECECEC",
                  borderRadius: "3px",
                  height: "34px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer"
                }}
              >
                <RiSearch2Line />
              </InputAdornment>
            </div>

            {clicked === "country" ||clicked == "location" ? (
                <Grid sx={{ marginLeft: 2, alignItems: "center" }}>
                  <SubmitButton
                    title="Add New"
                    submit=""
                    widthSize="123px"
                    heightSize="38px"
                    type="click"
                    handleSubmit={handleModalClick}
                  />
                </Grid>
              ):""}
          </Grid>
        </Grid>
      </Grid>

      {clicked === "country" && (
        <CountryMaster
          addCountryFormOpen={addFormCountry}
          formStatusHandler={countryFormStatusHandler}
          searchHandle={search}
          subMenu="master"
        />
      )}

      {clicked === "state" && (
        <StateMaster
          addFormState={addFormState}
          stateFormStatusHandler={stateFormStatusHandler}
          searchHandle={search}
          subMenu="master"
        />
      )}

      {clicked === "city" && (
        <CityMaster
          addFormCity={addFormCity}
          cityFormStatusHandler={cityFormStatusHandler}
          subMenu="master"
          searchHandle={search}
        />
      )}

      {clicked === "location" && (
        <LocationMaster
          addCountryFormOpen={addFormLocation}
          formStatusHandler={countryFormStatusHandler}
          searchHandle={search}
          subMenu="master"
          handleClosePopUp = {()=>setAddFormLocation(false)}
        />
      )}

      {clicked === "currency" && <CurrencyMaster searchHandle={search} />}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
          subMenu="master"
        />
      )}
    </>
  );
}

export default MasterChip;
