import React, { useState } from "react";
import HeadBreadcrumbs from "../breadcrumbs/breadcrumbs";
import { MainContainer } from "../content/content.element";
import BasicTabs from "../Tabs/TabsMain";

function SettingMain(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <MainContainer active={props.toggle}>
        {tabValue === 0 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Master"
          />
        )}

        {tabValue === 1 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Notification"
          />
        )}

        {/* {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"  
            main="Coupons"
          />
        )} */}

        {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Branch"
          />
        )}

        {tabValue === 3 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Banner"
          />
        )}

        {tabValue === 4 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Lead Source"
          />
        )}

        {/* {tabValue === 5 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Course Major"
          />
        )} */}
        {tabValue === 5 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Ratings"
          />
        )}
        {tabValue === 6 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Settings"
            first="Settings"
            main="Default Documents"
          />
        )}
        <BasicTabs
          tab1={props.tab1}
          tab2={props.tab2}
          tab3={props.tab3}
          tab4={props.tab4}
          // tab5={props.tab5}
          tab5={props.tab5}
          tab6={props.tab6}
          tab7={props.tab7}
          tab8={props.tab8}
          tab9={props.tab9}
          callback={(data) => setTabValue(data)}
          tab1Name="Master"
          tab2Name="Notification"
          tab3Name="Branch"
          tab4Name="Banner"
          tab5Name="Lead Source"
          // tab6Name="Course Major"
          tab6Name="Rating"
          tab7Name="Default Documents"
          head="Home"
          sechead="role"
        />
      </MainContainer>
    </>
  );
}

export default SettingMain;
