// purchased student course find constants
export const IELTS_FIND_PURCHASED_STUDENT_COURSE_REQUEST =
  "IELTS_FIND_PURCHASED_STUDENT_COURSE_REQUEST";
export const IELTS_FIND_PURCHASED_STUDENT_COURSE_SUCCESS =
  "IELTS_FIND_PURCHASED_STUDENT_COURSE_SUCCESS";
export const IELTS_FIND_PURCHASED_STUDENT_COURSE_ERR =
  "IELTS_FIND_PURCHASED_STUDENT_COURSE_ERR";

// purchased student course find by id constants
export const IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_REQUEST =
  "IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_REQUEST";
export const IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_SUCCESS =
  "IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_SUCCESS";
export const IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_ERR =
  "IELTS_FIND_PURCHASED_STUDENT_DETAILS_BY_ID_ERR";

// purchased student course find by id constants
export const IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_REQUEST =
  "IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_REQUEST";
export const IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_SUCCESS =
  "IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_SUCCESS";
export const IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_ERR =
  "IELTS_FIND_PURCHASED_STUDENT_COURSE_BY_ID_ERR";

// purchased student assign teacher constants
export const IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_REQUEST =
  "IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_REQUEST";
export const IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_SUCCESS =
  "IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_SUCCESS";
export const IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_ERR =
  "IELTS_PURCHASED_STUDENT_ASSIGN_TEACHER_ERR";

// purchased student course status change constants
export const IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_REQUEST =
  "IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_REQUEST";
export const IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_SUCCESS =
  "IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_SUCCESS";
export const IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_ERR =
  "IELTS_PURCHASED_STUDENT_COURSE_STATUS_CHANGE_ERR";
