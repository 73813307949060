import { Alert, AlertTitle, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EmployeeHolidayTable } from "./EmployeeHolidayTable";
import { hrEmployeeHolidayFindAction } from "../../actions/HrActions/hrEmployeeManagmentAction";
import { useDispatch, useSelector } from "react-redux";
import { SubmitButton } from "../customComponent/Buttons";
import Dialogue from "../customComponent/Dialogue";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";
import PremissionDialog from "../customComponent/premissionDialog";

function AllEmployeeHolidays() {
  const dispatch = useDispatch();
  const [Holidaylist, SetHolidayList] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // custom Hooks

  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  // fetch_Data

  let { hrEmployeeCreateSuccess, hrEmployeeCreateErr } = useSelector(
    (state) => {
      return state.hrEmployeeHolidayCreation;
    }
  );
  let { hrEmployeeDeleteSuccess } = useSelector((state) => {
    return state.hrEmployeeHolidayDelete;
  });

  let { hrEmployeeEditCreateSuccess } = useSelector((state) => {
    return state.hrEmployeeHolidayEditCreate;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (hrEmployeeCreateSuccess && successAlertMsgSuccess) {
      Swal.fire("Added!", hrEmployeeCreateSuccess.message, "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [hrEmployeeCreateSuccess]);

  useEffect(() => {
    if (hrEmployeeCreateErr && successAlertMsgErr) {
      Swal.fire(
        "Something Went Wrong!",
        `${hrEmployeeCreateErr.message}`,
        "error"
      );
      dispatch(successTrueMsgAction(false));
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [hrEmployeeCreateErr, successAlertMsgErr]);

  useEffect(() => {
    if (hrEmployeeDeleteSuccess) {
      setDeleteSuccess(true);
    }
  }, [hrEmployeeDeleteSuccess]);

  useEffect(() => {
    dispatch(hrEmployeeHolidayFindAction());
  }, [
    dispatch,
    hrEmployeeCreateSuccess,
    deleteSuccess,
    hrEmployeeEditCreateSuccess,
  ]);

  let { hrEmployeeHolidaySuccess } = useSelector((state) => {
    return state.hrEmployeeHolidayFind;
  });

  // setdata_store

  useEffect(() => {
    if (hrEmployeeHolidaySuccess) {
      SetHolidayList(hrEmployeeHolidaySuccess);
    }
  }, [hrEmployeeHolidaySuccess]);

  const data = Holidaylist.map((holidays, index) => ({
    sl: index + 1,
    id: holidays.id,
    coloum1: holidays.Holiday_Month,
    coloum2: holidays.Leave_count,
    coloum3: holidays.Holiday_Name,
    coloum4: holidays.Holiday_Date,
  }));


  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (isCreatePrevilage("holiday list")) {
      setOpen(true);
    } else {
      setOpenAlert(true);
    }
  };
  const handleClose = () => setOpen(false);

  if (!hasPrivilege("holiday list") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={4}
          lg={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Create Holiday"
            submit=""
            widthSize="136px"
            heightSize="38px"
            type="click"
            handleSubmit={handleOpen}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <EmployeeHolidayTable
            columns={[
              {
                title: "SL NO",
                field: "SL NO",
              },
              {
                title: "MONTH",
                field: "MONTH",
              },
              {
                title: "TOTAL WORKING DAYS",
                field: "TOTAL WORKING DAYS",
              },
              {
                title: "HOLIDAY NAME",
                field: "HOLIDAY NAME",
              },
              {
                title: "DATE",
                field: "DATE",
              },
            ]}
            data={data}
            component="holiday"
            subMenu="holiday list"
            //   isPresentMap={isPresent}
          />
        </Grid>
        {/* Add Holiday Modal */}

        {open && (
          <Dialogue
            openTrue={open}
            handleClose={handleClose}
            headerName="New Holiday"
            Dailogcomponent="holiday"
            appliedApplicationId
          />
        )}
      </Grid>

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default AllEmployeeHolidays;
