// ADD TEACHER CONSTANTS

export const IELTS_TEACHERMANAGMENT_TEACHER_CREATE_REQUEST =
  "IELTS_TEACHERMANAGMENT_TEACHER_CREATE_REQUEST";
export const IELTS_TEACHERMANAGMENT_TEACHER_CREATE_SUCCESS =
  "IELTS_TEACHERMANAGMENT_TEACHER_CREATE_SUCCESS";
export const IELTS_TEACHERMANAGMENT_TEACHER_CREATE_ERR =
  "IELTS_TEACHERMANAGMENT_TEACHER_CREATE_ERR";

//  FIND TEACHER CONSTANTS

export const IELTS_TEACHERMANAGMENT_TEACHER_FIND_REQUEST =
  "IELTS_TEACHERMANAGMENT_TEACHER_FIND_REQUEST";
export const IELTS_TEACHERMANAGMENT_TEACHER_FIND_SUCCESS =
  "IELTS_TEACHERMANAGMENT_TEACHER_FIND_SUCCESS";
export const IELTS_TEACHERMANAGMENT_TEACHER_FIND_ERR =
  "IELTS_TEACHERMANAGMENT_TEACHER_FIND_ERR";

//  FINDBYID TEACHER CONSTANTS

export const IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_REQUEST =
  "IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_REQUEST";
export const IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_SUCCESS =
  "IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_SUCCESS";
export const IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_ERR =
  "IELTS_TEACHERMANAGMENT_TEACHER_FINDBYID_ERR";

// TEACHER STATUS CHANGE CONSTANTS

export const IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_REQUEST =
  "IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_REQUEST";
export const IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_SUCCESS =
  "IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_SUCCESS";
export const IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_ERR =
  "IELTS_TEACHERMANAGMENT_TEACHER_STATUSCHANGE_ERR";

// TEACHER UPDATE CONSTANTS

export const IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_REQUEST =
  "IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_REQUEST";
export const IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_SUCCESS =
  "IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_SUCCESS";
export const IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_ERR =
  "IELTS_TEACHERMANAGMENT_TEACHER_UPDATE_ERR";

// TEACHER FINDBYCENTERID CONSTANTS

export const IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_REQUEST =
  "IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_REQUEST";
export const IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_SUCCESS =
  "IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_SUCCESS";
export const IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_ERR =
  "IELTS_TEACHERMANAGMENT_TEACHER_FINDBYCENTERID_ERR";

// TEACHER ASSIGNEDSTUDENTS CONSTANTS

export const IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_REQUEST =
  "IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_REQUEST";
export const IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_SUCCESS =
  "IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_SUCCESS";
export const IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_ERR =
  "IELTS_TEACHERMANAGMENT_ASSIGNED_STUDENTS_ERR";
