import React, { useState } from "react";

import BasicTabs from "../../../Tabs/TabsMain";
import HeadBreadcrumbs from "../../../breadcrumbs/breadcrumbs";
import { MainContainer } from "../../../content/content.element";

function RoleDetailsMain(props) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <MainContainer active={props.toggle}>
        {tabValue === 0 && (
          <HeadBreadcrumbs
            head="Administration"
            sechead="Role Managment"
            first="Role Details"
            main="Role Details"
          />
        )}

        {tabValue === 1 && (
          <HeadBreadcrumbs
            head="Administration"
            sechead="Role Managment"
            first="Role Details"
            main="Sub Menu"
          />
        )}

        {tabValue === 2 && (
          <HeadBreadcrumbs
            head="home"
            sechead="Administration"
            first="Administration"
            main="Student Managment"
          />
        )}
        <BasicTabs
          tab1={props.tab1}
          tab2={props.tab2}
          // tab3={props.tab3}

          callback={(data) => setTabValue(data)}
          tab1Name="Main Menus"
          tab2Name="Permission"
          // tab3Name = "permissionOr"
          // tab3Name="Student Management"
          head="Home"
          sechead="role"
        />
      </MainContainer>
    </>
  );
}

export default RoleDetailsMain;
