
// HR_EMPLOYEE_ATTENDANCE_TABLE_Create

import {
    EMPLOYEE_FIND_REQUEST,
    EMPLOYEE_FIND_SUCCESS,
    EMPLOYEE_FIND_ERR,
    EMPLOYEE_ATTENDANCE_CREATE_REQUEST,
    EMPLOYEE_ATTENDANCE_CREATE_SUCCESS,
    EMPLOYEE_ATTENDANCE_CREATE_ERR ,
    EMPLOYEE_HOLIDAY_FIND_REQUEST,
    EMPLOYEE_HOLIDAY_FIND_SUCCESS,
    EMPLOYEE_HOLIDAY_FIND_ERR,
    EMPLOYEE_HOLIDAY_CREATE_REQUEST, 
    EMPLOYEE_HOLIDAY_CREATE_SUCCESS,
    EMPLOYEE_HOLIDAY_CREATE_ERR, 
    EMPLOYEE_HOLIDAY_DELETE_REQUEST,
    EMPLOYEE_HOLIDAY_DELETE_SUCCESS,
    EMPLOYEE_HOLIDAY_DELETE_ERR,
    EMPLOYEE_HOLIDAY_EDIT_REQUEST,
    EMPLOYEE_HOLIDAY_EDIT_SUCCESS,
    EMPLOYEE_HOLIDAY_EDIT_ERR,
    EMPLOYEE_HOLIDAY_CREATE_EDIT_REQUEST,
    EMPLOYEE_HOLIDAY_CREATE_EDIT_SUCCESS,
    EMPLOYEE_HOLIDAY_CREATE_EDIT_ERR,
    EMPLOYEE_LEAVE_APPLICATION_FIND_REQUEST,
    EMPLOYEE_LEAVE_APPLICATION_FIND_SUCCESS,
    EMPLOYEE_LEAVE_APPLICATION_FIND_ERR,
    EMPLOYEE_PAYSLIP_FIND_REQUEST,
    EMPLOYEE_PAYSLIP_CREATE_REQUEST,
    EMPLOYEE_PAYSLIP_CREATE_SUCCESS,
    EMPLOYEE_PAYSLIP_CREATE_ERR,
    EMPLOYEE_PAYSLIP_FIND_SUCCESS,
    EMPLOYEE_PAYSLIP_FIND_ERR,
    EMPLOYEE_JOB_CREATE_REQUEST,
    EMPLOYEE_JOB_CREATE_SUCCESS,
    EMPLOYEE_JOB_CREATE_ERR,
    EMPLOYEE_JOB_FIND_REQUEST,
    EMPLOYEE_JOB_FIND_SUCCESS,
    EMPLOYEE_JOB_FIND_ERR,
    EMPLOYEE_JOB_DELETE_REQUEST,
    EMPLOYEE_JOB_DELETE_SUCCESS,
    EMPLOYEE_JOB_DELETE_ERR,
    EMPLOYEE_JOB_EDIT_REQUEST,
    EMPLOYEE_JOB_EDIT_SUCCESS,
    EMPLOYEE_JOB_EDIT_ERR,
    EMPLOYEE_JOB_UPDATE_REQUEST,
    EMPLOYEE_JOB_UPDATE_SUCCESS,
    EMPLOYEE_JOB_UPDATE_ERR,
    EMPLOYEE_ATTENDANCE_SEARCH_REQUEST,
    EMPLOYEE_ATTENDANCE_SEARCH_SUCCESS,
    EMPLOYEE_ATTENDANCE_SEARCH_ERR,
    EMPLOYEE_PAYSLIP_DELETE_REQUEST,
    EMPLOYEE_PAYSLIP_DELETE_SUCCESS,
    EMPLOYEE_PAYSLIP_DELETE_ERR,
    EMPLOYEE_JOB_FIND_BY_ID_REQUEST,
    EMPLOYEE_JOB_FIND_BY_ID_SUCCESS,
    EMPLOYEE_JOB_FIND_BY_ID_ERR,
    JOB_APPLIED_LIST_REQUEST,
    JOB_APPLIED_LIST_SUCCESS,
    JOB_APPLIED_LIST_ERR,
    JOB_STATUS_CHANGE_REQUEST,
    JOB_STATUS_CHANGE_SUCCESS,
    JOB_STATUS_CHANGE_ERR,
    APPLIED_JOB_STATUS_CHANGE_REQUEST,
    APPLIED_JOB_STATUS_CHANGE_SUCCESS,
    APPLIED_JOB_STATUS_CHANGE_ERR
} from '../constants/Hr/EmployeeManagmentConstant'
import { EMPLOYEE_LEAVE_APPLY_ERR, EMPLOYEE_LEAVE_APPLY_REQUEST, EMPLOYEE_LEAVE_APPLY_SUCCESS } from '../constants/employeeManagmentConstant';
export const hrEmployeeFindReducer = (state = {}, action) => {
    switch (action.type) {
      case EMPLOYEE_FIND_REQUEST:
        return {
          ...state,
          hrEmployeeFindLoading: true,
        };
      case EMPLOYEE_FIND_SUCCESS:
        return {
          ...state,
          hrEmployeeFindLoading: false,
          hrEmployeeFindSuccess: action.payload,
        };
      case EMPLOYEE_FIND_ERR:
        return {
          ...state,
          hrEmployeeFindLoading: false,
          hrEmployeeFindErr: action.payload,
        };
      default:
        return state;
    }
  };
  

// HR_EMPLOYEE_ATTENDANCE_CREATION

export const hrEmployeeAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case  EMPLOYEE_ATTENDANCE_CREATE_REQUEST:
      return {
        ...state,
        hrEmployeeAttendanceLoading: true,
      };
    case EMPLOYEE_ATTENDANCE_CREATE_SUCCESS:
      return {
        ...state,
        hrEmployeeAttendanceLoading: false,
        hrEmployeeAttendanceSuccess: action.payload,
      };
    case EMPLOYEE_ATTENDANCE_CREATE_ERR:
      return {
        ...state,
        hrEmployeeAttendanceLoading: false,
        hrEmployeeAttendanceErr: action.payload,
      };
    default:
      return state;
  }
};


// employeeSearchReducer
export const employeeSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case  EMPLOYEE_ATTENDANCE_SEARCH_REQUEST:
      return {
        ...state,
        employeeSearchLoading: true,
      };
    case EMPLOYEE_ATTENDANCE_SEARCH_SUCCESS:
      return {
        ...state,
        employeeSearchLoading: false,
        employeeSearchSuccess: action.payload,
      };
    case EMPLOYEE_ATTENDANCE_SEARCH_ERR:
      return {
        ...state,
        employeeSearchLoading: false,
        employeeSearchErr: action.payload,
      };
    default:
      return state;
  }
};



//HR_EMPLOYEE_HOLIDAY_FIND

export const hrEmployeeHolidayFindReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_HOLIDAY_FIND_REQUEST:
      return {
        ...state,
        hrEmployeeHolidayFindLoading: true,
      };
    case EMPLOYEE_HOLIDAY_FIND_SUCCESS:
      return {
        ...state,
        hrEmployeeHolidayLoading: false,
        hrEmployeeHolidaySuccess: action.payload,
      };
    case EMPLOYEE_HOLIDAY_FIND_ERR:
      return {
        ...state,
        hrEmployeeHolidayLoading: false,
        hrEmployeeHolidayErr: action.payload,
      };
    default:
      return state;
  }
};


//HR_EMPLOYEE_HOLIDAY_CREATION

export const hrEmployeeHolidayCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_HOLIDAY_CREATE_REQUEST:
      return {
        ...state,
        hrEmployeeCreateFindLoading: true,
      };
    case EMPLOYEE_HOLIDAY_CREATE_SUCCESS:
      return {
        ...state,
        hrEmployeeCreateLoading: false,
        hrEmployeeCreateSuccess: action.payload,
      };
    case EMPLOYEE_HOLIDAY_CREATE_ERR:
      return {
        ...state,
        hrEmployeeCreateLoading: false,
        hrEmployeeCreateErr: action.payload,
      };
    default:
      return state;
  }
};


//HR_EMPLOYEE_HOLIDAY_DELETE


export const hrEmployeeHolidayDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_HOLIDAY_DELETE_REQUEST:
      return {
        ...state,
        hrEmployeeDeleteFindLoading: true,
      };
    case EMPLOYEE_HOLIDAY_DELETE_SUCCESS:
      return {
        ...state,
        hrEmployeeDeleteLoading: false,
        hrEmployeeDeleteSuccess: action.payload,
      };
    case EMPLOYEE_HOLIDAY_DELETE_ERR:
      return {
        ...state,
        hrEmployeeDeleteLoading: false,
        hrEmployeeDeleteErr: action.payload,
      };
    default:
      return state;
  }
};

//HR_EMPLOYEE_HOLIDAY_EDIT


export const hrEmployeeHolidayEditReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_HOLIDAY_EDIT_REQUEST:
      return {
        ...state,
        hrEmployeeEditFindLoading: true,
      };
    case EMPLOYEE_HOLIDAY_EDIT_SUCCESS:
      return {
        ...state,
        hrEmployeeEditLoading: false,
        hrEmployeeEditSuccess: action.payload,
      };
    case EMPLOYEE_HOLIDAY_EDIT_ERR:
      return {
        ...state,
        hrEmployeeEditLoading: false,
        hrEmployeeEditErr: action.payload,
      };
    default:
      return state;
  }
};

export const hrEmployeeHolidayEditCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_HOLIDAY_CREATE_EDIT_REQUEST:
      return {
        ...state,
        hrEmployeeEditCreateLoading: true,
      };
    case EMPLOYEE_HOLIDAY_CREATE_EDIT_SUCCESS:
      return {
        ...state,
        hrEmployeeEditCreateLoading: false,
        hrEmployeeEditCreateSuccess: action.payload,
      };
    case EMPLOYEE_HOLIDAY_CREATE_EDIT_ERR:
      return {
        ...state,
        hrEmployeeEditCreateLoading: false,
        hrEmployeeEditCreateErr: action.payload,
      };
    default:
      return state;
  }
};


// HR_EMPLOYEE_LEAVE_APPLICATION_REUCERS //////////////////


//HR_EMPLOYEE_LEAVE_APPLICATION_FIND_REDUCER

export const hrEmployeeLeaveApplicationFindReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_LEAVE_APPLICATION_FIND_REQUEST:
      return {
        ...state,
        hrEmployeeLeaveApplicationFindLoading: true,
      };
    case EMPLOYEE_LEAVE_APPLICATION_FIND_SUCCESS:
      return {
        ...state,
        hrEmployeeLeaveApplicationFindLoading: false,
        hrEmployeeLeaveApplicationFindSuccess: action.payload,
      };
    case EMPLOYEE_LEAVE_APPLICATION_FIND_ERR:
      return {
        ...state,
        hrEmployeeLeaveApplicationFindLoading: false,
        hrEmployeeLeaveApplicationFindErr: action.payload,
      };
    default:
      return state;
  }
};

//HR_EMPLOYEE_LEAVE_APPLICATION_CREATE_REDUCER


export const hrEmployeeLeaveApplicationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_LEAVE_APPLY_REQUEST:
      return {
        ...state,
        hrEmployeeLeaveApplicationApplyLoading: true,
      };
    case EMPLOYEE_LEAVE_APPLY_SUCCESS:
      return {
        ...state,
        hrEmployeeLeaveApplicationApplyLoading: false,
        hrEmployeeLeaveApplicationApplySuccess: action.payload,
      };
    case EMPLOYEE_LEAVE_APPLY_ERR:
      return {
        ...state,
        hrEmployeeLeaveApplicationApplyLoading: false,
        hrEmployeeLeaveApplicationApplyErr: action.payload,
      };
    default:
      return state;
  }
};



// HR_EMPLOYEE_PAYSLIP_FIND_REDUCER


export const hrEmployeePayslipFindReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_PAYSLIP_FIND_REQUEST:
      return {
        ...state,
        hrEmployeePayslipFindLoading: true,
      };
    case EMPLOYEE_PAYSLIP_FIND_SUCCESS:
      return {
        ...state,
        hrEmployeePayslipFindLoading: false,
        hrEmployeePayslipFindSuccess: action.payload,
      };
    case EMPLOYEE_PAYSLIP_FIND_ERR:
      return {
        ...state,
        hrEmployeePayslipFindLoading: false,
        hrEmployeePayslipFindErr: action.payload,
      };
    default:
      return state;
  }
};

// HR_EMPLOYEE_PAYSLIP_CREATE_REDUCER


export const hrEmployeePayslipCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_PAYSLIP_CREATE_REQUEST:
      return {
        ...state,
        hrEmployeePayslipCreateLoading: true,
      };
    case EMPLOYEE_PAYSLIP_CREATE_SUCCESS:
      return {
        ...state,
        hrEmployeePayslipCreateLoading: false,
        hrEmployeePayslipCreateSuccess: action.payload,
      };
    case EMPLOYEE_PAYSLIP_CREATE_ERR:
      return {
        ...state,
        hrEmployeePayslipCreateLoading: false,
        hrEmployeePayslipCreateErr: action.payload,
      };
    default:
      return state;
  }
};


// HR_EMPLOYEE_PAYSLIP_DELETE_REDUCER

export const hrEmployeePayslipDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_PAYSLIP_DELETE_REQUEST:
      return {
        ...state,
        hrEmployeePayslipDeleteLoading: true,
      };
    case EMPLOYEE_PAYSLIP_DELETE_SUCCESS:
      return {
        ...state,
        hrEmployeePayslipDeleteLoading: false,
        hrEmployeePayslipDeleteSuccess: action.payload,
      };
    case EMPLOYEE_PAYSLIP_DELETE_ERR:
      return {
        ...state,
        hrEmployeePayslipDeleteLoading: false,
        hrEmployeePayslipDeleteErr: action.payload,
      };
    default:
      return state;
  }
};




// HR_EMPLOYEE_JOB_CREATE_REDUCER

export const hrEmployeeJobCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_JOB_CREATE_REQUEST:
      return {
        ...state,
        hrEmployeeJobCreateLoading: true,
      };
    case EMPLOYEE_JOB_CREATE_SUCCESS:
      return {
        ...state,
        hrEmployeeJobCreateLoading: false,
        hrEmployeeJobCreateSuccess: action.payload,
      };
    case EMPLOYEE_JOB_CREATE_ERR:
      return {
        ...state,
        hrEmployeeJobCreateLoading: false,
        hrEmployeeJobCreateErr: action.payload,
      };
    default:
      return state;
  }
};

// HR_EMPLOYEE_JOB_FIND_REDUCER


export const hrEmployeeJobFindReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_JOB_FIND_REQUEST:
      return {
        ...state,
        hrEmployeeJobFindLoading: true,
      };
    case EMPLOYEE_JOB_FIND_SUCCESS:
      return {
        ...state,
        hrEmployeeJobFindLoading: false,
        hrEmployeeJobFindSuccess: action.payload,
      };
    case EMPLOYEE_JOB_FIND_ERR:
      return {
        ...state,
        hrEmployeeJobFindLoading: false,
        hrEmployeeJobFindErr: action.payload,
      };
    default:
      return state;
  }
};

// HR_EMPLOYEE_JOB_FIND_BY_ID_REDUCER

export const hrEmployeeJobFindByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_JOB_FIND_BY_ID_REQUEST:
      return {
        ...state,
        hrEmployeeJobFindByIdLoading: true,
      };
    case EMPLOYEE_JOB_FIND_BY_ID_SUCCESS:
      return {
        ...state,
        hrEmployeeJobFindByIdLoading: false,
        hrEmployeeJobFindByIdSuccess: action.payload,
      };
    case EMPLOYEE_JOB_FIND_BY_ID_ERR:
      return {
        ...state,
        hrEmployeeJobFindByIdLoading: false,
        hrEmployeeJobFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// HR_EMPLOYEE_JOB_DELETE_REDUCER


export const hrEmployeeJobDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_JOB_DELETE_REQUEST:
      return {
        ...state,
        hrEmployeeJobDeleteLoading: true,
      };
    case EMPLOYEE_JOB_DELETE_SUCCESS:
      return {
        ...state,
        hrEmployeeJobDeleteLoading: false,
        hrEmployeeJobDeleteSuccess: action.payload,
      };
    case EMPLOYEE_JOB_DELETE_ERR:
      return {
        ...state,
        hrEmployeeJobDeleteLoading: false,
        hrEmployeeJobDeleteErr: action.payload,
      };
    default:
      return state;
  }
};


// HR_EMPLOYEE_JOB_FIND_DATA_TO_EDIT_REDUCER

export const hrEmployeeJobeEditReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_JOB_EDIT_REQUEST:
      return {
        ...state,
        hrEmployeeJobEditLoading: true,
      };
    case EMPLOYEE_JOB_EDIT_SUCCESS:
      return {
        ...state,
        hrEmployeeJobEditLoading: false,
        hrEmployeeJobEditSuccess: action.payload,
      };
    case EMPLOYEE_JOB_EDIT_ERR:
      return {
        ...state,
        hrEmployeeJobEditLoading: false,
        hrEmployeeJobEditErr: action.payload,
      };
    default:
      return state;
  }
};


//  HR_EMPLOYEE_JOB_UPDATE_REDUCER

export const hrEmployeeJobUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYEE_JOB_UPDATE_REQUEST:
      return {
        ...state,
        hrEmployeeJobUpdateLoading: true,
      };
    case EMPLOYEE_JOB_UPDATE_SUCCESS:
      return {
        ...state,
        hrEmployeeJobUpdateLoading: false,
        hrEmployeeJobUpdateSuccess: action.payload,
      };
    case EMPLOYEE_JOB_UPDATE_ERR:
      return {
        ...state,
        hrEmployeeJobUpdateLoading: false,
        hrEmployeeJobUpdateErr: action.payload,
      };
    default:
      return state;
  }
};




//  EMPLOYEE_JOB_APPLIEDLIST_REDUCER

export const employeeJobAppliedReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_APPLIED_LIST_REQUEST:
      return {
        ...state,
        jobAppliedListLoading: true,
      };
    case JOB_APPLIED_LIST_SUCCESS:
      return {
        ...state,
        jobAppliedListLoading: false,
        jobAppliedListSuccess: action.payload,
      };
    case JOB_APPLIED_LIST_ERR:
      return {
        ...state,
        jobAppliedListLoading: false,
        jobAppliedListErr: action.payload,
      };
    default:
      return state;
  }
};




//  EMPLOYEE_JOB_STATUS_CHANGE_REDUCER

export const employeeJobStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case JOB_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        jobStatusChangeLoading: true,
      };
    case JOB_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        jobStatusChangeLoading: false,
        jobStatusChangeSuccess: action.payload,
      };
    case JOB_STATUS_CHANGE_ERR:
      return {
        ...state,
        jobStatusChangeLoading: false,
        jobStatusChangeErr: action.payload,
      };
    default:
      return state;
  }
};

export const appliedJobStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLIED_JOB_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        appliedJobStatusChangeLoading: true,
      };
    case APPLIED_JOB_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        appliedJobStatusChangeLoading: false,
        appliedJobStatusChangeSuccess: action.payload,
      };
    case APPLIED_JOB_STATUS_CHANGE_ERR:
      return {
        ...state,
        appliedJobStatusChangeLoading: false,
        appliedJobStatusChangeErr: action.payload,
      };
    default:
      return state;
  }
};




