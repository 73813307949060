import { ADD_UNIVERSITY_COVER_IMAGE_ERR, ADD_UNIVERSITY_COVER_IMAGE_REQUEST, ADD_UNIVERSITY_COVER_IMAGE_SUCCESS, DELETE_UNIVERSITY_COVER_IMAGE_ERR, DELETE_UNIVERSITY_COVER_IMAGE_REQUEST, DELETE_UNIVERSITY_COVER_IMAGE_SUCCESS, GET_UNIVERSITY_COVER_IMAGE_ERR, GET_UNIVERSITY_COVER_IMAGE_REQUEST, GET_UNIVERSITY_COVER_IMAGE_SUCCESS } from "../../constants/settings/settingsConstants";

export const addUniversityCoverImageReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_UNIVERSITY_COVER_IMAGE_REQUEST:
      return {
        ...state,
        addUniversityCoverImageLoading: true,
      };
    case ADD_UNIVERSITY_COVER_IMAGE_SUCCESS:
      return {
        ...state,
        addUniversityCoverImageLoading: false,
        addUniversityCoverImageSuccess: action.payload,
      };
    case ADD_UNIVERSITY_COVER_IMAGE_ERR:
      return {
        ...state,
        addUniversityCoverImageLoading: false,
        addUniversityCoverImageerror: action.payload,
      };
    default:
      return state;
  }
};

export const getUniversityCoverImageReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UNIVERSITY_COVER_IMAGE_REQUEST:
      return {
        ...state,
        getUniversityCoverImageLoading: true,
      };
    case GET_UNIVERSITY_COVER_IMAGE_SUCCESS:
      return {
        ...state,
        getUniversityCoverImageLoading: false,
        getUniversityCoverImageSuccess: action.payload,
      };
    case GET_UNIVERSITY_COVER_IMAGE_ERR:
      return {
        ...state,
        getUniversityCoverImageLoading: false,
        getUniversityCoverImageerror: action.payload,
      };
    default:
      return state;
  }
};

export const deleteUniversityCoverImageReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_UNIVERSITY_COVER_IMAGE_REQUEST:
      return {
        ...state,
        deleteUniversityCoverImageLoading: true,
      };
    case DELETE_UNIVERSITY_COVER_IMAGE_SUCCESS:
      return {
        ...state,
        deleteUniversityCoverImageLoading: false,
        deleteUniversityCoverImageSuccess: action.payload,
      };
    case DELETE_UNIVERSITY_COVER_IMAGE_ERR:
      return {
        ...state,
        deleteUniversityCoverImageLoading: false,
        deleteUniversityCoverImageerror: action.payload,
      };
    default:
      return state;
  }
};
