import React, { useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import HeadBreadcrumbs from "../../breadcrumbs/breadcrumbs";
import BasicTabs from "../../Tabs/TabsMain";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { entrolledStudentsDetailsFindAction } from "../../../actions/IeltsActions/entrolledStudentsAction";
import { IoArrowBack } from "react-icons/io5";

function IeltsEntrolledStudentsViewMain(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [wichTab, setWichTab] = useState(0);

  const callback = (tab) => {
    setWichTab(tab);
  };
  return (
    <MainContainer active={props.toggle}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <IoArrowBack
          style={{ height: "24px", width: "24px", cursor: "pointer" }}
          onClick={() => navigate("/ielts")}
        />
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            fontFamily: "'Montserrat', sans-serif",
          }}
        >
          Enrolled
        </Typography>
      </Box>
      <Box>
        <BasicTabs
          tab1Name="Student Details"
          tab1={props.tab1}
          callback={callback}
        />
      </Box>
    </MainContainer>
  );
}

export default IeltsEntrolledStudentsViewMain;
