import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CourseTable from "./CourseTable";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCourseFindAction } from "../../../../actions/IeltsActions/courseManagmentAction";

function IeltsCoursesMain({subMenu}) {
  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "TITLE", field: "title" },
    { title: "DURATION", field: "duration" },
    { title: "PRICE", field: "price" },
    { title: "COURSE TYPE", field: "course type" },
    { title: "STATUS", field: "status" },
  ];
  const dispatch = useDispatch();
  const [courseList, setCourseList] = useState([]);
  const [secListopen, setSecListOpen] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateList, setStateList] = useState([
    "Listing",
    "Reading",
    "Speaking",
    "Writing",
    "Grammar",
    "Exam Tips",
    "Question Bank",
  ]);

  const handleCheckCountry = (event, branchId, CheckedValue) => {
    if (event !== "gender") {
      setSelectedCountry(branchId === selectedCountry ? null : branchId);
      //   props.LeadFilterFunction(branchId, CheckedValue, event.target.checked);

      const searchText = event.target.cheked;

      if (searchText === false) {
        // setCountryList(uniqueCountries); // Reset to the original data when search text is empty
      }
    } else {
      //   props.LeadFilterFunction(branchId.target.value, "gender", true);
    }
  };

  const handleClick2 = () => {
    setSecListOpen(!secListopen);
  };

  const [ids, setIds] = useState([]);

  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  const handleDelete = () => {
    alert("You deleted the chip");
  };

  // Find created courses

  let {
    ieltsCourseManagmentCourseFindSuccess,
    ieltsCourseManagmentCourseFinderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseFind;
  });

  let {
    ieltsCourseManagmentCourseStatusChangeSuccess,
    ieltsCourseManagmentCourseStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCoursesStatusChange;
  });

  let {
    ieltsCourseManagmentCourseCreatingSuccess,
    ieltsCourseManagmentCourseCreatingerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseCreating;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseFindAction());
  }, [
    dispatch,
    ieltsCourseManagmentCourseStatusChangeSuccess,
    ieltsCourseManagmentCourseCreatingSuccess,
  ]);

  useEffect(() => {
    if (ieltsCourseManagmentCourseFindSuccess) {
      setCourseList(ieltsCourseManagmentCourseFindSuccess.data);
    }
  }, [dispatch, ieltsCourseManagmentCourseFindSuccess]);

const [page, setPage] = useState(10);

  return (
    <>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <CourseTable
          data={courseList}
          columns={columns}
          component="questionBank"
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          pageNumber={page}
          subMenu={subMenu}
        />
      </Box>
    </>
  );
}

export default IeltsCoursesMain;
