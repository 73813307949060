// Scedule meeting constants

export const SCEDULEMEETING_FIND_REQUEST = "SCEDULEMEETING_FIND_REQUEST";
export const SCEDULEMEETING_FIND_SUCCESS = "SCEDULEMEETING_FIND_SUCCESS";
export const SCEDULEMEETING_FIND_ERR = "SCEDULEMEETING_FIND_ERR";

// Scedule meeting statusChange constants

export const SCEDULEMEETING_STATUSCHANGE_REQUEST =
  "SCEDULEMEETING_STATUSCHANGE_REQUEST";
export const SCEDULEMEETING_STATUSCHANGE_SUCCESS =
  "SCEDULEMEETING_STATUSCHANGE_SUCCESS";
export const SCEDULEMEETING_STATUSCHANGE_ERR =
  "SCEDULEMEETING_STATUSCHANGE_ERR";

// Scedule meeting manageSlot constants

export const SLOT_CREATE_REQUEST = "SLOT_CREATE_REQUEST";
export const SLOT_CREATE_SUCCESS = "SLOT_CREATE_SUCCESS";
export const SLOT_CREATE_ERR = "SLOT_CREATE_ERR";

// Slot find constants

export const SLOT_FIND_REQUEST = "SLOT_FIND_REQUEST";
export const SLOT_FIND_SUCCESS = "SLOT_FIND_SUCCESS";
export const SLOT_FIND_ERR = "SLOT_FIND_ERR";

// Slot find constants

export const SLOT_STATUS_CHANGE_FIND_REQUEST = "SLOT_STATUS_CHANGE_FIND_REQUEST";
export const SLOT_STATUS_CHANGE_FIND_SUCCESS = "SLOT_STATUS_CHANGE_FIND_SUCCESS";
export const SLOT_STATUS_CHANGE_FIND_ERR = "SLOT_STATUS_CHANGE_FIND_ERR";
