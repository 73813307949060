import axios from "axios";

// TeacherDashBoard action

import {
  IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_ERR,
  IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_REQUEST,
  IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_SUCCESS,
  IELTS_TEACHER_DASHBOARD_FIND_ERR,
  IELTS_TEACHER_DASHBOARD_FIND_REQUEST,
  IELTS_TEACHER_DASHBOARD_FIND_SUCCESS,
  IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_ERR,
  IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_REQUEST,
  IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_SUCCESS,
  IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_ERR,
  IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_REQUEST,
  IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_SUCCESS,
} from "../../constants/Ielts/ieltsTeacherDashBoardContants";
import {
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_ERR,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_REQUEST,
  IELTS_COURSEMANAGMENT_COURSE_LIVECLASS_FIND_SUCCESS,
} from "../../constants/Ielts/ieltsCoursemanagmentConstnts";
import { SUCCESS_TRUE_MSG_ERR } from "../../constants/roleManagmentConstant";

// TEACHER DASHBOARD FIND ACTION

export const ieltsTeacherDashBoardFindaction = (id) => async (dispatch) => {
  try {
    dispatch({ type: IELTS_TEACHER_DASHBOARD_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/teacher/teacherDashboardDetails?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_TEACHER_DASHBOARD_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHER_DASHBOARD_FIND_ERR,
      payload: error.response.data,
    });
  }
};

// TEACHER DASHBORAD ASSIGNED STUDENTS FIND ACTION

export const ieltsTeacherDashBoardaAssignedStudentsFindByIdaction = (
  id
) => async (dispatch) => {
  try {
    dispatch({
      type: IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/teacher/assignedStudentsDetails?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHER_DASHBOARD_FINDBYID_ASSIGNEDSTUDENTS_ERR,
      payload: error.response.data,
    });
  }
};

// TEACHER DASHBORAD ASSIGNED STUDENTS COURSE FIND ACTION

export const ieltsTeacherDashBoardaAssignedStudentsCourseFindaction = (
  id
) => async (dispatch) => {
  try {
    dispatch({
      type: IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_REQUEST,
    });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let { data } = await axios.get(
      `/api/admin/ielts/teacher/assignedStudentsCourseFind?id=${id}`,
      config
    );

    dispatch({
      type: IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHER_DASHBOARD__ASSIGNEDSTUDENTS_COURSEFIND_ERR,
      payload: error.response.data,
    });
  }
};

// TeacherDashBoard Course LiveClass find Action

export const ieltsTeacherDashboardCourseLiveClassFindAction = () => async (
  dispatch
) => {
  try {
    dispatch({ type: IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_REQUEST });

    let isUserExist = localStorage.getItem("loginInfo")
      ? JSON.parse(localStorage.getItem("loginInfo"))
      : null;

    const config = {
      headers: {
        Authorization: `Bearer ${isUserExist?.token}`,
      },
    };

    let id = isUserExist?.UserId;

    let { data } = await axios.get(
      `/api/admin/ielts/teacher/assignedLiveClassDetails?teacherId=${id}`,
      config
    );

    console.log(data,"dataaa");

    dispatch({
      type: IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error.response, "error.response");
    dispatch({
      type: IELTS_TEACHER_DASHBOARD_LIVECLASS_FIND_ERR,
      payload: error.response.data,
    });
    dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: true });
  }
};
