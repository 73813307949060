import { Alert, AlertTitle, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TalkToExpertTable from "./TalkToExpertTable";
import { useDispatch, useSelector } from "react-redux";
import { TalkToExpertAction } from "../../../actions/LandingPageAction";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import TableNoItemComponent from "../../customComponent/TableNoItemComponent";

function TalkToExpertMain() {
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  const { talkToExpertSuccess } = useSelector((state) => state.talkToExpert);

  const { talkToExpertChangeStatusSuccess } = useSelector(
    (state) => state.talkToExpertChangeStatus
  );
  const { talkToExpertDeleteStatusSuccess } = useSelector(
    (state) => state.talkToExpertDeleteStatus
  );

  const { talktoExpertConvertToLeadSuccess, talktoExpertConvertToLeadErr } =
    useSelector((state) => state.talktoExpertConvertToLead);

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (talktoExpertConvertToLeadSuccess && successAlertMsgSuccess) {
      Swal.fire("Converted!", "converted Successfully.", "success");
      dispatch(successTrueMsgAction(false));
    }
    if (talktoExpertConvertToLeadErr && successAlertMsgErr) {
      Swal.fire("Error!", "Already a lead.", "error");
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [
    talktoExpertConvertToLeadSuccess,
    talktoExpertConvertToLeadErr,
    successAlertMsgSuccess,
    successAlertMsgErr,
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TalkToExpertAction());
  }, [
    dispatch,
    talkToExpertChangeStatusSuccess,
    talkToExpertDeleteStatusSuccess,
    talktoExpertConvertToLeadSuccess,
  ]);

  useEffect(() => {
    if (talkToExpertSuccess) {
      console.log(talkToExpertSuccess?.data);
      setData(talkToExpertSuccess.data);
    }
  }, [talkToExpertSuccess]);

  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "Full Name", field: "fullName" },
    { title: "Phone Number", field: "phoneNumber" },
    { title: "Email", field: "email" },
    { title: "Status", field: "status" },
    { title: "Action", field: "action" },
  ];

  const [data, setData] = useState();

  const [ids, setIds] = useState([]);
  const selectBoxIdPassHandler = (ids) => {
    setIds(ids);
  };

  if (!hasPrivilege("talk to expert") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      {data?.length > 0 ? (
        <TalkToExpertTable
          data={data}
          columns={columns}
          selectBoxIdPassHandler={selectBoxIdPassHandler}
          subMenu="talk to expert"
        />
      ) : (
        <TableNoItemComponent />
      )}
    </Box>
  );
}

export default TalkToExpertMain;
