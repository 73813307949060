import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Swal from "sweetalert2";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { courseAddShortListAction } from "../../../../actions/leadActions/leadDocsActions";
import { SubmitButton } from "../../../customComponent/Buttons";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import { UNIVERSITY_COURSE_FIND_SUCCESS } from "../../../../constants/university/courseConstant/universityCourseConstant";

function ShowUniversityCourse() {
  // const navigate = useNavigate()
  const dispatch = useDispatch();
  const { id } = useParams();

  const [universityShowData, setUniversityShowData] = useState([]);
  const [coursView, setCoursView] = useState();
  const [courseVisible, setCourseTableVisible] = useState(false);

  let {
    universityCourseFindLoading,
    universityCourseFindSuccess,
    universityCourseFinderror
  } = useSelector((state) => {
    return state.universityCourseFindForShortList;
  });

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  const {
    leadShortListAddLoading,
    leadShortListAddSuccess,
    leadShortListAdderror
  } = useSelector((state) => {
    return state.courseAddShortList;
  });

  useEffect(() => {
    if (successAlertMsgErr) {
      Swal.fire({
        icon: "error",
        title: "Oops..asdasdasdada.",
        text: "Something went wrong!",
        footer: `<p>${universityCourseFinderror}</p>`
      });

      Swal.fire(
        "Something Went Wrong!",
        `${universityCourseFinderror}`,
        "error"
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, universityCourseFinderror, dispatch]);

  useEffect(() => {
    if (leadShortListAddSuccess) {
    }
  }, []);

  useEffect(() => {
    if (successAlertMsgErr && leadShortListAdderror) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `<p>${leadShortListAdderror}</p>`
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, leadShortListAdderror, dispatch]);

  useEffect(() => {
    if (universityCourseFindSuccess) {
      setUniversityShowData(universityCourseFindSuccess);
      dispatch(successTrueMsgAction(false));
    }
  }, [universityCourseFindSuccess, dispatch]);

  // useEffect(() => {
  //   if (universityCourseFindSuccess) {
  //     Swal.fire("Good job!", "Course find successfully", "success")
  //     dispatch(successTrueMsgAction(false));
  //     dispatch({
  //       type: UNIVERSITY_COURSE_FIND_MESSAGE,
  //       payload: { message: false },
  //     });
  //   }
  // }, [universityCourseFindSuccess,]);

  console.log(
    universityCourseFindSuccess,
    "universityCourseFindSuccessuniversityCourseFindSuccess"
  );

  const handleAddToShortList = (
    university,
    course,
    intakeDate,
    intakeId,
    leadId
  ) => {
    Swal.fire({
      title: "Are you sure?",
      text: "do you want to add to short list ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          courseAddShortListAction(
            university,
            course,
            intakeDate,
            intakeId,
            leadId
          )
        );
      }
    });
  };

  const handleCourseShowClick = (id) => {
    setCourseTableVisible(true);
    setCoursView(id);
  };
  return (
    <>
      <Box sx={{ border: "1px solid #ececec", width: "auto", mt: 2 }}>
        {universityShowData.length !== 0 &&
          universityShowData?.map((university) => {
            return (
              <>
                <Grid
                  key={university.id}
                  container
                  sx={{
                    mt: 5,
                    backgroundColor: "white",
                    border: "0px solid #ececec"
                  }}
                >
                  <Grid item xs={12} lg={5} sx={{ mt: 2 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "auto",
                        margin: "6px"
                      }}
                    >
                      <img
                        src={`${university?.universityImages[0]?.universityImageUrl}`}
                        width={300}
                        height={90}
                        alt="gsl_university_image"
                        style={{ flexShrink: 0 }} // Prevents image from resizing
                      />
                      {/* </div> */}
                      <div
                        style={{
                          marginTop: "-20px",
                          marginLeft: "16px",
                          textAlign: "left",
                          width: "100%"
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            fontFamily: "'Montserrat', sans-serif",
                            whiteSpace: "nowrap", // Prevents text wrapping

                          }}
                        >
                          {university.universityName}
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "17px",
                            fontFamily: "'Montserrat', sans-serif",
                            mt: 2
                          }}
                        >
                          {university.universityLocation}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "17px",
                            fontFamily: "'Montserrat', sans-serif"
                          }}
                        >
                          Ranking: {university.rank},&nbsp; Courses:{" "}
                          {university.courses?.length
                            ? university.courses?.length
                            : 0}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "17px",
                        fontFamily: "'Open Sans', sans-serif",
                        // mt: 1,
                        ml: 18
                      }}
                    >
                      {university.aboutUniversity}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={12} lg={3}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "25px"
                      }}
                    >
                      {courseVisible === false ? (
                        // <Button
                        //   sx={{
                        //     color: "white",
                        //     backgroundColor: "#141E3C",
                        //     mt: 2,
                        //   }}
                        //   onClick={() => handleCourseShowClick(university.id)}
                        // >
                        //   View Course
                        // </Button>

                        <SubmitButton
                          title="View Course"
                          submit=""
                          widthSize="150px"
                          heightSize="40px"
                          type="click"
                          handleSubmit={() =>
                            handleCourseShowClick(university.id)
                          }
                        />
                      ) : (
                        // <Button
                        //   sx={{
                        //     color: "white",
                        //     backgroundColor: "#141E3C",
                        //     mt: 2,
                        //   }}
                        //   onClick={() => {
                        //     setCourseTableVisible(false);
                        //     setCoursView("");
                        //   }}
                        // >
                        //   hide Coures
                        // </Button>

                        <SubmitButton
                          title="hide Coures"
                          submit=""
                          widthSize="150px"
                          heightSize="40px"
                          type="click"
                          handleSubmit={() => {
                            setCourseTableVisible(false);
                            setCoursView("");
                          }}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>

                {coursView === university.id ? (
                  <Grid
                    key={university.id}
                    container
                    sx={{ backgroundColor: "white", mt: 7 }}
                  >
                    <TableContainer component={Box}>
                      <Table
                        sx={{ minWidth: 550, border: "0.5px solid #ececec" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow
                            sx={{
                              backgroundColor: "#efedf0",
                              boxShadow: 0
                            }}
                          >
                            <TableCell align="left">sl no</TableCell>
                            <TableCell align="left">Course Name</TableCell>
                            <TableCell align="left">Intake</TableCell>
                            <TableCell align="left">Tuition fee</TableCell>
                            <TableCell align="left">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {university &&
                            university?.courses?.map((course, index) => {
                              if (course.Courses_Intakes.length === 0) {
                                return (
                                  <TableRow
                                    key={course.id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: "0px",
                                        m: 2
                                      }
                                    }}
                                  >
                                    <TableCell align="left">
                                      {index + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                      {course.courseTitle || "-"}
                                    </TableCell> 
                                    <TableCell align="left">
                                        {course.intakeDate || "-"}
                                      </TableCell>                                 
                                    <TableCell align="left">
                                      {course.totalFee || "-"}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          handleAddToShortList(
                                            university,
                                            course,
                                            "-",
                                            "-",
                                            id
                                          )
                                        }
                                        sx={{
                                          width: "150px", // Set a fixed width for consistency
                                          height: "40px", // Adjust height for better proportions
                                          m: 1,
                                          borderColor: "#141E3C",
                                          color: "#141E3C",
                                          backgroundColor: "white",
                                          borderRadius: "5px",
                                          textTransform: "none", // Prevent uppercase transformation
                                          fontSize: "14px", // Ensure consistent font size
                                          lineHeight: "1.5", // Adjust line height for better alignment
                                        }}
                                      >
                                        Add to shortlist
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              } else {
                                return course.Courses_Intakes.map(
                                  (intake, index) => (
                                    <TableRow
                                      key={course.id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "0px",
                                          m: 2
                                        }
                                      }}
                                    >
                                      <TableCell align="left">
                                        {index + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        {course.courseTitle}
                                      </TableCell>
                                      <TableCell align="left">
                                        {intake.intakeDate}
                                      </TableCell>
                                      <TableCell align="left">
                                        {course.totalFee}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            handleAddToShortList(
                                              university,
                                              course,
                                              intake.intakeDate,
                                              intake.id,
                                              id
                                            )
                                          }
                                          sx={{
                                            width: "150px", // Set a fixed width for consistency
                                            height: "40px", // Adjust height for better proportions
                                            m: 1,
                                            borderColor: "#141E3C",
                                            color: "#141E3C",
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            textTransform: "none", // Prevent uppercase transformation
                                            fontSize: "14px", // Ensure consistent font size
                                            lineHeight: "1.5", // Adjust line height for better alignment
                                          }}
                                        >
                                          Add to shortlist
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  )
                                );
                              }
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : null}
              </>
            );
          })}
      </Box>
    </>
  );
}

export default ShowUniversityCourse;
