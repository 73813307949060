import * as React from "react";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline";

export default function MusicPlayerSlider({url}) {

    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
      if (isPlaying) {
        setIsPlaying(false);
        waveform?.pause();
      } else {
        setIsPlaying(true);
        waveform?.play();
      }
    };

    

    const [waveform, setWaveform] = useState(null);

    useEffect(() => {
      if (url) {
        waveform?.destroy();
        const newWaveform = WaveSurfer.create({
          container: "#waveform",
          backend: "MediaElement",
          waveColor: "#777AAE",
          progressColor: "#FF1B6F",
          hideScrollbar: true,
          barWidth: 2,
          barGap: 2,
          url: url,
          height: 50,
        });

        setWaveform(newWaveform);

        return () => {
          waveform?.destroy();
        };
      }
    }, [url]);


    
    return (
      <>
        <Box
          sx={{
            padding: "20px 30px",
            background: "#eee",
            textAlign: "center",
            maxWidth: "500px",
            boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.45)",
            borderRadius: "15px",
            width: "100%",
          }}
        >
          <Button
            sx={{
              color: "rgba(20, 30, 60, 1)",
              fontWeight: 700,
            }}
            onClick={handlePlayPause}
          >
            {isPlaying ? "Pause" : "Play"}
          </Button>
         
          <div id="waveform"></div>
        </Box>
      </>
    );
}
