// STUDENT MANAGMENT REDUCER

import {
  IELTS_STUDENTMANAGMENT_CREATE_ERR,
  IELTS_STUDENTMANAGMENT_CREATE_REQUEST,
  IELTS_STUDENTMANAGMENT_CREATE_SUCCESS,
} from "../../constants/Ielts/ieltsStudentManagmentConstants";

export const ieltsStudentManagmentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case IELTS_STUDENTMANAGMENT_CREATE_REQUEST:
      return {
        ...state,
        ieltsStudentManagmentCreateLoading: true,
      };
    case IELTS_STUDENTMANAGMENT_CREATE_SUCCESS:
      return {
        ...state,
        ieltsStudentManagmentCreateLoading: false,
        ieltsStudentManagmentCreateSuccess: action.payload,
      };
    case IELTS_STUDENTMANAGMENT_CREATE_ERR:
      return {
        ...state,
        ieltsStudentManagmentCreateLoading: false,
        ieltsStudentManagmentCreateerror: action.payload,
      };
    default:
      return state;
  }
};
