import React, { useEffect, useState } from "react";
import { MainContainer } from "../../../content/content.element";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Box, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import { ieltsCourseManagmentCourseLiveClassFindByIdAction } from "../../../../actions/IeltsActions/courseManagmentAction";

function IeltsLiveClassSinglePage({ toggle }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [liveClassDetails, setLiveClassDetails] = useState();
  const [description, setDescription] = useState("");

  let {
    ieltsCourseManagmentCourseLiveClassFindByIdSuccess,
    ieltsCourseManagmentCourseLiveClassFindByIderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseLiveClassFindById;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseLiveClassFindByIdAction(id));
  }, []);

  useEffect(() => {
    if (ieltsCourseManagmentCourseLiveClassFindByIdSuccess) {
      setLiveClassDetails(
        ieltsCourseManagmentCourseLiveClassFindByIdSuccess.data
      );
    }
  }, [ieltsCourseManagmentCourseLiveClassFindByIdSuccess]);

  console.log(liveClassDetails, "liveClassDetailsliveClassDetails");

  // Convert html to plainText

  function convertHtmlToTextWithStyles(html) {
    const div = document.createElement("div");
    div.innerHTML = html;

    // Use the textContent property to get the plain text content
    const plainText = div.textContent;

    // If you also want to keep the styles as inline styles, you can use the outerHTML property
    const textWithStyles = div.outerHTML;

    return { plainText, textWithStyles };
  }

  // Example usage
  const { plainText, textWithStyles } = convertHtmlToTextWithStyles(
    liveClassDetails?.LiveClassDescription
  );

  function getTimeWithAMPM(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const hours = dateObject.getHours() % 12;
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";
    let time = undefined;
    if (dateTimeString) {
      time = `${hours}:${minutes} ${amPm}`;
    }
    return time;
  }

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Live Class Details" url="/IELTS" />
      <Box
        sx={{
          padding: "16px",
          borderRadius: "4px",
          border: "1px solid rgba(236, 236, 236, 1)",
          mt: "25px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "250px 1fr",
            alignItems: "center",
          }}
        >
          <Box>
            <Box
              sx={{
                "& img": {
                  width: "225px",
                  margin: "auto",
                  objectFit:"cover",
                },
              }}
            >
              <img src={liveClassDetails?.LiveClassThumbnail ? liveClassDetails?.LiveClassThumbnail : "/liveClass.png" } />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "10px 1fr",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <svg
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="black" />
              </svg>

              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                {liveClassDetails?.LiveClassTopic}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "10px 1fr",
                alignItems: "center",
                gap: "10px",
                mt: "5px",
              }}
            >
              <svg
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="black" />
              </svg>

              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                Introduction to listening
              </Typography>
            </Box> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "fit-content",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Course
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {liveClassDetails?.ielts_course?.CourseName}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Category
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {liveClassDetails?.ielts_category?.CategoryName}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Teacher
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {liveClassDetails?.ielts_teacher?.teacherName}
                </Typography>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "fit-content",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Date
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {liveClassDetails?.createdAt?.split("T")[0]}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Time
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {getTimeWithAMPM(liveClassDetails?.createdAt)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Duration
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {liveClassDetails?.LiveClassDuration}
                </Typography>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "fit-content",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Meeting Channel
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {liveClassDetails?.MeetingLink}
                </Typography>
              </Box>
              {/* <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Entry Created On
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  19/08/2023
                </Typography>
              </Box> */}
              {/* <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Entry Created By
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Aravind
                </Typography>
              </Box> */}
            </Box>
            {/* <Divider orientation="vertical" flexItem /> */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "fit-content",
              }}
            > */}
            {/* <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Entry Updated On
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  19/08/2023
                </Typography>
              </Box> */}
            {/* <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Entry Updated By
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  19/08/2023
                </Typography>
              </Box> */}
            {/* </Box> */}
            <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "fit-content",
                maxWidth: "400px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  Description
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 1)",
                  }}
                >
                  {plainText}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainContainer>
  );
}

export default IeltsLiveClassSinglePage;
