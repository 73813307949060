import {
  UNIVERSITY_MASTER_BY_TYPE_ID_FIND_ERR,
  UNIVERSITY_MASTER_BY_TYPE_ID_FIND_REQUEST,
  UNIVERSITY_MASTER_BY_TYPE_ID_FIND_SUCCESS,
  UNIVERSITY_MASTER_EDIT_ERR,
  UNIVERSITY_MASTER_EDIT_MESSAGE,
  UNIVERSITY_MASTER_EDIT_REQUEST,
  UNIVERSITY_MASTER_EDIT_SUCCESS,
  UNIVERSITY_MASTER_FIND_ERR,
  UNIVERSITY_MASTER_FIND_REQUEST,
  UNIVERSITY_MASTER_FIND_SUCCESS,
  UNIVERSITY_MASTER_SUBMIT_ERR,
  UNIVERSITY_MASTER_SUBMIT_REQUEST,
  UNIVERSITY_MASTER_SUBMIT_SUCCESS,
  UNIVERSITY_MASTER_SUB_DELETE_ERR,
  UNIVERSITY_MASTER_SUB_DELETE_REQUEST,
  UNIVERSITY_MASTER_SUB_DELETE_SUCCESS,
} from "../../constants/university/universityManagmentMaster";

// universityMasterAddReducer
export const universityMasterAddReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_MASTER_SUBMIT_REQUEST:
      return {
        ...state,
        universityMasterAddLoading: true,
      };
    case UNIVERSITY_MASTER_SUBMIT_SUCCESS:
      return {
        ...state,
        universityMasterAddLoading: false,
        universityMasterAddSuccess: action.payload,
      };
    case UNIVERSITY_MASTER_SUBMIT_ERR:
      return {
        ...state,
        universityMasterAddLoading: false,
        universityMasterAddErr: action.payload,
      };
    default:
      return state;
  }
};

// University Master edit ::::::::::::::::::::::::::::::::::

export const universityMasterEditReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_MASTER_EDIT_REQUEST:
      return {
        ...state,
        universityMasterEditLoading: true,
      };
    case UNIVERSITY_MASTER_EDIT_SUCCESS:
      return {
        ...state,
        universityMasterEditLoading: false,
        universityMasterEditSuccess: action.payload,
      };
    case UNIVERSITY_MASTER_EDIT_ERR:
      return {
        ...state,
        universityMasterEditLoading: false,
        universityMasterEditErr: action.payload,
      };
    default:
      return state;
  }
};

// universityMasterFindReducer
export const universityMasterFindReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_MASTER_FIND_REQUEST:
      return {
        ...state,
        universityMasterFindLoading: true,
      };
    case UNIVERSITY_MASTER_FIND_SUCCESS:
      return {
        ...state,
        universityMasterFindLoading: false,
        universityMasterFindSuccess: action.payload,
      };
    case UNIVERSITY_MASTER_FIND_ERR:
      return {
        ...state,
        universityMasterFindLoading: false,
        universityMasterFindErr: action.payload,
      };
    default:
      return state;
  }
};

// findMasterbyTypeIDReducer
export const findMasterbyTypeIDReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_MASTER_BY_TYPE_ID_FIND_REQUEST:
      return {
        ...state,
        universityMasterFindByIdLoading: true,
      };
    case UNIVERSITY_MASTER_BY_TYPE_ID_FIND_SUCCESS:
      return {
        ...state,
        universityMasterFindByIdLoading: false,
        universityMasterFindByIdSuccess: action.payload,
      };
    case UNIVERSITY_MASTER_BY_TYPE_ID_FIND_ERR:
      return {
        ...state,
        universityMasterFindByIdLoading: false,
        universityMasterFindByIdErr: action.payload,
      };
    default:
      return state;
  }
};

// deleteUniversityMasterReducer
export const deleteUniversityMasterReducer = (state = {}, action) => {
  switch (action.type) {
    case UNIVERSITY_MASTER_SUB_DELETE_REQUEST:
      return {
        ...state,
        universityMasterDeleteByIdLoading: true,
      };
    case UNIVERSITY_MASTER_SUB_DELETE_SUCCESS:
      return {
        ...state,
        universityMasterDeleteByIdLoading: false,
        universityMasterDeleteByIdSuccess: action.payload,
      };
    case UNIVERSITY_MASTER_SUB_DELETE_ERR:
      return {
        ...state,
        universityMasterDeleteByIdLoading: false,
        universityMasterDeleteByIdErr: action.payload,
      };
    default:
      return state;
  }
};
