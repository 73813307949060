import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SwitchButton } from "../../../../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCourseManagmentCreateCourseAction, ieltsCourseManagmentCreateCourseErrorAction } from "../../../../../actions/IeltsActions/courseManagmentAction";

function SelectCourseCategoryEdit({ handleChange, switches, handleError }) {

  let { ieltsCourseManagmentCourseCreateSuccess } = useSelector((state) => {
    return state.ieltsCourseManagmentCreateCourse;
  });

  let { ieltsCourseManagmentCourseCreateCheckErrorSuccess } = useSelector(
    (state) => {
      return state.ieltsCourseManagmentCreateCheckErrorCourse;
    }
  );

  const dispatch = useDispatch();
  let stateData;
  const [formData, setFormData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateSuccess) {
      setFormData(ieltsCourseManagmentCourseCreateSuccess);
    }
  }, [ieltsCourseManagmentCourseCreateSuccess]);
  console.log(formData, "formData"); 

  useEffect(() => {
    if (ieltsCourseManagmentCourseCreateCheckErrorSuccess) {
      setError(ieltsCourseManagmentCourseCreateCheckErrorSuccess);
    }
  }, [ieltsCourseManagmentCourseCreateCheckErrorSuccess]);

  const handleSwitches = (e) => {
    const { name, checked } = e.target;

    const updatedData = (prevSlots) => ({
      ...prevSlots,
      [name]: checked,
    });

    setFormData((prevSlots) => updatedData(prevSlots));
    dispatch(ieltsCourseManagmentCreateCourseAction(updatedData(formData)));
    dispatch(
      ieltsCourseManagmentCreateCourseErrorAction(updatedData(error))
    );
    handleError((prev) => ({
      ...prev,
      ["category"]: "",
    }));
  };

  const checkbox = [
    { label: "Speaking", name: "speaking" },
    { label: "Reading", name: "reading" },
    { label: "Listening", name: "listening" },
    { label: "Writing", name: "writing" },
    { label: "grammar", name: "grammar" },
  ];

  return (

    <Box
      sx={{
        mt: "32px",
      }}
    >

      <Typography
        sx={{
          fontSize: "16px",
          color: "#05050F",
          fontWeight: "600",
          mb: "24px",
        }}
      >
        Select Categories
      </Typography>

      <Box
        sx={{
          display: "flex",
          //   gridTemplateColumns: "repeat(4,1fr)",
          flexWrap: "wrap",
          gap: "32px",
          maxWidth: "600px",
          mb: "10px",
        }}
      >
        <Box
          sx={{
            "& .MuiFormControlLabel-root": {
              width: "fit-content !important",
              ml: "0 !important",
              gap: "8px !important",
              color: "#05050F",
            },
          }}
        >
          <SwitchButton
            label={"Online Class"}
            checked={formData?.isOnline}
            handleChange={handleSwitches}
            name={"isOnline"}
          />
        </Box>
        <Box
          sx={{
            "& .MuiFormControlLabel-root": {
              width: "fit-content !important",
              ml: "0 !important",
              gap: "8px !important",
              color: "#05050F",
            },
          }}
        >
          <SwitchButton
            label={"Offline Class"}
            checked={formData?.isOffline}
            handleChange={handleSwitches}
            name={"isOffline"}
          />
        </Box>
      </Box>

      {formData?.isOnline && (

        <Box
          sx={{
            display: "flex",
            //   gridTemplateColumns: "repeat(4,1fr)",
            flexWrap: "wrap",
            gap: "32px",
            maxWidth: "700px",
            mb: "24px",
            mt: "24px",
          }}
        >
          {checkbox.map((item) => (
            <Box
              sx={{
                "& .MuiFormControlLabel-root": {
                  width: "fit-content !important",
                  ml: "0 !important",
                  gap: "8px !important",
                  color: "#05050F",
                },
              }}
            >
              <SwitchButton
                label={item.label}
                checked={switches[item.name]}
                handleChange={handleChange}
                name={item.name}
              />
            </Box>
          ))}
        </Box>

      )}

    </Box>

  );
}

export default SelectCourseCategoryEdit;
