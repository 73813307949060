import React, { useEffect, useState } from "react";
import LeadsTable from "../../Table/LeadsTable";

import { useDispatch, useSelector } from "react-redux";

import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  InputAdornment,
  TextField
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { findApprovingStatusLeadAction } from "../../../actions/leadActions/leadActions";
import { RiSearch2Line } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function LeadStatusApprove(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(5);
  const [searchText, setSearchText] = useState("");

  let { findleadApprovingStatusSuccess, findleadApprovingStatusLoading } =
    useSelector((state) => {
      return state.findApprovingStatusLead;
    });

  let { changeleadApprovingStatusSuccess } = useSelector((state) => {
    return state.leadStatusApprovingChange;
  });

  //   let { loginFindSuccess } = useSelector((state) => {
  //     return state.findUser;
  //   });

  useEffect(() => {
    dispatch(findApprovingStatusLeadAction());
  }, [dispatch, changeleadApprovingStatusSuccess]);

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Email",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Phone",
      field: "phone"
      // hidden: showPhoneColumn,
    },

    {
      title: "What's App Number",
      field: "whatsappNumber"
      // hidden: showWhatsAppNumColumn,
    },

    {
      title: "Request Status",
      field: "status"
      // hidden: showStatusColumn,
    },
    {
      title: "Current Status",
      field: "currentStatus"
      // hidden: showEducationColumn,
    },
    {
      title: "Reject reason",
      field: "rejectReason"
      // hidden: showEducationColumn,
    },
    {
      title: "Lead Source",
      field: "leadSource"
      // hidden: showLeadSourceColumn,
    },
    {
      title: "Lead Owner",
      field: "leadOwner"
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Lead Place",
      field: "leadCity"
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Action",
      field: "action",
      filtering: false
    }
  ];

  // custom hook for privilage check
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  useEffect(() => {
    if (findleadApprovingStatusSuccess) {
      setLeadData(findleadApprovingStatusSuccess);
    }
  }, [findleadApprovingStatusSuccess]);

  console.log(findleadApprovingStatusSuccess,"findleadApprovingStatusSuccessfindleadApprovingStatusSuccess")

  useEffect(() => {
    setData(
      leadData?.map((lead, index) => ({
        id: lead.id,
        statusId: lead.leads_status_approvings[0]?.id,
        sl: index + 1,
        name: lead.firstName,
        email: lead.email,
        phone: lead.mobileNumber,
        whatsappNumber: lead.whatsappNumber,
        status: lead.leads_status_approvings[0]?.leadStatus,
        approvalStatus:lead.leads_status_approvings[0]?.status,
        currentStatus: lead.status,
        rejectReason:lead.leads_status_approvings[0]?.rejectReason,
        leadSource: lead.leadSource,
        leadOwner: lead.leadOwnerName,
        leadCity: lead.location
      }))
    );
  }, [leadData]);


  const searchHandleChange = (e) => {
    let value = e.target.value;
    if (value) {
      let result = findleadApprovingStatusSuccess?.filter(
        (item) =>
          item.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.email?.toLowerCase()?.includes(value?.toLowerCase())||
          item.mobileNumber?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setLeadData(result);
     
    } else {
      setLeadData(findleadApprovingStatusSuccess);    }
  };


  return (
    <>
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          // alignItems: "center",
          justifyContent: "end"
        }}
      >
        <div className={classes.searchContainer}>
          <TextField
            className={classes.searchInput}
            variant="outlined"
            placeholder="Search Here"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              searchHandleChange(e);
            }}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "1px",
                paddingRight: "48px !important"
              },
              "& .MuiFormLabel-root": {
                lineHeight: "5px"
              },
              "& .MuiInputBase-root": {
                borderRadius: "3px",
                borderColor: "#fafafa"
              },
              "& .MuiOutlinedInput-notchedOutline ": {
                borderRadius: "3px",
                borderColor: "#ECECEC !important",
                borderWidth: "1px !important"
              }
            }}
          />
          <>
            <InputAdornment
              position="end"
              sx={{
                position: "absolute",
                right: "8px",
                width: "40px",
                // top:"0",
                // bottom:"0",
                border: "1px solid #ECECEC",
                borderRadius: "3px",
                height: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              <RiSearch2Line />
            </InputAdornment>
          </>
        </div>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LeadsTable
            toggle={props.toggle}
            showNameColumn=""
            showPrefferedColumn=""
            showPhoneColumn=""
            showLeadSourceColumn=""
            showLeadTypeColumn=""
            showStatusColumn=""
            showEducationColumn=""
            showUniversityPlaceColumn=""
            reset=""
            setIdsFunction=""
            data={data}
            columns={columns}
            leadFindLoading={findleadApprovingStatusLoading}
            //   selectBoxIdPassHandler={selectBoxIdPassHandler}
            component="approvingStatus"
            pagiantionEntry={pagiantionEntry}
            paginationPageNumber={paginationPageNumber}
            subMenu="Status Approving"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LeadStatusApprove;
