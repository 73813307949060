import {
  COURSELEVELMASTER_CREATE_ERR,
  COURSELEVELMASTER_CREATE_REQUEST,
  COURSELEVELMASTER_CREATE_SUCCESS,
  COURSELEVELMASTER_DELETE_ERR,
  COURSELEVELMASTER_DELETE_REQUEST,
  COURSELEVELMASTER_DELETE_SUCCESS,
  COURSELEVELMASTER_FIND_ERR,
  COURSELEVELMASTER_FIND_REQUEST,
  COURSELEVELMASTER_FIND_SUCCESS,
  COURSELEVELMASTER_UPDATE_ERR,
  COURSELEVELMASTER_UPDATE_REQUEST,
  COURSELEVELMASTER_UPDATE_SUCCESS,
  COURSEMASTER_CREATE_ERR,
  COURSEMASTER_CREATE_REQUEST,
  COURSEMASTER_CREATE_SUCCESS,
  COURSEMASTER_DELETE_ERR,
  COURSEMASTER_DELETE_REQUEST,
  COURSEMASTER_DELETE_SUCCESS,
  COURSEMASTER_FINDONE_ERR,
  COURSEMASTER_FINDONE_REQUEST,
  COURSEMASTER_FINDONE_SUCCESS,
  COURSEMASTER_FIND_ERR,
  COURSEMASTER_FIND_REQUEST,
  COURSEMASTER_FIND_SUCCESS,
  COURSEMASTER_UPDATE_ERR,
  COURSEMASTER_UPDATE_REQUEST,
  COURSEMASTER_UPDATE_SUCCESS,
  SUBJECTMASTER_BULKUPLOAD_ERR,
  SUBJECTMASTER_BULKUPLOAD_REQUEST,
  SUBJECTMASTER_BULKUPLOAD_SUCCESS,
  SUBJECTMASTER_CREATE_ERR,
  SUBJECTMASTER_CREATE_REQUEST,
  SUBJECTMASTER_CREATE_SUCCESS,
  SUBJECTMASTER_DELETE_ERR,
  SUBJECTMASTER_DELETE_REQUEST,
  SUBJECTMASTER_DELETE_SUCCESS,
  SUBJECTMASTER_FINDONE_ERR,
  SUBJECTMASTER_FINDONE_REQUEST,
  SUBJECTMASTER_FINDONE_SUCCESS,
  SUBJECTMASTER_FIND_ERR,
  SUBJECTMASTER_FIND_REQUEST,
  SUBJECTMASTER_FIND_SUCCESS,
  SUBJECTMASTER_UPDATE_ERR,
  SUBJECTMASTER_UPDATE_REQUEST,
  SUBJECTMASTER_UPDATE_SUCCESS,
} from "../constants/courseMajorMasterConstants";

//  courseMajor create reducer

export const courseMajorMasterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSEMASTER_CREATE_REQUEST:
      return {
        ...state,
        courseMajorCreateLoading: true,
      };
    case COURSEMASTER_CREATE_SUCCESS:
      return {
        ...state,
        courseMajorCreateLoading: false,
        courseMajorCreateSuccess: action.payload,
      };
    case COURSEMASTER_CREATE_ERR:
      return {
        ...state,
        courseMajorCreateLoading: false,
        courseMajorCreateErr: action.payload,
      };
    default:
      return state;
  }
};

//  courseMajor find reducer

export const courseMajorMasterFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSEMASTER_FIND_REQUEST:
      return {
        ...state,
        courseMajorFindLoading: true,
      };
    case COURSEMASTER_FIND_SUCCESS:
      return {
        ...state,
        courseMajorFindLoading: false,
        courseMajorFindSuccess: action.payload,
      };
    case COURSEMASTER_FIND_ERR:
      return {
        ...state,
        courseMajorFindLoading: false,
        courseMajorFindErr: action.payload,
      };
    default:
      return state;
  }
};

//  courseMajor findone reducer

export const courseMajorMasterFindOneReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSEMASTER_FINDONE_REQUEST:
      return {
        ...state,
        courseMajorFindOneLoading: true,
      };
    case COURSEMASTER_FINDONE_SUCCESS:
      return {
        ...state,
        courseMajorFindOneLoading: false,
        courseMajorFindOneSuccess: action.payload,
      };
    case COURSEMASTER_FINDONE_ERR:
      return {
        ...state,
        courseMajorFindOneLoading: false,
        courseMajorFindOneErr: action.payload,
      };
    default:
      return state;
  }
};

//  courseMajor update reducer

export const courseMajorMasterUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSEMASTER_UPDATE_REQUEST:
      return {
        ...state,
        courseMajorUpdateLoading: true,
      };
    case COURSEMASTER_UPDATE_SUCCESS:
      return {
        ...state,
        courseMajorUpdateLoading: false,
        courseMajorUpdateSuccess: action.payload,
      };
    case COURSEMASTER_UPDATE_ERR:
      return {
        ...state,
        courseMajorUpdateLoading: false,
        courseMajorUpdateErr: action.payload,
      };
    default:
      return state;
  }
};

//  courseMajor delete reducer

export const courseMajorMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSEMASTER_DELETE_REQUEST:
      return {
        ...state,
        courseMajorDeleteLoading: true,
      };
    case COURSEMASTER_DELETE_SUCCESS:
      return {
        ...state,
        courseMajorDeleteLoading: false,
        courseMajorDeleteSuccess: action.payload,
      };
    case COURSEMASTER_DELETE_ERR:
      return {
        ...state,
        courseMajorDeleteLoading: false,
        courseMajorDeleteErr: action.payload,
      };
    default:
      return state;
  }
};








//  subject create reducer

export const subjectMajorMasterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECTMASTER_CREATE_REQUEST:
      return {
        ...state,
        subjectMajorCreateLoading: true,
      };
    case SUBJECTMASTER_CREATE_SUCCESS:
      return {
        ...state,
        subjectMajorCreateLoading: false,
        subjectMajorCreateSuccess: action.payload,
      };
    case SUBJECTMASTER_CREATE_ERR:
      return {
        ...state,
        subjectMajorCreateLoading: false,
        subjectMajorCreateErr: action.payload,
      };
    default:
      return state;
  }
};

//  SUBJECT find reducer

export const subjectMajorMasterFindReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECTMASTER_FIND_REQUEST:
      return {
        ...state,
        subjectMajorFindLoading: true,
      };
    case SUBJECTMASTER_FIND_SUCCESS:
      return {
        ...state,
        subjectMajorFindLoading: false,
        subjectMajorFindSuccess: action.payload,
      };
    case SUBJECTMASTER_FIND_ERR:
      return {
        ...state,
        subjectMajorFindLoading: false,
        subjectMajorFindErr: action.payload,
      };
    default:
      return state;
  }
};

//  SUBJECT findone reducer

export const subjectMajorMasterFindOneReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECTMASTER_FINDONE_REQUEST:
      return {
        ...state,
        subjectMajorFindOneLoading: true,
      };
    case SUBJECTMASTER_FINDONE_SUCCESS:
      return {
        ...state,
        subjectMajorFindOneLoading: false,
        subjectMajorFindOneSuccess: action.payload,
      };
    case SUBJECTMASTER_FINDONE_ERR:
      return {
        ...state,
        subjectMajorFindOneLoading: false,
        subjectMajorFindOneErr: action.payload,
      };
    default:
      return state;
  }
};

//  SUBJECT update reducer

export const subjectMajorMasterUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECTMASTER_UPDATE_REQUEST:
      return {
        ...state,
        subjectMajorUpdateLoading: true,
      };
    case SUBJECTMASTER_UPDATE_SUCCESS:
      return {
        ...state,
        subjectMajorUpdateLoading: false,
        subjectMajorUpdateSuccess: action.payload,
      };
    case SUBJECTMASTER_UPDATE_ERR:
      return {
        ...state,
        subjectMajorUpdateLoading: false,
        subjectMajorUpdateErr: action.payload,
      };
    default:
      return state;
  }
};

//  SUBJECT delete reducer

export const subjectMajorMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECTMASTER_DELETE_REQUEST:
      return {
        ...state,
        subjectMajorDeleteLoading: true,
      };
    case SUBJECTMASTER_DELETE_SUCCESS:
      return {
        ...state,
        subjectMajorDeleteLoading: false,
        subjectMajorDeleteSuccess: action.payload,
      };
    case SUBJECTMASTER_DELETE_ERR:
      return {
        ...state,
        subjectMajorDeleteLoading: false,
        subjectMajorDeleteErr: action.payload,
      };
    default:
      return state;
  }
};


//  SUBJECT BULKUPLOAD REDUCER

export const subjectBulkUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBJECTMASTER_BULKUPLOAD_REQUEST:
      return {
        ...state,
        subjectMajorBulkUploadLoading: true,
      };
    case SUBJECTMASTER_BULKUPLOAD_SUCCESS:
      return {
        ...state,
        subjectMajorBulkUploadLoading: false,
        subjectMajorBulkUploadSuccess: action.payload,
      };
    case SUBJECTMASTER_BULKUPLOAD_ERR:
      return {
        ...state,
        subjectMajorBulkUploadLoading: false,
        subjectMajorBulkUploadErr: action.payload,
      };
    default:
      return state;
  }
};



//  courselevel create reducer

export const courseLevelMasterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSELEVELMASTER_CREATE_REQUEST:
      return {
        ...state,
        courseLevelCreateLoading: true,
      };
    case COURSELEVELMASTER_CREATE_SUCCESS:
      return {
        ...state,
        courseLevelCreateLoading: false,
        courseLevelCreateSuccess: action.payload,
      };
    case COURSELEVELMASTER_CREATE_ERR:
      return {
        ...state,
        courseLevelCreateLoading: false,
        courseLevelCreateErr: action.payload,
      };
    default:
      return state;
  }
};



//  courselevel find reducer

export const courseLevelMasterFindReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSELEVELMASTER_FIND_REQUEST:
      return {
        ...state,
        courseLevelFindLoading: true,
      };
    case COURSELEVELMASTER_FIND_SUCCESS:
      return {
        ...state,
        courseLevelFindLoading: false,
        courseLevelFindSuccess: action.payload,
      };
    case COURSELEVELMASTER_FIND_ERR:
      return {
        ...state,
        courseLevelFindLoading: false,
        courseLevelFindErr: action.payload,
      };
    default:
      return state;
  }
};



//  course level update reducer

export const courseLevelMajorMasterUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSELEVELMASTER_UPDATE_REQUEST:
      return {
        ...state,
        courseLevelMajorUpdateLoading: true,
      };
    case COURSELEVELMASTER_UPDATE_SUCCESS:
      return {
        ...state,
        courseLevelMajorUpdateLoading: false,
        courseLevelMajorUpdateSuccess: action.payload,
      };
    case COURSELEVELMASTER_UPDATE_ERR:
      return {
        ...state,
        courseLevelMajorUpdateLoading: false,
        courseLevelMajorUpdateErr: action.payload,
      };
    default:
      return state;
  }
};



//  course level delete reducer

export const courseLevelMajorMasterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSELEVELMASTER_DELETE_REQUEST:
      return {
        ...state,
        courseLevelMajorDeleteLoading: true,
      };
    case COURSELEVELMASTER_DELETE_SUCCESS:
      return {
        ...state,
        courseLevelMajorDeleteLoading: false,
        courseLevelMajorDeleteSuccess: action.payload,
      };
    case COURSELEVELMASTER_DELETE_ERR:
      return {
        ...state,
        courseLevelMajorDeleteLoading: false,
        courseLevelMajorDeleteErr: action.payload,
      };
    default:
      return state;
  }
};
