import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { Box, makeStyles, setRef } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  leadWorkDetailsAddAction,
  leadWorkDetailsDeleteAction,
  leadWorkDetailsFindAction
} from "../../../actions/leadActions/leadDocsActions";
import { SubmitButton } from "../../customComponent/Buttons";
import { TopInputFieldBorderLabel } from "../../customComponent/InputField";
import { HiOutlinePlus } from "react-icons/hi";
import Swal from "sweetalert2";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";

const useStyles = makeStyles((theme) => ({
  input: {
    height: "10px",
    border: "1px solid #ECECEC"
  },
  formLabel: {
    lineHeight: "13px"
  },
  outlinedInput: {
    // borderRadius: "13px",
    borderColor: "#ECECEC",
    border: "1px solid #ECECEC"
  }
}));

const WorkDetails = ({ handleNext, handleCompleteTick }) => {
  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage
  } = usePrivilegeCheck();

  const dispatch = useDispatch();
  const { id } = useParams();

  let { leadWorkDetailsFindingSuccess } = useSelector((state) => {
    return state.leadWorkDetailsFind;
  });

  let { leadWorkDetailsAddingSuccess } = useSelector((state) => {
    return state.leadWorkDetailsAdd;
  });

  let { leadWorkDetailsDeletingSuccess } = useSelector((state) => {
    return state.leadWorkDetailsDelete;
  });

  const [jobTitle, setJobTitle] = useState("");
  const [nameOfOrganization, setNameOfOrganization] = useState("");
  const [addressOfOrganization, setAddressOfOrganization] = useState("");
  const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState("");
  const [haveExperience, setHaveExperience] = useState(false);
  const [haveReferee, setHaveReferee] = useState(false);
  const [refereeName, setRefereeName] = useState("");
  const [position, setPosition] = useState("");
  const [institution, setInstitution] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [personKnow, setPersonKnow] = useState("");
  const [mobile, setMobile] = useState("");
  const [relationship, setRelationship] = useState("");
  const [address, setAddress] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [workDetailsData, setworkDetailsData] = useState([]);
  const [refereeDetails, setRefereeDetails] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(leadWorkDetailsFindAction(id));
  }, [
    dispatch,
    id,
    leadWorkDetailsAddingSuccess,
    leadWorkDetailsDeletingSuccess
  ]);

  useEffect(() => {
    if (leadWorkDetailsFindingSuccess) {
      setworkDetailsData(leadWorkDetailsFindingSuccess.leadWorkDeatails);
      setRefereeDetails(leadWorkDetailsFindingSuccess.leadRefereeDeatails);
      setHaveExperience(
        leadWorkDetailsFindingSuccess.leadWorkDeatails[0]?.haveExperience
      );
      setHaveReferee(
        leadWorkDetailsFindingSuccess.leadRefereeDeatails[0]?.haveRefereeDetails
      );
    }
  }, [leadWorkDetailsFindingSuccess]);

  // useEffect(() => {
  //   if (leadWorkDetailsFindingSuccess) {
  //     handleCompleteTick();
  //   }
  // }, [leadWorkDetailsFindingSuccess, handleNext]);

  // work details
  const handleAcademinHistoryAdd = () => {
    console.log(
      {
        jobTitle: jobTitle,
        nameOfOrganization: nameOfOrganization,
        addressOfOrganization: addressOfOrganization,
        organizationPhoneNumber: organizationPhoneNumber,
        startDate: startDate,
        endDate: endDate
      },
      "***********"
    );
    if (
      jobTitle !== "" &&
      nameOfOrganization !== "" &&
      addressOfOrganization !== "" &&
      organizationPhoneNumber !== "" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      const intakeExists = workDetailsData.some(
        (data) => data.jobTitle === jobTitle
      );

      if (intakeExists) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "this Name Of Organization alredy you added!"
        });
      } else {
        setworkDetailsData([
          ...workDetailsData,
          {
            jobTitle: jobTitle,
            nameOfOrganization: nameOfOrganization,
            addressOfOrganization: addressOfOrganization,
            organizationPhoneNumber: organizationPhoneNumber,
            startDate: startDate,
            endDate: endDate
          }
        ]);

        setJobTitle("");
        setNameOfOrganization("");
        setAddressOfOrganization("");
        setStartDate("");
        setEndDate("");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields!"
      });
    }
  };

  // Referee details

  const handleRefereeHistoryAdd = () => {
    console.log(
      {
        refereeName: refereeName,
        position: position,
        institution: institution,
        workEmail: workEmail,
        personKnow: personKnow,
        mobile: mobile,
        relationship: relationship,
        address: address
      },
      "***********"
    );
    if (
      refereeName !== "" &&
      position !== "" &&
      institution !== "" &&
      workEmail !== "" &&
      personKnow !== "" &&
      mobile !== "" &&
      relationship !== "" &&
      address !== ""
    ) {
      const intakeExists = refereeDetails.some(
        (data) => data.refereeName === refereeName
      );

      if (intakeExists) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "this Referee Name alredy you added!"
        });
      } else {
        setRefereeDetails([
          ...refereeDetails,
          {
            refereeName: refereeName,
            position: position,
            institution: institution,
            workEmail: workEmail,
            personKnow: personKnow,
            mobile: mobile,
            relationship: relationship,
            address: address
          }
        ]);

        setRefereeName("");
        setPosition("");
        setInstitution("");
        setWorkEmail("");
        setPersonKnow("");
        setMobile("");
        setRelationship("");
        setAddress("");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all fields!"
      });
    }
  };

  // delete academic history
  const handleAcademicDeleteClick = (nameOfOrganization, id, section) => {
    if (id) {
      dispatch(leadWorkDetailsDeleteAction(id, section));
    }
    const updatedTestData = workDetailsData.filter(
      (data) => data.nameOfOrganization !== nameOfOrganization
    );
    setworkDetailsData(updatedTestData);
  };

  // delete referee history
  const handleRefereeDeleteClick = (refereeName, id, section) => {
    if (id) {
      dispatch(leadWorkDetailsDeleteAction(id, section));
    }
    const updatedTestData = refereeDetails.filter(
      (data) => data.refereeName !== refereeName
    );
    console.log(updatedTestData);
    setRefereeDetails(updatedTestData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or other actions
    dispatch(
      leadWorkDetailsAddAction(
        workDetailsData,
        refereeDetails,
        id,
        haveExperience,
        haveReferee
      )
    );
    handleNext();
  };

  const handlePhone = (e) => {
    const phoneNumber = e.target.value;
    if (/^\d{0,10}$/.test(phoneNumber)) {
      setMobile(phoneNumber);
      setErrors((prev) => ({
        ...prev,
        ["whatsappNumber"]: ""
      }));
    } else {
      errors.whatsappNumber = "Invalid Whatsapp number";
    }
  };

  console.log(
    leadWorkDetailsFindingSuccess,
    "leadWorkDetailsFindingSuccessleadWorkDetailsFindingSuccess"
  );

  return (
    <form onSubmit={handleFormSubmit}>
      {/* High School Section */}

      <Grid item xs={12} sm={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="experience"
              checked={haveExperience}
              onChange={(e) => setHaveExperience(e.target.checked)}
              // helpertext={formErrors.sameAsPermanentAddress}
              // error={!!formErrors.sameAsPermanentAddress}
            />
          }
          label="Don’t have any work experience"
        />
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 700 }}>
            Work Details
          </Typography>
        </Box>

        <SubmitButton
          title="Add"
          submit="button"
          widthSize="100px"
          heightSize="30px"
          type="click"
          handleSubmit={handleAcademinHistoryAdd}
        />
        {/* 
        <Typography
          sx={{ mt: 2, fontSize: "20px", cursor: "pointer" }}
          variant="h6"
          gutterBottom
          onClick={handleAcademinHistoryAdd}
        >
          Add
        </Typography> */}
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#efedf0"
        }}
      >
        <Grid container spacing={2} sx={{ m: 1 }}>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveExperience}
              label="Job Title"
              handleChange={(e) => setJobTitle(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={jobTitle}
              required
              paramses
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveExperience}
              label="Name of Organization"
              handleChange={(e) => setNameOfOrganization(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={nameOfOrganization}
              required
              paramses
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveExperience}
              label="Address of Organization"
              handleChange={(e) => setAddressOfOrganization(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={addressOfOrganization}
              required
              paramses
              type="text"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Box
              sx={{
                "& .MuiFormHelperText-root.Mui-error": {
                  marginTop: "-10px"
                }
              }}
            >
              <TopInputFieldBorderLabel
                disabled={haveExperience}
                label="Organization Phone Number"
                handleChange={(e) => {
                  let value = e.target.value;
                  const phoneRegex = /^\d{0,10}$/;
                  console.log(phoneRegex.test(value));
                  if (phoneRegex.test(value) || e.target.value === "") {
                    setOrganizationPhoneNumber(e.target.value);
                    setErrors((prev) => ({
                      ...prev,
                      ["phoneNumber"]: ""
                    }));
                  } else {
                    setErrors((prev) => ({
                      ...prev,
                      ["phoneNumber"]: "Invalid phone number"
                    }));
                  }
                }}
                widthSize="fullWidth"
                heightSize="42px"
                value={organizationPhoneNumber}
                required
                paramses
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
                type="number"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveExperience}
              label="Start Date"
              handleChange={(e) => setStartDate(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={startDate}
              required
              paramses
              type="date"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveExperience}
              label="End Date"
              handleChange={(e) => setEndDate(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={endDate}
              required
              paramses
              type="date"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              // display: "flex",
              // justifyContent: "flex-end",
              textAlign: "center"
            }}
          >
            {/* <HiOutlinePlus
              style={{
                fontSize: "60px",
                margin: "10px",
                cursor: "pointer",
                width: "auto",
                height: "40px",
              }}
              onClick={handleAcademinHistoryAdd}
            /> */}
          </Grid>
        </Grid>
      </Box>
      {workDetailsData
        .filter((data) => data?.jobTitle) // Filter items with a valid jobTitle
        .map((data, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#efedf0",
              marginTop: "30px"
            }}
            key={index}
          >
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Job Title"
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.jobTitle}
                  required
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Name of Organization"
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.nameOfOrganization}
                  required
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Address of Organization"
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.addressOfOrganization}
                  required
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Organization Phone Number"
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.organizationPhoneNumber}
                  required
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Start Date"
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.startDate}
                  required
                  type="date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="End Date"
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.endDate}
                  required
                  type="date"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <img
                  src="/menuIcons/trash.png"
                  alt="gsl"
                  style={{
                    marginTop: "19px",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    handleAcademicDeleteClick(
                      data.nameOfOrganization,
                      data.id,
                      "work"
                    )
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ))}

      <Grid item xs={12} sm={4}>
        <FormControlLabel
          control={
            <Checkbox
              name="referre"
              checked={haveReferee}
              onChange={(e) => setHaveReferee(e.target.checked)}
              // helpertext={formErrors.sameAsPermanentAddress}
              // error={!!formErrors.sameAsPermanentAddress}
            />
          }
          label="Don’t have referee details"
        />
      </Grid>

      {/* Referee Details Section */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ mt: 2, fontWeight: 700 }} variant="h6" gutterBottom>
          Referee Details
        </Typography>

        <Box sx={{ mt: 2 }}>
          <SubmitButton
            title="Add"
            submit="button"
            widthSize="100px"
            heightSize="30px"
            type="click"
            handleSubmit={handleRefereeHistoryAdd}
          />
        </Box>

        {/* <Typography
          sx={{ mt: 2, fontSize: "20px", cursor: "pointer" }}
          variant="h6"
          gutterBottom
          onClick={handleRefereeHistoryAdd}
        >
          Add
        </Typography> */}
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#efedf0",
          paddingBottom: "15px"
        }}
      >
        <Grid container spacing={2} sx={{ m: 1 }}>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="Name"
              handleChange={(e) => setRefereeName(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={refereeName}
              required
              paramses
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="Position"
              handleChange={(e) => setPosition(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={position}
              required
              paramses
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="Institution"
              handleChange={(e) => setInstitution(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={institution}
              required
              paramses
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="Work Email"
              handleChange={(e) => setWorkEmail(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={workEmail}
              required
              paramses
              type="text"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="How long has the person know you?"
              handleChange={(e) => setPersonKnow(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={personKnow}
              required
              paramses
              type="text"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Box
              sx={{
                "& .MuiFormHelperText-root.Mui-error": {
                  marginTop: "-10px"
                }
              }}
            >
              <TopInputFieldBorderLabel
                disabled={haveReferee}
                label="Mobile"
                handleChange={handlePhone}
                widthSize="fullWidth"
                heightSize="42px"
                value={mobile}
                required
                error={Boolean(errors.whatsappNumber)}
                helperText={errors.whatsappNumber}
                paramses
                type="number"
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="Relationship"
              handleChange={(e) => setRelationship(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={relationship}
              required
              paramses
              type="text"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TopInputFieldBorderLabel
              disabled={haveReferee}
              label="Address"
              handleChange={(e) => setAddress(e.target.value)}
              widthSize="fullWidth"
              heightSize="42px"
              value={address}
              required
              paramses
              type="text"
            />
          </Grid>
        </Grid>
      </Box>

      {refereeDetails
        .filter((data) => data?.refereeName)
        .map((data, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#efedf0",
              marginTop: "30px"
            }}
            key={index}
          >
            <Grid container spacing={2} sx={{ m: 1 }}>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Name"
                  // handleChange={(e) => setResultInfo(e.target.value)}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.refereeName}
                  required
                  paramses
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Position"
                  // handleChange={(e) => setResultInfo(e.target.value)}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.position}
                  required
                  paramses
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Institution"
                  // handleChange={(e) => setResultInfo(e.target.value)}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.institution}
                  required
                  paramses
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Work Email"
                  // handleChange={(e) => setResultInfo(e.target.value)}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.workEmail}
                  required
                  paramses
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="How long has The Person you know ?"
                  // handleChange={(e) => setResultInfo(e.target.value)}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.personKnow}
                  required
                  paramses
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <TopInputFieldBorderLabel
                  label="Mobile"
                  handleChange={(e) => setEndDate(e.target.value)}
                  widthSize="fullWidth"
                  heightSize="42px"
                  value={data.mobile}
                  required
                  paramses
                  type="number"
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <img
                  src="/menuIcons/trash.png"
                  alt="gsl"
                  style={{
                    marginTop: "19px",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    handleRefereeDeleteClick(
                      data.refereeName,
                      data.id,
                      "referee"
                    )
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ))}

      {/* Submit Button */}

      {isUpdatePrevilage("All Leads") ? (
        <Grid
          item
          xs={12}
          textAlign="center"
          marginTop="1rem"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SubmitButton
            title="Save and Continue"
            submit="submit"
            widthSize="185px"
            heightSize="30px"
            type="submit"
            handleSubmit=""
          />
        </Grid>
      ) : (
        ""
      )}
    </form>
  );
};

export default WorkDetails;
