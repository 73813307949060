import { Box, Button, FormLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputField, {
  CustomDateField,
  SelectInputField,
} from "../../../customComponent/InputField";
import { MainContainer } from "../../../content/content.element";
import { InfoHeader } from "../../../customComponent/InfoHeader";
import { Editor } from "react-draft-wysiwyg";
import { SubmitButton, SwitchButton } from "../../../customComponent/Buttons";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { ieltsTeacherManagmentFindAction } from "../../../../actions/IeltsActions/teacherManagmentAction";
import { ieltsCategoryFindAction } from "../../../../actions/IeltsActions/learningManadmentAction";
import {
  ieltsCourseManagmentCourseFindAction,
  ieltsCourseManagmentCourseLiveClassCreateAction,
} from "../../../../actions/IeltsActions/courseManagmentAction";
import { EditorState, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import Swal from "sweetalert2";
import { ieltsSettingsZoomConfigFindAction } from "../../../../actions/IeltsActions/SettingsAction";

function IeltsLiveClassAddNewForm({ toggle }) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    course: "",
    category: "",
    teacherName: "",
    classTopic: "",
    time: dayjs(),
    duration: "",
    channel: "",
    thumbnailImage: "",
    discription: EditorState.createEmpty(),
    date: new Date(),
    isPaid: "",
  });
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [course, setCourse] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [category, setCategory] = useState([]);
  const [channel, setChannel] = useState([]);
  const [errorData, setErrorData] = useState({});
  const [isLiveClassPaid, setIsLiveClassPaid] = useState(false);

  let {
    ieltsCourseManagmentCourseFindSuccess,
    ieltsCourseManagmentCourseFinderror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseFind;
  });

  let {
    ieltsTeacherManagmentTeacherFindSuccess,
    ieltsTeacherManagmentTeacherFinderror,
  } = useSelector((state) => {
    return state.ieltsTeacherManagmentTeacherFind;
  });

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  let {
    ieltsSettingsZoomConfigFindSuccess,
    ieltsSettingsZoomConfigFinderror,
  } = useSelector((state) => {
    return state.ieltsSettingsZoomConfigFind;
  });

  useEffect(() => {
    dispatch(ieltsCourseManagmentCourseFindAction());
    dispatch(ieltsTeacherManagmentFindAction("courseTable"));
    dispatch(ieltsCategoryFindAction("courseTable"));
    dispatch(ieltsSettingsZoomConfigFindAction());
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCourseManagmentCourseFindSuccess) {
      setCourse(ieltsCourseManagmentCourseFindSuccess.data);
    }
    if (ieltsTeacherManagmentTeacherFindSuccess) {
      setTeacher(ieltsTeacherManagmentTeacherFindSuccess.data);
    }
    if (ieltsCategoryFindSuccess) {
      setCategory(ieltsCategoryFindSuccess.data);
    }
    if (ieltsSettingsZoomConfigFindSuccess) {
      setChannel(ieltsSettingsZoomConfigFindSuccess?.data);
    }
  }, [
    ieltsCourseManagmentCourseFindSuccess,
    ieltsTeacherManagmentTeacherFindSuccess,
    ieltsCategoryFindSuccess,
    ieltsSettingsZoomConfigFindSuccess,
  ]);

  console.log(formData.course, "course");
  console.log(
    ieltsTeacherManagmentTeacherFindSuccess,
    "ieltsTeacherManagmentTeacherFindSuccess"
  );
  console.log(formData.category, "category");
  console.log(channel, "channeleeeeeeeeeeeeeeeeeeeeeeeeeee");

  const handleEditorChange = (state) => {
    const contentState = state.getCurrentContent();
    const html = stateToHTML(contentState);

    setFormData((prevSlots) => ({
      ...prevSlots,
      discription: html,
    }));
    setErrorData((prev) => ({
      ...prev,
      ["discription"]: "",
    }));

    console.log("Raw Content:", html);
  };

  const handleDate = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ["date"]: value,
    }));
    setErrorData((prev) => ({
      ...prev,
      ["date"]: "",
    }));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "thumbnailImage") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
      setErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));
    } else {
      console.log(value, "valueeeeeeeeeeeeeeeeeeeeeee");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setErrorData((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  // Success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  let {
    ieltsCourseManagmentCourseLiveClassCreateSuccess,
    ieltsCourseManagmentCourseLiveClassCreateerror,
  } = useSelector((state) => {
    return state.ieltsCourseManagmentCourseLiveClassCreate;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentCourseLiveClassCreateSuccess?.status == true
    ) {
      Swal.fire(
        "Created",
        ieltsCourseManagmentCourseLiveClassCreateSuccess.message,
        "success"
      );
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      ieltsCourseManagmentCourseLiveClassCreateSuccess?.status == false
    ) {
      Swal.fire(
        "Error",
        ieltsCourseManagmentCourseLiveClassCreateSuccess.message,
        "warning"
      );
      dispatch(successTrueMsgAction(false));
    }
  }, [
    ieltsCourseManagmentCourseLiveClassCreateSuccess,
    successAlertMsgSuccess,
  ]);

  const validateForm = () => {
    console.log(formData.discription, "============================");
    let isValid = true;
    const newErr = { ...errorData };

    if (formData.thumbnailImage === "") {
      newErr.thumbnailImage = "Thumbnail cannot be empty";
      isValid = false;
    } else {
      newErr.thumbnailImage = "";
    }

    if (!formData.discription) {
      newErr.discription = "discription cannot be empty";
      isValid = false;
    } else {
      newErr.discription = "";
    }

    if (formData.course === "") {
      newErr.course = "Course cannot be empty";
      isValid = false;
    } else {
      newErr.course = "";
    }

    if (formData.category === "") {
      newErr.category = "Category cannot be empty";
      isValid = false;
    } else {
      newErr.category = "";
    }

    if (formData.teacherName === "") {
      newErr.teacherName = "Teacher name cannot be empty";
      isValid = false;
    } else {
      newErr.teacherName = "";
    }

    if (formData.classTopic === "") {
      newErr.classTopic = "Class topic cannot be empty";
      isValid = false;
    } else {
      newErr.classTopic = "";
    }

    if (formData.time === "") {
      newErr.time = "Time name cannot be empty";
      isValid = false;
    } else {
      newErr.time = "";
    }

    if (formData.duration === "") {
      newErr.duration = "Duration name cannot be empty";
      isValid = false;
    } else {
      newErr.duration = "";
    }

    if (formData.channel === "") {
      newErr.channel = "Channel name cannot be empty";
      isValid = false;
    } else {
      newErr.channel = "";
    }

    setErrorData(newErr);
    return isValid;
  };

  // handle form submission
  const onSubmit = () => {
    if (validateForm()) {
      console.log(formData, "formDataaaaaaaaaaaa");
      const formDatas = new FormData();
      formDatas.append("thumbnail", formData.thumbnailImage);
      formDatas.append("formDataValues", JSON.stringify(formData));
      dispatch(ieltsCourseManagmentCourseLiveClassCreateAction(formDatas));
      dispatch(successTrueMsgAction(true));
      setFormData({
        course: "",
        category: "",
        teacherName: "",
        classTopic: "",
        time: dayjs(),
        duration: "",
        channel: "",
        thumbnailImage: "",
        discription: EditorState.createEmpty(),
        date: new Date(),
        isPaid: false,
      });
    }
  };

  return (
    <MainContainer active={toggle}>
      <InfoHeader headTitle="Add Live Class" url="/IELTS" />
      <Box
        sx={{
          mt: "30px",
        }}
      >
        <Box
          sx={{
            gap: "20px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  lg: "1fr 1fr",
                },
                gap: "20px",
              }}
            >
              <Box>
                <SelectInputField
                  handleSelectChange={handleChange}
                  name={"course"}
                  value={formData.course}
                  label="Select Course"
                  mapValue={course}
                  mapMethod={"ieltsLiveClass"}
                />
                {errorData?.course && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.course}
                  </Typography>
                )}
              </Box>
              <Box>
                <SelectInputField
                  handleSelectChange={handleChange}
                  name={"category"}
                  value={formData.category}
                  label="Select Category"
                  mapValue={category}
                  mapMethod={"ieltsLiveClassCategory"}
                />
                {errorData?.category && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.category}
                  </Typography>
                )}
              </Box>
              <Box>
                <SelectInputField
                  handleSelectChange={handleChange}
                  name={"teacherName"}
                  value={formData.teacherName}
                  label={"Teacher Name"}
                  mapValue={teacher}
                  mapMethod={"ieltsLiveClassTeacher"}
                />
                {errorData?.teacherName && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.teacherName}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  "& .input_style": {
                    width: "calc(100% - 10px) !important",
                    maxWidth: "100%",
                  },
                  "& .input-group": {
                    mb: "0",
                  },
                }}
              >
                <InputField
                  label="Live class topic"
                  name="classTopic"
                  handleChange={handleChange}
                  InputType="text"
                  value={formData.classTopic}
                />
                {errorData?.classTopic && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.classTopic}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                }}
              >
                <CustomDateField
                  name="date"
                  value={formData.date}
                  handleChange={handleDate}
                  minDate={new Date()}
                />
                {errorData?.date && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.date}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    lg: "1fr 1fr",
                  },
                  gap: "20px",
                  "& .input_style": {
                    width: "calc(100% - 10px) !important",
                  },
                  "& .input-group": {
                    mb: "0",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "46px",
                        "& input": {
                          boxSizing: "border-box",
                          height: "100%",
                        },
                      }}
                      value={formData.time}
                      name={"time"}
                      onChange={(value) => {
                        console.log(value.$d, "valueeeeeeeeeeeeeeeeeeee");
                        const formattedTime = value.$d.toLocaleTimeString(
                          "en-US",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        );

                        console.log(
                          formattedTime,
                          "formattedTimeformattedTime"
                        );
                        setFormData({
                          ...formData,
                          time: formattedTime,
                        });
                        // setFormData({ ...formData, timeInIsoFormat: value.$d });
                      }}
                    />
                  </LocalizationProvider>
                  {errorData?.time && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errorData?.time}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <InputField
                    label="Duration"
                    name="duration"
                    handleChange={handleChange}
                    // InputType="time"
                    value={formData.duration}
                  />
                  {errorData?.duration && (
                    <Typography sx={{ color: "red", fontSize: "12px" }}>
                      {errorData?.duration}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  lg: "1fr 1fr",
                },
                alignItems: "end",
                gap: "20px",
                mt: "20px",
              }}
            >
              <Box>
                <SelectInputField
                  handleSelectChange={handleChange}
                  name={"channel"}
                  value={formData.channel}
                  label={"Meeting Channel"}
                  mapValue={channel}
                  mapMethod={"channel"}
                />
                {errorData?.channel && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.channel}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(5, 5, 15, 1)",
                    mb: "15px",
                    fontSize: "16px",
                  }}
                >
                  Thumbnail Image (
                  <Typography
                    component={"span"}
                    sx={{ fontSize: "12px", color: "rgba(254, 11, 122, 1)" }}
                  >
                    jpg, 100px * 100px
                  </Typography>
                  )
                </Typography>
                {formData.thumbnailImage !== "" ? (
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: "46px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      "& svg": {
                        cursor: "pointer",
                        mr: "10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        paddingX: "10px",
                      }}
                    >
                      {formData?.thumbnailImage?.name}
                    </Typography>
                    <svg
                      onClick={() => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          ["thumbnailImage"]: "",
                        }));
                      }}
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="1.63672"
                        width="2.31394"
                        height="23.1394"
                        rx="1.15697"
                        transform="rotate(-45 0 1.63672)"
                        fill="#D9D9D9"
                      />
                      <rect
                        x="16.3594"
                        width="2.31394"
                        height="23.1394"
                        rx="1.15697"
                        transform="rotate(45 16.3594 0)"
                        fill="#D9D9D9"
                      />
                    </svg>
                  </Box>
                ) : (
                  <FormLabel
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      width: "100%",
                      //   height: "56px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: "46px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      sx={{
                        pl: "15px",
                      }}
                    >
                      Choose File
                    </Typography>
                    <input
                      type="file"
                      onChange={handleChange}
                      name={"thumbnailImage"}
                      hidden
                      accept="image/*"
                    />
                    <Box
                      sx={{
                        color: "rgba(30, 136, 229, 1)",
                        padding: "4px 31px",
                        background: "rgba(235, 242, 253, 1)",
                        mt: "5px",
                        mb: "5px",
                        mr: "5px",
                      }}
                    >
                      {" "}
                      Browse
                    </Box>
                  </FormLabel>
                )}
                {errorData?.thumbnailImage && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {errorData?.thumbnailImage}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ ml: -2, mt: 4 }}>
          <SwitchButton
            label="Paid"
            handleChange={() => {
              setIsLiveClassPaid(!isLiveClassPaid);
              setFormData({ ...formData, isPaid: !isLiveClassPaid });
            }}
            checked={isLiveClassPaid}
          />
        </Box>

        <Box
          sx={{
            mt: "24px",
            overflow: "hidden",
            "& .rdw-editor-main::-webkit-scrollbar": {
              display: "none",
            },
            "& .rdw-editor-main": {
              border: `1px solid #ECECEC`,
              // rgba(0, 0, 0, 0.23)
              padding: "0 16px",
              minHeight: "200px",
              borderRadius: "4px",
            },
          }}
        >
          <Typography
            sx={{
              color: "#05050F",
              mb: "24px",
            }}
          >
            Description
          </Typography>
          <Box
            sx={{
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Editor
              // editorState={formData?.discription}
              onEditorStateChange={handleEditorChange}
              placeholder="Content"
              wrapperClassName="rdw-editor-wrapper"
              editorClassName="rdw-editor-main"
            />
          </Box>
          {errorData?.discription && (
            <Typography sx={{ color: "red", fontSize: "12px" }}>
              {errorData?.discription}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          mt: "50px",
          mb: "50px",
        }}
      >
        <SubmitButton handleSubmit={onSubmit} type="click" title={"Save"}>
          Save
        </SubmitButton>
      </Box>
    </MainContainer>
  );
}

export default IeltsLiveClassAddNewForm;
