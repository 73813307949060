import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  createRoleSubMenuAction,
  createSelectAllPrivilageAction,
  findRoleSubMenuAction,
  getAllMainMenuWithSubAction
} from "../../../../actions/roleMangmentAction";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import AccessDeniedIcon from "../../../Leads/createLeads/AccessDeniedIcon";
import usePrivilegeCheck from "../../../../constants/customHooks/UsePrivilegeCheck";
import { SwitchButton } from "../../../customComponent/Buttons";

function RoleMenuShows() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { privilege, hasPrivilege, isrolePermissionChangePrevilage } =
    usePrivilegeCheck();

  const [listMenu, setListMenu] = useState([]);
  const [listRoleSub, setListRoleSub] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [isSelecteAll, setIsSelecteAll] = useState([]);

  const { findAllMenuWithSubSuccess, findAllMenuWithSubLoading } = useSelector(
    (state) => {
      return state.getAllMainMenuWithSub;
    }
  );

  const { findRoleSubSuccessSuccess, findRoleSubSuccessLoading } = useSelector(
    (state) => {
      return state.findRoleSubMenu;
    }
  );
  const { createRoleSubSuccessSuccess } = useSelector((state) => {
    return state.createRoleSubMenu;
  });

  const { createSelectAllPrivilageSuccess } = useSelector((state) => {
    return state.createSelectAllPrivilage;
  });

  useEffect(() => {
    dispatch(getAllMainMenuWithSubAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findRoleSubMenuAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (findAllMenuWithSubSuccess) {
      setListMenu(findAllMenuWithSubSuccess?.data);
      setIsSelecteAll(
        findAllMenuWithSubSuccess?.data.map((value) => {
          return value?.sub_menus?.every((subMenu) =>
            findRoleSubSuccessSuccess?.data?.some(
              (item) => subMenu.id == item.subId
            )
          );
        })
      );
    }
    if (findRoleSubSuccessSuccess) {
      setListRoleSub(findRoleSubSuccessSuccess?.data);
    }
  }, [dispatch, findAllMenuWithSubSuccess, findRoleSubSuccessSuccess]);



  const subModuleChange = (e, mainMenuId, subId) => {
    const isChecked = e.target.checked;
    const updatedListRoleSub = listRoleSub.map((item) =>
      item.subId === subId ? { ...item, isChecked } : item
    );
    setListRoleSub(updatedListRoleSub);
    dispatch(createRoleSubMenuAction(isChecked, mainMenuId, subId, id));
  };

  function capitalizeFirstLetterOfEachWord(string) {
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
  const handleSwitchChange = (index) => {
    const newSwitchStates = [...isSelecteAll];
    newSwitchStates[index] = !newSwitchStates[index];
    setIsSelecteAll(newSwitchStates);
    dispatch(
      createSelectAllPrivilageAction(
        newSwitchStates[index],
        listMenu[index].id,
        id
      )
    );
  };

  console.log(
    isSelecteAll,
    "isSelecteAllisSelecteAllisSelecteAll"
  );
  console.log(
    findRoleSubSuccessSuccess,
    "findRoleSubSuccessSuccessfindRoleSubSuccessSuccess"
  );

  return (
    <>
      {!findAllMenuWithSubLoading && !findRoleSubSuccessLoading ? (
        <Box
          sx={{
            gap: "20px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(600px, 1fr))",
            position: "relative"
          }}
        >
          {!isrolePermissionChangePrevilage("role managment") && (
            <Box
              sx={{
                position: "absolute",
                right: "0",
                top: "0",
                left: "0",
                bottom: "0",
                zIndex: "9998"
              }}
              onClick={() => setOpenAlert(true)}
            ></Box>
          )}
          {listMenu?.map(
            (item, index) =>
              item?.sub_menus.length > 0 && (
                <Box
                  sx={{
                    height: "fit-content"
                  }}
                  key={item.id}
                >
                  <Box
                    sx={{
                      border: "1px solid #ECECEC",
                      borderRadius: "8px",
                      overflow: "hidden"
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#F9F9FB",
                        padding: "8px 15px",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        sx={{
                          padding: "8px 15px",
                          fontSize: "16px"
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <SwitchButton
                        checked={isSelecteAll[index]}
                        handleChange={() => handleSwitchChange(index)}
                      />
                    </Box>
                    <Box
                      sx={{
                        padding: "15px 23px",
                        gap: "10px",
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit,minmax(200px, 1fr))"
                      }}
                    >
                      {item?.sub_menus?.map((innerItem) => {
                        return findRoleSubSuccessSuccess?.data?.some(
                          (data) => data.subId == innerItem.id
                        ) ? (
                          <Grid item xs={5} key={innerItem.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    subModuleChange(e, item.id, innerItem.id)
                                  }
                                  defaultChecked
                                  sx={{
                                    color: pink[800],
                                    "&.Mui-checked": {
                                      color: pink[600]
                                    }
                                  }}
                                />
                              }
                              label={
                                innerItem?.name
                                  ? capitalizeFirstLetterOfEachWord(innerItem.name)
                                  : ""
                              }
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={5} key={innerItem.id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    subModuleChange(e, item.id, innerItem.id)
                                  }
                                  checked={
                                    findRoleSubSuccessSuccess?.data?.find(
                                      (data) => data.subId === innerItem.id
                                    )?.isChecked
                                  }
                                  sx={{
                                    color: pink[800],
                                    "&.Mui-checked": {
                                      color: pink[600]
                                    }
                                  }}
                                />
                              }
                              label={
                                innerItem?.name
                                  ? capitalizeFirstLetterOfEachWord(innerItem.name)
                                  : ""
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              )
          )}
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircularProgress variant="outlined" />
        </Box>
      )}

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Close
            sx={{
              cursor: "pointer"
            }}
            onClick={() => setOpenAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700"
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px"
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px"
          }}
        >
          Sorry, you don’t have permission
        </Typography>
      </Dialog>
    </>
  );
}

export default RoleMenuShows;
