import React, { useEffect, useState } from "react";
import CategoryTable from "./categoryTable";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ieltsCategoryFindAction } from "../../../actions/IeltsActions/learningManadmentAction";

function CategoriesMain() {
  const dispatch = useDispatch();
  const columns = [
    { title: "SL NO", field: "sl" },
    { title: "CATEGORY NAME", field: "category Name" },
    { title: "STATUS", field: "status" },
  ];

  const [categoryList, setCategoryList] = useState([]);

  // Find Category Response

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  let {
    ieltsCategoryStatusChangeSuccess,
    ieltsCategoryStatusChangeerror,
  } = useSelector((state) => {
    return state.ieltsCategoryStatusChange;
  });

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("categoryTable"));
  }, [dispatch, ieltsCategoryStatusChangeSuccess]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  console.log(categoryList, "categoryListcategoryListcategoryList");

  const data = [
    {
      sl: 1,
      column1: "Speaking",
      column2: "Active",
    },
    {
      sl: 2,
      column1: "Reading",
      column2: "Active",
    },
    {
      sl: 3,
      column1: "Listening",
      column2: "Active",
    },
    {
      sl: 4,
      column1: "Writing",
      column2: "Active",
    },
    {
      sl: 5,
      column1: "Question Bank",
      column2: "Active",
    },
    {
      sl: 6,
      column1: "Exam Tips",
      column2: "Active",
    },
    {
      sl: 7,
      column1: "Grammar",
      column2: "Active",
    },
    {
      sl: 8,
      column1: "Live Class",
      column2: "Active",
    },
  ];

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <CategoryTable
          data={categoryList}
          columns={columns}
          component="ieltsCategory"
        />
      </Box>
    </>
  );
}

export default CategoriesMain;
