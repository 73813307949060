import React, { useEffect, useState } from "react";
import { MainContainer } from "../../content/content.element";
import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { InfoHeader } from "../../customComponent/InfoHeader";
import InputField, { SelectInputField } from "../../customComponent/InputField";
import { SubmitButton, SwitchButton } from "../../customComponent/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
  ieltsCategoryFindAction,
  ieltsLearningManagmentCreateTopicAction,
} from "../../../actions/IeltsActions/learningManadmentAction";
import TopicUploadComponent from "../../customComponent/TopicUploadComponent";
import { successTrueMsgAction } from "../../../actions/globalAction";
import Swal from "sweetalert2";
import Upload from "../../customComponent/Upload";

function AddNewTopicsForm(props) {
  let isUserExist = localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : null;

  const leadId = isUserExist.UserId;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedVideo, setCheckedVideo] = useState(false);
  const [checkedAudio, setCheckedAudio] = useState(false);
  const [checkedNote, setCheckedNote] = useState(false);
  const [topicName, setTopicName] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [audioDuration, setAudioDuration] = useState("");
  const [uploadedAudio, setUploadedAudio] = useState("");
  const [uploadedNote, setUplodedNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({
    topicName: "",
    category: "",
    videoDuration: "",
    video: "",
    audio: "",
    note: "",
    audioDuration: "",
    all: "",
  });

  let { ieltsCategoryFindSuccess, ieltsCategoryFinderror } = useSelector(
    (state) => {
      return state.ieltsCategoryList;
    }
  );

  let {
    ieltsLearningManagmentCreateTopicSuccess,
    ieltsLearningManagmentCreateTopicerror,
  } = useSelector((state) => {
    return state.ieltslearningManagmentCreateTopic;
  });

  // Find all topics

  useEffect(() => {
    dispatch(ieltsCategoryFindAction("topicsAdd"));
  }, [dispatch]);

  useEffect(() => {
    if (ieltsCategoryFindSuccess) {
      setCategoryList(ieltsCategoryFindSuccess.data);
    }
  }, [ieltsCategoryFindSuccess]);

  // success message

  let { successAlertMsgSuccess } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (
      successAlertMsgSuccess &&
      ieltsLearningManagmentCreateTopicSuccess?.status == true
    ) {
      setLoading(false);
      Swal.fire("Created", "Topic created successfully", "success");
      dispatch(successTrueMsgAction(false));
    }
  }, [ieltsLearningManagmentCreateTopicSuccess, successAlertMsgSuccess]);

  //   handleSaveTopic Function

  const handleSaveTopic = () => {
    let isValid = true;
    const currentErr = { ...err };
    if (!selectedCategory) {
      currentErr.category = "Enter category name";
      isValid = false;
    }
    if (!topicName) {
      currentErr.topicName = "Enter Topic name";
      isValid = false;
    }

    if (!uploadedVideo && !uploadedAudio && !uploadedNote) {
      currentErr.all = "Add any one Audio,Video,Note";
      isValid = false;
    }
    setErr(currentErr);
    if (!isValid) {
      return;
    }

    const formData = new FormData();
    formData.append("Topicname", topicName);
    formData.append("Category", selectedCategory);
    formData.append("videoDuration", videoDuration);
    formData.append("video", uploadedVideo);
    formData.append("audioDuration", audioDuration);
    formData.append("audio", uploadedAudio);
    formData.append("note", uploadedNote);
    formData.append("leadId", leadId);
    setLoading(true);
    dispatch(ieltsLearningManagmentCreateTopicAction(formData));
    dispatch(successTrueMsgAction(true));
    setTopicName("");
    setSelectedCategory("");
    setVideoDuration("");
    setUploadedVideo("");
    setAudioDuration("");
    setUploadedAudio("");
    setUplodedNote("");
  };

  return (
    <MainContainer active={props.toggle}>
      {loading && <Upload />}
      <InfoHeader headTitle="Add Topic" url="/IELTS" />
      <div style={{ marginTop: "30px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              "& .input-group": {
                mb: "0px",
              },
            }}
          >
            <InputField
              label="Topic Name"
              handleChange={(e) => {
                setTopicName(e.target.value);
                setErr({ all: "" });
              }}
              widthSize="98.4%"
              value={topicName}
              required
              paramses
              InputType="text"
              dispcriptionHeight
            />
            <span
              style={{
                color: "red",
                paddingLeft: "10px",
                fontWeight: 400,
                fontSize: "12px",
                mb: "-10px",
              }}
            >
              {err.topicName}
            </span>
          </Grid>
          <Grid item lg={4} xs={12}>
            <SelectInputField
              label="Select Category"
              handleSelectChange={(e) => {
                setSelectedCategory(e.target.value);
                setErr({ all: "" });
              }}
              widthSize="110px"
              value={selectedCategory}
              mapValue={categoryList}
              mapMethod="ieltsCategory"
            />
            <span
              style={{
                color: "red",
                paddingLeft: "10px",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              {err.category}
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SwitchButton
              label="Video"
              handleChange={() => {
                setCheckedVideo(!checkedVideo);
                if (checkedVideo == false) {
                  setUploadedVideo("");
                }
              }}
              checked={checkedVideo}
            />
          </Grid>
        </Grid>
        {checkedVideo ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item lg={4} xs={12}>
              <InputField
                label="Video Duration"
                handleChange={(e) => {
                  setVideoDuration(e.target.value);
                  setErr({ all: "" });
                }}
                widthSize="98.4%"
                value={videoDuration}
                required
                paramses
                // InputType="time"
                dispcriptionHeight
              />
              <span
                style={{
                  fontSize: "12px",
                  color: "#777AAE",
                  marginTop: "-15px",
                  display: "block",
                }}
              >
                Enter values in "00:00:00" format
              </span>
              <span
                style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
              >
                {err.videoDuration}
              </span>
            </Grid>
            {!uploadedVideo ? (
              <Grid item lg={4} xs={12}>
                <FormLabel
                  sx={{
                    //   width: "98.4%",
                    height: "46px",
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    alignItems: "center",
                    pl: "14px",
                    pr: "14px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: uploadedVideo?.name ? "#05050F" : "#ACB1C6",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {uploadedVideo?.name != null
                      ? uploadedVideo?.name
                      : "Choose File"}
                  </Typography>
                  <input
                    type="file"
                    hidden
                    name="logoFile"
                    onChange={(e) => {
                      setUploadedVideo(e.target.files[0]);
                      setErr({ all: "" });
                    }}
                    accept="video/*"
                  />
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      background: "#EBF2FD",
                      color: "#1E88E5",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Browse
                  </Box>
                </FormLabel>
                <span
                  style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
                >
                  {err.video}
                </span>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  width: "100%",
                  gridTemplateColumns: "repeat(4,234px)",
                  padding: "30px 20px",
                  background: "#F5F5F5",
                }}
              >
                <Box>
                  <TopicUploadComponent
                    type="video"
                    title={uploadedVideo?.name}
                    handleClick={() => setUploadedVideo("")}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        ) : (
          ""
        )}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SwitchButton
              label="Audio"
              handleChange={() => {
                setCheckedAudio(!checkedAudio);
                if (checkedAudio == false) {
                  setUploadedAudio("");
                }
              }}
              checked={checkedAudio}
            />
          </Grid>
        </Grid>
        {checkedAudio ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item lg={4} xs={12}>
              <InputField
                label="Audio Duration"
                handleChange={(e) => {
                  setAudioDuration(e.target.value);
                  setErr({ all: "" });
                }}
                widthSize="98.4%"
                value={audioDuration}
                required
                paramses
                // InputType="time"
                dispcriptionHeight
              />
              <span
                style={{
                  fontSize: "12px",
                  color: "#777AAE",
                  marginTop: "-15px",
                  display: "block",
                }}
              >
                Enter values in "00:00:00" format
              </span>
              <span
                style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
              >
                {err.audioDuration}
              </span>
            </Grid>
            {!uploadedAudio ? (
              <Grid item lg={4} xs={12}>
                <FormLabel
                  sx={{
                    //   width: "98.4%",
                    height: "46px",
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    alignItems: "center",
                    pl: "14px",
                    pr: "14px",
                  }}
                  //   onChange={handleChange}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: uploadedAudio.name ? "#05050F" : "#ACB1C6",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {uploadedAudio?.name != null
                      ? uploadedAudio.name
                      : " Choose File"}
                  </Typography>
                  <input
                    type="file"
                    hidden
                    name="logoFile"
                    onChange={(e) => {
                      setUploadedAudio(e.target.files[0]);
                      setErr({ all: "" });
                    }}
                    accept=".mp3,audio/mpeg3,!audio/webm"
                  />
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      background: "#EBF2FD",
                      color: "#1E88E5",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Browse
                  </Box>
                </FormLabel>
                <span
                  style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
                >
                  {err.audio}
                </span>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,234px)",
                  padding: "30px 20px",
                  background: "#F5F5F5",
                  width: "100%",
                }}
              >
                <Box>
                  <TopicUploadComponent
                    type="audio"
                    title={uploadedAudio?.name}
                    handleClick={() => setUploadedAudio("")}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        ) : (
          ""
        )}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SwitchButton
              label="Note"
              handleChange={() => {
                setCheckedNote(!checkedNote);
                setErr({ all: "" });
                if (checkedNote == false) {
                  setUplodedNote("");
                }
              }}
              checked={checkedNote}
            />
          </Grid>
        </Grid>
        {checkedNote ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {!uploadedNote ? (
              <Grid item lg={4} xs={12}>
                <FormLabel
                  sx={{
                    //   width: "98.4%",
                    height: "46px",
                    border: "1px solid #C4C4C4",
                    display: "flex",
                    alignItems: "center",
                    pl: "14px",
                    pr: "14px",
                  }}
                  //   onChange={handleChange}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: uploadedNote.name ? "#05050F" : "#ACB1C6",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {uploadedNote?.name != null
                      ? uploadedNote.name
                      : "Choose File"}
                  </Typography>
                  <input
                    type="file"
                    hidden
                    name="logoFile"
                    onChange={(e) => {
                      setUplodedNote(e.target.files[0]);
                      setErr({ all: "" });
                    }}
                    accept=".pdf, .doc, .docx"
                  />
                  <Box
                    sx={{
                      cursor: "pointer",
                      padding: "8px 18px",
                      background: "#EBF2FD",
                      color: "#1E88E5",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Browse
                  </Box>
                </FormLabel>
                <span
                  style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
                >
                  {err.note}
                </span>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "grid",
                  width: "100%",
                  gridTemplateColumns: "repeat(4,234px)",
                  padding: "30px 20px",
                  background: "#F5F5F5",
                }}
              >
                <Box>
                  <TopicUploadComponent
                    type="pdf"
                    title={uploadedNote?.name}
                    handleClick={() => setUplodedNote("")}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        ) : (
          ""
        )}

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={4} xs={12}>
            <SubmitButton
              title="Save Topics"
              submit=""
              widthSize="123px"
              heightSize="38px"
              type="click"
              handleSubmit={handleSaveTopic}
            />
            <span
              style={{ color: "red", paddingLeft: "10px", fontWeight: 600 }}
            >
              {err.all}
            </span>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
}

export default AddNewTopicsForm;
