import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, TextField, Typography } from "@mui/material";

import Select from "react-select";
import { countries } from "countries-list";
import { useDispatch, useSelector } from "react-redux";
import { locationFindOneAction, locationUpdateAction } from "../../../../actions/locationAction";

const allCountries = Object.entries(countries).map(([code, country]) => ({
  value: code,
  label: `${country.name} (+${country.phone})`,
  phone: `+${country.phone}`
}));

function EditLocation({ status, editMasterCategryHandler, id }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(status);
  const [scroll, setScroll] = useState("paper");
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryId, setCountryId] = useState("");
  const [location, setLocation] = useState("");
  const [errors, setErrors] = useState();

  let { locationFindOneSuccess } = useSelector((state) => {
    return state.LocationFindOne;
  });

  useEffect(() => {
    dispatch(locationFindOneAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (locationFindOneSuccess) {
      setCountryId(locationFindOneSuccess.data.id);
      setSelectedCountry({
        value: locationFindOneSuccess.data?.countryCode,
        label: locationFindOneSuccess?.data?.countryName,
        phone: locationFindOneSuccess?.data?.phoneCode
      });
      setLocation(locationFindOneSuccess?.data?.location)
    }
  }, [locationFindOneSuccess]);

  const handleClose = () => {
    setOpen(false);
    editMasterCategryHandler(status, false);
  };

  const handleSubmit = () => {
    const validate = () => {
      const newErrors = {};
      if (selectedCountry === "") {
        newErrors.selectedCountry = "Country is required";
      }
      if (location === "") {
        newErrors.location = "Location is required";
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
    if (validate()) {
      dispatch(locationUpdateAction(selectedCountry,location,id));
      handleClose();
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  //   form

  const handleCountryChange = (selectedOption) => {
    setErrorMsg(false);
    setSelectedCountry(selectedOption);
  };


  console.log(locationFindOneSuccess,"locationFindOneSuccesslocationFindOneSuccess")

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Edit Location</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div style={{ width: "500px", height: "400px" }}>
              {errorMsg && (
                <Typography align="center" color="error">
                  Please Choose Country
                </Typography>
              )}
              <Select
                options={allCountries}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select a country"
                style={{ width: "100%", height: "50px" }}
              />
              {selectedCountry && (
                <Grid container>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      label="Country Code"
                      value={selectedCountry.value}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      label="Phone Code"
                      value={countries[selectedCountry.value].phone}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextField
                  label="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  fullWidth
                  required
                />
                <Typography
                  sx={{
                    color: "#FF2400",
                    fontSize: "12px"
                  }}
                >
                  {errors?.location}
                </Typography>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditLocation;
