import React, { useEffect, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { RiDeleteBin6Line } from "react-icons/ri";
import { Box } from "@mui/system";
import { SlOptionsVertical } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import {
  CountryMasterDeleteAction,
  findcountryMasterAddAction,
} from "../../../../actions/countryMasterAction";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { Skeleton, Typography } from "@mui/material";
import { MasterTable } from "../MasterTable";
import { SUCCESS_TRUE_MSG_ERR } from "../../../../constants/roleManagmentConstant";
import { successTrueMsgAction } from "../../../../actions/globalAction";
import {
  COUNTRY_MASTER_ADD_ERR,
  COUNTRY_MASTER_ADD_SUCCESS,
} from "../../../../constants/countryMasterConstant";
import AddLocation from "./AddLocation";
import EditLocation from "./EditLocation";
import { locationFindAction } from "../../../../actions/locationAction";
import { LOCATION_CREATE_SUCCESS, LOCATION_UPDATE_SUCCESS } from "../../../../constants/locationConstants";

const ITEM_HEIGHT = 48;
function LocationMaster({
  addCountryFormOpen,
  formStatusHandler,
  subMenu,
  searchHandle,
  handleClosePopUp
}) {
  const [open, setOpen] = useState(addCountryFormOpen);

  const dispatch = useDispatch();

  const [locationData, setLocationData] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [tableId, setTableId] = useState("");

  // selectors
 
  let { locationCreateSuccess} = useSelector((state) => {
    return state.LocationCreate;
  });

  let { locationFindLoading,locationFindSuccess} = useSelector((state) => {
    return state.LocationFind;
  });

  let {locationDeleteSuccess} = useSelector((state) => {
    return state.LocationDelete;
  });

  let {locationUpdateSuccess} = useSelector((state) => {
    return state.LocationUpdate;
  });


  
  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  useEffect(() => {
    dispatch(locationFindAction());
  }, [dispatch,locationCreateSuccess,locationDeleteSuccess,locationUpdateSuccess]);

  useEffect(() => {
    setOpen(addCountryFormOpen)
  }, [addCountryFormOpen]);

  useEffect(()=>{
    setLocationData(locationFindSuccess?.data)
  },[locationFindSuccess])

  console.log(locationFindSuccess,"locationFindSuccesslocationFindSuccess")

  useEffect(() => {
    if (locationCreateSuccess) {
      Swal.fire("Good job!", `created a new location`, "success").then((value) =>
        dispatch({ type: LOCATION_CREATE_SUCCESS, payload: false })
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }

    if (locationUpdateSuccess) {
      Swal.fire("Good job!", `Updated a new location`, "success").then((value) =>
        dispatch({ type: LOCATION_UPDATE_SUCCESS, payload: false })
      );
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }

  }, [successAlertMsgSuccess, locationCreateSuccess,locationUpdateSuccess,dispatch]);

 
  const addMasterCategryHandler = (category, status) => {
    setOpen(status);
    formStatusHandler(status);
  };

  // edit form
  const editMasterCategryHandler = (category, status) => {
    setEditForm(status);
  };

  // navigate to Edit page
  const rollEditHandler = (id) => {
    setTableId(id);
    setEditForm(true);
  };

  // delete action
  const RoledeleteHandler = (id) => {
    setAnchorEl(false);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(CountryMasterDeleteAction(tableId));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  // action button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setTableId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      title: "Sl no",
      field: "sl",
      // type: "numeric",
      // hidden: true,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "CountryName",
      field: "CountryName",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
    {
      title: "Location",
      field: "Location",
      //   hidden: showEmployeeNameColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
 
    {
      title: "Updated By",
      field: "updatedby",
      //   hidden: showRollColumn,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },

    {
      title: "Action",
      field: "action",
      filtering: false,
      headerStyle: { backgroundColor: "#F9F9FB" },
    },
  ];

  const data = locationData?.map((data, index) => ({
    id: data.id,
    sl: index + 1,
    column1: data.countryName,
    column2: data.location,
    column3: data.updatedBy,
    action: (
      <>
        <SlOptionsVertical
          aria-label="more"
          id="long-button"
          aria-controls={openAction ? "long-menu" : undefined}
          aria-expanded={openAction ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) => handleClick(e, data.id)}
          style={{ marginLeft: "10px", color: "#F80B7A" }}
        >
          <MoreVertIcon />
        </SlOptionsVertical>
      </>
    ),
  }));

  return (
    <>
      {/*TABLE ACTION MENU START*/}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openAction}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
            borderColor: "black",
            marginLeft: "81px",
            backgroundColor: "white",
          },
        }}
      >
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <MenuItem>
            <TbEdit
              onClick={() => rollEditHandler(tableId)}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>

          <MenuItem>
            <RiDeleteBin6Line
              onClick={() => RoledeleteHandler(tableId)}
              style={{ fontSize: "lg", width: "50px", height: "23px" }}
            />
          </MenuItem>
        </Box>
      </Menu>
      {/*TABLE ACTION MENU END*/}

      {/* edit component */}
      {editForm && (
        <EditLocation
          status={editForm}
          editMasterCategryHandler={editMasterCategryHandler}
          id={tableId}
        />
      )}
      {/* popup */}
      {open && (
        <AddLocation
          status={open}
          addMasterCategryHandler={addMasterCategryHandler}
          handleClosepopUp = {handleClosePopUp}
        />
      )}

      <Box sx={{ mt: 1 }}>
        {locationFindLoading ? (
          <>
            <Typography variant="h1">
              <Skeleton animation={false} />
            </Typography>

            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
            <Typography variant="h3">
              <Skeleton animation="wave" />
            </Typography>

            <Skeleton animation={false} />
          </>
        ) : (
          <MasterTable
            data={data}
            columns={columns}
            component="locationMaster"
            rollEditHandler={rollEditHandler}
            subMenu={subMenu}
          />
        )}
      </Box>
    </>
  );
}

export default LocationMaster;
